import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import { Add } from '@mui/icons-material';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InputMain from '../../../../shared/InputMain/InputMain';
import ButtonMain from '../../../../shared/ButtonMain';
import validaCaracteresEspeciais from '../../../../../../utils/validaCaracteresEspeciais';
import strip from '../../../../../../utils/strip';
import DocumentoUpload from '../../../../shared/DocumentoUpload';
import LoaderMain from '../../../../shared/LoaderMain';
import AlertErroMain from '../../../../shared/AlertErroMain';

import { postConfirmarDocumentos, getListaTiposDocumentos } from '../../../../../../api/onboarding';

export default ({
  cpfSocioAberto,
  setCpfSocioAberto,
  listaSocios,
  setListaSocios,
  isPendingCadastro,
  setPending,
  setliberarBotaoContinuar,
  cnpjEmpresa,
  codEntrada = null,
  origemProposta
}) => {
  const [dadosFormulario, setDadosFormulario] = useState({});
  const [indexSocio, setIndexSocio] = useState(false);
  const [estadoCivil, setEstadoCivil] = useState('');
  const [regimeBens, setRegimeBens] = useState('CPC');
  const [pessoaPoliticaExposta, setPessoaPoliticaExposta] = useState('N');
  const [mostraErro, setMostraErro] = useState(false);
  const [tiposArquivosAceitos, setTiposArquivosAceitos] = useState(['pdf']);
  const [documentosJuridicos, setDocumentosJuridicos] = useState([]);
  const [isPendingDocumento, setPendingDocumento] = useState(false);
  const [isPendingDocumentoJuridico, setPendingDocumentoJuridico] = useState(false);
  const [newDocOpen, setNewDocOpen] = useState(false);
  const [listaTiposDocInseridos, setListaTiposDocInseridos] = useState([]);
  const [listaTiposDocumentos, setListaTiposDocumentos] = useState([]);
  const [nomeBloqueado, setNomeBloqueado] = useState(true);

  useEffect(() => {
    let btnFinalizar = true;
    listaSocios.map((socio, index) => {
      if (cpfSocioAberto === socio.cpf_cnpj) {
        setIndexSocio(index);
      }
      if (cpfSocioAberto && socio.documentos_enviados !== 'S') {
        if (btnFinalizar && origemProposta === 'one7_mais_credito' && socio.CPFCNPJ.length > 11) {
          btnFinalizar = true;
        } else {
          btnFinalizar = false;
        }
      }
      return socio;
    });

    if (btnFinalizar) {
      setliberarBotaoContinuar(true);
    }

    let tiposArqAceitos = ['pdf'];
    if (origemProposta === 'one7_mais_credito') {
      tiposArqAceitos = ['pdf', 'png', 'jpg'];
    }
    setTiposArquivosAceitos(tiposArqAceitos);
  }, [listaSocios]);

  useEffect(() => {
    if (typeof listaSocios[indexSocio] === 'undefined') return false;
    controlarDadosFomulario(listaSocios[indexSocio]);
    setPendingDocumentoJuridico(false);
  }, [indexSocio]);

  const controlarDadosFomulario = dados => {
    setDadosFormulario(dados);
    setEstadoCivil(dados && dados.estado_civil ? dados.estado_civil : 'SLT');
    setRegimeBens(dados && dados.regime_bens ? dados.regime_bens : 'CPC');
    setPessoaPoliticaExposta(
      dados && dados.pessoa_politicamente_exposta ? dados.pessoa_politicamente_exposta : 'N'
    );
  };

  const confirmarDocumentosSocioPj = (values, form) => {
    var documentosSocioTemp = [...documentosJuridicos];
    var bloquearSubmit = false;
    let mostraFocus = false;
    documentosSocioTemp = documentosSocioTemp.map(doc => {
      if (doc.id === 'new_doc') {
        mostraFocus = true;
      }
      if ((doc.hash_arquivo === undefined || doc.hash_arquivo === '') && doc.obrigacao_doc) {
        bloquearSubmit = true;
        if (doc.nome_arquivo === undefined || doc.nome_arquivo === '') {
          doc.status = 'reprovado';
          doc.titulo_status = 'Pendente';
          doc.subtitulo_status = 'Favor importar um arquivo para este documento';
        }
      }
      return doc;
    });

    if (mostraFocus) {
      document.getElementById('novo_tipoDocumento').focus();
      return false;
    }

    if (bloquearSubmit) {
      setDocumentosJuridicos(documentosSocioTemp);
      return false;
    }

    if (origemProposta === 'one7_mais_credito') {
      setPendingDocumentoJuridico(true);
    } else {
      setPending(true);
    }

    let paramsSocio = formatarPayloadDocumentos(
      documentosJuridicos,
      dadosFormulario.cpf_cnpj,
      null,
      'DSO',
      dadosFormulario.tipo,
      dadosFormulario.SOCIO_RESPONSAVEL
    );

    enviaPostDocumentosSocio(paramsSocio, values, form);
  };

  const enviaPostDocumentosSocio = (params, values, form) => {
    if (typeof params.documentos !== 'undefined' && params.documentos.length > 0) {
      postConfirmarDocumentos(params)
        .then(res => {
          if (res.data && res.data.dados && res.data.dados.processado) {
            enviaAlterarSocio(values, form, true);
          } else {
            setMostraErro(true);
            setPending(false);
            setPendingDocumentoJuridico(false);
            form.setSubmitting(false);
          }
        })
        .catch(err => {
          setMostraErro(err.response);
          setPending(false);
          setPendingDocumentoJuridico(false);
          form.setSubmitting(false);
        });
    } else {
      enviaAlterarSocio(values, form, false);
    }
  };

  const enviaAlterarSocio = (values, form, docEnviado = true) => {
    setPending(false);
    setPendingDocumentoJuridico(false);
    form.setSubmitting(false);

    values = formatarPayLoad(values);
    let tmpListaSocios = listaSocios !== undefined ? [...listaSocios] : [];
    tmpListaSocios[indexSocio] = values;
    if (docEnviado) tmpListaSocios[indexSocio].documentos_enviados = 'S';
    setListaSocios(tmpListaSocios);
    botaoVoltar();
  };

  const formatarPayLoad = dadosForm => {
    dadosForm.cpf_cnpj = strip(dadosForm.cpf_cnpj);
    dadosForm.celular = strip(dadosForm.celular);
    dadosForm.estado_civil = estadoCivil;
    dadosForm.pessoa_politicamente_exposta = pessoaPoliticaExposta;
    if (estadoCivil === 'CSD') {
      dadosForm.cpf_conjuge = strip(dadosForm.cpf_conjuge);
      dadosForm.regime_bens = regimeBens;
    } else {
      dadosForm.regime_bens = '';
      dadosForm.cpf_conjuge = '';
      dadosForm.nome_conjuge = '';
    }
    return dadosForm;
  };

  const formatarPayloadDocumentos = (
    dadosDocumentos,
    cpnj_socio,
    cnpj_conjuge,
    tipoEmpresa,
    tipoSocio,
    socioResponsavel = false
  ) => {
    let tempDocs = [];
    dadosDocumentos.map(doc => {
      if (
        typeof doc.hash_arquivo !== 'undefined' &&
        doc.hash_arquivo !== undefined &&
        doc.hash_arquivo !== null
      ) {
        let tempDoc = {
          cpf_socio: cpnj_socio,
          cpf_conjugue: cnpj_conjuge,
          hash: doc.hash_arquivo,
          cod_documento: doc.id_original ? doc.id_original : doc.id,
          tipo: tipoSocio,
          socio_responsavel: socioResponsavel
        };
        tempDocs.push(tempDoc);
      }
      return doc;
    });

    let params = {
      cnpj: cnpjEmpresa,
      tipo_empresa: tipoEmpresa,
      cod_entrada: codEntrada,
      origem_proposta: origemProposta,
      cod_proposta_comercial: null,
      valida_documento: true,
      envia_email: false,
      documentos: tempDocs
    };

    return params;
  };

  const handleFormSubmit = (values, form) => {
    let resultDoc = confirmarDocumentosSocioPj(values, form);
    if (resultDoc === false) {
      setPending(false);
      setPendingDocumentoJuridico(false);
      form.setSubmitting(false);
      return false;
    }
  };

  const botaoVoltar = () => {
    setCpfSocioAberto(false);
  };

  const salvarNovoDocumento = (indexArquivo, tipoNovoDoc) => {
    if (tipoNovoDoc && tipoNovoDoc.CODPPCDOCUMENTO && documentosJuridicos[indexArquivo].hash_arquivo) {
      setDocumentosJuridicos([]);

      let newListaDocs = documentosJuridicos.map(doc => {
        if (doc.id === 'new_doc') {
          doc.cnpj = doc.cpfcnpj;
          doc.descricao = tipoNovoDoc.DESCRICAO;
          doc.explicacao_doc = tipoNovoDoc.DESCRICAO;
          doc.nome = tipoNovoDoc.NOME;
          doc.nome_doc = tipoNovoDoc.NOME;
          doc.obrigacao_doc = false;
          doc.requerido = false;
          doc.id = tipoNovoDoc.CODPPCDOCUMENTO + `_${documentosJuridicos.length}`;
          doc.id_original = tipoNovoDoc.CODPPCDOCUMENTO;
          delete doc['cpfcnpj'];
          delete doc['novo_doc_lista'];
        }
        return doc;
      });

      setDocumentosJuridicos(newListaDocs);
    }
  };

  const clickDocumento = (indexArquivo, arquivo) => {
    let arqSelected = { ...arquivo };
    if (arqSelected.novo_doc_lista === undefined || arqSelected.novo_doc_lista.length === 0) {
      arqSelected.indexArquivo = indexArquivo;
    }
  };

  const novoDocumento = () => {
    if (newDocOpen || documentosJuridicos === undefined) return false;
    let listaDocsTemp = [...documentosJuridicos];
    let lstTiposDoc = listaTiposDocumentos.filter(
      tipoDoc => listaTiposDocInseridos.includes(tipoDoc.CODPPCDOCUMENTO) === false
    );
    listaDocsTemp.unshift({ id: 'new_doc', novo_doc_lista: lstTiposDoc });
    setDocumentosJuridicos(listaDocsTemp);
  };

  useEffect(() => {
    if (listaTiposDocumentos.length === 0) {
      buscaTiposDocumentos();
    }

    let newDoc = false;
    let lstTiposDocInsTemp = [...listaTiposDocInseridos];
    documentosJuridicos.map(doc => {
      if (doc.id === 'new_doc') newDoc = true;
      if (doc.status !== 'reprovado' && doc.id !== 'new_doc') lstTiposDocInsTemp.push(doc.cod_documento);
      return doc;
    });
    setNewDocOpen(newDoc);
    setListaTiposDocInseridos(lstTiposDocInsTemp);
  }, [documentosJuridicos]);

  const buscaTiposDocumentos = () => {
    getListaTiposDocumentos('S')
      .then(retornoTipos => {
        let ret = retornoTipos.data;
        if (ret.dados && ret.dados.length > 0) {
          setListaTiposDocumentos(ret.dados);
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      });
  };

  return (
    <Formik
      initialValues={dadosFormulario}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        nome: Yup.string()
          .nullable()
          .required('Favor informar o nome')
          .test('nome', 'Nome precisa ter 2 palavras ou mais', value => {
            if (value && value.length > 0) {
              value = value.trim();
              let result = value.includes(' ');
              if (!result) {
                setNomeBloqueado(result);
              }
              return result;
            }
          })
          .test('nome', 'Nome com caracteres Inválidos', value => {
            let caracteresEspeciais = [`'`, `*`, `"`];
            let result = validaCaracteresEspeciais(value, caracteresEspeciais);
            if (!result) {
              setNomeBloqueado(result);
            }
            return result;
          }),
        cpf_cnpj: Yup.string()
          .nullable()
          .required('Campo obrigatório.')
          .test({
            exclusive: true,
            message: 'CPF inválido.',
            name: 'cpf_cnpj',
            test: () => {
              return true;
            }
          })
      })}
      onSubmit={handleFormSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form>
          <ConnectedFocusError />
          <Field
            type="text"
            name="nome"
            titulo="Nome completo"
            id="nome"
            size="small"
            uppercase={true}
            component={InputMain}
            autoComplete="off"
            disabled={isPendingCadastro || isSubmitting || nomeBloqueado}
          />

          <Field
            type="text"
            name="cpf_cnpj"
            titulo="CNPJ"
            id="cpf_cnpj"
            size="small"
            uppercase={true}
            component={InputMain}
            autoComplete="off"
            disabled={isPendingCadastro || isSubmitting || dadosFormulario.cpf_cnpj !== ''}
            tipoMascara="CPFCNPJMask"
          />

          <Box className="mt-3 borda-1 borda-cor-suporte pad-15 border-radius-8 margin-bottom-15">
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5 texto-negrito">
              Para enviar os arquivos corretamente siga as instruções:
            </Typography>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
              <WbSunnyOutlinedIcon className="font-size-1-rem" />
              <span className="pad-5">Escolha um ambiente com boa iluminação e sem sombras</span>
            </Typography>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
              <InfoOutlinedIcon className="font-size-1-rem" />
              <span className="pad-5">Retire a proteção plástica do documento para evitar reflexos</span>
            </Typography>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
              <LockOutlinedIcon className="font-size-1-rem" />
              <span className="pad-5">
                Não se preocupe! Coletamos e tratamos seus dados para fins de identificação, autenticação
                e prevenção de fraudes. Esse processo é 100% confidencial
              </span>
            </Typography>
          </Box>

          <Box sx={isPendingDocumentoJuridico ? { position: 'relative', minHeight: '255px' } : {}}>
            {isPendingDocumentoJuridico && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

            <ButtonMain
              tipoBotao="principal-texto-branco"
              disabled={
                newDocOpen ||
                isPendingDocumento ||
                isPendingDocumentoJuridico ||
                listaTiposDocumentos.length === 0
              }
              onClick={() => novoDocumento()}
            >
              <Add />
              Novo Documento
            </ButtonMain>

            {documentosJuridicos.length !== 0 &&
              documentosJuridicos.map((doc, index) => {
                var classesAdicionais =
                  index + 1 === documentosJuridicos.length
                    ? 'borda-transparente'
                    : 'borda-cor-cinza-claro';

                return (
                  <DocumentoUpload
                    key={doc.id}
                    id={doc.id}
                    boxClassesAdicionais={classesAdicionais}
                    isPending={isPendingDocumento}
                    setPending={setPendingDocumento}
                    listaDocs={documentosJuridicos}
                    setListaDocs={setDocumentosJuridicos}
                    arquivosAceitos={tiposArquivosAceitos}
                    tamanhoMaximoMegaAceito={30}
                    boxUploadAction={clickDocumento}
                    btnEnviarDocumento={false}
                    cnpjEmpresa={cnpjEmpresa}
                    funcaoSalvarNovoDocumento={salvarNovoDocumento}
                  />
                );
              })}
          </Box>

          <Grid container className="mb-4">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />
            </Grid>
          </Grid>

          <Grid container spacing={3} className="mt-2">
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}>
              <ButtonMain
                tipoBotao="transparente"
                disabled={isPendingCadastro || isSubmitting || isPendingDocumento}
                onClick={() => {
                  botaoVoltar();
                }}
              >
                Voltar
              </ButtonMain>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
              <ButtonMain
                type="submit"
                tipoBotao="principal"
                disabled={
                  isPendingCadastro ||
                  isSubmitting ||
                  isPendingDocumento ||
                  documentosJuridicos.length <= 0
                }
              >
                Continuar
              </ButtonMain>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
