import React, { useState, useEffect } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DropZone from 'react-dropzone';
import swal from '@sweetalert/with-react';
import FileRow from '../../FileRow/FileRow';
import {
  uploadArquivoXML,
  saveBorderoArquivoXML,
  deleteBorderoArquivoXML
} from '../../../../../api/borderos';
import { TAMANHO_MAXIMO_UPLOAD } from '../../../../../shared';

const ModalImportarPDF = props => {
  const { toggle, modal, bordero, updateBordero } = props;

  const [files, setFiles] = useState([]); // armazena o arquivo que será dado o upload
  const [, setFileUploadProgress] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const fileTypesAccepted = [
    // array contendo os mime types de arquivos permitidos
    '.rar',
    '.zip',
    '.xml'
  ];

  useEffect(() => {
    var arquivos = [];
    bordero.titulos.forEach(tit => {
      var existe = arquivos.find(arq => arq.id === parseInt(tit.duplicata.codArquivoXml));
      if (tit.duplicata.codArquivoXml && tit.duplicata.nomeXml && !existe) {
        var arq = { id: parseInt(tit.duplicata.codArquivoXml), arquivo: tit.duplicata.nomeXml };
        arquivos.push(arq);
      }
    });
    setFiles(arquivos || []);
  }, []);

  useEffect(() => {
    bordero.arquivos = files;
    updateBordero(bordero);
  }, [files]);

  /**
   * Trata o arquivo que foi jogado/carregado via drodzone
   * Armazena ele no files, para que quando o usuário clicar em "Importar" seja feito o upload no server
   *
   * @param acceptedFiles
   */
  const filesAcceptedHandler = acceptedFiles => {
    const parsedFile = acceptedFiles.map(acceptedFile => {
      const data = new FormData();
      data.append('file', acceptedFile);

      const uniqueId = Math.random()
        .toString(36)
        .substr(2, 9);

      return {
        id: uniqueId,
        data,
        name: acceptedFile.name,
        statusCode: 0,
        response: null,
        alreadyUploaded: false
      };
    });
    setFiles([...files, ...parsedFile]);

    var arquivosProcessar = [];
    let _processados = 0;

    // percorre todos os arquivos salvos no state (listados)
    parsedFile.forEach(file => {
      // se o arquivo possuir prop data e ainda não tiver efetuado o upload, envia para o servidor
      // somente arquivos que serão dados upload que possuem propriedade 'data'
      if (!file.alreadyUploaded && isNaN(file.id)) {
        uploadArquivoXML(file.data, progressValue => {
          file.progress = progressValue;
          file.statusCode = 0;

          setFileUploadProgress({ [file.id]: progressValue });
        })
          .then(res => {
            file.response = res.data;
            file.statusCode = res.status;
            file.alreadyUploaded = true;

            //modificado para ler os arquivos que vier em array
            file.response.forEach(arq => {
              arquivosProcessar.push(arq);
            });
          })
          .catch(err => {
            file.alreadyUploaded = false;
            file.response = err.response || 'Falha';
            file.statusCode = (err.response && err.response.status) || 400;
          })
          .finally(() => {
            _processados++; // incrementa quantos arquivos foram processados, para executar envio da URL apenas na última requisição
            if (_processados === acceptedFiles.length) {
              var parseArquivos = [...parsedFile];
              saveBorderoArquivoXML(bordero.id, arquivosProcessar)
                .then(response => {
                  setFiles([...files, ...response.data]);
                  updateBordero();
                })
                .catch(err => {
                  _processados = 0;
                  parseArquivos.map(arq => {
                    arq.alreadyUploaded = false;
                    arq.response = err.response || 'Falha';
                    arq.statusCode = (err.response && err.response.status) || 400;
                    return arq;
                  });
                  setFiles([...files, ...parseArquivos]);
                });
            }
          });
      } else {
        _processados++;
      }
    });
  };

  const filesRejectedHandler = files => {
    const rejectedMessage = files.map(file => {
      const fileName = file.name;
      const extension = fileName.split('.');

      if (fileTypesAccepted.indexOf(`.${extension[extension.length - 1]}`) === -1) {
        return 'Extensões permitidas:  xml, zip ou rar';
      }

      return 'Ocorreu algo inesperado! Verifique o arquivo enviado e tente novamente.';
    });

    swal({
      title: 'Atenção!',
      text: rejectedMessage[0],
      icon: 'error',
      dangerMode: true
    });
  };

  const deleteFile = async (file, idFile) => {
    const updatedFiles = files.filter(fl => fl.id !== file.id);

    if (idFile && !isNaN(idFile)) {
      await swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar o arquivo XML? 
               Este arquivo será desatrelado dos titulos dos XML`,
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar']
      }).then(confirm => {
        if (confirm) {
          deleteBorderoArquivoXML(bordero.id, idFile)
            .then(() => {
              updateBordero();
              setFiles([...updatedFiles]);
            })
            .catch(err => {
              if (err.response.data.error && err.response.data.error.message) {
                setErrorMessage(err.response.data.error.message);
              }
            });
        }
      });
    } else {
      setFiles([...updatedFiles]);
    }
  };

  const retryUpload = file => {
    uploadArquivoXML(file.data, progressValue => {
      file.progress = progressValue;
      file.statusCode = 0;
      setFiles([...files]);
      setFileUploadProgress({ [file.id]: progressValue });
    })
      .then(res => {
        file.response = res.data;
        file.statusCode = res.status;
        file.alreadyUploaded = true;

        //modificado para ler os arquivos que vier em array
        var arquivosProcessar = [...file.response];
        saveBorderoArquivoXML(bordero.id, arquivosProcessar)
          .then(response => {
            file.id = response.id;
            file.arquivo = response.arquivo;
            file.observacao = response.observacao;
            setFiles([...files]);
            updateBordero();
          })
          .catch(err => {
            file.alreadyUploaded = false;
            file.response = err.response || 'Falha';
            file.statusCode = (err.response && err.response.status) || 400;
            setFiles([...files]);
          });
      })
      .catch(err => {
        file.alreadyUploaded = false;
        file.response = err.response || 'Falha';
        file.statusCode = (err.response && err.response.status) || 400;
      });
  };

  return (
    <Modal isOpen={modal.show} toggle={toggle} size="md" className="modalImportarXML">
      <ModalHeader toggle={toggle}>Importar Arquivos</ModalHeader>
      <ModalBody>
        {errorMessage && (
          <div className="alert alert-danger animated fadeIn" role="alert">
            {errorMessage}
          </div>
        )}
        <Label className="custom-label mb-3">
          Importe aqui os XMLs dos titulos incluidos, podendo serem incluidos via ZIP/RAR
        </Label>
        <DropZone
          onDropAccepted={filesAcceptedHandler}
          onDropRejected={filesRejectedHandler}
          noDrag={false}
          accept={fileTypesAccepted}
          maxSize={TAMANHO_MAXIMO_UPLOAD}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section>
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <h4>Solte o arquivo aqui...</h4>
                ) : (
                  <h4>
                    <span>
                      <i className="fas fa-paperclip file-icon" />
                    </span>
                    <span className="link">Selecione</span>
                    <span> o arquivo ou arraste aqui</span>
                  </h4>
                )}
              </div>
            </section>
          )}
        </DropZone>
        {files.length > 0 && (
          <div className="file-wrapper">
            {files.map((file, key) => (
              <FileRow
                // eslint-disable-next-line react/no-array-index-key
                key={`arquivo-duplicata-${key}`}
                file={file}
                deleteFile={deleteFile}
                retryUpload={retryUpload}
              />
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="md" type="button" onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalImportarPDF.defaultProps = {
  modal: { show: false }
};

export default ModalImportarPDF;
