export const validaForcaSenha = password => {
  const regexUppercase = new RegExp(/^(?=.*[A-Z]).+$/);
  const regexLowercase = new RegExp(/^(?=.*[a-z]).+$/);
  const regexStringcase = new RegExp(/(?=.*[A-z]).+$/);
  const regexNumber = new RegExp(/^(?=.*[0-9]).+$/);
  const regexSpecial = new RegExp(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);

  const length = password.length >= 8;
  const caseText = regexUppercase.test(password) && regexLowercase.test(password);
  const numberText = regexNumber.test(password);
  const stringText = regexStringcase.test(password);
  const charScpecial = regexSpecial.test(password);
  const caseTextCharScpecial = caseText || charScpecial;

  let forcaSenha = 0;

  if (caseTextCharScpecial && numberText && length) {
    forcaSenha = 100;
  } else if (caseTextCharScpecial && length) {
    forcaSenha = 50;
  } else if (caseTextCharScpecial && numberText && password.length >= 8) {
    forcaSenha = 50;
  } else if (password.length >= 8 && numberText && stringText) {
    forcaSenha = 50;
  } else if (length) {
    forcaSenha = 15;
  } else if (numberText) {
    forcaSenha = 15;
  } else if (caseTextCharScpecial) {
    forcaSenha = 15;
  } else if (password.length > 0) {
    forcaSenha = 5;
  }

  return forcaSenha;
};

export const teste = valor => {
  return valor;
};

export const convertToTitleCase = nome => {
  return (
    nome &&
    nome
      .toLowerCase()
      .split(' ')
      .reduce((s, c) => s + '' + (c.charAt(0).toUpperCase() + c.slice(1) + ' '), '')
  );
};

export const obterPrimeiroNome = nome => {
  return nome && nome.split(' ')[0];
};

export const obterPrimeiroNomeConvertidoToTitleCase = nome => {
  let fullNome = convertToTitleCase(nome);
  return obterPrimeiroNome(fullNome);
};

export const truncarFraseAcrescentandoEllipis = (str, maxLength) => {
  const ellipsis = '...';
  if (str.length > maxLength) {
    return str.slice(0, maxLength - ellipsis.length) + ellipsis;
  } else {
    return str;
  }
};

export const retornaInformacoesNasPillsDeCheckpoints = (
  dataInsercao,
  descricao,
  usuarioInsercao,
  Observacao
) => {
  return (
    'Checkpoint: ' +
    (dataInsercao ? dataInsercao : '') +
    ' - ' +
    (descricao ? descricao : ' - ') +
    ', por: ' +
    (usuarioInsercao ? obterPrimeiroNomeConvertidoToTitleCase(usuarioInsercao) : '') +
    '\n' +
    (Observacao ? Observacao : '')
  );
};

export const formatarDataCreatedAtFromMongo = data => {
  const date = new Date(data.created_at);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return `${day}/${month}/${year}`;
};

export const formatarDataCreatedAtNow = () => {
  let hoje = new Date();

  let dia = hoje.getDate();
  let mes = hoje.getMonth() + 1;
  let ano = hoje.getFullYear();

  return dia + '/' + mes + '/' + ano;
};
