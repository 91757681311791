import React from 'react';

import { getMostrarPesquisaWootric } from '../../../api/dashboard';

class Wootric extends React.Component {
  componentDidMount() {
    getMostrarPesquisaWootric()
      .then(res => {
        if (res.data.TOTAL > 0) {
          let setupScript = document.createElement('script');
          let email_usuario = res.data.EMAIL;

          setupScript.type = 'text/javascript';
          setupScript.id = 'wootric-settings';
          setupScript.async = true;
          setupScript.innerHTML = `
            wootric_no_surveyed_cookie = false;
            wootric_survey_immediately = false;
            window.wootricSettings = {
              email: '${email_usuario}',
              account_token: 'NPS-f1671462',
              language: 'PT'
            };
          `;

          if (document.getElementById('wootric-settings') == null) {
            document.body.appendChild(setupScript);
          }

          // Beacon
          let beacon = document.createElement('script');
          beacon.type = 'text/javascript';
          beacon.id = 'wootric-beacon';
          beacon.async = true;

          beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
          beacon.onload = function() {
            window.wootric('run');
          };

          if (document.getElementById('wootric-beacon') == null) {
            document.body.appendChild(beacon);
          }
        }
      })
      .catch(() => {});
  }

  render() {
    return <div />;
  }
}

export default Wootric;
