import React from 'react';
import { Container } from 'reactstrap';
import Sidebar from '../Sidebar/Sidebar';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { getClienteAtual } from '../../shared';
import UsuarioLogado from './UsuarioLogado/UsuarioLogado';
import BrokerCliente from './UsuarioLogado/BrokerCliente';
import ModalNovidades from '../Dashboard/ModalNovidades/ModalNovidades';

export default ({ className, children, Header = false, mouseLeaveEnter = false, ...props }) => {
  const [mostraFundoEscuro, setMostraFundoEscuro] = React.useState(false);
  const clienteAtual = getClienteAtual();

  const onMouseLeaveEnter = estado => {
    if (typeof mouseLeaveEnter === 'function') {
      mouseLeaveEnter(estado);
    }
  };

  return (
    <>
      <Sidebar {...props} clienteAtual={clienteAtual} setMostraFundoEscuro={setMostraFundoEscuro} />
      {mostraFundoEscuro && <div className="div-fundo-escuro" />}

      <div className="main-panel">
        <UsuarioLogado setMostraFundoEscuro={setMostraFundoEscuro} fixed={true} />
        <div className="header-principal" />
        <Container className="float-lg-left main-container">
          <div
            className="content"
            onMouseLeave={() => onMouseLeaveEnter(true)}
            onMouseEnter={() => onMouseLeaveEnter(false)}
          >
            {Header ? (
              <Header />
            ) : (
              <>
                <div className="cliente-title">
                  {clienteAtual && clienteAtual.razaoSocial ? clienteAtual.razaoSocial : ''}
                </div>
                <BreadCrumb {...props} />
              </>
            )}
            <main className={className}>{children}</main>
          </div>
        </Container>
        <BrokerCliente />

        {process.env.REACT_APP_ALERTA_PRINCIPAL && process.env.REACT_APP_ALERTA_PRINCIPAL !== '' && (
          <ModalNovidades />
        )}
      </div>
    </>
  );
};
