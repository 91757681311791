import React from 'react';
import Select from 'react-select';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import stringDotNotationToObject from '../../../../utils/stringDotNotationToObject';

// REF: https://react-select.com/home
export const CustomSelect = ({
  field: { ...fields },
  // eslint-disable-next-line no-unused-vars
  form: { touched, errors, setFieldValue, ...rest },
  ...props
}) => {
  const {
    labelClassName,
    label,
    loading = false,
    className = 'react-custom-select',
    fieldError,
    disabled,
    isClearable = true,
    defaultValue,
    ...restProps
  } = props;
  const onChange = option => {
    let newValue = null;
    if (option) {
      newValue = restProps.isMulti ? option.map(item => item.value) : option.value;
    }
    setFieldValue(fields.name, newValue);
    if (restProps.onChange) {
      restProps.onChange(option);
    }
  };

  const getValue = () => {
    if (restProps.options) {
      if (fields.value) {
        return restProps.isMulti
          ? restProps.options.filter(option => fields.value.indexOf(option.value) >= 0)
          : restProps.options.find(option => option.value === fields.value);
      } else {
        return '';
      }
    } else {
      return restProps.isMulti ? [] : '';
    }
  };

  const error = fields.name.includes('.')
    ? stringDotNotationToObject(fields.name, errors)
    : (errors || {})[fields.name];

  const touchedField = fields.name.includes('.')
    ? stringDotNotationToObject(fields.name, touched)
    : (touched || {})[fields.name];

  return (
    <FormGroup className="loadingWrapper">
      <Label for={restProps.id} className={`${labelClassName ? labelClassName : ''}`}>
        {label}
      </Label>
      <Select
        {...fields}
        {...restProps}
        defaultValue={defaultValue}
        isDisabled={disabled}
        isLoading={loading}
        isClearable={isClearable}
        value={getValue()}
        onChange={onChange}
        classNamePrefix={className}
        className={`${className} ${touchedField && error ? 'is-invalid' : ''}`}
      />
      {touchedField && error ? (
        <FormFeedback>{fieldError ? error[fieldError] : error}</FormFeedback>
      ) : (
        ''
      )}
      {loading && (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          <span className="sr-only">Carregando...</span>
        </>
      )}
    </FormGroup>
  );
};

export default CustomSelect;
