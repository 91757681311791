import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import TableSearch from '../Borderos/common/TableSearch';

import columnsTable from './columnsTable';

export default props => {
  const {
    data,
    classeIdentificadora,
    mensagem,
    configuracaoColunas,
    classes,
    keyField,
    tableSearch
  } = props;

  return (
    <>
      <div className={'table-responsive ' + (classeIdentificadora || '')}>
        {mensagem && (
          <div className="alert alert-danger animated fadeIn" role="alert">
            {mensagem}
          </div>
        )}
        {data && data.length > 0 ? (
          <ToolkitProvider
            keyField={keyField}
            data={data}
            columns={columnsTable(configuracaoColunas || [])}
            search
          >
            {tableProps => (
              <>
                {tableSearch && <TableSearch {...tableProps.searchProps} />}
                <div className="scrollable-content">
                  <BootstrapTable
                    {...props} //no props estão as configurações do bootstraptabel passada na criação do grid expandRow, foi feito assim, pois caso não tenha o expandRow, ele não pode ser criado
                    className={`table hover ${classes || ''}`}
                    bootstrap4
                    bordered={false}
                    responsive
                    hover
                    {...tableProps.baseProps}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        ) : (
          <> Nenhum item foi encontrado para listar </>
        )}
      </div>
    </>
  );
};
