export default [
  {
    code: '1',
    descricao: 'Recompra'
  },
  {
    code: '2',
    descricao: 'Abatimento'
  },
  {
    code: '3',
    descricao: 'Prorrogação'
  },
  {
    code: '4',
    descricao: 'Baixa'
  }
];
