import React, { useState, useEffect } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DropZone from 'react-dropzone';
import swal from '@sweetalert/with-react';
import FileRow from '../../Borderos/Wizard/FileRow/FileRow';
import {
  uploadAnexoInstrucao,
  saveInstrucoesFilesURL,
  deleteInstrucaoFile
} from '../../../api/instrucoes';
import { TAMANHO_MAXIMO_UPLOAD } from '../../../shared';

const ModalAnexoInstrucao = props => {
  const { toggle, modal, setCorBtnAnexo, enviarEmailAnexo } = props;

  const [files, setFiles] = useState([]); // armazena o arquivo que será dado o upload
  const [, setFileUploadProgress] = useState({});
  const [qtdFilesInitial] = useState(modal.arquivos.length); //armazena a quantidade inicial de arquivos
  const fileTypesAccepted = [
    // array contendo os mime types de arquivos permitidos
    '.pdf',
    '.rar',
    '.zip'
  ];

  useEffect(() => {
    setFiles(modal.arquivos || []);
  }, []);

  useEffect(() => {
    modal.arquivos = files;
  }, [files]);

  /**
   * Trata o arquivo que foi jogado/carregado via drodzone
   * Armazena ele no files, para que quando o usuário clicar em "Importar" seja feito o upload no server
   *
   * @param acceptedFiles
   */
  const filesAcceptedHandler = acceptedFiles => {
    const parsedFile = acceptedFiles.map(acceptedFile => {
      const data = new FormData();
      data.append('file', acceptedFile);

      const uniqueId = Math.random()
        .toString(36)
        .substr(2, 9);

      return {
        id: uniqueId,
        data,
        name: acceptedFile.name,
        statusCode: 0,
        response: null,
        alreadyUploaded: false
      };
    });

    setFiles([...files, ...parsedFile]);

    const parseArquivos = [];
    let _processados = 0;

    // percorre todos os arquivos salvos no state (listados)
    parsedFile.forEach(file => {
      // se o arquivo possuir prop data e ainda não tiver efetuado o upload, envia para o servidor
      // somente arquivos que serão dados upload que possuem propriedade 'data'
      if (!file.alreadyUploaded && isNaN(file.id)) {
        uploadAnexoInstrucao(file.data, progressValue => {
          file.progress = progressValue;
          file.statusCode = 0;

          setFileUploadProgress({ [file.id]: progressValue });
        })
          .then(res => {
            file.response = res.data;
            file.statusCode = res.status;
            file.alreadyUploaded = true;

            // só atualiza state 'files' caso envio do arquivo seja bem sucedido
            parseArquivos.push({
              arquivo: file.response[0].arquivoSalvo
            });
          })
          .catch(err => {
            file.alreadyUploaded = false;
            file.response = err.response || 'Falha';
            file.statusCode = (err.response && err.response.status) || 400;
          })
          .finally(() => {
            _processados++; // incrementa quantos arquivos foram processados, para executar envio da URL apenas na última requisição

            if (_processados === acceptedFiles.length) {
              setFiles([...files, ...parsedFile]);

              saveInstrucoesFilesURL(modal.codInstrucao, parseArquivos).then(response => {
                modal.arquivos = response.data;
                setFiles(modal.arquivos);

                setCorBtnAnexo(modal.codInstrucao, 'primary');
              });
            }
          });
      } else {
        _processados++;
      }
    });
  };

  const filesRejectedHandler = files => {
    const rejectedMessage = files.map(file => {
      const fileName = file.name;
      const extension = fileName.split('.');

      if (fileTypesAccepted.indexOf(`.${extension[extension.length - 1]}`) === -1) {
        return 'Extensões permitidas: pdf, zip ou rar';
      }

      return 'Ocorreu algo inesperado! Verifique o arquivo enviado e tente novamente.';
    });

    swal({
      title: 'Atenção!',
      text: rejectedMessage[0],
      icon: 'error',
      dangerMode: true
    });
  };

  const deleteFile = (file, idFile) => {
    const updatedFiles = files.filter(fl => fl.id !== file.id);

    // caso o ID do arquivo exista e seja um número => enviar requisição de DELETE para o back
    if (idFile && !isNaN(idFile)) {
      deleteInstrucaoFile(modal.codInstrucao, idFile);
    }

    // atualiza files
    setFiles([...updatedFiles]);

    if (updatedFiles.length < 1) {
      setCorBtnAnexo(modal.codInstrucao, 'danger');
    }
  };

  const retryUpload = file => {
    uploadAnexoInstrucao(file.data, progressValue => {
      file.progress = progressValue;
      file.statusCode = 0;
      setFiles([...files]);
    })
      .then(res => {
        file.response = res.data;
        file.statusCode = res.status;
      })
      .catch(err => {
        file.response = err.response;
        file.statusCode = err.response.status;
      })
      .finally(() => {
        setFiles([...files]);
      });
  };

  const verificaEnvioEmail = () => {
    if (modal.codTipoInstrucao === '4' && files.length >= 1 && files.length !== qtdFilesInitial) {
      enviarEmailAnexo(modal.codInstrucao);
    }
  };

  return (
    <Modal isOpen={modal.show} toggle={toggle} backdrop="static" size="md" className="modalDuplicata">
      <ModalHeader>Importar anexos da Instrução</ModalHeader>
      <ModalBody>
        <Label className="custom-label mb-3">Inserir documentos relacionados à instrução.</Label>
        <DropZone
          onDropAccepted={filesAcceptedHandler}
          onDropRejected={filesRejectedHandler}
          noDrag={false}
          accept={fileTypesAccepted}
          maxSize={TAMANHO_MAXIMO_UPLOAD}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section>
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <h4>Solte o arquivo aqui...</h4>
                ) : (
                  <h4>
                    <span>
                      <i className="fas fa-paperclip file-icon" />
                    </span>
                    <span className="link">Selecione</span>
                    <span> o arquivo ou arraste aqui</span>
                  </h4>
                )}
              </div>
            </section>
          )}
        </DropZone>
        {files.length > 0 && (
          <div className="file-wrapper">
            {files.map((file, key) => (
              <FileRow
                // eslint-disable-next-line react/no-array-index-key
                key={`arquivo-duplicata-${key}`}
                file={file}
                deleteFile={deleteFile}
                retryUpload={retryUpload}
              />
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          size="md"
          type="button"
          onClick={() => {
            toggle();
            verificaEnvioEmail();
          }}
        >
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalAnexoInstrucao.defaultProps = {
  modal: { show: false }
};

export default ModalAnexoInstrucao;
