/* eslint-disable react/jsx-indent */
/* eslint-disable prettier/prettier */
/* NECESSARIO RESOLVER OS ERROS DE prettier e jsx-indent -- RETIRAR OS COMENTARIOS ACIMA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
import React, { useEffect, useState, memo } from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup, Label, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from '@sweetalert/with-react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import VoltarIcon from '../../../../shared/icons/Voltar';
import Avancar from '../../../../shared/icons/Avancar';
import ModalGiroRapido from './ModalGiroRapido/ModalGiroRapido';
import ModalNovaConta from './ModalNovaConta/ModalNovaConta';
import { TIPO_EMPRESA } from '../SelectionStep/ModalInclusaoTitulo/schema';
import ExclamationIcon from '../../../../assets/img/triangle-exclamation.svg';

import {
  Radio,
  Select,
  SkeletonLoader,
  TAMANHO_MAXIMO_UPLOAD,
  getDadosUsuario
} from '../../../../shared';
import {
  saveOrUpdateBordero,
  uploadFile,
  getTiposOperacoes,
  getTiposTitulos,
  getTiposEmpresas,
  saveBorderoTitulo
} from '../../../../api/borderos';
import { getContasCorrentes } from '../../../../api/dominios';
import FileRow from '../FileRow/FileRow';
import { searchContasViaJornadaDigital } from '../../../../api/clientSearch';
import HistoricoAcesso from '../../../commom/HistoricoAcesso';

const validateSelectedValues = (values, tipoOperacao, setFieldValue) => {
  if (values.tipoEmpresa) {
    const tipoEmpresaSelectedIsValid = tipoOperacao.tiposEmpresasPermitidos.includes(
      values.tipoEmpresa.code
    );

    if (!tipoEmpresaSelectedIsValid) {
      setFieldValue('tipoEmpresa', {});
    }
  }

  if (values.tipoTitulo) {
    const tipoTituloSelectedIsValid = tipoOperacao.tiposEmpresasPermitidos.includes(
      values.tipoTitulo.code
    );
    if (!tipoTituloSelectedIsValid) {
      setFieldValue('tipoTitulo', {});
    }
  }

  if (tipoOperacao.code === 'SC') {
    setFieldValue('tipoEmpresa', {});
  } else {
    setFieldValue('contaEscrow', {});
  }
};

const validateSelectedValuesForCompany = (values, tipoEmpresa, setFieldValue) => {
  if (values.tipoTitulo) {
    const tipoTituloSelectedIsValid = tipoEmpresa.tiposDocumentosPermitidos.includes(
      values.tipoTitulo.code
    );
    if (!tipoTituloSelectedIsValid) {
      setFieldValue('tipoTitulo', {});
    }
  }
};

const contaCorrenteForSelect = contasCorrentes => {
  if (contasCorrentes) {
    return contasCorrentes.map(conta => {
      return {
        value: conta.id,
        label: conta.descricao
      };
    });
  }
};

const validationSchema = () => {
  return Yup.lazy(() =>
    Yup.object().shape({
      contaCorrente: Yup.object().when('tipoEmpresa', {
        is: tipoEmpresa => tipoEmpresa.code && tipoEmpresa.exigeConta === true,
        then: Yup.object().shape({
          id: Yup.string()
            .required('Campo obrigatório')
            .nullable()
        }),
        otherwise: Yup.object().shape({
          id: Yup.string().nullable()
        })
      }),
      tipoOperacao: Yup.object().shape({
        code: Yup.string()
          .required('Campo obrigatório')
          .nullable()
      }),
      tipoEmpresa: Yup.object().when('tipoOperacao', {
        is: tipoOperacao =>
          tipoOperacao.code && !tipoOperacao.tiposEmpresasPermitidos.includes(tipoOperacao.code),
        then: Yup.object().shape({
          code: Yup.string()
            .required('Campo obrigatório')
            .nullable()
        }),
        otherwise: Yup.object().shape({
          code: Yup.string().nullable()
        })
      }),
      tipoTitulo: Yup.object().when('tipoOperacao', {
        is: tipoOperacao => tipoOperacao.code && tipoOperacao.tiposDocumentosPermitidos.length > 0,
        then: Yup.object().shape({
          code: Yup.string()
            .required('Campo obrigatório')
            .nullable()
        }),
        otherwise: Yup.object().shape({
          code: Yup.string().nullable()
        })
      })
    })
  );
};

const UploadStep = props => {
  const {
    previousStep,
    nextStep,
    currentStep,
    isPending: isParentPending,
    bordero,
    updateStateBordero,
    history,
    match,
    clienteAtual
  } = props;

  const [groupedTitulos, setGroupedTitulos] = useState([]);
  const [titulos, setTitulos] = useState([]);
  const [files, setFiles] = useState([]);
  const [, setFileUploadProgress] = useState({});
  const [modalGiroRapido, setModalGiroRapido] = useState(false);
  const [modalNovaConta, setModalNovaConta] = useState(false);
  const [message, setMessage] = useState();
  const [isPending, setIsPending] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [form, setForm] = useState({});
  const [primeiraOperacaoOnboarding, setPrimeiraOperacaoOnboarding] = useState(false);
  const [initialValues] = useState({
    contaCorrente: bordero.contaCorrente || {
      id: null
    },
    contaEscrow: bordero.contaEscrow || {
      id: null
    },
    tipoOperacao: bordero.tipoOperacao || {
      code: null
    },
    tipoEmpresa: bordero.tipoEmpresa || {
      code: null
    },
    tipoTitulo: bordero.tipoTitulo || {
      code: null
    }
  });
  const [dominios, setDominios] = useState({
    contasCorrentes: null,
    contasBoletoEscrow: null,
    empresasEscrowSimples: null,
    tiposOperacoes: null,
    tiposOperacoesObj: null,
    tiposTitulos: null,
    tiposEmpresas: null
  });

  const [modalAlertaBordero, setModal] = useState({ show: false, bordero: null });

  const toggle = bordero => {
    const newModal = {
      bordero,
      show: !modalAlertaBordero.show
    };
    setModal(newModal);
  };

  const [contaViaJornada, setContaViaJornada] = useState(true);
  const usuario = getDadosUsuario();

  useEffect(() => {
    if (form && form.setFieldValue && bordero && bordero.contaCorrente) {
      form.setFieldValue('contaCorrente', bordero.contaCorrente);
    }
    if (form && form.setFieldValue && bordero && bordero.contaEscrow) {
      form.setFieldValue('contaEscrow', bordero.contaEscrow);
    }
    if (form && form.setFieldValue && bordero && bordero.tipoOperacao) {
      form.setFieldValue('tipoOperacao', bordero.tipoOperacao);
    }
    if (form && form.setFieldValue && bordero && bordero.tipoEmpresa) {
      form.setFieldValue('tipoEmpresa', bordero.tipoEmpresa);
    }
    if (form && form.setFieldValue && bordero && bordero.tipoTitulo) {
      form.setFieldValue('tipoTitulo', bordero.tipoTitulo);
    }
    if (form && form.setFieldValue && bordero && bordero.contratoFisico) {
      form.setFieldValue('contratoFisico', bordero.contratoFisico);
    }
  }, [form.setFieldValue, dominios]);

  const filtrarPorEmpresaPermitidaOperacaoES = (tiposOperacoes, contasCorrentes) => {
    const operacoesFiltradas = tiposOperacoes.filter(operacao => {
      if (operacao.code === 'ES') {
        return operacao.tiposEmpresasPermitidos.some(tipo => contasCorrentes.includes(tipo));
      }
      return true;
    });
    return operacoesFiltradas;
  };

  useEffect(() => {
    setIsPending(true);
    Promise.all([getContasCorrentes(), getTiposOperacoes(), getTiposTitulos(), getTiposEmpresas()])
      .then(([contasCorrentes, tiposOperacoes, tiposTitulos, tiposEmpresas]) => {
        const newDominio = {
          contasCorrentes: contasCorrentes.data['corrente'],
          contasBoletoEscrow: contasCorrentes.data['escrow']['boleto'],
          empresasEscrowSimples: contasCorrentes.data['escrow']['simples'],
          tiposOperacoes: filtrarPorEmpresaPermitidaOperacaoES(
            tiposOperacoes.data,
            contasCorrentes.data['escrow']['simples']
          ),
          tiposOperacoesObj: {},
          tiposTitulos: tiposTitulos.data,
          tiposEmpresas: tiposEmpresas.data
        };
        newDominio.tiposOperacoes.map(tipoOp => {
          newDominio.tiposOperacoesObj[tipoOp.code] = tipoOp;
          return tipoOp;
        });
        setDominios(newDominio);
      })
      .finally(() => {
        setIsPending(false);
      });
    contaViaJornadaDigital();
  }, []);

  useEffect(() => {
    setTitulos(
      groupedTitulos.flatMap(groupedTitulo => groupedTitulo.data).filter(titulos => titulos !== null)
    );
  }, [groupedTitulos]);

  const setContaCorrente = novaContaCorrente => {
    setIsPending(true);
    return getContasCorrentes()
      .then(contasCorrentes => {
        const newDominio = {
          ...dominios,
          contasCorrentes: contasCorrentes.data['corrente']
        };
        setDominios(newDominio);
        form.setFieldValue('contaCorrente.id', novaContaCorrente.id.toString());
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const deleteFile = file => {
    const updatedFiles = files.filter(fl => fl.id !== file.id);
    const updatedGroupedTitulos = groupedTitulos.filter(gp => gp.fileId !== file.id);
    setFiles([...updatedFiles]);
    setGroupedTitulos([...updatedGroupedTitulos]);
  };

  const filesAcceptedHandler = acceptedFiles => {
    setIsUploading(true);

    const parsedFiles = acceptedFiles.map(acceptedFile => {
      const data = new FormData();
      data.append('file', acceptedFile);
      data.append('bordero', form.state.values.tipoTitulo.code);
      const uniqueId = Math.random()
        .toString(36)
        .substr(2, 9);
      return {
        id: uniqueId,
        data,
        name: acceptedFile.name,
        statusCode: 0,
        response: null
      };
    });

    const parsedTitles = parsedFiles.map(pf => ({ fileId: pf.id, data: null }));

    setFiles([...files, ...parsedFiles]);
    setGroupedTitulos([...groupedTitulos, ...parsedTitles]);

    parsedFiles.forEach(parsedFile => {
      uploadFile(
        parsedFile.data,
        progressValue => {
          parsedFile.progress = progressValue;
          parsedFile.statusCode = 0;
          setFileUploadProgress({ [parsedFile.id]: progressValue });
        },
        clienteAtual.clienteEspecial,
        form.state.values.tipoOperacao.code,
        clienteAtual.clienteOperaConnect,
        primeiraOperacaoOnboarding,
        clienteAtual.liberaXmlRedesconto
      )
        .then(res => {
          let parsedTitle = parsedTitles.find(gt => gt.fileId === parsedFile.id);
          parsedFile.response = res.data;
          parsedFile.statusCode = res.status;
          parsedTitle.data = res.data.titulos;

          parsedTitle.data.forEach(titulo => {
            if (titulo.cheque && titulo.cheque.cmc7) {
              titulo.cheque.cmc7 = titulo.cheque.cmc7.replace(/\s/g, '');
            }
          });
        })
        .catch(err => {
          parsedFile.response = err.response;
          parsedFile.statusCode = (err.response && err.response.status) || 500;
        })
        .finally(() => {
          setGroupedTitulos([...groupedTitulos, ...parsedTitles]);
          setFiles([...files, ...parsedFiles]);
          setIsUploading(false);
        });
    });
  };

  const filesRejectedHandler = file => {
    const formikBag = form.getFormikBag();
    const _fileName = file[0].name;
    const _fileTypesAccepted = getFilesToAccept(formikBag.values);
    const _extension = _fileName.split('.');
    let _errorMessage;
    // let borderoTipoTitulo = formikBag.values.tipoTitulo.code;

    if (_fileTypesAccepted.indexOf(`.${_extension[_extension.length - 1]}`) === -1) {
      if (clienteAtual.clienteEspecial) {
        _errorMessage = `Para clientes que emitem o próprio boleto, o tipo de arquivo ${_extension[
          _extension.length - 1
        ].toUpperCase()} não é permitido`;
      } else if (clienteAtual && clienteAtual.clienteOperaConnect) {
        _errorMessage = `Para clientes que operam via Connect, o tipo de arquivo ${_extension[
          _extension.length - 1
        ].toUpperCase()} não é permitido`;
      } else if (bordero.tipoTitulo === 'DS') {
        _errorMessage = `Para duplicatas de serviço o tipo de arquivo ${_extension[
          _extension.length - 1
        ].toUpperCase()} não é permitido`;
      } else if (primeiraOperacaoOnboarding) {
        _errorMessage = `ARQUIVO DO TIPO ${_extension[
          _extension.length - 1
        ].toUpperCase()} NÃO É PERMITIDO!`;
      } else {
        _errorMessage = `O tipo de arquivo ${_extension[
          _extension.length - 1
        ].toUpperCase()} não é permitido`;
      }
    } else {
      _errorMessage = 'Arquivo ultrapassa o tamanho máximo de 10MB';
    }

    swal({
      title: 'Atenção!',
      text: _errorMessage,
      icon: 'error',
      dangerMode: true
    });
  };

  const retryUpload = file => {
    setIsUploading(true);

    uploadFile(
      file.data,
      progressValue => {
        file.progress = progressValue;
        file.statusCode = 0;
        setFiles([...files]);
      },
      clienteAtual.clienteEspecial,
      form.state.values.tipoOperacao.code,
      clienteAtual.clienteOperaConnect,
      primeiraOperacaoOnboarding,
      clienteAtual.liberaXmlRedesconto
    )
      .then(res => {
        file.response = res.data;
        file.statusCode = res.status;
        const parsedTitle = { fileId: file.id, data: res.data.titulos };

        parsedTitle.data.forEach(titulo => {
          if (titulo.cheque && titulo.cheque.cmc7) {
            titulo.cheque.cmc7 = titulo.cheque.cmc7.replace(/\s/g, '');
          }
        });

        setGroupedTitulos([...groupedTitulos, parsedTitle]);
      })
      .catch(err => {
        file.response = err.response;
        file.statusCode = (err.response && err.response.status) || 400;
      })
      .finally(() => {
        setFiles([...files]);
        setIsUploading(false);
      });
  };

  const showFileRows = () => {
    if (files.length) {
      return (
        <div className="file-wrapper">
          {files.map(file => (
            <FileRow key={file.id} file={file} deleteFile={deleteFile} retryUpload={retryUpload} />
          ))}
        </div>
      );
    }
  };

  const toggleModalGiroRapido = () => {
    setModalGiroRapido(!modalGiroRapido);
  };

  const toggleModalNovaConta = () => {
    setModalNovaConta(!modalNovaConta);
  };

  const handleFormSubmit = async (values, form) => {
    let isValid = false;
    await swal({
      title: 'Atenção!',
      text: 'Ao avançar a conta corrente não poderá ser alterada. Deseja continuar?',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar']
    }).then(confirm => {
      if (confirm) {
        isValid = true;
      } else {
        form.setSubmitting(false);
      }
    });

    setMessage();
    const contaCorrente = dominios.contasCorrentes.find(option => option.id === values.contaCorrente.id);
    const contaEscrow = dominios.contasBoletoEscrow.find(option => option.id === values.contaEscrow.id);

    const newBordero = { ...bordero, ...values, contaCorrente, contaEscrow };

    if (isValid) {
      if (
        newBordero.contaCorrente &&
        newBordero.contaCorrente.liberado === 'N' &&
        parseInt(newBordero.tipoEmpresa.code) === TIPO_EMPRESA.FIDC
      ) {
        setMessage(
          `Conta Corrente "${newBordero.contaCorrente.descricao}" não liberada para operação FIDC`
        );
        return form.setSubmitting(false);
      }
      updateStateBordero(newBordero);

      if (newBordero.tipoOperacao && newBordero.tipoOperacao.code === 'GR') {
        form.setSubmitting(false);
        return toggleModalGiroRapido(newBordero);
      } else if (
        newBordero.tipoOperacao &&
        (newBordero.tipoOperacao.code === 'GB' || newBordero.tipoOperacao.code === 'GP')
      ) {
        form.setSubmitting(false);
        return history.push({
          pathname: '/nova-entrada',
          state: { codContacorrente: values.contaCorrente.id, codEntrada: null, novaEntrada: true }
        });
      } else {
        return saveBordero(newBordero, form).catch(err => {
          const {
            response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
          } = err;
          setMessage(message);
          form.setSubmitting(false);
        });
      }
    }
  };

  const saveBordero = (values, form) => {
    const newBordero = { ...bordero, ...values };
    let titulosFiltrados = [];

    if (newBordero.tipoTitulo.code === 'DM' || newBordero.tipoTitulo.code === 'DS') {
      titulosFiltrados = titulos.filter(titulo => titulo.duplicata);
    } else if (newBordero.tipoTitulo.code === 'CH') {
      titulosFiltrados = titulos.filter(titulo => titulo.cheque);
    }

    return saveOrUpdateBordero(newBordero).then(res => {
      const borderoCreated = res.data;
      const { bordero_id } = match.params;
      const newBorderoID = res.data.id;

      if (
        borderoCreated.tipoOperacao.code === 'GR' ||
        borderoCreated.tipoOperacao.code === 'GB' ||
        borderoCreated.tipoOperacao.code === 'GP'
      ) {
        return history.push(`/borderos`);
      }

      if (bordero_id === 'new') {
        history.push(`/borderos/${newBorderoID}`);
      }

      if (titulosFiltrados.length) {
        return saveBorderoTitulo(borderoCreated.id, titulosFiltrados)
          .then(() => {
            updateStateBordero(borderoCreated);
            nextStep();
          })
          .catch(err => {
            const {
              response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
            } = err;
            setMessage(message);
            form.setSubmitting(false);
          });
      } else {
        updateStateBordero(borderoCreated);
        nextStep();
      }
      return res;
    });
  };

  const contaViaJornadaDigital = () => {
    return searchContasViaJornadaDigital(clienteAtual ? clienteAtual.cnpj : '')
      .then(resContaJornada => {
        if (resContaJornada && resContaJornada.data && resContaJornada.data.onboarding) {
          setContaViaJornada(true);
          setPrimeiraOperacaoOnboarding(
            resContaJornada.data.total_operacoes && resContaJornada.data.total_operacoes > 0
              ? false
              : contaViaJornada
          );
        } else {
          setContaViaJornada(false);
        }
      })
      .catch(err => {
        const {
          response: { data: { error: { message = 'Erro ao buscar os dados' } = {} } = {} } = {}
        } = err;
        setMessage(message);
        setIsPending(false);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const getFilesToAccept = values => {
    let generalFiles = ['.txt', '.zip', '.rar', '.rem'];

    if (clienteAtual && clienteAtual.clienteOperaConnect && !clienteAtual.liberaXmlRedesconto) {
      return generalFiles;
    } else {
      if (clienteAtual && clienteAtual.clienteEspecial) {
        if (
          values.tipoTitulo.code === 'CH' ||
          values.tipoOperacao.code === 'CM' ||
          values.tipoOperacao.code === 'ES'
        ) {
          return [...generalFiles, '.xml', '.xls'];
        }
        return generalFiles;
      }

      if (values.tipoTitulo.code === 'DS') {
        return [...generalFiles, '.xls'];
      } else if (clienteAtual && primeiraOperacaoOnboarding) {
        generalFiles = ['.xml', '.zip', '.rar'];
        return [...generalFiles, '.xml', '.zip', '.rar'];
      }
      return [...generalFiles, '.xml', '.xls'];
    }
  };

  return (
    <>
      <div className={`upload-step ${isParentPending || isPending ? 'pending' : ''}`}>
        <Formik
          ref={node => setForm(node)}
          initialValues={initialValues}
          validationSchema={validationSchema()}
          onSubmit={handleFormSubmit}
          render={({ values, setFieldValue, isSubmitting }) => {
            return (
              <Form className={isParentPending || isSubmitting || isPending ? 'is-submitting' : ''}>
                <div className="body">
                  <Row className="margin-zero">
                    <Col md="12" lg="12">
                      <h1 className="title">Defina sua operação</h1>
                    </Col>
                    {process.env.REACT_APP_ALERTA_BORDERO &&
                      process.env.REACT_APP_ALERTA_BORDERO !== '' && (
                        <Col md="12" lg="12">
                          <div className="box-aviso-qcertifica">
                            <div className="label-alert">
                              <img
                                src={ExclamationIcon}
                                className=" alert-icon"
                                alt="exclamation"
                                width="25"
                              />
                              Atenção!
                            </div>
                            <div className="text-alert layout-pre">
                              {process.env.REACT_APP_ALERTA_BORDERO.replace(/\\n/g, '\n')}
                            </div>
                          </div>
                        </Col>
                      )}
                    <Col md="12" lg="12">
                      {message && (
                        <div className="alert alert-danger animated fadeIn" role="alert">
                          {message}
                        </div>
                      )}
                      <SkeletonLoader
                        isPending={isParentPending || isPending}
                        width="60%"
                        height="30px"
                        count={8}
                      >
                        <Row className="margin-zero">
                          <Col xl="10" lg="8" md="12">
                            <Field
                              label="Conta corrente"
                              name="contaCorrente.id"
                              options={contaCorrenteForSelect(dominios.contasCorrentes)}
                              component={Select}
                              disabled={isParentPending || isPending || isSubmitting || isUploading}
                              placeholder=""
                              autoComplete="off"
                              isClearable={false}
                            />

                            {values.contaCorrente.id && values.contaCorrente.id !== null && (
                              <div className="alerta-conta-corrente">
                                <i className="fas fa-comment-alt-exclamation info-icon" />
                                Atenção ao prosseguir com a entrada a conta corrente não poderá ser
                                alterada.
                              </div>
                            )}
                          </Col>
                          <Col xl="2" lg="4" md="12" className="mt-4">
                            {usuario === null ||
                              usuario.tipoOnboardingPlataforma === undefined ||
                              (usuario.tipoOnboardingPlataforma !== 'connect' &&
                                usuario.tipoOnboardingPlataforma !== 'indicantes' && (
                                  <Button
                                    outline
                                    color="primary"
                                    size="lg"
                                    block
                                    disabled={isParentPending || isPending || isSubmitting}
                                    onClick={() => toggleModalNovaConta()}
                                  >
                                    + Nova Conta
                                  </Button>
                                ))}
                          </Col>
                        </Row>
                        <Row className="margin-zero">
                          <Col lg="3" md="6" className="mt-2">
                            <Label className="custom-label">Cliente: </Label>
                            <h4 className="company-name">
                              {clienteAtual && (clienteAtual.razaoSocial || clienteAtual.nomeFantasia)}
                            </h4>
                          </Col>
                          <Col lg="3" md="6" className="mt-2">
                            <FormGroup tag="fieldset">
                              <legend>Tipo de operação:</legend>
                              {dominios.tiposOperacoes && dominios.tiposOperacoes.length > 0 ? (
                                dominios.tiposOperacoes.map(tipoOperacao => {
                                  return (
                                    <Field
                                      key={tipoOperacao.code}
                                      name="tipoOperacao"
                                      fieldError="code"
                                      checked={
                                        values.tipoOperacao &&
                                        values.tipoOperacao.code === tipoOperacao.code
                                      }
                                      label={tipoOperacao.descricao}
                                      value={values.tipoOperacao}
                                      disabled={
                                        isParentPending || isPending || isSubmitting || isUploading
                                      }
                                      onChange={() => {
                                        setFieldValue('tipoOperacao', tipoOperacao);
                                        validateSelectedValues(
                                          values,
                                          tipoOperacao,
                                          setFieldValue,
                                          dominios
                                        );
                                      }}
                                      autoComplete="off"
                                      component={Radio}
                                    />
                                  );
                                })
                              ) : (
                                <>
                                  Não existe produtos ativos.
                                  <br />
                                  Entre em contato com seu Gerente Comercial
                                </>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="3" md="6" className="mt-2">
                            {values.tipoOperacao &&
                            (values.tipoOperacao.code !== 'SC' ||
                              (values.tipoOperacao.code === 'SC' && values.contaEscrow.id)) ? (
                              <FormGroup tag="fieldset">
                                <legend>Tipo de Empresa:</legend>
                                {values.tipoOperacao && values.tipoOperacao.code
                                  ? dominios.tiposEmpresas &&
                                    dominios.tiposEmpresas.map(tipoEmpresa => {
                                      let empresasPermitidas =
                                        values.tipoOperacao &&
                                        values.tipoOperacao.code &&
                                        dominios.tiposOperacoesObj[values.tipoOperacao.code] !==
                                          undefined
                                          ? dominios.tiposOperacoesObj[values.tipoOperacao.code]
                                              .tiposEmpresasPermitidos
                                          : [];

                                      let contaScrowSelecionada = [];
                                      if (values.tipoOperacao && values.tipoOperacao.code) {
                                        if (values.tipoOperacao.code === 'SC') {
                                          contaScrowSelecionada = dominios.contasBoletoEscrow.find(
                                            conta => conta.id === values.contaEscrow.id
                                          );
                                          empresasPermitidas = contaScrowSelecionada.empresas.filter(
                                            value => empresasPermitidas.includes(value)
                                          );
                                        } else if (values.tipoOperacao.code === 'ES') {
                                          empresasPermitidas = dominios.empresasEscrowSimples.filter(
                                            value => empresasPermitidas.includes(value)
                                          );
                                        }
                                      }
                                      const tipoOperacaoPermiteTipoEmpresa =
                                        values.tipoOperacao &&
                                        empresasPermitidas.includes(tipoEmpresa.code);

                                      return (
                                        tipoOperacaoPermiteTipoEmpresa && (
                                          <Field
                                            key={tipoEmpresa.code}
                                            name="tipoEmpresa"
                                            checked={
                                              values.tipoEmpresa &&
                                              +values.tipoEmpresa.code === +tipoEmpresa.code
                                            }
                                            fieldError="code"
                                            label={tipoEmpresa.descricao}
                                            value={values.tipoEmpresa}
                                            onChange={() => {
                                              setFieldValue('tipoEmpresa', tipoEmpresa);
                                              validateSelectedValuesForCompany(
                                                values,
                                                tipoEmpresa,
                                                setFieldValue
                                              );
                                            }}
                                            disabled={
                                              !tipoOperacaoPermiteTipoEmpresa ||
                                              isParentPending ||
                                              isPending ||
                                              isSubmitting ||
                                              isUploading
                                            }
                                            autoComplete="off"
                                            component={Radio}
                                          />
                                        )
                                      );
                                    })
                                  : dominios.tiposEmpresas
                                  ? dominios.tiposEmpresas.map(tipoEmpresa => {
                                      return (
                                        <Field
                                          key={tipoEmpresa.code}
                                          label={tipoEmpresa.descricao}
                                          name="tipoEmpresaDisabled"
                                          value={false}
                                          checked={false}
                                          disabled={true}
                                          component={Radio}
                                        />
                                      );
                                    })
                                  : 'Nenhuma empresa parametrizada, favor contatar o suporte '}
                              </FormGroup>
                            ) : (
                              ''
                            )}
                          </Col>

                          <Col lg="2" md="6" className="mt-2">
                            {values.tipoOperacao &&
                            (values.tipoOperacao.code !== 'SC' ||
                              (values.tipoOperacao.code === 'SC' && values.contaEscrow.id)) ? (
                              <FormGroup
                                tag="fieldset"
                                style={{
                                  opacity:
                                    values.tipoOperacao &&
                                    values.tipoOperacao.tiposDocumentosPermitidos &&
                                    values.tipoOperacao.tiposDocumentosPermitidos.length === 0
                                      ? 0.38
                                      : 1
                                }}
                              >
                                <legend>Tipo de documento:</legend>
                                {dominios.tiposTitulos &&
                                  dominios.tiposTitulos.map(tipoTitulo => {
                                    const tipoOperacaoPermiteTipoTitulos =
                                      values.tipoOperacao &&
                                      values.tipoOperacao.tiposDocumentosPermitidos &&
                                      !values.tipoOperacao.tiposDocumentosPermitidos.includes(
                                        tipoTitulo.code
                                      );
                                    const tipoEmpresaPermiteTipoDocumento =
                                      values.tipoEmpresa &&
                                      values.tipoEmpresa.tiposDocumentosPermitidos &&
                                      !values.tipoEmpresa.tiposDocumentosPermitidos.includes(
                                        tipoTitulo.code
                                      );
                                    return (
                                      <Field
                                        key={tipoTitulo.code}
                                        name="tipoTitulo"
                                        checked={
                                          values.tipoTitulo && values.tipoTitulo.code === tipoTitulo.code
                                        }
                                        label={tipoTitulo.descricao}
                                        fieldError="code"
                                        value={values.tipoTitulo}
                                        onChange={() => {
                                          setFiles([]);
                                          setTitulos([]);
                                          setGroupedTitulos([]);
                                          setFieldValue('contratoFisico', {
                                            code: 'N',
                                            descricao: 'NÃO'
                                          });
                                          return setFieldValue('tipoTitulo', tipoTitulo);
                                        }}
                                        disabled={
                                          tipoOperacaoPermiteTipoTitulos ||
                                          tipoEmpresaPermiteTipoDocumento ||
                                          isParentPending ||
                                          isPending ||
                                          isSubmitting ||
                                          isUploading
                                        }
                                        autoComplete="off"
                                        component={Radio}
                                      />
                                    );
                                  })}
                              </FormGroup>
                            ) : (
                              ''
                            )}
                          </Col>

                          {values &&
                          values.tipoTitulo &&
                          values.tipoTitulo.code &&
                          values.tipoTitulo.code === 'DM' ? (
                            <Col lg="1" md="6" className="mt-2">
                              <FormGroup tag="fieldset">
                                <legend>Contrato Físico?</legend>
                                <Field
                                  key="contratoFisicoSim"
                                  name="contratoFisico"
                                  fieldError="code"
                                  label="Sim"
                                  checked={values.contratoFisico && values.contratoFisico.code === 'S'}
                                  value={{ code: 'S', descricao: 'Sim' }}
                                  disabled={isParentPending || isPending || isSubmitting || isUploading}
                                  onChange={() => {
                                    setFieldValue('contratoFisico', { code: 'S', descricao: 'Sim' });
                                  }}
                                  autoComplete="off"
                                  component={Radio}
                                />
                                <Field
                                  key="contratoFisicoNao"
                                  name="contratoFisico"
                                  fieldError="code"
                                  label="Não"
                                  checked={values.contratoFisico && values.contratoFisico.code === 'N'}
                                  value={{ code: 'N', descricao: 'Não' }}
                                  disabled={isParentPending || isPending || isSubmitting || isUploading}
                                  onChange={() => {
                                    setFieldValue('contratoFisico', { code: 'N', descricao: 'Não' });
                                  }}
                                  autoComplete="off"
                                  component={Radio}
                                />
                              </FormGroup>
                            </Col>
                          ) : (
                            ''
                          )}

                          {values &&
                          values.tipoOperacao &&
                          values.tipoOperacao.code &&
                          values.tipoOperacao.code === 'SC' ? (
                            <Col md="6" lg="6">
                              <Row className="margin-zero">
                                <Col md="12" lg="12">
                                  <Field
                                    label="Conta para cobrança Escrow"
                                    name="contaEscrow.id"
                                    options={contaCorrenteForSelect(dominios.contasBoletoEscrow)}
                                    component={Select}
                                    onChange={() => {
                                      setFieldValue('tipoEmpresa', {});
                                    }}
                                    disabled={
                                      isParentPending || isPending || isSubmitting || isUploading
                                    }
                                    placeholder=""
                                    autoComplete="off"
                                    isClearable={false}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          ) : (
                            ''
                          )}

                          <Col md="12" lg="12">
                            <Row className="margin-zero">
                              <Col md="12" lg="12">
                                <Label className="custom-label mb-3">Importe seus arquivos: </Label>
                              </Col>
                              <Col md="12" lg="12">
                                <Dropzone
                                  onDropAccepted={filesAcceptedHandler}
                                  onDropRejected={filesRejectedHandler}
                                  disabled={
                                    values.tipoOperacao.code === 'GR' ||
                                    values.tipoOperacao.code === 'GB' ||
                                    values.tipoOperacao.code === 'GP' ||
                                    isParentPending ||
                                    isPending ||
                                    isSubmitting
                                  }
                                  noDrag={false}
                                  accept={getFilesToAccept(values)}
                                  maxSize={TAMANHO_MAXIMO_UPLOAD}
                                >
                                  {({ getRootProps, getInputProps, isDragActive }) => (
                                    <section>
                                      <div
                                        {...getRootProps()}
                                        className={`dropzone ${
                                          values.tipoOperacao.code === 'GR' ||
                                          values.tipoOperacao.code === 'GB' ||
                                          values.tipoOperacao.code === 'GP'
                                            ? 'disabled'
                                            : ''
                                        }`}
                                      >
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                          <h4>Solte o arquivo aqui...</h4>
                                        ) : (
                                          <h4>
                                            <span>
                                              <i className="fas fa-paperclip file-icon" />
                                            </span>
                                            <span className="link">Selecione</span>
                                            <span> o arquivo ou arraste aqui</span>
                                          </h4>
                                        )}
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                {showFileRows()}
                                {values.tipoOperacao.code !== 'GR' &&
                                values.tipoOperacao.code !== 'GB' &&
                                values.tipoOperacao.code !== 'GP' ? (
                                  <>
                                    <p>
                                      <span>
                                        <i className="far fa-info-circle info-icon" />
                                      </span>
                                      A inclusão de títulos manuais poderá ser realizada na etapa de
                                      Seleção.
                                    </p>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </SkeletonLoader>
                    </Col>
                  </Row>
                </div>
                <div className="footer d-flex justify-content-between">
                  {currentStep <= 1 ? (
                    <Link
                      className="mr-auto btn-back btn btn-outline-primary btn-lg"
                      to="/Borderos"
                      disabled={isSubmitting || isParentPending || isPending}
                    >
                      <i className="svg-icon">
                        <VoltarIcon />
                      </i>
                      Voltar
                    </Link>
                  ) : (
                    <Button
                      className="mr-auto btn-back"
                      size="lg"
                      outline={true}
                      color="primary"
                      type="button"
                      onClick={previousStep}
                      disabled={isSubmitting}
                    >
                      <i className="svg-icon">
                        <VoltarIcon />
                      </i>
                      Voltar
                    </Button>
                  )}
                  <Button
                    size="lg"
                    className="ml-3"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || isUploading}
                  >
                    {!isSubmitting && (
                      <>
                        <i className="svg-icon">
                          <Avancar />
                        </i>
                        Avançar
                      </>
                    )}
                    {isSubmitting && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        />
                        Avançando
                      </>
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        {modalGiroRapido && (
          <ModalGiroRapido
            bordero={bordero}
            saveBordero={saveBordero}
            toggle={toggleModalGiroRapido}
            modal={modalGiroRapido}
          />
        )}
        {modalNovaConta && (
          <ModalNovaConta
            modal={modalNovaConta}
            toggle={toggleModalNovaConta}
            setContaCorrente={setContaCorrente}
            clienteAtual={clienteAtual}
          />
        )}

        <Modal
          isOpen={modalAlertaBordero.show}
          toggle={() => toggle(null)}
          centered
          size="lg"
          className="modalAlertaBordero"
        >
          <ModalHeader toggle={() => toggle(null)} className="botaoX" />
          <ModalBody className="imgBackGround" />
        </Modal>
      </div>
      <HistoricoAcesso codTela={1195} />
    </>
  );
};

export default memo(UploadStep);
