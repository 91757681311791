import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from 'react-router-dom';
import MainOnlyHeader from '../../../Main/MainOnlyHeader';
import ButtonMain from '../../shared/ButtonMain';
import InputMain from '../../shared/InputMain/InputMain';
import { postVerificaTokenCadastro, postReenviaTokenCadastro } from '../../../../api/onboarding';
import {
  getEmailValidaToken,
  removeEmailValidaToken,
  getTelaRequisitoraToken,
  removeTelaRequisitoraToken
} from '../../../../shared/services/User';

export default () => {
  const history = useHistory();
  const [isPending, setPending] = useState(false);
  const [validacaoCodigoVerificacao, setValidacaoCodigoVerificacao] = useState(false);
  const [open, setOpen] = useState(false);
  const [msgCodigoVerificacao, setMsgCodigoVerificacao] = useState('');
  const [contador, setContador] = useState(false);
  const [controle, setControle] = useState(false);
  const [bloqueiaBotaoConfirmar, setBloqueiaBotaoConfirmar] = useState(true);
  const [emailValidaToken, setEmailValidaToken] = useState(false);
  const [emailMascarado, setEmailMascarado] = useState('');
  const [telaRequisitora, setTelaRequisitora] = useState('login');

  useEffect(() => {
    let email = getEmailValidaToken();
    if (email) {
      setEmailValidaToken(email);
    } else {
      history.push('/authentication/login');
    }
    let telaRequisitoraToken = getTelaRequisitoraToken();
    if (telaRequisitoraToken) {
      setTelaRequisitora(telaRequisitoraToken);
      removeTelaRequisitoraToken();
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    escondeEmail(emailValidaToken);
    removeEmailValidaToken();
  }, [emailValidaToken]);

  const handleFormSubmit = (values, form) => {
    setPending(true);
    values.codigo_verificacao = values.codigo_verificacao.replaceAll(' ', '');

    let params = {
      token: values.codigo_verificacao.toUpperCase(),
      email: emailValidaToken
    };

    postVerificaTokenCadastro(params)
      .then(retorno => {
        if (retorno.status === 202) {
          setMsgCodigoVerificacao('');
          setValidacaoCodigoVerificacao(true);
        } else {
          setMsgCodigoVerificacao('O código inserido está incorreto');
          setValidacaoCodigoVerificacao(false);
        }
      })
      .catch(() => {
        setMsgCodigoVerificacao('O código inserido está incorreto');
        setValidacaoCodigoVerificacao(false);
      })
      .finally(() => {
        setPending(false);
        form.setSubmitting(false);
        return false;
      });
  };

  const reenviarTokenCadastro = () => {
    let params = {
      email: emailValidaToken
    };

    postReenviaTokenCadastro(params);
  };

  const disparaContador = () => {
    reenviarTokenCadastro();
    setContador(true);
    setOpen(false);
  };

  const limpaMsgErro = event => {
    setMsgCodigoVerificacao('');
    if (event.target.value !== '') {
      setBloqueiaBotaoConfirmar(false);
    } else {
      setBloqueiaBotaoConfirmar(true);
    }
  };

  const acessarProximaTela = () => {
    if (telaRequisitora === 'recuperar-senha') {
      history.push('/authentication/forgot-password');
    } else {
      history.push('/authentication/login');
    }
  };

  useEffect(() => {
    if (contador && !controle) {
      let display = document.getElementById('timer');
      contadorDecrescente(300, display);
      setControle(true);
    }
  });

  const contadorDecrescente = (timer, elemento) => {
    let minutes, seconds;
    var meuInterval = setInterval(function() {
      try {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        elemento.textContent = minutes + ':' + seconds;
        timer--;

        if (timer < -1) {
          pararContagemRegressiva(meuInterval);
        }
      } catch (err) {
        pararContagemRegressiva(meuInterval);
      }
    }, 1000);
  };

  const pararContagemRegressiva = meuInterval => {
    clearInterval(meuInterval);
    setContador(false);
    setControle(false);
  };

  const modalReenviar = acao => {
    setOpen(acao);
  };

  const escondeEmail = value => {
    let emailEscondido = '*********@******.com';
    if (value && value !== undefined && value !== '') {
      let index = value.indexOf('@');
      if (index >= 0) {
        let stringInicial = value.substring(0, index);
        let stringFinal = value.substring(index);
        let manter = stringInicial.slice(-3);
        let remover = stringInicial.replace(manter, '');
        let stringEscondida = manter.padStart(remover.length + 3, '*');
        emailEscondido = stringEscondida + stringFinal;
      }
    }
    setEmailMascarado(emailEscondido);
  };

  return (
    <MainOnlyHeader>
      <Container fixed>
        <Grid container>
          <Grid item xl={2} lg={2} md={2} sm={1} xs={12} />
          <Grid item xl={8} lg={8} md={8} sm={10} xs={12}>
            <Card variant="outlined" className="box-shadow-natural-5 border-radius-8">
              <CardContent>
                <Grid container>
                  <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                  <Grid item xl={8} lg={8} md={8} sm={10} xs={10} sx={{ p: '50px 0 100px 0' }}>
                    {validacaoCodigoVerificacao === false ? (
                      <>
                        {telaRequisitora === 'login' ? (
                          <Typography
                            variant="h5"
                            className="texto-cor-principal texto-negrito texto-centro"
                          >
                            Antes de logar é necessário validar o seu email conosco, por isso lhe
                            enviamos um e-mail com um código...
                          </Typography>
                        ) : telaRequisitora === 'recuperar-senha' ? (
                          <Typography
                            variant="h5"
                            className="texto-cor-principal texto-negrito texto-centro"
                          >
                            Antes de recuperar sua senha é necessário validar o seu email conosco, por
                            isso lhe enviamos um e-mail com um código...
                          </Typography>
                        ) : (
                          // VEIO DO CADASTRO
                          <Typography
                            variant="h5"
                            className="texto-cor-principal texto-negrito texto-centro"
                          >
                            Agora digite o código que enviamos para o seu email...
                          </Typography>
                        )}
                        <Typography
                          variant="subtitle1"
                          className="texto-cor-cinza-escuro pad-10 texto-centro mt-1"
                        >
                          <span>Verifique o e-mail </span>
                          <b>{emailMascarado}</b>
                          <span> e digite o código abaixo para confirmar seu cadastro</span>
                        </Typography>

                        <Formik
                          initialValues={{
                            codigo_verificacao: ''
                          }}
                          validationSchema={Yup.object().shape({
                            codigo_verificacao: Yup.string()
                              .required('Informe o código.')
                              .nullable()
                              .test({
                                exclusive: true,
                                message: 'Digite o código enviado',
                                name: 'codigo_verificacao',
                                test: value => {
                                  if (value !== '' && value !== undefined) {
                                    value = value.replaceAll(' ', '');
                                    if (value.length === 6) {
                                      return true;
                                    }
                                  }
                                  return false;
                                }
                              })
                          })}
                          onSubmit={handleFormSubmit}
                          validateOnChange={false}
                          validateOnBlur={false}
                          render={({ isSubmitting }) => {
                            return (
                              <>
                                <Form>
                                  <Grid container className="container-input-confirmacao-codigo">
                                    <Grid item xl={3} lg={3} md={2} sm={2} xs={1} />
                                    <Grid item xl={6} lg={6} md={8} sm={8} xs={10}>
                                      <Field
                                        type="text"
                                        name="codigo_verificacao"
                                        id="codigo_verificacao"
                                        size="small"
                                        inputProps={{ maxLength: 7 }}
                                        variant="standard"
                                        inputClassName="w-100 input-confirmacao-codigo"
                                        className="w-100 font-size-32"
                                        boxClassName="pad-topbot-30"
                                        placeholder="---  ---"
                                        uppercase={true}
                                        erro={msgCodigoVerificacao}
                                        component={InputMain}
                                        autoComplete="off"
                                        onChangeInput={limpaMsgErro}
                                        disabled={isPending || isSubmitting}
                                        tipoMascara="CodigoConfirmacaoMask"
                                      />
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={2} sm={2} xs={1} />
                                  </Grid>

                                  <Grid container spacing={2} className="pad-topbot-10">
                                    <Grid item xl={3} lg={3} md={2} sm={2} xs={12} />
                                    <Grid item xl={6} lg={6} md={8} sm={8} xs={12}>
                                      <ButtonMain
                                        type="submit"
                                        tipoBotao="principal"
                                        className="btn-confirmar-codigo"
                                        disabled={isPending || isSubmitting || bloqueiaBotaoConfirmar}
                                      >
                                        Confirmar
                                      </ButtonMain>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={2} sm={2} xs={12} />
                                  </Grid>
                                  <Grid container className="pad-topbot-10">
                                    <Grid item xl={4} lg={4} md={3} sm={2} xs={12} />
                                    <Grid item xl={4} lg={4} md={6} sm={8} xs={12}>
                                      {contador === false ? (
                                        <ButtonMain
                                          tipoBotao="transparente"
                                          disabled={isPending || isSubmitting}
                                          onClick={() => modalReenviar(true)}
                                        >
                                          Reenviar código
                                        </ButtonMain>
                                      ) : (
                                        <div className="texto-cor-cinza-escuro texto-centro">
                                          <span>Aguarde para enviar novamente em </span>
                                          <b>
                                            <span id="timer" className="texto-cor-principal">
                                              05:00
                                            </span>
                                          </b>
                                        </div>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Dialog
                                    open={open}
                                    keepMounted
                                    onClose={() => modalReenviar(false)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                  >
                                    <DialogTitle
                                      id="alert-dialog-title"
                                      className="texto-cor-principal texto-negrito"
                                    >
                                      Reenviar código
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        Deseja realmente reenviar o código de confirmação?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Grid container>
                                        <Grid item xl={4} lg={4} md={4} sm={2} />
                                        <Grid item xl={4} lg={4} md={4} sm={5} xs={6}>
                                          <ButtonMain
                                            tipoBotao="transparente"
                                            onClick={() => modalReenviar(false)}
                                          >
                                            Cancelar
                                          </ButtonMain>
                                        </Grid>
                                        <Grid item xl={4} lg={4} md={4} sm={5} xs={6}>
                                          <ButtonMain
                                            tipoBotao="principal"
                                            classesAdicional="btn-reenviar-codigo"
                                            onClick={disparaContador}
                                            autoFocus
                                          >
                                            Reenviar
                                          </ButtonMain>
                                        </Grid>
                                      </Grid>
                                    </DialogActions>
                                  </Dialog>
                                </Form>
                              </>
                            );
                          }}
                        />
                      </>
                    ) : (
                      <Grid container spacing={0} className="pad-topbot-20">
                        <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                        <Grid item xl={8} lg={8} md={8} sm={10} xs={10}>
                          <div className="texto-centro">
                            <CheckCircleIcon
                              color="success"
                              className="font-size-45"
                              sx={{ color: '#54A900' }}
                            />
                          </div>

                          <Typography
                            variant="h5"
                            className="mt-3 texto-cor-principal texto-negrito texto-centro"
                          >
                            {telaRequisitora === 'cadastro'
                              ? 'Cadastro realizado'
                              : 'E-mail validado com sucesso!'}
                          </Typography>

                          <Typography
                            variant="subtitle1"
                            className="texto-cor-cinza-escuro pad-10 texto-centro"
                          >
                            {telaRequisitora === 'recuperar-senha'
                              ? 'Agora é só clicar abaixo para continuar a recuperação de sua senha'
                              : 'Utilize o e-mail e a senha cadastrados para acessar sua conta.'}
                          </Typography>

                          <Grid container spacing={4} className="mt-1">
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={12} />
                            <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                              <ButtonMain tipoBotao="principal" onClick={acessarProximaTela}>
                                Acessar
                              </ButtonMain>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MainOnlyHeader>
  );
};
