export default {
  RES: 'fa-book',
  AGR: 'fa-book',
  REN: 'fa-recycle',
  ESTADOSPROPONDO: 'fa-check-circle',
  ABO: 'fa-solid fa-file-pdf',
  ANA: 'fa fa-check icon',
  PDG: 'fa-reply',
  DPI: 'fa-reply',
  CPD: 'fa-reply',
  BDG: 'fa-reply',
  CDP: 'fa-reply-all',
  CBG: 'fa-reply-all',
  REJ: 'fa-times-circle-o',
  CAN: 'fa-times-circle',
  EXP: 'fa-hourglass-o',
  INA: 'fa-ban',
  CRE: 'fa-hourglass-start',
  CAU: 'fa-university',
  CPI: 'fa-info-circle',
  CAF: 'fa-usd',
  CAP: 'fa-shopping-bag',
  FCR: 'fa fa-info-circle info-icon',
  COM: 'fa-users',
  REP: 'fa-flag-o',
  FIM: 'fa-flag-checkered',
  RA: 'fa-retweet border-all border-black all-radius padXs',
  CDG: 'fa-share-square-o fa-flip-horizontal',
  CDB: 'fa fa-paperclip file-icon',
  CLI: 'fa-user-circle-o'
};
