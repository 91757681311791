import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { getDadosUsuario, getMostraModalNovidades, setMostraModalNovidades } from '../../../shared';
import {
  getMostrarModalPesquisaNovidades,
  cadastraUsuarioPesquisaNovidades
} from '../../../api/dashboard';
import ButtonMain from '../../Onboarding/shared/ButtonMain';

const ModalNovidades = () => {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const msgPrincipal = process.env.REACT_APP_ALERTA_PRINCIPAL
    ? process.env.REACT_APP_ALERTA_PRINCIPAL.replace(/\\n/g, '\n').split(/(<b>.*?<\/b>)/)
    : [];

  useEffect(() => {
    if (!getMostraModalNovidades()) {
      getMostrarModalPesquisaNovidades()
        .then(res => {
          if (res.data && res.data.usuario) {
            handleClose();
          } else {
            setTimeout(() => {
              handleOpen();
            }, 10000);
          }
        })
        .catch(() => {});
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const finalizaModalNovidades = () => {
    let user = getDadosUsuario();
    let params = {
      nome: user && user.nome ? user.nome : '',
      nivel: user && user.nivel ? parseInt(user.nivel) : 0
    };
    cadastraUsuarioPesquisaNovidades(params);
    setMostraModalNovidades(true);
    handleClose();
  };

  const handleCloser = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    handleClose();
  };

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress >= 100) {
            clearInterval(timer);
          }
          return Math.min(oldProgress + 1.2, 100);
        });
      }, 50);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      keepMounted
      onClose={handleCloser}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {/* {progress !== 100 && <LinearProgress variant="determinate" value={progress} />} */}
        <Box className="texto-cor-complementar" sx={{ margin: '-16px -24px 0 -24px' }}>
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={progress}
            sx={{ height: '8px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
          />
        </Box>
        <Typography
          variant="h5"
          className="texto-centro texto-cor-principal texto-negrito"
          sx={{ margin: '30px 0 15px 0' }}
        >
          Atenção
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" className="texto-cor-preto layout-break-space line-height-23">
          <>
            {msgPrincipal.map((part, index) => {
              return index % 2 === 1 ? <b>{part.replace(/<\/?b>/g, '')}</b> : part;
            })}
          </>
        </Typography>
        <Box className="texto-centro w-100">
          <ButtonMain
            type="submit"
            tipoBotao="principal-texto-branco"
            disabled={progress !== 100}
            onClick={() => finalizaModalNovidades()}
            widthClass="w-auto"
            sx={{ marginTop: '25px' }}
          >
            OK, entendi
          </ButtonMain>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalNovidades;
