import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Card, CardContent, Container, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { KeyboardArrowDown } from '@mui/icons-material';
import MainOnlyHeader from '../../../Main/MainOnlyHeader';
import ButtonMain from '../../shared/ButtonMain';
import {
  getClientes,
  getClienteAtual,
  removeClienteAtual,
  getDadosUsuario
} from '../../../../shared/services/User';
import LoaderMain from '../../shared/LoaderMain';

export default props => {
  const { location: { state: { redirecionando } = {} } = {} } = props;
  const history = useHistory();
  const [cliente, setCliente] = useState(null);
  const [clientes, setClientes] = useState(null);
  const [valueClientes, setValueClientes] = useState('');
  const [pasted, setPasted] = useState(false);
  const [acessarDisabled, setAcessarDisabled] = useState(false);
  const [clienteRedirecionar, setClienteRedirecionar] = useState(false);
  const [isPending, setPending] = useState(false);

  const handleBtnAcessar = () => {
    setPending(true);
    if (cliente !== null) {
      setAcessarDisabled(true);
      let objCliente = getClienteAtual();
      if (typeof objCliente !== 'undefined' && objCliente !== null) {
        removeClienteAtual();
      }
      setPending(false);
      setClienteRedirecionar(cliente);
    }
    setPending(false);
  };

  const handleOnChangeCliente = (ev, newValue) => {
    setValueClientes(newValue && newValue.label ? newValue.label : '');
    if (
      typeof newValue === 'undefined' ||
      newValue == null ||
      newValue === 1 ||
      typeof newValue.cliente === 'undefined' ||
      typeof newValue.cliente.cnpj === 'undefined'
    ) {
      setCliente(null);
      return;
    } else {
      setCliente(newValue.cliente);
    }
  };

  useEffect(() => {
    let usuario = getDadosUsuario();
    let lstClientes = getClientes();
    if (typeof lstClientes !== 'undefined' && lstClientes !== null) {
      var lstCli = lstClientes.map(item => {
        return { label: `${item.cnpj} - ${item.razaoSocial}`, cliente: item };
      });
      setClientes(lstCli);

      // SE O USUARIO FOR CLIENTE (NIVEL 7) NAO REDIRECIONAR AUTOMATICAMENTE PARA O DASHBOARD COM O CLIENTE 'TODOS' SELECIONADO
      let naoRedirecionar = [7];
      if (
        usuario &&
        !naoRedirecionar.includes(usuario.nivel) &&
        redirecionando &&
        redirecionando === 'login'
      ) {
        setClienteRedirecionar({
          clienteEspecial: false,
          clienteOperaConnect: false,
          cnpj: 'todos',
          codCliente: 'todos',
          dadosBrokerCliente: null,
          id: 'todos',
          nomeFantasia: 'TODAS AS EMPRESAS',
          operaTrustee: 'N',
          razaoSocial: 'TODAS AS EMPRESAS'
        });
      }
    } else {
      history.push('/authentication/login');
    }
  }, []);

  const handleOnChange = event => {
    if (event && event.target) {
      let string = event && event.target && event.target.value ? event.target.value : '';
      if (event) {
        if (pasted) {
          string = string
            .replaceAll('.', '')
            .replace('/', '')
            .replace('-', '');
        }
      }
      setValueClientes(string);
    }
    setPasted(false);
  };

  const handlePaste = () => {
    setPasted(true);
  };

  return (
    <MainOnlyHeader {...props}>
      <Container fixed>
        <Grid container>
          <Grid item xl={2} lg={2} md={2} sm={1} xs={12} />
          <Grid item xl={8} lg={8} md={8} sm={10} xs={12}>
            <Card variant="outlined" className="box-shadow-natural-5 border-radius-8">
              <CardContent sx={isPending ? { position: 'relative' } : {}}>
                {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                {clienteRedirecionar && (
                  <Redirect
                    to={{
                      pathname: '/authentication/change-client',
                      state: { cliente: clienteRedirecionar, location: { pathname: '/dashboard' } }
                    }}
                  />
                )}
                <Grid container>
                  <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                  <Grid item xl={8} lg={8} md={8} sm={10} xs={10} sx={{ p: '50px 0 100px 0' }}>
                    <Typography
                      variant="h5"
                      className="texto-cor-principal texto-negrito texto-centro"
                      sx={{ p: { xl: '0 60px', lg: '0 60px' } }}
                    >
                      Qual empresa você quer acessar?
                    </Typography>

                    <Grid container spacing={2} className="pad-topbot-10">
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Autocomplete
                          disablePortal
                          id="selecao_cliente"
                          onChange={handleOnChangeCliente}
                          className="combobox_principal"
                          popupIcon={
                            <KeyboardArrowDown className="texto-cor-complementar w-1-5em font-size-2rem" />
                          }
                          onInputChange={handleOnChange}
                          inputValue={valueClientes}
                          onPaste={handlePaste}
                          options={clientes || []}
                          disabled={acessarDisabled}
                          autoHighlight
                          renderInput={params => <TextField {...params} label="" />}
                          noOptionsText="Nenhuma empresa encontrada"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="pad-topbot-10">
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <ButtonMain
                          onClick={handleBtnAcessar}
                          tipoBotao="principal"
                          disabled={cliente === null || acessarDisabled}
                        >
                          Acessar
                        </ButtonMain>
                      </Grid>
                    </Grid>

                    <Grid container className="pad-topbot-10">
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <ButtonMain
                          onClick={() => history.push('/entrada-cnpj-onboarding')}
                          tipoBotao="transparente"
                        >
                          Cadastrar outra empresa
                        </ButtonMain>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MainOnlyHeader>
  );
};
