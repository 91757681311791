import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Close } from '@mui/icons-material';
import { postListarOperacoesVOP } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import definicaoDeColunas from './definicoes-grid';
import { exibeValorLocalizado } from '../../../../shared';

const ModalOperacoesVOP = ({ modalOperVop, setModalOperVop }) => {
  const [isPending, setIsPending] = useState(false);
  const [esconderFooter, setEsconderFooter] = useState(true);
  const [dadosOperVop, setDadosOperVop] = useState([]);
  const [lstDadosOperVop, setLstDadosOperVop] = useState({});
  const [sortModel, setSortModel] = useState([
    {
      field: 'valorFace',
      sort: 'desc'
    }
  ]);

  useEffect(() => {
    if (modalOperVop && modalOperVop.data) {
      let status = modalOperVop.statusAnalise ? '_' + modalOperVop.statusAnalise : '';
      let idLstDadosOp =
        modalOperVop.data.replace(/-/g, '_') +
        `_${modalOperVop.gerente}_${modalOperVop.plataforma}${status}`;
      if (lstDadosOperVop[idLstDadosOp] && lstDadosOperVop[idLstDadosOp].length > 0) {
        setDadosOperVop([...lstDadosOperVop[idLstDadosOp]]);
      } else {
        setIsPending(true);
        postListarOperacoesVOP(modalOperVop)
          .then(res => {
            if (res && res.data) {
              setDadosOperVop([...res.data]);
              let dadosTemp = { ...lstDadosOperVop };
              dadosTemp[idLstDadosOp] = res.data;
              setLstDadosOperVop(dadosTemp);
            }
          })
          .finally(() => {
            setIsPending(false);
          });
      }
    }
  }, [modalOperVop]);

  useEffect(() => {
    if (dadosOperVop.length > 100) setEsconderFooter(false);
  }, [dadosOperVop]);

  return (
    <Dialog
      open={modalOperVop !== false}
      fullWidth={true}
      maxWidth="lg"
      keepMounted
      onClose={() => setModalOperVop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="borda-bottom-2-gray pad-topbot-10">
          Detalhes
          {modalOperVop && modalOperVop.nomeStatus && (
            <>
              {' - '}
              {modalOperVop.nomeStatus}
            </>
          )}
          <IconButton onClick={() => setModalOperVop(false)} className="float-right margin-neg-top-5">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        {dadosOperVop !== false ? (
          dadosOperVop.length > 0 ? (
            <Box sx={{ minWidth: '600px', overflowX: 'auto' }}>
              <DataGrid
                className="data-grid-operacoes-vop data-grid-separator-header-hidden data-grid-borderless"
                columns={definicaoDeColunas(modalOperVop)}
                rows={dadosOperVop}
                getRowHeight={() => 'auto'}
                autoHeight
                disableColumnMenu
                disableRowSelectionOnClick
                sx={{ margin: '15px 0 30px 0' }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                sortingOrder={['desc', 'asc']}
                sortModel={sortModel}
                onSortModelChange={model => setSortModel(model)}
                hideFooter={esconderFooter}
              />
              <Grid container>
                <Grid item lg={6} md={6} sm={6} xs={6} className="texto-esquerda pad-10">
                  <span className="font-size-1-rem">Quantidade: </span>
                  <span className="font-bold font-size-1-2-rem">{dadosOperVop.length}</span>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} className="texto-direita pad-10">
                  <span className="font-size-1-rem">Total: </span>
                  <span className="font-bold font-size-1-2-rem">
                    {`R$ ${exibeValorLocalizado(
                      dadosOperVop
                        .map(oper => parseFloat(oper.valorFace))
                        .reduce((total, valor) => total + valor, 0),
                      2,
                      2
                    )}`}
                  </span>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <div className="error">
              <h1>Nenhuma operação encontrada</h1>
            </div>
          )
        ) : (
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalOperacoesVOP;
