import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import BoxInputMain from './BoxInputMain';

const RadioBoxMain = props => {
  const {
    field: { ...fields },
    form: { errors },
    labelRadio,
    corRadioBox,
    children,
    className,
    erro = '',
    onChangeRadiobx,
    onBlurRadiobx,
    opcoes = [],
    ...restProps
  } = props;

  const onChangeRadiobxField = event => {
    if (typeof fields.onChange === 'function') {
      fields.onChange(event);
    }
    if (typeof onChangeRadiobx === 'function') {
      onChangeRadiobx(event);
    }
  };

  const onBlurRadiobxField = event => {
    if (typeof fields.onBlur === 'function') {
      fields.onBlur(event);
    }
    if (typeof onBlurRadiobx === 'function') {
      onBlurRadiobx(event);
    }
  };

  var valorRadiobx = fields.value && fields.value !== '' ? fields.value : '';
  var erroTexto = errors[fields.name] ? errors[fields.name] : '';
  erroTexto = erro !== '' ? erro : erroTexto;
  var erroExiste = erroTexto !== '' ? true : false;
  var corRadioGroupBox = corRadioBox && corRadioBox !== '' ? corRadioBox : 'checkbox-cor-principal';
  corRadioGroupBox += ` ${erroExiste ? 'texto-danger' : 'texto-cor-principal'}`;

  return (
    <BoxInputMain {...restProps} boxClassName={labelRadio ? 'margin-top-10 ' : ' '}>
      <FormControl>
        <RadioGroup
          {...restProps}
          value={valorRadiobx}
          name={fields.name}
          onChange={onChangeRadiobxField}
          onBlur={onBlurRadiobxField}
          className={`radio-group-principal ${className ? className : ''} ${corRadioGroupBox}`}
        >
          {opcoes.map(opt => {
            return <FormControlLabel key={opt.value} control={<Radio />} {...opt} />;
          })}
        </RadioGroup>
      </FormControl>
      {erroTexto ? <Typography className="texto-danger label-padding">{erroTexto}</Typography> : ''}
    </BoxInputMain>
  );
};
export default RadioBoxMain;
