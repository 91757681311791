import React from 'react';
import { FormGroup, CustomInput, UncontrolledTooltip, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default (
  handleCheck,
  checkedAll,
  handleCheckAll,
  toggleModalParecerInstrucao,
  toggleModalAnexoInstrucao,
  clienteAtual
) => {
  const criarCheckBoxBoletos = row => {
    return (
      <FormGroup>
        <CustomInput
          type="checkbox"
          checked={row.meta.checked}
          disabled={row.meta.disabled}
          onChange={event => handleCheck(event)}
          id={`check-${row.id}`}
          value={row.id}
        />
      </FormGroup>
    );
  };

  const checkBoxAll = (checkedAll, handleCheckAll) => {
    return (
      <FormGroup>
        <CustomInput
          type="checkbox"
          checked={checkedAll.checked}
          disabled={checkedAll.disabled}
          onChange={event => handleCheckAll(event)}
          id="chkAllTitulos"
          value="all"
        />
      </FormGroup>
    );
  };

  const headerCheckBoxAll = () => {
    return checkBoxAll(checkedAll, handleCheckAll);
  };

  const formatarStatusAnalise = (cellContent, row) => {
    return <span className={`text-${row.statusAnaliseInstrucao.cor}`}>{cellContent}</span>;
  };

  const criarBotoesInstrucao = row => {
    return (
      <>
        <Link
          to={`/instrucoes-visualizar-titulos/${row.id}`}
          id={`btn-visualizar-instrucao-${row.id}`}
          className="btn btn-outline-primary rounded-circle"
        >
          <i className="fa fa-eye" />
          <UncontrolledTooltip
            target={`btn-visualizar-instrucao-${row.id}`}
            placement="top"
            className="bold-font"
            delay={0}
          >
            Visualizar Instrução
          </UncontrolledTooltip>
        </Link>
        {row.acessoBotaoParecer !== '0' && row.codTipoInstrucao !== '4' && (
          <Button
            color="primary"
            outline
            type="button"
            id={`btn-pareceres-${row.codOperacaoInstrucao}`}
            value={row.codOperacaoInstrucao}
            className="rounded-circle fa fa-pencil"
            onClick={() =>
              toggleModalParecerInstrucao(row.codOperacaoInstrucao, row.statusAnaliseInstrucao.code)
            }
          >
            <UncontrolledTooltip
              target={`btn-pareceres-${row.codOperacaoInstrucao}`}
              placement="top"
              delay={0}
              className="bold-font"
            >
              Inserir Parecer
            </UncontrolledTooltip>
          </Button>
        )}
        <Button
          color={row.corBtnAnexo}
          outline
          type="button"
          id={`btn-anexoInstrucao-${row.id}`}
          value={row.id}
          className={`rounded-circle fa fa-upload ${row.corBtnAnexo}`}
          onClick={() => toggleModalAnexoInstrucao(row.id, row.codTipoInstrucao)}
        >
          <UncontrolledTooltip
            target={`btn-anexoInstrucao-${row.id}`}
            placement="top"
            delay={0}
            className="bold-font"
          >
            Inserir documentos relacionados a instrução
          </UncontrolledTooltip>
        </Button>
      </>
    );
  };

  const config = [
    {
      dataField: 'meta.checked',
      text: '',
      headerFormatter: headerCheckBoxAll,
      formatacao: 'checkbox',
      evento: criarCheckBoxBoletos,
      sort: false
    },
    {
      dataField: 'id',
      text: 'Nº INST.',
      tipoOrdenacao: 'inteiro',
      tdAlign: 'center'
    },
    {
      dataField: 'qtdTitulos',
      text: 'QTD. TÍTULOS',
      tipoOrdenacao: 'inteiro',
      tdAlign: 'center'
    },
    /* {
      dataField: 'documento',
      text: 'Nº DOC',
      tipoOrdenacao: 'inteiro',
      tdAlign: 'center'
    },
    {
      dataField: 'sacado',
      text: 'SACADO',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    },*/
    {
      dataField: 'vencimento',
      text: 'VENCIMENTO',
      formatacao: 'data',
      tdAlign: 'center',
      tipoOrdenacao: 'data'
    },
    /*{
      dataField: 'valorTitulo',
      text: 'VALOR TÍTULO',
      formatacao: 'valor',
      tipoOrdenacao: 'dinheiroFormatado',
      tdAlign: 'right',
      thWhiteSpace: 'normal'
    },*/
    {
      dataField: 'valor',
      text: 'VALOR INSTRUÇÃO',
      formatacao: 'valor',
      tipoOrdenacao: 'dinheiroFormatado',
      tdAlign: 'right',
      thWhiteSpace: 'normal'
    },
    {
      dataField: 'tipoInstrucao',
      text: 'INSTRUÇÃO',
      formatacao: 'inteiro',
      tdAlign: 'center'
    },
    {
      dataField: 'dataInclusao',
      text: 'INCLUSÃO',
      formatacao: 'data',
      tdTitle: (cell, row) => row.tituloInclusao,
      tdAlign: 'center'
    },
    {
      dataField: 'statusAnaliseInstrucao.descricao',
      text: 'STATUS',
      tipoOrdenacao: 'texto',
      formatacao: formatarStatusAnalise
    },
    {
      dataField: 'ACOES',
      text: 'AÇÕES',
      tdAlign: 'center',
      formatacao: 'button',
      evento: criarBotoesInstrucao,
      style: { whiteSpace: 'nowrap' },
      sort: false
    }
  ];

  if (clienteAtual && clienteAtual.id === 'todos') {
    config.splice(1, 0, {
      dataField: 'nomeCliente',
      text: 'CLIENTE',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    });
  }

  return config;
};
