import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';

import MainOnlyHeader from '../Main/MainOnlyHeader';
import Main from '../Main/Main';
import { getTelas } from '../../shared/services/User';

export default props => {
  const [tipoMain, setTipoMain] = useState('main');
  useEffect(() => {
    let tela = getTelas();
    if (tela && tela.home) {
      setTipoMain('Main');
    } else {
      setTipoMain('MainOnlyHeader');
    }
  }, []);

  return (
    <>
      {tipoMain === 'Main' && (
        <Main title="Página Não Encontrada" className="response-status" {...props}>
          <Card>
            <CardBody>
              <CardTitle>404</CardTitle>
              <CardSubtitle>Página não encontrada</CardSubtitle>
              <CardText>
                O servidor não pode encontrar o recurso solicitado. Este código de resposta talvez seja o
                mais famoso devido à frequência com que acontece na web.
              </CardText>
            </CardBody>
          </Card>
        </Main>
      )}
      {tipoMain === 'MainOnlyHeader' && (
        <MainOnlyHeader title="Página Não Encontrada" className="response-status">
          <Card>
            <CardBody>
              <CardTitle>404</CardTitle>
              <CardSubtitle>Página não encontrada</CardSubtitle>
              <CardText>
                O servidor não pode encontrar o recurso solicitado. Este código de resposta talvez seja o
                mais famoso devido à frequência com que acontece na web.
              </CardText>
            </CardBody>
          </Card>
        </MainOnlyHeader>
      )}
    </>
  );
};
