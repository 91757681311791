import React from 'react';
import { phoneMask } from '../../../masks/masks';
import CustomMaskedInput from '../CustomMaskedInput/CustomMaskedInput';

const PhoneInput = props => {
  const { ...otherProps } = props;
  let value = otherProps.field.value;

  if (otherProps.field.value) {
    value =
      typeof otherProps.field.value === 'object' ? otherProps.field.value.fone : otherProps.field.value;
  }

  otherProps.field.value = (value || '').replace(/\D/g, '');

  return <CustomMaskedInput returnConformed={true} {...otherProps} mask={phoneMask} />;
};

export default PhoneInput;
