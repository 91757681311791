import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Schedule } from '@mui/icons-material';
import { postStatusMesa } from '../../../api/dashboard';
import LoaderMain from '../../Onboarding/shared/LoaderMain';
import ModalOperacoesVOP from '../Charts/VOP/ModalOperacoesVOP';

const StatusMesa = ({ filtroDashboard, loadingDashFim }) => {
  const [dadosStatusMesa, setDadosStatusMesa] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [modalOperVop, setModalOperVop] = useState(false);

  useEffect(() => {
    let params = filtroDashboard ? filtroDashboard : {};
    if (params.filtrar === false) return false;
    setModalOperVop(false);

    setIsPending(true);
    setDadosStatusMesa([]);
    postStatusMesa(params)
      .then(res => {
        let lstDados = res && res.data ? res.data : [];
        setDadosStatusMesa(lstDados);
      })
      .catch(() => {
        setDadosStatusMesa(false);
      })
      .finally(() => {
        setIsPending(false);
        if (params.filtrar && params.filtrar === 'carga-inicial') {
          loadingDashFim('carga-inicial');
        } else {
          loadingDashFim('StatusMesa');
        }
      });
  }, [filtroDashboard]);

  const modalOperacoes = dados => {
    if (filtroDashboard.filtrar === false) return false;

    let paramsModal = {
      ...filtroDashboard,
      data: moment().format('YYYY-MM-DD'),
      temVop: false,
      statusAnalise: dados.STATUSANALISE,
      statusOperacao: true,
      nomeStatus: dados.DESCRICAO
    };
    setModalOperVop(paramsModal);
  };

  return (
    <Card className="chart-card status-mesa">
      {dadosStatusMesa ? (
        <CardContent
          className="ajuste-spin-chart ajuste-card-mesa"
          sx={isPending ? { position: 'relative' } : {}}
        >
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-7 texto-weight-600">
            Status das Operações
          </Typography>
          <Box className="box-principal-status-mesa">
            <Grid container>
              {dadosStatusMesa !== false ? (
                dadosStatusMesa.length > 0 ? (
                  dadosStatusMesa.map(dados => {
                    return (
                      <Grid
                        key={dados.STATUSANALISE}
                        className="box-card-status-mesa"
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                      >
                        <Box
                          className="box-status-card cursor-pointer"
                          onClick={() => modalOperacoes(dados)}
                        >
                          <Grid container>
                            <Grid item lg={3} md={3} sm={5} xs={5} style={{ height: '25px' }}>
                              <Box className="cor-status-card" style={{ borderColor: dados.COR }} />
                            </Grid>
                            <Grid item lg={9} md={9} sm={7} xs={7} className="texto-direita">
                              {(dados.STATUSANALISE === 'D' || dados.STATUSANALISE === 'I') && (
                                <>
                                  <Schedule className="margin-right-5 texto-info font-bold margin-neg-top-5" />
                                  <span className="texto-info font-bold font-size-1-rem">hoje</span>
                                </>
                              )}
                            </Grid>
                            <Grid className="descricao-status" item lg={12} md={12} sm={12} xs={12}>
                              <span className="quantidade-status">
                                {String(dados.TOTAL).padStart(2, '0')}
                              </span>
                              <span className="nome-status">{dados.DESCRICAO}</span>
                              <hr />
                              <Box className="valor-status">
                                <span>R$ </span>
                                {parseFloat(dados.VALOR).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    );
                  })
                ) : (
                  <div className="error pad-15">
                    <h1>Nenhum status encontrado</h1>
                  </div>
                )
              ) : (
                <CardContent>
                  <center className="mt-3">
                    <h1>Não há status da mesa para serem mostrados!</h1>
                  </center>
                </CardContent>
              )}
            </Grid>
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
          </div>
        </CardContent>
      )}

      <ModalOperacoesVOP modalOperVop={modalOperVop} setModalOperVop={setModalOperVop} />
    </Card>
  );
};

export default StatusMesa;
