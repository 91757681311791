export const segmentosEmpresa = {
  1: { valor: 1, label: 'CONSTRUÇÃO CIVIL' },
  2: { valor: 2, label: 'INDÚSTRIA, COMÉRCIO E SERVIÇO' },
  3: { valor: 3, label: 'COMÉRCIO E SERVIÇO' }
};

export const faturamentosAnual = {
  de_4800_ate_12000: {
    valor: 'de_4800_ate_12000',
    label: 'DE R$ 4.800,00 ATÉ R$ 12.000,00',
    valor_minimo: 4800,
    valor_maximo: 12000
  },
  de_12001_ate_30000: {
    valor: 'de_12001_ate_30000',
    label: 'DE R$ 12.001,00 ATÉ R$ 30.000,00',
    valor_minimo: 12001,
    valor_maximo: 30000
  },
  acima_30001: {
    valor: 'acima_30001',
    label: 'ACIMA DE R$ 30.001,00',
    valor_minimo: 30001,
    valor_maximo: 99999999999
  }
};

export const estadosCivil = {
  CSD: { valor: 'CSD', label: 'Casado(a)' },
  SLT: { valor: 'SLT', label: 'Solteiro(a)' },
  DVC: { valor: 'DVC', label: 'Divorciado(a)' },
  VIV: { valor: 'VIV', label: 'Viúvo(a)' }
};

export const regimesBens = {
  CPC: { valor: 'CPC', label: 'Comunhão Parcial', col: 4, xs: 12 },
  CUN: { valor: 'CUN', label: 'Comunhão Universal', col: 4, xs: 12 },
  STT: { valor: 'STT', label: 'Separação Total', col: 4, xs: 12 }
};

export const tiposDocumentosPessoaFisica = {
  CNH: { valor: 'CNH', label: 'CNH', col: 4, xs: 12 },
  RG: { valor: 'RG', label: 'RG com CPF', col: 4, xs: 12 },
  RG_CPF: { valor: 'RG_CPF', label: 'RG e CPF', col: 4, xs: 12 }
};

export const tiposImovelGarantia = {
  APT: { valor: 'APT', label: 'APARTAMENTO RESIDENCIAL' },
  CSA: { valor: 'CSA', label: 'CASA RESIDENCIAL' },
  CSD: { valor: 'CSD', label: 'CASA EM CONDOMÍNIO' },
  CSC: { valor: 'CSC', label: 'CASA COMERCIAL' },
  SLC: { valor: 'SLC', label: 'SALA COMERCIAL' },
  GLP: { valor: 'GLP', label: 'GALPÃO' },
  TER: { valor: 'TER', label: 'TERRENO/LOTE' },
  RUR: { valor: 'RUR', label: 'IMÓVEL RURAL' }
};

export const tipoCapacitacaoproposta = {
  COMERCIAL: 1,
  MARKETING: 2,
  REBEL: 3,
  PONTO_DE_ATENDIMENTO: 4,
  PRE_VENDAS: 5,
  INDICACAO: 6,
  JORNADA_DIGITAL: 7
};

export const origemCadastro = {
  DIGITAL: 'DIG',
  IPROSPECT: 'COM'
};

export const MODULOS = {
  PLATAFORMACLIENTE: 24
};

export const MODULOS_TAGS = {
  PLATAFORMACLIENTE: 'plataforma_cliente',
  PLATAFORMA_CRM: 'plataforma_crm',
  PLATAFORMA_FORMALIZACAO: 'plataforma_formalizacao',
  PLATAFORMA_RELACIONAMENTOS: 'plataforma_relacionamento'
};
