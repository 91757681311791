import API from './api';
import removeNullKeys from '../utils/remove-null-keys';

import { unmaskValue } from '../utils/format';

const validateTitulosParams = values => {
  values = {
    ...values,
    data_vencimento_inicial: values.data_vencimento_inicial ? values.data_vencimento_inicial : null,
    data_vencimento_final: values.data_vencimento_final ? values.data_vencimento_final : null,
    data_pagamento_inicial: values.data_pagamento_inicial ? values.data_pagamento_inicial : null,
    data_pagamento_final: values.data_pagamento_final ? values.data_pagamento_final : null,
    data_aceite_inicial: values.data_aceite_inicial ? values.data_aceite_inicial : null,
    data_aceite_final: values.data_aceite_final ? values.data_aceite_final : null,
    valor_inicial: unmaskValue(values.valor_inicial) || null,
    valor_final: unmaskValue(values.valor_final) || null,
    documento: values.documento ? values.documento.replace(/\s/gm, '') : null,
    bordero: values.bordero ? values.bordero.replace(/\s/gm, '') : null
  };

  removeNullKeys(values);

  return values;
};

const getRelatoriosConcentracaoSacados = values => {
  values = validateTitulosParams(values);
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/relatorios/concentracao-sacados`, {
    params: { ...values }
  });
};

const getRelatoriosTitulos = values => {
  values = validateTitulosParams(values);
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/relatorios/titulos`, {
    params: { ...values }
  });
};

const getVisaoSacadoTitulos = values => {
  values = validateTitulosParams(values);
  return API.get(`/area-sacado/segunda-via`, {
    params: { ...values }
  });
};

const getRelatoriosBorderos = values => {
  values = {
    ...values,
    bordero: values.bordero ? values.bordero.replace(/\s/gm, '') : null,
    documento: values.documento ? values.documento.replace(/\s/gm, '') : null,
    data_inicio: values.data_inicio ? values.data_inicio : null,
    data_final: values.data_final ? values.data_final : null
  };

  removeNullKeys(values);

  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/relatorios/borderos`, {
    params: { ...values }
  });
};

const getRelatoriosDebitos = values => {
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/relatorios/debitos`, {
    params: { ...values }
  });
};

export {
  getRelatoriosConcentracaoSacados,
  getRelatoriosTitulos,
  getVisaoSacadoTitulos,
  getRelatoriosBorderos,
  getRelatoriosDebitos
};
