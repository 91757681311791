import React, { useState, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { Card, CardBody } from 'reactstrap';
import Main from '../../Main/Main';
import FinalizationStep from './FinalizationStep/FinalizationStep';
import SelectionStep from './SelectionStep/SelectionStep';
import UploadStep from './UploadStep/UploadStep';
import { getBorderoByCode, getBorderoTitulo } from '../../../api/borderos';
import { StepWizardNav, getClienteAtual } from '../../../shared';
import stepDefinition from './step-definition';
import { formatarBorderoTitulo } from '../../../utils/format';

const Wizard = props => {
  const { match: { params: { bordero_id: borderoID, history = [] } = {} } = {} } = props;

  const [wizardState, setWizardState] = useState({});
  const [bordero, setBordero] = useState({});
  const [titulosChecados, setTitulosChecados] = useState([]);
  const [isPending, setIsPending] = useState(false);
  // Douglas - 24/01/20 - Ao carregar a pagina, caso o tamanho da janela seja menor ou igual a 600px ja carrega o menu collapsado
  const [collapseMenuRight, setCollapseMenuRight] = useState(window.innerWidth <= 600 ? true : false);
  const clienteAtual = getClienteAtual();
  const hist = useHistory();

  const onStepChange = state => setWizardState({ state });

  const [itensCount, setItensCount] = useState(0);

  useEffect(() => {
    if (clienteAtual && clienteAtual.id === 'todos') {
      hist.push('/home');
    }
  }, [clienteAtual]);

  const updateStateBordero = data => {
    if (data) {
      setBordero(data);
      setItensCount(0);
      setTitulosChecados([]);
    } else {
      setIsPending(true);
      return Promise.all([getBorderoByCode(borderoID), getBorderoTitulo(borderoID)])
        .then(([resultBordero, resultTitulos]) => {
          resultTitulos.data.map(item => {
            return formatarBorderoTitulo(item);
          });
          const updatedBordero = {
            ...resultBordero.data,
            titulos: resultTitulos.data
          };

          // CASO SEJA DIFERENTE DE SELECAO, IRA REDIRECIONAR PARA PAGINA DE VISUALIZACAO
          if (updatedBordero.statusProcesso !== 0) {
            setBordero([]);
            setItensCount(0);
            setTitulosChecados([]);
            hist.push(`/borderos-visualizar-titulos/${borderoID}`);
          } else {
            setBordero(updatedBordero);
            setItensCount(0);
            setTitulosChecados([]);
          }
        })
        .catch(() => {
          history.push('/Borderos');
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  };

  const { state: { activeStep = borderoID && borderoID !== 'new' ? 2 : 1 } = {} } = wizardState;

  return (
    <Main
      title="Incluir Novo Borderô"
      className={
        `wizard-bordero ${isPending ? 'is-submitting' : ''}` + (collapseMenuRight ? ' collapsed' : '')
      }
      {...props}
    >
      <Card className={`${wizardState.currentStep > 1 ? 'nr-radius' : ''}`}>
        <CardBody>
          <StepWizard
            initialStep={activeStep}
            nav={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <StepNavigation
                isPending={isPending}
                collapseMenuRight={collapseMenuRight}
                setCollapseMenuRight={setCollapseMenuRight}
              />
            }
            onStepChange={onStepChange}
            transitions={{}}
            isLazyMount={true}
            className={`wizard step-${activeStep}`}
          >
            <UploadStep
              isPending={isPending}
              bordero={bordero}
              updateStateBordero={updateStateBordero}
              clienteAtual={clienteAtual}
              {...props}
            />
            <SelectionStep
              bordero={bordero}
              updateStateBordero={updateStateBordero}
              isPending={isPending}
              clienteAtual={clienteAtual}
              itensCount={itensCount}
              setItensCount={setItensCount}
              titulosChecados={titulosChecados}
              setTitulosChecados={setTitulosChecados}
              collapseMenuRight={collapseMenuRight}
              setCollapseMenuRight={setCollapseMenuRight}
              {...props}
            />
            <FinalizationStep
              bordero={bordero}
              clienteAtual={clienteAtual}
              collapseMenuRight={collapseMenuRight}
              setCollapseMenuRight={setCollapseMenuRight}
              {...props}
            />
          </StepWizard>
        </CardBody>
      </Card>
    </Main>
  );
};

export const StepNavigation = props => {
  const { currentStep, collapseMenuRight, setCollapseMenuRight } = props;
  return (
    <div className="row">
      <div className="col align-middle">
        <StepWizardNav steps={stepDefinition} {...props} />
      </div>
      <div className="col fixed-side-info">
        {currentStep !== 1 ? (
          collapseMenuRight && (
            <button
              type="button"
              onClick={() => setCollapseMenuRight(false)}
              title="Detalhes do Borderô"
              className="btn btn-outline-secondary float-right btn-show-menu-right"
            >
              <i className="fa fa-angle-left" />
              <span>Info. Borderô</span>
            </button>
          )
        ) : (
          <>&nbsp;</>
        )}
      </div>
    </div>
  );
};

export default memo(Wizard);
