import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup
} from 'reactstrap';
import { Form, Formik, Field } from 'formik';

import moment from 'moment';
import {
  SkeletonLoader,
  Select,
  If,
  verificaMostraFiltroPlataforma,
  setCodPlataformaEscolhida,
  listarPlataformaEListarGerentes,
  listaFiltradaGerentesPlataforma,
  listaFiltradaUnidadeNegocioPlataforma,
  normalizaListaGerentesPlataforma,
  normalizaListaUnidadesNegociosPlataforma,
  getCodGerenteEscolhido,
  getCodUnidadeEscolhido,
  setCodGerenteEscolhido,
  setCodUnidadeEscolhido,
  setCodVerticalEscolhida,
  getCodPlataformaEscolhida
} from '../../../shared';
import { moneyFullFormat } from '../../../utils/format';
import RelatorioExport from '../RelatorioExport';

import { getRelatoriosDebitos } from '../../../api/relatorios';
import Grid from '../../commom/Grid';
import debitosGrid from './debitosGrid';
import HistoricoAcesso from '../../commom/HistoricoAcesso';

const Debitos = props => {
  const { clienteAtual, filtroTipoEmpresas, isPending, collapseMenuRight, setCollapseMenuRight } = props;
  const [form, setForm] = useState({});
  const [isLocalPending, setIsLocalPending] = useState(false);
  const [message, setMessage] = useState();
  const [relatorios, setRelatorios] = useState({});
  const [isDropDownExportarOpened, setIsDropDownExportarOpened] = useState(false);

  const [vertical, setVertical] = useState(0);
  const [lstVerticais, setLstVerticais] = useState([]);
  const [plataforma, setPlataforma] = useState(0);
  const [lstPlataformas, setLstPlataformas] = useState([]);
  const mostrarFiltroPlataforma = verificaMostraFiltroPlataforma();
  const [lstPlataformasFiltradas, setLstPlataformasFiltradas] = useState([]);
  const [lstFiltradaGerentePlataforma, setLstFiltradaGerentePlataforma] = useState([]);
  const [gerente, setGerente] = useState(null);
  const [lstFiltradaUnidadeNegocio, setLstFiltradaUnidadeNegocio] = useState([]);
  const [unidadeNegocio, setUnidadeNegocio] = useState(null);

  useEffect(() => {
    buscaPlataformas();
  }, []);

  const buscaPlataformas = () => {
    if (!mostrarFiltroPlataforma) {
      return;
    }
    setIsLocalPending(true);
    listarPlataformaEListarGerentes({
      setLstPlataformas: setLstPlataformas,
      setPlataforma: setPlataforma,
      setLstPlataformasFiltradas: setLstPlataformasFiltradas,
      setListaFiltradaGerentes: setLstFiltradaGerentePlataforma,
      setGerenteEscolhido: setGerente,
      setListaFiltradaUnidadeNegocio: setLstFiltradaUnidadeNegocio,
      setUnidadeNegocioEscolhido: setUnidadeNegocio,
      setPending: setIsLocalPending,
      setLstVerticais: setLstVerticais,
      setVertical: setVertical
    });
  };

  const handleSubmit = (values, actions) => {
    setCodVerticalEscolhida(vertical);
    setCodPlataformaEscolhida([plataforma]);
    setCodUnidadeEscolhido(unidadeNegocio);
    setCodGerenteEscolhido(gerente);

    setIsLocalPending(true);
    setRelatorios({});
    setMessage();

    return getRelatoriosDebitos(values)
      .then(res => {
        const { data } = res;

        data.map(item => {
          item.saldo = item.saldo ? item.saldo : 0;
          item.debito = item.debito ? item.debito : 0;
          return item;
        });

        const newRelatorios = {
          data
        };
        setRelatorios(newRelatorios);

        if (data.length === 0) {
          setMessage('Nenhum item foi encontrado com estes filtros');
        }
      })
      .catch(() => {
        setMessage('Houve um erro na busca. Por favor, tente novamente mais tarde.');
      })
      .finally(() => {
        setIsLocalPending(false);
        actions.setSubmitting(false);
        setCollapseMenuRight(true);
      });
  };

  const handleToggle = () => {
    setIsDropDownExportarOpened(!isDropDownExportarOpened);
  };

  useEffect(() => {
    if (form && form.setFieldValue && vertical && lstPlataformas.length > 0) {
      form.setFieldValue('cod_vertical', vertical);

      let plataformas = [...lstPlataformas].filter(
        plataf => parseInt(plataf.codVertical) === parseInt(vertical)
      );
      setLstPlataformasFiltradas(plataformas);

      let platafEscolhida =
        plataformas && plataformas[0] && plataformas[0].value ? plataformas[0].value : null;
      if (
        getCodPlataformaEscolhida() &&
        plataformas &&
        plataformas.filter(e => e.value === getCodPlataformaEscolhida()[0]).length > 0
      ) {
        platafEscolhida = getCodPlataformaEscolhida()[0];
      }
      setPlataforma(platafEscolhida);
    }
  }, [vertical]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_plataforma', Array.isArray(plataforma) ? plataforma[0] : plataforma);

      let lstFiltradaUnidadeNegocioPlataforma = listaFiltradaUnidadeNegocioPlataforma(
        Array.isArray(plataforma) ? plataforma : [plataforma]
      );
      let unidadeNegocioEscolhido = null;
      if (lstFiltradaUnidadeNegocioPlataforma.length > 0) {
        lstFiltradaUnidadeNegocioPlataforma = normalizaListaUnidadesNegociosPlataforma(
          lstFiltradaUnidadeNegocioPlataforma
        );
        setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
        unidadeNegocioEscolhido =
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma[0] &&
          lstFiltradaUnidadeNegocioPlataforma[0].value
            ? lstFiltradaUnidadeNegocioPlataforma[0].value
            : null;

        if (
          getCodUnidadeEscolhido() &&
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma.filter(e => e.value === getCodUnidadeEscolhido()).length >
            0
        ) {
          unidadeNegocioEscolhido = getCodUnidadeEscolhido();
        }
      } else {
        setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
      }
      setUnidadeNegocio(unidadeNegocioEscolhido);
    }
  }, [plataforma]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_unidade_negocio', unidadeNegocio);

      let lstFiltradaGerentes = listaFiltradaGerentesPlataforma(plataforma, unidadeNegocio);
      lstFiltradaGerentes = normalizaListaGerentesPlataforma(lstFiltradaGerentes);
      setLstFiltradaGerentePlataforma(lstFiltradaGerentes);

      let gerenteEscolhido =
        lstFiltradaGerentes && lstFiltradaGerentes[0] && lstFiltradaGerentes[0].value
          ? lstFiltradaGerentes[0].value
          : null;

      if (
        getCodGerenteEscolhido() &&
        lstFiltradaGerentes &&
        lstFiltradaGerentes.filter(e => e.value === getCodGerenteEscolhido()).length > 0
      ) {
        gerenteEscolhido = getCodGerenteEscolhido();
      }
      setGerente(gerenteEscolhido);
    }
  }, [unidadeNegocio, plataforma]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_gerente', gerente);
    }
  }, [gerente]);

  return (
    <>
      <Formik
        ref={node => setForm(node)}
        onSubmit={handleSubmit}
        render={({ isSubmitting, submitCount }) => {
          return (
            <div className="row">
              <div
                className={
                  (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                  ' col-12 content-body content-relatorio '
                }
              >
                <div className="body">
                  <div className="row">
                    <div className="col-lg-10 col-md-8 col-titulo-relatorio">
                      <h1 className="title">Relatório de Débitos</h1>
                      <p className="subTitle">Filtre ao lado para visualizar seus Débitos.</p>
                    </div>
                    <div className="col-lg-2 col-md-4 form-inline d-flex flex-row-reverse bd-highlight export-buttons">
                      {collapseMenuRight && (
                        <button
                          type="button"
                          onClick={() => setCollapseMenuRight(false)}
                          title="Filtros"
                          className="btn btn-outline-secondary btn-show-menu-right float-right"
                        >
                          <i className="fa fa-angle-left" />
                          <span>Filtros</span>
                        </button>
                      )}
                      {relatorios.data && (
                        <Dropdown isOpen={isDropDownExportarOpened} toggle={() => handleToggle()}>
                          <DropdownToggle
                            color="outline-primary"
                            disabled={!relatorios.data.length || isPending || isLocalPending}
                          >
                            Exportar
                            <i className="svg-icon right-side far fa-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => RelatorioExport(relatorios.data, 'csv')}>
                              CSV
                            </DropdownItem>
                            <DropdownItem onClick={() => RelatorioExport(relatorios.data, 'xls')}>
                              XLS
                            </DropdownItem>
                            <DropdownItem onClick={() => window.print()}>IMPRIMIR</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                  <div className="nomeCliente">
                    <Label>Cliente: &nbsp;</Label>
                    {clienteAtual && (clienteAtual.razaoSocial || clienteAtual.nomeFantasia)}
                    <br />
                    <Label>Emitido em: &nbsp;</Label>
                    {moment().format('DD/MM/YYYY') + ' ás ' + moment().format('HH:mm:ss')}
                    {relatorios.data && relatorios.data.length
                      ? ` por ${relatorios.data[0].apelidoUsuario}`
                      : ''}
                    <br />
                    <Label>Tipo Empresa: &nbsp;</Label>
                    {relatorios.data && relatorios.data.length
                      ? relatorios.data[0].filtroEmpresa === 'Especifico'
                        ? relatorios.data[0].empresa
                        : 'Todas'
                      : ''}
                    {relatorios.data ? ` - ${relatorios.data.length} títulos filtrados` : ''}
                    <img
                      className="logoOne7 float-right"
                      src="/static/media/one7-logo.92588ecf.png"
                      width="150"
                      alt="one7 logo"
                    />
                  </div>
                  <SkeletonLoader
                    isPending={isPending || isLocalPending}
                    width="100%"
                    height="40px"
                    count={8}
                    widthRandomness={0}
                  >
                    <If test={submitCount > 0}>
                      <Grid
                        keyField="codigo"
                        data={relatorios.data}
                        configuracaoColunas={debitosGrid(clienteAtual)}
                        mensagem={message}
                        classeIdentificadora="debitos"
                      />

                      {relatorios.data && relatorios.data.length > 0 && (
                        <div className="row" style={{ marginTop: 5 }}>
                          <div className="col-md-12 ml-auto mt-12 text-right">
                            <Label className="col-md-12 col-lg-12 col-xl-6 align-items-center">
                              Quantidade
                              <span className="badge badge-relatorio">{relatorios.data.length}</span>
                            </Label>
                            <Label className="col-md-12 col-lg-6 col-xl-3 align-items-center">
                              Total Débito
                              <span className="badge badge-relatorio">
                                {moneyFullFormat(
                                  relatorios &&
                                    relatorios.data.reduce(
                                      (total, valor) => Number(total) + Number(valor.debito),
                                      0
                                    )
                                )}
                              </span>
                            </Label>
                            <Label className="col-md-12 col-lg-6 col-xl-3 align-items-center">
                              Total Saldo
                              <span className="badge badge-relatorio">
                                {moneyFullFormat(
                                  relatorios &&
                                    relatorios.data.reduce(
                                      (total, valor) => Number(total) + Number(valor.debito),
                                      0
                                    )
                                )}
                              </span>
                            </Label>
                          </div>
                        </div>
                      )}
                    </If>
                  </SkeletonLoader>
                </div>
                <div className="footer"> </div>
              </div>
              {!collapseMenuRight && (
                <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                  <Form>
                    <div className="fixed-side">
                      <div
                        className="header cursor-pointer"
                        onClick={() => setCollapseMenuRight(true)}
                        onKeyPress={() => setCollapseMenuRight(true)}
                        role="button"
                        tabIndex={0}
                      >
                        <h4 className="title">
                          Filtros
                          <i className="fa fa-angle-right float-right" />
                        </h4>
                      </div>
                      <div className="body">
                        <SkeletonLoader
                          isPending={isPending}
                          width="100%"
                          height="20px"
                          count={8}
                          widthRandomness={0}
                        >
                          <div className="container p-0">
                            <FormGroup>
                              <Label>Cliente:</Label>
                              <p className="form-control-static">
                                {clienteAtual && (clienteAtual.razaoSocial || clienteAtual.nomeFantasia)}
                              </p>
                            </FormGroup>

                            {mostrarFiltroPlataforma && lstVerticais && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Field
                                    label="Vertical"
                                    id="cod_vertical"
                                    name="cod_vertical"
                                    className="select-plataforma-relatorios"
                                    options={lstVerticais}
                                    onChange={e => {
                                      setVertical(e.value);
                                    }}
                                    disabled={isSubmitting || lstVerticais.length === 1}
                                    component={Select}
                                    autoComplete="off"
                                    isClearable={false}
                                    placeholder="-- Selecione"
                                  />
                                </div>
                              </div>
                            )}

                            {mostrarFiltroPlataforma && lstPlataformasFiltradas && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Field
                                    label="Plataforma"
                                    id="cod_plataforma"
                                    name="cod_plataforma"
                                    className="select-plataforma-relatorios"
                                    options={lstPlataformasFiltradas}
                                    onChange={e => {
                                      setPlataforma(e.value);
                                    }}
                                    disabled={isSubmitting || lstPlataformasFiltradas.length === 1}
                                    component={Select}
                                    autoComplete="off"
                                    isClearable={false}
                                    placeholder="-- Selecione"
                                  />
                                </div>
                              </div>
                            )}

                            {mostrarFiltroPlataforma &&
                              lstPlataformasFiltradas &&
                              lstFiltradaUnidadeNegocio &&
                              unidadeNegocio && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Unidade de Negócio"
                                      id="cod_unidade_negocio"
                                      name="cod_unidade_negocio"
                                      className="select-plataforma-relatorios"
                                      options={lstFiltradaUnidadeNegocio}
                                      onChange={e => {
                                        setUnidadeNegocio(e.value);
                                      }}
                                      disabled={isSubmitting || lstFiltradaUnidadeNegocio.length === 1}
                                      component={Select}
                                      autoComplete="off"
                                      isClearable={false}
                                      placeholder="-- Selecione"
                                    />
                                  </div>
                                </div>
                              )}

                            {mostrarFiltroPlataforma &&
                              lstPlataformasFiltradas &&
                              lstFiltradaGerentePlataforma &&
                              gerente && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Gerente"
                                      id="cod_gerente"
                                      name="cod_gerente"
                                      className="select-plataforma-relatorios"
                                      options={lstFiltradaGerentePlataforma}
                                      onChange={e => {
                                        setGerente(e.value);
                                      }}
                                      disabled={
                                        isSubmitting || lstFiltradaGerentePlataforma.length === 1
                                      }
                                      component={Select}
                                      autoComplete="off"
                                      isClearable={false}
                                      placeholder="-- Selecione"
                                    />
                                  </div>
                                </div>
                              )}

                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  label="Tipo de Empresa"
                                  id="companyType"
                                  name="empresa"
                                  options={filtroTipoEmpresas}
                                  component={Select}
                                  disabled={isSubmitting}
                                  placeholder="-- Selecione"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </SkeletonLoader>
                      </div>
                      <div className="bottom">
                        <Button color="primary" type="submit" block disabled={isSubmitting || isPending}>
                          Filtrar
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          );
        }}
      />
      <HistoricoAcesso codTela={1205} />
    </>
  );
};
export default memo(Debitos);
