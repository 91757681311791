import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import NotFound from './components/ResponseStatus/NotFound';

import Borderos from './components/Borderos/Borderos';
import Wizard from './components/Borderos/Wizard/Wizard';
import VisualizarTitulos from './components/Borderos/VisualizarTitulos/VisualizarTitulos';

import Dashboard from './components/Dashboard/Dashboard';
import Home from './components/Home';
import NovaEntrada from './components/NovaEntrada';
import JornadaCredito from './components/JornadaCredito';
import Instrucoes from './components/Instrucoes';
import IncluirNova from './components/Instrucoes/IncluirNova';
import VisualizarTitulosInstrucao from './components/Instrucoes/VisualizarTitulos/VisualizarTitulosInstrucao';
import Relatorios from './components/Relatorios/Relatorios';
// import Tutoriais from './components/Tutoriais/Tutoriais';
import Login from './components/Authentication/Login';
import Cadastro from './components/Onboarding/Login/Cadastro/Cadastro';
import Confirmacao from './components/Onboarding/Login/Confirmacao/Confirmacao';
import GrupoEmpresarial from './components/Onboarding/Login/GrupoEmpresarial/GrupoEmpresarial';
import Logout from './components/Authentication/Logout';
import ChangeClient from './components/Authentication/ChangeClient';
import ForgotPassword from './components/Authentication/ForgotPassword';
import SegundaVia from './components/SegundaViaBoleto/SegundaVia';
import { isAuthenticated } from './shared';
import {
  getClienteAtual,
  getClientes,
  getDadosUsuario,
  etapaTelaOnborading,
  verificaSeStringExiste,
  TELASHOME,
  getEscopoComponentes
} from './shared/services/User';
import ResetPassword from './components/Authentication/ResetPassword';
import Retorno from './components/Retorno/Retorno';
import EntradaCNPJ from './components/Onboarding/Jornada/CadastroEmpresa/EntradaCNPJ/EntradaCNPJ';
import Jornada from './components/Onboarding/Jornada';
import JornadaConnect from './components/Onboarding/JornadaConnect';
import PlataformasComplementares from './components/Authentication/PlataformasComplementares';
// import Documentos from './components/Documentos';

const rotasComplementares = [
  { modulo: 'plataforma_crm', rota: 'dashboard' },
  { modulo: 'plataforma_formalizacao', rota: 'dashboard' },
  { modulo: 'plataforma_relacionamento', rota: 'dashboard' }
];

const PrivateRoute = ({ component: Component, escopoAcesso, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (
        isAuthenticated() &&
        escopoAcesso &&
        escopoAcesso.plataforma_cliente &&
        escopoAcesso.plataforma_cliente.rota &&
        Object.values(escopoAcesso.plataforma_cliente.rota).length > 0
      ) {
        return getClientes() && Object.keys(getClientes()).length > 0 ? (
          (getClienteAtual() && props.location.pathname !== '/grupo-empresarial') ||
          props.location.pathname === '/entrada-cnpj-onboarding' ? (
            etapaTelaOnborading() &&
            verificaSeStringExiste(props.location.pathname, TELASHOME, '/', false) ? (
              <Redirect to={{ pathname: '/jornada-onboarding', state: { from: props.location } }} />
            ) : (
              <ValidarRenderizarComponente
                componente={Component}
                props={{ ...props }}
                escopoAcesso={escopoAcesso}
              />
            )
          ) : props.location.pathname === '/grupo-empresarial' ? (
            <ValidarRenderizarComponente
              componente={GrupoEmpresarial}
              props={{ ...props }}
              escopoAcesso={escopoAcesso}
            />
          ) : (
            <Redirect to={{ pathname: '/grupo-empresarial', state: { from: props.location } }} />
          )
        ) : props.location.pathname === '/entrada-cnpj-onboarding' ? (
          <ValidarRenderizarComponente
            componente={EntradaCNPJ}
            props={{ ...props }}
            escopoAcesso={escopoAcesso}
          />
        ) : getClienteAtual() || (getDadosUsuario() && getDadosUsuario().nivel !== 7) ? (
          <ValidarRenderizarComponente
            componente={EntradaCNPJ}
            props={{ ...props }}
            escopoAcesso={escopoAcesso}
          />
        ) : (
          <Redirect to={{ pathname: '/entrada-cnpj-onboarding', state: { from: props.location } }} />
        );
      } else if (isAuthenticated()) {
        return <NotFound {...props} escopoAcesso={escopoAcesso} />;
      } else {
        return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />;
      }
    }}
  />
);

const ValidarRenderizarComponente = ({ escopoAcesso, componente: Componente, props }) => {
  var rotaVerificar =
    props.location.pathname[0] === '/' ? props.location.pathname.substring(1) : props.location.pathname;
  rotaVerificar = rotaVerificar
    .replaceAll('/', '.')
    .replaceAll(/\.\d+/g, '.param_x')
    .replaceAll('.new', '.param_x')
    .toLowerCase();
  if (rotaVerificar && escopoAcesso.plataforma_cliente.rota[rotaVerificar]) {
    return <Componente {...props} escopoAcesso={escopoAcesso} />;
  } else {
    return <NotFound {...props} escopoAcesso={escopoAcesso} />;
  }
};

export default () => {
  const [escopoAcesso, setEscopoAcesso] = useState(isAuthenticated() ? getEscopoComponentes() : false);

  return (
    <Switch>
      {/* ROTAS DE LOGIN - NAO AUTENTICADO */}
      <Route
        path="/authentication/login"
        render={props => {
          return <Login {...props} setEscopoAcesso={setEscopoAcesso} />;
        }}
      />
      <Route path="/authentication/logout" component={Logout} />
      <Route path="/authentication/forbidden" component={Login} />
      <Route path="/authentication/unauthorized" component={Login} />
      <Route path="/authentication/change-client" component={ChangeClient} />
      <Route path="/authentication/forgot-password" component={ForgotPassword} />
      <Route path="/authentication/reset-password/:token" component={ResetPassword} />

      {/* ROTA DE BOLETO - NAO AUTENTICADO */}
      <Route path="/segunda-via" component={SegundaVia} />

      {/* ROTA DE ONBOARDING - NAO AUTENTICADO */}
      <Route
        path="/cadastro/:tipo_cadastro?/:token_usuario_insert?/:token_plataforma?/:token_unidade_negocio?/:token_broker?/:tipo_onboarding?"
        component={Cadastro}
      />
      <Route path="/confirmacao" component={Confirmacao} />

      {/* ROTA DE ONBOARDING - AUTENTICADO */}
      <PrivateRoute path="/grupo-empresarial" component={GrupoEmpresarial} escopoAcesso={escopoAcesso} />
      <PrivateRoute
        path="/entrada-cnpj-onboarding"
        component={EntradaCNPJ}
        escopoAcesso={escopoAcesso}
      />
      <PrivateRoute path="/jornada-onboarding" component={Jornada} escopoAcesso={escopoAcesso} />
      <PrivateRoute
        path="/jornada-onboarding-connect"
        component={JornadaConnect}
        escopoAcesso={escopoAcesso}
      />
      {/* <PrivateRoute path="/documentos-cliente" component={Documentos} escopoAcesso={escopoAcesso} /> */}
      <PrivateRoute
        path="/nova-entrada"
        exact={true}
        component={NovaEntrada}
        escopoAcesso={escopoAcesso}
      />

      {/* ROTA DA TELA PRINCIPAL e DASHBOARD - AUTENTICADO */}
      <Route
        path="/"
        exact={true}
        render={props => <Redirect to={{ pathname: '/home', state: { from: props.location } }} />}
      />
      <PrivateRoute path="/home" exact={true} component={Home} escopoAcesso={escopoAcesso} />
      <PrivateRoute
        path="/jornada-credito"
        exact={true}
        component={JornadaCredito}
        escopoAcesso={escopoAcesso}
      />
      <PrivateRoute path="/dashboard" exact={true} component={Dashboard} escopoAcesso={escopoAcesso} />

      {/* ROTAS BORDERO - AUTENTICADO */}
      <PrivateRoute path="/borderos" exact={true} component={Borderos} escopoAcesso={escopoAcesso} />
      <PrivateRoute path="/borderos/:bordero_id" component={Wizard} escopoAcesso={escopoAcesso} />
      <PrivateRoute
        path="/borderos-visualizar-titulos/:bordero_id"
        component={VisualizarTitulos}
        escopoAcesso={escopoAcesso}
      />

      {/* ROTAS INSTRUÇÕES - AUTENTICADO */}
      <PrivateRoute path="/instrucoes" exact={true} component={Instrucoes} escopoAcesso={escopoAcesso} />
      <PrivateRoute
        path="/instrucoes/incluir-nova"
        component={IncluirNova}
        escopoAcesso={escopoAcesso}
      />
      <PrivateRoute
        path="/instrucoes-visualizar-titulos/:instrucao_id"
        component={VisualizarTitulosInstrucao}
        escopoAcesso={escopoAcesso}
      />

      {/* OUTRAS ROTAS - AUTENTICADO */}
      <PrivateRoute path="/relatorios" component={Relatorios} escopoAcesso={escopoAcesso} />
      {/* <PrivateRoute path="/tutoriais" component={Tutoriais} escopoAcesso={escopoAcesso} /> */}
      <PrivateRoute path="/retornos" component={Retorno} escopoAcesso={escopoAcesso} />

      {/* ROTAS DE OUTROS MODULOS COMPLEMENTARES */}
      {rotasComplementares.map(dados => {
        if (
          isAuthenticated() &&
          escopoAcesso &&
          escopoAcesso[dados.modulo] &&
          escopoAcesso[dados.modulo].rota &&
          escopoAcesso[dados.modulo].rota[dados.rota]
        ) {
          if (dados.modulo !== 'plataforma_cliente') {
            return (
              <Route
                key={`${dados.modulo}_${dados.rota}`}
                path={`/${dados.modulo}/${dados.rota}`}
                render={props => {
                  return (
                    <PlataformasComplementares
                      rota={dados.rota}
                      modulo={dados.modulo}
                      escopoAcesso={escopoAcesso}
                      {...props}
                    />
                  );
                }}
              />
            );
          } else {
            return null; // Retorno necessário para o uso do MAP
          }
        } else {
          return null; // Retorno necessário para o uso do MAP
        }
      })}

      {/* ROTAS e REDIRECIONAMENTOS DE SISTEMA */}
      <Route
        path="/404"
        render={props => {
          return <NotFound {...props} escopoAcesso={escopoAcesso} />;
        }}
      />
      <Redirect from="*" to="/404" />
    </Switch>
  );
};
