import { Col, FormGroup, Label, Row } from 'reactstrap';
import * as CPF from '@fnando/cpf';
import * as CNPJ from '@fnando/cnpj';
import React from 'react';

export default {
  expandByColumnOnly: true,
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return <i className="fa fa-angle-up expand-icon" />;
    }
    return <i className="fa fa-angle-down expand-icon" />;
  },
  expandHeaderColumnRenderer: () => {
    return '';
  },
  onlyOneExpanding: true,
  renderer: row => (
    <div className="expanded-container">
      <Row className="align-items-center">
        <Col md="2">
          <FormGroup>
            <Label>{CPF.isValid(row.sacado.cpf) ? 'CPF' : 'CNPJ'}</Label>
            <p className="form-control-static">
              {CPF.isValid(row.sacado.cpf) ? CPF.format(row.sacado.cpf) : CNPJ.format(row.sacado.cnpj)}
            </p>
          </FormGroup>
        </Col>
        {row.tipoOperacao.codBanco && (
          <>
            <Col md="5">
              <FormGroup>
                <Label>CMC7:</Label>
                <p className="form-control-static">{row.tipoOperacao.cmc7}</p>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Banco:</Label>
                <p className="form-control-static">{row.tipoOperacao.codBanco}</p>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Agência:</Label>
                <p className="form-control-static">{row.tipoOperacao.codAgencia}</p>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Conta:</Label>
                <p className="form-control-static">{row.tipoOperacao.codcc}</p>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Compensação:</Label>
                <p className="form-control-static">{row.tipoOperacao.compensacao}</p>
              </FormGroup>
            </Col>
          </>
        )}
        {row.tipoOperacao.chaveDanfe && (
          <Col md="4">
            <FormGroup>
              <Label>Chave DANFE:</Label>
              <p className="form-control-static">{row.tipoOperacao.chaveDanfe}</p>
            </FormGroup>
          </Col>
        )}
        {row.tipoOperacao.nf && (
          <Col md="1">
            <FormGroup>
              <Label>Nota Fiscal:</Label>
              <p className="form-control-static">{row.tipoOperacao.nf}</p>
            </FormGroup>
          </Col>
        )}
        {row.cedente && (
          <Col md="5">
            <FormGroup>
              <Label>Cedente: </Label>
              <span className="form-control-static">{` ${CNPJ.format(row.cedente.cnpj)} `}</span>
              <p className="form-control-static">{row.cedente.nome}</p>
            </FormGroup>
          </Col>
        )}
      </Row>
    </div>
  ),
  showExpandColumn: true
};
