import * as CNPJ from '@fnando/cnpj';
import * as CPF from '@fnando/cpf';
import React, { memo, useState } from 'react';
import { searchByCPFOrCNPJ } from '../../../../api/clientSearch';
import strip from '../../../../utils/strip';
import CustomMaskedInput from '../CustomMaskedInput/CustomMaskedInput';
import { CPFMask, CNPJMask, CPFCNPJMask } from '../../../masks/masks';

const CPFCNPJInput = props => {
  const { onSearch, searchHandler, mascaraEspecifica, maxLength = 18, ...otherProps } = props;
  const [loading, setLoading] = useState(false);
  let applyMask = CPFCNPJMask;

  if (mascaraEspecifica === 'CPF') {
    applyMask = CPFMask;
  } else if (mascaraEspecifica === 'CNPJ') {
    applyMask = CNPJMask;
  }

  const searchDocument = (stripped, trueValue) => {
    let propriedades = props;
    if (CNPJ.isValid(trueValue) || CPF.isValid(trueValue)) {
      if (onSearch && !propriedades.form.isSubmitting) {
        setLoading(true);
        searchHandler(stripped)
          .then(res => {
            onSearch(res.data, otherProps.form, otherProps.field);
          })
          .finally(() => {
            setLoading(false);
          })
          .catch(() => {
            onSearch([], otherProps.form, otherProps.field);
          });
      }
    }
  };

  const handlePaste = e => {
    const stripped = strip(e.clipboardData.getData('Text'));
    const trueValue = e.clipboardData.getData('Text');

    searchDocument(stripped, trueValue);

    otherProps.form.setFieldValue(otherProps.field.name, '');
  };

  const handleChange = e => {
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) || // only numbers
      (e.keyCode >= 96 && e.keyCode <= 105) // only numbers (keypad)
      // ((e.ctrlKey || e.metaKey) && (e.keyCode === 86 || e.keyCode === 90)) // ctrl+v || cmd+v || ctrl+z || cmd+z
    ) {
      const stripped = strip(otherProps.field.value);
      const trueValue = otherProps.field.value;

      searchDocument(stripped, trueValue);
    }

    let propriedades = props;
    if (strip(propriedades.field.value) === '' && onSearch) {
      onSearch(null, otherProps.form, otherProps.field);
    }
  };

  return (
    <div className="loadingWrapper">
      <CustomMaskedInput
        maxLength={maxLength}
        onKeyUp={handleChange}
        onPaste={handlePaste}
        {...otherProps}
        mask={applyMask}
      />
      {loading && (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          <span className="sr-only">Carregando...</span>
        </>
      )}
    </div>
  );
};

CPFCNPJInput.defaultProps = {
  searchHandler: searchByCPFOrCNPJ
};

export default memo(CPFCNPJInput);
