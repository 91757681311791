export const TIPOS = [
  {
    code: 1,
    descricao: 'A Vencer'
  },
  {
    code: 2,
    descricao: 'Vencidos'
  },
  {
    code: 3,
    descricao: 'Baixados'
  },
  {
    code: 4,
    descricao: 'Amortizados'
  },
  {
    code: 5,
    descricao: 'Em Aberto'
  },
  {
    code: 6,
    descricao: 'Cheques em Custódia'
  },
  {
    code: 7,
    descricao: 'Recomprados'
  },
  {
    code: 8,
    descricao: 'Todos'
  }
];

export const TIPO_DOCUMENTOS = [
  {
    code: 'DM',
    descricao: 'Duplicata'
  },
  {
    code: 'CH',
    descricao: 'Cheque'
  },
  {
    code: 'NP',
    descricao: 'Nota promissória'
  }
];

export const TIPO_COBRANCAS = [
  {
    code: 'CP',
    descricao: 'Compra'
  },
  {
    code: 'CS',
    descricao: 'Cobrança Simples'
  }
];
