import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';

import ButtonMain from '../../shared/ButtonMain';
import InputMain from '../../shared/InputMain/InputMain';
import { confirmacaoSalvar, confirmacaoResend } from '../../../../api/usuario';

export default ({ logar, tokenCodUser, emailUser, setTela, setMessageLogin }) => {
  const [isPending, setPending] = useState(false);
  const [open, setOpen] = useState(false);
  const [msgCodigoVerificacao, setMsgCodigoVerificacao] = useState('');
  const [contador, setContador] = useState(0);
  const [controle, setControle] = useState(false);
  const [bloqueiaBotaoConfirmar, setBloqueiaBotaoConfirmar] = useState(true);

  const handleFormSubmit = (values, form) => {
    setPending(true);
    values.TOKENVALIDACAO = values.TOKENVALIDACAO.replaceAll(' ', '').toUpperCase();
    values.token_user = tokenCodUser;

    confirmacaoSalvar(values)
      .then(retorno => {
        let retMFA = retorno.data;
        if (retMFA.home) {
          setTela('login');
          logar();
        } else if (retMFA.login) {
          if (retMFA.message) {
            setMessageLogin(retMFA.message);
          }
          setTela('login');
        } else {
          if (retMFA.message) {
            setMsgCodigoVerificacao(retMFA.message);
          }
        }
      })
      .catch(() => {
        setMsgCodigoVerificacao('Ocorreu um erro interno, favor contatar o suporte');
      })
      .finally(() => {
        setPending(false);
        form.setSubmitting(false);
        return false;
      });
  };

  const reenviarToken = () => {
    modalReenviar(false);
    setPending(true);
    let params = { token_user: tokenCodUser };

    confirmacaoResend(params)
      .then(retorno => {
        let retMFA = retorno.data;
        let countSec = 60;
        if (retMFA.SEGUNDOS_FALTANTES) {
          countSec = retMFA.SEGUNDOS_FALTANTES;
        }
        setContador(countSec);
        if (retMFA.login) {
          if (retMFA.message) {
            setMessageLogin(retMFA.message);
          }
          setTela('login');
        } else {
          if (retMFA.message) {
            setMsgCodigoVerificacao(retMFA.message);
          }
        }
      })
      .catch(() => {
        setMsgCodigoVerificacao('Ocorreu um erro interno, favor contatar o suporte');
      })
      .finally(() => {
        setPending(false);
        return false;
      });
  };

  const limpaMsgErro = event => {
    setMsgCodigoVerificacao('');
    if (event.target.value !== '') {
      setBloqueiaBotaoConfirmar(false);
    } else {
      setBloqueiaBotaoConfirmar(true);
    }
  };

  useEffect(() => {
    if (contador !== 0 && !controle) {
      let display = document.getElementById('timer');
      contadorDecrescente(contador, display);
      setControle(true);
    }
  });

  const contadorDecrescente = (timer, elemento) => {
    let minutes, seconds;
    var meuInterval = setInterval(function() {
      try {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        elemento.textContent = minutes + ':' + seconds;
        timer--;
        if (timer < -1) {
          pararContagemRegressiva(meuInterval);
        }
      } catch (err) {
        pararContagemRegressiva(meuInterval);
      }
    }, 1000);
  };

  const pararContagemRegressiva = meuInterval => {
    clearInterval(meuInterval);
    setContador(0);
    setControle(false);
  };

  const modalReenviar = acao => {
    setOpen(acao);
  };

  return (
    <Grid container>
      <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
      <Grid item xl={8} lg={8} md={8} sm={10} xs={10} sx={{ p: '50px 0 100px 0' }}>
        <Typography variant="h5" className="texto-cor-principal texto-negrito texto-centro">
          Porfavor, agora digite o código que enviamos para o seu email...
        </Typography>

        <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro mt-1">
          <>Verifique o código em seu e-mail </>
          {emailUser}
          <> e digite ele logo abaixo para acessar o sistema.</>
        </Typography>

        <Formik
          initialValues={{
            TOKENVALIDACAO: ''
          }}
          validationSchema={Yup.object().shape({
            TOKENVALIDACAO: Yup.string()
              .required('Informe o código.')
              .nullable()
              .test({
                exclusive: true,
                message: 'Digite o código enviado',
                name: 'TOKENVALIDACAO',
                test: value => {
                  if (value !== '' && value !== undefined) {
                    value = value.replaceAll(' ', '');
                    if (value.length === 6) {
                      return true;
                    }
                  }
                  return false;
                }
              })
          })}
          onSubmit={handleFormSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          render={({ isSubmitting }) => {
            return (
              <>
                <Form>
                  <Grid container className="container-input-confirmacao-codigo">
                    <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                    <Grid item xl={8} lg={8} md={8} sm={10} xs={10}>
                      <Field
                        type="text"
                        name="TOKENVALIDACAO"
                        id="TOKENVALIDACAO"
                        size="small"
                        inputProps={{ maxLength: 7 }}
                        variant="standard"
                        inputClassName="w-100 input-confirmacao-codigo"
                        className="w-100 font-size-32"
                        boxClassName="pad-topbot-30"
                        placeholder="---  ---"
                        uppercase={true}
                        erro={msgCodigoVerificacao}
                        component={InputMain}
                        autoComplete="off"
                        onChangeInput={limpaMsgErro}
                        disabled={isPending || isSubmitting}
                        tipoMascara="CodigoConfirmacaoMask"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="pad-topbot-10">
                    <Grid item xl={4} lg={4} md={3} sm={3} xs={12} />
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                      <ButtonMain
                        type="submit"
                        tipoBotao="principal"
                        className="btn-confirmar-codigo"
                        disabled={isPending || isSubmitting || bloqueiaBotaoConfirmar}
                      >
                        Confirmar
                      </ButtonMain>
                    </Grid>
                  </Grid>
                  <Grid container className="pad-topbot-10">
                    <Grid item xl={4} lg={4} md={3} sm={2} xs={12} />
                    <Grid item xl={4} lg={4} md={6} sm={8} xs={12}>
                      {contador === 0 ? (
                        <ButtonMain
                          tipoBotao="transparente"
                          disabled={isPending || isSubmitting}
                          onClick={() => modalReenviar(true)}
                        >
                          Reenviar código
                        </ButtonMain>
                      ) : (
                        <div className="texto-cor-cinza-escuro texto-centro">
                          <span>Aguarde para enviar novamente em </span>
                          <b>
                            <span id="timer" className="texto-cor-principal">
                              00:00
                            </span>
                          </b>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Dialog
                    open={open}
                    keepMounted
                    onClose={() => modalReenviar(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
                      Reenviar código
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Deseja realmente reenviar o código de confirmação?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Grid container>
                        <Grid item xl={4} lg={4} md={4} sm={2} />
                        <Grid item xl={4} lg={4} md={4} sm={5} xs={6}>
                          <ButtonMain tipoBotao="transparente" onClick={() => modalReenviar(false)}>
                            Cancelar
                          </ButtonMain>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={5} xs={6}>
                          <ButtonMain
                            tipoBotao="principal"
                            classesAdicional="btn-reenviar-codigo"
                            onClick={reenviarToken}
                            autoFocus
                          >
                            Reenviar
                          </ButtonMain>
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </Dialog>
                </Form>
              </>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
