import React from 'react';
import { Typography, TextField, OutlinedInput } from '@mui/material';
import { conformToMask } from 'react-text-mask';
import * as masks from '../../../../shared/masks/masks';
import { moneyFullFormat, unmaskValue } from '../../../../utils/format';

const TextFieldMain = ({ field: { value = '', ...fields }, form: { errors }, ...props }) => {
  const {
    inputClassName,
    boxClassName,
    adicional,
    helper,
    uppercase = false,
    erro = '',
    field,
    tipoMascara,
    onChangeInput,
    onBlurInput,
    tipoComponente,
    setFieldValue,
    ...restProps
  } = props;

  if (uppercase && typeof value === 'string') {
    value = value.toUpperCase();
  }
  // quando o valor não for passado, settar como string vazia
  if (!value) {
    value = '';
  }

  if (tipoMascara && masks[tipoMascara]) {
    value = conformToMask(value || '', masks[tipoMascara], {
      guide: false,
      keepCharPositions: true
    }).conformedValue;
  } else if (tipoMascara === 'number-float') {
    value = moneyFullFormat(unmaskValue(value), true);
  } else if (tipoMascara === 'number-money') {
    value = moneyFullFormat(unmaskValue(value));
  } else if (tipoMascara === 'number-percentual') {
    value = moneyFullFormat(unmaskValue(value), true);
  } else if (tipoMascara === 'number-inteiro') {
    value = value ? parseInt(value) : 0;
  }

  var nomeInput = fields.name;
  var erroTexto = errors[nomeInput] ? errors[nomeInput] : '';
  erroTexto = erro !== '' ? erro : erroTexto;
  var erroExiste = erroTexto !== '' ? true : false;

  const onChangeTextField = event => {
    if (typeof fields.onChange === 'function') {
      fields.onChange(event);
    }
    if (typeof onChangeInput === 'function') {
      onChangeInput(event);
    }
    if (tipoMascara === 'number-percentual' && typeof setFieldValue === 'function') {
      var valor = unmaskValue(event.target.value);
      if (valor > 100) {
        setFieldValue(fields.name, 100);
      }
    }
  };

  const onBlurTextField = event => {
    if (typeof fields.onBlur === 'function') {
      fields.onBlur(event);
    }
    if (typeof onBlurInput === 'function') {
      onBlurInput(event);
    }
  };

  var classes = inputClassName
    ? inputClassName.indexOf('w-') === -1
      ? `${inputClassName} w-100`
      : inputClassName
    : 'w-100';

  return (
    <>
      {tipoComponente === 'outlineInput' ? (
        <OutlinedInput
          {...restProps}
          name={fields.name}
          onChange={onChangeTextField}
          onBlur={onBlurTextField}
          value={value}
          error={erroExiste}
          className={classes}
        />
      ) : (
        <TextField
          {...restProps}
          name={fields.name}
          onChange={onChangeTextField}
          onBlur={onBlurTextField}
          value={value}
          error={erroExiste}
          className={classes}
        />
      )}

      {erroTexto !== '' ? (
        <Typography className="texto-danger label-padding">{erroTexto}</Typography>
      ) : (
        ''
      )}
      {adicional}
      {helper && (
        <Typography variant="subtitle2" className="texto-cor-cinza-escuro">
          {helper}
        </Typography>
      )}
    </>
  );
};
export default TextFieldMain;
