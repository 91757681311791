import moment from 'moment';

moment.locale('pt-br');

const UNICODE_NON_BREAKING_SPACE = String.fromCharCode(160);
const USUAL_SPACE = String.fromCharCode(32);

export const percentualFormat = (value = 0) => {
  if (isNaN(value)) {
    value = 0;
  }

  // REF: https://medium.com/@jotafeldmann/desafios-da-localiza%C3%A7%C3%A3o-e-teste-unit%C3%A1rio-no-browser-ad4e0aa2fef3
  return String(
    new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }).format(value)
  ).replace(UNICODE_NON_BREAKING_SPACE, USUAL_SPACE);
};

export const moneySimpleFormat = (value = 0) => {
  if (isNaN(value)) {
    value = 0;
  }

  // REF: https://medium.com/@jotafeldmann/desafios-da-localiza%C3%A7%C3%A3o-e-teste-unit%C3%A1rio-no-browser-ad4e0aa2fef3
  return String(
    new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    }).format(value)
  ).replace(UNICODE_NON_BREAKING_SPACE, USUAL_SPACE);
};

export const moneyFullFormat = (value = 0, hideSymbol = false) => {
  if (isNaN(value)) {
    value = 0;
  }

  const options = {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: hideSymbol ? 'decimal' : 'currency'
  };

  // REF: https://medium.com/@jotafeldmann/desafios-da-localiza%C3%A7%C3%A3o-e-teste-unit%C3%A1rio-no-browser-ad4e0aa2fef3
  return String(new Intl.NumberFormat('pt-BR', options).format(value)).replace(
    UNICODE_NON_BREAKING_SPACE,
    USUAL_SPACE
  );
};

export const dateFormat = value => {
  const newDate = value && moment(value, moment.HTML5_FMT.DATE);

  if (newDate && newDate.isValid()) {
    return moment(value, moment.HTML5_FMT.DATE).format('DD/MM/YYYY');
  } else if (value) {
    return 'Data Inválida';
  }

  return '';
};

export const formatarData = value => {
  if (typeof value !== 'string') return value;
  let spliter = value.indexOf('/') !== -1 ? '/' : value.indexOf('-') !== -1 ? '-' : '';
  if (spliter !== '') {
    let gluer = spliter === '/' ? '-' : '/';
    return value.split(spliter)[2] + gluer + value.split(spliter)[1] + gluer + value.split(spliter)[0];
  } else {
    return value;
  }
};

export const unmaskValue = (maskedValue = 0) => {
  if (!maskedValue) {
    return 0;
  } else if (typeof maskedValue === 'number') {
    return maskedValue;
  } else if (typeof maskedValue === 'string') {
    if (maskedValue.indexOf('.') !== -1 && maskedValue.indexOf(',') === -1) {
      return parseFloat((maskedValue || '').toString().replace(/\D/g, '') || 0, 10);
    }

    return parseFloat((maskedValue || '').toString().replace(/\D/g, '') || 0, 10) / 100;
  }
};

export const maskCMC7 = (value = '') => {
  if (!value) {
    return '';
  }

  value = value.replace(/[/\D*]/g, ''); // removendo mascara
  if (value && (value.length === 30 || value.length === 34)) {
    const regexCmc7 = /(\d{8})(\d{10})(\d{12})/;
    return value.replace(regexCmc7, '<$1<$2>$3Ç');
  }
  return '';
};

export const getOnlyNumbers = (value = '') => {
  if (!value) {
    return '';
  }
  return value.replace(/[^0-9]+/g, '');
};

export const deletePropriedades = (objeto, propriedades) => {
  for (const propName of propriedades) {
    propName in objeto && delete objeto[propName];
  }
  return objeto;
};

export const scrollToRef = ref => {
  if (ref.current !== null) {
    ref.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    //window.scrollTo(0, ref.current.offsetTop);
    //ref.current.focus();
  }
};

export const scrollToId = id => {
  if (id !== '' && id !== null) {
    var element = document.getElementById(id);
    if (element !== null && typeof element !== 'undefined') {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      element.focus();
    }
  }
};

export const formatarBorderoTitulo = titulo => {
  titulo.meta = {
    visible: true,
    disabled: false,
    checked: false
  };
  if (titulo.duplicata) {
    if (titulo.duplicata.vencimento != null && titulo.duplicata.vencimento.indexOf('-') !== -1) {
      titulo.duplicata.vencimento = dateFormat(titulo.duplicata.vencimento);
    }
  } else if (titulo.cheque) {
    if (titulo.cheque.vencimento != null && titulo.cheque.vencimento.indexOf('-') !== -1) {
      titulo.cheque.vencimento = dateFormat(titulo.cheque.vencimento);
    }
  }

  return titulo;
};

export const transformTableTitulosData = (data = []) => {
  return data.map(value => {
    const { sacado, duplicata, cheque, ...rest } = value;

    let newData = {
      ...rest,
      sacado: sacado.fisica || sacado.juridica,
      tipoOperacao: cheque || duplicata,
      originalData: value
    };

    if (sacado && sacado.juridica) {
      newData.sacado.nome = sacado.juridica.razaoSocial || sacado.juridica.nomeFantasia;
    }

    return newData;
  });
};

export const calcularOpcoesAnoMes = qtdMeses => {
  let dataBase = moment()
    .subtract(qtdMeses - 1, 'months')
    .format('YYYY[-]MM[-01]');
  let dataMaxima = moment().format('YYYY[-]MM[-01]');
  let opcoes = [];
  while (!moment(dataBase).isSame(dataMaxima, 'year') || !moment(dataBase).isSame(dataMaxima, 'month')) {
    opcoes.push({
      label: moment(dataBase).format('MMMM[/]YYYY'),
      valor: moment(dataBase).format('YYYY[-]MM')
    });
    dataBase = moment(dataBase)
      .add(1, 'month')
      .format('YYYY[-]MM[-01]');
  }
  opcoes.push({
    label: moment(dataMaxima).format('MMMM[/]YYYY'),
    valor: moment(dataMaxima).format('YYYY[-]MM')
  });
  return opcoes.reverse();
};

export const validarValorVazioPositivo = valor => {
  valor = valor ? parseFloat(valor) : 0;
  valor = valor > 0 ? valor : 0;
  return valor;
};

export const likeStringMatch = (inputString, pattern) => {
  const regexPattern = new RegExp(pattern.replace(/%/g, '.*'), 'i');
  return regexPattern.test(inputString);
};
