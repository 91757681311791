import { Field } from 'formik';
import React, { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { CurrencyInput, DateTimePicker, Input } from '../../../../../shared';

const AlteracaoNdocTituloForm = ({ submitting, values }) => {
  return (
    <div className="mt-3">
      {Object.keys(values.listaNdocAjuste).map(index => (
        <Row key={index} className="card-ajuste-ndoc mt-2 mb-3 p-2">
          <Col lg="2" md="2">
            <Field
              component={Input}
              type="text"
              label="Nº DO DOC."
              name={`listaNdocAjuste.${index}.ndoc`}
              disabled={submitting}
            />
          </Col>
          <Col lg="4" md="4">
            <Field
              type="text"
              label="Sacado"
              name={`listaNdocAjuste.${index}.razaoSocial`}
              id="name"
              disabled={true}
              component={Input}
            />
          </Col>
          <Col lg="2" md="2">
            <Field
              component={Input}
              type="text"
              label="Nota Fiscal"
              name={`listaNdocAjuste.${index}.nf`}
              disabled={true}
            />
          </Col>
          <Col lg="2" md="2">
            <Field
              component={DateTimePicker}
              type="text"
              label="Vencimento"
              name={`listaNdocAjuste.${index}.vencimento`}
              disabled={true}
            />
          </Col>
          <Col lg="2" md="2">
            <Field
              component={CurrencyInput}
              type="text"
              label="Valor Bruto"
              name={`listaNdocAjuste.${index}.valor`}
              disabled={true}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default memo(AlteracaoNdocTituloForm);
