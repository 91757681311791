import React from 'react';
import { Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import InputMain from '../../../Onboarding/shared/InputMain/InputMain';
import ButtonMain from '../../../Onboarding/shared/ButtonMain';
import SelectMain from '../../../Onboarding/shared/InputMain/SelectMain';
import { tiposImovelGarantia } from '../../../Onboarding/shared/Enums/EnumsOnboarding';
import { unmaskValue } from '../../../../utils/format';

export default ({ dadosFormulario, setDadosFormulario, setAvancarEtapa }) => {
  const formatarDadosFormulario = dadosForm => {
    let newDados = {};
    newDados.valor_imovel = dadosForm.valor_imovel;
    newDados.valor_garantia = dadosForm.valor_garantia;
    newDados.tipo_imovel = dadosForm.tipo_imovel;
    newDados.tipo_garantia = 'IM';
    return newDados;
  };

  const formataDadosPtBr = dadosForm => {
    dadosForm.valor_imovel = dadosForm.valor_imovel
      ? dadosForm.valor_imovel.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;
    dadosForm.valor_garantia = dadosForm.valor_garantia
      ? dadosForm.valor_garantia.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;
    dadosForm.tipo_garantia = 'IM';
    return dadosForm;
  };

  const dadosIniciaisForm =
    dadosFormulario && Object.keys(dadosFormulario).length > 0 ? formataDadosPtBr(dadosFormulario) : {};

  const handleFormSubmit = (values, form) => {
    form.setSubmitting(true);
    let dadosFormatado = formatarDadosFormulario(values);
    setDadosFormulario(dadosFormatado);
    setTimeout(() => {
      form.setSubmitting(false);
      setAvancarEtapa('STEP2');
    }, 1000);
  };

  return (
    <Formik
      initialValues={dadosIniciaisForm}
      validationSchema={Yup.object().shape({
        tipo_imovel: Yup.string()
          .nullable()
          .required('Informe o tipo do imóvel'),
        valor_imovel: Yup.string()
          .nullable()
          .required('Informe o valor do imóvel')
          .test('valor_imovel', 'Valor do imóvel é inválido', value => {
            if (value && value.length > 0) {
              let newValue = unmaskValue(value).toString();
              return value && newValue.length <= 20 && value && newValue > 0;
            }
          }),
        valor_garantia: Yup.string()
          .nullable()
          .required('Informe o valor solicitado')
          .test('valor_garantia', 'Valor solicitado é inválido', value => {
            if (value && value.length > 0) {
              let newValue = unmaskValue(value).toString();
              return value && newValue.length <= 20 && value && newValue > 0;
            }
          })
          .test('valor_garantia', 'Valor solicitado mínimo é inválido', value => {
            if (value && value.length > 0) {
              let newValue = unmaskValue(value).toString();
              return parseFloat(newValue) >= 70000;
            }
          })
          .test('valor_garantia', 'Valor solicitado máximo excedido', value => {
            if (value && value.length > 0) {
              let newValue = unmaskValue(value).toString();
              return parseFloat(newValue) <= 2000000;
            }
          })
      })}
      onSubmit={handleFormSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form className="jornada-credito-formulario-imovel">
          <ConnectedFocusError />
          <Field
            name="tipo_imovel"
            titulo="Tipo do imóvel"
            id="tipo_imovel"
            size="small"
            placeholder="Selecione o tipo do imóvel"
            component={SelectMain}
            autoComplete="off"
            disabled={isSubmitting}
            opcoes={tiposImovelGarantia}
          />

          <Field
            type="text"
            name="valor_imovel"
            titulo="Valor do imóvel"
            id="valor_imovel"
            size="small"
            placeholder="Digite o valor do imóvel"
            component={InputMain}
            autoComplete="off"
            disabled={isSubmitting}
            tipoMascara="number-float"
            inputProps={{
              maxLength: 21
            }}
          />

          <Field
            type="text"
            name="valor_garantia"
            titulo="Qual valor você quer solicitar?"
            id="valor_garantia"
            size="small"
            placeholder="R$ 2.000.000,00"
            component={InputMain}
            autoComplete="off"
            disabled={isSubmitting}
            tipoMascara="number-float"
            inputProps={{
              maxLength: 21
            }}
          />

          <Grid container className="mt-4">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ButtonMain type="submit" tipoBotao="principal" disabled={isSubmitting}>
                Continuar
              </ButtonMain>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
