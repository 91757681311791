const stepDefinition = [
  {
    number: 1,
    title: 'Upload'
  },
  {
    number: 2,
    title: 'Seleção'
  },
  {
    number: 3,
    title: 'Finalização'
  }
];

export default stepDefinition;
