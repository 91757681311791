import API from './api';

export const getEstadoOne7MaisCredito = (cnpjEmpresa, codEntrada) => {
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/busca-estado-one7-mais-credito/${cnpjEmpresa}`,
    {
      params: { cod_entrada: codEntrada }
    }
  );
};

export const incluiPropostaOne7MaisCredito = dados => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/inclui-proposta-one7-mais-credito`,
    dados
  );
};

export const finalizaPropostaOne7MaisCredito = dados => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/finaliza-proposta-one7-mais-credito`,
    dados
  );
};
