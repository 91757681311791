export const postLink = (url, parametros, target) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = url;
  form.target = target;

  Object.keys(parametros).map(key => {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = parametros[key];
    form.appendChild(input);
    return key;
  });

  document.body.appendChild(form);
  form.submit();
};

export const sideScroll = (element, direction, speed = 35, distance = 100, step = 10) => {
  var scrollAmount = 0;
  var slideTimer = setInterval(function() {
    if (direction === 'left') {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
};

export const ordenarValoresGrid = (dados, campo, tipoCampo, order) => {
  let novosDados = Object.values(dados).sort(function(v1, v2) {
    if (tipoCampo === 'string') {
      v1 = v1[campo];
      v2 = v2[campo];
      v1 = v1 !== null && v1 !== undefined ? v1.trim() : '';
      v2 = v2 !== null && v2 !== undefined ? v2.trim() : '';

      if (order === 'asc') {
        return v1.localeCompare(v2, 'pt-BR');
      } else {
        v1 = v1.localeCompare(v2, 'pt-BR');
        return v1 < 0 ? 1 : v1 > 0 ? -1 : 0;
      }
    } else if (tipoCampo === 'float') {
      v1 = parseFloat(v1.valorFace);
      v2 = parseFloat(v2.valorFace);
      v1 = isNaN(v1) ? -1 : v1;
      v2 = isNaN(v2) ? -1 : v2;

      if (order === 'asc') {
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
      } else {
        return v1 > v2 ? -1 : v1 < v2 ? 1 : 0;
      }
    } else {
      return v1 - v2;
    }
  });
  return novosDados;
};
