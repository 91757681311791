import React, { useState, useEffect } from 'react';
import { Card, CardBody, FormGroup, Label } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

//import StepWizard from 'react-step-wizard';
import * as CPF from '@fnando/cpf';
import * as CNPJ from '@fnando/cnpj';
import Main from '../../Main/Main';

import VoltarIcon from '../../../shared/icons/Voltar';
import { moneyFullFormat } from '../../../utils/format';
import { getInstrucoesListaResumidaTitulos } from '../../../api/instrucoes';
import { getClienteAtual } from '../../../shared';
import SkeletonLoader from '../../../shared/components/common/SkeletonLoader';
import Grid from '../../commom/Grid';
import HistoricoAcesso from '../../commom/HistoricoAcesso';

const VisualizarTitulosInstrucao = props => {
  const { match: { params: { instrucao_id: instrucaoID } = {} } = {} } = props;
  const [instrucao, setInstrucao] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [message, setMessage] = useState();
  const clienteAtual = getClienteAtual();
  //Ao carregar a pagina, caso o tamanho da janela seja menor ou igual a 600px ja carrega o menu collapsado
  const [collapseMenuRight, setCollapseMenuRight] = useState(window.innerWidth <= 600 ? true : false);
  const hist = useHistory();

  useEffect(() => {
    if (clienteAtual && clienteAtual.id === 'todos') {
      hist.push('/home');
    }
  }, [clienteAtual]);

  const updateStateInstrucao = data => {
    if (data) {
      setInstrucao(data);
    } else {
      setIsPending(true);
      getInstrucoesListaResumidaTitulos(instrucaoID)
        .then(res => {
          const updateInstrucao = {
            ...res.data[0],
            titulos: res.data
          };
          // console.log(updateInstrucao);
          setInstrucao(updateInstrucao);
        })
        .catch(err => {
          const {
            response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
          } = err;
          setMessage(message);
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  };

  useEffect(() => {
    updateStateInstrucao();
  }, []);

  const formatarCpfCnpj = (cellContent, row) => {
    return (
      <>
        {CPF.isValid(row.cfpCnpjSacado) ? CPF.format(row.cfpCnpjSacado) : CNPJ.format(row.cfpCnpjSacado)}
      </>
    );
  };

  const mostraNovoVencimento = () => {
    if (instrucao.numeroTipoInstrucao && instrucao.numeroTipoInstrucao === '3') {
      return '';
    }
    return 'esconderElemento';
  };

  return (
    <Main
      title="Instruções"
      className={'instrucoes-grid instrucoes ' + (collapseMenuRight ? 'collapsed' : '')}
      {...props}
    >
      <Card>
        <CardBody>
          <div className="wizard visualizar-titulos">
            <div className="selection-step">
              <div className="row row-content">
                <div
                  className={
                    (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                    ' col-12 content-body '
                  }
                >
                  <div className="body">
                    <div className="d-flex align-items-center">
                      <h1 className="title" style={{ width: '100%' }}>
                        Informações da Instrução
                        {collapseMenuRight && (
                          <button
                            type="button"
                            onClick={() => setCollapseMenuRight(false)}
                            title="Filtros"
                            className="btn btn-outline-secondary btn-show-menu-right float-right"
                            style={{ minWidth: 'auto' }}
                          >
                            <i className="fa fa-angle-left" />
                            <span>Info. Instrução</span>
                          </button>
                        )}
                      </h1>
                    </div>

                    <SkeletonLoader isPending={isPending} width="60%" height="30px" count={8}>
                      {message && (
                        <div className="alert alert-danger animated fadeIn" role="alert">
                          {message}
                        </div>
                      )}
                      <Grid
                        keyField="codigoRow"
                        data={instrucao.titulos || []}
                        //expandRow={expandRow}
                        tableSearch={true}
                        classes="table-layout-auto"
                        configuracaoColunas={[
                          {
                            dataField: 'cfpCnpjSacado',
                            text: 'CPF/CNPJ',
                            formatacao: formatarCpfCnpj,
                            tdAlign: 'left'
                          },
                          {
                            dataField: 'nomeSacado',
                            text: 'SACADO',
                            formatacao: 'texto',
                            tipoOrdenacao: 'texto'
                          },
                          {
                            dataField: 'documento',
                            text: 'Nº DO DOC.',
                            formatacao: 'texto',
                            tipoOrdenacao: 'texto'
                          },
                          {
                            dataField: 'vencimentoTitulo',
                            text: 'VENCIMENTO',
                            formatacao: 'dataOuIfem',
                            tipoOrdenacao: 'data'
                          },
                          {
                            dataField: 'novoVencimento',
                            text: 'NOVO VENCIMENTO',
                            formatacao: 'dataOuIfem',
                            tipoOrdenacao: 'data',
                            classes: mostraNovoVencimento(),
                            headerClasses: mostraNovoVencimento()
                          },
                          {
                            dataField: 'valorTitulo',
                            text: 'VALOR TÍTULO',
                            formatacao: 'valor'
                          }
                        ]}
                      />
                    </SkeletonLoader>
                  </div>
                  <div className="footer d-flex justify-content-between">
                    <Link
                      className="mr-auto btn-back btn btn-outline-primary btn-lg"
                      to="/instrucoes"
                      disabled={isPending}
                    >
                      <i className="svg-icon">
                        <VoltarIcon />
                      </i>
                      Voltar
                    </Link>
                  </div>
                </div>
                {!collapseMenuRight && (
                  <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                    <div className="fixed-side">
                      {instrucao && instrucao.id && (
                        <>
                          <div
                            className="header cursor-pointer"
                            onClick={() => setCollapseMenuRight(true)}
                            onKeyPress={() => setCollapseMenuRight(true)}
                            role="button"
                            tabIndex={0}
                          >
                            <h4 className="title">
                              Detalhes Instrução
                              <i className="fa fa-angle-right float-right" />
                            </h4>
                          </div>
                          <div className="info infoBordero">
                            <FormGroup>
                              <Label>Nº Instrução:</Label>
                              <p className="form-control-static bold-font">{instrucao.id}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Status:</Label>
                              <p
                                className={
                                  'form-control-static text-' + instrucao.statusAnaliseInstrucao.cor
                                }
                              >
                                {instrucao.statusAnaliseInstrucao.descricao}
                              </p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Cliente:</Label>
                              <p className="form-control-static">{clienteAtual.razaoSocial}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Tipo de Instrução:</Label>
                              <p className="form-control-static">{instrucao.tipoInstrucao}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Vencimento do boleto solicitado:</Label>
                              <p className="form-control-static">{instrucao.vencimento}</p>
                            </FormGroup>
                            {instrucao.numeroTipoInstrucao && instrucao.numeroTipoInstrucao === '3' && (
                              <FormGroup>
                                <Label>Vencimento do boleto aprovado:</Label>
                                <p className="form-control-static total-price">
                                  {instrucao.vencimentoProrrogado}
                                </p>
                              </FormGroup>
                            )}
                            <FormGroup>
                              <Label>Total bruto:</Label>
                              <p className="form-control-static total-price">
                                {moneyFullFormat(instrucao.valor)}
                              </p>
                            </FormGroup>
                            {instrucao.valorLiberado &&
                              instrucao.statusAnaliseInstrucao.cor === 'success' && (
                                <FormGroup>
                                  <Label>Valor liberado:</Label>
                                  <p className="form-control-static total-price">
                                    {moneyFullFormat(instrucao.valorLiberado)}
                                  </p>
                                </FormGroup>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <HistoricoAcesso codTela={1178} />
    </Main>
  );
};

export default VisualizarTitulosInstrucao;
