import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import stringDotNotationToObject from '../../../../utils/stringDotNotationToObject';

const NumberInput = props => {
  const {
    field,
    form,
    labelClassName,
    label,
    id,
    additional,
    helper,
    uppercase = false,
    ...restProps
  } = props;

  const { name } = field;
  const { errors, touched, setFieldValue } = form;

  let { value = '' } = field;
  value = value === null ? '' : value;

  const numberRegex = /\D/g;

  const error = name.includes('.') ? stringDotNotationToObject(name, errors) : (errors || {})[name];

  const touchedField = name.includes('.')
    ? stringDotNotationToObject(name, touched)
    : (touched || {})[name];

  if (uppercase && typeof value === 'string') {
    value = value.toUpperCase();
  }

  const handleChange = event => {
    value = (event.target.value || '').replace(numberRegex, '');
    setFieldValue(name, value);
  };

  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id} className={`${labelClassName ? labelClassName : ''}`}>
          {label}
        </Label>
      )}
      <Input
        {...restProps}
        {...field}
        id={id}
        onChange={handleChange}
        value={value}
        invalid={!!(touchedField && error)}
      />
      {touchedField && error ? <FormFeedback>{error}</FormFeedback> : ''}
      {additional}
      {helper && <small className="form-text text-muted">{helper}</small>}
    </FormGroup>
  );
};
export default NumberInput;
