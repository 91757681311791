/* eslint-disable react/no-danger */
import { Field, Form, Formik } from 'formik';
import React, { useState, useEffect, memo } from 'react';
import swal from '@sweetalert/with-react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Alert
} from 'reactstrap';
import { Accordion, Card } from 'react-bootstrap';
import * as Yup from 'yup';
import { Input, Radio, Select, SkeletonLoader } from '../../../shared';
import { postParecer } from '../../../api/instrucoes';

const ModalParecerInstrucao = props => {
  const { modal, className = '', toggle } = props;
  const [tiposParecerSelect, setTiposParecerSelect] = useState([]);
  //const [encaminharParaSelect, setEncaminharParaSelect] = useState([]);
  const [form, setForm] = useState({});
  const [activeTab, setActiveTab] = useState('1');
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState();
  const [enviaEmail, setEnviaEmail] = useState('N');
  const [bloquearAba, setBloquearAba] = useState(false);

  const isAlteracao = modal.titulo;

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const initialValues = {
    enviarEmail: '',
    de: '',
    para: '',
    assunto: '',
    codTipoParecer: '',
    codUsuarioEncaminhar: '',
    parecer: ''
  };

  useEffect(() => {
    const tiposForSelect = modal.tiposParecer.map(tipo => {
      return {
        value: tipo.codTipoParecer,
        label: tipo.descricao
      };
    });
    setTiposParecerSelect(tiposForSelect);
    /* const encaminharParaForSelect = modal.encaminharPara.map(user => {
      return {
        value: user.codUsuario,
        label: user.nome.toUpperCase()
      };
    });*/
    //setEncaminharParaSelect(encaminharParaForSelect);

    //Se Status do parecer for diferente de Analise vai bloquear a inclsão do novo parecer e bloquear a incluir instruções
    if (modal.statusAnaliseInstrucao !== '2') {
      setActiveTab('2');
      setBloquearAba(true);
    } else {
      setActiveTab('1');
      setBloquearAba(false);
    }
  }, []);

  const formSchema = () => {
    let baseSchema;
    const validationSchema = {
      de: Yup.string()
        .email('E-mail inválido')
        .nullable()
        .test('de', 'Campo obrigatório', value => {
          if (enviaEmail && enviaEmail === 'N') {
            return true;
          } else {
            return value && value.toString().trim() !== '';
          }
        }),
      para: Yup.string()
        .nullable()
        .test('para', 'Campo obrigatório', value => {
          if (enviaEmail && enviaEmail === 'N') {
            return true;
          } else {
            return value && value.toString().trim() !== '';
          }
        }),
      assunto: Yup.string()
        .nullable()
        .test('para', 'Campo obrigatório', value => {
          if (enviaEmail && enviaEmail === 'N') {
            return true;
          } else {
            return value && value.toString().trim() !== '';
          }
        }),
      parecer: Yup.string()
        .nullable()
        .required('Insira o parecer')
        .test('parecer', 'Campo obrigatório', value => {
          return value && value.toString().trim() !== '';
        }),
      codTipoParecer: Yup.string()
        .nullable()
        .required('Informe o tipo do parecer.')
    };
    baseSchema = Yup.object().shape(validationSchema);
    return baseSchema;
  };

  const handleFormSubmit = (values, formProps) => {
    setSubmitting(true);
    values.idInstrucao = modal.idInstrucao;
    values.cliente = modal.cliente;
    values.enviarEmail = enviaEmail;
    postParecer(values)
      .then(() => {
        formProps.resetForm();
        swal({
          title: 'Sucesso!',
          text: 'Parecer incluído com sucesso',
          icon: 'success'
        });
        toggle({});
      })
      .catch(err => {
        const {
          response: {
            data: { error: { message = 'Erro inesperado ao incluir parecer' } = {} } = {}
          } = {}
        } = err;
        setMessage(message);
      })
      .finally(() => {
        setSubmitting(false);
        form.setSubmitting(false);
      });
  };

  const handleButtonIncluir = () => {
    form.submitForm();
  };

  const handlerButtonfechar = () => {
    // Fecha ou abre o modal
    toggle({});
  };

  const handlerEnviarEmailOnChange = e => {
    setEnviaEmail(e.target.value);
  };

  return (
    <Modal
      isOpen={modal.show}
      toggle={toggle}
      className={`modalInclusaoTitulo ${className}`}
      size="lg"
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Pareceres</ModalHeader>
      <ModalBody>
        {!isAlteracao && (
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${activeTab === '1' ? 'active' : ''}`}
                onClick={() => {
                  toggleTab('1');
                }}
                disabled={bloquearAba}
              >
                Incluir Parecer
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === '2' ? 'active' : ''}`}
                onClick={() => {
                  toggleTab('2');
                }}
              >
                Pareceres
              </NavLink>
            </NavItem>
          </Nav>
        )}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <>
              {message && (
                <div className="alert alert-danger animated fadeIn" role="alert">
                  {message}
                </div>
              )}
              <Formik
                ref={node => setForm(node)}
                initialValues={initialValues}
                validationSchema={formSchema}
                onSubmit={handleFormSubmit}
                render={() => {
                  return (
                    <Form>
                      <SkeletonLoader width="100%" height="30px" count={8} widthRandomness={1}>
                        <Row>
                          <Col md="3">
                            <Field
                              label="Não enviar E-mail"
                              name="enviarEmail"
                              checked={enviaEmail === 'N'}
                              value="N"
                              onChange={handlerEnviarEmailOnChange}
                              autoComplete="off"
                              component={Radio}
                            />
                          </Col>
                          <Col md="3">
                            <Field
                              label="Enviar email"
                              name="enviarEmail"
                              checked={enviaEmail === 'S'}
                              value="S"
                              onChange={handlerEnviarEmailOnChange}
                              autoComplete="off"
                              component={Radio}
                            />
                          </Col>
                        </Row>
                        {enviaEmail && enviaEmail === 'S' && (
                          <>
                            <Row>
                              <Col md="12">
                                <Field
                                  type="email"
                                  label="De"
                                  name="de"
                                  disabled={submitting}
                                  helper="(exemplo1@email.com.br)"
                                  autoComplete="off"
                                  component={Input}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <Field
                                  type="text"
                                  label="Para"
                                  name="para"
                                  disabled={submitting}
                                  helper="(exemplo1@email.com.br, exemplo2@email.com.br, …)"
                                  autoComplete="off"
                                  component={Input}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <Field
                                  type="text"
                                  label="Assunto"
                                  name="assunto"
                                  disabled={submitting}
                                  autoComplete="off"
                                  component={Input}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row>
                          <Col lg="6" md="12">
                            <Field
                              label="Tipo"
                              id="select-tipo-parecer"
                              name="codTipoParecer"
                              disabled={submitting}
                              options={tiposParecerSelect}
                              component={Select}
                              autoComplete="off"
                            />
                          </Col>
                          {/* <Col lg="6" md="12">
                            <Field
                              label="Encaminhar para"
                              id="select-encaminhar-para"
                              name="codUsuarioEncaminhar"
                              disabled={submitting}
                              options={encaminharParaSelect}
                              component={Select}
                              autoComplete="off"
                            />
                          </Col>*/}
                        </Row>
                        <Row>
                          <Col md="12">
                            <Field
                              label="Parecer"
                              id="parecer"
                              name="parecer"
                              autoComplete="off"
                              type="textarea"
                              component={Input}
                              className="form-control"
                              rows="5"
                            />
                          </Col>
                        </Row>
                      </SkeletonLoader>
                    </Form>
                  );
                }}
              />
            </>
          </TabPane>
          <TabPane tabId="2">
            <Accordion className="accordionInstrucoesPareceres">
              {modal.pareceres.length > 0 &&
                modal.pareceres.map(parecer => {
                  return (
                    <Card key={parecer.id}>
                      <Accordion.Toggle className="title" as={Card.Header} eventKey={parecer.id}>
                        {`${parecer.tipoParecer} Incluído em ${parecer.dataInclusao} por ${parecer.usuarioInclusao}`}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={parecer.id}>
                        <Card.Body>
                          <span dangerouslySetInnerHTML={{ __html: parecer.parecer }} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              {modal.pareceres.length === 0 && (
                <div>
                  <Alert className="alert alert-warning">Nenhum paracer a ser listado</Alert>
                </div>
              )}
            </Accordion>
            <br />
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          size="md"
          className="mr-auto"
          outline
          onClick={handlerButtonfechar}
        >
          Fechar
        </Button>
        {!submitting && activeTab === '1' && (
          <Button color="primary" size="md" type="button" onClick={handleButtonIncluir}>
            Incluir
          </Button>
        )}
        {submitting && (
          <Button color="primary" size="md" type="button">
            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
            Incluindo...
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

ModalParecerInstrucao.defaultProps = {
  modal: { show: false },
  regras: {},
  instrucao: {}
};

export default memo(ModalParecerInstrucao);
