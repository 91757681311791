import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Box,
  TextField,
  Autocomplete
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as CNPJ from '@fnando/cnpj';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import swal from '@sweetalert/with-react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { setClienteAtual } from '../../../../../shared';
import strip from '../../../../../utils/strip';
import { formatarData } from '../../../../../utils/format';
import InputMain from '../../../shared/InputMain/InputMain';
import ButtonMain from '../../../shared/ButtonMain';
import MainOnlyHeader from '../../../../Main/MainOnlyHeader';
import {
  setClientes,
  getClientes,
  getDadosUsuario,
  setTelas
} from '../../../../../shared/services/User';
import {
  postDadosEmpresaReceitaFederal,
  postValidaEntradaJornadaOnboarding,
  postCadastroDadosEmpresa,
  postCriarPpcInativa,
  postReservarProposta,
  getDadosCEP,
  postCadastroDadosEndereco,
  getListaBrokersEntradaProposta
} from '../../../../../api/onboarding';
import LoaderMain from '../../../shared/LoaderMain';
import AlertErroMain from '../../../shared/AlertErroMain';

export default () => {
  const [isPending, setPending] = useState(false);
  const [validacaoCnpjNegada, setValidacaoCnpjNegada] = useState(false);
  const [validacaoEmpresaPropria, setValidacaoEmpresaPropria] = useState(null);
  const [mostraErro, setMostraErro] = useState(false);
  const [clienteJornada, setClienteJornada] = useState(null);
  const [dadosBroker, setDadosBroker] = useState({});
  const [listaBrokers, setListaBrokers] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const dadosUser = getDadosUsuario();

  useEffect(() => {
    setPending(true);
    getListaBrokersEntradaProposta()
      .then(retorno => {
        if (retorno && retorno.data) {
          if (retorno.data.listaBrokers) {
            setListaBrokers(retorno.data.listaBrokers);
          } else if (retorno.data.brokerUser) {
            setDadosBroker(retorno.data.brokerUser);
          }
        }
      })
      .catch(error => {
        setMostraErro(error.response ? error.response : error);
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  const handleFormSubmit = (values, form) => {
    let params = {
      cnpj: strip(values.cnpj),
      tagTipoOnboarding: dadosUser.tagTipoOnboarding,
      cod_broker: dadosBroker && dadosBroker.codBroker ? dadosBroker.codBroker : 0
    };
    setPending(true);
    postValidaEntradaJornadaOnboarding(params)
      .then(retValidacao => {
        let ret = retValidacao.data;
        if (ret.continuar_jornada !== true) {
          setValidacaoCnpjNegada(true);
          if (ret.empresa_propria) {
            setValidacaoEmpresaPropria(strip(values.cnpj));
          } else if (ret.empresa_propria === false) {
            setValidacaoEmpresaPropria(false);
          } else {
            setValidacaoEmpresaPropria(null);
          }
        } else {
          submitDadosReceitaFederal(params, form);
        }
        if (ret.mostrar_mensagem_usuario && ret.mensagem)
          setMostraErro({ title: 'Atenção', msg: ret.mensagem });
      })
      .catch(error => {
        setMostraErro(error.response ? error.response : error);
        setPending(false);
      })
      .finally(() => {
        form.setSubmitting(false);
      });
  };

  const submitDadosReceitaFederal = (params, form) => {
    setPending(true);
    postDadosEmpresaReceitaFederal(params)
      .then(resReceita => {
        // history.push('/jornada-onboarding');
        if (resReceita.data && resReceita.data.dados && resReceita.data.dados.detail) {
          // Só deve inserir no mongoDb se os dados da receita estiverem aprovados
          if (resReceita.data.dados.detail && resReceita.data.dados.status === 'APPROVED') {
            params.nomeEmpresa = resReceita.data.dados.detail.nome;
            //# SE O TIPO DE ONBOARDING DO USUARIO FOR CONNECT OU INDICANTES JA CRIA A PROPOSTA COMO RESERVA
            if (
              dadosUser.tagTipoOnboarding === 'connect' ||
              dadosUser.tagTipoOnboarding === 'indicantes'
            ) {
              criarNovaReserva(resReceita, params, form);

              //# TIPO ONBOARDING NAO CONNECT/INDICANTES
            } else {
              submitDadosEmpresaMongo(resReceita.data.dados.detail, resReceita, params, null, form);
            }
          } else {
            setarClienteContinuarJornada(resReceita, params, false, null, form);
          }
        } else {
          setPending(false);
          setValidacaoCnpjNegada(true);
        }
      })
      .catch(error => {
        setMostraErro(error.response ? error.response : error);
        setPending(false);
      });
  };

  const submitDadosEmpresaMongo = (params, resReceita, paramsReceita, ppc_status = null, form) => {
    var paramsMongo = {
      cnpj: strip(params.cnpj),
      cod_usuario: dadosUser.codusuario ? dadosUser.codusuario : 1,
      nome: params.nome,
      data_fundacao: formatarData(params.abertura)
    };
    setPending(true);
    return postCadastroDadosEmpresa(paramsMongo)
      .then(() => {
        cadastrarEnderecoReceitaMongo(params, resReceita, paramsReceita, ppc_status, form);
      })
      .catch(error => {
        setMostraErro(error.response ? error.response : error);
        setPending(false);
      });
  };

  const cadastrarEnderecoReceitaMongo = (params, resReceita, paramsReceita, ppc_status, form) => {
    if (resReceita.data.dados.detail && resReceita.data.dados.detail.cep) {
      var dadosReceita = resReceita.data.dados.detail;
      setPending(true);
      getDadosCEP(strip(dadosReceita.cep))
        .then(res => {
          if (res.data && res.data.dados && res.data.dados.cidade) {
            postCadastroDadosEndereco({
              cnpj: strip(params.cnpj),
              cep: strip(dadosReceita.cep),
              logradouro: res.data.dados.endereco,
              bairro: res.data.dados.bairro,
              uf: res.data.dados.estado,
              cidade: res.data.dados.cidade,
              numero: String(dadosReceita.numero ? dadosReceita.numero : ''),
              complemento: dadosReceita.complemento ? dadosReceita.complemento : ''
            }).finally(() => {
              setarClienteContinuarJornada(resReceita, paramsReceita, true, ppc_status, form);
            });
          } else {
            setarClienteContinuarJornada(resReceita, paramsReceita, true, ppc_status, form);
          }
        })
        .catch(() => {
          setarClienteContinuarJornada(resReceita, paramsReceita, true, ppc_status, form);
        });
    } else {
      setarClienteContinuarJornada(resReceita, paramsReceita, true, ppc_status, form);
    }
  };

  const criarNovaReserva = (resReceita, params, form) => {
    setPending(true);
    postReservarProposta(params)
      .then(resNovaReserva => {
        var ppc_status =
          resNovaReserva.data && resNovaReserva.data.ppc_status ? resNovaReserva.data.ppc_status : null;
        submitDadosEmpresaMongo(resReceita.data.dados.detail, resReceita, params, ppc_status, form);
      })
      .catch(error => {
        setMostraErro(error.response ? error.response : error);
        setPending(false);
      });
  };

  const setarClienteContinuarJornada = (
    resReceita,
    params,
    continuar_jornada,
    ppc_status = null,
    form
  ) => {
    let dadosEmpresa = resReceita.data.dados.detail ? resReceita.data.dados.detail : null;
    let newCli = {
      codCliente: null,
      nomeFantasia: dadosEmpresa.fantasia ? dadosEmpresa.fantasia : null,
      razaoSocial: dadosEmpresa.nome ? dadosEmpresa.nome : null,
      cnpj: dadosEmpresa.cnpj ? strip(dadosEmpresa.cnpj) : null,
      clienteEspecial: null,
      codUsuarioEmpresa: null,
      id: null,
      ppc_status: ppc_status,
      empresa_onboarding: true
    };

    setClienteAtual(newCli);

    let lstClientesTemp = [...getClientes()];
    lstClientesTemp.push(newCli);
    setClientes(lstClientesTemp);

    iniciarJornada(params, continuar_jornada, form);
  };

  const iniciarJornada = (params, continuar_jornada, form) => {
    if (continuar_jornada) {
      setPending(false);
      setValidacaoCnpjNegada(false);
      swal({
        title: 'Sucesso!',
        text: 'A Proposta foi reservada com sucesso, clique abaixo para continuar',
        icon: 'success',
        buttons: ['Nova Empresa', 'Continuar Cadastro'],
        className: 'alert-trocar-empresa'
      }).then(confirm => {
        if (confirm) {
          setTelas(true, false);
          history.push('/jornada-onboarding');
        } else {
          if (form) form.resetForm({ cnpj: '' });
        }
      });
    } else {
      criarPpcInativa(params);
    }
  };

  const criarPpcInativa = params => {
    setPending(true);
    postCriarPpcInativa(params)
      .then(() => {
        setValidacaoCnpjNegada(true);
      })
      .catch(error => {
        setMostraErro(error.response ? error.response : error);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const continuarJornada = () => {
    const clientes = getClientes();
    var cnpj = validacaoEmpresaPropria;
    var cliente = clientes.filter(cli => cli.cnpj === cnpj);
    if (cliente.length > 0) {
      setClienteJornada(cliente[0]);
    } else {
      setMostraErro({
        title: 'Atenção',
        msg: 'Você não tem acesso a estra empresa',
        tipoAlerta: 'warning'
      });
    }
  };

  return (
    <MainOnlyHeader>
      <Container fixed>
        {clienteJornada && (
          <Redirect
            to={{
              pathname: '/authentication/change-client',
              state: { cliente: clienteJornada, location }
            }}
          />
        )}
        <Card variant="outlined" className="box-shadow-natural-5 border-radius-8">
          <CardContent>
            <Grid container>
              <Grid item xl={3} lg={3} md={3} sm={2} xs={1} />
              <Grid item xl={6} lg={6} md={6} sm={8} xs={10} sx={{ p: '120px 0 160px 0' }}>
                {validacaoEmpresaPropria === null && validacaoCnpjNegada === false && (
                  <Typography variant="h5" className="texto-cor-principal texto-negrito texto-centro">
                    {dadosUser.tagTipoOnboarding === 'connect' ||
                    dadosUser.tagTipoOnboarding === 'indicantes'
                      ? 'Chegou a hora de cadastrar a empresa...'
                      : 'Chegou a hora de cadastrar sua empresa...'}
                  </Typography>
                )}

                {validacaoCnpjNegada === false && validacaoEmpresaPropria === null && (
                  <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
                    Para agilizar o processo de análise de crédito precisamos validar o CNPJ da sua
                    empresa.
                  </Typography>
                )}

                {validacaoCnpjNegada === true && validacaoEmpresaPropria === null && (
                  <>
                    <Typography
                      variant="h6"
                      className="texto-cor-principal texto-negrito texto-centro mb-3"
                    >
                      Não foi possível prosseguir com a sua solicitação.
                    </Typography>
                    <ButtonMain
                      tipoBotao="principal"
                      onClick={() => {
                        setPending(false);
                        setValidacaoCnpjNegada(false);
                        setValidacaoEmpresaPropria(null);
                      }}
                    >
                      Voltar para o cadastro
                    </ButtonMain>
                  </>
                )}

                {validacaoCnpjNegada === false ? (
                  <Formik
                    initialValues={{
                      cnpj: ''
                    }}
                    validationSchema={Yup.object().shape({
                      cnpj: Yup.string()
                        .required('Informe o CNPJ.')
                        .nullable()
                        .test({
                          exclusive: true,
                          message: params => {
                            const strippedValue = strip(params.value);
                            if (strippedValue.length === 14 && CNPJ.isValid(strippedValue)) {
                              return 'CNPJ válido.';
                            }
                            return 'CNPJ inválido.';
                          },
                          name: 'cnpj',
                          test: value => CNPJ.isValid(value)
                        })
                    })}
                    onSubmit={handleFormSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    render={({ isSubmitting }) => {
                      return (
                        <Form>
                          <Box
                            sx={
                              isPending ? { position: 'relative', p: '20px 0 0 0' } : { p: '20px 0 0 0' }
                            }
                          >
                            {isPending && <LoaderMain tipoLoader="float" tipoImagem="logo" />}
                            <Box className="box-input-padding">
                              <Typography
                                variant="subtitle1"
                                className="texto-cor-cinza-escuro label-padding"
                              >
                                Gerente de Negócio
                              </Typography>
                              {listaBrokers && listaBrokers.length > 0 ? (
                                <Autocomplete
                                  value={
                                    listaBrokers && dadosBroker
                                      ? listaBrokers.find(
                                          option =>
                                            parseInt(option.value) === parseInt(dadosBroker.codBroker)
                                        )
                                      : null
                                  }
                                  onChange={(event, newValue) => setDadosBroker(newValue)}
                                  disablePortal
                                  id="vertical"
                                  disableClearable
                                  className="combobox_principal disabled-is-label"
                                  size="small"
                                  popupIcon={
                                    <KeyboardArrowDown className="texto-cor-complementar w-1-5em font-size-2rem" />
                                  }
                                  options={listaBrokers.sort(
                                    (a, b) => -b.nomePlataforma.localeCompare(a.nomePlataforma)
                                  )}
                                  groupBy={option => option.nomePlataforma}
                                  getOptionLabel={option => option.nomeBroker}
                                  autoHighlight
                                  renderInput={params => (
                                    <TextField {...params} label="" placeholder="Ger.Negócios" />
                                  )}
                                  noOptionsText="Nenhuma registro encontrado"
                                  disabled={isPending || isSubmitting}
                                  required
                                />
                              ) : dadosBroker && dadosBroker.codBroker ? (
                                <Typography variant="subtitle1" className="texto-cor-cinza">
                                  {`${dadosBroker.nomeBroker} - ${dadosBroker.nomePlataforma}`}
                                </Typography>
                              ) : (
                                !isPending && (
                                  <div className="error pad-15">
                                    <h2>
                                      Nenhum gerente encontrado, favor contatar o suporte para a
                                      configuração do seu cadastro
                                    </h2>
                                  </div>
                                )
                              )}
                            </Box>
                            <Field
                              type="text"
                              name="cnpj"
                              titulo="CNPJ Empresa"
                              id="CNPJ"
                              size="small"
                              boxClassName="pad-topbot-10"
                              uppercase={true}
                              component={InputMain}
                              autoComplete="off"
                              disabled={isPending || isSubmitting}
                              tipoMascara="CNPJMask"
                              placeholder="CNPJ"
                            />

                            <Box className="pad-topbot-20">
                              <Grid container spacing={4}>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                  <ButtonMain
                                    disabled={isPending || isSubmitting}
                                    onClick={() => {
                                      history.push('/home');
                                    }}
                                  >
                                    Cancelar
                                  </ButtonMain>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                  <ButtonMain
                                    type="submit"
                                    tipoBotao="principal"
                                    disabled={isPending || isSubmitting}
                                  >
                                    Continuar
                                  </ButtonMain>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Form>
                      );
                    }}
                  />
                ) : (
                  <Grid container spacing={0}>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                      {validacaoEmpresaPropria === false && (
                        <>
                          <Box className="pad-topbot-20" sx={{ marginTop: '-50px' }}>
                            <Typography
                              variant="h5"
                              className="mt-3 texto-cor-principal texto-negrito texto-centro"
                            >
                              O CNPJ não pode ser cadastrado
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              className="mt-1 texto-cor-cinza-escuro pad-10 texto-centro"
                              sx={{
                                whiteSpace: { xl: 'nowrap', lg: 'nowrap' },
                                marginLeft: { xl: '-25px', lg: '-25px' }
                              }}
                            >
                              Entre em contato com o(a) administrador(a) da empresa.
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              className="mt-1 texto-cor-cinza-escuro pad-10 texto-centro"
                              sx={{
                                whiteSpace: { xl: 'nowrap', lg: 'nowrap' },
                                marginLeft: { xl: '-40px', lg: '-40px' }
                              }}
                            >
                              Em caso de dúvidas fale com a gente pelo chat ou mande um
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              className="texto-cor-cinza-escuro texto-centro"
                            >
                              <span>
                                e-mail para
                                <a href="mail:contato@one7.com.vc"> contato@one7.com.vc. </a>
                              </span>
                            </Typography>
                          </Box>

                          <ButtonMain
                            tipoBotao="principal"
                            onClick={() => {
                              setPending(false);
                              setValidacaoCnpjNegada(false);
                              setValidacaoEmpresaPropria(null);
                            }}
                          >
                            Voltar para o cadastro
                          </ButtonMain>
                        </>
                      )}

                      {validacaoEmpresaPropria !== null && validacaoEmpresaPropria !== false && (
                        <>
                          <Box className="pad-topbot-20" sx={{ marginTop: '-50px' }}>
                            <div className="texto-centro">
                              <ErrorOutlineIcon
                                className="font-size-45 texto-info"
                                sx={{ color: '#FEB700' }}
                              />
                            </div>

                            <Typography
                              variant="h5"
                              className="mt-3 texto-cor-principal texto-negrito texto-centro"
                            >
                              Identificamos que você já cadastrou essa empresa
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              className="mt-1 texto-cor-cinza-escuro pad-10 texto-centro"
                            >
                              Selecione a opção voltar para pesquisar as empresas que você tem acesso
                            </Typography>
                          </Box>

                          <Grid container spacing={1}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <ButtonMain
                                tipoBotao="transparente-com-bordas-principal"
                                onClick={() => {
                                  setPending(false);
                                  setValidacaoCnpjNegada(false);
                                  setValidacaoEmpresaPropria(null);
                                }}
                              >
                                Voltar para o cadastro
                              </ButtonMain>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <ButtonMain
                                tipoBotao="principal"
                                onClick={() => {
                                  continuarJornada();
                                }}
                              >
                                Continuar Cadastro
                              </ButtonMain>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
                <AlertErroMain
                  exibeErro={mostraErro}
                  escondeErro={setMostraErro}
                  titleErro={mostraErro && mostraErro.title ? mostraErro.title : ''}
                  msgErro={mostraErro && mostraErro.msg ? mostraErro.msg : ''}
                  tipoAlerta={mostraErro && mostraErro.tipoAlerta ? mostraErro.tipoAlerta : 'error'}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </MainOnlyHeader>
  );
};
