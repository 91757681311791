import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonMain from '../../../shared/ButtonMain';
import SlideMain from '../../../shared/InputMain/SlideMain';
import {
  getDadosGarantiaMinima,
  getDadosSimulacaoResumo,
  enviaAceiteProposta
} from '../../../../../api/onboarding';
import LoaderMain from '../../../shared/LoaderMain';
import AlertErroMain from '../../../shared/AlertErroMain';

export default ({
  avancarEtapa,
  mudaTelaSolucoes,
  cnpjEmpresa,
  produtoEscolhido,
  setProdutoEscolhido,
  codContacorrente = null,
  origemProposta = 'digital'
}) => {
  const [valorEmprestimo, setValorEmprestimo] = useState(0);
  const [quantidadeParcela, setQuantidadeParcela] = useState(0);
  const [valorMinimo, setValorMinimo] = useState(0);
  const [valorMaximo, setValorMaximo] = useState(0);
  const [parcelaMinima, setParcelaMinima] = useState(0);
  const [parcelaMaxima, setParcelaMaxima] = useState(0);
  const [tipoGarantia, setTipoGarantia] = useState('');
  const [idSimulacao, setIdSimulacao] = useState('');
  const [valorGarantiaMinina, setValorGarantiaMinina] = useState(0);
  const [resumoEmprestimo, setResumoEmprestimo] = useState(false);
  const [valorParcelas, setValorParcelas] = useState(0);
  const [taxaJuros, setTaxaJuros] = useState(0);
  const [valorIOF, setValorIOF] = useState(0);
  const [valorCET, setValorCET] = useState(0);
  const [isPending, setPending] = useState(false);
  const [isPendingContinuar, setPendingContinuar] = useState(false);
  const [isPendingAceitar, setPendingAceitar] = useState(false);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mostraErro, setMostraErro] = useState(false);

  useEffect(() => {
    let dados = produtoEscolhido;

    try {
      if (dados !== undefined && Object.keys(dados).length > 0) {
        let numero_parcelas = dados.condicao.parcelas_max ? dados.condicao.parcelas_max : 0;
        let numero_parcelas_minimo = dados.condicao.parcelas_min ? dados.condicao.parcelas_min : 0;
        let valor_maximo = dados.limite.valor_max ? dados.limite.valor_max : 0;
        let valor_minimo = dados.limite.valor_min ? dados.limite.valor_min : 0;
        let tipo_garantia = dados.sigla ? dados.sigla : '';

        valor_minimo = parseFloat(valor_minimo);
        valor_maximo = parseFloat(valor_maximo);
        numero_parcelas = parseInt(numero_parcelas);
        numero_parcelas_minimo = parseInt(numero_parcelas_minimo);

        setParcelaMaxima(numero_parcelas);
        setParcelaMinima(numero_parcelas_minimo);
        setValorMinimo(valor_minimo);
        setValorMaximo(valor_maximo);
        setTipoGarantia(tipo_garantia);
        setQuantidadeParcela(numero_parcelas);
        setValorEmprestimo(valor_maximo);

        handleSliderBuscaValor(cnpjEmpresa, valor_maximo, tipo_garantia);
      }
    } catch (e) {
      setOpenAlert(true);
      return false;
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [resumoEmprestimo]);

  const handleSliderValorEmprestimo = (event, valor) => {
    setValorEmprestimo(valor);
  };

  const handleSliderQuantidadeParcela = (event, valor) => {
    setQuantidadeParcela(valor);
  };

  const handleSliderBuscaValor = (cnpj = '', valorEmp = '', tipo = '') => {
    cnpj = cnpj !== '' ? cnpj : cnpjEmpresa;
    valorEmp = valorEmp !== '' ? parseFloat(valorEmp) : valorEmprestimo;
    tipo = tipo !== '' ? tipo : tipoGarantia;

    let params = {
      cnpj: cnpj,
      valor: valorEmp,
      tipo_garantia: tipo
    };

    setPending(true);

    getDadosGarantiaMinima(params)
      .then(resp => {
        try {
          let dadosGarantia = resp.data;
          if (dadosGarantia !== undefined && Object.keys(dadosGarantia).length > 0) {
            let garantiaMinina = dadosGarantia.limite_minimo_garantia
              ? dadosGarantia.limite_minimo_garantia
              : 0;
            garantiaMinina = parseFloat(garantiaMinina);
            setValorGarantiaMinina(garantiaMinina);
          }
        } catch (e) {
          setOpenAlert(true);
          return false;
        }
      })
      .catch(() => {
        setOpenAlert(true);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const aceitarProposta = () => {
    let payLoad = {
      id: idSimulacao,
      tipo_garantia: tipoGarantia,
      cod_conta_corrente: codContacorrente,
      origem_proposta: origemProposta
    };

    setPendingAceitar(true);

    enviaAceiteProposta(payLoad)
      .then(() => {
        avancarEtapa();
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPendingAceitar(false);
      });
  };

  const telaSimulacaoResumo = valueTela => {
    if (valueTela) {
      let payLoad = {
        cnpj: cnpjEmpresa,
        valor: valorEmprestimo,
        numero_parcelas: quantidadeParcela,
        tipo_garantia: tipoGarantia
      };

      setPendingContinuar(true);

      getDadosSimulacaoResumo(payLoad)
        .then(resp => {
          let dadosResumo = resp.data;
          try {
            if (dadosResumo !== undefined && Object.keys(dadosResumo).length > 0) {
              let iofValue =
                dadosResumo.simulacao_ccb.tot_iof !== undefined ? dadosResumo.simulacao_ccb.tot_iof : 0;
              let cetValue =
                dadosResumo.simulacao_ccb.cet_m !== undefined ? dadosResumo.simulacao_ccb.cet_m : 0;
              let taxaJurosValue =
                dadosResumo.simulacao_ccb.juros !== undefined ? dadosResumo.simulacao_ccb.juros : 0;
              let parcelasValue = dadosResumo.simulacao_ccb.valor_parcela
                ? dadosResumo.simulacao_ccb.valor_parcela
                : 0;
              let simulacaoId = dadosResumo.simulacao_id ? dadosResumo.simulacao_id : '';

              setValorParcelas(parcelasValue);
              setTaxaJuros(taxaJurosValue);
              setValorIOF(iofValue);
              setValorCET(cetValue);
              setIdSimulacao(simulacaoId);

              setResumoEmprestimo(valueTela);
            }
          } catch (e) {
            setOpenAlert(true);
            return false;
          }
        })
        .catch(err => {
          setMostraErro(err.response);
        })
        .finally(() => {
          setPendingContinuar(false);
        });
    } else {
      setResumoEmprestimo(valueTela);
    }
  };

  const voltarTelaProdutos = () => {
    setProdutoEscolhido([]);
    mudaTelaSolucoes('Produtos');
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Grid container>
      <Grid item xl={3} lg={3} md={2} sm={1} />
      <Grid item xl={6} lg={6} md={8} sm={10} xs={12}>
        {resumoEmprestimo === false ? (
          <>
            <Typography variant="h5" className="mt-3 texto-cor-principal texto-negrito texto-centro">
              Informe abaixo o valor e a quantidade de parcelas desejadas
            </Typography>

            <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
              Sua empresa tem um limite pré-aprovado de
              <span className="texto-cor-principal texto-weight-600"> R$&nbsp;</span>
              <span className="texto-cor-principal texto-weight-600">
                {valorMaximo.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
              </span>
            </Typography>

            <Card className="card-simulacao-solucoes">
              <Box
                sx={
                  isPendingContinuar
                    ? {
                        position: 'relative'
                      }
                    : {}
                }
              >
                {isPendingContinuar && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                <Grid container>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <div>
                      <div className="mt-4 texto-cor-cinza-escuro pad-0 texto-centro font-size-075">
                        VALOR DO EMPRÉSTIMO
                      </div>

                      <Typography
                        variant="h5"
                        className="mt-1 texto-cor-principal texto-negrito texto-centro"
                      >
                        R$&nbsp;
                        <span>
                          {valorEmprestimo.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                        </span>
                      </Typography>

                      <Grid container className="mt-3">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <SlideMain
                            name="valor"
                            min={typeof valorMinimo === 'number' ? valorMinimo : 0}
                            max={typeof valorMaximo === 'number' ? valorMaximo : 0}
                            defaultValue={typeof valorMinimo === 'number' ? valorMinimo : 0}
                            value={typeof valorEmprestimo === 'number' ? valorEmprestimo : 0}
                            onChange={handleSliderValorEmprestimo}
                            onChangeCommitted={e => handleSliderBuscaValor('', e.target.value, '')}
                            disableSwap
                            step={1000}
                            marks={[
                              {
                                value: typeof valorMinimo === 'number' ? valorMinimo : 0
                              },
                              {
                                value: typeof valorMaximo === 'number' ? valorMaximo : 0
                              }
                            ]}
                          />

                          <Grid container className="font-size-875 texto-cinza-medio">
                            <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                              <div className="texto-esquerda">
                                R$&nbsp;
                                <span>
                                  {valorMinimo.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                </span>
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={4} />
                            <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                              <div className="texto-direita">
                                R$&nbsp;
                                <span>
                                  {valorMaximo.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>

                    <div>
                      <div className="mt-4 texto-cor-cinza-escuro pad-0 texto-centro font-size-075">
                        QUANTIDADE DE PARCELAS
                      </div>

                      <Typography
                        variant="h5"
                        className="mt-1 texto-cor-principal texto-negrito texto-centro"
                      >
                        {typeof quantidadeParcela === 'number' ? quantidadeParcela : 1}
                      </Typography>

                      <Grid container className="mt-3">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <SlideMain
                            name="parcela"
                            min={typeof parcelaMinima === 'number' ? parcelaMinima : 0}
                            max={typeof parcelaMaxima === 'number' ? parcelaMaxima : 0}
                            defaultValue={typeof parcelaMinima === 'number' ? parcelaMinima : 0}
                            value={typeof quantidadeParcela === 'number' ? quantidadeParcela : 0}
                            onChange={handleSliderQuantidadeParcela}
                            step={1}
                            marks={[
                              {
                                value: typeof parcelaMinima === 'number' ? parcelaMinima : 0
                              },
                              {
                                value: typeof parcelaMaxima === 'number' ? parcelaMaxima : 0
                              }
                            ]}
                          />

                          <Grid container className="font-size-875 texto-cinza-medio margin-bottom-15">
                            <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                              <div className="texto-esquerda">
                                <span>{parcelaMinima}</span>
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={4} />
                            <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                              <div className="texto-direita">
                                <span>{parcelaMaxima}</span>
                              </div>
                            </Grid>
                          </Grid>

                          {tipoGarantia !== 'AV' && (
                            <>
                              <hr />

                              <Grid
                                container
                                className="mt-2 margin-bottom-15 font-size-875 texto-cinza-medio"
                                sx={{ minHeight: '33px' }}
                              >
                                <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
                                  <div className="mt-2">Garantia mínima para o crédito solicitado</div>
                                </Grid>
                                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                  <Box className="mt-2 texto-direita texto-cor-principal font-size-1-rem texto-weight-600 loader-simulacao">
                                    {isPending ? (
                                      <Box
                                        sx={{
                                          position: 'relative',
                                          margin: '-10px 0 0 -25px',
                                          minHeight: '20px'
                                        }}
                                        className={onlySmallScreen ? 'loader-garantia-minima-small' : ''}
                                      >
                                        <LoaderMain tipoLoader="float" tipoImagem="circulo" />
                                      </Box>
                                    ) : (
                                      <span>
                                        R$&nbsp;
                                        {valorGarantiaMinina.toLocaleString('pt-br', {
                                          minimumFractionDigits: 2
                                        })}
                                      </span>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

            <Grid container className="mt-3" spacing={3}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
              >
                <center>
                  <ButtonMain
                    tipoBotao="transparente"
                    onClick={() => voltarTelaProdutos()}
                    disabled={isPendingContinuar}
                  >
                    Voltar
                  </ButtonMain>
                </center>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
              >
                <center>
                  <ButtonMain
                    tipoBotao="principal"
                    onClick={() => telaSimulacaoResumo(true)}
                    disabled={isPending || isPendingContinuar}
                  >
                    Continuar
                  </ButtonMain>
                </center>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h5" className="mt-3 texto-cor-principal texto-negrito texto-centro">
              Resumo do crédito
            </Typography>

            <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
              Confira as informações do seu crédito antes de seguir para o envio dos documentos.
            </Typography>

            <Card className="card-simulacao-solucoes">
              <Grid container>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Box
                    sx={
                      isPendingAceitar
                        ? {
                            position: 'relative'
                          }
                        : {}
                    }
                  >
                    {isPendingAceitar && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

                    <Grid container className="mt-4">
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="texto-cor-cinza-escuro pad-0 texto-esquerda white-space-nowrap"
                        >
                          Valor a ser depositado
                          <Tooltip
                            title="Este é o valor líquido que você irá receber em conta, já com o desconto das taxas. Este valor será creditado em uma conta corrente de sua preferência assim que a documentação for aprovada."
                            placement={onlySmallScreen ? 'bottom' : 'right'}
                            id="tooltip-valor"
                          >
                            <HelpOutlineIcon className="icone-resumo-simulacao" />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className={onlySmallScreen ? 'pad-0 texto-esquerda' : 'pad-0 texto-direita'}
                        >
                          <span className="texto-cor-principal texto-weight-600">
                            <span>R$ </span>
                            {valorEmprestimo.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item className="mt-2" xl={10} lg={10} md={10} sm={10} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="texto-cor-cinza-escuro pad-0 texto-esquerda white-space-nowrap"
                        >
                          Quantidade de parcelas
                          <Tooltip
                            title="A data da primeira parcela será de acordo com o fluxo descrito na Cédula de Crédito Bancário (CCB). Para que você consiga se programar, o pagamento é feito por boleto ou DDA"
                            placement={onlySmallScreen ? 'bottom' : 'right'}
                            id="tooltip-qtd-parcelas"
                          >
                            <HelpOutlineIcon className="icone-resumo-simulacao" />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className={
                            onlySmallScreen ? 'pad-0 texto-esquerda' : 'mt-2 pad-0 texto-direita'
                          }
                        >
                          <span className="texto-cor-principal texto-weight-600">
                            {quantidadeParcela}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item className="mt-2" xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="texto-cor-cinza-escuro pad-0 texto-esquerda white-space-nowrap"
                        >
                          Valor das parcelas
                          <Tooltip
                            title="O valor é pago mensalmente até a quitação total do crédito incluindo todas as taxas e impostos descritos aqui no resumo."
                            placement={onlySmallScreen ? 'bottom' : 'right'}
                            id="tooltip-valor-parcelas"
                          >
                            <HelpOutlineIcon className="icone-resumo-simulacao" />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className={
                            onlySmallScreen ? 'pad-0 texto-esquerda' : 'mt-2 pad-0 texto-direita'
                          }
                        >
                          <span className="texto-cor-principal texto-weight-600">
                            <span>R$ </span>
                            {valorParcelas.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item className="mt-2" xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="texto-cor-cinza-escuro pad-0 texto-esquerda white-space-nowrap"
                        >
                          Taxa de juros (mês)
                          <Tooltip
                            title="A taxa de juros é calculada com base na análise de crédito do seu CNPJ. Essa taxa é mensal, dessa forma o cálculo é feito de acordo com o número de parcelas solicitadas."
                            placement={onlySmallScreen ? 'bottom' : 'right'}
                            id="tooltip-taxa"
                          >
                            <HelpOutlineIcon className="icone-resumo-simulacao" />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className={
                            onlySmallScreen ? 'pad-0 texto-esquerda' : 'mt-2 pad-0 texto-direita'
                          }
                        >
                          <span className="texto-cor-principal texto-weight-600">
                            {taxaJuros.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                            <span>%</span>
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item className="mt-2" xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="texto-cor-cinza-escuro pad-0 texto-esquerda white-space-nowrap"
                        >
                          IOF
                          <Tooltip
                            title="O Imposto sobre Operações Financeiras (IOF) é o tributo cobrado pelo governo federal pago por pessoas físicas e jurídicas em todas as transações financeiras."
                            placement={onlySmallScreen ? 'bottom' : 'right'}
                            id="tooltip-iof"
                          >
                            <HelpOutlineIcon className="icone-resumo-simulacao" />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className={
                            onlySmallScreen ? 'pad-0 texto-esquerda' : 'mt-2 pad-0 texto-direita'
                          }
                        >
                          <span className="texto-cor-principal texto-weight-600">
                            <span>R$ </span>
                            {valorIOF.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item className="mt-2" xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="texto-cor-cinza-escuro pad-0 texto-esquerda white-space-nowrap"
                        >
                          CET
                          <Tooltip
                            title="O Custo Efetivo Total (CET) é o somatório dos encargos que fazem parte do valor total do crédito contratado."
                            placement={onlySmallScreen ? 'bottom' : 'right'}
                            id="tooltip-cet"
                          >
                            <HelpOutlineIcon className="icone-resumo-simulacao" />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className={
                            onlySmallScreen ? 'pad-0 texto-esquerda' : 'mt-2 pad-0 texto-direita'
                          }
                        >
                          <span className="texto-cor-principal texto-weight-600">
                            {valorCET.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                            <span>%</span>
                          </span>
                        </Typography>
                      </Grid>

                      <Grid item className="mt-3" xl={12} lg={12} md={12} sm={12} xs={12}>
                        <hr />
                      </Grid>

                      <Grid item className="mt-2" xl={12} lg={12} md={12} sm={12} xs={12}>
                        <span className="font-size-875 texto-cinza-escuro">
                          • O recurso só será liberado após a aprovação do crédito
                          {tipoGarantia !== 'AV'
                            ? ' e do registro da garantia ofertada em cartório competente'
                            : ''}
                          . Ressaltamos que o vencimento da primeira parcela será de acordo com o fluxo
                          descrito na Cédula de Crédito Bancário (CCB).
                        </span>
                      </Grid>

                      <Grid
                        item
                        className="mt-2 margin-bottom-15"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <span className="font-size-875 texto-cinza-escuro">
                          • Os valores acima simulados podem ser alterados mediante custos e tarifas de
                          avaliação e registro da garantia.
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Card>

            <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

            <Grid container className="mt-3" spacing={3}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
              >
                <center>
                  <ButtonMain
                    tipoBotao="transparente"
                    disabled={isPendingAceitar}
                    onClick={() => telaSimulacaoResumo(false)}
                  >
                    Simular outro valor
                  </ButtonMain>
                </center>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
              >
                <center>
                  <ButtonMain
                    tipoBotao="principal"
                    disabled={isPendingAceitar}
                    onClick={() => aceitarProposta()}
                  >
                    Continuar
                  </ButtonMain>
                </center>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
          Ocorreu um erro!
        </Alert>
      </Snackbar>
    </Grid>
  );
};
