export const borderosClienteGridConfig = () => {
  const config = [
    {
      dataField: 'codigo',
      text: 'Nº DO BORDERÔ',
      tdAlign: 'center'
    },
    {
      dataField: 'entrada',
      text: 'CÓD. ENTRADA',
      tdAlign: 'center'
    },
    {
      dataField: 'empresa',
      text: 'EMPRESA',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    },
    {
      dataField: 'dataAceite',
      text: 'ACEITE',
      tipoOrdenacao: 'data',
      formatacao: 'data',
      tdAlign: 'center'
    },
    {
      dataField: 'operacao',
      text: 'OPERAÇÃO',
      tipoOrdenacao: 'texto',
      tdAlign: 'center'
    },
    {
      dataField: 'documentos',
      text: 'DOCUMENTOS',
      tipoOrdenacao: 'inteiro',
      tdAlign: 'center'
    },
    {
      dataField: 'valorFace',
      text: 'VALOR FACE',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      tdAlign: 'right'
    }
  ];

  return config;
};

export const borderosGeralGridConfig = () => {
  const config = [
    {
      dataField: 'codigo',
      text: 'Nº DO BORDERÔ',
      tdAlign: 'center'
    },
    {
      dataField: 'nomeGerente',
      text: 'GERENTE',
      tipoOrdenacao: 'texto',
      tdAlign: 'center'
    },
    {
      dataField: 'nomePlataforma',
      text: 'PLATAFORMA',
      tipoOrdenacao: 'texto',
      tdAlign: 'center'
    },
    {
      dataField: 'unidadeNegocioPlataf',
      text: 'UNID.NEGÓC.',
      tipoOrdenacao: 'texto',
      tdAlign: 'center'
    },
    {
      dataField: 'empresa',
      text: 'EMPRESA',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    },
    {
      dataField: 'dataAceite',
      text: 'ACEITE',
      tipoOrdenacao: 'data',
      formatacao: 'data',
      tdAlign: 'center'
    },
    {
      dataField: 'nomeFundo',
      text: 'FUNDO',
      tipoOrdenacao: 'texto',
      tdAlign: 'center'
    },
    {
      dataField: 'operacao',
      text: 'OPERAÇÃO',
      tipoOrdenacao: 'texto',
      tdAlign: 'center'
    },
    {
      dataField: 'valorFace',
      text: 'VALOR FACE',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      tdAlign: 'right'
    },
    {
      dataField: 'valorTac',
      text: 'TAC',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      tdAlign: 'right'
    },
    {
      dataField: 'valorDesagio',
      text: 'DESÁGIO',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      tdAlign: 'right'
    },
    {
      dataField: 'valorDesembolso',
      text: 'DESEMBOLSO',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      tdAlign: 'right'
    }
  ];

  return config;
};
