import React, { useState, useEffect } from 'react';
import { Card, CardBody, FormGroup, Label } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

//import StepWizard from 'react-step-wizard';
import * as CPF from '@fnando/cpf';
import * as CNPJ from '@fnando/cnpj';
import Main from '../../Main/Main';

import VoltarIcon from '../../../shared/icons/Voltar';
import {
  formatarBorderoTitulo,
  moneyFullFormat,
  transformTableTitulosData
} from '../../../utils/format';
import expandRow from '../common/expandRowVisualizar';
import { getBorderoByCode, getBorderoTitulo } from '../../../api/borderos';
import { getClienteAtual } from '../../../shared';
import SkeletonLoader from '../../../shared/components/common/SkeletonLoader';
import Grid from '../../commom/Grid';
import HistoricoAcesso from '../../commom/HistoricoAcesso';

const VisualizarTitulos = props => {
  const { match: { params: { bordero_id: borderoID } = {} } = {} } = props;
  const [bordero, setBordero] = useState({});
  const clienteAtual = getClienteAtual();
  const [isPending, setIsPending] = useState(false);
  const [message, setMessage] = useState();
  // Douglas - 24/01/20 - Ao carregar a pagina, caso o tamanho da janela seja menor ou igual a 600px ja carrega o menu collapsado
  const [collapseMenuRight, setCollapseMenuRight] = useState(window.innerWidth <= 600 ? true : false);
  const hist = useHistory();

  useEffect(() => {
    if (clienteAtual && clienteAtual.id === 'todos') {
      hist.push('/home');
    }
  }, [clienteAtual]);

  const updateStateBordero = data => {
    if (data && data.titulos) {
      setBordero(data);
    } else {
      setIsPending(true);
      return Promise.all([getBorderoByCode(borderoID), getBorderoTitulo(borderoID)])
        .then(([resultBordero, resultTitulos]) => {
          resultTitulos.data.map(item => {
            return formatarBorderoTitulo(item);
          });

          const updatedBordero = {
            ...resultBordero.data,
            titulos: resultTitulos.data
          };
          setBordero(updatedBordero);
        })
        .catch(err => {
          const {
            response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
          } = err;
          setMessage(message);
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  };

  useEffect(() => {
    updateStateBordero();
  }, []);

  const formatarCpfCnpj = (cellContent, row) => {
    return (
      <>{CPF.isValid(row.sacado.cpf) ? CPF.format(row.sacado.cpf) : CNPJ.format(row.sacado.cnpj)}</>
    );
  };

  return (
    <Main
      title="Incluir Novo Borderô"
      className={'wizard-bordero visualizar-titulo ' + (collapseMenuRight ? 'collapsed' : '')}
      {...props}
    >
      <Card>
        <CardBody>
          <div className="wizard visualizar-titulos">
            <div className="selection-step">
              <div className="row row-content">
                <div
                  className={
                    (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                    ' col-12 content-body '
                  }
                >
                  <div className="body">
                    <div className="d-flex align-items-center">
                      <h1 className="title" style={{ width: '100%' }}>
                        Informações do Borderô
                        {collapseMenuRight && (
                          <button
                            type="button"
                            onClick={() => setCollapseMenuRight(false)}
                            title="Filtros"
                            className="btn btn-outline-secondary btn-show-menu-right float-right"
                            style={{ minWidth: 'auto' }}
                          >
                            <i className="fa fa-angle-left" />
                            <span>Info. Borderô</span>
                          </button>
                        )}
                      </h1>
                    </div>

                    <SkeletonLoader isPending={isPending} width="60%" height="30px" count={8}>
                      {message && (
                        <div className="alert alert-danger animated fadeIn" role="alert">
                          {message}
                        </div>
                      )}
                      <Grid
                        keyField="id"
                        data={transformTableTitulosData(bordero.titulos) || []}
                        expandRow={expandRow}
                        tableSearch={true}
                        classes="table-layout-auto"
                        configuracaoColunas={[
                          {
                            dataField: 'sacado.id',
                            text: 'CPF/CNPJ',
                            formatacao: formatarCpfCnpj,
                            tdAlign: 'left'
                          },
                          { dataField: 'sacado.nome', text: 'SACADO', formatacao: 'texto' },
                          {
                            dataField: 'tipoOperacao.ndoc',
                            text: 'Nº DO DOC.',
                            formatacao: 'texto',
                            tipoOrdenacao: 'texto'
                          },
                          {
                            dataField: 'tipoOperacao.vencimento',
                            text: 'VENCIMENTO',
                            formatacao: 'dataOuIfem',
                            tipoOrdenacao: 'data'
                          },
                          {
                            dataField: 'tipoOperacao.valor',
                            text: 'VALOR BRUTO',
                            formatacao: 'valor'
                          }
                        ]}
                      />
                    </SkeletonLoader>
                  </div>
                  <div className="footer d-flex justify-content-between">
                    <Link
                      className="mr-auto btn-back btn btn-outline-primary btn-lg"
                      to="/Borderos"
                      disabled={isPending}
                    >
                      <i className="svg-icon">
                        <VoltarIcon />
                      </i>
                      Voltar
                    </Link>
                  </div>
                </div>
                {!collapseMenuRight && (
                  <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                    <div className="fixed-side">
                      {bordero && bordero.id && (
                        <>
                          <div
                            className="header cursor-pointer"
                            onClick={() => setCollapseMenuRight(true)}
                            onKeyPress={() => setCollapseMenuRight(true)}
                            role="button"
                            tabIndex={0}
                          >
                            <h4 className="title">
                              Detalhes Borderô
                              <i className="fa fa-angle-right float-right" />
                            </h4>
                          </div>
                          <div className="info infoBordero">
                            <FormGroup>
                              <Label>Status:</Label>
                              <p className={'form-control-static text-' + bordero.statusAnalise.cor}>
                                {bordero.statusAnalise.descricao}
                              </p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Cliente:</Label>
                              <p className="form-control-static">{clienteAtual.razaoSocial}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Conta Corrente:</Label>
                              <p className="form-control-static">{bordero.contaCorrente.descricao}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Tipo de Empresa:</Label>
                              <p className="form-control-static">{bordero.tipoEmpresa.descricao}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Tipo de Operação:</Label>
                              <p className="form-control-static">{bordero.tipoOperacao.descricao}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Tipo de Documento:</Label>
                              <p className="form-control-static">{bordero.tipoTitulo.descricao}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Quantidade de Títulos:</Label>
                              <p className="form-control-static">{bordero.titulosQuantidade}</p>
                            </FormGroup>
                            <FormGroup>
                              <Label>Total bruto:</Label>
                              <p className="form-control-static total-price">
                                {moneyFullFormat(bordero.titulosTotalValorBruto)}
                              </p>
                            </FormGroup>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <HistoricoAcesso codTela={1175} />
    </Main>
  );
};

export default VisualizarTitulos;
