import React from 'react';
import TextFieldMain from './TextFieldMain';
import BoxInputMain from './BoxInputMain';

const InputMain = props => {
  return (
    <BoxInputMain {...props}>
      <TextFieldMain {...props} />
    </BoxInputMain>
  );
};
export default InputMain;
