import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/InfoOutlined';

export default function ProdutoNaoDisponivel({ msgNaoDisponivel }) {
  const history = useHistory();

  const irParaHome = () => {
    history.push('/home');
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box>
          <Grid container className="grid-container-finalizacao">
            <Grid item xs={1} sm={2} md={3} lg={4} xl={4} />
            <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
              <Typography variant="h4" className="mt-4 texto-centro">
                <InfoIcon className="texto-warning" sx={{ fontSize: '60px' }} />
              </Typography>
              <Typography
                variant="h4"
                className="mt-4 texto-cor-principal texto-negrito texto-centro font-size-24"
              >
                Informação
              </Typography>
              <Typography
                variant="subtitle1"
                className="mt-3 texto-cor-cinza-escuro texto-esquerda font-size-16"
              >
                {msgNaoDisponivel === 'ANALISE' && (
                  <span>
                    Já existe uma operação de Giro Parcelado em andamento, aguarde a finalização.
                  </span>
                )}
                {msgNaoDisponivel === 'BLOQUEADO' && (
                  <span>Produto não disponível, entre com uma nova solicitação na Home.</span>
                )}
                {msgNaoDisponivel === 'VENCIDO' && (
                  <span>Produto fora do prazo, entre com uma nova solicitação através da Home.</span>
                )}
              </Typography>

              <Button className="mt-5 btn-pagina-inicial" onClick={() => irParaHome()}>
                Ir para Home
              </Button>
            </Grid>
            <Grid item xs={1} sm={2} md={3} lg={4} xl={4} />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
