import React, { memo } from 'react';
import strip from '../../../../utils/strip';
import CustomMaskedInput from '../CustomMaskedInput/CustomMaskedInput';
import { CMC7Mask } from '../../../masks/masks';
import { getCMC7 } from '../../../../api/borderos';

/*
  Segue exemplo de CMC7 (CMC7):
  001176860182038965985000095639
  <00117686<0182038965>985000095639Ç

  Onde:
  Banco (CODBANCO): 001
  Agência (CODAGENCIA): 1768
  Compensação (COMPENSACAO): 018
  Documento (NDOC): 203896
  Conta Corrente (CODCC): 8500009563
*/

const CMC7Input = props => {
  const { onSearch, searchHandler, ...otherProps } = props;
  const maxLength = 34;
  const minLength = 30;

  const checkCMC7Data = e => {
    let element = props;
    let maskedValue = element.field.value || '';
    let stripped = strip(element.field.value);

    if (e && e.type && e.type === 'paste') {
      maskedValue = e.clipboardData.getData('Text');
      stripped = strip(e.clipboardData.getData('Text'));
      otherProps.form.setFieldValue('cheque.cmc7', maskedValue);
    }

    if ((stripped.length === minLength || stripped.length === maxLength) && onSearch) {
      const banco = stripped.substring(0, 3);
      const agencia = stripped.substring(3, 7);
      const DV2 = stripped.substring(7, 8);
      const compensacao = stripped.substring(8, 11);
      const numeroDocumento = stripped.substring(11, 17); // Numero do CHEQUE
      const tipificacao = stripped.substring(17, 18);
      const DV1 = stripped.substring(18, 19);
      const contaCorrente = stripped.substring(19, 29);
      const DV3 = stripped.substring(29, 30);

      let cm7 = {
        banco,
        agencia,
        DV2,
        compensacao,
        numeroDocumento,
        tipificacao,
        DV1,
        contaCorrente,
        DV3
      };

      otherProps.form.setSubmitting(true);

      getCMC7(maskedValue)
        .then(res => {
          let _doc = '',
            _nome = '';

          if (res.data.juridica) {
            _doc = res.data.juridica.cnpj;
            _nome = res.data.juridica.nomeFanasia;
          } else if (res.data.fisica) {
            _doc = res.data.fisica.cpf;
            _nome = res.data.fisica.nome;
          }

          cm7 = { ...cm7, documento: _doc, nome: _nome, eCliente: res.data.eCliente };
        })
        .finally(() => {
          otherProps.form.setSubmitting(false);
          onSearch(cm7, otherProps.form);
        });
    } else {
      return onSearch({}, otherProps.form);
    }
  };

  const handleChange = e => {
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) || // only numbers
      (e.keyCode >= 96 && e.keyCode <= 105) // only numbers (keypad)
    ) {
      checkCMC7Data();
    }
  };

  return (
    <div className="loadingWrapper">
      <CustomMaskedInput
        onKeyUp={handleChange}
        onPaste={checkCMC7Data}
        {...otherProps}
        mask={CMC7Mask}
      />
      {otherProps.form.isSubmitting && (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          <span className="sr-only">Carregando...</span>
        </>
      )}
    </div>
  );
};

export default memo(CMC7Input);
