import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import Main from '../Main/Main';
import Descricao from './Descricao';
import Garantias from './Garantias';
import CheckCircle from '../../assets/img/check-circle.svg';

export default function JornadaCredito(props) {
  const history = useHistory();
  const [garantiaFinalizada, setGarantiaFinalizada] = useState(false);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let dados = { ...props };
    if (dados.location && dados.location.state && dados.location.state.novaJornadaCredito) {
      // criado esse controle para ele não burlar atraves da URL
      // Realiza a verificação se ele pode realizar uma nova jornada, caso venha o parametro novaJornadaCredito
    } else {
      // caso não vier o parametro, será redirecionada para a Home
      irParaHome();
    }
  }, []);

  const irParaHome = () => {
    history.push('/home');
  };

  return (
    <Main {...props}>
      <Card className="jornada-credito">
        <CardContent className="pad-0">
          <Box>
            {garantiaFinalizada === false && (
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="grid-container-descricao">
                  <Descricao />
                </Grid>
                <Grid className="mt-3" item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Garantias setGarantiaFinalizada={setGarantiaFinalizada} />
                </Grid>
              </Grid>
            )}
            {garantiaFinalizada && (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box>
                    <Grid container className="grid-container-finalizacao">
                      <Grid item xs={1} sm={2} md={3} lg={4} xl={4} />
                      <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
                        <Typography
                          variant="h4"
                          className="mt-4 texto-cor-principal texto-negrito texto-centro font-size-32"
                        >
                          <img src={CheckCircle} alt="finalizado" className="check-circle" />
                        </Typography>
                        <Typography
                          variant="h4"
                          className="mt-4 texto-cor-principal texto-negrito texto-centro font-size-24"
                        >
                          Recebemos a sua solicitação
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className="mt-3 texto-cor-cinza-escuro texto-esquerda font-size-16"
                        >
                          Sua documentação está em análise. Não se preocupe, vamos atender sua
                          solicitação o mais rápido possível.
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className="mt-3 texto-cor-cinza-escuro texto-esquerda font-size-16"
                        >
                          Se tiver alguma dúvida, entre em contato com a gente pelo chat ou ligue para
                          <b> (15) 3042-0017.</b>
                        </Typography>

                        <Button className="mt-5 btn-pagina-inicial" onClick={() => irParaHome()}>
                          {onlySmallScreen ? 'Página inicial' : 'Ir para página inicial'}
                        </Button>
                      </Grid>
                      <Grid item xs={1} sm={2} md={3} lg={4} xl={4} />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </CardContent>
      </Card>
    </Main>
  );
}
