import React from 'react';
import { Col, Row, Progress, Button } from 'reactstrap';
import { downloadFileToken } from '../../../../shared';
// import { Field } from 'formik';

export default ({ file, deleteFile, retryUpload }) => {
  if (!file) {
    return null;
  }

  const loadingStatus = () => {
    if (file.statusCode >= 400) {
      return <div className="status-fail">Falha</div>;
    } else if (file.statusCode === 200) {
      return <div className="status-success">Sucesso</div>;
    } else if (file.arquivo) {
      return (
        <Button
          target="blank"
          onClick={() => downloadFileToken(file.arquivo)}
          className="btn-minimal"
          color="link"
        >
          <i className="fa fa-download icon-times" />
        </Button>
      );
    }
    return <Progress color="success" value={file.progress} />;
  };

  const _deleteFile = idFile => {
    deleteFile(file, idFile);
  };

  const _retryUpload = () => {
    retryUpload(file);
  };

  const showRetryUpload = () => {
    if (file.statusCode >= 400) {
      return (
        <Button className="btn-minimal" color="link" onClick={_retryUpload}>
          <p>Tentar novamente</p>
        </Button>
      );
    }
    return null;
  };

  /**
   * Caso não exista file.name, está listando os arquivos do bordero. Configura o fileName para ser exibido
   */
  let fileName = '';
  if (!file.name && file.arquivo) {
    fileName = file.arquivo.split('/');
    fileName = fileName[fileName.length - 1].split('-');
    fileName = fileName[fileName.length - 1];
  }

  return (
    <Row className="mb-2">
      <Col md={!file.arquivo ? (file.statusCode >= 400 ? 5 : 9) : 10} className="my-auto font-bold">
        {file.name || fileName}
      </Col>
      <Col md={!file.arquivo ? 2 : 1} className="my-auto font-bold">
        {loadingStatus()}
      </Col>
      <Col md="1" className="my-auto">
        <Button
          className="btn-minimal"
          color="link"
          onClick={() => {
            _deleteFile(file.id);
          }}
        >
          <i className={'fas fa-times icon-times ' + (isNaN(file.id) ? 'color-danger' : '')} />
        </Button>
      </Col>
      {file.statusCode >= 400 && (
        <Col md="4" className="my-auto">
          {showRetryUpload()}
        </Col>
      )}
      {file.observacao && (
        <Col md="12" className={'my-auto file-obs ' + (isNaN(file.id) ? 'status-fail' : '')}>
          {file.observacao}
        </Col>
      )}
    </Row>
  );
};
