import React from 'react';
import MainHeader from './MainHeader';
import BrokerCliente from './UsuarioLogado/BrokerCliente';

export default ({ children, brokerCadastro = {}, plataformaCadastro = {}, ...props }) => {
  return (
    <>
      <MainHeader />
      <main className="main-without-menu font-family-ibm-plex" {...props}>
        {children}
        <BrokerCliente
          dadosBrokerValidado={brokerCadastro}
          dadosPlataformaValidada={plataformaCadastro}
          buscarDadosCliente={props && props.tela && props.tela === 'cadastro_usuario' ? false : true}
        />
      </main>
    </>
  );
};
