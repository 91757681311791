import moment from 'moment';
import API from './api';
import removeNullKeys from '../utils/remove-null-keys';

const getBoletos = instrucaosIdList => {
  return API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/boletos`, {
    instrucoes: instrucaosIdList
  });
};

const postCancelarInstrucoes = instrucaosIdList => {
  return API.put(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/cancelar`, {
    instrucoes: instrucaosIdList
  });
};

const postSimularInstrucao = instrucao => {
  return API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/simular`, instrucao);
};

const postInstrucao = instrucao => {
  return API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes`, instrucao);
};

const getTiposInstrucao = () => {
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/tipos`);
};

const getTitulosDisponiveis = tipoInstrucao => {
  const params = {
    tipo_instrucao: tipoInstrucao
  };
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/titulos`, { params });
};

const getMotivosInstrucao = tipoInstrucao => {
  const params = {
    tipo_instrucao: tipoInstrucao
  };
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/motivos`, { params });
};

const getRegraDataVencimento = (codTitulo, dataAgendamento) => {
  const params = {
    cod_titulo: codTitulo,
    data_agendamento: dataAgendamento
  };
  return API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/regras/data-vencimento`,
    { params }
  );
};

const getRegraDataAgendamento = tipoInstrucao => {
  const params = {
    tipo_instrucao: tipoInstrucao
  };
  return API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/regras/data-agendamento`,
    { params }
  );
};

const getInstrucoesListaResumida = (values = {}) => {
  if (Object.keys(values).length > 0) {
    values = {
      ...values,
      instrucao: values.instrucao ? values.instrucao.replace(/\s/gm, '') : null,
      documento: values.documento ? values.documento.replace(/\s/gm, '') : null,
      data_inicio: values.data_inicio
        ? moment(values.data_inicio, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null,
      data_final: values.data_final
        ? moment(values.data_final, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null
    };
  }
  removeNullKeys(values);
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/lista-resumida`, {
    params: { ...values }
  });
};

const getInstrucoesListaResumidaTitulos = idInstrucao =>
  API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/${idInstrucao}/lista-resumida-titulos`
  );

const getInstrucoesPareceres = idInstrucao =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/${idInstrucao}/pareceres`);

const postParecer = parecer => {
  return API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/parecer`, {
    parecer: parecer
  });
};

const uploadAnexoInstrucao = (file, cb) =>
  API.post(`/upload/titulo`, file, {
    //trocar de API2 para API
    onUploadProgress: function(progressEvent) {
      cb(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
    }
  });

const getInstrucoesAnexos = codInstrucao =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/${codInstrucao}/anexos`);

const getEmailInstrucoesAnexos = codInstrucao =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/${codInstrucao}/email`);

const saveInstrucoesFilesURL = (codInstrucao, arquivos) => {
  return API.post(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/${codInstrucao}/anexos`,
    arquivos
  );
};

const deleteInstrucaoFile = (codInstrucao, idFile) => {
  API.delete(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/instrucoes/${codInstrucao}/anexos/${idFile}`
  );
};

export {
  getBoletos,
  postCancelarInstrucoes,
  getTiposInstrucao,
  getTitulosDisponiveis,
  getMotivosInstrucao,
  getInstrucoesListaResumida,
  postSimularInstrucao,
  postInstrucao,
  getRegraDataVencimento,
  getRegraDataAgendamento,
  getInstrucoesListaResumidaTitulos,
  getInstrucoesPareceres,
  postParecer,
  getInstrucoesAnexos,
  getEmailInstrucoesAnexos,
  uploadAnexoInstrucao,
  saveInstrucoesFilesURL,
  deleteInstrucaoFile
};
