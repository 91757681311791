import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { Card, CardContent, Container, Grid, Typography } from '@mui/material';
import * as CPF from '@fnando/cpf';
import { recoveryPassword } from '../../api/authentication';
import strip from '../../utils/strip';
import MainOnlyHeader from '../Main/MainOnlyHeader';
import ButtonMain from '../Onboarding/shared/ButtonMain';
import InputMain from '../Onboarding/shared/InputMain/InputMain';
import { setEmailValidaToken, setTelaRequisitoraToken } from '../../shared/services/User';

export default () => {
  const history = useHistory();
  const [isPending, setPending] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({});
  const [isRecoveredPassword, setRecoveredPassword] = useState(false);
  const [message, setMessage] = useState(false);

  const handleFormSubmit = (values, form) => {
    setPending(true);
    const entity = {
      login: values.email,
      documento: strip(values.documento)
    };

    recoveryPassword(entity)
      .then(() => {
        setForgotPassword(entity);
        form.resetForm();
        setRecoveredPassword(true);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          setEmailValidaToken(values.email);
          setTelaRequisitoraToken('recuperar-senha');
          history.push('/confirmacao');
        } else {
          setMessage('Verifique os dados e tente novamente');
        }
      })
      .finally(() => {
        setPending(false);
        form.setSubmitting(false);
      });
  };

  const limpaMsgErro = () => {
    setMessage(false);
  };

  const acessaLogin = () => {
    history.push('/authentication/login');
  };

  return (
    <MainOnlyHeader>
      <Container fixed>
        <Grid container>
          <Grid item xl={2} lg={2} md={2} sm={1} xs={12} />
          <Grid item xl={8} lg={8} md={8} sm={10} xs={12}>
            <Card variant="outlined" className="box-shadow-natural-5 border-radius-8">
              <CardContent>
                <Grid container>
                  <Grid item xl={2} lg={3} md={4} sm={4} xs={4}>
                    <Link
                      to="/authentication/login"
                      className="texto-cor-cinza-escuro height-fit-content"
                    >
                      <ArrowBackIcon />
                      &nbsp; Voltar
                    </Link>
                  </Grid>
                  <Grid item xl={10} lg={9} md={8} sm={8} xs={8} />
                  <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                  <Grid item xl={8} lg={8} md={8} sm={10} xs={10} sx={{ p: '100px 0 100px 0' }}>
                    {isRecoveredPassword === false ? (
                      <>
                        <Typography
                          variant="h5"
                          className="texto-cor-principal texto-negrito texto-centro"
                          sx={{ margin: '0 0 20px 0' }}
                        >
                          Informe seus dados
                        </Typography>
                        <Formik
                          initialValues={{
                            email: ''
                          }}
                          validationSchema={Yup.object().shape({
                            email: Yup.string().required('Informe seu login'),
                            documento: Yup.string()
                              .required('Informe o CPF')
                              .nullable()
                              .test({
                                exclusive: true,
                                message: params => {
                                  const strippedValue = strip(params.value);
                                  if (strippedValue.length === 11 && CPF.isValid(strippedValue)) {
                                    return 'CPF válido';
                                  }
                                  return 'CPF inválido';
                                },
                                name: 'documento',
                                test: value => CPF.isValid(value)
                              })
                          })}
                          onSubmit={handleFormSubmit}
                          validateOnChange={false}
                          validateOnBlur={false}
                          render={({ isSubmitting }) => {
                            return (
                              <>
                                <Form>
                                  <Grid container>
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={12} />
                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                                      <Field
                                        type="text"
                                        name="documento"
                                        titulo="CPF"
                                        id="documento"
                                        size="small"
                                        className="w-100"
                                        placeholder="000.000.000-00"
                                        uppercase={true}
                                        component={InputMain}
                                        autoComplete="off"
                                        tipoMascara="CPFMask"
                                        disabled={isPending || isSubmitting}
                                      />

                                      <Field
                                        type="text"
                                        name="email"
                                        titulo="Login"
                                        id="email"
                                        size="small"
                                        component={InputMain}
                                        onChangeInput={limpaMsgErro}
                                        className="w-100"
                                        placeholder="Informe seu login"
                                        uppercase={false}
                                        autoComplete="off"
                                        disabled={isPending || isSubmitting}
                                      />

                                      {message && (
                                        <Typography
                                          variant="subtitle1"
                                          className="texto-danger pad-10 texto-centro"
                                        >
                                          {message}
                                        </Typography>
                                      )}
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={2} className="mt-3">
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={12} />
                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                                      <ButtonMain
                                        type="submit"
                                        tipoBotao="principal"
                                        disabled={isPending || isSubmitting}
                                      >
                                        Recuperar acesso
                                      </ButtonMain>
                                    </Grid>
                                  </Grid>
                                </Form>
                              </>
                            );
                          }}
                        />
                      </>
                    ) : (
                      <Grid container>
                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} />
                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                          <div className="texto-centro">
                            <MarkEmailReadIcon
                              className="font-size-45 texto-info"
                              sx={{ color: '#0085ff' }}
                            />
                          </div>

                          <Typography
                            variant="h5"
                            className="mt-3 texto-cor-principal texto-negrito texto-centro"
                          >
                            E-mail enviado!
                          </Typography>

                          <Typography
                            variant="subtitle1"
                            className="texto-cor-cinza-escuro pad-10 texto-centro"
                          >
                            Um e-mail foi enviado para o endereço&nbsp;
                            <strong>{forgotPassword.email}</strong>
                            <p>Siga as instruções para redefinir sua senha.</p>
                          </Typography>

                          <Grid container className="">
                            <Grid item xl={3} lg={3} md={3} sm={3} xs={12} />
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                              <center className="mt-2">
                                <ButtonMain tipoBotao="principal" onClick={acessaLogin}>
                                  Acessar
                                </ButtonMain>
                              </center>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MainOnlyHeader>
  );
};
