import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import ClientesDashboard from './ClientesDashboard';
import PropostasEsteira from './PropostasEsteira';
import LoaderMain from '../../Onboarding/shared/LoaderMain';

const VisaoGeral = ({ filtroDashboard, clienteAtual, loadingDashFim, dadosUser, escopoAcesso }) => {
  const [abaAtiva, mudarAba] = useState(1);
  const [loadPipelinePropostas, setLoadPipelinePropostas] = useState(false);

  // !!!!!! -------- USADO APENAS PARA PUBLICAÇÃO INICIAL - ACESSO INICIAL PARA POUCOS -------- !!!!!!
  const [habilitarAcessoEsteiraProposta, setHabilitarAcessoEsteiraProposta] = useState(false);

  const trocarAba = (event, newValue) => {
    mudarAba(newValue);
  };

  // !!!!!! -------- USADO APENAS PARA PUBLICAÇÃO INICIAL - ACESSO INICIAL PARA POUCOS -------- !!!!!!
  useEffect(() => {
    let filtro = filtroDashboard ? filtroDashboard : {};
    if (filtro.filtrar === false) return false;
    if (
      escopoAcesso &&
      escopoAcesso.componente &&
      escopoAcesso.componente['dashboard-visao-geral-pipeline-propostas']
    ) {
      setHabilitarAcessoEsteiraProposta(true);
    } else {
      loadingDashFim('PropostasEsteira');
    }
  }, [filtroDashboard]);

  return (
    <Card className="chart-card">
      <CardContent className="ajuste-spin-chart">
        <Typography
          variant="subtitle1"
          className="texto-cor-cinza-escuro pad-5 texto-weight-600 margin-bottom-25"
        >
          Visão geral
        </Typography>
        <Grid container className="margin-topbot-15 items-centro-verticalmente">
          <Grid item xl={3} lg={4} md={5} sm={12} xs={12}>
            <Tabs
              value={parseInt(abaAtiva)}
              onChange={trocarAba}
              variant="fullWidth"
              className="hide-tabs-indicator preencher-fundo-selected"
            >
              <Tab
                item={0}
                // !!!!!! -------- USADO APENAS PARA PUBLICAÇÃO INICIAL - ACESSO INICIAL PARA POUCOS -------- !!!!!!
                className={`borda-radius-left borda-1 font-bold sem-uppercase texto-cor-cinza-3 ${
                  !habilitarAcessoEsteiraProposta ? 'pointer-events-unset' : ''
                }`}
                disabled={loadPipelinePropostas || !habilitarAcessoEsteiraProposta}
                title={!habilitarAcessoEsteiraProposta ? 'EM BREVE...' : ''}
                icon={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <>
                    <Check className="font-size-1-1-rem margin-neg-top-2 margin-right-5 font-bold selected-cor-principal" />
                    <Box className="flex-box-wrap flex-direction-column">
                      <Typography
                        variant="subtitle1"
                        className="font-size-085 texto-centro selected-cor-principal line-height-1"
                      >
                        Pipeline
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="font-size-1-rem texto-centro selected-cor-principal line-height-1 font-bold"
                      >
                        Propostas
                      </Typography>
                    </Box>
                    {loadPipelinePropostas && (
                      <Box sx={{ pl: '10px', mr: '-50px' }}>
                        <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                      </Box>
                    )}
                  </>
                }
              />
              <Tab
                item={1}
                className="borda-radius-right borda-1 font-bold sem-uppercase texto-cor-cinza-3"
                icon={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <>
                    <Check className="font-size-1-1-rem margin-neg-top-2 margin-right-5 font-bold selected-cor-principal" />
                    <Box className="flex-box-wrap flex-direction-column">
                      <Typography
                        variant="subtitle1"
                        className="font-size-085 texto-centro selected-cor-principal line-height-1"
                      >
                        Pipeline
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="font-size-1-rem texto-centro selected-cor-principal line-height-1 font-bold"
                      >
                        Negócios
                      </Typography>
                    </Box>
                  </>
                }
              />
            </Tabs>
          </Grid>
          <Grid item xl={6} lg={4} md={1} sm={12} xs={12} />
          <Grid item xl={3} lg={4} md={5} sm={12} xs={12}>
            <Box
              className={`fundo-cor-azul-branco border-radius-8 pad-15 ${
                abaAtiva !== 0 ? 'hidden' : ''
              }`}
            >
              <Typography className="font-size-1 font-bold texto-cor-cinza-escuro margin-bottom-10">
                Legenda situação da proposta:
              </Typography>

              <Grid container>
                <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                  <Box className="fundo-info border-radius-50pct width-height-15px display-inline-block margin-right-10" />
                  <Typography
                    variant="subtitle1"
                    className="font-size-080 texto-cor-cinza-escuro display-inline-block"
                  >
                    Dentro do prazo
                  </Typography>
                </Grid>
                <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                  <Box className="fundo-warning-strong border-radius-50pct width-height-15px display-inline-block margin-right-10" />
                  <Typography
                    variant="subtitle1"
                    className="font-size-080 texto-cor-cinza-escuro display-inline-block"
                  >
                    Fora do prazo
                  </Typography>
                </Grid>
                <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                  <Box className="fundo-success border-radius-50pct width-height-15px display-inline-block margin-right-10" />
                  <Typography
                    variant="subtitle1"
                    className="font-size-080 texto-cor-cinza-escuro display-inline-block"
                  >
                    Apto a Operar
                  </Typography>
                </Grid>
                <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                  <Box className="fundo-danger border-radius-50pct width-height-15px display-inline-block margin-right-10" />
                  <Typography
                    variant="subtitle1"
                    className="font-size-080 texto-cor-cinza-escuro display-inline-block"
                  >
                    Inapto a Operar
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box role="tabpanel" hidden={abaAtiva !== 0} className="min-height-20vh">
          {/* !!!!!! -------- USADO APENAS PARA PUBLICAÇÃO INICIAL - ACESSO INICIAL PARA POUCOS -------- !!!!!! */}
          {habilitarAcessoEsteiraProposta && (
            <PropostasEsteira
              filtroDashboard={filtroDashboard}
              loadingDashFim={loadingDashFim}
              parentAbaAtiva={abaAtiva}
              parentMudarAbaAtiva={mudarAba}
              dadosUser={dadosUser}
              setLoadPipelinePropostas={setLoadPipelinePropostas}
            />
          )}
        </Box>
        <Box role="tabpanel" hidden={abaAtiva !== 1} className="min-height-20vh" sx={{ m: '0 -16px' }}>
          <ClientesDashboard
            filtroDashboard={filtroDashboard}
            loadingDashFim={loadingDashFim}
            parentAbaAtiva={abaAtiva}
            parentMudarAbaAtiva={mudarAba}
            dadosUser={dadosUser}
            clienteAtual={clienteAtual}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
export default VisaoGeral;
