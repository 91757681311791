import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { Field } from 'formik';
import InputMain from '../../shared/InputMain/InputMain';
import RadioBoxMain from '../../shared/InputMain/RadioBoxMain';

export default ({ values, campoValidacao, setCampoValidacao, isDisabled }) => {
  return (
    <Box>
      <Box>
        <Typography
          variant="h5"
          className={
            'texto-cor-principal texto-negrito ' +
            (campoValidacao === 'data_relacionamento' ? 'texto-danger-strong' : '')
          }
        >
          Relacionamento
        </Typography>
        <Typography
          variant="subtitle1"
          className={
            'texto-cor-cinza-escuro pad-topbot-10 ' +
            (campoValidacao === 'data_relacionamento' ? 'texto-danger-strong' : '')
          }
        >
          Informe caso possua relacionamento com a empresa
        </Typography>
      </Box>
      <Grid
        key="boxRelacionamento"
        className="pad-bot-20 margin-neg-top-10"
        container
        spacing={1}
        sx={{ height: '110px' }}
      >
        <Grid item xl={4} lg={4} md={6} xs={12} className="pad-top-20">
          <Field
            id="radio_relacionamento"
            name="radio_relacionamento"
            component={RadioBoxMain}
            disabled={isDisabled}
            corRadioBox="checkbox-cor-complementar"
            row
            opcoes={[
              { value: 'S', label: 'Possui' },
              { value: 'N', label: 'Não Possui' }
            ]}
          />
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          xs={12}
          className="pad-top-0"
          sx={values.radio_relacionamento === 'S' ? {} : { display: 'none' }}
        >
          <Field
            type="text"
            name="data_relacionamento"
            titulo="Data do Relacionamento"
            id="data_relacionamento"
            size="small"
            uppercase={true}
            component={InputMain}
            InputProps={{ maxLength: 10 }}
            autoComplete="off"
            disabled={isDisabled || values.radio_relacionamento !== 'S'}
            inputClassName={
              'input-disabled-cinza ' + (campoValidacao === 'data_relacionamento' ? 'input-error' : '')
            }
            tipoMascara="dateMask"
            onChangeInput={() => {
              if (campoValidacao === 'data_relacionamento') setCampoValidacao('');
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
