/* eslint-disable import/prefer-default-export */
import API from './api';

export const getClientes = () => API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/clientes`);
export const getClientesOnboarding = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/clientes/clientes-onboarding`);

export const getClientesStatus = cnpj =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/clientes/clientes-status/${cnpj}`);

export const getClienteBrokerDados = codCliente =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/clientes/dados-broker-cliente/${codCliente}`);

export const getPropostaBrokerDados = codPPC =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/clientes/dados-broker-proposta/${codPPC}`);

export const getClientesUsuario = value =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/clientes/clientes-usuario/${value}`);
