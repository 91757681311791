import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Stepper, Step, StepLabel, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import MainOnlyHeader from '../../Main/MainOnlyHeader';
import CadastroEmpresa from '../Jornada/CadastroEmpresa';
import EnvioDocumentos from '../Jornada/EnvioDocumentos';
import ParecerFinal from './ParecerFinal';
import PerfilCarteira from './PerfilCarteira';
import GerenciarDocumentos from './GerenciarDocumentos';
import FinalizacaoJornada from './FinalizacaoJornada';
import LoaderMain from '../shared/LoaderMain';
import etapasStatusConnect from '../shared/Enums/EnumsEtapasStatusConnect';
import { getEnvioProposta } from '../../../api/onboarding';
import { getClientesStatus } from '../../../api/clientes';
import { getClienteAtual, etapaTelaHome, setClienteStatus } from '../../../shared/services/User';
import AlertErroMain from '../shared/AlertErroMain';

export default props => {
  const [mostraErro, setMostraErro] = useState(false);
  const [isPending, setPending] = useState(false);
  const [isPendingStatusEtapa, setPendingStatusEtapa] = useState(false);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepDesc, setActiveStepDesc] = useState('');
  const [subStepDesc, setSubStepDesc] = useState('');
  const [origemProposta, setOrigemProposta] = useState('digital');
  const [clienteStatus, setClienteStatusTela] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let clienteSelecionado = getClienteAtual();
    if (clienteSelecionado && typeof clienteSelecionado !== 'undefined' && clienteSelecionado !== null) {
      setCnpjEmpresa(clienteSelecionado.cnpj);
    } else {
      history.push('/authentication/login');
    }
  }, []);

  useEffect(() => {
    if (clienteStatus === false) return false;
    let origemPropostaTemp =
      clienteStatus && clienteStatus.ppc_status && clienteStatus.ppc_status.tipo_origem_proposta
        ? clienteStatus.ppc_status.tipo_origem_proposta
        : 'digital';
    if (origemPropostaTemp !== 'connect' && origemPropostaTemp !== 'indicantes') {
      history.push('/jornada-onboarding');
    }
    setOrigemProposta(origemPropostaTemp);
  }, [clienteStatus]);

  useEffect(() => {
    avancarEtapa();
  }, [cnpjEmpresa]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const mudarEtapa = stepDesc => {
    var stepNumbers = {
      CadastroEmpresa: 0,
      PerfilCarteira: 1,
      EnvioDocumentos: 2,
      ParecerFinalGerente: 3,
      GerenciarDocumentos: 4,
      FinalizacaoJornada: 5
    };
    if (typeof stepNumbers[stepDesc] === 'undefined') return true;
    setActiveStepDesc(stepDesc);
    setActiveStep(stepNumbers[stepDesc]);
  };

  const avancarEtapa = (siglaStatusPrioritaria = '') => {
    if (siglaStatusPrioritaria !== undefined && siglaStatusPrioritaria !== '') {
      definirEtapa(siglaStatusPrioritaria);
      return true;
    }

    if (cnpjEmpresa === undefined || cnpjEmpresa === '') return false;

    if (etapaTelaHome()) {
      history.push('/');
    }

    setPending(true);
    setPendingStatusEtapa(true);
    getClientesStatus(cnpjEmpresa)
      .then(res => {
        setClienteStatus(res.data);
        setClienteStatusTela(res.data);
        var siglaStatus =
          res.data && res.data.ppc_status && res.data.ppc_status.sigla ? res.data.ppc_status.sigla : '';
        if (siglaStatus === '') {
          verificaPropostaEnviada(cnpjEmpresa);
        } else {
          definirEtapa(siglaStatus);
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPending(false);
        setPendingStatusEtapa(false);
      });
  };

  const verificaPropostaEnviada = cnpjEmpresa => {
    setPending(true);
    setPendingStatusEtapa(true);

    getEnvioProposta(cnpjEmpresa)
      .then(res => {
        let siglaStatus = res.data && res.data.enviada ? 'ANE' : '';
        definirEtapa(siglaStatus);
      })
      .catch(() => {})
      .finally(() => {
        setPending(false);
        setPendingStatusEtapa(false);
      });
  };

  const definirEtapa = siglaStatus => {
    var sigla = siglaStatus !== undefined && siglaStatus !== '' ? siglaStatus : 'INICIO';

    // SE EXISTIR, AVANÇA PARA A ETAPA CONFIGURADA
    if (typeof etapasStatusConnect[sigla] !== 'undefined') {
      var dadosStep = etapasStatusConnect[sigla];
      mudarEtapa(dadosStep.etapa);
      setSubStepDesc(dadosStep.subEtapa);

      // SE A SIGLA DO STATUS NAO ESTIVER DENTRO DO ENUM DO CONNECT,
      //   MAS EXISTIR NO ENUM DO CONNECT, REDIRECIONA PARA JORNADA DIGITAL
    } else if (typeof etapasStatusConnect[sigla] !== 'undefined') {
      history.push('/jornada-onboarding');

      // SE A SIGLA DO STATUS NAO ESTIVER DENTRO DO ENUM REDIRECIONA PARA HOME
    } else {
      history.push('/');
    }
  };

  return (
    <MainOnlyHeader {...props}>
      <Container fixed>
        <Card variant="outlined" className="box-shadow-natural-5 border-radius-8">
          <CardContent>
            {(isPending || isPendingStatusEtapa || cnpjEmpresa === '' || activeStepDesc === '') && (
              <LoaderMain />
            )}
            {cnpjEmpresa !== '' && activeStepDesc !== '' && (
              <>
                {activeStep < 4 && (
                  <Box
                    sx={isPending || isPendingStatusEtapa ? { display: 'none' } : { m: '10px 0 0 0' }}
                  >
                    <Stepper activeStep={activeStep} alternativeLabel className="steps-jornada">
                      <Step
                        key="1"
                        id="step-1-jornada"
                        className={
                          activeStepDesc === 'EnvioDocumentos'
                            ? 'esconde-step step-principal'
                            : 'step-principal'
                        }
                      >
                        <StepLabel>Cadastro</StepLabel>
                      </Step>
                      <Step
                        key="2"
                        id="step-2-jornada"
                        className={
                          activeStepDesc === 'EnvioDocumentos'
                            ? 'esconde-step step-principal'
                            : 'step-principal'
                        }
                      >
                        <StepLabel>Perfil</StepLabel>
                      </Step>
                      <Step key="3" id="step-3-jornada" className="step-principal">
                        <StepLabel>Documentos</StepLabel>
                      </Step>
                      <Step key="4" id="step-4-jornada" className="step-principal">
                        <StepLabel>Parecer</StepLabel>
                      </Step>
                    </Stepper>
                  </Box>
                )}

                <Box
                  sx={
                    isPending || isPendingStatusEtapa
                      ? { display: 'none' }
                      : activeStepDesc !== 'GerenciarDocumentos'
                      ? { p: '40px 0 0 10px' }
                      : ''
                  }
                >
                  {activeStepDesc === 'CadastroEmpresa' && (
                    <CadastroEmpresa
                      avancarEtapa={avancarEtapa}
                      isPending={isPending}
                      setPending={setPending}
                      cnpjEmpresa={cnpjEmpresa}
                      origemProposta={origemProposta}
                      clienteStatus={clienteStatus}
                    />
                  )}
                  {activeStepDesc === 'PerfilCarteira' && (
                    <PerfilCarteira
                      avancarEtapa={avancarEtapa}
                      isPending={isPending}
                      setPending={setPending}
                      cnpjEmpresa={cnpjEmpresa}
                      origemProposta={origemProposta}
                    />
                  )}
                  {activeStepDesc === 'EnvioDocumentos' && subStepDesc !== '' && (
                    <EnvioDocumentos
                      avancarEtapa={avancarEtapa}
                      isPending={isPending}
                      setPending={setPending}
                      cnpjEmpresa={cnpjEmpresa}
                      tipoAbaAtiva={subStepDesc}
                      origemProposta={origemProposta}
                    />
                  )}
                  {activeStepDesc === 'ParecerFinalGerente' && (
                    <ParecerFinal
                      avancarEtapa={avancarEtapa}
                      isPending={isPending}
                      setPending={setPending}
                      cnpjEmpresa={cnpjEmpresa}
                      origemProposta={origemProposta}
                    />
                  )}
                  {activeStepDesc === 'GerenciarDocumentos' && (
                    <GerenciarDocumentos
                      avancarEtapa={avancarEtapa}
                      isPending={isPending}
                      setPending={setPending}
                      cnpjEmpresa={cnpjEmpresa}
                      origemProposta={origemProposta}
                    />
                  )}
                  {activeStepDesc === 'FinalizacaoJornada' && <FinalizacaoJornada />}
                </Box>
              </>
            )}

            <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />
          </CardContent>
        </Card>
      </Container>
    </MainOnlyHeader>
  );
};
