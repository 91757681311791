import React from 'react';
import { Box, Grid } from '@mui/material';

import StatusMesa from './StatusMesa/StatusMesa';
import UltimosBorderos from './Grids/UltimosBorderos/UltimosBorderos';
import LimitesCarteiraCompacto from './Charts/LimitesCarteira/LimitesCarteiraCompacto';
import Vencidos from './Charts/Vencidos/Vencidos';
import Liquidez from './Charts/Liquidez/Liquidez';
import Avencer from './Charts/Avencer/Avencer';
import ConcentracaoSacado from './Charts/ConcentracaoSacado/ConcentracaoSacado';
import VisaoGeral from './VisaoGeral';

const DashboardCliente = ({
  clienteAtual,
  dadosUser,
  loadingDashFim,
  valorTotalVencidos,
  valorTotalAVencer,
  setValorTotalVencidos,
  setValorTotalAVencer,
  valoresCalculados,
  qtdeClientesAVencer,
  setQtdeClientesAVencer,
  escopoAcesso
}) => {
  return (
    <Box className="dashboard-cliente">
      <Box className="container-box">
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <StatusMesa clienteAtual={clienteAtual} loadingDashFim={loadingDashFim} />
          </Grid>
        </Grid>
      </Box>
      <Box className="container-box charts-ultimos-borderos-limites-carteira">
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <UltimosBorderos clienteAtual={clienteAtual} loadingDashFim={loadingDashFim} />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <LimitesCarteiraCompacto
              clienteAtual={clienteAtual}
              loadingDashFim={loadingDashFim}
              dadosUser={dadosUser}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="container-box charts-vencidos-avencer">
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Vencidos
              clienteAtual={clienteAtual}
              loadingDashFim={loadingDashFim}
              valorTotal={valorTotalVencidos}
              setValorTotal={setValorTotalVencidos}
              valoresCalculados={valoresCalculados}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Avencer
              clienteAtual={clienteAtual}
              loadingDashFim={loadingDashFim}
              valorTotal={valorTotalAVencer}
              setValorTotal={setValorTotalAVencer}
              valoresCalculados={valoresCalculados}
              qtdeClientes={qtdeClientesAVencer}
              setQtdeClientes={setQtdeClientesAVencer}
              dadosUser={dadosUser}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="container-box charts-liquidez-concentracao">
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Liquidez clienteAtual={clienteAtual} loadingDashFim={loadingDashFim} />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <ConcentracaoSacado
              clienteAtual={clienteAtual}
              dadosUser={dadosUser}
              loadingDashFim={loadingDashFim}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="container-box charts-clientes-grid">
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <VisaoGeral
              clienteAtual={clienteAtual}
              loadingDashFim={loadingDashFim}
              dadosUser={dadosUser}
              escopoAcesso={escopoAcesso}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default DashboardCliente;
