/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { Close, WarningAmberRounded } from '@mui/icons-material';
import CorrenteFechada from '../../../../assets/img/corrente_fechada.svg';
import { mascararCpfCnpj, pegaLetrasIniciaisNome } from '../../../../shared';
import { getConjuntoEmpresasProdutos, getPareceresClientes } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import ButtonMain from '../../../Onboarding/shared/ButtonMain';

const ModalGridDashboard = ({ modalGrid, setModalGrid }) => {
  const [isPending, setIsPending] = useState(false);
  const [dadosConjuntos, setDadosConjuntos] = useState([]);
  const [dadosPareceres, setDadosPareceres] = useState([]);
  const [arrayCardPareceres, setArrayCardPareceres] = useState([]);
  const [mostrarTodosPareceres, setMostrarTodosPareceres] = useState('N');

  useEffect(() => {
    if (
      modalGrid &&
      modalGrid.modal === 'grupo_economico' &&
      modalGrid.params &&
      modalGrid.params.limiteConjunto === 'S' &&
      typeof modalGrid.produtosConjunto === 'undefined'
    ) {
      if (typeof dadosConjuntos[modalGrid.params.id] !== 'undefined') {
        setModalGrid({ ...dadosConjuntos[modalGrid.params.id], ...modalGrid });
      } else {
        setIsPending(true);
        getConjuntoEmpresasProdutos(modalGrid.params.id)
          .then(res => {
            if (res && res.data) {
              setModalGrid({ ...res.data, ...modalGrid });
              let dadosConjuntosTemp = [...dadosConjuntos];
              dadosConjuntosTemp[modalGrid.params.id] = res.data;
              setDadosConjuntos(dadosConjuntosTemp);
            }
          })
          .finally(() => {
            setIsPending(false);
          });
      }
    }

    if (modalGrid && modalGrid.modal === 'parecer' && modalGrid.params && modalGrid.params.cnpjCliente) {
      setIsPending(true);
      setDadosPareceres([]);
      setArrayCardPareceres([]);
      getPareceresClientes(modalGrid.params.cnpjCliente, mostrarTodosPareceres)
        .then(res => {
          if (res && res.data) {
            let dados = res.data;
            let arrayPareceres = [];
            dados.map(d => {
              arrayPareceres[d.id] = false;
              return d;
            });

            setArrayCardPareceres(arrayPareceres);
            setDadosPareceres(res.data);
          }
        })
        .finally(() => {
          setIsPending(false);
        });
    }

    if (!modalGrid) {
      setMostrarTodosPareceres('N');
    }
  }, [modalGrid, mostrarTodosPareceres]);

  const buttonExpandirParecer = id => {
    let newArray = [...arrayCardPareceres];
    newArray[id] = newArray[id] ? false : true;
    setArrayCardPareceres(newArray);
  };

  return (
    <Dialog
      open={modalGrid !== false}
      fullWidth={true}
      maxWidth={
        modalGrid && modalGrid.modal === 'parecer' ? 'lg' : modalGrid.modal === 'status' ? 'sm' : 'md'
      }
      keepMounted
      onClose={() => setModalGrid(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="borda-bottom-2-gray pad-topbot-10">
          {modalGrid ? modalGrid.modalTitle : ''}
          <IconButton onClick={() => setModalGrid(false)} className="float-right margin-neg-top-5">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {modalGrid && modalGrid.modal === 'grupo_economico' && modalGrid.params ? (
          <Box sx={isPending ? { position: 'relative' } : {}}>
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Box className="pad-15 flex-box-items-centralizados fundo-cor-azul-claro border-radius-8 margin-top-10">
              <Avatar src={CorrenteFechada} sx={{ width: 22, height: 22 }} className="margin-right-10" />
              <Typography
                variant="subtitle1"
                className="font-size-1-1-rem texto-cor-cinza-escuro font-bold"
              >
                {`${modalGrid.params.nomeCliente} - ${mascararCpfCnpj(modalGrid.params.cnpjCliente)}`}
              </Typography>
            </Box>
            <Box className="pad-top-20">
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro font-bold">
                Tipo do limite:
              </Typography>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro font-size-1-1-rem">
                {modalGrid.params.limiteConjunto === 'S' ? 'Conjunto' : 'Individual'}
                {modalGrid.produtosConjunto && modalGrid.produtosConjunto.length > 0
                  ? ` para produto${modalGrid.produtosConjunto.length > 1 ? 's' : ''} `
                  : ''}
                <span>
                  {modalGrid.produtosConjunto && modalGrid.produtosConjunto.length > 0
                    ? modalGrid.produtosConjunto.map(prd => prd.nomeProduto.toLowerCase()).join(' e ')
                    : ''}
                </span>
              </Typography>
            </Box>
            {modalGrid.empresasConjunto && modalGrid.empresasConjunto.length > 1 && (
              <Box className="pad-top-20">
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro font-bold">
                  Empresas do conjunto:
                </Typography>
                {modalGrid.empresasConjunto &&
                  modalGrid.empresasConjunto.map(emp => (
                    <Typography
                      key={`cli_${emp.cnpjCliente}`}
                      variant="subtitle1"
                      className="texto-cor-cinza-escuro"
                    >
                      {`${emp.nomeCliente} - ${mascararCpfCnpj(emp.cnpjCliente)}`}
                    </Typography>
                  ))}
              </Box>
            )}
          </Box>
        ) : modalGrid && modalGrid.modal === 'status' ? (
          <Box className="margin-top-10">
            {modalGrid.params.status &&
              modalGrid.params.status.map((alerta, index) => (
                <Box
                  key={alerta.id}
                  className={`${
                    modalGrid.params.status.length !== index + 1
                      ? 'borda-bottom-2-gray margin-bottom-25'
                      : ''
                  } pad-bot-30`}
                >
                  <Box
                    className={`fundo-alerta-${alerta.tipo} pad-10 flex-box-items-centralizados border-radius-8 margin-bottom-20`}
                  >
                    <WarningAmberRounded
                      className={`texto-alerta-${alerta.tipo}`}
                      sx={{ margin: '-5px 5px 0 0' }}
                    />
                    <Typography variant="subtitle1" className="texto-cor-cinza-escuro font-bold">
                      {alerta.titulo}
                    </Typography>
                  </Box>
                  <Typography variant="subtitle1" className="texto-cor-cinza-escuro">
                    {alerta.mensagem}
                  </Typography>
                </Box>
              ))}
          </Box>
        ) : modalGrid && modalGrid.modal === 'parecer' && modalGrid.params ? (
          <Box className="box-parecer" sx={isPending ? { position: 'relative', height: '250px' } : {}}>
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Box className="pad-5 texto-centro sub-titulo border-radius-8 margin-top-10">
              <Typography
                variant="subtitle1"
                className="font-size-1-1-rem texto-cor-cinza-escuro font-bold"
              >
                {`${modalGrid.params.nomeCliente} - ${mascararCpfCnpj(modalGrid.params.cnpjCliente)}`}
              </Typography>
            </Box>
            <Grid className="time-line">
              {dadosPareceres &&
                dadosPareceres.map(parecer => (
                  <Grid container key={parecer.id}>
                    <Grid className="border-line-right" item xl={1} lg={1} md={1} sm={1} xs={1} />
                    <Grid
                      className="border-line-left time-card"
                      item
                      xl={11}
                      lg={11}
                      md={11}
                      sm={11}
                      xs={11}
                    >
                      <Box className="timeline-badge" sx={{ background: parecer.CORPARECER }}>
                        {pegaLetrasIniciaisNome(parecer.TIPOPARECER)}
                      </Box>
                      <Box className="timeline-card">
                        <Box>
                          <Typography
                            variant="subtitle1"
                            className="timeline-title texto-cor-cinza-escuro font-bold"
                          >
                            {parecer.TIPOPARECER}
                            <span className="timeline-inclusao"> &nbsp; </span>
                            <span className="timeline-inclusao">
                              {`${parecer.DATAINCLUSAOFORMATADA} por ${parecer.USUAINCLUSAO}`}
                            </span>
                          </Typography>
                        </Box>

                        <Box
                          className={`box-pareceres ${
                            arrayCardPareceres[parecer.id] ? '' : 'limitar-tamanho-parecer'
                          }`}
                        >
                          <Box dangerouslySetInnerHTML={{ __html: parecer.OBS }} />
                        </Box>
                        <Box>
                          <Button
                            onClick={() => buttonExpandirParecer(parecer.id)}
                            className="float-right"
                            sx={{ textTransform: 'inherit' }}
                          >
                            {arrayCardPareceres[parecer.id] ? 'Ver menos' : 'Ver mais'}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ))}

              {dadosPareceres.length >= 6 && mostrarTodosPareceres === 'N' && (
                <Box className="texto-centro">
                  <ButtonMain
                    sx={{ maxWidth: '190px', marginTop: '15px' }}
                    tipoBotao="principal"
                    onClick={() => setMostrarTodosPareceres('S')}
                  >
                    Mostrar tudo
                  </ButtonMain>
                </Box>
              )}
            </Grid>
          </Box>
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalGridDashboard;
