import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from '@mui/material';
import LoaderMain from '../../Onboarding/shared/LoaderMain';
import ButtonMain from '../../Onboarding/shared/ButtonMain';
import Detalhes from '../Detalhes';

export default ({
  dadosDocumentosSociedade,
  detalhesDocumentos,
  setDetalhesDocumentos,
  iconeStatus,
  listaTiposDocumentos,
  clienteSelecionado = false,
  cnpjEmpresa = false,
  isPending,
  carregarAbaSociedade,
  bloquearTipoDocDuplicado = true,
  arquivosAceitos = ['pdf']
}) => {
  const [listaDocs, setListaDocs] = useState([]);
  const [chaveSociedadeSelected, setChaveSociedadeSelected] = useState(0);
  const [dadosSocioSelected, setDadosSocioSelected] = useState({});

  useEffect(() => {
    if (chaveSociedadeSelected !== 0 && Object.keys(dadosDocumentosSociedade).length > 0) {
      if (typeof dadosDocumentosSociedade[chaveSociedadeSelected] !== 'undefined') {
        setListaDocs(dadosDocumentosSociedade[chaveSociedadeSelected].documentos);
        setDadosSocioSelected(dadosDocumentosSociedade[chaveSociedadeSelected].dados);
      } else {
        fecharDocumentos();
      }
    }
  }, [chaveSociedadeSelected, dadosDocumentosSociedade]);

  const verDocumentos = chaveSociedade => {
    setChaveSociedadeSelected(chaveSociedade);
    setDetalhesDocumentos(true);
  };

  const fecharDocumentos = () => {
    setChaveSociedadeSelected(0);
    setDetalhesDocumentos(false);
  };

  return (
    <Box sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      {detalhesDocumentos === false ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead className="tbl-head-doc-clientes">
              <TableRow>
                <TableCell align="left" className="texto-semi-negrito">
                  SOCIEDADE
                </TableCell>
                <TableCell align="left" className="texto-semi-negrito">
                  TIPO
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '170px' }} className="texto-semi-negrito">
                  STATUS
                </TableCell>
                <TableCell align="left" sx={{ width: '270px' }} />
              </TableRow>
            </TableHead>
            <TableBody className="tab-body-doc-clientes">
              {Object.keys(dadosDocumentosSociedade).length > 0 ? (
                Object.keys(dadosDocumentosSociedade).map(chaveSocio => {
                  let dadosDocs = dadosDocumentosSociedade[chaveSocio].dados;
                  return (
                    <TableRow key={'socio_' + chaveSocio}>
                      <TableCell align="left">{dadosDocs.nome}</TableCell>
                      <TableCell align="left">{dadosDocs.tipoSociedadeLabel}</TableCell>
                      <TableCell align="left">
                        {iconeStatus[dadosDocs.status_documentos]}
                        {dadosDocs.status_documentos}
                      </TableCell>
                      <TableCell align="left">
                        <ButtonMain
                          tipoBotao="principal"
                          onClick={() => verDocumentos(chaveSocio)}
                          sx={{ m: '10px 0' }}
                        >
                          VER DOCUMENTOS
                          {dadosDocs.status_documentos === 'REPROVADO' && (
                            <div className="icone-alert-right-top" />
                          )}
                        </ButtonMain>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    Nenhum Registro Encontrado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Detalhes
          tipo="sociedade"
          clienteSelecionado={clienteSelecionado}
          cnpjEmpresa={cnpjEmpresa}
          listaDocumentos={listaDocs}
          setListaDocumentos={setListaDocs}
          fecharDocumentos={fecharDocumentos}
          dados={dadosSocioSelected}
          listaTiposDocumentos={listaTiposDocumentos}
          carregarAba={carregarAbaSociedade}
          bloquearTipoDocDuplicado={bloquearTipoDocDuplicado}
          arquivosAceitos={arquivosAceitos}
        />
      )}
    </Box>
  );
};
