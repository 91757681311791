import React, { useState, useEffect, memo } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';
import { FastField, Form, Formik, Field } from 'formik';
import * as CPF from '@fnando/cpf';
import * as CNPJ from '@fnando/cnpj';
import * as Yup from 'yup';
import strip from '../../../../../utils/strip';
import { Input, Select, SkeletonLoader, CPFInput } from '../../../../../shared';
import { getBancos, getAgencias, postContasCorrentes } from '../../../../../api/dominios';
import { searchNamehByCPFOrCNPJ, searchContasViaJornadaDigital } from '../../../../../api/clientSearch';

const tipoContas = [
  { value: 'C', label: 'CONTA-CORRENTE' },
  { value: 'P', label: 'CONTA-POUPANÇA' }
];

const validationSchema = Yup.object().shape({
  documentoFavorecido: Yup.string()
    .nullable()
    .required('Informe o documento.')
    .test({
      exclusive: true,
      message: params => {
        const strippedValue = strip(params.value);
        if (strippedValue.length === 11) {
          return 'CPF inválido.';
        } else if (strippedValue.length === 14) {
          return 'CNPJ inválido.';
        }
        return 'CPF/CNPJ inválido.';
      },
      name: 'CPF-CNPJ',
      test: value => {
        if (value) {
          return CNPJ.isValid(value) || CPF.isValid(value);
        }
        return true;
      }
    }),
  nomeFavorecido: Yup.string()
    .nullable()
    .required('Insira o nome do favorecido'),
  codAgenciaBanco: Yup.string()
    .nullable()
    .required('Informe a agência do banco.'),
  codBanco: Yup.number()
    .nullable()
    .required('Selecione o banco.'),
  numeroConta: Yup.number().required('Insira o número da conta.'),
  digitoConta: Yup.number().required('Insira o dígito da conta.'),
  tipoConta: Yup.string()
    .nullable()
    .required('Selecione o tipo de conta.')
});

const ModalNovaConta = props => {
  const { modal, toggle, setContaCorrente, clienteAtual = {} } = props;
  const [bancos, setBancos] = useState([]);
  const [agencias, setAgencias] = useState({
    isPending: false,
    values: [
      {
        value: '',
        label: '-- Selecione um banco'
      }
    ]
  });
  const [isPending, setIsPending] = useState(true);
  const [message, setMessage] = useState();
  const [disableCpfCnpf, setDisableCpfCnpf] = useState(true);

  const initialValues = {
    documentoFavorecido: clienteAtual.cnpj,
    nomeFavorecido: clienteAtual.razaoSocial || clienteAtual.nomeFantasia,
    codBanco: undefined,
    codAgenciaBanco: '',
    numeroConta: '',
    digitoConta: '',
    tipoConta: ''
  };

  useEffect(() => {
    getBancos()
      .then(res => {
        const bancosForSelect = res.data.map(banco => {
          return {
            value: banco.codBanco,
            label: `${banco.codBanco} - ${banco.nomeBanco}`
          };
        });
        setBancos(bancosForSelect);
      })
      .finally(() => {
        setIsPending(false);
      });
    contaViaJornadaDigital();
  }, []);

  const contaViaJornadaDigital = () => {
    return searchContasViaJornadaDigital(clienteAtual.cnpj)
      .then(resContaJornada => {
        if (resContaJornada && resContaJornada.data && resContaJornada.data.onboarding) {
          setDisableCpfCnpf(true);
        } else {
          setDisableCpfCnpf(false);
        }
      })
      .catch(err => {
        const {
          response: { data: { error: { message = 'Erro ao buscar os dados' } = {} } = {} } = {}
        } = err;
        setMessage(message);
        setIsPending(false);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleFormSubmit = (values, form) => {
    return postContasCorrentes(values)
      .then(res => {
        return setContaCorrente(res.data).then(() => {
          toggle();
        });
      })
      .catch(err => {
        const {
          response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
        } = err;
        setMessage(message);
        form.setSubmitting(false);
        setIsPending(false);
      });
  };

  const onClientSearch = (data, form) => {
    if (data) {
      const { nome } = data;
      form.setFieldValue('nomeFavorecido', nome);
    }
  };

  const onBancoChange = (codBanco, form) => {
    setAgencias({
      isPending: true,
      values: [
        {
          value: '',
          label: ''
        }
      ]
    });
    return getAgencias(codBanco)
      .then(res => {
        const agenciasForSelect = res.data.map(agencia => {
          return {
            value: agencia.codAgenciaBanco,
            label: agencia.nomeAgencia
          };
        });
        setAgencias({
          isPending: false,
          values: agenciasForSelect
        });
        form.setFieldValue('codAgenciaBanco', agenciasForSelect[0].value);
      })
      .finally(() => {});
  };

  const CPFInputWrapper = props => (
    <CPFInput {...props} searchHandler={searchNamehByCPFOrCNPJ} onSearch={onClientSearch} />
  );

  const BancosSelectWrapper = props => {
    const { form } = props;
    return (
      <Select
        {...props}
        onChange={option => {
          form.setFieldValue('codAgenciaBanco', '');

          if (option) {
            onBancoChange(option.value, form);
          } else {
            setAgencias({
              isPending: false,
              values: [
                {
                  value: '',
                  label: '-- Selecione um banco',
                  selected: true
                }
              ]
            });
          }
        }}
      />
    );
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="md" backdrop="static">
      <Formik
        validationSchema={validationSchema}
        validate={({ numeroConta, digitoConta }) => {
          let errors = {};
          const numberRegExp = /\D/gm;
          if (numberRegExp.test(numeroConta)) errors.numeroConta = 'Digite somente números';
          if (numberRegExp.test(digitoConta)) errors.digitoConta = 'Digite somente números';
          return errors;
        }}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        render={({ isSubmitting }) => {
          return (
            <Form className={isPending || isSubmitting ? 'is-submitting' : ''}>
              <ModalHeader toggle={toggle}>Cadastro de conta para pagamento</ModalHeader>
              <ModalBody>
                {message && (
                  <div className="alert alert-danger animated fadeIn" role="alert">
                    {message}
                  </div>
                )}
                <SkeletonLoader
                  isPending={isPending}
                  width="100%"
                  height="30px"
                  count={8}
                  widthRandomness={1}
                >
                  <Row>
                    <Col lg="6" md="12">
                      <Field
                        type="text"
                        label="CPF ou CNPJ"
                        name="documentoFavorecido"
                        disabled={isPending || isSubmitting || disableCpfCnpf}
                        autoComplete="off"
                        component={CPFInputWrapper}
                      />
                    </Col>
                    <Col lg="6" md="12">
                      <FastField
                        type="text"
                        label="Favorecido"
                        name="nomeFavorecido"
                        id="input-favorecido"
                        disabled={isPending || isSubmitting || disableCpfCnpf}
                        autoComplete="off"
                        component={Input}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Field
                        label="Banco"
                        id="select-banco"
                        name="codBanco"
                        options={bancos}
                        component={BancosSelectWrapper}
                        disabled={isPending || isSubmitting}
                        autoComplete="off"
                        placeholder=""
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" md="12">
                      <Field
                        label="Agência"
                        id="select-agencia"
                        name="codAgenciaBanco"
                        options={agencias.values}
                        component={Select}
                        loading={agencias.isPending}
                        disabled={isPending || isSubmitting}
                        autoComplete="off"
                        placeholder=""
                      />
                    </Col>
                    <Col lg="6" md="12">
                      <FastField
                        type="text"
                        label="Conta"
                        name="numeroConta"
                        id="input-conta"
                        disabled={isPending || isSubmitting}
                        autoComplete="off"
                        component={Input}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" md="12">
                      <FastField
                        type="text"
                        label="Digito"
                        name="digitoConta"
                        id="input-digito"
                        disabled={isPending || isSubmitting}
                        autoComplete="off"
                        component={Input}
                      />
                    </Col>
                    <Col lg="6" md="12">
                      <Field
                        label="Tipo"
                        id="select-tipo"
                        name="tipoConta"
                        options={tipoContas}
                        component={Select}
                        disabled={isPending || isSubmitting}
                        autoComplete="off"
                        placeholder=""
                      />
                    </Col>
                  </Row>
                </SkeletonLoader>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  size="lg"
                  className="mr-auto py-0"
                  outline
                  onClick={toggle}
                  disabled={isPending || isSubmitting}
                >
                  Fechar
                </Button>
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  className="py-0"
                  disabled={isPending || isSubmitting}
                >
                  Confirmar e Finalizar
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default memo(ModalNovaConta);
