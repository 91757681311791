import { FormGroup, Input, Label } from 'reactstrap';
import React from 'react';

export default props => {
  const { classes, onSearch } = props;
  return (
    <div className="row col-md-6">
      <div className="col-md-9">
        <FormGroup className="tamanhoSearch">
          <Label for="search" className="sr-only">
            Busca
          </Label>
          <Input
            type="search"
            onChange={event => onSearch(event.target.value)}
            id="search"
            autoComplete="off"
            className={classes || ''}
            placeholder="&#xEAED; Buscar"
          />
        </FormGroup>
      </div>
    </div>
  );
};
