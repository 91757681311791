import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as CPF from '@fnando/cpf';
import * as Yup from 'yup';
import { conformToMask } from 'react-text-mask';
import InputMain from '../../../shared/InputMain/InputMain';
import ButtonMain from '../../../shared/ButtonMain';
import ToggleButtonMain from '../../../shared/ToggleButtonMain';
// import { estadosCivil, regimesBens } from '../../../shared/Enums/EnumsOnboarding';
import { estadosCivil } from '../../../shared/Enums/EnumsOnboarding';
import validaCaracteresEspeciais from '../../../../../utils/validaCaracteresEspeciais';
import strip from '../../../../../utils/strip';
import { formatarData } from '../../../../../utils/format';
import * as masks from '../../../../../shared/masks/masks';
import { postCadastroDadosSocios, deleteDadosCadastroSocio } from '../../../../../api/onboarding';
import AlertErroMain from '../../../shared/AlertErroMain';

export default ({
  mudarAba,
  todosDadosCadastrais,
  setTodosDadosCadastrais,
  isPendingCadastro,
  setPendingCadastro,
  finalizarCadastro,
  setMsgFinalizando
}) => {
  const naoTemSocio =
    todosDadosCadastrais.DadosSocios === undefined || todosDadosCadastrais.DadosSocios.length === 0
      ? true
      : false;
  const [dadosFormulario, setDadosFormulario] = useState({});
  const [listaSocios, setListaSocios] = useState(
    todosDadosCadastrais.DadosSocios !== undefined ? todosDadosCadastrais.DadosSocios : []
  );
  const [listaCpfSocios, setListaCpfSocios] = useState(
    todosDadosCadastrais.DadosSocios !== undefined
      ? todosDadosCadastrais.DadosSocios.map(valor => valor.cpf)
      : []
  );
  const [mostraFormulario, setMostraFormulario] = useState(naoTemSocio);
  const [socioAlterando, setSocioAlterando] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('SLT');
  // const [regimeBens, setRegimeBens] = useState('');
  const [pessoaPoliticaExposta, setPessoaPoliticaExposta] = useState('N');
  const [modalDeletarSocio, setModalDeletarSocio] = useState(false);
  const [erroToggleButtons, setErroToggleButtons] = useState('');
  const [mostraErro, setMostraErro] = useState(false);
  // const [erroCPFConjuge, setErroCPFConjuge] = useState('');

  const controlarDadosFomulario = dados => {
    dados.data_nascimento = dados.data_nascimento ? formatarData(dados.data_nascimento) : '';
    dados.ddd = dados.ddd ? dados.ddd : '';
    dados.fone = dados.fone ? dados.fone : '';
    dados.fone = dados.ddd + '' + dados.fone;
    // if (!dados.regime_casamento) dados.regime_casamento = '';
    // if (!dados.cpf_conjuge) dados.cpf_conjuge = '';
    // if (!dados.nome_conjuge) dados.nome_conjuge = '';
    setDadosFormulario(dados);

    setEstadoCivil(dados && dados.estado_civil ? dados.estado_civil : 'SLT');
    // setRegimeBens(dados && dados.regime_casamento ? dados.regime_casamento : '');
    setPessoaPoliticaExposta(dados && dados.politicamente_exposta ? dados.politicamente_exposta : 'N');
  };

  const confirmarSocios = () => {
    if (listaSocios === undefined || listaSocios.length === 0) return false;
    var dadosCad = { ...todosDadosCadastrais };
    dadosCad.DadosSocios = listaSocios;
    setTodosDadosCadastrais(dadosCad);
    finalizarCadastro();
  };

  const novoSocio = () => {
    controlarDadosFomulario({});
    setSocioAlterando('');
    setMostraFormulario(true);
  };

  const alterarSocio = (indexSocio, dadosSocio) => {
    controlarDadosFomulario(dadosSocio);
    setSocioAlterando(indexSocio);
    setMostraFormulario(true);
  };

  const deletarSocio = () => {
    var { indexSocio, cpfSocio, cnpjEmpresa } = modalDeletarSocio;
    setModalDeletarSocio(false);
    setPendingCadastro(true);
    deleteDadosCadastroSocio(cnpjEmpresa, cpfSocio)
      .then(() => {
        var tmpListaSocios = listaSocios;
        tmpListaSocios.splice(indexSocio, 1);
        setListaSocios(tmpListaSocios);

        var tmpListaCpfSocios = listaCpfSocios;
        tmpListaCpfSocios.splice(tmpListaCpfSocios.indexOf(cpfSocio), 1);
        setListaCpfSocios(tmpListaCpfSocios);
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPendingCadastro(false);
      });
  };

  const formatarPayLoad = dadosForm => {
    dadosForm.nome = dadosForm.nome ? dadosForm.nome.toUpperCase() : dadosForm.nome;
    // dadosForm.nome_conjuge = dadosForm.nome_conjuge
    //   ? dadosForm.nome_conjuge.toUpperCase()
    //   : dadosForm.nome_conjuge;
    dadosForm.cnpj = todosDadosCadastrais.cnpj;
    dadosForm.cpf = strip(dadosForm.cpf);
    dadosForm.fone = dadosForm.fone ? strip(dadosForm.fone) : '';
    dadosForm.ddd = dadosForm.fone.substr(0, 2);
    dadosForm.fone = dadosForm.fone.substr(2);
    dadosForm.data_nascimento = dadosForm.data_nascimento ? formatarData(dadosForm.data_nascimento) : '';
    dadosForm.estado_civil = estadoCivil;
    dadosForm.politicamente_exposta = pessoaPoliticaExposta;
    // if (estadoCivil === 'CSD') {
    //   dadosForm.cpf_conjuge = strip(dadosForm.cpf_conjuge);
    //   dadosForm.regime_casamento = regimeBens;
    // } else {
    //   delete dadosForm.regime_casamento;
    //   delete dadosForm.cpf_conjuge;
    //   delete dadosForm.nome_conjuge;
    // }
    return dadosForm;
  };

  const handleFormSubmit = (values, form) => {
    var dadosForm = { ...values };
    dadosForm = formatarPayLoad(dadosForm);
    // if (dadosForm.estado_civil === 'CSD' && dadosForm.cpf_conjuge === dadosForm.cpf) {
    //   setErroCPFConjuge('O CPF do conjugê não pode ser igual do sócio');
    //   form.setSubmitting(false);
    //   return false;
    // }

    if (dadosForm.estado_civil === '') {
      setErroToggleButtons('estado_civil');
      form.setSubmitting(false);
      return false;
      // } else if (dadosForm.estado_civil === 'CSD' && dadosForm.regime_casamento === '') {
      //   setErroToggleButtons('regime_bens');
      //   form.setSubmitting(false);
      //   return false;
    } else if (dadosForm.politicamente_exposta === '') {
      setErroToggleButtons('politicamente_exposta');
      form.setSubmitting(false);
      return false;
    } else {
      setErroToggleButtons('');
    }

    setPendingCadastro(true);
    postCadastroDadosSocios(dadosForm)
      .then(() => {
        var tmpListaSocios = listaSocios !== undefined ? listaSocios : [];
        if (socioAlterando === '') {
          tmpListaSocios.push(dadosForm);
          setListaSocios(tmpListaSocios);

          var tmpListaCpfSocios = listaCpfSocios !== undefined ? listaCpfSocios : [];
          tmpListaCpfSocios.push(dadosForm.cpf);
          setListaCpfSocios(tmpListaCpfSocios);
        } else {
          tmpListaSocios[socioAlterando] = dadosForm;
          setListaSocios(tmpListaSocios);
        }

        setPendingCadastro(false);
        form.setSubmitting(false);

        setMostraFormulario(false);
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPendingCadastro(false);
        form.setSubmitting(false);
      });
  };

  const botaoVoltar = () => {
    if (
      listaSocios === undefined ||
      listaSocios.length === 0 ||
      (listaSocios !== undefined && listaSocios.length > 0 && mostraFormulario === false)
    ) {
      mudarAba('DadosEndereco');
    } else if (listaSocios !== undefined && listaSocios.length > 0 && mostraFormulario === true) {
      setMostraFormulario(false);
    }
  };

  React.useEffect(() => {
    setMsgFinalizando(!mostraFormulario);
  }, [mostraFormulario]);

  return (
    <>
      {mostraFormulario ? (
        <Formik
          initialValues={dadosFormulario}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nome: Yup.string()
              .nullable()
              .required('Favor informar o nome')
              .test('nome', 'Nome obrigatório', value => {
                if (value && value.toString().replace(/\s/g, '') === '') {
                  return false;
                } else if (value && value.length > 0) {
                  value = value.trim();
                  return value.includes(' ');
                }
                return true;
              })
              .test('nome', 'Caracteres inválidos', value => {
                let caracteresEspeciais = [`'`, `*`, `"`];
                return validaCaracteresEspeciais(value, caracteresEspeciais);
              }),
            cpf: Yup.string()
              .nullable()
              .required('Campo obrigatório.')
              .test({
                exclusive: true,
                message: 'CPF inválido.',
                name: 'cpf',
                test: value => {
                  if (value) {
                    const strippedValue = strip(value);
                    if (strippedValue.length !== 11) return false;
                    return CPF.isValid(strippedValue);
                  }
                  return true;
                }
              })
              .test('cpf', 'CPF já inserido.', value => {
                if (value && socioAlterando === '') {
                  var validarListaCpfSocios = listaCpfSocios !== undefined ? listaCpfSocios : [];
                  const cpfStripped = strip(value);
                  return !validarListaCpfSocios.includes(cpfStripped);
                }
                return true;
              }),
            data_nascimento: Yup.string()
              .nullable()
              .test({
                message: () => {
                  return 'Data inválida';
                },
                name: 'data_nascimento',
                test: data => {
                  if (data !== undefined && data !== '' && data !== null) {
                    if (data.length >= 10) {
                      data = data.substr(0, 10);
                      let dia = data.split('/')[0];
                      let mes = data.split('/')[1];
                      let ano = data.split('/')[2];
                      let dataAtual = new Date(ano, mes - 1, dia);
                      if (
                        dataAtual.getMonth() + 1 !== parseInt(mes) ||
                        dataAtual.getDate() !== parseInt(dia) ||
                        dataAtual.getFullYear() !== parseInt(ano) ||
                        dataAtual > new Date()
                      ) {
                        return false;
                      }
                      if (dataAtual.getFullYear() < 1900) {
                        return false;
                      }
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return true;
                }
              }),
            email: Yup.string()
              .nullable()
              .required('Favor informar o e-mail'),
            fone: Yup.string()
              .nullable()
              .required('Favor informar o celular')
              .test({
                message: 'Celular inválido',
                test: telefone => {
                  if (telefone !== undefined && telefone !== '' && telefone !== null) {
                    const tel = strip(telefone);
                    const ddd = parseInt(tel.substring(0, 2), 10);
                    if (tel === undefined) return false;
                    const telWithoutDDD = tel.substring(2, tel.length);
                    if (ddd <= 10 || ddd >= 100) {
                      return false;
                    }
                    if (
                      (telWithoutDDD.length === 8 ||
                        (telWithoutDDD.length === 9 &&
                          [7, 8, 9].includes(parseInt(telWithoutDDD.charAt(0), 10)))) === false
                    ) {
                      return false;
                    }
                    if (telWithoutDDD.split('').every(char => char === telWithoutDDD[0])) {
                      return false;
                    }
                  }
                  return true;
                }
              })
            // ,cpf_conjuge: Yup.string()
            //   .nullable()
            //   .test('termos', 'Favor informar o CPF do conjuge', value => {
            //     if (estadoCivil === 'CSD') {
            //       if (value) {
            //         const strippedValue = strip(value);
            //         if (strippedValue.length !== 11) return false;
            //         return CPF.isValid(strippedValue);
            //       }
            //       return false;
            //     }
            //     return true;
            //   }),
            // nome_conjuge: Yup.string()
            //   .nullable()
            //   .test('termos', 'Favor informar o nome do conjuge', value => {
            //     if (estadoCivil === 'CSD') {
            //       if (value && value.toString().replace(/\s/g, '') === '') {
            //         return false;
            //       } else if (value && value.length > 0) {
            //         value = value.trim();
            //         return value.includes(' ');
            //       }
            //       return false;
            //     }
            //     return true;
            //   })
          })}
          onSubmit={handleFormSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ isSubmitting }) => (
            <Form>
              <ConnectedFocusError />
              <Field
                type="text"
                name="nome"
                titulo="Nome completo"
                id="nome"
                size="small"
                uppercase={true}
                component={InputMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting}
              />
              <Field
                type="text"
                name="cpf"
                titulo="CPF"
                id="cpf"
                size="small"
                uppercase={true}
                component={InputMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting}
                tipoMascara="CPFMask"
                InputProps={
                  socioAlterando !== ''
                    ? {
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <CheckIcon className="texto-success" />
                          </InputAdornment>
                        )
                      }
                    : {}
                }
                inputClassName={socioAlterando !== '' ? 'fundo-cor-cinza-claro' : ''}
              />
              <Field
                type="text"
                name="data_nascimento"
                titulo="Data de nascimento"
                id="data_nascimento"
                size="small"
                component={InputMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting}
                tipoMascara="dateMask"
                inputProps={{ maxLength: 10 }}
              />
              <Field
                type="text"
                name="fone"
                titulo="Celular"
                id="fone"
                size="small"
                uppercase={true}
                inputProps={{ maxLength: 15 }}
                component={InputMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting}
                tipoMascara="phoneMask"
              />
              <Field
                type="email"
                name="email"
                titulo="E-mail"
                id="email"
                size="small"
                uppercase={false}
                component={InputMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting}
              />

              <ToggleButtonMain
                titulo="Estado cívil"
                tipo="input"
                nomeInput="estado_civil"
                funcaoHandleChange={value => {
                  setEstadoCivil(value);
                  setErroToggleButtons('');
                }}
                atributoHandle={estadoCivil}
                atributosBotao={{ disabled: isPendingCadastro || isSubmitting }}
                botoes={Object.values(estadosCivil)}
                erro={
                  erroToggleButtons === 'estado_civil' ? 'Favor escolher o estado civil do sócio' : ''
                }
              />

              {/*estadoCivil === 'CSD' && (
                <>
                  <ToggleButtonMain
                    titulo="Regime de Bens"
                    tipo="input"
                    nomeInput="regime_bens"
                    funcaoHandleChange={value => {
                      setRegimeBens(value);
                      setErroToggleButtons('');
                    }}
                    atributoHandle={regimeBens}
                    atributosBotao={{ disabled: isPendingCadastro || isSubmitting }}
                    botoes={Object.values(regimesBens)}
                    erro={
                      erroToggleButtons === 'regime_bens'
                        ? 'Favor escolher o regime de bens do socio com o conjuge'
                        : ''
                    }
                  />
                  <Field
                    type="text"
                    name="nome_conjuge"
                    titulo="Nome completo do cônjuge"
                    id="nome_conjuge"
                    size="small"
                    uppercase={true}
                    component={InputMain}
                    autoComplete="off"
                    disabled={isPendingCadastro || isSubmitting}
                  />
                  <Field
                    type="text"
                    name="cpf_conjuge"
                    titulo="CPF do cônjuge"
                    id="cpf_conjuge"
                    size="small"
                    erro={erroCPFConjuge}
                    uppercase={true}
                    component={InputMain}
                    autoComplete="off"
                    disabled={isPendingCadastro || isSubmitting}
                    tipoMascara="CPFMask"
                  />
                </>
              )*/}
              <ToggleButtonMain
                titulo="É uma pessoa politicamente exposta (PEP)?"
                nomeInput="input"
                nome="politicamente_exposta"
                funcaoHandleChange={value => {
                  setPessoaPoliticaExposta(value);
                  setErroToggleButtons('');
                }}
                atributosBotao={{ disabled: isPendingCadastro || isSubmitting }}
                atributoHandle={pessoaPoliticaExposta}
                botoes={[
                  { valor: 'S', label: 'Sim', col: 3, sm: 4, xs: 6 },
                  { valor: 'N', label: 'Não', col: 3, sm: 4, xs: 6 }
                ]}
                erro={
                  erroToggleButtons === 'politicamente_exposta'
                    ? 'Favor escolher se a pessoa é ou não politicamente exposta'
                    : ''
                }
              />

              <Grid container spacing={3} className="pad-topbot-50">
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                >
                  <ButtonMain
                    tipoBotao="transparente"
                    disabled={isPendingCadastro || isSubmitting}
                    onClick={() => {
                      botaoVoltar();
                    }}
                  >
                    {socioAlterando === '' ? 'Voltar' : 'Cancelar alteração'}
                  </ButtonMain>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                  <ButtonMain
                    type="submit"
                    tipoBotao="principal"
                    disabled={isPendingCadastro || isSubmitting}
                  >
                    {socioAlterando === '' ? 'Continuar' : 'Alterar Sócio'}
                  </ButtonMain>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <Box className="borda-1 borda-cor-complementar pad-topbot-20 pad-right-left-15 border-radius-8">
            {listaSocios !== undefined && listaSocios.length > 0 ? (
              <>
                {listaSocios.map((socio, index) => {
                  var bordaBottom =
                    index + 1 === listaSocios.length ? 'borda-transparente' : 'borda-cor-cinza-claro';
                  return (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={socio.cpf + '_' + index}
                      className={`borda-bottom-2 ${bordaBottom}`}
                      sx={{ pb: '20px', mb: '25px' }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={8}>
                          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                            <b>CPF: </b>
                            {conformToMask(socio.cpf, masks.CPFMask).conformedValue}
                          </Typography>
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                          <ButtonMain
                            tipoBotao="transparente"
                            disabled={isPendingCadastro}
                            classesAdicional="min-width-none"
                            onClick={() => {
                              alterarSocio(index, socio);
                            }}
                          >
                            <CreateOutlinedIcon />
                          </ButtonMain>
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                          <ButtonMain
                            tipoBotao="transparente"
                            disabled={isPendingCadastro}
                            classesAdicional="min-width-none"
                            onClick={() => {
                              setModalDeletarSocio({
                                indexSocio: index,
                                cpfSocio: socio.cpf,
                                cnpjEmpresa: todosDadosCadastrais.cnpj
                              });
                            }}
                          >
                            <DeleteForeverOutlinedIcon />
                          </ButtonMain>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="subtitle1"
                        className="texto-cor-cinza-escuro pad-5 word-break"
                      >
                        <b>Nome: </b>
                        {socio.nome ? socio.nome.toUpperCase() : socio.nome}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="texto-cor-cinza-escuro pad-5 word-break"
                      >
                        <b>Data de nascimento: </b>
                        {socio.data_nascimento ? (
                          formatarData(socio.data_nascimento)
                        ) : (
                          <i>Não Informado</i>
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="texto-cor-cinza-escuro pad-5 word-break"
                      >
                        <b>Celular: </b>
                        {socio.fone ? (
                          conformToMask(socio.ddd + '' + socio.fone, masks.phoneMask).conformedValue
                        ) : (
                          <i>Não Informado</i>
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="texto-cor-cinza-escuro pad-5 word-break"
                      >
                        <b>E-mail: </b>
                        {socio.email ? socio.email : <i>Não Informado</i>}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="texto-cor-cinza-escuro pad-5 word-break"
                      >
                        <b>Estado cívil: </b>
                        {estadosCivil[socio.estado_civil] ? (
                          estadosCivil[socio.estado_civil].label
                        ) : (
                          <i>Indefinido</i>
                        )}
                      </Typography>
                      {/*socio.estado_civil === 'CSD' && (
                        <>
                          <Typography
                            variant="subtitle1"
                            className="texto-cor-cinza-escuro pad-5 word-break"
                          >
                            <b>Regime bens: </b>
                            {regimesBens[socio.regime_casamento]
                              ? regimesBens[socio.regime_casamento].label
                              : 'Indefinido'}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className="texto-cor-cinza-escuro pad-5 word-break"
                          >
                            <b>Nome cônjuge: </b>
                            {socio.nome_conjuge ? socio.nome_conjuge.toUpperCase() : 'Não Informado'}
                          </Typography>
                          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                            <b>CPF cônjuge: </b>
                            {socio.cpf_conjuge
                              ? conformToMask(socio.cpf_conjuge, masks.CPFMask).conformedValue
                              : 'Não Informado'}
                          </Typography>
                        </>
                      )*/}
                      <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                        <b>PEP: </b>
                        {socio.politicamente_exposta === 'S' ? 'Sim' : 'Não'}
                      </Typography>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Typography variant="h6" className="texto-cor-principal texto-negrito" sx={{ pb: '20px' }}>
                Nenhum sócio cadastrado para esta empresa
              </Typography>
            )}
            <ButtonMain
              type="button"
              tipoBotao="azul-escuro"
              disabled={isPendingCadastro}
              onClick={() => novoSocio()}
            >
              Adicionar outra sociedade
            </ButtonMain>
          </Box>

          <Grid container spacing={3} className="pad-topbot-50">
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}>
              <ButtonMain
                tipoBotao="transparente"
                disabled={isPendingCadastro}
                onClick={() => {
                  botaoVoltar();
                }}
              >
                Voltar
              </ButtonMain>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
              <ButtonMain
                type="button"
                tipoBotao="principal"
                disabled={isPendingCadastro || listaSocios === undefined || listaSocios.length === 0}
                onClick={() => confirmarSocios()}
              >
                Confirmar
              </ButtonMain>
            </Grid>
          </Grid>
          <Dialog
            open={modalDeletarSocio !== false}
            keepMounted
            onClose={() => setModalDeletarSocio(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
              Excluir dados do sócio
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ao confirmar esta ação todos as informações do sócio serão excluidas do cadastro. Você
                quer mesmo continuar?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xl={6} lg={6} md={4} sm={2} />
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  <ButtonMain tipoBotao="transparente" onClick={() => setModalDeletarSocio(false)}>
                    Cancelar
                  </ButtonMain>
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  <ButtonMain tipoBotao="azul-escuro" onClick={deletarSocio} autoFocus>
                    Confirmar
                  </ButtonMain>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </>
      )}
      <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />
    </>
  );
};
