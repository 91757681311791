export default () => {
  const config = [
    {
      dataField: 'sacado.nome',
      text: 'SACADO',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    },
    {
      dataField: 'tipoOperacao.ndoc',
      text: 'Nº DO DOC.',
      tipoOrdenacao: 'texto',
      tdAlign: 'center'
    },
    {
      dataField: 'tipoOperacao.vencimento',
      text: 'VENCIMENTO',
      tipoOrdenacao: 'data',
      formatacao: 'data',
      tdAlign: 'center'
    },
    {
      dataField: 'tipoOperacao.valor',
      text: 'VALOR BRUTO',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      tdAlign: 'right'
    }
  ];

  return config;
};
