import strip from '../../utils/strip';

export const CPFCNPJMask = value => {
  const stripped = strip(value);
  if (stripped.length > 11) {
    // Mascara de CNPJ
    return [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/
    ];
  }

  // Mascara de cpf
  return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
};

export const CPFMask = value => {
  const stripped = strip(value);
  const tamanho = stripped.length;
  if (tamanho > 3 && tamanho <= 6) {
    return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/];
  } else if (tamanho >= 7 && tamanho <= 9) {
    return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/];
  } else if (tamanho >= 10) {
    return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  } else {
    return [/\d/, /\d/, /\d/];
  }
};

export const CNPJMask = value => {
  const stripped = strip(value);
  const tamanho = stripped.length;
  if (tamanho <= 2) {
    return [/\d/, /\d/];
  } else if (tamanho > 2 && tamanho <= 5) {
    return [/\d/, /\d/, '.', /\d/, /\d/, /\d/];
  } else if (tamanho > 5 && tamanho <= 8) {
    return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/];
  } else if (tamanho > 8 && tamanho <= 12) {
    return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  } else {
    return [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/
    ];
  }
};

export const CEPMask = value => {
  if (strip(value).length > 5) {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }
  return [/\d/, /\d/, /\d/, /\d/, /\d/];
};

export const phoneMask = value => {
  value = value.trim();
  value = strip(value);

  if (value.length <= 2) {
    return [/[1-9]/, /\d/];
  }

  if (value.length >= 3 && value.length <= 6) {
    return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/];
  }

  if (value.length >= 7 && value.length < 11) {
    return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
};

export const dateMask = value => {
  const stripped = strip(value);
  const tamanho = stripped.length;
  if (tamanho > 2 && tamanho <= 4) {
    return [/\d/, /\d/, '/', /\d/, /\d/];
  } else if (tamanho >= 5) {
    return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  } else {
    return [/\d/, /\d/];
  }
};

// <00117686<0182038965>985000095639Ç
export const CMC7Mask = () => [
  '<',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '<',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '>',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  'Ç'
];

// 35180657424244000119550010000936611122667587
export const DANFEMask = () => [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const CodigoConfirmacaoMask = value => {
  if (value.length > 3) {
    return [/[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/, ' ', /[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/];
  }
  return [/[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/];
};
