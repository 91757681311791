import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { Field } from 'formik';
import CheckBoxMain from '../../shared/InputMain/CheckBoxMain';
import InputMain from '../../shared/InputMain/InputMain';

export default ({
  dadosFormaRecRecebivel,
  values,
  setFieldValue,
  campoValidacao,
  setCampoValidacao,
  isDisabled
}) => {
  return (
    <Box className="margin-top-60">
      <Box className="pad-bot-10">
        <Typography
          variant="h5"
          className={
            'texto-cor-principal texto-negrito ' +
            (campoValidacao === 'formaRecRecebivel' ? 'texto-danger-strong' : '')
          }
        >
          Meios de pagamento
        </Typography>
        <Typography
          variant="subtitle1"
          className={
            'texto-cor-cinza-escuro pad-topbot-10 ' +
            (campoValidacao === 'formaRecRecebivel' ? 'texto-danger-strong' : '')
          }
        >
          Selecione os meios de pagamento aceitos pela empresa e preencha as informações correspondentes
          a cada um
        </Typography>
      </Box>
      {dadosFormaRecRecebivel.map(formaRecebivel => (
        <Box key={`boxFormaRecRecebivel_${formaRecebivel.siglaTipo}`} className="pad-top-20">
          <Field
            type="checkbox"
            name={`chkbx_formaRecRecebivel[${formaRecebivel.siglaTipo}]`}
            value={formaRecebivel.codeTipo}
            checked={
              values.chkbx_formaRecRecebivel && values.chkbx_formaRecRecebivel[formaRecebivel.siglaTipo]
                ? true
                : false
            }
            component={CheckBoxMain}
            disabled={isDisabled}
            corCheckBox="checkbox-cor-complementar"
            controlLabelCheckbox={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <span className="font-size-1-rem texto-cor-cinza-escuro camel-case font-bold">
                {formaRecebivel.nomeTipo.toLowerCase()}
              </span>
            }
            onChangeChkbx={() => {
              if (campoValidacao.substring(0, 13) === 'formaRecReceb') setCampoValidacao('');
            }}
          />
          <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`formaRecRecebivel[${formaRecebivel.siglaTipo}][percentual]`}
                titulo="Percentual (%)"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName={
                  'input-disabled-cinza ' +
                  (campoValidacao === `formaRecRecebivel_${formaRecebivel.siglaTipo}_percentual`
                    ? 'input-error'
                    : '')
                }
                disabled={
                  isDisabled ||
                  !(
                    values.chkbx_formaRecRecebivel &&
                    values.chkbx_formaRecRecebivel[formaRecebivel.siglaTipo]
                  )
                }
                tipoMascara="number-percentual"
                setFieldValue={setFieldValue}
                onChangeInput={() => {
                  if (campoValidacao === `formaRecRecebivel_${formaRecebivel.siglaTipo}_percentual`)
                    setCampoValidacao('');
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`formaRecRecebivel[${formaRecebivel.siglaTipo}][ticketMedio]`}
                titulo="Ticket médio (R$)"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName={
                  'input-disabled-cinza ' +
                  (campoValidacao === `formaRecRecebivel_${formaRecebivel.siglaTipo}_ticketMedio`
                    ? 'input-error'
                    : '')
                }
                disabled={
                  isDisabled ||
                  !(
                    values.chkbx_formaRecRecebivel &&
                    values.chkbx_formaRecRecebivel[formaRecebivel.siglaTipo]
                  )
                }
                tipoMascara="number-money"
                onChangeInput={() => {
                  if (campoValidacao === `formaRecRecebivel_${formaRecebivel.siglaTipo}_ticketMedio`)
                    setCampoValidacao('');
                }}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`formaRecRecebivel[${formaRecebivel.siglaTipo}][prazoMedio]`}
                titulo="Prazo médio (dias)"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName={
                  'input-disabled-cinza ' +
                  (campoValidacao === `formaRecRecebivel_${formaRecebivel.siglaTipo}_prazoMedio`
                    ? 'input-error'
                    : '')
                }
                disabled={
                  isDisabled ||
                  !(
                    values.chkbx_formaRecRecebivel &&
                    values.chkbx_formaRecRecebivel[formaRecebivel.siglaTipo]
                  )
                }
                tipoMascara="number-inteiro"
                onChangeInput={() => {
                  if (campoValidacao === `formaRecRecebivel_${formaRecebivel.siglaTipo}_prazoMedio`)
                    setCampoValidacao('');
                }}
                inputProps={{ maxLength: 5 }}
              />
            </Grid>
          </Grid>
          {formaRecebivel.siglaTipo === 'CH' && (
            <Grid container spacing={3} className="pad-top-20">
              <Grid item xl={6} lg={6} md={6} xs={12} className="pad-top-0">
                <Field
                  type="text"
                  name={`formaRecRecebivel[${formaRecebivel.siglaTipo}][percChequeVendaTerc]`}
                  titulo="Percentual cheques de terceiros (%)"
                  size="small"
                  component={InputMain}
                  autoComplete="off"
                  inputClassName={
                    'input-disabled-cinza ' +
                    (campoValidacao ===
                    `formaRecRecebivel_${formaRecebivel.siglaTipo}_percChequeVendaTerc`
                      ? 'input-error'
                      : '')
                  }
                  disabled={
                    isDisabled ||
                    !(
                      values.chkbx_formaRecRecebivel &&
                      values.chkbx_formaRecRecebivel[formaRecebivel.siglaTipo]
                    )
                  }
                  tipoMascara="number-percentual"
                  setFieldValue={setFieldValue}
                  onChangeInput={() => {
                    if (
                      campoValidacao ===
                      `formaRecRecebivel_${formaRecebivel.siglaTipo}_percChequeVendaTerc`
                    )
                      setCampoValidacao('');
                  }}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={12} className="pad-top-0">
                <Field
                  type="text"
                  name={`formaRecRecebivel[${formaRecebivel.siglaTipo}][percChequeVendaDireta]`}
                  titulo="Percentual cheques diretos (%)"
                  size="small"
                  component={InputMain}
                  autoComplete="off"
                  inputClassName={
                    'input-disabled-cinza ' +
                    (campoValidacao ===
                    `formaRecRecebivel_${formaRecebivel.siglaTipo}_percChequeVendaDireta`
                      ? 'input-error'
                      : '')
                  }
                  disabled={
                    isDisabled ||
                    !(
                      values.chkbx_formaRecRecebivel &&
                      values.chkbx_formaRecRecebivel[formaRecebivel.siglaTipo]
                    )
                  }
                  tipoMascara="number-percentual"
                  setFieldValue={setFieldValue}
                  onChangeInput={() => {
                    if (
                      campoValidacao ===
                      `formaRecRecebivel_${formaRecebivel.siglaTipo}_percChequeVendaDireta`
                    )
                      setCampoValidacao('');
                  }}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={3} className="pad-top-20">
            <Grid item xl={12} lg={12} md={12} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`formaRecRecebivel[${formaRecebivel.siglaTipo}][observacao]`}
                titulo="Observação"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName="input-disabled-cinza"
                disabled={
                  isDisabled ||
                  !(
                    values.chkbx_formaRecRecebivel &&
                    values.chkbx_formaRecRecebivel[formaRecebivel.siglaTipo]
                  )
                }
                multiline
                rows={3}
                inputProps={{ maxLength: 250 }}
                placeholder="Escreva informações que considera importante sobre esse meio de pagamento "
              />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};
