import { Form, Formik, FieldArray } from 'formik';
import React, { memo, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AlteracaoNdocTituloForm from './AlteracaoNdocTituloForm';
import { updateNdocTitulo } from '../../../../../api/borderos';
import { scrollToId } from '../../../../../utils/format';

const ModalListaTitulosCorrecao = props => {
  const { modal, updateBordero, toggle, bordero, listaNdocAjuste, setListaNdocAjuste } = props;
  const [form, setForm] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleFormSubmit = values => {
    setSubmitting(true);
    return updateNdocTitulo(bordero.id, values.listaNdocAjuste)
      .then(resultTituloAlterado => {
        if (resultTituloAlterado.data.length > 0) {
          let listaNdocAjusteTemp = {};
          resultTituloAlterado.data.forEach(codigo => {
            listaNdocAjusteTemp[codigo] = values.listaNdocAjuste[codigo];
          });
          setListaNdocAjuste(listaNdocAjusteTemp);
          setErrorMessage('Ainda há Nº de Documento(s) fora do padrão');
          scrollToId('modalAjusteNdoc');
        } else {
          toggle({ show: false });
        }
        return updateBordero();
      })
      .finally(() => {
        setSubmitting(false);
        form.setSubmitting(false);
      });
  };

  return (
    <Modal
      id="modalAjusteNdoc"
      isOpen={modal.show}
      toggle={toggle}
      size="xl"
      className="modalAjusteNdoc"
    >
      <ModalHeader toggle={toggle}>Ajustar Número de Documento</ModalHeader>
      <ModalBody className="body-ajuste-ndoc">
        {errorMessage && (
          <div className="alert alert-danger animated fadeIn" role="alert">
            {errorMessage}
          </div>
        )}
        <Formik
          ref={node => setForm(node)}
          initialValues={{ listaNdocAjuste }}
          onSubmit={handleFormSubmit}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          render={({ values }) => (
            <Form>
              <h5>Favor ajustar o Nº de documento no padrão NF-Parcela (FIDC)</h5>
              <FieldArray
                render={() => {
                  const mergedProps = {
                    submitting,
                    values
                  };
                  return <AlteracaoNdocTituloForm {...mergedProps} />;
                }}
              />
              {!submitting && (
                <Button color="primary" size="lg" type="submit" className="pl-2 pr-2">
                  Alterar
                </Button>
              )}
              {submitting && (
                <Button
                  color="primary"
                  size="lg"
                  type="button"
                  className="pl-2 pr-2"
                  disabled={submitting}
                >
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  />
                  Alterando...
                </Button>
              )}
            </Form>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="md" type="button" outline onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalListaTitulosCorrecao.defaultProps = {
  modal: { show: false }
};

export default memo(ModalListaTitulosCorrecao);
