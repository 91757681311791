import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import stringDotNotationToObject from '../../../../utils/stringDotNotationToObject';

const Radio = ({
  field: { onChange: onChangeFormik, ...fields },
  // eslint-disable-next-line no-unused-vars
  form: { touched, errors, ...rest },
  ...props
}) => {
  const {
    labelClassName,
    label,
    id,
    onChange,
    className = 'form-check-input form-control',
    fieldError,
    disabled,
    ...restProps
  } = props;
  const error = fields.name.includes('.')
    ? stringDotNotationToObject(fields.name, errors)
    : (errors || {})[fields.name];

  const touchedField = fields.name.includes('.')
    ? stringDotNotationToObject(fields.name, touched)
    : (touched || {})[fields.name];

  return (
    <FormGroup check={true}>
      <Label check={true} htmlFor={id} className="radio">
        <Input
          type="radio"
          onChange={onChange || onChangeFormik}
          disabled={disabled}
          {...fields}
          {...restProps}
          id={id}
          invalid={!!(touchedField && error)}
          className={`${className}`}
        />
        {label}
        <span className="checkround" />
        {touchedField && error && !disabled ? (
          <FormFeedback>{fieldError ? error[fieldError] : error}</FormFeedback>
        ) : (
          ''
        )}
      </Label>
    </FormGroup>
  );
};
export default Radio;
