import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Add } from '@mui/icons-material';
import AlertErroMain from '../../../shared/AlertErroMain';
import ButtonMain from '../../../shared/ButtonMain';
import LoaderMain from '../../../shared/LoaderMain';
import DocumentoUpload from '../../../shared/DocumentoUpload';
import {
  postCadastrarGarantia,
  postConfirmarDocumentosGarantia,
  getDocumentosGarantia,
  getListaTiposDocumentos
} from '../../../../../api/onboarding';

export default ({
  avancarEtapa,
  cnpjEmpresa,
  isPending,
  setPending,
  codEntrada = null,
  origemProposta = 'digital'
}) => {
  const [documentosGarantia, setDocumentosGarantia] = useState([]);
  const [isPendingDocumento, setPendingDocumento] = useState(false);
  const [isGetPendingDocumento, setGetPendingDocumento] = useState(false);
  const [mostraErro, setMostraErro] = useState(false);
  const [listaTiposDocumentos, setListaTiposDocumentos] = useState([]);
  const [arquivoBoxSelected, setArquivoBoxSelected] = useState(
    documentosGarantia[0] ? documentosGarantia[0] : {}
  );
  const [newDocOpen, setNewDocOpen] = useState(false);
  const [listaTiposDocInseridos, setListaTiposDocInseridos] = useState([]);
  const [listaTiposArquivosAceitos, setTiposArquivosAceitos] = useState(['pdf']);

  useEffect(() => {
    if (cnpjEmpresa === undefined || cnpjEmpresa === '') return false;

    setGetPendingDocumento(true);
    getDocumentosGarantia(cnpjEmpresa)
      .then(res => {
        if (res.data && res.data.length !== 0) {
          var docGarTemp = res.data.map((doc, index) => {
            doc.id_original = doc.id;
            doc.cnpj = cnpjEmpresa;
            doc.nome_doc = doc.nome;
            doc.explicacao_doc = doc.descricao;
            doc.obrigacao_doc = doc.requerido;
            doc.id = doc.id + `_${index + 1}`;
            return doc;
          });
          setDocumentosGarantia(docGarTemp);
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPending(false);
        setGetPendingDocumento(false);
      });

    if (origemProposta === 'one7_mais_credito') {
      setTiposArquivosAceitos(['pdf', 'png', 'jpg']);
    }
  }, [cnpjEmpresa]);

  const buscaTiposDocumentos = () => {
    getListaTiposDocumentos()
      .then(retornoTipos => {
        let ret = retornoTipos.data;
        if (ret.dados && ret.dados.length > 0) {
          let tiposDoc = [];
          ret.dados.map(doc => {
            // SO IRA PERMITIR NOVOS DOCUMENTOS DO TIPO OBRIGATORIOS
            if (documentosGarantia.find(item => parseInt(item.id) === parseInt(doc.CODPPCDOCUMENTO))) {
              tiposDoc.push(doc);
            }
            return doc;
          });

          setListaTiposDocumentos(tiposDoc);
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      });
  };

  const confirmarGarantia = () => {
    if (documentosGarantia === undefined || documentosGarantia.length === 0) return false;
    var documentosGarantiaTemp = [...documentosGarantia];
    var bloquearSubmit = false;
    let mostraFocus = false;
    documentosGarantiaTemp = documentosGarantiaTemp.map(gar => {
      if (gar.id === 'new_doc') {
        mostraFocus = true;
      }
      if (gar.hash_arquivo === undefined || gar.hash_arquivo === '') {
        bloquearSubmit = true;
        if (gar.nome_arquivo === undefined || gar.nome_arquivo === '') {
          gar.status = 'reprovado';
          gar.titulo_status = 'Pendente';
          gar.subtitulo_status = 'Favor importar um arquivo para este documento';
        }
      }
      return gar;
    });

    if (mostraFocus) {
      document.getElementById('novo_tipoDocumento').focus();
      return false;
    }

    if (bloquearSubmit) {
      setDocumentosGarantia(documentosGarantiaTemp);
      return false;
    }

    if (origemProposta === 'one7_mais_credito') {
      setGetPendingDocumento(true);
    } else {
      setPending(true);
    }

    let payload = {
      cod_entrada: codEntrada
    };

    postCadastrarGarantia(payload)
      .then(res => {
        if (res.data && res.data.Dados && res.data.Dados.CODPPCGARANTIA && res.data.Dados.CODPPC) {
          var dadosDocumentosGarantia = formatarPayLoadDocumentosGarantia(
            res.data.Dados.CODPPC,
            res.data.Dados.CODPPCGARANTIA,
            documentosGarantia
          );

          postConfirmarDocumentosGarantia(dadosDocumentosGarantia)
            .then(() => {
              avancarEtapa();
            })
            .catch(err => {
              setMostraErro(err.response);
            })
            .finally(() => {
              setPending(false);
              setGetPendingDocumento(false);
            });
        } else {
          setPending(false);
          setGetPendingDocumento(false);
        }
      })
      .catch(err => {
        setPending(false);
        setGetPendingDocumento(false);
        setMostraErro(err.response);
      });
  };

  const formatarPayLoadDocumentosGarantia = (cod_ppc, garantia_id, documentosGarantia) => {
    let tempDocs = [];
    documentosGarantia.map(doc => {
      if (
        typeof doc.hash_arquivo !== 'undefined' &&
        doc.hash_arquivo !== undefined &&
        doc.hash_arquivo !== null
      ) {
        let tempDoc = { ...doc };
        tempDoc.documento_id = doc.id_original;
        tempDoc.hash = doc.hash_arquivo;
        tempDocs.push(tempDoc);
      }
      return doc;
    });
    return {
      cnpj: cnpjEmpresa,
      cod_ppc: cod_ppc,
      origem_proposta: origemProposta,
      garantias: [
        {
          garantia_id: garantia_id,
          documentos: tempDocs
        }
      ]
    };
  };

  const clickDocumento = (indexArquivo, arquivo) => {
    let arqSelected = { ...arquivo };
    if (arqSelected.novo_doc_lista === undefined || arqSelected.novo_doc_lista.length === 0) {
      arqSelected.indexArquivo = indexArquivo;
      setArquivoBoxSelected(arqSelected);
    }
  };

  const novoDocumento = () => {
    if (newDocOpen || documentosGarantia === undefined) return false;
    let listaDocsTemp = [...documentosGarantia];
    let lstTiposDoc = listaTiposDocumentos.filter(
      tipoDoc => listaTiposDocInseridos.includes(tipoDoc.CODPPCDOCUMENTO) === false
    );
    listaDocsTemp.unshift({ id: 'new_doc', novo_doc_lista: lstTiposDoc });
    setDocumentosGarantia(listaDocsTemp);
  };

  useEffect(() => {
    if (documentosGarantia.length > 0 && listaTiposDocumentos.length === 0) {
      buscaTiposDocumentos();
    }

    let newDoc = false;
    let lstTiposDocInsTemp = [...listaTiposDocInseridos];
    documentosGarantia.map(doc => {
      if (doc.id === 'new_doc') newDoc = true;
      if (doc.status !== 'reprovado' && doc.id !== 'new_doc') lstTiposDocInsTemp.push(doc.cod_documento);
      return doc;
    });
    setNewDocOpen(newDoc);
    setListaTiposDocInseridos(lstTiposDocInsTemp);
  }, [documentosGarantia]);

  const salvarNovoDocumento = (indexArquivo, tipoNovoDoc) => {
    if (tipoNovoDoc && tipoNovoDoc.CODPPCDOCUMENTO && documentosGarantia[indexArquivo].hash_arquivo) {
      setDocumentosGarantia([]);

      let newListaDocs = documentosGarantia.map(doc => {
        if (doc.id === 'new_doc') {
          doc.cnpj = doc.cpfcnpj;
          doc.descricao = tipoNovoDoc.DESCRICAO;
          doc.explicacao_doc = tipoNovoDoc.DESCRICAO;
          doc.nome = tipoNovoDoc.NOME;
          doc.nome_doc = tipoNovoDoc.NOME;
          doc.obrigacao_doc = false;
          doc.requerido = false;
          doc.id = tipoNovoDoc.CODPPCDOCUMENTO + `_${documentosGarantia.length}`;
          doc.id_original = tipoNovoDoc.CODPPCDOCUMENTO;
          delete doc['cpfcnpj'];
          delete doc['novo_doc_lista'];
        }
        return doc;
      });

      setDocumentosGarantia(newListaDocs);
    }
  };

  return (
    <Grid container>
      <Grid item xl={2} lg={2} md={2} sm={1} />
      <Grid item xl={8} lg={8} md={8} sm={10} xs={12}>
        <Box>
          <Typography variant="h4" className="texto-cor-principal texto-negrito texto-centro">
            Documentos do imóvel
          </Typography>
        </Box>
        <Box>
          <Box sx={{ p: '25px 0px 10px 0px' }}>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
              São aceitos documentos emitidos com no máximo 30 dias de emissão. O arquivo deve ser
              enviado no formato PDF, JPG ou PNG com até 30MB.
            </Typography>
          </Box>

          <Box className="borda-1 borda-cor-suporte pad-15 border-radius-8 margin-bottom-15">
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5 texto-negrito">
              Para enviar os arquivos corretamente siga as instruções:
            </Typography>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
              <WbSunnyOutlinedIcon className="font-size-1-rem" />
              <span className="pad-5">Escolha um ambiente com boa iluminação e sem sombras</span>
            </Typography>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
              <InfoOutlinedIcon className="font-size-1-rem" />
              <span className="pad-5">Retire a proteção plástica do documento para evitar reflexos</span>
            </Typography>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
              <LockOutlinedIcon className="font-size-1-rem" />
              <span className="pad-5">
                Não se preocupe! Coletamos e tratamos seus dados para fins de identificação, autenticação
                e prevenção de fraudes. Esse processo é 100% confidencial
              </span>
            </Typography>
          </Box>

          <Box sx={isGetPendingDocumento ? { position: 'relative', minHeight: '255px' } : {}}>
            {isGetPendingDocumento && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

            <ButtonMain
              tipoBotao="principal-texto-branco"
              disabled={newDocOpen || isPendingDocumento || listaTiposDocumentos.length === 0}
              onClick={() => novoDocumento()}
            >
              <Add />
              Novo Documento
            </ButtonMain>

            {documentosGarantia.length !== 0 &&
              documentosGarantia.map((doc, index) => {
                var classesAdicionais =
                  index + 1 === documentosGarantia.length
                    ? 'borda-transparente'
                    : 'borda-cor-cinza-claro';
                classesAdicionais +=
                  typeof arquivoBoxSelected.indexArquivo !== 'undefined' &&
                  arquivoBoxSelected.indexArquivo === index &&
                  arquivoBoxSelected.id !== 'new_doc'
                    ? ' box-selected '
                    : '';

                return (
                  <DocumentoUpload
                    key={doc.id}
                    id={doc.id}
                    boxClassesAdicionais={classesAdicionais}
                    isPending={isPendingDocumento}
                    setPending={setPendingDocumento}
                    listaDocs={documentosGarantia}
                    setListaDocs={setDocumentosGarantia}
                    arquivosAceitos={listaTiposArquivosAceitos}
                    tamanhoMaximoMegaAceito={30}
                    boxUploadAction={clickDocumento}
                    btnEnviarDocumento={false}
                    cnpjEmpresa={cnpjEmpresa}
                    funcaoSalvarNovoDocumento={salvarNovoDocumento}
                  />
                );
              })}

            {documentosGarantia.length === 0 && (
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
                Nenhum documento encontrado
              </Typography>
            )}
          </Box>

          <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

          <Grid container spacing={3} className="pad-topbot-20">
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
            />
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
              <ButtonMain
                type="button"
                tipoBotao="principal"
                disabled={isPending || isPendingDocumento || isGetPendingDocumento}
                onClick={() => confirmarGarantia()}
              >
                Confirmar
              </ButtonMain>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
