import React from 'react';
import { Grid, Typography } from '@mui/material';
import ButtonMain from './ButtonMain';
import BoxInputMain from './InputMain/BoxInputMain';

const ToggleButtonMain = ({
  funcaoHandleChange,
  atributoHandle,
  groupClassName = '',
  atributosBotao = {},
  botoes = [],
  colsBotoes,
  grupoSpacing = 1,
  btnSize = 'large',
  titulo,
  erro,
  helper,
  nomeInput = ''
}) => {
  if (typeof funcaoHandleChange !== 'function') {
    return false;
  }
  if (typeof atributoHandle === 'undefined') {
    return false;
  }

  var colsPadrao = { xl: 2, lg: 2, md: 2, sm: 3, xs: 6 };
  colsPadrao = colsBotoes ? colsBotoes : colsPadrao;

  var componenteAdicional = '';
  if (nomeInput !== '') {
    componenteAdicional = <input type="hidden" name={nomeInput} value={atributoHandle} />;
  }

  return (
    <BoxInputMain titulo={titulo}>
      <Grid container spacing={grupoSpacing} className={`${groupClassName} grupo-botoes-principal`}>
        {botoes.map((prop, index) => (
          <Grid
            // eslint-disable-next-line react/no-array-index-key
            key={`key_${index}`}
            item
            xl={prop.xl ? prop.xl : prop.col ? prop.col : colsPadrao.xl}
            lg={prop.lg ? prop.lg : prop.col ? prop.col : colsPadrao.lg}
            md={prop.md ? prop.md : prop.col ? prop.col : colsPadrao.md}
            sm={prop.sm ? prop.sm : prop.col ? prop.col : colsPadrao.sm}
            xs={prop.xs ? prop.xs : prop.col ? prop.col : colsPadrao.xs}
          >
            <ButtonMain
              classesAdicional={`${atributoHandle === prop.valor ? 'Mui-selected' : ''} ${
                erro && erro !== '' ? 'borda-error' : ''
              }`}
              onClick={() => funcaoHandleChange(prop.valor)}
              size={btnSize}
              {...atributosBotao}
            >
              {prop.label}
            </ButtonMain>
          </Grid>
        ))}
        {componenteAdicional}
      </Grid>
      {erro && erro !== '' && <Typography className="texto-danger label-padding">{erro}</Typography>}
      {helper && (
        <Typography variant="subtitle2" className="texto-cor-cinza-escuro">
          {helper}
        </Typography>
      )}
    </BoxInputMain>
  );
};
export default ToggleButtonMain;
