import React from 'react';
import { Card, CardContent, Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '../../../assets/img/home.svg';
import AccountCircleIcon from '../../../assets/img/user.svg';

export default function JornadaCreditoDescricao() {
  return (
    <Card className="descricao-jornada-credito">
      <CardContent className="pad-0">
        <Box>
          <Link to="/home" className="icon-voltar-jornada-credito">
            <ArrowBackIcon />
            &nbsp; Voltar
          </Link>
          <Typography
            variant="h4"
            className="mt-4 texto-cor-principal texto-negrito texto-esquerda font-size-32"
          >
            Como funciona?
          </Typography>
          <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro texto-esquerda">
            Facilite a compra de matéria-prima, pagamento de colaboradores, ajustes de caixa ou realize
            investimento em infraestrutura e estoque.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            className="mt-4 texto-cor-principal texto-negrito texto-esquerda font-size-18"
          >
            Tipos de garantia
          </Typography>
          <Box className="itens-descricao">
            <Box>
              <img src={HomeOutlinedIcon} alt="usuario" className="icone-card-descricao" />
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro texto-esquerda font-size-16 texto-negrito"
              >
                Giro parcelado com garantia de imóvel
              </Typography>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro texto-esquerda font-size-16"
              >
                Contrate até 2 milhões usando um imóvel como garantia. Crédito seguro e com menores taxas
              </Typography>
            </Box>
          </Box>
          <Box className="itens-descricao">
            <Box>
              <img src={AccountCircleIcon} alt="usuario" className="icone-card-descricao" />
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro texto-esquerda font-size-16 texto-negrito"
              >
                Giro parcelado com garantia de aval
              </Typography>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro texto-esquerda font-size-16"
              >
                Nesta modalidade a pessoa avalista entra como garantidora de pagamento do crédito. O
                valor disponível para contratação é de até R$ 200 mil.
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="subtitle1"
            className="mt-5 texto-cor-cinza-escuro texto-esquerda font-size-16"
          >
            Solicite uma análise de crédito preenchendo o formulário a seguir
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
