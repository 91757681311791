import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Route, Switch } from 'react-router';
import Main from '../Main/Main';
import Borderos from './Borderos/Borderos';
import Titulos from './Titulos/Titulos';
import ConcentracaoSacados from './ConcentracaoSacados/ConcentracaoSacados';
import Debitos from './Debitos/Debitos';
import { getClienteAtual } from '../../shared/services/User';
import { getTiposEmpresasRelatorio, getTiposOperacoes } from '../../api/borderos';

export default props => {
  const { location } = props;

  const [isPending, setIsPending] = useState(false);
  const [tipoEmpresas, setTipoEmpresas] = useState([]);
  const [clienteAtual, setClienteAtual] = useState();
  const [tipoOperacoes, setTipoOperacoes] = useState([]);
  // Douglas - 24/01/20 - Ao carregar a pagina, caso o tamanho da janela seja menor ou igual a 600px ja carrega o menu collapsado
  const [collapseMenuRight, setCollapseMenuRight] = useState(window.innerWidth <= 600 ? true : false);

  useEffect(() => {
    if (!clienteAtual) {
      const newCliente = getClienteAtual();
      setClienteAtual(newCliente);
    }
  }, []);

  useEffect(() => {
    setIsPending(true);
    getTiposEmpresasRelatorio()
      .then(res => {
        setTipoEmpresas(
          res.data.map(empresa => {
            return {
              value: empresa.code,
              label: empresa.descricao
            };
          })
        );
      })
      .finally(() => {
        setIsPending(false);
      });

    getTiposOperacoes()
      .then(res => {
        setTipoOperacoes(
          res.data.map(operacao => {
            return {
              value: operacao.code,
              label: operacao.descricao
            };
          })
        );
      })
      .finally(() => {
        setIsPending(false);
      });
  }, []);

  return (
    <Main title="Relatório" className={'relatorio ' + (collapseMenuRight ? 'collapsed' : '')} {...props}>
      <Card>
        <CardBody>
          <Switch>
            <Route
              path="/relatorios/borderos"
              render={() => (
                <Borderos
                  clienteAtual={clienteAtual}
                  isPending={isPending}
                  filtroTipoEmpresas={tipoEmpresas}
                  filtroTipoOperacoes={tipoOperacoes}
                  search={location.search || null}
                  state={location.state || false}
                  collapseMenuRight={collapseMenuRight}
                  setCollapseMenuRight={setCollapseMenuRight}
                  {...props}
                />
              )}
            />
            <Route
              path="/relatorios/titulos"
              render={() => (
                <Titulos
                  clienteAtual={clienteAtual}
                  isPending={isPending}
                  filtroTipoEmpresas={tipoEmpresas}
                  filtroTipoOperacoes={tipoOperacoes}
                  search={location.search || null}
                  state={location.state || false}
                  collapseMenuRight={collapseMenuRight}
                  setCollapseMenuRight={setCollapseMenuRight}
                  {...props}
                />
              )}
            />
            <Route
              path="/relatorios/concentracao-sacados"
              render={() => (
                <ConcentracaoSacados
                  clienteAtual={clienteAtual}
                  isPending={isPending}
                  filtroTipoEmpresas={tipoEmpresas}
                  filtroTipoOperacoes={tipoOperacoes}
                  search={location.search || null}
                  state={location.state || false}
                  collapseMenuRight={collapseMenuRight}
                  setCollapseMenuRight={setCollapseMenuRight}
                  {...props}
                />
              )}
            />
            <Route
              path="/relatorios/debitos"
              render={() => (
                <Debitos
                  clienteAtual={clienteAtual}
                  isPending={isPending}
                  filtroTipoEmpresas={tipoEmpresas}
                  state={location.state || false}
                  collapseMenuRight={collapseMenuRight}
                  setCollapseMenuRight={setCollapseMenuRight}
                  {...props}
                />
              )}
            />
          </Switch>
        </CardBody>
      </Card>
    </Main>
  );
};
