import React from 'react';
import moment from 'moment';
import { dateFormat, moneyFullFormat, deletePropriedades, unmaskValue } from '../../utils/format';

const ordenaColunaData = (a, b, order) => {
  let diff = 0;
  let formato = 'DD/MM/YYYY';

  if (a.indexOf('-') !== -1 && b.indexOf('-') !== -1) {
    formato = 'YYYY-MM-DD';
  }

  var dataA_Formatada = moment(a, formato);
  var dataB_Formatada = moment(b, formato);

  if (order === 'asc') {
    diff = dataB_Formatada.diff(dataA_Formatada);
  } else {
    diff = dataA_Formatada.diff(dataB_Formatada);
  }

  return diff;
};

const ordenaColunaTexto = (a, b, order) => {
  if (order === 'asc') {
    return a.localeCompare(b);
  } else {
    return b.localeCompare(a);
  }
};

const ordenaColunaInteiro = (a, b, order) => {
  a = a !== null && a !== '' ? parseInt(a) : 0;
  b = b !== null && b !== '' ? parseInt(b) : 0;
  if (order === 'asc') {
    return a - b;
  } else {
    return b - a;
  }
};

const ordenaColunaFloat = (a, b, order) => {
  a = a !== null && a !== '' ? parseFloat(a) : 0;
  b = b !== null && b !== '' ? parseFloat(b) : 0;
  if (order === 'asc') {
    return a - b;
  } else {
    return b - a;
  }
};

const ordenaColunaDinheiroFormatado = (a, b, order) => {
  a = a !== null && a !== '' ? parseFloat(unmaskValue(a)) : 0;
  b = b !== null && b !== '' ? parseFloat(unmaskValue(b)) : 0;
  if (order === 'asc') {
    return a - b;
  } else {
    return b - a;
  }
};

export default lstColumns => {
  const colunasRetorno = [];
  if (lstColumns) {
    lstColumns.forEach(item => {
      // Anderson / bruno santana - 28/10/2019 - Cria uma variavel temporáriao para quardar as colunas criadas.
      // Essa variavel existe porque os controle de formatação e ordenação vão ser inseridas somente se necessário.
      // assim quando não for necessário por não passar nada no objeto referente, o BootStrapGrid vai fazer o controle padrão.
      // Pega-se tudo, retirado no fim os que não irão, podendo passar assim diretamente algum parametro do proprio bootstrap - Douglas 31/10/2019
      var temp = { ...item };
      deletePropriedades(temp, [
        'formatacao',
        'tipoOrdenacao',
        'evento',
        'headerStyle',
        'tdAlign',
        'thAlign',
        'tdTitle',
        'thWhiteSpace'
      ]);

      // Anderson / bruno santana - 28/10/2019 - Por padrão todas as colunas podem ser ordenadas,
      // temp ja possui sort caso exista, caso não exista atribue true como padrão - douglas 31/10/2019
      if (temp.sort === null || typeof temp.sort === 'undefined') {
        temp.sort = true;
      }

      // Anderson / bruno santana - 28/10/2019 - Ifs para o controle de formatação dos campos
      if (item.formatacao === 'valor') {
        let formatar = {
          //formatter: (cellContent, row) => {
          formatter: cellContent => {
            if (cellContent !== null && cellContent.toString().indexOf('R$') === -1) {
              cellContent = moneyFullFormat(cellContent);
            }
            return <>{cellContent}</>;
          }
        };
        Object.assign(temp, formatar);
      } else if (item.formatacao === 'textoOuIfem') {
        let formatar = {
          //formatter: (cellContent, row) => {
          formatter: cellContent => {
            return <>{cellContent ? cellContent : '--'}</>;
          }
        };
        Object.assign(temp, formatar);
      } else if (item.formatacao === 'valorOuIfem') {
        let formatar = {
          //formatter: (cellContent, row) => {
          formatter: cellContent => {
            if (cellContent !== null && cellContent.toString().indexOf('R$') === -1) {
              cellContent = moneyFullFormat(cellContent);
            }
            return <>{cellContent ? cellContent : '--'}</>;
          }
        };
        Object.assign(temp, formatar);
      } else if (item.formatacao === 'dataOuIfem') {
        let formatar = {
          //formatter: (cellContent, row) => {
          formatter: cellContent => {
            if (cellContent !== null && cellContent.indexOf('-') !== -1) {
              cellContent = dateFormat(cellContent);
            }
            return <>{cellContent ? cellContent : '--'}</>;
          }
        };
        Object.assign(temp, formatar);
      } else if (item.formatacao === 'data') {
        let formatar = {
          //formatter: (cellContent, row) => {
          formatter: cellContent => {
            if (cellContent !== null && cellContent.indexOf('-') !== -1) {
              cellContent = dateFormat(cellContent);
            }
            return <>{cellContent}</>;
          }
        };
        Object.assign(temp, formatar);
      } else if (
        typeof item.evento === 'function' &&
        (item.formatacao === 'button' || item.formatacao === 'checkbox')
      ) {
        let formatar = {
          formatter: (cellContent, row) => {
            return item.evento(row);
          }
        };
        Object.assign(temp, formatar);
      } else if (typeof item.formatacao === 'function') {
        let formatar = {
          formatter: (cellContent, row) => {
            return item.formatacao(cellContent, row);
          }
        };
        Object.assign(temp, formatar);
      }

      // ORDENAÇÃO --------------------------------------------------
      if (item.tipoOrdenacao === 'data') {
        let ordenar = {
          sortFunc: (a, b, order) => {
            return ordenaColunaData(a, b, order);
          }
        };
        Object.assign(temp, ordenar);
      } else if (item.tipoOrdenacao === 'texto') {
        let ordenar = {
          sortFunc: (a, b, order) => {
            return ordenaColunaTexto(a, b, order);
          }
        };
        Object.assign(temp, ordenar);
      } else if (item.tipoOrdenacao === 'inteiro') {
        let ordenar = {
          sortFunc: (a, b, order) => {
            return ordenaColunaInteiro(a, b, order);
          }
        };
        Object.assign(temp, ordenar);
      } else if (item.tipoOrdenacao === 'float') {
        let ordenar = {
          sortFunc: (a, b, order) => {
            return ordenaColunaFloat(a, b, order);
          }
        };
        Object.assign(temp, ordenar);
      } else if (item.tipoOrdenacao === 'dinheiroFormatado') {
        let ordenar = {
          sortFunc: (a, b, order) => {
            return ordenaColunaDinheiroFormatado(a, b, order);
          }
        };
        Object.assign(temp, ordenar);
      } else if (typeof item.tipoOrdenacao === 'function') {
        let ordenar = {
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            return item.tipoOrdenacao(a, b, order, dataField, rowA, rowB);
          }
        };
        Object.assign(temp, ordenar);
      }

      // ESTILIZAÇÃO PADRAO DE TODAS AS CELULAS DO HEADER (TH) - Douglas 01/11/2019
      var headerStyle = { width: '150px', textAlign: 'center', whiteSpace: 'nowrap' };

      // ESTILOS PADROES DE ACORDO COM A FORMATAÇÃO - Douglas 01/11/2019
      if (item.formatacao === 'checkbox') {
        headerStyle.width = '50px';
      }

      // ESTILIZAÇÃO DA CELULA DO HEADER (TH) - Douglas 01/11/2019
      if (typeof item.headerStyle !== 'undefined') {
        headerStyle = { ...item.headerStyle };
      } else {
        if (typeof item.width !== 'undefined') {
          headerStyle.width = item.width;
        }
        if (typeof item.thAlign !== 'undefined') {
          headerStyle.width = item.thAlign;
        }
        if (typeof item.thWhiteSpace !== 'undefined') {
          headerStyle.whiteSpace = item.thWhiteSpace;
        }
      }
      if (Object.keys(headerStyle).length > 0) {
        let headStyle = {
          //headerStyle: (colum, colIndex) => {
          headerStyle: () => {
            return headerStyle;
          }
        };
        Object.assign(temp, headStyle);
      }

      // ESTILIZAÇÃO PADRAO DOS VALORES DA COLUNA (TD)
      if (typeof temp.style === 'undefined') {
        let stylePadrao = {
          style: { textAlign: 'center' }
        };
        if (item.formatacao === 'valor') {
          stylePadrao.style.textAlign = 'right';
        } else if (item.formatacao === 'texto') {
          stylePadrao.style.textAlign = 'left';
        }
        if (typeof item.tdAlign !== 'undefined') {
          stylePadrao.style.textAlign = item.tdAlign;
        }
        Object.assign(temp, stylePadrao);
      }

      // TITLE (hover) da TD
      if (typeof item.tdTitle === 'function') {
        let titulo = {
          title: function callback(cell, row, rowIndex, colIndex) {
            return item.tdTitle(cell, row, rowIndex, colIndex);
          }
        };
        Object.assign(temp, titulo);
      }

      colunasRetorno.push(temp);
    });
  }

  return colunasRetorno;
};
