import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState, memo } from 'react';
import swal from '@sweetalert/with-react';

import { deleteBorderoTitulo, deleteTituloBorderoByCodeIN } from '../../../../api/borderos';

const ModalApagarTitulo = ({ modal, toggle, updateParentBordero }) => {
  const [message, setMessage] = useState();
  const [isPending, setIsPending] = useState(false);

  const carregaTextoConfirmacao = () => {
    if (typeof modal.titulo !== 'undefined' && modal.titulo) {
      //bruno santana - 31/10/2019 - Deletar somente um título
      return 'Você tem certeza que deseja excluir este título?';
    } else if (typeof modal.tituloLote !== 'undefined' && modal.tituloLote) {
      //bruno santana - 31/10/2019 - Deletar vários títulos de uma só vez
      return 'Você tem certeza que deseja excluir os títulos selecionados?';
    }
  };

  const handleConfirmExcluir = event => {
    setIsPending(true);
    setMessage(null);

    if (typeof modal.titulo !== 'undefined' && modal.titulo) {
      //bruno santana - 31/10/2019 - Deletar somente um título
      deleteBorderoTitulo(modal.bordero.id, modal.titulo)
        .then(() => {
          // exclui os titulos direto da variavel de titulos - douglas 06/11/2019
          var newBordero = { ...modal.bordero };
          newBordero.titulosTotalValorBruto = 0;
          newBordero.titulos.forEach((tit, index) => {
            if (tit.id === modal.titulo.id) {
              newBordero.titulos.splice(index, 1);
            }
          });

          if (newBordero.tipoTitulo.code === 'CH') {
            newBordero.titulosTotalValorBruto = newBordero.titulos.reduce(
              (total, valor) => Number(total) + Number(valor.cheque.valor),
              0
            );
          } else {
            newBordero.titulosTotalValorBruto = newBordero.titulos.reduce(
              (total, valor) => Number(total) + Number(valor.duplicata.valor),
              0
            );
          }

          newBordero.titulosQuantidade = newBordero.titulos.length;
          updateParentBordero(newBordero);
          toggle(event);
          swal({
            title: 'Exclusão de Titulo',
            text: 'Titulo Excluido com sucesso!',
            icon: 'success'
          });
        })
        .catch(err => {
          const {
            response: {
              data: { error: { message = 'Erro inesperado ao excluir o titulo' } = {} } = {}
            } = {}
          } = err;
          setMessage(message);
        });
    } else if (typeof modal.tituloLote !== 'undefined' && modal.tituloLote) {
      //bruno santana - 31/10/2019 - Deletar vários títulos de uma só vez
      deleteTituloBorderoByCodeIN(modal.bordero.id, modal.tituloLote)
        .then(() => {
          // exclui os titulos direto da variavel de titulos - douglas 06/11/2019
          var newBordero = { ...modal.bordero };
          newBordero.titulosTotalValorBruto = 0;
          for (var index = 0; index < newBordero.titulos.length; index++) {
            var tit = newBordero.titulos[index];
            if (modal.tituloLote.cod_titulo_in.includes(tit.id)) {
              newBordero.titulos.splice(index, 1);
              index--;
            } else {
              var valorTitulo = tit.duplicata ? tit.duplicata.valor : tit.cheque.valor;
              newBordero.titulosTotalValorBruto += valorTitulo;
            }
          }
          newBordero.titulosQuantidade = newBordero.titulos.length;
          updateParentBordero(newBordero);
          toggle(event);
          swal({
            title: 'Exclusão de Titulos',
            text: 'Titulos Excluidos com sucesso!',
            icon: 'success'
          });
        })
        .catch(err => {
          const {
            response: {
              data: { error: { message = 'Erro inesperado ao excluir os titulos' } = {} } = {}
            } = {}
          } = err;
          setMessage(message);
        });
    }
  };

  return (
    <Modal
      isOpen={modal.show}
      toggle={toggle}
      centered
      size="sm"
      className={`delete-modal ${isPending ? 'is-submitting' : ''}`}
    >
      <ModalHeader toggle={toggle}>Excluir título</ModalHeader>
      <ModalBody>
        {message && (
          <div className="alert alert-danger animated fadeIn" role="alert">
            {message}
          </div>
        )}
        {carregaTextoConfirmacao()}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          size="md"
          className="mr-auto"
          outline
          disabled={isPending}
          onClick={toggle}
        >
          Cancelar
        </Button>
        <Button color="primary" size="md" disabled={isPending} onClick={handleConfirmExcluir}>
          Excluir
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalApagarTitulo.defaultProps = {
  modal: { show: false }
};

export default memo(ModalApagarTitulo);
