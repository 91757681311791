import React, { useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';

import VoltarIcon from '../../../../shared/icons/Voltar';
import { moneyFullFormat, transformTableTitulosData } from '../../../../utils/format';
import expandRow from '../../common/expandRow';
import { finalizarBordero } from '../../../../api/borderos';
import SkeletonLoader from '../../../../shared/components/common/SkeletonLoader';
import Grid from '../../../commom/Grid';
import finalizationStepGrid from './finalizationStepGrid';
import HistoricoAcesso from '../../../commom/HistoricoAcesso';

const FinalizationStep = props => {
  const {
    previousStep,
    bordero,
    history,
    clienteAtual,
    isPending: isParentPending,
    collapseMenuRight,
    setCollapseMenuRight,
    codEntradaTitSeparacao
  } = props;
  const [isPending, setIsPending] = useState(false);
  const [message, setMessage] = useState();
  const isContratoFisico =
    bordero &&
    bordero.contratoFisico &&
    bordero.contratoFisico.code &&
    bordero.contratoFisico.code === 'S';

  const handleConcluir = () => {
    setIsPending(true);
    finalizarBordero(bordero, codEntradaTitSeparacao)
      .then(() => {
        history.push('/borderos');
      })
      .catch(err => {
        const {
          response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
        } = err;
        setMessage(message);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <>
      <div className="selection-step">
        <div className="row row-content">
          <div
            className={
              (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
              ' col-12 content-body col-selection-step'
            }
          >
            <div className="body">
              <div className="d-flex align-items-center">
                <h1 className="title">Resumo da operação</h1>
              </div>
              <div className={'grid-scrollable-content ' + (message ? 'haveMessage' : '')}>
                <SkeletonLoader isPending={isParentPending} width="60%" height="30px" count={8}>
                  {message && (
                    <div className="alert alert-danger animated fadeIn" role="alert">
                      {message}
                    </div>
                  )}

                  <Grid
                    keyField="id"
                    data={transformTableTitulosData(bordero.titulos || [])}
                    configuracaoColunas={finalizationStepGrid()}
                    mensagem={message}
                    classes="table-layout-fixed"
                    classeIdentificadora="finalizationStep"
                    tableSearch={true}
                    expandRow={expandRow}
                  />
                </SkeletonLoader>
              </div>
            </div>
            <div className="footer d-flex justify-content-between">
              <Button
                className="mr-auto btn-back"
                size="lg"
                outline
                color="primary"
                type="button"
                disabled={isPending || isParentPending}
                onClick={previousStep}
              >
                <i className="svg-icon">
                  <VoltarIcon />
                </i>
                Voltar
              </Button>

              <Button
                size="lg"
                className="ml-3"
                color="primary"
                type="button"
                onClick={handleConcluir}
                disabled={isPending || isParentPending}
              >
                {!isPending && (
                  <>
                    <i className="far fa-check-circle mr-2" />
                    Concluir
                  </>
                )}
                {isPending && (
                  <>
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    />
                    Concluindo
                  </>
                )}
              </Button>
            </div>
          </div>
          {!collapseMenuRight && (
            <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
              <div className="fixed-side">
                {bordero && bordero.id && (
                  <>
                    <div
                      role="button"
                      className="header cursor-pointer"
                      onClick={() => setCollapseMenuRight(true)}
                      onKeyPress={() => setCollapseMenuRight(true)}
                      tabIndex={0}
                    >
                      <h4 className="title">
                        Detalhes Borderô
                        <i className="fa fa-angle-right float-right" />
                      </h4>
                    </div>
                    <div className="info infoBordero">
                      <FormGroup>
                        <Label>Cliente:</Label>
                        <p className="form-control-static">{clienteAtual.razaoSocial}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Conta Corrente:</Label>
                        <p className="form-control-static">{bordero.contaCorrente.descricao}</p>
                      </FormGroup>
                      {bordero.contaEscrow && bordero.contaEscrow.descricao ? (
                        <FormGroup>
                          <Label>Conta para cobrança Escrow:</Label>
                          <p className="form-control-static">{bordero.contaEscrow.descricao}</p>
                        </FormGroup>
                      ) : (
                        ''
                      )}
                      <FormGroup>
                        <Label>Tipo de Empresa:</Label>
                        <p className="form-control-static">{bordero.tipoEmpresa.descricao}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Tipo de Operação:</Label>
                        <p className="form-control-static">{bordero.tipoOperacao.descricao}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Tipo de Documento:</Label>
                        <p className="form-control-static">
                          {bordero.tipoTitulo.descricao}
                          {isContratoFisico ? ' (Com Contrato Físico)' : ''}
                        </p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Quantidade de Títulos:</Label>
                        <p className="form-control-static">{bordero.titulosQuantidade}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Total bruto:</Label>
                        <p className="form-control-static total-price">
                          {moneyFullFormat(bordero.titulosTotalValorBruto)}
                        </p>
                      </FormGroup>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <HistoricoAcesso codTela={1196} />
    </>
  );
};

export default FinalizationStep;
