import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import React, { memo, useState } from 'react';
// import { Link } from 'react-router-dom';
import Grid from '../../../commom/Grid';
import { downloadFileToken } from '../../../../shared';
import SkeletonLoader from '../../../../shared/components/common/SkeletonLoader';

const ModalBorderoVisualizaDocumento = ({ modal, toggle, setMessage }) => {
  const [isPending, setPending] = useState(false);
  const criarButtonAcoes = row => {
    const handleDownloadFile = row => {
      downloadFileToken(row.NOME, row.NOMEFORMATADO, setPending, setMessage);
    };

    return (
      <>
        <button
          id={`btn-documentos-${row.CODARQUIVOSNOTASERVICO}`}
          className="btn btn-outline-primary rounded-circle"
          onClick={() => handleDownloadFile(row)}
          type="button"
          rel="noreferrer"
        >
          <i className="fa fa-file-download" />
          <UncontrolledTooltip
            target={`btn-documentos-${row.CODARQUIVOSNOTASERVICO}`}
            placement="top"
            className="bold-font"
            delay={0}
          >
            Visualizar
          </UncontrolledTooltip>
        </button>
        {/* <Button
        color="primary"
        outline
        type="button"
        id={`btn-visualiza-${row.id}`}
        className="btn btn-outline-primary rounded-circle fa fa-file-download"
        onClick={() => abrirDocumento({ link: row.NOME })}
        title="Visualiza"
      ></Button> */}
      </>
    );
  };

  return (
    <Modal isOpen={modal.show} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>{'Documentos do Borderô: ' + modal.idBordero}</ModalHeader>
      <ModalBody>
        <SkeletonLoader isPending={isPending} width="98%" height="40px" count={8} widthRandomness={0}>
          <div>
            {modal != null && modal.notasServico != null && modal.notasServico.length > 0 ? (
              <>
                <Grid
                  keyField="CODIGO"
                  data={modal.notasServico}
                  classes="table-layout-auto"
                  configuracaoColunas={[
                    {
                      dataField: 'NOMEARQUIVO',
                      text: 'Nota Fiscal',
                      tipoOrdenacao: 'texto'
                    },
                    {
                      dataField: 'btnAcoes',
                      text: '',
                      formatacao: 'button',
                      evento: criarButtonAcoes,
                      width: '70px',
                      sort: false
                    }
                  ]}
                />
              </>
            ) : (
              <>- Nenhuma nota fiscal de serviço vinculado ao borderô selecionado!</>
            )}
          </div>
          <div>
            <br />
          </div>
          <div>
            {modal != null &&
            modal.impotacaoGeracaoTitulo != null &&
            modal.impotacaoGeracaoTitulo.length > 0 ? (
              <>
                <Grid
                  keyField="CODIGO"
                  data={modal.impotacaoGeracaoTitulo}
                  classes="table-layout-auto"
                  configuracaoColunas={[
                    {
                      dataField: 'NOMEFORMATADO',
                      text: 'Documento importado para geraçao de título',
                      tipoOrdenacao: 'texto'
                    },
                    {
                      dataField: 'btnAcoes',
                      text: '',
                      formatacao: 'button',
                      evento: criarButtonAcoes,
                      width: '80px',
                      sort: false
                    }
                  ]}
                />
              </>
            ) : (
              <>- Nenhum documento de importação de título vinculado ao borderô selecionado!</>
            )}
          </div>
          <div>
            <br />
          </div>
          <div>
            {modal != null && modal.contratosFisico != null && modal.contratosFisico.length > 0 ? (
              <>
                <Grid
                  keyField="CODIGO"
                  data={modal.contratosFisico}
                  classes="table-layout-auto"
                  configuracaoColunas={[
                    {
                      dataField: 'NOMEARQUIVO',
                      text: 'Documento importado para contrato fisico',
                      tipoOrdenacao: 'texto'
                    },
                    {
                      dataField: 'btnAcoes',
                      text: '',
                      formatacao: 'button',
                      evento: criarButtonAcoes,
                      width: '70px',
                      sort: false
                    }
                  ]}
                />
              </>
            ) : (
              <>- Nenhum contrato fisico vinculado ao borderô selecionado!</>
            )}
          </div>
        </SkeletonLoader>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="md" onClick={toggle}>
          Retornar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalBorderoVisualizaDocumento.defaultProps = {
  modal: { show: false }
};

export default memo(ModalBorderoVisualizaDocumento);
