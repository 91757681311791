import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  IconButton
} from '@mui/material';
import { Close, DeleteForeverOutlined } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import ButtonMain from './ButtonMain';
import PDFIcon from '../../../assets/img/PDF_2.svg';
import TXTIcon from '../../../assets/img/TXT.svg';
import XLSIcon from '../../../assets/img/XLS.svg';
import PPTIcon from '../../../assets/img/PPT.svg';
import JPGIcon from '../../../assets/img/JPG.svg';
import PNGIcon from '../../../assets/img/PNG.svg';
import CSVIcon from '../../../assets/img/CSV.svg';
import DOCIcon from '../../../assets/img/DOC.svg';
import ZIPIcon from '../../../assets/img/ZIP.svg';
import RARIcon from '../../../assets/img/RAR.svg';
import XMLIcon from '../../../assets/img/XML.svg';
import HTMLIcon from '../../../assets/img/HTML.svg';
import FILEIcon from '../../../assets/img/ARQUIVO.svg';
import { downloadFileToken } from '../../../shared';
import LoaderMain from './LoaderMain';

const BoxArquivo = ({ file, funcaoDeletar, permitirDeletar, loadingUpload, isPending }) => {
  const [modalExcluirArquivo, setModalExcluirArquivo] = useState(false);
  const [isPendingDownload, setPendingDownload] = useState(false);
  const [msgErro, setMsgErro] = useState('');
  const [fileExtension, setFileExtension] = useState(FILEIcon);

  useEffect(() => {
    if (file.nome_arquivo) {
      carregaIcone();
    }
  }, []);

  const getFileExtension = fileName => {
    if (!fileName) return '';
    return fileName
      .split('.')
      .pop()
      .toLowerCase();
  };

  const carregaIcone = () => {
    let icon = getFileExtension(file.nome_arquivo);

    icon === 'pdf'
      ? setFileExtension(PDFIcon)
      : icon === 'txt'
      ? setFileExtension(TXTIcon)
      : icon === 'xls' || icon === 'xlsx'
      ? setFileExtension(XLSIcon)
      : icon === 'ppt' || icon === 'pptx'
      ? setFileExtension(PPTIcon)
      : icon === 'doc' || icon === 'docx'
      ? setFileExtension(DOCIcon)
      : icon === 'jpg' || icon === 'jpeg'
      ? setFileExtension(JPGIcon)
      : icon === 'png'
      ? setFileExtension(PNGIcon)
      : icon === 'csv'
      ? setFileExtension(CSVIcon)
      : icon === 'zip'
      ? setFileExtension(ZIPIcon)
      : icon === 'rar'
      ? setFileExtension(RARIcon)
      : icon === 'xml'
      ? setFileExtension(XMLIcon)
      : icon === 'html'
      ? setFileExtension(HTMLIcon)
      : setFileExtension(FILEIcon);
  };

  let fileSize = file.tamanho_arquivo && file.tamanho_arquivo !== 0 ? file.tamanho_arquivo / 1000 : 0;
  return (
    <Grid container sx={isPendingDownload ? { position: 'relative' } : {}}>
      {isPendingDownload && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      <Grid item xl={2} lg={2} md={3} sm={3} xs={2} className="flex-box-items-centralizados">
        <img src={fileExtension} alt={'arquivo ' + getFileExtension(file.nome_arquivo)} width="35" />
      </Grid>
      <Grid
        item
        xl={8}
        lg={8}
        md={7}
        sm={7}
        xs={8}
        className="texto-esquerda pad-top-0 items-centro-verticalmente"
        sx={{ pl: '5px' }}
      >
        <Typography
          variant="subtitle1"
          className={`texto-cor-preto w-100 texto-hidden-overflow texto-negrito ${
            file.link_arquivo ? 'link-texto' : ''
          }`}
          onClick={() => {
            if (file.link_arquivo) {
              let filePath = `upload/titulo/${file.link_arquivo}`;
              downloadFileToken(filePath, file.nome_arquivo, setPendingDownload, setMsgErro);
            }
          }}
        >
          {file.nome_arquivo}
        </Typography>
        <Typography variant="subtitle1" className="texto-cor-cinza-claro font-size-0-875rem w-100">
          {file.tamanho_arquivo && !isNaN(file.tamanho_arquivo) ? (
            <>
              {fileSize.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              Kb
            </>
          ) : file.tamanho_arquivo && isNaN(file.tamanho_arquivo) ? (
            <>{file.tamanho_arquivo}</>
          ) : (
            ''
          )}
        </Typography>
        {loadingUpload !== false && (
          <LinearProgress
            variant="determinate"
            value={loadingUpload}
            className="progressbar-main progressbar-primary"
          />
        )}
      </Grid>
      {permitirDeletar && (
        <>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2} sx={{ pr: '5px' }}>
            <ButtonMain
              tipoBotao="transparente"
              disabled={isPending || loadingUpload !== false}
              classesAdicional="min-width-none texto-cor-cinza-2 btn-deletar-arquivo"
              onClick={() => setModalExcluirArquivo(true)}
            >
              <DeleteForeverOutlined />
            </ButtonMain>
          </Grid>
          <Dialog
            open={modalExcluirArquivo !== false}
            keepMounted
            onClose={() => setModalExcluirArquivo(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
              Excluir documento
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Você confirma a exclusão do documento?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={2} />
                <Grid item xl={4} lg={4} md={4} sm={5} xs={6}>
                  <ButtonMain tipoBotao="transparente" onClick={() => setModalExcluirArquivo(false)}>
                    Cancelar
                  </ButtonMain>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={5} xs={6}>
                  <ButtonMain
                    tipoBotao="azul-escuro"
                    onClick={() => {
                      funcaoDeletar();
                      setModalExcluirArquivo(false);
                    }}
                  >
                    Confirmar
                  </ButtonMain>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </>
      )}
      {msgErro !== '' && (
        <Alert
          severity="error"
          className="texto-danger texto-negrito font-size-0-8rem pad-topbot-0 pad-right-left-15"
          // eslint-disable-next-line prettier/prettier
          action={(
            <IconButton size="small" onClick={() => setMsgErro('')}>
              <Close />
            </IconButton>
            // eslint-disable-next-line prettier/prettier
          )}
        >
          {msgErro}
        </Alert>
      )}
    </Grid>
  );
};
export default BoxArquivo;
