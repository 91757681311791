import React, { memo } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import MaskedTextInput from 'react-text-mask';
import ptBR from 'react-datepicker/node_modules/date-fns/locale/pt-BR';

import { dateMask } from '../../../masks/masks';

import stringDotNotationToObject from '../../../../utils/stringDotNotationToObject';

const DateTimePicker = props => {
  const {
    labelClassName,
    timeFormat,
    label,
    id,
    rules,
    className,
    isClearable,
    disabled,
    popperPlacement,
    popperModifiers,
    disableWeekend,
    ...restProps
  } = props;
  const {
    field: { onChange, onBlur, value, ...fields }
  } = props;
  const {
    form: { touched, errors, setFieldValue, setFieldTouched }
  } = props;

  registerLocale('pt-BR', ptBR);

  const valueFormated = moment(value, 'DD/MM/YYYY').isValid()
    ? moment(value, 'DD/MM/YYYY').toDate()
    : null;

  const dataMinimatoDate = moment(rules.dataMinima, 'YYYY-MM-DD').isValid()
    ? new Date(moment(rules.dataMinima, 'YYYY-MM-DD').toDate())
    : new Date();

  const error =
    fields && fields.name && fields.name.includes('.')
      ? stringDotNotationToObject(fields.name, errors)
      : (errors || {})[fields.name];

  const touchedField =
    fields && fields.name && fields.name.includes('.')
      ? stringDotNotationToObject(fields.name, touched)
      : (touched || {})[fields.name];

  const handleOnChange = date => {
    if (setFieldValue && moment(date).isValid()) {
      const newDate = moment(date).format('DD/MM/YYYY');
      setFieldValue(fields.name, newDate, true);
    } else if (setFieldValue) {
      setFieldValue(fields.name, null, true);
    }

    if (onChange) {
      onChange(date);
    }
  };

  const handleOnBlur = event => {
    if (setFieldTouched) {
      setFieldTouched(fields.name, true);
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  const isValidDateHandler = current => {
    const { dataMaxima, dataMinima, datasBloqueadas } = rules;
    const workingDays = [1, 2, 3, 4, 5];
    let validRange = true;
    let isHoliday = true;
    let isWeekend = true;
    current = moment(current);

    if (disableWeekend) {
      isWeekend = workingDays.includes(current.day());
    }
    if (dataMaxima) {
      const before = moment(dataMinima);
      const after = moment(dataMaxima);
      // REF: https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
      validRange = current.isBetween(before, after, null, '[]');
    }
    if (datasBloqueadas) {
      const currentDateISO = current.format(moment.HTML5_FMT.DATE);
      isHoliday = !datasBloqueadas.includes(currentDateISO);
    }
    return validRange && isHoliday && isWeekend;
  };

  const maskedTextInput = (
    <MaskedTextInput
      {...restProps}
      {...fields}
      mask={dateMask}
      value={valueFormated}
      invalid={!!(touchedField && error)}
      render={(ref, props) => <Input innerRef={ref} {...props} />}
    />
  );

  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id} className={`${labelClassName ? labelClassName : ''}`}>
          {label}
        </Label>
      )}
      <DatePicker
        id={id}
        customInput={maskedTextInput}
        locale="pt-BR"
        dateFormat="dd/MM/yyyy"
        selected={valueFormated}
        isClearable={isClearable && !disabled}
        disabled={disabled}
        openToDate={valueFormated ? new Date(valueFormated) : dataMinimatoDate}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        filterDate={isValidDateHandler}
        popperPlacement={popperPlacement}
        popperModifiers={popperModifiers}
        {...restProps}
      />
      {touchedField && error ? <FormFeedback>{error}</FormFeedback> : ''}
    </FormGroup>
  );
};

DateTimePicker.defaultProps = {
  rules: {
    dataMaxima: null,
    dataMinima: null,
    datasBloqueadas: null
  },
  className: 'form-control',
  isClearable: true,
  disableWeekend: false
};

export default memo(DateTimePicker);
