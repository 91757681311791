import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

export default props => {
  const { onSearch, itensCount = 0, tipoInstrucao = '' } = props;
  return (
    <div className="row">
      <div className="col-lg-3">
        <FormGroup>
          <Label for="search" className="sr-only">
            Busca
          </Label>
          <Input
            type="search"
            onChange={event => onSearch(event.target.value)}
            id="search"
            autoComplete="off"
            placeholder="&#xEAED; Buscar"
          />
        </FormGroup>
      </div>
      <div className="col-lg-9 d-flex flex-row-reverse bd-highlight">
        <div className="d-none d-md-block d-lg-block d-xl-none">
          <div className="form-inline selecao-itens">
            <span className="form-control-static">
              <b>Tipo de Instrução: </b>
              {tipoInstrucao}
              &nbsp;&nbsp;
            </span>
            <span className="selecao-itens-numero m-2 half-rounded-circle">{itensCount}</span>
            Ítens selecionados
          </div>
        </div>
      </div>
    </div>
  );
};
