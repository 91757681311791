import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { geraCorHexaDecimal, mascararCpfCnpj } from '../../../../shared';
import ButtonMain from '../../../Onboarding/shared/ButtonMain';

const ModalEsteiraProposta = ({ modalDados, setModalDados }) => {
  const [arrayCardPareceres, setArrayCardPareceres] = useState([]);
  const [mostrarTodosPareceres, setMostrarTodosPareceres] = useState('N');

  const buttonExpandirParecer = id => {
    let newArray = [...arrayCardPareceres];
    newArray[id] = newArray[id] ? false : true;
    setArrayCardPareceres(newArray);
  };

  return (
    <Dialog
      open={modalDados !== false}
      fullWidth={true}
      maxWidth={modalDados && modalDados.tamanho ? modalDados.tamanho : 'lg'}
      keepMounted
      onClose={() => setModalDados(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="borda-bottom-2-gray pad-topbot-10">
          {modalDados ? modalDados.modalTitle : ''}
          <IconButton onClick={() => setModalDados(false)} className="float-right margin-neg-top-5">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minHeight: '60vh' }}>
        <Box className="box-parecer">
          <Box className="pad-5 texto-centro sub-titulo border-radius-8 margin-top-10">
            <Typography
              variant="subtitle1"
              className="font-size-1-1-rem texto-cor-cinza-escuro font-bold"
            >
              {modalDados && modalDados.nomeCliente && modalDados.cnpjCliente
                ? `${modalDados.nomeCliente} - ${mascararCpfCnpj(modalDados.cnpjCliente)}`
                : ''}
            </Typography>
          </Box>
          <Grid className="time-line">
            {modalDados.estadosPareceres &&
              modalDados.estadosPareceres.map(estado => (
                <Grid container key={estado.id}>
                  <Grid className="border-line-right" item xl={1} lg={1} md={1} sm={1} xs={1} />
                  <Grid
                    className="border-line-left time-card"
                    item
                    xl={11}
                    lg={11}
                    md={11}
                    sm={11}
                    xs={11}
                  >
                    <Box className="timeline-badge-box">
                      <Box
                        className="timeline-badge-color"
                        sx={{ background: geraCorHexaDecimal(estado.nomeEstado) + '90' }}
                      >
                        {estado.siglaEstado}
                      </Box>
                    </Box>
                    <Box className="timeline-card">
                      <Box>
                        <Typography
                          variant="subtitle1"
                          className="timeline-title texto-cor-cinza-escuro font-bold"
                        >
                          {estado.nomeEstado}
                          <span className="timeline-inclusao"> &nbsp; </span>
                          <span className="timeline-inclusao">
                            {`${estado.dthrInsertFmt} por ${estado.usuarioInsert}`}
                          </span>
                        </Typography>
                      </Box>

                      <Box
                        className={`box-pareceres ${
                          arrayCardPareceres[estado.id] ? '' : 'limitar-tamanho-parecer'
                        }`}
                      >
                        <Box className="box-pre">{estado.parecer}</Box>
                      </Box>
                      <Box>
                        <Button
                          onClick={() => buttonExpandirParecer(estado.id)}
                          className="float-right"
                          sx={{ textTransform: 'inherit' }}
                        >
                          {arrayCardPareceres[estado.id] ? 'Ver menos' : 'Ver mais'}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ))}

            {modalDados && modalDados.estadosPareceres && modalDados.estadosPareceres.length === 0 && (
              <Box className="texto-centro error mt-5 mb-4">
                <h1>Nenhum parecer foi cadastrado para este cliente</h1>
              </Box>
            )}

            {modalDados &&
              modalDados.estadosPareceres &&
              modalDados.estadosPareceres.length >= 6 &&
              mostrarTodosPareceres === 'N' && (
                <Box className="texto-centro">
                  <ButtonMain
                    sx={{ maxWidth: '190px', marginTop: '15px' }}
                    tipoBotao="principal"
                    onClick={() => setMostrarTodosPareceres('S')}
                  >
                    Mostrar tudo
                  </ButtonMain>
                </Box>
              )}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalEsteiraProposta;
