import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormularioImovel from './FormularioImovel';
import FormularioAval from './FormularioAval';
import Documentos from './Documentos';

export default ({ setGarantiaFinalizada }) => {
  const [avancarEtapa, setAvancarEtapa] = useState('STEP1');
  const [valueGarantia, setValueGarantia] = useState(false);
  const [dadosFormulario, setDadosFormulario] = useState({});

  const handleRadioChange = event => {
    setValueGarantia(event.target.value);
    setDadosFormulario({});
  };

  const voltaStep = () => {
    setAvancarEtapa('STEP1');
  };

  return (
    <Card className="garantias-jornada-credito">
      <CardContent className="pad-0">
        <Box>
          <Grid container className="mt-3">
            {avancarEtapa === 'STEP1' ? (
              <Grid className="jornada-credito-step-1" item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container className="mt-2">
                  <Grid item className="mt-1 step-1-preenchido" xs={6} sm={6} md={6} lg={6} xl={6} />
                  <Grid item className="mt-1 step-1-nao-preenchido" xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="subtitle1"
                      className="mt-1 texto-cor-preto texto-direita font-size-14"
                    >
                      Passo 1 de 2
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-3 mb-3">
                  <Grid item className="mt-2" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      variant="h4"
                      className="mt-4 texto-cor-principal texto-negrito texto-esquerda font-size-20"
                    >
                      Precisamos de algumas informações para fazer a análise de crédito
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="mt-4 texto-cor-preto texto-esquerda font-size-16"
                    >
                      Selecione a opção de crédito que mais te interessou
                    </Typography>
                    <FormControl className="form-radio-group-tipo-garantia">
                      <RadioGroup
                        className="radio-group-tipo-garantia"
                        row
                        name="tipo_garantia"
                        value={valueGarantia}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="IM"
                          className={`${valueGarantia === 'IM' ? 'checked' : ''} radio-group`}
                          control={<Radio />}
                          label="Com garantia de imóvel"
                        />
                        <FormControlLabel
                          value="AV"
                          className={`${valueGarantia === 'AV' ? 'checked' : ''} radio-group`}
                          control={<Radio />}
                          label="Com aval"
                        />
                      </RadioGroup>
                    </FormControl>
                    {valueGarantia === 'IM' && (
                      <FormularioImovel
                        dadosFormulario={dadosFormulario}
                        setDadosFormulario={setDadosFormulario}
                        setAvancarEtapa={setAvancarEtapa}
                      />
                    )}
                    {valueGarantia === 'AV' && (
                      <FormularioAval
                        dadosFormulario={dadosFormulario}
                        setDadosFormulario={setDadosFormulario}
                        setAvancarEtapa={setAvancarEtapa}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid className="jornada-credito-step-2" item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container className="mt-2">
                  <Grid item className="mt-1 step-1-preenchido" xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ArrowBackIcon onClick={voltaStep} className="mt-1 icon-voltar-step-2" />
                  </Grid>
                  <Grid item className="mt-1 step-1-preenchido" xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="subtitle1"
                      className="mt-1 texto-cor-preto texto-direita font-size-14"
                    >
                      Passo 2 de 2
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="mt-3 mb-3">
                  <Grid item className="mt-2" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      variant="h4"
                      className="mt-4 texto-cor-principal texto-negrito texto-esquerda font-size-20"
                    >
                      Para seguir o processo digital da análise, precisamos de alguns documentos
                    </Typography>
                    <Typography
                      variant="h4"
                      className="mt-3 texto-cor-cinza-escuro texto-esquerda font-size-12"
                    >
                      <b>Arquivos aceitos: </b>
                      Imagens (.jpeg, .jpg, .png, .bmp), Documentos (.doc, .docx, .pdf, .ppt, .pptx),
                      Planilhas Excel (.xls, .xlsx) e Compactados (.zip, .rar)
                    </Typography>

                    <Documentos
                      dadosFormulario={dadosFormulario}
                      setGarantiaFinalizada={setGarantiaFinalizada}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
