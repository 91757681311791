import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
// import { Link } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import 'moment/locale/pt-br';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { calcularOpcoesAnoMes, moneyFullFormat } from '../../../../utils/format';
import { postListarVOPMeta } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import { exibeValorResumido } from '../../../../shared/services/User';
import ModalOperacoesVOP from './ModalOperacoesVOP';
import Target from '../../../../assets/img/target.svg';

const VOP = ({ filtroDashboard, loadingDashFim }) => {
  moment.locale('pt-br');
  let mesAnoAtual = moment().format('YYYY[-]MM');
  const dadosIniciais = [
    ['dia', 'Valor Operado', 'Meta Diária'],
    [moment().format('DD[/]MM[/]YYYY'), 0, 0]
  ];

  const [dadosChart, setDadosChart] = useState(dadosIniciais);
  const [paramsChart, setParamsChart] = useState(false);
  const [valorTotalVOP, setValorTotalVOP] = useState(0);
  const [variacaoVOP, setVariacaoVOP] = useState(0);
  const [variacaoSpread, setVariacaoSpread] = useState(0);
  const [percentualAlvo, setPercentualAlvo] = useState(0);
  const [valorTotalMeta, setValorTotalMeta] = useState(0);
  const [percentualVopMeta, setPercentualVopMeta] = useState(0);
  const [filAnoMes, setFilAnoMes] = useState(false);
  const [opcoesAnoMes, setOpcoesAnoMes] = useState([
    {
      label: moment().format('MM[/]YYYY'),
      valor: moment().format('YYYY[-]MM')
    }
  ]);
  const [isPending, setIsPending] = useState(false);
  const [modalOperVop, setModalOperVop] = useState(false);
  const [rowOperVop, setRowOperVop] = useState(false);
  const [spreadPercentualEsperado, setSpreadPercentualEsperado] = useState('texto-cor-principal');
  const [spreadPercentual, setSpreadPercentual] = useState(0);
  const [vopPercentualEsperado, setVopPercentualEsperado] = useState('texto-cor-principal');
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    title: null,
    width: '100%',
    height: '100px',
    chartArea: { left: 5, right: 10 },
    vAxis: { textPosition: 'in', format: 'short' },
    seriesType: 'bars',
    colors: ['#1A284D', '#5AD4FF'],
    legend: { position: 'bottom', alignment: 'center' },
    animation: {
      duration: 1000,
      easing: 'out'
    },
    annotations: {
      textStyle: {
        color: 'black',
        auraColor: 'transparent'
      }
    }
  };

  useEffect(() => {
    setOpcoesAnoMes(calcularOpcoesAnoMes(6));
  }, []);

  useEffect(() => {
    let params = filtroDashboard ? { ...filtroDashboard } : {};
    if (params.filtrar === false) return false;
    if (filAnoMes === false) {
      buscarVopMeta();
    } else {
      setFilAnoMes(mesAnoAtual);
    }
  }, [filtroDashboard]);

  useEffect(() => {
    if (filAnoMes === false) return false;
    buscarVopMeta();
  }, [filAnoMes]);

  useEffect(() => {
    if (!isNaN(rowOperVop) && paramsChart[rowOperVop] && paramsChart[rowOperVop].temVop) {
      var params = {
        ...filtroDashboard,
        ...paramsChart[rowOperVop]
      };
      setModalOperVop(params);
    }
  }, [rowOperVop, filtroDashboard]);

  useEffect(() => {
    if (modalOperVop === false) {
      setRowOperVop(false);
    }
  }, [modalOperVop]);

  const buscarVopMeta = () => {
    let params = filtroDashboard ? filtroDashboard : {};
    if (params.filtrar === false) return false;
    params = {
      anoMes: filAnoMes ? filAnoMes : mesAnoAtual,
      ...params
    };
    setIsPending(true);
    postListarVOPMeta(params)
      .then(res => {
        var arrayDados = [];
        var arrayParams = [];
        var totalVOP = res && res.data && res.data.totalVOP ? parseFloat(res.data.totalVOP) : 0;
        var totalVariacaoVop =
          res && res.data && res.data.variacaoVop ? parseFloat(res.data.variacaoVop) : 0;
        var totalVariacaoSpread =
          res && res.data && res.data.variacaoSpred ? parseFloat(res.data.variacaoSpred) : 0;
        var totalPercentualAlvo =
          res && res.data && res.data.percentualAlvo ? parseFloat(res.data.percentualAlvo) : 0;
        var totalMeta = res && res.data && res.data.totalMeta ? parseFloat(res.data.totalMeta) : 0;
        let spreadPercEsperado =
          res && res.data && res.data.spreadEsperado ? res.data.spreadEsperado : false;
        let vopPercEsperado = res && res.data && res.data.vopEsperado ? res.data.vopEsperado : false;
        let percentualSpreadMeta =
          res && res.data && res.data.percentualSpreadMeta ? res.data.percentualSpreadMeta : 0;
        var pctValorMeta =
          res && res.data && res.data.percentualVopMeta ? parseFloat(res.data.percentualVopMeta) : 0;
        var listaDataVOP = res && res.data && res.data.lista ? res.data.lista : [];
        var anoMesHoje = moment().format('YYYY[-]MM');
        var diaHoje = moment().format('DD');
        if (listaDataVOP.length > 0) {
          if (totalMeta !== false) {
            arrayDados.push(['dia', 'Valor Operado', 'Meta Diária']);
          } else {
            arrayDados.push(['dia', 'Valor Operado']);
          }
          // setFilRelatorio({
          //   inicial: listaDataVOP[0].data,
          //   final: listaDataVOP[listaDataVOP.length - 1].data
          // });
          listaDataVOP.map(dados => {
            var dia = moment(dados.data).format('DD');
            if (
              moment(dados.data).format('YYYY[-]MM') === anoMesHoje &&
              parseInt(dia) > parseInt(diaHoje)
            )
              return dados;
            var vop = parseInt(dados.vop);
            var meta = parseInt(dados.meta);
            if (totalMeta !== false) {
              arrayDados.push([dia, vop, meta]);
            } else {
              arrayDados.push([dia, vop]);
            }
            arrayParams.push({
              data: dados.data,
              gerente: params && params.gerente ? params.gerente : 0,
              plataforma: params && params.plataforma ? params.plataforma : 0,
              temVop: parseFloat(vop) > 0
            });
            return dados;
          });
          if (arrayDados.length === 1) {
            arrayDados.push([moment().format('DD[/]MM[/]YYYY'), 0, 0]);
          }
        } else {
          arrayDados = dadosIniciais;
        }
        setValorTotalVOP(totalVOP);
        setVariacaoVOP(totalVariacaoVop);
        setVariacaoSpread(totalVariacaoSpread);
        setPercentualAlvo(totalPercentualAlvo);
        setValorTotalMeta(totalMeta);
        setPercentualVopMeta(pctValorMeta);
        setDadosChart(arrayDados);
        setParamsChart(arrayParams);
        setSpreadPercentual(percentualSpreadMeta);
        setVopPercentualEsperado(vopPercEsperado ? 'texto-cor-principal' : 'texto-warning');
        setSpreadPercentualEsperado(spreadPercEsperado ? 'texto-cor-principal' : 'texto-warning');
      })
      .catch(() => {
        setDadosChart(false);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('VOP');
      });
  };

  const handleOnChangeAnoMes = event => {
    setFilAnoMes(event.target.value);
  };

  const eventos = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart();
        google.visualization.events.addListener(chart, 'click', e => {
          let { targetID } = e;
          if (targetID.indexOf('hAxis#0#label#') >= 0) {
            let row = parseInt(targetID.replace('hAxis#0#label#', ''));
            if (!isNaN(row)) {
              setRowOperVop(row);
            }
          }
        });
        google.visualization.events.addListener(chart, 'select', () => {
          let chartSelected = chart.getSelection();
          if (chartSelected && chartSelected[0]) {
            var { row, column } = chartSelected[0];
            if (!isNaN(row) && column && column === 1) {
              setRowOperVop(row);
              chart.setSelection([{ row: 0, column: 0 }]);
            }
          }
        });
        google.visualization.events.addListener(chart, 'onmouseover', e => {
          if (e && e.column && e.column === 1 && e.row !== null)
            chart.container.style.cursor = 'pointer';
        });
        google.visualization.events.addListener(chart, 'onmouseout', e => {
          if (e && e.column && e.column === 1 && e.row !== null)
            chart.container.style.cursor = 'default';
        });
      }
    }
  ];

  return (
    <>
      <Card className="chart-card">
        {dadosChart ? (
          <CardContent className="ajuste-spin-chart" sx={isPending ? { position: 'relative' } : {}}>
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={5} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-cinza-escuro pad-5 word-break labelChartVOP mb-1"
                >
                  <span className="font-size-1-1-rem margin-right-10 texto-weight-600">VOP</span>
                  <Tooltip arrow placement="top" title={moneyFullFormat(valorTotalVOP)}>
                    <span className="font-size-1-1-rem texto-weight-600 texto-cor-principal">
                      {`R$ ${exibeValorResumido(valorTotalVOP)} / `}
                    </span>
                  </Tooltip>
                  <Tooltip
                    arrow
                    placement="top"
                    title={`R$ ${variacaoVOP.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}`}
                  >
                    <span className={`font-size-1-1-rem texto-weight-600 ${vopPercentualEsperado}`}>
                      {`${percentualVopMeta.toFixed(2)}%`}
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={3} xs={12}>
                <Typography
                  variant="subtitle1"
                  className={`texto-cor-cinza-escuro pad-5 word-break labelChartVOP ${
                    onlySmallScreen ? 'texto-esquerda' : 'texto-centro'
                  } `}
                >
                  <img src={Target} alt="alvo" className="alvo-chart-vop" />
                  <Tooltip arrow placement="top" title="Alvo do dia">
                    <span className="font-size-1-1-rem texto-weight-600 texto-cor-principal">
                      {`${parseInt(percentualAlvo)}%`}
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography
                  variant="subtitle1"
                  className={`texto-cor-cinza-escuro pad-5 word-break labelChartVOP mb-1 ${
                    onlySmallScreen ? 'texto-esquerda' : 'texto-direita'
                  } `}
                >
                  <span className="font-size-1-1-rem margin-right-10 texto-weight-600">Meta</span>
                  <Tooltip arrow placement="top" title={moneyFullFormat(valorTotalMeta)}>
                    <span className="font-size-1-1-rem texto-weight-600 texto-cor-principal">
                      {`R$ ${exibeValorResumido(valorTotalMeta)}`}
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
            <Box className="labelChartVOP">
              <Grid container>
                <Grid
                  item
                  xl={7}
                  lg={7}
                  md={7}
                  sm={7}
                  xs={12}
                  order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                >
                  <Select
                    className="select-periodo-chart-dasboard"
                    value={filAnoMes ? filAnoMes : mesAnoAtual}
                    onChange={handleOnChangeAnoMes}
                    input={<OutlinedInput label="" />}
                  >
                    {opcoesAnoMes.map(item => (
                      <MenuItem key={item.label} value={item.valor}>
                        <DateRangeIcon className="icone-select" />
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={5}
                  sm={5}
                  xs={12}
                  order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    className={`texto-cor-cinza-escuro word-break labelChartSpread ${
                      onlySmallScreen ? 'texto-esquerda mb-2' : 'texto-direita'
                    } `}
                  >
                    <span className="font-size-1-1-rem margin-right-10 texto-weight-600">Spread</span>
                    <Tooltip
                      arrow
                      placement="top"
                      title={`R$ ${variacaoSpread.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}`}
                    >
                      <span className={`font-size-1-1-rem texto-weight-600 ${spreadPercentualEsperado}`}>
                        {`${spreadPercentual.toFixed(2)}%`}
                      </span>
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
              {/* <Link
                className="linkRelatorios float-right texto-info font-size-875 mt-2"
                to={{
                  pathname: '/relatorios/titulos',
                  search: `?tipo=8&data_aceite_inicial=${filRelatorio.inicial}&data_aceite_final=${filRelatorio.final}`,
                  state: {
                    fromDashboard: true
                  }
                }}
              >
                Relatório
              </Link> */}
            </Box>

            <Chart
              className="chartVOP"
              chartType="ComboChart"
              width="100%"
              height="400px"
              data={dadosChart}
              options={options}
              chartLanguage="pt-Br"
              chartEvents={eventos}
            />
            <Typography variant="subtitle1" className="font-size-1-rem">
              <b>*</b>
              <i>Clique na barra azul escuro ou na data para ver mais detalhes das operações</i>
            </Typography>
          </CardContent>
        ) : (
          <CardContent>
            <div className="error">
              <h1>Ops!</h1>
              <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
            </div>
          </CardContent>
        )}
      </Card>
      <ModalOperacoesVOP modalOperVop={modalOperVop} setModalOperVop={setModalOperVop} />
    </>
  );
};

export default VOP;
