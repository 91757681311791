import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { Field } from 'formik';
import CheckBoxMain from '../../shared/InputMain/CheckBoxMain';
import InputMain from '../../shared/InputMain/InputMain';

export default ({
  dadosPerformDesconto,
  values,
  setFieldValue,
  campoValidacao,
  setCampoValidacao,
  isDisabled
}) => {
  return (
    <Box className="margin-top-60">
      <Box className="pad-bot-10">
        <Typography
          variant="h5"
          className={
            'texto-cor-principal texto-negrito ' +
            (campoValidacao === 'performDesconto' ? 'texto-danger-strong' : '')
          }
        >
          Período de pagamento
        </Typography>
        <Typography
          variant="subtitle1"
          className={
            'texto-cor-cinza-escuro pad-topbot-10 ' +
            (campoValidacao === 'performDesconto' ? 'texto-danger-strong' : '')
          }
        >
          Selecione os períodos em que os pagamentos são recebidos pela empresa e preencha as informações
          correspondentes a cada um
        </Typography>
      </Box>
      {dadosPerformDesconto.map(performDesconto => (
        <Box key={`boxPerformDesconto_${performDesconto.siglaTipo}`} className="pad-top-20">
          <Field
            type="checkbox"
            name={`chkbx_performDesconto[${performDesconto.siglaTipo}]`}
            value={performDesconto.codeTipo}
            checked={
              values.chkbx_performDesconto && values.chkbx_performDesconto[performDesconto.siglaTipo]
                ? true
                : false
            }
            component={CheckBoxMain}
            disabled={isDisabled}
            corCheckBox="checkbox-cor-complementar"
            controlLabelCheckbox={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <span className="font-size-1-rem texto-cor-cinza-escuro camel-case font-bold">
                {performDesconto.nomeTipo.toLowerCase()}
              </span>
            }
            onChangeChkbx={() => {
              if (campoValidacao.substring(0, 13) === 'performDescon') setCampoValidacao('');
            }}
          />
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`performDesconto[${performDesconto.siglaTipo}][percentual]`}
                titulo="Percentual (%)"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName={
                  'input-disabled-cinza ' +
                  (campoValidacao === `performDesconto_${performDesconto.siglaTipo}_percentual`
                    ? 'input-error'
                    : '')
                }
                disabled={
                  isDisabled ||
                  !(
                    values.chkbx_performDesconto &&
                    values.chkbx_performDesconto[performDesconto.siglaTipo]
                  )
                }
                tipoMascara="number-percentual"
                setFieldValue={setFieldValue}
                onChangeInput={() => {
                  if (campoValidacao === `performDesconto_${performDesconto.siglaTipo}_percentual`)
                    setCampoValidacao('');
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`performDesconto[${performDesconto.siglaTipo}][diasEntrega]`}
                titulo="Média de dias para entrega (dias)"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName={
                  'input-disabled-cinza ' +
                  (campoValidacao === `performDesconto_${performDesconto.siglaTipo}_diasEntrega`
                    ? 'input-error'
                    : '')
                }
                disabled={
                  isDisabled ||
                  !(
                    values.chkbx_performDesconto &&
                    values.chkbx_performDesconto[performDesconto.siglaTipo]
                  )
                }
                tipoMascara="number-inteiro"
                onChangeInput={() => {
                  if (campoValidacao === `performDesconto_${performDesconto.siglaTipo}_diasEntrega`)
                    setCampoValidacao('');
                }}
                inputProps={{ maxLength: 5 }}
              />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};
