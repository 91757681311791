import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ConnectedFocusError } from 'focus-formik-error';
import ButtonMain from '../../shared/ButtonMain';
import validaCaracteresEspeciais from '../../../../utils/validaCaracteresEspeciais';
import AlertErroMain from '../../shared/AlertErroMain';
import InputMain from '../../shared/InputMain/InputMain';
import { postInsereParecerFinal } from '../../../../api/onboarding';
import { moneyFullFormat, unmaskValue } from '../../../../utils/format';

export default ({ avancarEtapa, isPending, setPending, cnpjEmpresa, origemProposta = 'connect' }) => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [mostraErro, setMostraErro] = useState(false);

  const handleFormSubmit = (values, form) => {
    var parecerFinal =
      'Limite Solicitado: ' +
      moneyFullFormat(unmaskValue(values.limite_solicitado)) +
      '\n' +
      'Taxa Solicitada: ' +
      moneyFullFormat(unmaskValue(values.taxa_solicitada), true) +
      '\n\n' +
      values.parecer;

    let params = {
      cnpj: cnpjEmpresa,
      origem_proposta: origemProposta,
      cod_ppc_jornada: 1,
      parecer: parecerFinal
    };

    setPending(true);
    postInsereParecerFinal(params)
      .then(() => {
        avancarEtapa();
      })
      .catch(() => {
        setMostraErro(true);
      })
      .finally(() => {
        form.setSubmitting(false);
        setPending(false);
      });
  };

  const acessaLogin = () => {
    history.push('/authentication/logout');
  };

  return (
    <Grid container>
      <Grid item xl={2} lg={2} md={1} sm={1} />
      <Grid item xl={8} lg={8} md={10} sm={10} xs={12}>
        <Typography variant="h5" className="mt-2 texto-cor-principal texto-negrito texto-centro">
          Parecer
        </Typography>

        <Grid container>
          <Grid item xl={1} lg={1} md={1} sm={1} />
          <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
            <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
              <span>
                Preencha o campo de limite solicitado, a taxa solicitada e o parecer a seguir com as
                considerações a respeito da empresa para que possamos seguir com o cadastro
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Grid container className="mt-5">
          <Grid item xl={1} lg={1} md={1} sm={1} />
          <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
            <Formik
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                limite_solicitado: Yup.string()
                  .nullable()
                  .required('Favor preencher a solicitação de limite')
                  .test(
                    'limite_solicitado',
                    'Favor preencher um valor para solicitação de limite',
                    value => {
                      return value !== null && value !== '' && unmaskValue(value) !== 0;
                    }
                  ),
                taxa_solicitada: Yup.string()
                  .nullable()
                  .required('Favor preencher a solicitação de taxa')
                  .test(
                    'taxa_solicitada',
                    'Favor preencher um valor para solicitação de taxa',
                    value => {
                      return value !== null && value !== '' && unmaskValue(value) !== 0;
                    }
                  ),
                parecer: Yup.string()
                  .nullable()
                  .required('Favor preencha o parecer')
                  .test('parecer', 'Caracteres Inválidos', value => {
                    let caracteresEspeciais = [`'`, `*`, `"`];
                    return validaCaracteresEspeciais(value, caracteresEspeciais);
                  })
              })}
              onSubmit={handleFormSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ isSubmitting }) => (
                <Form>
                  <ConnectedFocusError />

                  <Grid container spacing={3}>
                    <Grid item xl={6} lg={6} md={6} xs={12} className="pad-top-0">
                      <Field
                        type="text"
                        name="limite_solicitado"
                        titulo="Limite (R$)"
                        size="small"
                        component={InputMain}
                        autoComplete="off"
                        disabled={isPending || isSubmitting}
                        tipoMascara="number-money"
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={12} className="pad-top-0">
                      <Field
                        type="text"
                        name="taxa_solicitada"
                        titulo="Taxa"
                        size="small"
                        component={InputMain}
                        autoComplete="off"
                        disabled={isPending || isSubmitting}
                        tipoMascara="number-percentual"
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  </Grid>

                  <Field
                    name="parecer"
                    placeholder="Parecer"
                    id="parecer"
                    size="small"
                    uppercase={false}
                    component={InputMain}
                    autoComplete="off"
                    multiline
                    rows={12}
                    disabled={isPending || isSubmitting}
                  />

                  <Grid container className="mb-4">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                    >
                      <ButtonMain
                        tipoBotao="transparente"
                        disabled={isPending || isSubmitting}
                        onClick={() => {
                          acessaLogin();
                        }}
                      >
                        Sair
                      </ButtonMain>
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                    >
                      <ButtonMain
                        type="submit"
                        tipoBotao="principal"
                        disabled={isPending || isSubmitting}
                      >
                        Continuar
                      </ButtonMain>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
