import React from 'react';
import { useHistory } from 'react-router-dom';
import MainHeader from '../Main/MainHeader';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import {
  getClienteAtual,
  removeClienteAtual,
  setClienteAtual,
  verificaClienteOnboarding,
  isAuthenticated,
  getTelaDefault,
  setRedirecionamentoRealizado,
  getRedirecionamentoRealizado
} from '../../shared';

export default props => {
  // recupera localizacao no caso de perda de sessao
  const { location: { state: { cliente } = {} } = {} } = props;
  const { location: { state: { location } = {} } = {} } = props;
  const history = useHistory();

  if (!isAuthenticated()) {
    sessionStorage.clear();
    history.push('/authentication/login');
  }

  if (cliente && cliente !== null) {
    let objCliente = getClienteAtual();

    if (typeof objCliente !== 'undefined' && objCliente !== null) {
      removeClienteAtual();
    }

    setClienteAtual(cliente);
    verificaClienteOnboarding(cliente).then(verifCli => {
      if (verifCli.jornada_onboarding) {
        var urlJornada = '/jornada-onboarding';
        urlJornada += verifCli.tipo_jornada ? '-' + verifCli.tipo_jornada : '';
        if (verifCli.empresa_onboarding) {
          history.push(urlJornada);
        } else if (
          verifCli.etapaOnboarding !== undefined &&
          verifCli.etapaOnboarding.subEtapa !== 'validacao'
        ) {
          history.push(urlJornada);
        } else if (
          verifCli.etapaOnboarding !== undefined &&
          verifCli.etapaOnboarding.subEtapa === 'validacao'
        ) {
          history.push(urlJornada);
        } else {
          history.push('/');
        }
      } else {
        var redirecionar =
          location && location.pathname && location.pathname !== '/grupo-empresarial'
            ? location.pathname
            : '/';
        var telaDefault = getTelaDefault();
        let redirecionamentoRealizado = getRedirecionamentoRealizado();

        if (!redirecionamentoRealizado) {
          if (
            telaDefault &&
            telaDefault.tipo_chave &&
            telaDefault.tipo_chave === 'ROTA' &&
            telaDefault.chave
          ) {
            redirecionar = '/' + telaDefault.chave.replaceAll('.', '/');

            if (telaDefault.modulo !== 'plataforma_cliente') {
              redirecionar = `/${telaDefault.modulo}${redirecionar}`;
            }
            setRedirecionamentoRealizado();
          }
        }

        history.push(redirecionar);
      }
    });
  }

  return (
    <>
      <MainHeader />
      <main className="main-without-menu font-family-ibm-plex">
        <LoaderMain />
      </main>
    </>
  );
};
