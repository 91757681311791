import React from 'react';
import { Box, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import swal from '@sweetalert/with-react';
import { getClientes, postLink } from '../../../../shared';
import { direcionamentoPropostas, nivelUsuarioEnum } from '../../../../shared/Enums';

const EsteiraProposta = ({ proposta, setPropostaCliente, setMostraErro, dadosUser }) => {
  const clientes = getClientes();
  const permitirParecer =
    dadosUser.nivel !== nivelUsuarioEnum.GERENTE_NEGOCIOS &&
    dadosUser.nivel !== nivelUsuarioEnum.GERENTE_PLATAFORMA;
  let lstPropostaEsteiraInvertida = [...proposta.esteira].reverse();

  const direcionarProposta = etapa => {
    if (etapa.direcionar && etapa.direcionar === 'plataforma') {
      var clienteClicado = [...clientes].filter(cliente => {
        if (cliente.cnpj !== null && cliente.cnpj === proposta.cnpjCliente) {
          return cliente;
        }
        return false;
      });
      clienteClicado = clienteClicado[0];
      if (clienteClicado) {
        swal({
          title: 'Trocar para outra empresa',
          text:
            'Ao confirmar esta ação você será redirecionado para outra sessão. Você quer mesmo continuar?',
          icon: 'warning',
          buttons: ['Cancelar', 'Confirmar'],
          className: 'alert-trocar-empresa'
        }).then(confirm => {
          if (confirm) {
            setPropostaCliente(clienteClicado);
          }
        });
      }
    } else if (
      etapa.direcionar &&
      direcionamentoPropostas[etapa.direcionar] &&
      direcionamentoPropostas[etapa.direcionar].tipo === 'post'
    ) {
      if (direcionamentoPropostas[etapa.direcionar].url) {
        postLink(direcionamentoPropostas[etapa.direcionar].url, { CODPPC: proposta.codPPC }, true);
      } else {
        setMostraErro('Url de redirecionamento não foi definida, favor contatar o suporte');
      }
    } else if (
      etapa.direcionar &&
      direcionamentoPropostas[etapa.direcionar] &&
      direcionamentoPropostas[etapa.direcionar].tipo === 'get'
    ) {
      if (direcionamentoPropostas[etapa.direcionar].url) {
        var paramAdc =
          etapa.direcionar === 'condicoes_operacionais' && proposta.codCliente
            ? '/' + proposta.codCliente
            : '#' + proposta.codPPC;
        window.open(
          direcionamentoPropostas[etapa.direcionar].url + paramAdc,
          '_blank',
          'noopener,noreferrer'
        );
      } else {
        setMostraErro('Url de redirecionamento não foi definida, favor contatar o suporte');
      }
    }
  };

  return (
    <Box className="pad-15 flex-box-items-centralizados items-centralizados-esquerda scroll-custom overflow-x w-100">
      {lstPropostaEsteiraInvertida.map((etapa, index, arr) => {
        var key = arr.length - 1 - index;
        var temPeriodoDias = etapa.periodoDias !== false && etapa.periodoDias !== undefined;
        var ultimaEtapa = key === proposta.esteira.length;
        var corFundoSLA = etapa.slaAtrasado ? 'warning-strong' : 'info';
        var boxWidth = temPeriodoDias ? '160px' : '100px';
        var tituloEtapa =
          etapa.etapa === 'CONCLUIDO' && etapa.aprovacao !== undefined
            ? etapa.aprovacao
              ? 'Apto a operar'
              : 'Inapto a operar'
            : etapa.titulo;
        return (
          <Box key={key}>
            <Box
              className="flex-box-items-centralizados items-centralizados-esquerda"
              sx={{ width: boxWidth }}
            >
              <Box
                className={`height-1px borda-1 ${
                  key !== 1 ? 'borda-cor-cinza' : 'borda-transparente'
                } margin-right-3`}
                sx={{ width: '30px' }}
              />
              {etapa.etapaNaoAtingida ? (
                <Box className="circulo-esteira fundo-cor-cinza-claro-4 borda-cor-cinza-claro-4 borda-1 flex-box-items-centralizados">
                  <Typography className="texto-cor-cinza-2 font-size-1-2-rem font-bold line-height-1">
                    {key}
                  </Typography>
                </Box>
              ) : etapa.finalizado ? (
                <Box
                  className={`circulo-esteira pad-5 ${
                    etapa.aprovacao !== undefined
                      ? etapa.aprovacao
                        ? 'fundo-success'
                        : 'fundo-danger'
                      : 'fundo-' + corFundoSLA
                  } ${permitirParecer ? 'cursor-auto' : ''}`}
                >
                  <Check className="texto-cor-branca font-bold" />
                </Box>
              ) : (
                <Box
                  className={
                    `circulo-esteira fundo-cor-branca pad-8 borda-cor-${corFundoSLA} borda-1 flex-box-items-centralizados ` +
                    (etapa.direcionar ? 'cursor-pointer' : '')
                  }
                  onClick={() => direcionarProposta(etapa)}
                >
                  <Typography className={`texto-${corFundoSLA} font-size-1-2-rem line-height-1`}>
                    {key}
                  </Typography>
                </Box>
              )}
              {ultimaEtapa === false && (
                <>
                  <Box
                    className="height-1px borda-1 borda-cor-cinza margin-left-3"
                    sx={{ width: '30px' }}
                  />
                  {temPeriodoDias && (
                    <Box
                      title={`${etapa.periodoDias} dias uteis`}
                      className={`badge-padrao-3 w-mw-60px ${
                        etapa.finalizado
                          ? `fundo-${corFundoSLA} borda-zero texto-cor-branca`
                          : `fundo-cor-branca borda-cor-${corFundoSLA} borda-1 texto-${corFundoSLA}`
                      }`}
                    >
                      {`${etapa.periodoDias} dias`}
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Box
              className="texto-centro texto-nowrap-overflow-dotted"
              sx={{ height: '55px', width: boxWidth }}
            >
              <Typography
                className={`font-size-080 texto-hidden-overflow font-bold texto-cor-preto pad-top-10 ${
                  temPeriodoDias ? 'margin-neg-left-65' : ''
                }`}
                title={tituloEtapa}
              >
                {tituloEtapa}
              </Typography>
              <Typography
                className={`font-size-080 texto-hidden-overflow pad-top-5 texto-cor-cinza-escuro ${
                  temPeriodoDias ? 'margin-neg-left-65' : ''
                }`}
                title={etapa.hora ? `Ás ${etapa.hora}` : ''}
              >
                {etapa.data ? etapa.data : ''}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default EsteiraProposta;
