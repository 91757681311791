import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as CPF from '@fnando/cpf';
import * as CNPJ from '@fnando/cnpj';
import * as Yup from 'yup';
import { conformToMask } from 'react-text-mask';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as masks from '../../../../shared/masks/masks';
import InputMain from '../../shared/InputMain/InputMain';
import AlertErroMain from '../../shared/AlertErroMain';
import SelectMain from '../../shared/InputMain/SelectMain';
import ButtonMain from '../../shared/ButtonMain';
import ToggleButtonMain from '../../shared/ToggleButtonMain';
import validaCaracteresEspeciais from '../../../../utils/validaCaracteresEspeciais';
import LoaderMain from '../../shared/LoaderMain';
import strip from '../../../../utils/strip';
import DocumentoUpload from '../../shared/DocumentoUpload';
import { tiposImovelGarantia } from '../../shared/Enums/EnumsOnboarding';
import {
  postConfirmarDadosGarantia,
  postConfirmarDocumentosGarantia,
  getDocumentosGarantia,
  getDadosCEP
} from '../../../../api/onboarding';
import { unmaskValue } from '../../../../utils/format';

export default ({ avancarEtapa, cnpjEmpresa, isPending, setPending, origemProposta = 'digital' }) => {
  const [alterandoGarantia, setAlterandoGarantia] = useState(false);
  const [modalDeletarGarantia, setModalDeletarGarantia] = useState(false);
  const [dadosGarantia, setDadosGarantia] = useState({});
  const [documentosGarantia, setDocumentosGarantia] = useState([]);
  const [dadosFormulario, setDadosFormulario] = useState({});
  const [isPendingCEP, setPendingCEP] = useState(false);
  const [isPendingDocumento, setPendingDocumento] = useState(false);
  const [pessoaProprietaria, setPessoaProprietaria] = useState('PF');
  const [erroCEPNaoEncontrado, setErroCEPNaoEncontrado] = useState(false);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mostraErro, setMostraErro] = useState(false);

  useEffect(() => {
    if (
      cnpjEmpresa === undefined ||
      cnpjEmpresa === '' ||
      (typeof documentosGarantia === 'object' && documentosGarantia.length !== 0)
    )
      return false;
    setPendingDocumento(true);
    getDocumentosGarantia(cnpjEmpresa)
      .then(res => {
        if (res.data && res.data.length !== 0) {
          var docGarTemp = res.data.map(doc => {
            doc.cnpj = cnpjEmpresa;
            doc.nome_doc = doc.nome;
            doc.explicacao_doc = doc.descricao;
            doc.obrigacao_doc = doc.requerido;
            return doc;
          });
          setDocumentosGarantia(docGarTemp);
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPending(false);
        setPendingDocumento(false);
      });
  }, [cnpjEmpresa]);

  const handleFormSubmit = (values, form) => {
    values.cnpj = cnpjEmpresa;
    values.pessoa_proprietaria = pessoaProprietaria;
    values.nome = values.nome ? values.nome.toUpperCase() : values.nome.toUpperCase();
    values.cep = dadosFormulario.cep ? strip(dadosFormulario.cep).substr(0, 8) : values.cep;
    values.logradouro = dadosFormulario.logradouro
      ? dadosFormulario.logradouro.toUpperCase()
      : values.logradouro.toUpperCase();
    values.bairro = dadosFormulario.bairro
      ? dadosFormulario.bairro.toUpperCase()
      : values.bairro.toUpperCase();
    values.cidade = dadosFormulario.cidade
      ? dadosFormulario.cidade.toUpperCase()
      : values.cidade.toUpperCase();
    values.uf = dadosFormulario.uf ? dadosFormulario.uf.toUpperCase() : values.uf.toUpperCase();
    setDadosGarantia(values);
    setDadosFormulario({});
    setAlterandoGarantia(false);
    form.setSubmitting(false);
    window.scrollTo(0, 348);
  };

  const buscarCEP = valuesForm => {
    var cep = valuesForm.cep ? strip(valuesForm.cep).substr(0, 8) : '';
    if (cep === '') return false;
    setErroCEPNaoEncontrado(false);
    setPendingCEP(true);
    getDadosCEP(cep)
      .then(res => {
        if (res.data && res.data.dados && res.data.dados.endereco) {
          var dadosCEP = {
            logradouro: res.data.dados.endereco,
            bairro: res.data.dados.bairro,
            uf: res.data.dados.estado,
            cidade: res.data.dados.cidade
          };
          delete valuesForm.logradouro;
          delete valuesForm.bairro;
          delete valuesForm.uf;
          delete valuesForm.cidade;
          var dados = { ...dadosCEP, ...valuesForm };
          setDadosFormulario(dados);
        } else {
          setErroCEPNaoEncontrado(true);
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPendingCEP(false);
      });
  };

  const confirmarGarantia = () => {
    if (dadosGarantia === undefined || Object.keys(dadosGarantia).length === 0) return false;
    if (documentosGarantia === undefined || documentosGarantia.length === 0) return false;
    var documentosGarantiaTemp = [...documentosGarantia];
    var bloquearSubmit = false;
    documentosGarantiaTemp = documentosGarantiaTemp.map(gar => {
      if (gar.hash_arquivo === undefined || gar.hash_arquivo === '') {
        bloquearSubmit = true;
        if (gar.nome_arquivo === undefined || gar.nome_arquivo === '') {
          gar.status = 'reprovado';
          gar.titulo_status = 'Pendente';
          gar.subtitulo_status = 'Favor importar um arquivo para este documento';
        }
      }
      return gar;
    });
    if (bloquearSubmit) {
      setDocumentosGarantia(documentosGarantiaTemp);
      return false;
    }

    if (origemProposta === 'one7_mais_credito') {
      setPendingDocumento(true);
    } else {
      setPending(true);
    }
    var dadosGarantiaFormatado = formatarPayLoadDadosGarantia(dadosGarantia);
    postConfirmarDadosGarantia(cnpjEmpresa, pessoaProprietaria, dadosGarantiaFormatado)
      .then(res => {
        if (res.data && res.data.Dados && res.data.Dados.CODPPCGARANTIA && res.data.Dados.CODPPC) {
          var dadosDocumentosGarantia = formatarPayLoadDocumentosGarantia(
            res.data.Dados.CODPPC,
            res.data.Dados.CODPPCGARANTIA,
            documentosGarantia
          );
          postConfirmarDocumentosGarantia(dadosDocumentosGarantia)
            .then(() => {
              avancarEtapa();
            })
            .catch(err => {
              setMostraErro(err.response);
            })
            .finally(() => {
              setPending(false);
              setPendingDocumento(false);
            });
        } else {
          setPending(false);
          setPendingDocumento(false);
        }
      })
      .catch(err => {
        setPending(false);
        setPendingDocumento(false);
        setMostraErro(err.response);
      });
  };

  const formatarPayLoadDadosGarantia = dados => {
    var dadosFmt = {
      documento: strip(dados.cpfCnpj),
      nome: dados.nome ? dados.nome.toUpperCase() : dados.nome,
      endereco: { ...dados }
    };
    delete dadosFmt.endereco.cpfCnpj;
    delete dadosFmt.endereco.nome;
    dadosFmt.endereco.cep = strip(dadosFmt.endereco.cep).substr(0, 8);
    dadosFmt.endereco.numero = String(dadosFmt.endereco.numero);
    dadosFmt.endereco.valor_imovel = unmaskValue(dadosFmt.endereco.valor_imovel);
    dadosFmt.endereco.complemento = dadosFmt.endereco.complemento ? dadosFmt.endereco.complemento : '';
    return dadosFmt;
  };

  const formatarPayLoadDocumentosGarantia = (cod_ppc, garantia_id, documentosGarantia) => {
    let tempDocs = [];
    documentosGarantia.map(doc => {
      if (
        typeof doc.hash_arquivo !== 'undefined' &&
        doc.hash_arquivo !== undefined &&
        doc.hash_arquivo !== null
      ) {
        let tempDoc = { ...doc };
        tempDoc.documento_id = doc.id;
        tempDoc.hash = doc.hash_arquivo;
        tempDocs.push(tempDoc);
      }
      return doc;
    });
    return {
      cnpj: cnpjEmpresa,
      cod_ppc: cod_ppc,
      origem_proposta: origemProposta,
      garantias: [
        {
          garantia_id: garantia_id,
          documentos: tempDocs
        }
      ]
    };
  };

  const deletarGarantia = () => {
    setModalDeletarGarantia(false);
    setDadosGarantia({});
    setDadosFormulario({});
    window.scrollTo(0, 200);
  };

  const abrirAlterarGarantia = () => {
    setDadosFormulario(dadosGarantia);
    setAlterandoGarantia(true);
  };

  return (
    <Grid container>
      <Grid item xl={2} lg={2} md={2} sm={1} />
      <Grid item xl={8} lg={8} md={8} sm={10} xs={12}>
        <Box>
          <Typography variant="h5" className="texto-cor-principal texto-negrito texto-centro">
            Dados do imóvel
          </Typography>
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-10 texto-centro"
            sx={{ p: { xl: '10px 80px', lg: '10px 80px' } }}
          >
            {alterandoGarantia || Object.keys(dadosGarantia).length === 0 ? (
              <>
                Precisamos de algumas informações e documentos do seu imóvel para cadastrá-lo como
                garantia
              </>
            ) : (
              <>Confirme os dados do imóvel abaixo</>
            )}
          </Typography>
        </Box>
        <Box sx={isPendingCEP ? { position: 'relative', p: '25px 0 0 0' } : { p: '25px 0 0 0' }}>
          {isPendingCEP && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          {alterandoGarantia || Object.keys(dadosGarantia).length === 0 ? (
            <Formik
              initialValues={dadosFormulario}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                cpfCnpj: Yup.string()
                  .nullable()
                  .required('Campo obrigatório.')
                  .test({
                    exclusive: true,
                    message: params => {
                      const strippedValue = strip(params.value);
                      if (strippedValue.length === 11) {
                        return 'CPF inválido.';
                      } else if (strippedValue.length === 14) {
                        return 'CNPJ inválido.';
                      }
                      return 'CPF/CNPJ inválido.';
                    },
                    name: 'CPF-CNPJ',
                    test: value => {
                      if (value) {
                        return CNPJ.isValid(value) || CPF.isValid(value);
                      }
                      return true;
                    }
                  }),
                nome: Yup.string()
                  .nullable()
                  .required('Favor informar o nome do proprietário')
                  .test('nome', 'Insira o nome e sobrenome do proprietário', value => {
                    if (value && value.length > 0) {
                      value = value.trim();
                      return value.includes(' ');
                    }
                  })
                  .test('nome', 'Caracteres inválidos', value => {
                    let caracteresEspeciais = [`'`, `*`, `"`];
                    return validaCaracteresEspeciais(value, caracteresEspeciais);
                  }),
                cep: Yup.string()
                  .nullable()
                  .required('Favor informar o CEP'),
                logradouro: Yup.string()
                  .nullable()
                  .required('Favor informar a rua'),
                numero: Yup.string()
                  .nullable()
                  .required('Favor informar o número')
                  .test('numero', 'Informe o valor correto do número', value => {
                    return value && value.length <= 10;
                  }),
                complemento: Yup.string(),
                bairro: Yup.string()
                  .nullable()
                  .required('Favor informar o bairro'),
                cidade: Yup.string()
                  .nullable()
                  .required('Favor buscar outro CEP para preencher a cidade'),
                uf: Yup.string()
                  .nullable()
                  .required('Favor buscar outro CEP para preencher o estado'),
                valor_imovel: Yup.string()
                  .nullable()
                  .required('Favor informar o valor do imóvel')
                  .test('valor_imovel', 'Valor informado do imóvel é inválido', value => {
                    if (value && value.length > 0) {
                      let newValue = unmaskValue(value).toString();
                      return value && newValue.length <= 20 && value && newValue > 0;
                    }
                  }),
                tipo_imovel: Yup.string()
                  .nullable()
                  .required('Favor informar o tipo do imóvel')
              })}
              onSubmit={handleFormSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              render={({ values, isSubmitting }) => {
                return (
                  <Form>
                    <ConnectedFocusError />
                    <ToggleButtonMain
                      titulo="Selecione o perfil do proprietário do imóvel"
                      nomeInput="input"
                      nome="pessoa_politicamente_exposta"
                      funcaoHandleChange={setPessoaProprietaria}
                      atributosBotao={{ disabled: isPending || isPendingCEP || isSubmitting }}
                      atributoHandle={pessoaProprietaria}
                      botoes={[
                        { valor: 'PF', label: 'Pessoa física', col: 6, xs: 12 },
                        { valor: 'PJ', label: 'Pessoa jurídica', col: 6, xs: 12 }
                      ]}
                    />
                    <Field
                      type="text"
                      name="nome"
                      titulo="Nome do proprietário"
                      id="nome"
                      size="small"
                      uppercase={true}
                      component={InputMain}
                      autoComplete="off"
                      disabled={isPending || isPendingCEP || isSubmitting}
                      inputProps={{
                        maxLength: 100
                      }}
                    />
                    <Field
                      type="text"
                      name="cpfCnpj"
                      titulo={`${pessoaProprietaria === 'PF' ? 'CPF' : 'CNPJ'} do proprietário`}
                      id="cpfCnpj"
                      size="small"
                      uppercase={true}
                      component={InputMain}
                      autoComplete="off"
                      disabled={isPending || isPendingCEP || isSubmitting}
                      tipoMascara={`${pessoaProprietaria === 'PF' ? 'CPF' : 'CNPJ'}Mask`}
                    />
                    <Grid container spacing={2}>
                      <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Field
                          type="text"
                          name="cep"
                          titulo="CEP"
                          id="cep"
                          size="small"
                          uppercase={true}
                          component={InputMain}
                          erro={erroCEPNaoEncontrado ? 'CEP não encontrado' : ''}
                          autoComplete="off"
                          disabled={isPending || isPendingCEP || isSubmitting}
                          tipoMascara="CEPMask"
                        />

                        {erroCEPNaoEncontrado && (
                          <a
                            className="mt-2 font-size-1-rem"
                            disabled={isPending || isPendingCEP || isSubmitting}
                            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Não sei o CEP
                          </a>
                        )}
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <ButtonMain
                          tipoBotao="azul-escuro"
                          disabled={isPending || isPendingCEP || isSubmitting}
                          className="margin-top-label"
                          onClick={() => buscarCEP(values)}
                        >
                          {onlySmallScreen ? 'Buscar' : 'Buscar CEP'}
                        </ButtonMain>
                      </Grid>
                    </Grid>
                    <Field
                      type="text"
                      name="logradouro"
                      titulo="Endereço"
                      id="logradouro"
                      size="small"
                      uppercase={true}
                      component={InputMain}
                      autoComplete="off"
                      disabled={isPending || isPendingCEP || isSubmitting}
                    />
                    <Grid container spacing={2}>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Field
                          type="number"
                          name="numero"
                          titulo="Número"
                          id="numero"
                          size="small"
                          uppercase={true}
                          component={InputMain}
                          autoComplete="off"
                          disabled={isPending || isPendingCEP || isSubmitting}
                        />
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                        <Field
                          type="text"
                          name="complemento"
                          titulo="Complemento"
                          id="complemento"
                          size="small"
                          uppercase={true}
                          component={InputMain}
                          autoComplete="off"
                          disabled={isPending || isPendingCEP || isSubmitting}
                          inputProps={{
                            maxLength: 50
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Field
                      type="text"
                      name="bairro"
                      titulo="Bairro"
                      id="bairro"
                      size="small"
                      uppercase={true}
                      component={InputMain}
                      autoComplete="off"
                      disabled={isPending || isPendingCEP || isSubmitting}
                    />
                    <Field
                      type="text"
                      name="cidade"
                      titulo="Cidade"
                      id="cidade"
                      size="small"
                      uppercase={true}
                      component={InputMain}
                      autoComplete="off"
                      disabled={isPending || isPendingCEP || isSubmitting}
                      InputProps={{
                        readOnly: true
                      }}
                      inputClassName="fundo-cor-cinza-claro"
                    />
                    <Field
                      type="text"
                      name="uf"
                      titulo="Estado"
                      id="uf"
                      size="small"
                      uppercase={true}
                      component={InputMain}
                      autoComplete="off"
                      disabled={isPending || isPendingCEP || isSubmitting}
                      InputProps={{
                        readOnly: true
                      }}
                      inputClassName="fundo-cor-cinza-claro"
                    />
                    <Field
                      type="text"
                      name="valor_imovel"
                      titulo="Valor do imóvel"
                      id="valor_imovel"
                      size="small"
                      uppercase={true}
                      component={InputMain}
                      autoComplete="off"
                      disabled={isPending || isPendingCEP || isSubmitting}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                      }}
                      tipoMascara="number-float"
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        maxLength: 21
                      }}
                    />
                    <Field
                      name="tipo_imovel"
                      titulo="Tipo do imóvel"
                      id="tipo_imovel"
                      size="small"
                      component={SelectMain}
                      autoComplete="off"
                      disabled={isPending || isPendingCEP || isSubmitting}
                      opcoes={tiposImovelGarantia}
                    />

                    <Grid container spacing={3} className="pad-topbot-50">
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                      >
                        {alterandoGarantia && (
                          <ButtonMain
                            tipoBotao="transparente"
                            disabled={isPending || isPendingCEP || isSubmitting}
                            onClick={() => {
                              setAlterandoGarantia(false);
                            }}
                          >
                            Voltar
                          </ButtonMain>
                        )}
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                      >
                        <ButtonMain
                          type="submit"
                          tipoBotao="principal"
                          disabled={isPending || isPendingCEP || isSubmitting}
                        >
                          Continuar
                        </ButtonMain>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            />
          ) : (
            <>
              <Box className="borda-1 borda-cor-complementar pad-topbot-20 pad-right-left-15 border-radius-8">
                <Grid container spacing={1}>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={8}>
                    <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                      <b>{strip(dadosGarantia.cpfCnpj).length > 11 ? 'CNPJ ' : 'CPF '}</b>
                      <b>proprietário: </b>
                      {conformToMask(dadosGarantia.cpfCnpj, masks.CPFCNPJMask).conformedValue}
                    </Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                    <ButtonMain
                      tipoBotao="transparente"
                      disabled={isPending || isPendingDocumento}
                      classesAdicional="min-width-none"
                      onClick={() => {
                        abrirAlterarGarantia();
                      }}
                    >
                      <CreateOutlinedIcon />
                    </ButtonMain>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                    <ButtonMain
                      tipoBotao="transparente"
                      disabled={isPending || isPendingDocumento}
                      classesAdicional="min-width-none"
                      onClick={() => {
                        setModalDeletarGarantia(true);
                      }}
                    >
                      <DeleteForeverOutlinedIcon />
                    </ButtonMain>
                  </Grid>
                </Grid>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Nome proprietário: </b>
                  {dadosGarantia.nome}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>CEP: </b>
                  {dadosGarantia.cep}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Endereço: </b>
                  {dadosGarantia.logradouro}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Número: </b>
                  {dadosGarantia.numero}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Complemento: </b>
                  {dadosGarantia.complemento ? dadosGarantia.complemento : ''}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Bairro: </b>
                  {dadosGarantia.bairro}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Cidade: </b>
                  {dadosGarantia.cidade}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Estado: </b>
                  {dadosGarantia.uf}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Valor imóvel: </b>
                  <span>R$ </span>
                  {unmaskValue(dadosGarantia.valor_imovel).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Tipo imóvel: </b>
                  {tiposImovelGarantia[dadosGarantia.tipo_imovel] !== undefined
                    ? tiposImovelGarantia[dadosGarantia.tipo_imovel].label
                    : 'indefinido'}
                </Typography>
              </Box>

              <Box sx={{ p: '45px 0px 10px 0px' }}>
                <Typography variant="h5" className="texto-cor-principal texto-negrito texto-centro">
                  Envie aqui o comprovante de endereço do imóvel.
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
                  São aceitos documentos emitidos por concessionária (água, luz, telefone...) com no
                  máximo 30 dias de emissão. O arquivo deve ser enviado no formato PDF até 30MB.
                </Typography>
              </Box>

              <Box className="borda-1 borda-cor-suporte pad-15 border-radius-8 margin-bottom-15">
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5 texto-negrito">
                  Para enviar os arquivos corretamente siga as instruções:
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <WbSunnyOutlinedIcon className="font-size-1-rem" />
                  <span className="pad-5">Escolha um ambiente com boa iluminação e sem sombras</span>
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <InfoOutlinedIcon className="font-size-1-rem" />
                  <span className="pad-5">
                    Retire a proteção plástica do documento para evitar reflexos
                  </span>
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <LockOutlinedIcon className="font-size-1-rem" />
                  <span className="pad-5">
                    Não se preocupe! Coletamos e tratamos seus dados para fins de identificação,
                    autenticação e prevenção de fraudes. Esse processo é 100% confidencial
                  </span>
                </Typography>
              </Box>

              <Box sx={isPending ? { position: 'relative', p: '25px 0 0 0' } : { p: '25px 0 0 0' }}>
                {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                {documentosGarantia.length !== 0 &&
                  documentosGarantia.map((doc, index) => {
                    var bordaBottom =
                      index + 1 === documentosGarantia.length
                        ? 'borda-transparente'
                        : 'borda-cor-cinza-claro';
                    return (
                      <DocumentoUpload
                        key={doc.id}
                        id={doc.id}
                        boxClassesAdicionais={bordaBottom}
                        isPending={isPendingDocumento}
                        setPending={setPendingDocumento}
                        listaDocs={documentosGarantia}
                        setListaDocs={setDocumentosGarantia}
                        arquivosAceitos={['pdf']}
                        tamanhoMaximoMegaAceito={30}
                      />
                    );
                  })}
                {documentosGarantia.length === 0 && (
                  <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
                    Nenhum documento encontrado
                  </Typography>
                )}
              </Box>

              <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

              <Grid container spacing={3} className="pad-topbot-20">
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                />
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                  <ButtonMain
                    type="button"
                    tipoBotao="principal"
                    disabled={
                      isPending ||
                      isPendingDocumento ||
                      dadosGarantia === undefined ||
                      Object.keys(dadosGarantia).length === 0
                    }
                    onClick={() => confirmarGarantia()}
                  >
                    Confirmar
                  </ButtonMain>
                </Grid>
              </Grid>
            </>
          )}

          <Dialog
            open={modalDeletarGarantia}
            keepMounted
            onClose={() => setModalDeletarGarantia(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
              Excluir dados do imóvel
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ao confirmar esta ação todos as informações do imóvel serão excluidas do cadastro. Você
                quer mesmo continuar?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xl={6} lg={6} md={4} sm={2} />
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  <ButtonMain tipoBotao="transparente" onClick={() => setModalDeletarGarantia(false)}>
                    Cancelar
                  </ButtonMain>
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  <ButtonMain tipoBotao="azul-escuro" onClick={deletarGarantia} autoFocus>
                    Confirmar
                  </ButtonMain>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
    </Grid>
  );
};
