import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import stringDotNotationToObject from '../../../../utils/stringDotNotationToObject';

const ReactStrapFormikInput = ({
  field: { value = '', ...fields },
  // eslint-disable-next-line no-unused-vars
  form: { touched, errors, ...rest },
  ...props
}) => {
  const { labelClassName, label, id, additional, helper, uppercase = false, ...restProps } = props;
  const error = fields.name.includes('.')
    ? stringDotNotationToObject(fields.name, errors)
    : (errors || {})[fields.name];

  const touchedField = fields.name.includes('.')
    ? stringDotNotationToObject(fields.name, touched)
    : (touched || {})[fields.name];

  if (uppercase && typeof value === 'string') {
    value = value.toUpperCase();
  }
  // quando o valor não for passado, settar como string vazia
  if (!value) {
    value = '';
  }

  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id} className={`${labelClassName ? labelClassName : ''}`}>
          {label}
        </Label>
      )}
      <Input {...restProps} {...fields} id={id} value={value} invalid={!!(touchedField && error)} />
      {touchedField && error ? <FormFeedback>{error}</FormFeedback> : ''}
      {additional}
      {helper && <small className="form-text text-muted">{helper}</small>}
    </FormGroup>
  );
};
export default ReactStrapFormikInput;
