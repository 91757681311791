import * as CPF from '@fnando/cpf';
import * as CNPJ from '@fnando/cnpj';
import { conformToMask } from 'react-text-mask';
import strip from '../../../../../utils/strip';
import { States } from '../../../../../shared';
import { CMC7Mask } from '../../../../../shared/masks/masks';
import { unmaskValue } from '../../../../../utils/format';

export const toPayload = values => {
  const {
    sacado: { endereco, telefone, cpfCnpj, nome, ...other },
    ...rest
  } = values;

  const phone = telefone ? { ddd: strip(telefone).substr(0, 2), fone: strip(telefone).substr(2) } : null;

  const payload = {
    ...rest
  };

  if (payload.cheque) {
    payload.cheque.valor = unmaskValue(payload.cheque.valor);
    payload.cheque.cmc7 = conformToMask(payload.cheque.cmc7, CMC7Mask).conformedValue;
  } else {
    payload.duplicata.valor = unmaskValue(payload.duplicata.valor);
  }

  if (CPF.isValid(cpfCnpj)) {
    payload.sacado = {
      tipo: 'fisica',
      fisica: {
        cpf: CPF.strip(cpfCnpj),
        enderecos: [endereco],
        telefones: [phone],
        nome,
        ...other
      }
    };
  } else {
    payload.sacado = {
      tipo: 'juridica',
      juridica: {
        cnpj: CNPJ.strip(cpfCnpj),
        enderecos: [endereco],
        telefones: [phone],
        razaoSocial: nome,
        nomeFantasia: nome,
        ...other
      }
    };
  }

  if (values.cedente) {
    const phoneCedente = values.cedente.telefone
      ? {
          ddd: strip(values.cedente.telefone).substr(0, 2),
          fone: strip(values.cedente.telefone).substr(2)
        }
      : null;

    payload.cedente = {
      cpfCnpj: CPF.isValid(values.cedente.cpfCnpj)
        ? CPF.strip(values.cedente.cpfCnpj)
        : CNPJ.strip(values.cedente.cpfCnpj),
      endereco: [values.cedente.endereco],
      telefone: [phoneCedente],
      nome: values.cedente.nome,
      eCliente: values.cedente.eCliente,
      email: values.cedente.email
    };
  }

  return payload;
};

export default () =>
  States.map(state => {
    return { value: state.sigla, label: state.sigla };
  });
