import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 45.6">
    <path
      d="M19.3,5.7v8.6H3.5V5.7H19.3m2-3.5H1.5A1.47,1.47,0,0,0,0,3.7V16.3a1.42,1.42,0,0,0,1.5,1.5H21.3a1.42,1.42,0,0,0,1.5-1.5V3.7a1.54,1.54,0,0,0-1.5-1.5ZM46.5,35.7v8.6H30.7V35.7H46.5m2-3.5H28.7a1.42,1.42,0,0,0-1.5,1.5V46.3a1.42,1.42,0,0,0,1.5,1.5H48.5A1.47,1.47,0,0,0,50,46.3V33.7a1.54,1.54,0,0,0-1.5-1.5Zm-2-26.5V24.8H30.7V5.7H46.5m2-3.5H28.7a1.42,1.42,0,0,0-1.5,1.5V26.8a1.42,1.42,0,0,0,1.5,1.5H48.5A1.47,1.47,0,0,0,50,26.8V3.7a1.54,1.54,0,0,0-1.5-1.5Zm-29.2,23V44.3H3.5V25.2H19.3m2-3.5H1.5A1.42,1.42,0,0,0,0,23.2V46.3a1.42,1.42,0,0,0,1.5,1.5H21.3a1.42,1.42,0,0,0,1.5-1.5V23.2a1.54,1.54,0,0,0-1.5-1.5Z"
      transform="translate(0 -2.2)"
    />
  </svg>
);
