import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Add } from '@mui/icons-material';
import ButtonMain from '../../../shared/ButtonMain';
import ToggleButtonMain from '../../../shared/ToggleButtonMain';
import DocumentoUpload from '../../../shared/DocumentoUpload';
import LoaderMain from '../../../shared/LoaderMain';
import AlertErroMain from '../../../shared/AlertErroMain';
import {
  getListaTiposEmpresa,
  getDocumentosRequeridos,
  postConfirmarDocumentos,
  getListaTiposDocumentos
} from '../../../../../api/onboarding';

export default ({
  avancarEtapa,
  isPending,
  setPending,
  cnpjEmpresa,
  origemProposta = 'digital',
  codEntrada = null
}) => {
  const [listaTiposEmpresa, setListaTiposEmpresa] = useState([]);
  const [tipoEmpresa, setTipoEmpresa] = useState(0);
  const [erroTipoEmpresa, setErroTipoEmpresa] = useState('');
  const [documentosEmpresa, setDocumentosEmpresa] = useState([]);
  const [isPendingTipoEmpresas, setPendingTipoEmpresas] = useState(false);
  const [isPendingDocumento, setPendingDocumento] = useState(false);
  const [isPendingDocumentosEmpresa, setPendingDocumentosEmpresa] = useState(false);
  const [mostraErro, setMostraErro] = useState(false);
  const [tiposArquivosAceitos, setTiposArquivosAceitos] = useState(['pdf']);
  const [listaTiposDocumentos, setListaTiposDocumentos] = useState([]);
  const [listaTiposDocInseridos, setListaTiposDocInseridos] = useState([]);
  const [newDocOpen, setNewDocOpen] = useState(false);

  useEffect(() => {
    setPendingTipoEmpresas(true);

    getListaTiposEmpresa()
      .then(retorno => {
        let dadosListaTemp = {};
        if (Object.keys(retorno.data).length > 0) {
          retorno.data.dados.map(dados => {
            return (dadosListaTemp[dados.SIGLA] = {
              valor: dados.SIGLA,
              label: dados.SIGLAJORNADA,
              xl: 2,
              lg: 2,
              md: 3,
              xs: 6
            });
          });
        }
        setListaTiposEmpresa(dadosListaTemp);
      })
      .finally(() => {
        setPendingTipoEmpresas(false);
      });

    var tiposArqAceitos = ['pdf'];
    if (origemProposta === 'connect' || origemProposta === 'indicantes') {
      tiposArqAceitos = ['pdf', 'imagens', 'word', 'powerPoint', 'excel'];
    } else if (origemProposta === 'one7_mais_credito') {
      tiposArqAceitos = ['pdf', 'png', 'jpg'];
    }
    setTiposArquivosAceitos(tiposArqAceitos);
  }, []);

  const alterarTipoEmpresa = novoTipo => {
    setDocumentosEmpresa([]);
    setErroTipoEmpresa('');
    setPendingDocumentosEmpresa(true);
    setTipoEmpresa(novoTipo);

    getDocumentosRequeridos(novoTipo, origemProposta)
      .then(retorno => {
        if (retorno.status === 200 && Object.keys(retorno.data).length > 0) {
          setDocumentosEmpresa(retorno.data.documentos);
        } else {
          setDocumentosEmpresa([]);
        }
      })
      .finally(() => {
        setPendingDocumentosEmpresa(false);
      });
  };

  const confirmarDocumentosEmpresa = () => {
    if (tipoEmpresa === 0) {
      setErroTipoEmpresa('Favor escolher o tipo da empresa');
      return false;
    } else {
      setErroTipoEmpresa('');
    }

    var documentosEmpresaTemp = [...documentosEmpresa];
    var bloquearSubmit = false;
    let mostraFocus = false;
    documentosEmpresaTemp = documentosEmpresaTemp.map(doc => {
      if (doc.id === 'new_doc') {
        mostraFocus = true;
      }
      if ((doc.hash_arquivo === undefined || doc.hash_arquivo === '') && doc.obrigacao_doc) {
        bloquearSubmit = true;
        if (doc.nome_arquivo === undefined || doc.nome_arquivo === '') {
          doc.status = 'reprovado';
          doc.titulo_status = 'Pendente';
          doc.subtitulo_status = 'Favor importar um arquivo para este documento';
        }
      }
      return doc;
    });

    if (mostraFocus) {
      document.getElementById('novo_tipoDocumento').focus();
      return false;
    }

    if (bloquearSubmit) {
      setDocumentosEmpresa(documentosEmpresaTemp);
      return false;
    }

    let params = formatarPayload(documentosEmpresa);

    if (origemProposta === 'one7_mais_credito') {
      setPendingDocumentosEmpresa(true);
    } else {
      setPending(true);
    }
    postConfirmarDocumentos(params)
      .then(() => {
        avancarEtapa();
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPending(false);
        setPendingDocumentosEmpresa(false);
      });
  };

  const formatarPayload = dadosDocumentos => {
    let tempDocs = [];

    dadosDocumentos.map(doc => {
      if (
        typeof doc.hash_arquivo !== 'undefined' &&
        doc.hash_arquivo !== undefined &&
        doc.hash_arquivo !== null
      ) {
        let tempDoc = {
          cpf_socio: null,
          cpf_conjugue: null,
          hash: doc.hash_arquivo,
          cod_documento: doc.id_original ? doc.id_original : doc.id,
          observacao: doc.observacao ? doc.observacao : null,
          tipo: null
        };
        tempDocs.push(tempDoc);
      }
      return doc;
    });

    let params = {
      cnpj: cnpjEmpresa,
      cod_entrada: codEntrada,
      origem_proposta: origemProposta,
      tipo_empresa: tipoEmpresa,
      valida_documento: true,
      envia_email: false,
      documentos: tempDocs
    };

    return params;
  };

  const buscaTiposDocumentos = () => {
    getListaTiposDocumentos()
      .then(retornoTipos => {
        let ret = retornoTipos.data;
        if (ret.dados && ret.dados.length > 0) {
          setListaTiposDocumentos(ret.dados);
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      });
  };

  useEffect(() => {
    if (origemProposta === 'connect' || origemProposta === 'indicantes') {
      if (documentosEmpresa.length > 0 && listaTiposDocumentos.length === 0) {
        buscaTiposDocumentos();
      }

      let newDoc = false;
      let lstTiposDocInsTemp = [...listaTiposDocInseridos];
      documentosEmpresa.map(doc => {
        if (doc.id === 'new_doc') newDoc = true;
        if (doc.status !== 'reprovado' && doc.id !== 'new_doc')
          lstTiposDocInsTemp.push(doc.cod_documento);
        return doc;
      });
      setNewDocOpen(newDoc);
      setListaTiposDocInseridos(lstTiposDocInsTemp);
    }
  }, [documentosEmpresa]);

  const salvarNovoDocumento = (indexArquivo, tipoNovoDoc) => {
    if (origemProposta === 'connect' || origemProposta === 'indicantes') {
      if (tipoNovoDoc && tipoNovoDoc.CODPPCDOCUMENTO && documentosEmpresa[indexArquivo].hash_arquivo) {
        let newListaDocs = documentosEmpresa.map(doc => {
          if (doc.id === 'new_doc') {
            doc.cnpj = doc.cpfcnpj;
            doc.descricao = tipoNovoDoc.DESCRICAO;
            doc.explicacao_doc = tipoNovoDoc.DESCRICAO;
            doc.nome = tipoNovoDoc.NOME;
            doc.nome_doc = tipoNovoDoc.NOME;
            doc.obrigacao_doc = false;
            doc.requerido = false;
            doc.id = tipoNovoDoc.CODPPCDOCUMENTO + `_${documentosEmpresa.length}`;
            doc.id_original = tipoNovoDoc.CODPPCDOCUMENTO;
            delete doc['cpfcnpj'];
            delete doc['novo_doc_lista'];
          }
          return doc;
        });

        setDocumentosEmpresa(newListaDocs);
      }
    }
  };

  const clickDocumento = (indexArquivo, arquivo) => {
    let arqSelected = { ...arquivo };
    if (arqSelected.novo_doc_lista === undefined || arqSelected.novo_doc_lista.length === 0) {
      arqSelected.indexArquivo = indexArquivo;
    }
  };

  const novoDocumento = () => {
    if (newDocOpen || documentosEmpresa === undefined) return false;
    let listaDocsTemp = [...documentosEmpresa];
    let lstTiposDoc = listaTiposDocumentos.filter(
      tipoDoc => listaTiposDocInseridos.includes(tipoDoc.CODPPCDOCUMENTO) === false
    );
    listaDocsTemp.unshift({ id: 'new_doc', novo_doc_lista: lstTiposDoc });
    setDocumentosEmpresa(listaDocsTemp);
  };

  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box
          className="mt-2"
          sx={
            isPendingTipoEmpresas
              ? { position: 'relative', p: '15px 0 0 0', minHeight: '150px' }
              : { p: '15px 0 0 0' }
          }
        >
          {isPendingTipoEmpresas && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <ToggleButtonMain
            titulo="Tipo de empresa"
            tipo="input"
            nomeInput="tipo_empresa"
            funcaoHandleChange={alterarTipoEmpresa}
            erro={erroTipoEmpresa}
            atributoHandle={tipoEmpresa}
            atributosBotao={{ disabled: isPending || isPendingDocumento || isPendingDocumentosEmpresa }}
            botoes={Object.values(listaTiposEmpresa)}
          />
        </Box>

        <Box
          sx={
            isPendingDocumentosEmpresa
              ? { position: 'relative', p: '15px 0 0 0', minHeight: '150px' }
              : { p: '15px 0 0 0' }
          }
        >
          {isPendingDocumentosEmpresa && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          {documentosEmpresa.length !== 0 && (
            <Box className="borda-1 borda-cor-suporte pad-15 border-radius-8 margin-bottom-35">
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5 texto-negrito">
                Para enviar os arquivos corretamente siga as instruções:
              </Typography>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                <WbSunnyOutlinedIcon className="font-size-1-rem" />
                <span className="pad-5">Escolha um ambiente com boa iluminação e sem sombras</span>
              </Typography>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                <InfoOutlinedIcon className="font-size-1-rem" />
                <span className="pad-5">
                  Retire a proteção plástica do documento para evitar reflexos
                </span>
              </Typography>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                <LockOutlinedIcon className="font-size-1-rem" />
                <span className="pad-5">
                  Não se preocupe! Coletamos e tratamos seus dados para fins de identificação,
                  autenticação e prevenção de fraudes. Esse processo é 100% confidencial
                </span>
              </Typography>
            </Box>
          )}

          {documentosEmpresa.length !== 0 &&
            (origemProposta === 'connect' || origemProposta === 'indicantes') && (
              <ButtonMain
                tipoBotao="principal-texto-branco"
                disabled={newDocOpen || isPendingDocumento || listaTiposDocumentos.length === 0}
                onClick={() => novoDocumento()}
              >
                <Add />
                Novo Documento
              </ButtonMain>
            )}

          {documentosEmpresa.length !== 0 &&
            documentosEmpresa.map((doc, index) => {
              var bordaBottom =
                index + 1 === documentosEmpresa.length ? 'borda-transparente' : 'borda-cor-cinza-claro';
              return (
                <DocumentoUpload
                  key={doc.id}
                  id={doc.id}
                  boxClassesAdicionais={bordaBottom}
                  isPending={isPendingDocumento}
                  setPending={setPendingDocumento}
                  listaDocs={documentosEmpresa}
                  setListaDocs={setDocumentosEmpresa}
                  arquivosAceitos={tiposArquivosAceitos}
                  tamanhoMaximoMegaAceito={30}
                  cnpjEmpresa={cnpjEmpresa}
                  boxUploadAction={clickDocumento}
                  btnEnviarDocumento={false}
                  funcaoSalvarNovoDocumento={salvarNovoDocumento}
                  inputObservacao={origemProposta === 'connect' || origemProposta === 'indicantes'}
                />
              );
            })}

          {documentosEmpresa.length === 0 && tipoEmpresa !== 0 && !isPendingDocumentosEmpresa && (
            <Typography variant="h6" className="mt-3 texto-negrito texto-centro">
              Não foram encontrados documentos para este tipo de empresa
            </Typography>
          )}
        </Box>

        <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

        <Grid container className="mt-3" spacing={3}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }} />
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
            <center>
              <ButtonMain
                tipoBotao="principal"
                disabled={
                  isPending || isPendingDocumento || isPendingDocumentosEmpresa || tipoEmpresa === 0
                }
                onClick={() => confirmarDocumentosEmpresa()}
              >
                Continuar
              </ButtonMain>
            </center>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
