export const ppcTipo = {
  CN: { valor: 1, label: 'CADASTRO NOVO' },
  RN: { valor: 2, label: 'RENOVAÇÃO' },
  RA: { valor: 3, label: 'REATIVAÇÃO' },
  SC: { valor: 4, label: 'SOLICITAÇÃO CLIENTE' }
};

export const tiposOrigemProposta = {
  INTERNO: 1,
  DIGITAL: 2,
  CONNECT: 3,
  INDICANTES: 4,
  ONE7MAISCREDITO: 5
};
