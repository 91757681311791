import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Switch, Typography } from '@mui/material';
import { Chart } from 'react-google-charts';
import { postConcentracaoSacado } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';

const ConcentracaoSacado = ({ filtroDashboard, dadosUser, loadingDashFim }) => {
  const [dadosChart, setDadosChart] = useState([['Sacado', 'Percentual']]);
  const [totalDados, setTotalDados] = useState(0);
  const [percentualSelected, setPercentualSelected] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [rowMouseOver, setRowMouseOver] = useState(false);
  const [switchConcentracao, setSwitchConcentracao] = useState(dadosUser && dadosUser.nivel !== 7);

  const options = {
    title: null,
    pieHole: 0.5,
    width: '100%',
    height: '500px',
    legend: {
      position: 'top',
      maxLines: 3,
      alignment: 'center',
      textStyle: { fontSize: 14 }
    },
    pieSliceText: 'none',
    enableInteractivity: true,
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true
    },
    colors: ['#4ABEBF', '#FF6585', '#FFC784', '#376384', '#7C7F98']
  };

  const eventos = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart();
        google.visualization.events.addListener(chart, 'onmouseover', e => {
          var { row } = e;
          row = row + 1;
          setRowMouseOver(row);
        });
        google.visualization.events.addListener(chart, 'onmouseout', () => {
          setRowMouseOver(-1);
        });
      }
    }
  ];

  useEffect(() => {
    if (rowMouseOver === false) return false;
    if (rowMouseOver >= 0 && typeof dadosChart[rowMouseOver] !== 'undefined') {
      let pctSelect = String(((dadosChart[rowMouseOver][1] / totalDados) * 100).toFixed(1));
      if (percentualSelected !== undefined && pctSelect !== percentualSelected) {
        setPercentualSelected(pctSelect);
      }
    } else {
      setPercentualSelected('');
    }
  }, [rowMouseOver]);

  useEffect(() => {
    let params = filtroDashboard ? { ...filtroDashboard } : {};
    if (params.filtrar === false) return false;

    params.tipo_concentracado = switchConcentracao ? 'C' : 'S';
    setIsPending(true);
    postConcentracaoSacado(params)
      .then(res => {
        let dadosChartTemp = [];
        let somaTotal = 0;
        if (res.data && res.data.length > 0) {
          dadosChartTemp.push(['Sacado', 'Percentual']);
          res.data.map(dados => {
            dados.soma = parseInt(dados.soma);
            somaTotal += parseFloat(dados.soma);
            let nome = dados.sacado.toLowerCase();
            nome = nome.charAt(0).toUpperCase() + nome.substr(1);
            dadosChartTemp.push([nome, dados.soma]);
            return dados;
          });
        }
        setTotalDados(somaTotal);
        setDadosChart(dadosChartTemp);
      })
      .catch(() => {
        setDadosChart(false);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('ConcentracaoSacado');
      });
  }, [filtroDashboard, switchConcentracao]);

  const handleChangeSwitch = event => {
    setSwitchConcentracao(event.target.checked);
  };

  return (
    <Card className="chart-card">
      <CardContent className="ajuste-spin-chart" sx={isPending ? { position: 'relative' } : {}}>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        {dadosChart ? (
          dadosChart.length > 1 ? (
            <>
              <Grid container>
                <Grid item xl={8} lg={8} md={8} sm={9} xs={12}>
                  <Typography
                    variant="subtitle1"
                    className="labelChartConcentracaoSacado texto-cor-cinza-escuro pad-5 texto-weight-600"
                  >
                    {`Concentração de ${switchConcentracao ? 'cedentes' : 'sacados'}`}
                  </Typography>
                </Grid>
                <Grid className="texto-centro" item xl={4} lg={4} md={4} sm={3} xs={12}>
                  <span
                    className={`${
                      !switchConcentracao ? 'texto-cor-cinza-escuro' : 'texto-cor-cinza-claro'
                    } texto-weight-600`}
                  >
                    Sacados
                  </span>
                  <Switch checked={switchConcentracao} onChange={handleChangeSwitch} />
                  <span
                    className={`${
                      switchConcentracao ? 'texto-cor-cinza-escuro' : 'texto-cor-cinza-claro'
                    } texto-weight-600`}
                  >
                    Cedentes
                  </span>
                </Grid>
              </Grid>

              <Typography variant="h5" className="chart-pie-hole-selected-value legend-position-top">
                {percentualSelected && (
                  <>
                    {percentualSelected.padStart(4, '0')}
                    <span className="percentual">%</span>
                  </>
                )}
              </Typography>
              <Chart
                className="chartConcentracaoSacado legend-position-top"
                chartType="PieChart"
                width="100%"
                height="450px"
                data={dadosChart}
                options={options}
                chartLanguage="pt-Br"
                chartEvents={eventos}
              />
            </>
          ) : (
            <div className="error">
              <h1>Nenhuma concentração de sacado encontrada</h1>
            </div>
          )
        ) : (
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ConcentracaoSacado;
