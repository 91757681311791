import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import UsuarioLogado from './UsuarioLogado/UsuarioLogado';
import one7LogoWhite from '../../assets/img/logo-one7-white.svg';
import one7LogoCollapsed from '../../assets/img/one7-logo_collapsed.png';

export default () => {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mostraFundoEscuro, setMostraFundoEscuro] = React.useState(false);
  const history = useHistory();

  return (
    <div className="header-main">
      {mostraFundoEscuro && <div className="div-fundo-escuro" />}
      <div className="img-logo-onboarding">
        {onlySmallScreen &&
        history.location.pathname !== '/cadastro' &&
        history.location.pathname !== '/authentication/login' ? (
          <img className="img-logo-coll-onboarding" src={one7LogoCollapsed} alt="ONE7" title="ONE7" />
        ) : (
          <img className="img-logo" src={one7LogoWhite} alt="ONE7" title="ONE7" />
        )}
      </div>
      <div className="header-onboarding-usuario-logado">
        <UsuarioLogado setMostraFundoEscuro={setMostraFundoEscuro} />
      </div>
    </div>
  );
};
