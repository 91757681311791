import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { Card, CardBody } from 'reactstrap';
import Main from '../Main/Main';
import DefinicaoStep from './Steps/DefinicaoStep';
import SelectionStep from './Steps/SelecaoStep';
import Dados from './Steps/DadosStep';

import { StepWizardNav, getClienteAtual } from '../../shared';
import stepDefinition from './stepDefinition';
import { getTiposInstrucao } from '../../api/instrucoes';

const IncluirNova = props => {
  const [wizardState, setWizardState] = useState({});
  const [instrucao, setInstrucao] = useState({});
  const [arrRules, setArrRUles] = useState();
  const [isPending, setIsPending] = useState(true);
  const [tiposInstrucao, setTiposIntrucao] = useState();
  // Douglas - 24/01/20 - Ao carregar a pagina, caso o tamanho da janela seja menor ou igual a 600px ja carrega o menu collapsado
  const [collapseMenuRight, setCollapseMenuRight] = useState(window.innerWidth <= 600 ? true : false);
  const [exibirAlerta, setExibirAlerta] = useState(true);
  const clienteAtual = getClienteAtual();
  const hist = useHistory();

  useEffect(() => {
    if (clienteAtual && clienteAtual.id === 'todos') {
      hist.push('/home');
    }
  }, [clienteAtual]);

  useEffect(() => {
    // https://reactjs.org/docs/hooks-rules.html#explanation
    if (!tiposInstrucao) {
      getTiposInstrucao().then(res => {
        setTiposIntrucao(res.data);
        setIsPending(false);
      });
    }
  }, []);

  const onStepChange = state => {
    setIsPending(true);
    setWizardState({ state });
  };

  const updateInstrucao = data => {
    setInstrucao(data);
  };

  const updateIsPending = pending => {
    setIsPending(pending);
  };

  const transitions = {
    enterRight: '',
    enterLeft: '',
    exitRight: '',
    exitLeft: ''
  };

  const { state: { activeStep = 1 } = {} } = wizardState;

  return (
    <Main
      title="Incluir Novo Instrução"
      className={'instrucoes-incluir instrucoes ' + (collapseMenuRight ? 'collapsed' : '')}
      {...props}
    >
      <Card>
        <CardBody>
          <StepWizard
            nav={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <StepNavigation
                collapseMenuRight={collapseMenuRight}
                setCollapseMenuRight={setCollapseMenuRight}
              />
            }
            onStepChange={onStepChange}
            isLazyMount={true}
            transitions={transitions}
            className={`wizard step-${activeStep}`}
          >
            <DefinicaoStep
              instrucao={instrucao}
              setInstrucao={updateInstrucao}
              isPending={isPending}
              setIsPending={updateIsPending}
              tiposInstrucao={tiposInstrucao}
              exibirAlerta={exibirAlerta}
              setExibirAlerta={setExibirAlerta}
              {...props}
            />
            <SelectionStep
              instrucao={instrucao}
              setInstrucao={updateInstrucao}
              isPending={isPending}
              setIsPending={updateIsPending}
              collapseMenuRight={collapseMenuRight}
              setCollapseMenuRight={setCollapseMenuRight}
              exibirAlerta={exibirAlerta}
              setExibirAlerta={setExibirAlerta}
              {...props}
            />
            <Dados
              instrucao={instrucao}
              setInstrucao={updateInstrucao}
              isPending={isPending}
              setIsPending={updateIsPending}
              collapseMenuRight={collapseMenuRight}
              setCollapseMenuRight={setCollapseMenuRight}
              arrRules={arrRules}
              setArrRUles={setArrRUles}
              {...props}
            />
            <Dados
              instrucao={instrucao}
              setInstrucao={updateInstrucao}
              isPending={isPending}
              setIsPending={updateIsPending}
              isConfirmation={true}
              collapseMenuRight={collapseMenuRight}
              setCollapseMenuRight={setCollapseMenuRight}
              arrRules={arrRules}
              setArrRUles={setArrRUles}
              {...props}
            />
          </StepWizard>
        </CardBody>
      </Card>
    </Main>
  );
};

export const StepNavigation = props => {
  const { currentStep, collapseMenuRight, setCollapseMenuRight } = props;
  return (
    <div className="row">
      <div className="col-xxl-9 col-xl-8 col-lg-7 col-md-11 col-11 align-middle">
        <StepWizardNav steps={stepDefinition} {...props} />
      </div>
      <div className="col fixed-side-info">
        {currentStep !== 1 ? (
          collapseMenuRight && (
            <button
              type="button"
              onClick={() => setCollapseMenuRight(false)}
              title="Detalhes da Instrução"
              className="btn btn-outline-secondary float-right btn-show-menu-right"
              style={{ marginTop: '30px' }}
            >
              <i className="fa fa-angle-left" />
              <span>Sua Intrução</span>
            </button>
          )
        ) : (
          <>&nbsp;</>
        )}
      </div>
    </div>
  );
};

export default IncluirNova;
