import { Col, FormGroup, Label, Row } from 'reactstrap';
import React from 'react';
import * as CNPJ from '@fnando/cnpj';

export default {
  expandByColumnOnly: true,
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return <i className="fa fa-angle-up expand-icon" />;
    }
    return <i className="fa fa-angle-down expand-icon" />;
  },
  expandHeaderColumnRenderer: () => {
    return '';
  },
  onlyOneExpanding: true,
  renderer: row => (
    <div className="expanded-container">
      <Row className="align-items-center">
        {row.tipoOperacao.codBanco ? (
          <>
            <Col md="4" sm="5" xs="12">
              <FormGroup>
                <Label>CMC7:</Label>
                <p className="form-control-static">{row.tipoOperacao.cmc7}</p>
              </FormGroup>
            </Col>
            <Col md="2" sm="3" xs="6">
              <FormGroup>
                <Label>Banco:</Label>
                <p className="form-control-static">{row.tipoOperacao.codBanco}</p>
              </FormGroup>
            </Col>
            <Col md="2" sm="3" xs="6">
              <FormGroup>
                <Label>Agência:</Label>
                <p className="form-control-static">{row.tipoOperacao.codAgencia}</p>
              </FormGroup>
            </Col>
            <Col md="2" sm="3" xs="6">
              <FormGroup>
                <Label>Conta:</Label>
                <p className="form-control-static">{row.tipoOperacao.codcc}</p>
              </FormGroup>
            </Col>
            <Col md="2" sm="3" xs="6">
              <FormGroup>
                <Label>Compensação:</Label>
                <p className="form-control-static">{row.tipoOperacao.compensacao}</p>
              </FormGroup>
            </Col>
          </>
        ) : (
          <>
            <Col md="5" sm="5" xs="12">
              <FormGroup>
                <Label>Chave DANFE:</Label>
                <p className="form-control-static">
                  {row.tipoOperacao.chaveDanfe ? row.tipoOperacao.chaveDanfe : 'Sem Chave Danfe'}
                </p>
              </FormGroup>
            </Col>
            <Col md="2" sm="2" xs="12">
              <FormGroup>
                <Label>Nota Fiscal:</Label>
                <p className="form-control-static">
                  {row.tipoOperacao.nf ? row.tipoOperacao.nf : 'Sem Nota Fiscal'}
                </p>
              </FormGroup>
            </Col>
            {row.cedente && (
              <Col md="5" sm="5" xs="12">
                <FormGroup>
                  <Label>Cedente: </Label>
                  <span className="form-control-static">{` ${CNPJ.format(row.cedente.cnpj)} `}</span>
                  <p className="form-control-static">{row.cedente.nome}</p>
                </FormGroup>
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  ),
  showExpandColumn: true
};
