export default {
  PLATAFORMA_TATUI: 1,
  PLATAFORMA_BROKERS: 2,
  PLATAFORMA_OP_ESTR: 3,
  PLATAFORMA_VALECRED: 4,
  PLATAFORMA_CAMPINAS: 5,
  PLATAFORMA_MIDAS: 6,
  PLATAFORMA_RIBEIRAO_PRETO: 7,
  PLATAFORMA_SIEGEN: 8,
  PLATAFORMA_OUTRAS: 9,
  PLATAFORMA_INTERIOR: 10,
  PLATAFORMA_TESTE: 11,
  PLATAFORMA_DIRETORIA: 13,
  PLATAFORMA_SAO_PAULO_1: 15,
  PARCERIA_LEGO: 16,
  PLATAFORMA_MARKETING: 17,
  PLATAFORMA_TESOURARIA_ANTIGA: 18,
  PLATAFORMA_DIGITAL: 19,
  PLATAFORMA_SAO_PAULO_2: 20,
  PLATAFORMA_NOVOS_NEGOCIOS: 21,
  PLATAFORMA_SUP_SAO_ROQUE: 22,
  PLATAFORMA_PAP: 23,
  PLATAFORMA_SUP_SAO_ROQUE_2: 24,
  PLATAFORMA_SAO_PAULO_3: 25,
  PLATAFORMA_PILOTO_EURO: 26,
  PLATAFORMA_TOAZUL: 27,
  ONE7_MAIS_CREDITO: 28,
  PLATAFORMA_ALIANCAS_E_PARCERIAS: 29,
  PLATAFORMA_PILOTO_MANAUS: 31,
  PLATAFORMA_PILOTO_CRICIUMA: 32,
  PLATAFORMA_HUBS_DE_EXPANSAO: 33,
  FOCO_VERTICAL: 34,
  REGIONAL: 35,
  CONSULTIVO_ESCALAVEL_PME: 36,
  KEY_ACCOUNT_MANAGEMENT: 37,
  PLATAFORMA_PARCERIA_AAX: 38,
  RAPIDOO: 39,
  PLATAFORMA_OPERACOES_ESTRUTURADAS: 40,
  PLATAFORMA_INDICANTES: 41,
  PLATAFORMA_TESOURARIA: 42,
  ANCORA: 43,
  JORNADA_DIGITAL: 44,
  PLATAFORMA_PARCERIAS: 45,
  CONNECT: 46,
  PLATAFORMA_SUPERINTENDENCIA_2: 47,
  MASTER_CONNECT_XP: 48,
  FINPLACE: 49,
  PLATAF_TESTE_PROPOSTA: 50
};
