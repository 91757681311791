import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import stringDotNotationToObject from '../../../../utils/stringDotNotationToObject';
import { moneyFullFormat, unmaskValue } from '../../../../utils/format';

const CurrencyInput = ({ field: { value, ...fields }, form: { touched, errors }, ...props }) => {
  const maskValue = (value = 0) => {
    return moneyFullFormat(unmaskValue(value));
  };

  const maskedValue = value ? maskValue(value) : '';

  const { labelClassName, label, id, ...restProps } = props;
  const error = fields.name.includes('.')
    ? stringDotNotationToObject(fields.name, errors)
    : (errors || {})[fields.name];

  const touchedField = fields.name.includes('.')
    ? stringDotNotationToObject(fields.name, touched)
    : (touched || {})[fields.name];

  return (
    <FormGroup>
      {label && (
        <Label for={id} className={`${labelClassName ? labelClassName : ''}`}>
          {label}
        </Label>
      )}
      <Input
        {...restProps}
        {...fields}
        value={maskedValue}
        id={id}
        invalid={!!(touchedField && error)}
      />
      {touchedField && error ? <FormFeedback>{error}</FormFeedback> : ''}
    </FormGroup>
  );
};
export default CurrencyInput;
