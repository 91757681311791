import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { FastField, Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import VoltarIcon from '../../../../shared/icons/Voltar';
import Avancar from '../../../../shared/icons/Avancar';

import { DateTimePicker, Radio, SkeletonLoader, CurrencyInput, Input } from '../../../../shared';
import { dateFormat, moneyFullFormat, unmaskValue } from '../../../../utils/format';

import { getMotivosInstrucao } from '../../../../api/instrucoes';

const validationSchema = (regras = {}) => {
  return Yup.lazy(() =>
    Yup.object().shape({
      dataAgendamento: Yup.string()
        .nullable()
        .required('Campo Obrigatório.')
        .test('Data', 'Data inválida', value => {
          if (value) {
            return moment(value, 'DD/MM/YYYY').isValid();
          }
          return true;
        })
        .test(
          'vencimento_entre',
          `Deve estar entre ${dateFormat(regras.dataMinima)} e ${dateFormat(regras.dataMaxima)}`,
          value => {
            const current = moment(value, 'DD/MM/YYYY');

            if (!current.isValid()) {
              return true;
            }

            const { dataMaxima, dataMinima } = regras;
            let validRange = true;
            if (dataMaxima) {
              const before = moment(dataMinima);
              const after = moment(dataMaxima);
              // REF: https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
              validRange = current.isBetween(before, after, null, '[]');
            }
            return validRange;
          }
        )
        .test('vencimento_bloqueado', 'Feriado(s) não são permitidos', value => {
          const current = moment(value, 'DD/MM/YYYY');

          if (!current.isValid()) {
            return true;
          }

          const { datasBloqueadas } = regras;
          // const workingDays = [1, 2, 3, 4, 5];
          let isHoliday = true;
          let isWeekend = true;
          // isWeekend = workingDays.includes(current.day());

          if (datasBloqueadas) {
            const currentDateISO = current.format(moment.HTML5_FMT.DATE);
            isHoliday = !datasBloqueadas.includes(currentDateISO);
          }
          return isHoliday && isWeekend;
        }),
      motivo: Yup.object().shape({
        id: Yup.string()
          .required('Campo obrigatório')
          .nullable()
      }),
      motivoId: Yup.ref('motivo.insereObservacao'),
      motivoDescricao: Yup.string().when('motivoId', {
        is: motivoId => {
          return motivoId === 'S';
        },
        then: Yup.string()
          .required('Campo obrigatório')
          .nullable()
          .test('motivo', 'Campo obrigatório', value => {
            return value && value.toString().replace(/\s/g, '') !== '';
          }),
        otherwise: Yup.string().nullable()
      }),
      abatimento: Yup.array()
        .of(
          Yup.object().shape({
            abatimento: Yup.string()
              .required('Campo obrigatório')
              .nullable()
              .test({
                name: 'Valor máximo',
                exclusive: true,
                message: 'Valor superior ao valor bruto',
                test: function(item) {
                  // eslint-disable-next-line react/no-this-in-sfc
                  const abatimentoItem = this.parent;
                  const unmaskedItem = unmaskValue(item);
                  const { valor } = abatimentoItem.titulo.duplicata || abatimentoItem.titulo.cheque;
                  return unmaskedItem < valor;
                }
              })
              .test({
                name: 'Valor mínimo',
                exclusive: true,
                message: 'Deve ser maior que 0',
                test: function(item) {
                  const unmaskedItem = unmaskValue(item);
                  return unmaskedItem > 0;
                }
              })
          })
        )
        .required('Campo obrigatório') // these constraints are shown if and only if inner constraints are satisfied
        .min(1, 'Obrigatório selecionar pelo menos 1')
    })
  );
};

const Abatimento = props => {
  const {
    nextStep,
    previousStep,
    isPending,
    setIsPending,
    instrucao,
    setInstrucao,
    getSimulacao,
    simulacao = {},
    finishStep,
    isConfirmation,
    parseValues,
    collapseMenuRight,
    setCollapseMenuRight,
    regras = {}
  } = props;
  const [motivosInstrucao, setMotivosIntrucao] = useState();
  const [itensCount, setItensCount] = useState(0);
  const [message, setMessage] = useState();
  const [form, setForm] = useState({});

  useEffect(() => {
    if (!itensCount) {
      setItensCount(instrucao.abatimento.length);
      setIsPending(false);
    }
    if (!motivosInstrucao) {
      getMotivosInstrucao(instrucao.tipoInstrucao.code).then(res => {
        setMotivosIntrucao(res.data);
      });
    }
  }, [instrucao.abatimento.length]);

  const handleFormSubmit = values => {
    return getSimulacao(values).then(res => {
      const { valorSimulado } = res.data;
      const parsedIntrucao = parseValues(values);
      const newInstrucao = {
        ...instrucao,
        ...parsedIntrucao,
        valor: valorSimulado
      };
      setInstrucao(newInstrucao);
      nextStep();
    });
  };

  const handleConfirmationFormSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    finishStep(values)
      .catch(err => {
        const {
          response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
        } = err;
        setMessage(message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    ...instrucao,
    dataAgendamento: instrucao.dataAgendamento || '',
    motivo: instrucao.motivo || { id: '', descricao: '' },
    motivoDescricao: instrucao.motivoDescricao || ''
  };

  const handleValidate = values => {
    if (typeof values.motivo !== 'object') {
      values.motivo = { id: '', descricao: '' };
      form.setFieldValue('motivo', { id: '', descricao: '' });
    }

    setInterval(() => {
      var errors = document.getElementsByClassName('is-invalid');
      if (errors && errors[0] && errors[0].focus) {
        setMessage('Existem campos com erros. Verifique-os para continuar.');
      } else {
        setMessage('');
      }
    }, 500);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(regras.dataAgendamento)}
      onSubmit={handleFormSubmit}
      validate={handleValidate}
      ref={node => setForm(node)}
      render={({
        setFieldTouched,
        values,
        setFieldValue,
        validateForm,
        isSubmitting,
        setSubmitting
      }) => {
        return (
          <Form className={isPending || isSubmitting ? 'is-submitting' : ''}>
            <div className="row row-content">
              <div
                className={
                  (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                  ' col-12 content-body'
                }
              >
                <div className="body">
                  <h1 className="title">
                    {isConfirmation && 'Confirmação dos '}
                    Dados do Abatimento
                  </h1>
                  {message && (
                    <div className="alert alert-danger animated fadeIn" role="alert">
                      {message}
                    </div>
                  )}
                  <SkeletonLoader
                    isPending={isPending}
                    width="100%"
                    height="40px"
                    count={8}
                    widthRandomness={0}
                  >
                    <div className="content-instrucoes--dados">
                      <div className="row margin-zero">
                        <div className="col-xl-3 col-lg-6 col-md-8 col-10">
                          {isConfirmation ? (
                            <div className="form-group">
                              <Label className="required-label">Agendar Pagamento</Label>
                              <div className="form-control-static">
                                {dateFormat(instrucao.dataAgendamento)}
                              </div>
                            </div>
                          ) : (
                            <FastField
                              label="Agendar Pagamento"
                              labelClassName="required-label"
                              name="dataAgendamento"
                              disabled={isPending || isSubmitting || simulacao.isPending}
                              rules={regras.dataAgendamento}
                              autoComplete="off"
                              component={DateTimePicker}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row margin-zero">
                        <div className="col-md-12">
                          {isConfirmation ? (
                            <div className="form-group">
                              <Label>Motivo</Label>
                              <div className="form-control-static">
                                {typeof instrucao.motivo === 'string'
                                  ? instrucao.motivo
                                  : instrucao.motivo.descricao}
                              </div>
                            </div>
                          ) : (
                            <FormGroup tag="fieldset" className="mb-0">
                              <legend>Motivo*</legend>
                              {motivosInstrucao &&
                                motivosInstrucao.map(motivo => {
                                  return (
                                    <React.Fragment key={motivo.id}>
                                      <Field
                                        name="motivo"
                                        checked={values.motivo && values.motivo.id === motivo.id}
                                        fieldError="id"
                                        label={motivo.descricao}
                                        value={values.motivo}
                                        onChange={() => {
                                          setFieldValue('motivo', motivo);
                                          if (!instrucao.motivoDescricao) {
                                            setFieldValue('motivoDescricao', '');
                                          }
                                        }}
                                        disabled={isPending || isSubmitting || simulacao.isPending}
                                        autoComplete="off"
                                        component={Radio}
                                      />
                                      {motivo.insereObservacao === 'S' && (
                                        <Field
                                          id="select-banco"
                                          name="motivoDescricao"
                                          component={Input}
                                          disabled={
                                            values.motivo.insereObservacao !== 'S' ||
                                            isPending ||
                                            isSubmitting
                                          }
                                          autoComplete="off"
                                        />
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                            </FormGroup>
                          )}
                        </div>
                      </div>
                      <h1 className="title pt-3 mb-2">Valores de Abatimento</h1>
                      <table className="table table-hover table-recompra-titulos">
                        <thead>
                          <tr>
                            <th>SACADO</th>
                            <th>Nº DO DOC</th>
                            <th>VENCIMENTO</th>
                            <th>VALOR BRUTO</th>
                            <th>ABATIMENTO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values &&
                            values.abatimento.map((abatimento, index) => {
                              const nome =
                                (abatimento.titulo.sacado.fisica || {}).nome ||
                                (abatimento.titulo.sacado.juridica || {}).razaoSocial ||
                                (abatimento.titulo.sacado.juridica || {}).nomeFantasia;
                              const vencimento = (
                                abatimento.titulo.cheque || abatimento.titulo.duplicata
                              ).vencimento;
                              const valor = (abatimento.titulo.cheque || abatimento.titulo.duplicata)
                                .valor;
                              const ndoc =
                                (abatimento.titulo.cheque || abatimento.titulo.duplicata).ndoc || '';

                              return (
                                <tr
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${abatimento.titulo.id}_${index}`}
                                >
                                  <td>{nome}</td>
                                  <th scope="row">{ndoc}</th>
                                  <td>{dateFormat(vencimento)}</td>
                                  <td>{moneyFullFormat(valor)}</td>
                                  <td>
                                    {isConfirmation ? (
                                      <div>{moneyFullFormat(abatimento.abatimento)}</div>
                                    ) : (
                                      <FastField
                                        id={`abatimento.${index}.abatimento.id`}
                                        name={`abatimento.${index}.abatimento`}
                                        disabled={isPending || isSubmitting || simulacao.isPending}
                                        autoComplete="off"
                                        component={CurrencyInput}
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </SkeletonLoader>
                </div>
                <div className="footer d-flex justify-content-between">
                  <Button
                    size="lg"
                    outline={true}
                    color="primary"
                    type="button"
                    disabled={isSubmitting || simulacao.isPending}
                    onClick={() => {
                      if (isConfirmation) {
                        const abatimentos = [...instrucao.abatimento];
                        let motivo = motivosInstrucao.find(motivo => {
                          return motivo.descricao === instrucao.motivo;
                        });
                        let motivoDescricao = '';

                        if (!motivo) {
                          motivo = motivosInstrucao.find(motivo => motivo.insereObservacao === 'S');
                          motivoDescricao = instrucao.motivo;
                        }

                        const newInstrucao = {
                          ...instrucao,
                          motivo,
                          motivoDescricao: motivoDescricao,
                          abatimento: abatimentos.map(abatimento => {
                            return {
                              ...abatimento,
                              abatimento: moneyFullFormat(abatimento.abatimento)
                            };
                          }),
                          dataAgendamento: moment(
                            instrucao.dataAgendamento,
                            moment.HTML5_FMT.DATE
                          ).format('DD/MM/YYYY')
                        };
                        //retornar os valores para input no step de dados
                        setInstrucao(newInstrucao);
                      }
                      previousStep();
                    }}
                  >
                    <i className="svg-icon">
                      <VoltarIcon />
                    </i>
                    Voltar
                  </Button>

                  {isConfirmation ? (
                    <Button
                      size="lg"
                      color="primary"
                      type="button"
                      disabled={isPending || isSubmitting || simulacao.isPending}
                      onClick={() => {
                        handleConfirmationFormSubmit(values, setSubmitting);
                      }}
                    >
                      <i className="svg-icon">
                        <Avancar />
                      </i>
                      Finalizar
                    </Button>
                  ) : (
                    <Button
                      size="lg"
                      color="primary"
                      type="submit"
                      disabled={isPending || isSubmitting || simulacao.isPending}
                    >
                      <i className="svg-icon">
                        <Avancar />
                      </i>
                      Avançar
                    </Button>
                  )}
                </div>
              </div>
              {!collapseMenuRight && (
                <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                  <div className="fixed-side">
                    <div
                      role="button"
                      className="header cursor-pointer"
                      onClick={() => setCollapseMenuRight(true)}
                      onKeyPress={() => setCollapseMenuRight(true)}
                      tabIndex={0}
                    >
                      <h4 className="title">
                        Sua Instrução
                        <i className="fa fa-angle-right float-right" />
                      </h4>
                    </div>
                    <div className="info infoInstrucoes">
                      <FormGroup>
                        <Label>Tipo de Instrução:</Label>
                        <p className="form-control-static">{instrucao.tipoInstrucao.descricao}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Quantidade de Títulos Selecionados:</Label>
                        <p className="form-control-static">{itensCount}</p>
                      </FormGroup>
                      <Label>Custo:</Label>
                      <SkeletonLoader
                        isPending={simulacao.isPending || isPending}
                        width="100%"
                        height="40px"
                        widthRandomness={false}
                      >
                        {simulacao.valorSimulado >= 0 || instrucao.valor >= 0 ? (
                          <p className="form-control-static total-price">
                            {moneyFullFormat(simulacao.valorSimulado || instrucao.valor)}
                          </p>
                        ) : (
                          <p>Preencha os campos ao lado para Simular Custo</p>
                        )}
                      </SkeletonLoader>
                      {!isConfirmation && (
                        <div className="footer bottom">
                          <Button
                            size="lg"
                            color="primary"
                            outline
                            disabled={isPending || isSubmitting || simulacao.isPending}
                            onClick={() => {
                              validateForm().then(errors => {
                                const isValid = Object.keys(errors).length === 0;

                                if (isValid) {
                                  getSimulacao(values);
                                } else {
                                  setFieldTouched('motivo', true);
                                  setFieldTouched('dataAgendamento', true);
                                  if (values.abatimento) {
                                    for (let index = 0; index < values.abatimento.length; index++) {
                                      setFieldTouched(`abatimento.${index}.abatimento`, true);
                                    }
                                  }
                                }
                              });
                            }}
                          >
                            Simular Custo
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default Abatimento;
