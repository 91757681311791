export default clienteAtual => {
  const config = [
    {
      dataField: 'codigo',
      text: 'CÓDIGO',
      style: { textAlign: 'center' }
    },
    {
      dataField: 'empresa',
      text: 'EMPRESA',
      style: { textAlign: 'left' }
    },
    {
      dataField: 'bordero',
      text: 'BORDERÔ',
      style: { textAlign: 'center' }
    },
    {
      dataField: 'notaFiscal',
      text: 'NOTA FISCAL',
      tipoOrdenacao: 'inteiro',
      style: { textAlign: 'center' }
    },
    {
      dataField: 'documento',
      text: 'DOCUMENTO',
      style: { textAlign: 'center' }
    },
    {
      dataField: 'debito',
      text: 'DÉBITO',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      style: { textAlign: 'right' }
    },
    {
      dataField: 'saldo',
      text: 'SALDO',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      style: { textAlign: 'right' }
    },
    {
      dataField: 'descricao',
      text: 'DESCRIÇÃO',
      style: { textAlign: 'left' }
    },
    {
      dataField: 'inclusao',
      text: 'INCLUSÃO',
      tipoOrdenacao: 'data',
      formatacao: 'data',
      style: { textAlign: 'center' }
    },
    {
      dataField: 'liquidacao',
      text: 'LIQUIDAÇÃO',
      tipoOrdenacao: 'data',
      formatacao: 'data',
      style: { textAlign: 'center' }
    }
  ];

  if (clienteAtual && clienteAtual.id === 'todos') {
    config.unshift({
      dataField: 'nomeCliente',
      text: 'CLIENTE',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    });
  }

  return config;
};
