import React, { useState, useEffect } from 'react';
import { Typography, CardContent, Grid, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import ButtonMain from '../../shared/ButtonMain';
import AlertErroMain from '../../shared/AlertErroMain';
import { postCadastrarPerfilCarteira, getDadosPerfilCarteira } from '../../../../api/onboarding';
import LoaderMain from '../../shared/LoaderMain';
import { dateFormat, formatarData, unmaskValue } from '../../../../utils/format';
import FormasPagamento from './FormasPagamento';
import MeiosPagamento from './MeiosPagamento';
import PeriodosPagamento from './PeriodosPagamento';
import Relacionamento from './Relacionamento';

export default ({ avancarEtapa, isPending, cnpjEmpresa, origemProposta }) => {
  const [dadosFormaRecPrazo, setDadosFormaRecPrazo] = useState([]);
  const [dadosFormaRecRecebivel, setDadosFormaRecRecebivel] = useState([]);
  const [dadosPerformDesconto, setDadosPerformDesconto] = useState([]);
  const [dadosIniciais, setDadosIniciais] = useState({});
  const [isPendingPerfil, setPendingPerfil] = useState(false);
  const [mostraErro, setMostraErro] = useState(false);
  const [campoValidacao, setCampoValidacao] = useState('');

  const handleFormSubmit = (values, form) => {
    setMostraErro(false);
    var dadosFormatados = validarFormatarFormulario({ ...values });
    if (typeof dadosFormatados.campoInvalido !== 'undefined') {
      setCampoValidacao(dadosFormatados.campoInvalido);
      form.setSubmitting(false);
      if (dadosFormatados.blocoValidacao === 'formaRecPrazo') {
        window.scrollTo(0, 250);
      } else if (dadosFormatados.blocoValidacao === 'FormaRecRecebivel') {
        window.scrollTo(0, 1000);
      } else if (dadosFormatados.blocoValidacao === 'performDesconto') {
        window.scrollTo(0, 2250);
      } else {
        window.scrollTo(0, 0);
      }
      return false;
    }

    var dadosForm = {
      cnpjEmpresa: cnpjEmpresa,
      origemProposta: origemProposta,
      dadosSalvar: dadosFormatados
    };

    setPendingPerfil(true);
    postCadastrarPerfilCarteira(dadosForm)
      .then(() => {
        avancarEtapa();
      })
      .catch(err => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.substring(0, 7) === 'Alerta:'
        ) {
          setMostraErro(err.response.data.error);
        } else {
          setMostraErro(true);
        }
      })
      .finally(() => {
        setPendingPerfil(false);
        form.setSubmitting(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPendingPerfil(true);
    getDadosPerfilCarteira(cnpjEmpresa)
      .then(resDados => {
        let dados = resDados.data ? resDados.data : {};
        setDadosFormaRecPrazo(dados.lstFormasPrazo ? dados.lstFormasPrazo : []);
        setDadosFormaRecRecebivel(dados.lstFormasRecebivel ? dados.lstFormasRecebivel : []);
        setDadosPerformDesconto(dados.lstPerfDesconto ? dados.lstPerfDesconto : []);
        var dadosIniciaisTemp = retornarInitialValues(
          dados.lstFormasPrazo ? dados.lstFormasPrazo : [],
          dados.lstFormasRecebivel ? dados.lstFormasRecebivel : [],
          dados.lstPerfDesconto ? dados.lstPerfDesconto : [],
          dados.relacionamento ? dados.relacionamento : []
        );
        setDadosIniciais(dadosIniciaisTemp);
      })
      .catch(() => {
        setMostraErro(true);
      })
      .finally(() => {
        setPendingPerfil(false);
      });
  }, []);

  const validarFormatarFormulario = dadosSalvar => {
    var camposInteiro = ['prazoMedio', 'diasEntrega'];
    var dadosFormatados = { ...dadosSalvar };

    Object.keys(dadosSalvar).map(keyBloco => {
      if (keyBloco === 'radio_relacionamento') {
        if (dadosSalvar[keyBloco] === 'S') {
          if (dadosSalvar.data_relacionamento !== '') {
            dadosFormatados['relacionamento'] = {
              possui_relacionamento: 'S',
              data_relacionamento: formatarData(dadosSalvar.data_relacionamento)
            };
          } else {
            dadosFormatados = {
              campoInvalido: 'data_relacionamento',
              blocoValidacao: 'data_relacionamento'
            };
          }
        } else {
          dadosFormatados['relacionamento'] = {
            possui_relacionamento: 'N',
            data_relacionamento: ''
          };
        }
      } else {
        var opcoes = dadosSalvar[`chkbx_${keyBloco}`];
        if (typeof dadosFormatados.campoInvalido === 'undefined' && opcoes) {
          var opcaoSelecionada = false;
          Object.keys(dadosSalvar[keyBloco]).map(siglaBloco => {
            if (opcoes && opcoes[siglaBloco] && typeof dadosSalvar[keyBloco][siglaBloco] !== 'boolean') {
              opcaoSelecionada = true;
              Object.keys(dadosSalvar[keyBloco][siglaBloco]).map(nomeCampo => {
                if (typeof dadosFormatados.campoInvalido === 'undefined' && nomeCampo !== 'observacao') {
                  var valor = dadosSalvar[keyBloco][siglaBloco][nomeCampo];
                  valor = camposInteiro.includes(nomeCampo) ? parseInt(valor) : unmaskValue(valor);
                  if (valor === '' || valor === null || valor === 0) {
                    dadosFormatados = {
                      campoInvalido: `${keyBloco}_${siglaBloco}_${nomeCampo}`,
                      blocoValidacao: keyBloco
                    };
                  } else if (typeof dadosFormatados.campoInvalido === 'undefined') {
                    dadosFormatados[keyBloco][siglaBloco][nomeCampo] = valor;
                  }
                }
                return nomeCampo;
              });
            }
            return siglaBloco;
          });
          if (opcaoSelecionada === false) {
            dadosFormatados = {
              campoInvalido: keyBloco,
              blocoValidacao: keyBloco
            };
          }
        }
      }
      return keyBloco;
    });
    return dadosFormatados;
  };

  const retornarInitialValues = (
    lstFormaRecPrazo,
    lstFormaRecRecebivel,
    lstPerformDesconto,
    dadosRelacionamento
  ) => {
    var dadosIniciais = {};
    dadosIniciais.chkbx_formaRecPrazo = {};
    dadosIniciais.chkbx_formaRecRecebivel = {};
    dadosIniciais.chkbx_performDesconto = {};
    dadosIniciais.radio_relacionamento = 'N';
    dadosIniciais.data_relacionamento = '';
    dadosIniciais.formaRecPrazo = {};
    dadosIniciais.formaRecRecebivel = {};
    dadosIniciais.performDesconto = {};

    if (dadosRelacionamento.possui_relacionamento) {
      dadosIniciais.radio_relacionamento = dadosRelacionamento.possui_relacionamento === 'S' ? 'S' : 'N';
      dadosIniciais.data_relacionamento = dadosRelacionamento.data_relacionamento
        ? dateFormat(dadosRelacionamento.data_relacionamento)
        : '';
      dadosIniciais.data_relacionamento =
        dadosIniciais.data_relacionamento !== 'Data Inválida' ? dadosIniciais.data_relacionamento : '';
    }

    if (dadosRelacionamento.data_relacionamento) {
      dadosIniciais.data_relacionamento =
        dateFormat(dadosRelacionamento.data_relacionamento) !== 'Data Inválida'
          ? dateFormat(dadosRelacionamento.data_relacionamento)
          : '';
    }

    lstFormaRecPrazo.map(formaPrazo => {
      var sigla = formaPrazo.siglaTipo;
      dadosIniciais.formaRecPrazo[sigla] = {};
      if (formaPrazo.code) dadosIniciais.formaRecPrazo[sigla].codigo = formaPrazo.code;
      dadosIniciais.chkbx_formaRecPrazo[sigla] = formaPrazo.code ? true : false;
      dadosIniciais.formaRecPrazo[sigla].codeTipo = formaPrazo.codeTipo;
      dadosIniciais.formaRecPrazo[sigla].percentual = parseFloat(formaPrazo.percentual);
      dadosIniciais.formaRecPrazo[sigla].ticketMedio = parseFloat(formaPrazo.ticketMedio);
      dadosIniciais.formaRecPrazo[sigla].prazoMedio = parseInt(formaPrazo.prazoMedio);
      dadosIniciais.formaRecPrazo[sigla].observacao = formaPrazo.observacao;
      return formaPrazo;
    });
    lstFormaRecRecebivel.map(formaRecebivel => {
      var sigla = formaRecebivel.siglaTipo;
      dadosIniciais.formaRecRecebivel[sigla] = {};
      if (formaRecebivel.code) dadosIniciais.formaRecRecebivel[sigla].codigo = formaRecebivel.code;
      dadosIniciais.chkbx_formaRecRecebivel[sigla] = formaRecebivel.code ? true : false;
      dadosIniciais.formaRecRecebivel[sigla].codeTipo = formaRecebivel.codeTipo;
      dadosIniciais.formaRecRecebivel[sigla].percentual = parseFloat(formaRecebivel.percentual);
      dadosIniciais.formaRecRecebivel[sigla].ticketMedio = parseFloat(formaRecebivel.ticketMedio);
      dadosIniciais.formaRecRecebivel[sigla].prazoMedio = parseInt(formaRecebivel.prazoMedio);
      if (sigla === 'CH') {
        dadosIniciais.formaRecRecebivel[sigla].percChequeVendaTerc = parseFloat(
          formaRecebivel.percChequeVendaTerc
        );
        dadosIniciais.formaRecRecebivel[sigla].percChequeVendaDireta = parseFloat(
          formaRecebivel.percChequeVendaDireta
        );
      }
      dadosIniciais.formaRecRecebivel[sigla].observacao = formaRecebivel.observacao;
      return formaRecebivel;
    });
    lstPerformDesconto.map(perfDesc => {
      var sigla = perfDesc.siglaTipo;
      dadosIniciais.performDesconto[sigla] = {};
      if (perfDesc.code) dadosIniciais.performDesconto[sigla].codigo = perfDesc.code;
      dadosIniciais.chkbx_performDesconto[sigla] = perfDesc.code ? true : false;
      dadosIniciais.performDesconto[sigla].codeTipo = perfDesc.codeTipo;
      dadosIniciais.performDesconto[sigla].percentual = parseFloat(perfDesc.percentual);
      dadosIniciais.performDesconto[sigla].diasEntrega = parseInt(perfDesc.diasEntrega);
      return perfDesc;
    });
    return dadosIniciais;
  };

  return (
    <CardContent>
      {isPendingPerfil && <LoaderMain />}
      <Grid container sx={isPendingPerfil ? { display: 'none' } : {}}>
        <Grid item xl={1} lg={1} md={1} sm={1} />
        <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
          <Box sx={{ p: '0 0 30px 0' }}>
            <Typography variant="h5" className="texto-cor-principal texto-negrito texto-centro">
              Perfil Carteira
            </Typography>
            <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
              Precisamos das informações abaixo para entender o perfil da carteira
            </Typography>
          </Box>
          {dadosFormaRecPrazo.length > 0 &&
          dadosFormaRecRecebivel.length > 0 &&
          dadosPerformDesconto.length > 0 ? (
            <Formik
              initialValues={dadosIniciais}
              enableReinitialize={true}
              onSubmit={handleFormSubmit}
              render={({ values, setFieldValue, isSubmitting }) => {
                return (
                  <Form>
                    <Relacionamento
                      values={values}
                      campoValidacao={campoValidacao}
                      setCampoValidacao={setCampoValidacao}
                      isDisabled={isPending || isSubmitting || isPendingPerfil}
                    />
                    {dadosFormaRecPrazo && dadosFormaRecPrazo.length > 0 && (
                      <FormasPagamento
                        dadosFormaRecPrazo={dadosFormaRecPrazo}
                        values={values}
                        setFieldValue={setFieldValue}
                        campoValidacao={campoValidacao}
                        setCampoValidacao={setCampoValidacao}
                        isDisabled={isPending || isSubmitting || isPendingPerfil}
                      />
                    )}
                    {dadosFormaRecRecebivel && dadosFormaRecRecebivel.length > 0 && (
                      <MeiosPagamento
                        dadosFormaRecRecebivel={dadosFormaRecRecebivel}
                        values={values}
                        setFieldValue={setFieldValue}
                        campoValidacao={campoValidacao}
                        setCampoValidacao={setCampoValidacao}
                        isDisabled={isPending || isSubmitting || isPendingPerfil}
                      />
                    )}
                    {dadosPerformDesconto && dadosPerformDesconto.length > 0 && (
                      <PeriodosPagamento
                        dadosPerformDesconto={dadosPerformDesconto}
                        values={values}
                        setFieldValue={setFieldValue}
                        campoValidacao={campoValidacao}
                        setCampoValidacao={setCampoValidacao}
                        isDisabled={isPending || isSubmitting || isPendingPerfil}
                      />
                    )}
                    <Grid container spacing={3} className="pad-topbot-50">
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                      />
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                      >
                        <ButtonMain
                          type="submit"
                          tipoBotao="principal"
                          disabled={isPending || isSubmitting || isPendingPerfil}
                        >
                          Continuar
                        </ButtonMain>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            />
          ) : (
            <Typography variant="subtitle1">
              Nenhuma proposta encontrada, favor entrar em contato com o suporte
            </Typography>
          )}
          <AlertErroMain
            exibeErro={mostraErro}
            escondeErro={setMostraErro}
            msgErro={typeof mostraErro === 'string' ? mostraErro : ''}
            titleErro={typeof mostraErro === 'string' ? 'Atenção' : ''}
            tipoAlerta={typeof mostraErro === 'string' ? 'warning' : 'error'}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};
