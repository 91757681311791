import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import {
  Cancel,
  Error,
  HighlightOff,
  CheckCircle,
  CloudUpload,
  Update,
  KeyboardArrowDown
} from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import { mimeTypesArquivo, extensoesArquivo } from './Enums/EnumsTiposArquivos';
import BoxArquivo from './BoxArquivo';
import ButtonMain from './ButtonMain';
import { uploadDocumentoPadrao, deletarDocumentoPadrao } from '../../../api/onboarding';
import AlertErroMain from './AlertErroMain';

const DocumentoUpload = ({
  id,
  listaDocs,
  setListaDocs,
  boxClassesAdicionais,
  qtdMaximaArquivos,
  arquivosAceitos,
  tamanhoMaximoMegaAceito,
  isPending,
  setPending,
  funcaoUpload,
  funcaoDeletar,
  adicional = '',
  cnpjEmpresa = '',
  bloquearUpload = false,
  tipoComponente = 'completa',
  boxUploadAction = false,
  funcaoSalvarNovoDocumento = false,
  permitirDeletar = true,
  btnEnviarDocumento = true,
  inputObservacao = false
}) => {
  const [mostraErro, setMostraErro] = useState(false);
  const [modalSalvarNovoDocumento, setModalSalvarNovoDocumento] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [indexArquivo, setIndexArquivo] = useState(false);
  const [draggingFile, setDraggingFile] = useState(-1);
  const [pastedNovoDoc, setPastedNovoDoc] = useState(false);
  const [valueNovoDoc, setValueNovoDoc] = useState('');
  const [tipoNovoDoc, setTipoNovoDoc] = useState(false);
  const [configDropZone, setConfigDropZone] = useState({});
  const [mimeTypesAceitos, setMimeTypesAceitos] = useState([]);
  const [statusArquivo, setStatusArquivo] = useState({
    status: 'pendente',
    titulo_status: 'Pendente',
    subtitulo_status: ''
  });
  const inputReference = useRef(null);
  const [tamanhoResponsividade, setTamanhoResponsividade] = useState(5);
  const [textoInputObservacao, setTextoInputObservacao] = useState('');

  useEffect(() => {
    var configDropZoneTemp = {};
    var mimeTypesAceitosTemp = [];
    if (qtdMaximaArquivos && qtdMaximaArquivos !== 0) configDropZoneTemp.maxFiles = qtdMaximaArquivos;
    if (tamanhoMaximoMegaAceito && tamanhoMaximoMegaAceito !== 0)
      configDropZoneTemp.maxSize = tamanhoMaximoMegaAceito * 1024 * 1024;
    if (arquivosAceitos && arquivosAceitos.length !== 0) {
      configDropZoneTemp.accept = [];
      arquivosAceitos.map(tipo => {
        configDropZoneTemp.accept = [...configDropZoneTemp.accept, ...extensoesArquivo[tipo]];
        mimeTypesAceitosTemp = [...mimeTypesAceitosTemp, ...mimeTypesArquivo[tipo]];
        return tipo;
      });
      setConfigDropZone(configDropZoneTemp);
      setMimeTypesAceitos(mimeTypesAceitosTemp);
    }
    setTamanhoResponsividade(btnEnviarDocumento ? 5 : 6);
  }, []);

  useEffect(() => {
    listaDocs.map((doc, index) => {
      if (id === doc.id) setIndexArquivo(index);
      return doc;
    });
  }, [listaDocs]);

  useEffect(() => {
    if (
      indexArquivo !== false &&
      listaDocs[indexArquivo] &&
      listaDocs[indexArquivo].status !== undefined
    ) {
      let status = {};
      status.status = listaDocs[indexArquivo].status;
      status.titulo_status = listaDocs[indexArquivo].titulo_status;
      status.subtitulo_status = listaDocs[indexArquivo].subtitulo_status;
      setStatusArquivo(status);
    } else if (
      indexArquivo !== false &&
      listaDocs[indexArquivo] &&
      listaDocs[indexArquivo].hash_arquivo !== undefined
    ) {
      let status = {};
      status.status = 'aprovado';
      status.titulo_status = 'Enviado';
      status.subtitulo_status = '';
      setStatusArquivo(status);
    } else {
      let status = {};
      status.status =
        listaDocs[indexArquivo] && listaDocs[indexArquivo].status
          ? listaDocs[indexArquivo].status
          : 'pendente';
      status.titulo_status =
        listaDocs[indexArquivo] && listaDocs[indexArquivo].titulo_status
          ? listaDocs[indexArquivo].titulo_status
          : 'Pendente';
      status.subtitulo_status =
        listaDocs[indexArquivo] && listaDocs[indexArquivo].subtitulo_status
          ? listaDocs[indexArquivo].subtitulo_status
          : '';
      setStatusArquivo(status);
    }
  }, [indexArquivo, listaDocs]);

  const filesAcceptedHandler = acceptedFiles => {
    setDraggingFile(-1);
    var cnpjEmp = listaDocs[indexArquivo].cnpj ? listaDocs[indexArquivo].cnpj : cnpjEmpresa;
    var acceptedFile = acceptedFiles[0];
    const dataFile = new FormData();
    dataFile.append('arquivo', acceptedFile);
    dataFile.append('cnpj', cnpjEmp);

    let listaDocsTemp = [...listaDocs];
    listaDocsTemp[indexArquivo].nome_arquivo = acceptedFile.name;
    listaDocsTemp[indexArquivo].tamanho_arquivo = acceptedFile.size;
    listaDocsTemp[indexArquivo].cpfcnpj = cnpjEmp;
    delete listaDocsTemp[indexArquivo].status;
    delete listaDocsTemp[indexArquivo].titulo_status;
    delete listaDocsTemp[indexArquivo].subtitulo_status;
    setListaDocs(listaDocsTemp);

    setStatusArquivo({ status: 'carregando', titulo_status: 'Carregando...', subtitulo_status: '' });

    var realizarUpload = uploadDocumentoPadrao;
    if (typeof funcaoUpload === 'function') {
      realizarUpload = funcaoUpload;
    }
    setPending(true);
    setLoadingUpload(0);
    realizarUpload(dataFile, cnpjEmp, progressValue => setLoadingUpload(progressValue - 30))
      .then(res => {
        if (res.data && res.data.hash) {
          listaDocsTemp[indexArquivo].hash_arquivo = res.data.hash;
          if (res.data && res.data.hash) {
            listaDocsTemp[indexArquivo].link_arquivo = res.data.path;
          }
          setListaDocs(listaDocsTemp);

          setLoadingUpload(75);
          setTimeout(() => {
            setLoadingUpload(90);
            setTimeout(() => {
              setLoadingUpload(100);
              setTimeout(() => {
                setPending(false);
                setLoadingUpload(false);
                setStatusArquivo({
                  status: 'aprovado',
                  titulo_status: 'Enviado',
                  subtitulo_status: ''
                });

                // IRA CHAMAR A FUNCAO PARA ATUALIZAR/SALVAR A LISTA ORIGINAL DE ARQUIVOS
                // SOMENTE QUANDO NAO MOSTRAR O BOTAO ENVIAR
                if (!btnEnviarDocumento) {
                  funcaoSalvarNovoDocumento(indexArquivo, tipoNovoDoc.documento);
                }
              }, 100);
            }, 300);
          }, 400);
        }
      })
      .catch(err => {
        acceptedFiles.response = err.response;
        acceptedFiles.statusCode = (err.response && err.response.status) || 500;
        var retorno = err.response && err.response.data ? err.response.data : '';
        var erroConhecido = '';
        erroConhecido =
          retorno.exception && retorno.exception.indexOf('PostTooLargeException') !== -1
            ? 'Arquivo ultrapassa o tamanho máximo permitido'
            : erroConhecido;
        erroConhecido =
          retorno.errors && retorno.errors.arquivo && retorno.errors.arquivo[0]
            ? retorno.errors.arquivo[0]
            : erroConhecido;
        setStatusArquivo({
          status: 'reprovado',
          titulo_status: erroConhecido !== '' ? 'Pendente' : 'Erro ao enviar arquivo',
          subtitulo_status: erroConhecido !== '' ? erroConhecido : 'Favor contatar nosso suporte'
        });
        setLoadingUpload(false);
        setPending(false);
        setMostraErro(err.response);
      });
  };

  const filesRejectedHandler = file => {
    setDraggingFile(-1);
    const rejectedFile = file[0];
    const _typeFile = rejectedFile.type;
    const _fileTypesAccepted = configDropZone.accept;
    const _extension = rejectedFile.name.split('.');
    const tamanhoMaximoBytesAceito = tamanhoMaximoMegaAceito * 1024 * 1024;
    let _errorMessage = 'Arquivo invalidado por erro desconhecido';
    if (_fileTypesAccepted.includes(`.${_extension[_extension.length - 1]}`) === false) {
      _errorMessage = `O tipo de arquivo ${_extension[
        _extension.length - 1
      ].toUpperCase()} não é permitido`;
    } else if (mimeTypesAceitos.includes(_typeFile) === false) {
      _errorMessage = 'O tipo interno do arquivo não é permitido';
    } else if (rejectedFile.size > tamanhoMaximoBytesAceito) {
      _errorMessage = `Arquivo ultrapassa o tamanho máximo de ${tamanhoMaximoMegaAceito}MB`;
    }

    setStatusArquivo({
      status: 'reprovado',
      titulo_status: 'Pendente',
      subtitulo_status: _errorMessage
    });
  };

  const excluirDocumento = () => {
    var deletarArquivo = deletarDocumentoPadrao;
    if (typeof funcaoDeletar === 'function') {
      funcaoDeletar(listaDocs[indexArquivo].hash_arquivo, indexArquivo);
      return true;
    }
    if (listaDocs[indexArquivo].hash_arquivo) {
      setPending(true);
      setLoadingUpload(10);
      setTimeout(() => {
        setLoadingUpload(50);
      }, 400);
      deletarArquivo(listaDocs[indexArquivo].hash_arquivo, indexArquivo)
        .then(() => {
          setLoadingUpload(75);
          setTimeout(() => {
            setLoadingUpload(100);
            setTimeout(() => {
              setPending(false);
              setLoadingUpload(false);
              removerArquivo(indexArquivo);
            }, 200);
          }, 400);
        })
        .catch(err => {
          setStatusArquivo({
            status: 'reprovado',
            titulo_status: 'Erro ao deletar',
            subtitulo_status: 'Favor contatar nosso suporte'
          });
          setLoadingUpload(false);
          setPending(false);
          setMostraErro(err.response);
        });
    } else {
      removerArquivo(indexArquivo);
    }
  };

  const removerArquivo = indexArquivo => {
    if (listaDocs[indexArquivo]) {
      var listaDocsTemp = [...listaDocs];
      delete listaDocsTemp[indexArquivo].nome_arquivo;
      delete listaDocsTemp[indexArquivo].link_arquivo;
      delete listaDocsTemp[indexArquivo].tamanho_arquivo;
      delete listaDocsTemp[indexArquivo].hash_arquivo;
      setListaDocs(listaDocsTemp);
      setStatusArquivo({
        status: 'pendente',
        titulo_status: 'Pendente',
        subtitulo_status: ''
      });
    }
  };

  const dropZoneEnter = indexArq => {
    if (draggingFile === -1 || draggingFile !== indexArq) {
      setDraggingFile(indexArq);
    }
  };

  const dropZoneLeave = () => {
    if (draggingFile !== -1) {
      setDraggingFile(-1);
    }
  };

  const handleOnChangeTipoDocNovoDoc = (ev, newValue) => {
    setValueNovoDoc(newValue && newValue.label ? newValue.label : '');
    if (
      typeof newValue === 'undefined' ||
      newValue == null ||
      newValue === 1 ||
      typeof newValue.documento.CODPPCDOCUMENTO === 'undefined'
    ) {
      setTipoNovoDoc(false);
      return;
    } else {
      setTipoNovoDoc(newValue);
    }
  };

  const handleOnChangeObservacao = ev => {
    listaDocs.map(arquivo => {
      if (arquivo.id === id) {
        arquivo.observacao = ev.target.value;
      }
      return arquivo;
    });
    setTextoInputObservacao(ev.target.value);
  };

  const handleOnChangeInputTipoDocNovoDoc = event => {
    if (event && event.target) {
      let string = event && event.target && event.target.value ? event.target.value : '';
      if (event) {
        if (pastedNovoDoc) {
          string = string
            .replaceAll('.', '')
            .replace('/', '')
            .replace('-', '');
        }
      }
      setValueNovoDoc(string);
    }
    setPastedNovoDoc(false);
  };

  const handlePasteTipoDocNovoDoc = () => {
    setPastedNovoDoc(true);
  };

  const corTextoStatus = {
    aprovado: 'texto-success',
    pendente: 'texto-warning',
    analise: 'texto-warning',
    carregando: 'texto-warning',
    reprovado: 'texto-danger'
  };

  const iconeStatus = {
    aprovado: <CheckCircle sx={{ mr: '14px', mt: '-7px' }} />,
    pendente: <Error sx={{ mr: '14px', mt: '-7px' }} />,
    analise: <Error sx={{ mr: '14px', mt: '-7px' }} />,
    carregando: <Update sx={{ mr: '14px', mt: '-7px' }} />,
    reprovado: <Cancel sx={{ mr: '14px', mt: '-7px' }} />
  };

  const clickBoxUpload = () => {
    if (boxUploadAction !== false && typeof boxUploadAction === 'function') {
      boxUploadAction(indexArquivo, listaDocs[indexArquivo]);
    }
  };

  const cancelarNovoDoc = () => {
    if (listaDocs[indexArquivo].id === 'new_doc') {
      var listaDocsTemp = [...listaDocs];
      delete listaDocsTemp.splice(indexArquivo, 1);
      setListaDocs(listaDocsTemp);
    }
  };

  return (
    <Box
      key={`doc_up_${id}`}
      id={`doc-focus-${id}${adicional}`}
      className={`borda-bottom-2 ${boxClassesAdicionais}`}
      sx={{ pb: '25px', pt: '25px' }}
      onClick={clickBoxUpload}
    >
      {indexArquivo !== false && listaDocs[indexArquivo] && (
        <Grid container spacing={2} className="items-centro-verticalmente">
          {listaDocs[indexArquivo].id === 'new_doc' ? (
            <>
              <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className="flex-box-items-centralizados">
                <IconButton
                  title="Cancelar Inclusão de Novo Documento"
                  onClick={() => cancelarNovoDoc()}
                >
                  <HighlightOff className="texto-cor-cinza-2 link-texto" />
                </IconButton>
              </Grid>
              <Grid
                item
                xl={tamanhoResponsividade}
                lg={tamanhoResponsividade}
                md={tamanhoResponsividade}
                sm={12}
                xs={12}
              >
                <Autocomplete
                  disablePortal
                  id="novo_tipoDocumento"
                  onChange={handleOnChangeTipoDocNovoDoc}
                  className="combobox_principal"
                  popupIcon={
                    <KeyboardArrowDown className="texto-cor-complementar w-1-5em font-size-2rem" />
                  }
                  onInputChange={handleOnChangeInputTipoDocNovoDoc}
                  inputValue={valueNovoDoc}
                  onPaste={handlePasteTipoDocNovoDoc}
                  options={
                    listaDocs[indexArquivo].novo_doc_lista
                      ? listaDocs[indexArquivo].novo_doc_lista.map((item, index) => {
                          return { label: parseInt(index + 1) + ' - ' + item.NOME, documento: item };
                        })
                      : []
                  }
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  autoHighlight
                  renderInput={params => (
                    <TextField
                      autoFocus
                      inputRef={inputReference}
                      {...params}
                      label="Escolha o tipo do documento"
                    />
                  )}
                  disabled={isPending}
                  noOptionsText="Nenhum tipo encontrado"
                />
              </Grid>
            </>
          ) : tipoComponente === 'completa' ? (
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              <Typography
                variant="h6"
                className={`${corTextoStatus[statusArquivo.status]} texto-negrito`}
              >
                {iconeStatus[statusArquivo.status]}
                {statusArquivo.titulo_status}
              </Typography>
              {statusArquivo.subtitulo_status !== '' && (
                <Typography variant="subtitle1" className={`${corTextoStatus[statusArquivo.status]}`}>
                  {statusArquivo.subtitulo_status}
                </Typography>
              )}
              <Typography variant="h6" className="texto-negrito texto-cor-cinza-escuro">
                {listaDocs[indexArquivo].nome_doc}
              </Typography>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro">
                {listaDocs[indexArquivo].obrigacao_doc ? '(obrigatório)' : '(opcional)'}
              </Typography>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro">
                {listaDocs[indexArquivo].explicacao_doc
                  ? listaDocs[indexArquivo].explicacao_doc.charAt(0).toUpperCase() +
                    listaDocs[indexArquivo].explicacao_doc.substr(1).toLowerCase()
                  : '---'}
              </Typography>
            </Grid>
          ) : listaDocs[indexArquivo].nome_doc ? (
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="flex-box items-centro-verticalmente"
            >
              <Typography
                variant="subtitle1"
                className="texto-negrito texto-cor-cinza-escuro"
                sx={{ ml: '60px' }}
              >
                {listaDocs[indexArquivo].nome_doc}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}

          <Grid className="component-arquivo-upload" item xl={5} lg={5} md={5} sm={12} xs={12}>
            <Box
              key={`arq_up_${id}`}
              className={`${
                listaDocs[indexArquivo].nome_arquivo === undefined
                  ? `texto-centro borda-2-width ${
                      draggingFile === indexArquivo
                        ? 'borda-dashed borda-cor-principal'
                        : 'borda-dotted borda-cor-cinza'
                    }`
                  : `borda-1 borda-cor-cinza ${
                      loadingUpload ? 'fundo-cor-cinza-claro-2' : 'box-shadow-natural-5'
                    }`
              } border-radius-8 w-100 box-upload fundo-cor-branca`}
            >
              {listaDocs[indexArquivo].nome_arquivo === undefined ? (
                <Dropzone
                  onDropAccepted={filesAcceptedHandler}
                  onDropRejected={filesRejectedHandler}
                  disabled={
                    isPending ||
                    loadingUpload !== false ||
                    bloquearUpload ||
                    (listaDocs[indexArquivo].id === 'new_doc' && !tipoNovoDoc && !btnEnviarDocumento)
                  }
                  onDragEnter={() => dropZoneEnter(indexArquivo)}
                  onDragLeave={() => dropZoneLeave()}
                  noDrag={false}
                  multiple={false} // PERMITE APENAS UM ARQUIVO
                  {...configDropZone}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      {...getRootProps()}
                      className={loadingUpload === false ? 'cursor-pointer' : 'pointer-events-none'}
                      sx={
                        tipoComponente === 'completa'
                          ? { p: '15px 30px', minHeight: '110px' }
                          : { p: '5px 15px' }
                      }
                    >
                      <input {...getInputProps()} />
                      {tipoComponente === 'completa' && (
                        <CloudUpload
                          className="texto-cor-cinza-claro w-100 font-size-2-5rem"
                          sx={{ mb: '15px' }}
                        />
                      )}
                      <Typography
                        variant="subtitle1"
                        className="texto-cor-cinza-claro font-size-0-875rem w-100"
                      >
                        Arraste e solte seu arquivo aqui
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="texto-cor-cinza-claro font-size-0-875rem w-100"
                      >
                        ou
                      </Typography>
                      <Typography variant="subtitle1" className="texto-link w-100">
                        Procurar arquivos
                      </Typography>
                    </Box>
                  )}
                </Dropzone>
              ) : (
                <Box
                  className="flex-box items-esquerda items-meio margin-zero"
                  sx={{ minHeight: '110px' }}
                >
                  <BoxArquivo
                    file={listaDocs[indexArquivo]}
                    funcaoDeletar={excluirDocumento}
                    loadingUpload={loadingUpload}
                    isPending={isPending}
                    tipoComponente={tipoComponente}
                    permitirDeletar={permitirDeletar}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          {listaDocs[indexArquivo].id === 'new_doc' && btnEnviarDocumento ? (
            <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className="flex-box-items-centralizados">
              <ButtonMain
                tipoBotao="azul-escuro"
                disabled={isPending}
                onClick={() => {
                  if (tipoNovoDoc && tipoNovoDoc.documento && tipoNovoDoc.documento.CODPPCDOCUMENTO) {
                    if (listaDocs[indexArquivo].hash_arquivo) {
                      setModalSalvarNovoDocumento(true);
                    } else {
                      setDraggingFile(indexArquivo);
                      setTimeout(() => setDraggingFile(-1), 500);
                    }
                  } else {
                    inputReference.current.focus();
                  }
                }}
              >
                Enviar
              </ButtonMain>
            </Grid>
          ) : (
            tipoComponente !== 'completa' && (
              <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className="flex-box-items-centralizados">
                <Typography
                  variant="h6"
                  className={`${corTextoStatus[statusArquivo.status]} texto-negrito`}
                  title={statusArquivo.subtitulo_status}
                >
                  {iconeStatus[statusArquivo.status]}
                </Typography>
              </Grid>
            )
          )}

          {listaDocs[indexArquivo].nome_arquivo !== undefined &&
            statusArquivo.status === 'aprovado' &&
            inputObservacao && (
              <TextField
                key={`input-observacao-upload-${id}`}
                id={`input-observacao-upload-${id}`}
                name={`input-observacao-upload-${id}`}
                variant="outlined"
                label="Observação"
                className="input-observacao-upload"
                value={textoInputObservacao}
                onChange={handleOnChangeObservacao}
                multiline
                maxRows={2}
                inputProps={{ maxLength: 250 }}
              />
            )}
        </Grid>
      )}

      <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />
      <Dialog
        open={modalSalvarNovoDocumento !== false}
        keepMounted
        onClose={() => setModalSalvarNovoDocumento(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
          Envio de documento
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao confirmar esta ação o arquivo será enviado para análise e não poderá ser alterado. Você
            quer mesmo continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xl={6} lg={6} md={4} sm={2} />
            <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
              <ButtonMain tipoBotao="transparente" onClick={() => setModalSalvarNovoDocumento(false)}>
                Cancelar
              </ButtonMain>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
              <ButtonMain
                tipoBotao="azul-escuro"
                onClick={() => {
                  funcaoSalvarNovoDocumento(indexArquivo, tipoNovoDoc.documento);
                  setModalSalvarNovoDocumento(false);
                }}
              >
                Confirmar
              </ButtonMain>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default DocumentoUpload;
