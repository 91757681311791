import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.6 49.9">
    <path
      d="M43.9,27.3H41.3a.68.68,0,0,0-.7.7V45.9H4.3V9.7H22.2a.68.68,0,0,0,.7-.7V6.3a.68.68,0,0,0-.7-.7H.9a.68.68,0,0,0-.7.7V49.2a.68.68,0,0,0,.7.7H43.8a.68.68,0,0,0,.7-.7V28A.56.56,0,0,0,43.9,27.3ZM49.8,8.2,24,34,12.2,37.7l3.7-12L41.7,0Z"
      transform="translate(-0.2)"
    />
  </svg>
);
