const documentosGarantias = {
  IM: [
    {
      id: 2,
      nome_doc: 'FATURAMENTO',
      explicacao_doc: 'FATURAMENTO DOS ÚLTIMOS 12 MESES',
      obrigacao_doc: true
    },
    {
      id: 28,
      nome_doc: 'MATRICULA DO IMÓVEL',
      explicacao_doc: 'DOCUMENTO DA MATRICULA DO IMÓVEL',
      obrigacao_doc: true
    }
  ],
  AV: [
    {
      id: 2,
      nome_doc: 'FATURAMENTO',
      explicacao_doc: 'FATURAMENTO DOS ÚLTIMOS 12 MESES',
      obrigacao_doc: true
    }
  ]
};

export default documentosGarantias;
