import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography
} from '@mui/material';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import 'moment/locale/pt-br';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { moneyFullFormat, percentualFormat } from '../../../../utils/format';
import { postTitulosVencidos } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import { exibeValorResumido } from '../../../../shared/services/User';

const Vencidos = ({ filtroDashboard, loadingDashFim, valorTotal, setValorTotal, valoresCalculados }) => {
  moment.locale('pt-br');

  const [dadosChart, setDadosChart] = useState(false);
  const [valorTotalPeriodo, setValorTotalPeriodo] = useState(0);
  const [filPeriodo, setFilPeriodo] = useState(7);
  const [isPending, setIsPending] = useState(false);
  const [diaAtual, setDiaAtual] = useState(false);
  const [diasCorridos, setDiasCorridos] = useState(false);

  const setDadosIniciais = () => {
    let hoje = moment();
    return [
      ['dia', 'Valores a vencer por data', 'Ticket médio'],
      [hoje.subtract(7, 'day').format('DD[/]MMM'), 0.0, 0],
      [hoje.add(1, 'day').format('DD[/]MMM'), 0.0, 0],
      [hoje.add(1, 'day').format('DD[/]MMM'), 0.0, 0],
      [hoje.add(1, 'day').format('DD[/]MMM'), 0.0, 0],
      [hoje.add(1, 'day').format('DD[/]MMM'), 0.0, 0],
      [hoje.add(1, 'day').format('DD[/]MMM'), 0.0, 0],
      [hoje.add(1, 'day').format('DD[/]MMM'), 0.0, 0]
    ];
  };

  const options = {
    title: null,
    width: '100%',
    height: '100px',
    chartArea: { width: '85%' },
    seriesType: 'bars',
    colors: ['#F97A7B'],
    series: { 1: { type: 'line', color: 'black' } },
    legend: { position: 'bottom', alignment: 'center' },
    animation: {
      duration: 1000,
      easing: 'out'
    },
    annotations: {
      alwaysOutside: true,
      isStacked: true,
      highContrast: true,
      textStyle: {
        auraColor: 'transparent',
        color: '#871b47',
        bold: true
      }
    }
  };

  const opcoesSelect = [
    { label: 'Últimos 7 dias', valor: 7 },
    { label: 'Últimos 15 dias', valor: 15 }
  ];

  useEffect(() => {
    setDadosChart(setDadosIniciais);
    let hoje = moment();
    let dia = hoje.format('YYYY-MM-DD');
    setDiaAtual(dia);
    let diasCorridos = hoje.subtract(filPeriodo, 'day').format('YYYY-MM-DD');
    setDiasCorridos(diasCorridos);
  }, []);

  useEffect(() => {
    buscaDadosChart(filPeriodo);
  }, [filtroDashboard]);

  const buscaDadosChart = periodo => {
    let filtros = filtroDashboard ? filtroDashboard : {};
    let params = {
      periodo: periodo,
      ...filtros
    };
    if (params.filtrar === false) return false;

    setIsPending(true);
    setValorTotal(0);
    setValorTotalPeriodo(0);
    setDadosChart(setDadosIniciais);
    postTitulosVencidos(params)
      .then(res => {
        let arrayDados = [];
        arrayDados.push([
          'dia',
          'Valores vencidos por data',
          { role: 'annotation' },
          'Ticket médio',
          { role: 'annotation' }
        ]);
        let valorTotal = res.data && res.data.valorTotal ? parseFloat(res.data.valorTotal) : 0;
        let valorTotalPer =
          res.data && res.data.valorTotalPeriodo ? parseFloat(res.data.valorTotalPeriodo) : 0;
        let lstTitulos = res.data && res.data.titulos ? res.data.titulos : [];

        lstTitulos.map(dados => {
          let dia = moment(dados.dataVencimento).format('DD[/]MMM');
          let valor = parseInt(dados.valor);
          let ticket = valor / parseInt(dados.qtd);
          ticket = parseInt(ticket);
          let ticketFormatado = ticket.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });

          arrayDados.push([dia, valor, valor, ticket, ticketFormatado]);
          return dados;
        });

        if (valorTotal > 0) {
          setValorTotal(valorTotal);
        }

        if (valorTotalPer > 0) {
          setValorTotalPeriodo(valorTotalPer);
        }

        if (lstTitulos.length > 0) {
          setDadosChart(arrayDados);
        }
      })
      .catch(() => {
        setDadosChart(false);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('Vencidos');
      });
  };

  const handleOnChangePeriodo = event => {
    setFilPeriodo(event.target.value);
    buscaDadosChart(event.target.value);
    let hoje = moment();
    let diasCorr = hoje.subtract(event.target.value, 'day').format('YYYY-MM-DD');
    setDiasCorridos(diasCorr);
  };

  return (
    <Card className="chart-card">
      {dadosChart ? (
        <CardContent className="ajuste-spin-chart" sx={isPending ? { position: 'relative' } : {}}>
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-5 word-break labelChartVencidos mb-1"
          >
            <span className="font-size-1rem texto-weight-600 white-space-nowrap">
              Total vencidos
              <Tooltip arrow placement="top" title="Últimos 180 dias">
                <span className="font-size-1rem margin-right-10 texto-weight-600"> (180 dd)</span>
              </Tooltip>
            </span>
            <Tooltip arrow placement="top" title={percentualFormat(valoresCalculados.vencidos) + '%'}>
              <span className="font-size-1-2-rem texto-weight-600 texto-danger white-space-nowrap">
                <span>R$ </span>
                {exibeValorResumido(valorTotal)}
              </span>
            </Tooltip>
          </Typography>
          <Grid container className="labelChartAvencer">
            <Grid
              item
              xl={3}
              lg={5}
              md={5}
              sm={5}
              xs={12}
              className="flex-box items-centralizados-esquerda"
            >
              <Select
                className="select-periodo-chart-dasboard"
                value={filPeriodo}
                onChange={handleOnChangePeriodo}
                input={<OutlinedInput label="" />}
              >
                {opcoesSelect.map(item => (
                  <MenuItem key={item.label} value={item.valor}>
                    <DateRangeIcon className="icone-select" />
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xl={7} lg={5} md={5} sm={5} xs={9}>
              <Typography
                variant="subtitle1"
                className="texto-cor-cinza-escuro pad-5 word-break labelChartAvencer mb-1 texto-esquerda"
              >
                <span className="font-size-1rem margin-right-10 white-space-nowrap">
                  Total / Período
                </span>
                <Tooltip arrow placement="top" title={moneyFullFormat(valorTotalPeriodo)}>
                  <span className="font-size-1-2-rem texto-weight-600 white-space-nowrap texto-cor-principal">
                    {`R$ ${exibeValorResumido(valorTotalPeriodo)}`}
                  </span>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={3}
              className="flex-box items-centralizados-direita"
            >
              <Link
                className="linkRelatorios texto-direita texto-info font-size-875"
                to={{
                  pathname: '/relatorios/titulos',
                  search: `?tipo=2&data_vencimento_inicial=${diasCorridos}&data_vencimento_final=${diaAtual}`,
                  state: {
                    fromDashboard: true
                  }
                }}
              >
                Relatório
              </Link>
            </Grid>
          </Grid>

          <Chart
            className="chartVencidos"
            chartType="ComboChart"
            width="100%"
            height="400px"
            data={dadosChart}
            options={options}
            chartLanguage="pt-Br"
          />
        </CardContent>
      ) : (
        <CardContent>
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde.</p>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default Vencidos;
