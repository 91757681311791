import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Button, FormGroup, Label } from 'reactstrap';
import { Form, Formik } from 'formik';

import swal from '@sweetalert/with-react';
import { v4 } from 'uuid';
import VoltarIcon from '../../../shared/icons/Voltar';
import Avancar from '../../../shared/icons/Avancar';
import { SkeletonLoader } from '../../../shared';

import { getTitulosDisponiveis } from '../../../api/instrucoes';
import expandRow from '../../Borderos/common/expandRow';
import columns from './Selecao/helper';
import TableSearch from './Selecao/TableSearch';
import { transformTableTitulosData } from '../../../utils/format';
import HistoricoAcesso from '../../commom/HistoricoAcesso';

const SelecaoStep = props => {
  const {
    nextStep,
    previousStep,
    isPending,
    setIsPending,
    instrucao,
    setInstrucao,
    collapseMenuRight,
    setCollapseMenuRight,
    exibirAlerta,
    setExibirAlerta
  } = props;

  const [itensCount, setItensCount] = useState(0);
  const [titulosDisponiveis, setTitulosDisponiveis] = useState();
  const [message, setMessage] = useState();
  const [form, setForm] = useState({});
  const [unselectableTitulos, setUnselectableTitulos] = useState([]);

  const handleFormSubmit = (_values, { setSubmitting }) => {
    if (
      (instrucao.recompra && instrucao.recompra.length > 0) ||
      (instrucao.abatimento && instrucao.abatimento.length > 0) ||
      (instrucao.prorrogacao && instrucao.prorrogacao.length > 0) ||
      (instrucao.baixa && instrucao.baixa.length > 0)
    ) {
      //Verificação em prorrogação
      if (typeof instrucao.prorrogacao !== 'undefined') {
        var arrayMessages = [];
        instrucao.prorrogacao.map(function(prorrogacao) {
          prorrogacao.titulo.status.map(function(status) {
            if (arrayMessages.includes(status.mensagem) === false) {
              arrayMessages.push(status.mensagem);
            }
            return arrayMessages;
          });
          return prorrogacao;
        });

        if (arrayMessages.length > 0) {
          const errorMessageText =
            'Títulos enviados com inconsistências passarão por uma análise personalizada, após finalizar a instrução, aguarde retorno do setor responsável.';
          const errorMessageContent = arrayMessages.map(function(item) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={v4()} className="alert alert-warning" role="alert">
                {item}
              </div>
            );
          });
          swal({
            title: 'Atenção!',
            text: errorMessageText,
            content: <>{errorMessageContent}</>,
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar']
          }).then(cancel => {
            if (!cancel) {
              setSubmitting(false);
            } else {
              nextStep();
            }
          });
        } else {
          nextStep();
        }
      } else {
        nextStep();
      }
    } else {
      setMessage('É obrigatório selecionar pelo menos um titulo');
      setSubmitting(false);
    }
    return;
  };

  useEffect(() => {
    // https://reactjs.org/docs/hooks-rules.html#explanation
    if (!titulosDisponiveis) {
      const {
        tipoInstrucao: { code }
      } = instrucao;
      getTitulosDisponiveis(code).then(res => {
        const titulos = res.data;
        const unselectables = titulos
          .filter(titulo => {
            return (
              titulo.status &&
              titulo.status.find(status => {
                return ['trava', 'erro'].includes(status.tipo);
              })
            );
          })
          .map(titulo => {
            return titulo.id;
          });

        setUnselectableTitulos(unselectables);

        titulos.forEach(titulo => {
          titulo.meta = {
            visible: true,
            disabled: unselectables && unselectables.length > 0,
            checked: false
          };
        });
        setTitulosDisponiveis(titulos);

        const newInstrucao = {
          ...instrucao,
          recompra: null,
          abatimento: null,
          prorrogacao: null,
          baixa: null,
          valor: 0,
          motivo: null,
          motivoDescricao: null
        };
        setInstrucao(newInstrucao);

        setIsPending(false);
      });
    }
  }, []);

  const updateIstrucao = () => {
    // Build Instrução
    const newInstrucao = {
      ...instrucao,
      recompra: null,
      abatimento: null,
      prorrogacao: null,
      baixa: null,
      dataAgendamento: null
    };
    const titulosSelected = titulosDisponiveis.filter(entity => {
      return entity.meta.checked;
    });

    // * 1 - Recompra.
    // * 2 - Abatimento.
    // * 3 - Prorrogação
    // * 4 - Baixa
    if (newInstrucao.tipoInstrucao.code === '1') {
      newInstrucao.recompra = [];
      titulosSelected.forEach(entity => {
        const recompra = {
          titulo: entity
        };
        newInstrucao.recompra.push(recompra);
      });
    } else if (newInstrucao.tipoInstrucao.code === '2') {
      newInstrucao.abatimento = [];
      titulosSelected.forEach(entity => {
        const abatimento = {
          titulo: entity,
          abatimento: null
        };
        newInstrucao.abatimento.push(abatimento);
      });
    } else if (newInstrucao.tipoInstrucao.code === '3') {
      newInstrucao.prorrogacao = [];
      titulosSelected.forEach(entity => {
        const prorrogacao = {
          titulo: entity,
          dataVencimento: null
        };
        newInstrucao.prorrogacao.push(prorrogacao);
      });
    } else if (newInstrucao.tipoInstrucao.code === '4') {
      newInstrucao.baixa = [];
      const values = [];

      titulosSelected.forEach(entity => {
        if (entity.cheque) {
          values.push(entity.cheque.valor);
        } else {
          values.push(entity.duplicata.valor);
        }
      });

      if (values.length) newInstrucao.valor = values.reduce((acc, curr) => acc + curr);
      else newInstrucao.valor = 0;

      titulosSelected.forEach(entity => {
        const baixa = {
          titulo: entity
        };
        newInstrucao.baixa.push(baixa);
      });
    }

    for (let propName in newInstrucao) {
      if (newInstrucao[propName] === null || newInstrucao[propName] === undefined) {
        delete newInstrucao[propName];
      }
    }

    setInstrucao(newInstrucao);
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    nonSelectable: unselectableTitulos,
    onSelect: row => {
      setMessage('');
      const statusError = row.status
        ? row.status.filter(status => {
            return ['trava', 'erro'].includes(status.tipo);
          })
        : null;

      if (statusError && statusError.length > 0) {
        setMessage(`Títulos com inconsistências não podes ser selecionado.`);
        return false;
      }

      const newTitulosDisponiveis = [...titulosDisponiveis];
      let intensCount = 0;

      newTitulosDisponiveis.forEach(entity => {
        if (row.id === entity.id) {
          entity.meta.checked = !entity.meta.checked;
        }

        if (entity.meta.checked) {
          intensCount++;

          if (form && form.state && form.state.isSubmitting) {
            form.setSubmitting(false);
            setMessage('');
          }
        }
      });

      setItensCount(intensCount);
      setTitulosDisponiveis(newTitulosDisponiveis);
      updateIstrucao();
    },
    onSelectAll: (isSelect, rows) => {
      const newTitulosDisponiveis = [...titulosDisponiveis];
      let innerItensCount = itensCount;
      setMessage('');

      rows.forEach(entity => {
        const statusError = entity.status
          ? entity.status.filter(status => {
              return ['trava', 'erro'].includes(status.tipo);
            }).length > 0
          : false;
        entity.meta.checked = isSelect && !statusError;

        if (entity.meta.checked && entity.meta.visible) {
          innerItensCount++;
        } else {
          innerItensCount--;
        }
      });

      setItensCount(innerItensCount);
      setTitulosDisponiveis(newTitulosDisponiveis);
      updateIstrucao();
    }
  };

  if (instrucao.tipoInstrucao.code === '1' && exibirAlerta) {
    swal({
      title: 'Atenção',
      text:
        'As recompras solicitadas após às 16h horas e até às 18h serão enviados os boletos com vencimento para o próximo dia útil.\nEm caso de dúvida, entrar em contato com o departamento de cobrança pelo telefone (15) 3042-0017',
      icon: 'warning'
    });
    setExibirAlerta(false);
  }

  if (instrucao.tipoInstrucao.code === '2' && exibirAlerta) {
    swal({
      title: 'Atenção',
      text:
        'Somente será permitida a instrução de abatimento, para títulos em que a data de vencimento seja maior que 5 dias, do dia atual (hoje).',
      icon: 'warning'
    });
    setExibirAlerta(false);
  }

  return (
    <>
      <Formik
        ref={node => setForm(node)}
        onSubmit={handleFormSubmit}
        render={({ isSubmitting }) => {
          return (
            <Form className={isPending || isSubmitting ? 'is-submitting' : ''}>
              <div className="row row-content">
                <div
                  className={
                    (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                    ' col-12 content-body'
                  }
                >
                  <div className="body">
                    <h1 className="title">Títulos para Instrução</h1>

                    <SkeletonLoader
                      isPending={isPending}
                      width="100%"
                      height="40px"
                      count={8}
                      widthRandomness={0}
                    >
                      <div className="content-instrucoes--selecao">
                        {message && (
                          <div className="alert alert-danger animated fadeIn" role="alert">
                            {message}
                          </div>
                        )}
                        <ToolkitProvider
                          keyField="id"
                          data={transformTableTitulosData(titulosDisponiveis || [])}
                          columns={columns()}
                          search
                        >
                          {tableProps => (
                            <>
                              <TableSearch
                                {...tableProps.searchProps}
                                itensCount={itensCount}
                                tipoInstrucao={instrucao.tipoInstrucao.descricao}
                              />
                              <BootstrapTable
                                bootstrap4
                                bordered={false}
                                responsive
                                hover
                                keyField="id"
                                disabled={isPending || isSubmitting}
                                expandRow={expandRow}
                                selectRow={selectRow}
                                {...tableProps.baseProps}
                              />
                              {(titulosDisponiveis || []).length === 0 && (
                                <SkeletonLoader isPending={isPending} width="100%" height="40px">
                                  <div className="empty-table-message" />
                                </SkeletonLoader>
                              )}
                            </>
                          )}
                        </ToolkitProvider>
                      </div>
                    </SkeletonLoader>
                  </div>
                  <div className="footer d-flex justify-content-between">
                    <Button
                      size="lg"
                      outline={true}
                      color="primary"
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => {
                        previousStep();
                      }}
                    >
                      <i className="svg-icon">
                        <VoltarIcon />
                      </i>
                      Voltar
                    </Button>
                    <Button size="lg" color="primary" type="submit" disabled={isPending || isSubmitting}>
                      <i className="svg-icon">
                        <Avancar />
                      </i>
                      Avançar
                    </Button>
                  </div>
                </div>
                {!collapseMenuRight && (
                  <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                    <div className="fixed-side">
                      <div
                        role="button"
                        className="header cursor-pointer"
                        onClick={() => setCollapseMenuRight(true)}
                        onKeyPress={() => setCollapseMenuRight(true)}
                        tabIndex={0}
                      >
                        <h4 className="title">
                          Sua Instrução
                          <i className="fa fa-angle-right float-right" />
                        </h4>
                      </div>
                      <div className="info infoInstrucoes">
                        <FormGroup>
                          <Label>Tipo de Instrução:</Label>
                          <p className="form-control-static">{instrucao.tipoInstrucao.descricao}</p>
                        </FormGroup>
                        <FormGroup>
                          <Label>Quantidade de Títulos Selecionados:</Label>
                          <p className="form-control-static">{itensCount}</p>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      />
      <HistoricoAcesso codTela={1199} />
    </>
  );
};

export default SelecaoStep;
