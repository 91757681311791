import React, { memo, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import Main from '../Main/Main';
import { getDadosUsuario, getClienteAtual } from '../../shared';
import DashboardCliente from './DashboardCliente';
import HistoricoAcesso from '../commom/HistoricoAcesso';
import DashboardGeral from './DashboardGeral';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import { nivelUsuarioEnum } from '../../shared/Enums';

const Dashboard = props => {
  const { escopoAcesso = false } = props;
  const { plataforma_cliente = false } = escopoAcesso;
  const [dadosUser, setDadosUser] = useState(false);
  const [clienteAtual, setClienteAtual] = useState(false);
  const [telaLoading, setTelaLoading] = useState(false);

  const [valorTotalAVencer, setValorTotalAVencer] = useState(0);
  const [valorTotalVencidos, setValorTotalVencidos] = useState(0);
  const [valoresCalculados, setValoresCalculados] = useState({ avencer: 0, vencidos: 0 });
  const [qtdeClientesAVencer, setQtdeClientesAVencer] = useState(0);

  useEffect(() => {
    let percentualVencido =
      valorTotalAVencer <= 0
        ? 100
        : (valorTotalVencidos / (valorTotalVencidos + valorTotalAVencer)) * 100;
    let percentualVencer = percentualVencido <= 0 ? 0 : 100 - percentualVencido;
    setValoresCalculados({ avencer: percentualVencer, vencidos: percentualVencido });
  }, [valorTotalAVencer, valorTotalVencidos]);

  useEffect(() => {
    let user = getDadosUsuario();
    setDadosUser(user);
    let cliente = getClienteAtual();
    setClienteAtual(cliente);
  }, []);

  var loadingDashs = [];
  const loadingDashFim = (tela, reset = false) => {
    var qtdTela =
      clienteAtual && dadosUser && parseInt(dadosUser.nivel) !== nivelUsuarioEnum.CLIENTE ? 11 : 9;
    var qtdTelaCargaInicial = 1;
    if (reset) {
      loadingDashs = [];
      setTelaLoading(true);
    } else {
      loadingDashs.push(tela);
      if (tela !== 'carga-inicial' && loadingDashs.length === qtdTela) {
        setTimeout(() => setTelaLoading(false), 500);
      } else if (tela === 'carga-inicial' && loadingDashs.length === qtdTelaCargaInicial) {
        setTimeout(() => setTelaLoading(false), 500);
      }
    }
  };

  return (
    <Main
      title="Dashboard"
      className="dashboard"
      Header={() => (
        <Typography
          className="texto-cor-preto font-bold-600 pad-bot-5 borda-cor-cinza-claro-2 borda-bottom-2 margin-bottom-15"
          variant="h6"
        >
          Dashboard
          {telaLoading && (
            <div className="display-inline-block float-right">
              <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
            </div>
          )}
        </Typography>
      )}
      {...props}
    >
      <Box className="dashboard-charts">
        {clienteAtual && dadosUser && parseInt(dadosUser.nivel) !== nivelUsuarioEnum.CLIENTE ? (
          <DashboardGeral
            dadosUser={dadosUser}
            clienteAtual={clienteAtual}
            loadingDashFim={loadingDashFim}
            telaLoading={telaLoading}
            valorTotalVencidos={valorTotalVencidos}
            valorTotalAVencer={valorTotalAVencer}
            setValorTotalVencidos={setValorTotalVencidos}
            setValorTotalAVencer={setValorTotalAVencer}
            valoresCalculados={valoresCalculados}
            qtdeClientesAVencer={qtdeClientesAVencer}
            setQtdeClientesAVencer={setQtdeClientesAVencer}
            escopoAcesso={plataforma_cliente}
          />
        ) : clienteAtual && dadosUser ? (
          <DashboardCliente
            dadosUser={dadosUser}
            clienteAtual={clienteAtual}
            loadingDashFim={loadingDashFim}
            telaLoading={telaLoading}
            valorTotalVencidos={valorTotalVencidos}
            valorTotalAVencer={valorTotalAVencer}
            setValorTotalVencidos={setValorTotalVencidos}
            setValorTotalAVencer={setValorTotalAVencer}
            valoresCalculados={valoresCalculados}
            qtdeClientesAVencer={qtdeClientesAVencer}
            setQtdeClientesAVencer={setQtdeClientesAVencer}
            escopoAcesso={plataforma_cliente}
          />
        ) : (
          ''
        )}
      </Box>
      <HistoricoAcesso codTela={1167} />
    </Main>
  );
};
export default memo(Dashboard);
