import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Switch,
  Typography,
  Select,
  OutlinedInput,
  MenuItem
} from '@mui/material';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { postVopSpreadComercial } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import { calcularOpcoesAnoMes } from '../../../../utils/format';
import { geraCorHexaDecimal } from '../../../../shared';

const VOPSpread = ({ filtroDashboard, loadingDashFim }) => {
  moment.locale('pt-br');
  let mesAnoAtual = moment().format('YYYY[-]MM');

  const [dadosChartPlataformas, setDadosChartPlataformas] = useState([]);
  const [dadosChartUnidadesNegocio, setDadosChartUnidadesNegocio] = useState([]);
  const [dadosChartGerentes, setDadosChartGerentes] = useState([]);
  const [isPendingPlataformas, setIsPendingPlataformas] = useState(true);
  const [isPendingUnidadesNegocio, setIsPendingUnidadesNegocio] = useState(true);
  const [isPendingGerentes, setIsPendingGerentes] = useState(true);
  const [switchVopSpreadPlataformas, setSwitchVopSpreadPlataformas] = useState('VOP');
  const [switchVopSpreadUnidadesNegocio, setSwitchVopSpreadUnidadesNegocio] = useState('VOP');
  const [switchVopSpreadGerentes, setSwitchVopSpreadGerentes] = useState('VOP');
  const [filAnoMes, setFilAnoMes] = useState(false);
  const [opcoesAnoMes, setOpcoesAnoMes] = useState([
    {
      label: moment().format('MM[/]YYYY'),
      valor: moment().format('YYYY[-]MM')
    }
  ]);

  const options = {
    title: null,
    width: '100%',
    height: '500px',
    legend: {
      position: 'top',
      maxLines: 3,
      alignment: 'center',
      textStyle: { fontSize: 14 }
    },
    pieSliceText: 'none',
    enableInteractivity: true,
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true
    },
    colors: []
  };

  const graficos = {
    plataformas: {
      id: 'plataformas',
      titulo: 'Plataformas',
      setPending: setIsPendingPlataformas,
      setDadosChart: setDadosChartPlataformas,
      setSwitchVopSpread: setSwitchVopSpreadPlataformas
    },
    unidadesNegocio: {
      id: 'unidadesNegocio',
      titulo: 'Unidades de Negócio',
      setPending: setIsPendingUnidadesNegocio,
      setDadosChart: setDadosChartUnidadesNegocio,
      setSwitchVopSpread: setSwitchVopSpreadUnidadesNegocio
    },
    gerentes: {
      id: 'gerentes',
      titulo: 'Gerentes de Negócio',
      setPending: setIsPendingGerentes,
      setDadosChart: setDadosChartGerentes,
      setSwitchVopSpread: setSwitchVopSpreadGerentes
    }
  };

  useEffect(() => {
    setOpcoesAnoMes(calcularOpcoesAnoMes(6));
  }, []);

  useEffect(() => {
    let params = filtroDashboard ? { ...filtroDashboard } : {};
    if (params.filtrar === false) return false;
    if (filAnoMes === false) {
      buscarDadosChart();
    } else {
      setFilAnoMes(mesAnoAtual);
    }
  }, [filtroDashboard]);

  useEffect(() => {
    if (filAnoMes === false) return false;
    buscarDadosChart();
  }, [filAnoMes]);

  const buscarDadosChart = () => {
    let params = filtroDashboard ? { ...filtroDashboard } : {};
    if (params.filtrar === false) return false;
    params.anoMes = filAnoMes ? filAnoMes : mesAnoAtual;

    Object.values(graficos).map(chart => {
      var chartParams = { ...params, tipoGrafico: chart.id };
      chart.setPending(true);
      postVopSpreadComercial(chartParams)
        .then(res => {
          if (res && res.data) {
            chart.setDadosChart(res.data);
          } else {
            chart.setDadosChart({});
          }
        })
        .catch(() => {
          chart.setDadosChart(false);
        })
        .finally(() => {
          chart.setPending(false);
          loadingDashFim('VopSpread-' + chart.id);
        });
      return chart;
    });
  };

  const handleOnChangeAnoMes = event => {
    setFilAnoMes(event.target.value);
  };

  const handleChangeSwitch = (event, tipoGrafico) => {
    graficos[tipoGrafico].setSwitchVopSpread(event.target.checked ? 'VOP' : 'SPREAD');
  };

  return (
    <Box>
      <Card className="chart-card">
        <CardContent className="pad-15">
          <Grid container spacing={3}>
            <Grid item xl={9} lg={9} md={8} sm={9} xs={12}>
              <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5 texto-weight-600">
                VOP/Spread Comercial
              </Typography>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12} className="texto-direita">
              <Select
                className="select-periodo-chart-dasboard"
                value={filAnoMes ? filAnoMes : mesAnoAtual}
                onChange={handleOnChangeAnoMes}
                input={<OutlinedInput label="" />}
                disabled={isPendingPlataformas || isPendingUnidadesNegocio || isPendingGerentes}
              >
                {opcoesAnoMes.map(item => (
                  <MenuItem key={item.label} value={item.valor}>
                    <DateRangeIcon className="icone-select" />
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={3}>
        {graficos &&
          Object.values(graficos).map(chart => {
            var tipoDados = 'VOP';
            if (chart.id === 'plataformas') {
              tipoDados = switchVopSpreadPlataformas;
            } else if (chart.id === 'unidadesNegocio') {
              tipoDados = switchVopSpreadUnidadesNegocio;
            } else if (chart.id === 'gerentes') {
              tipoDados = switchVopSpreadGerentes;
            }
            var switchVop = tipoDados === 'VOP';

            var dadosGrafico = [];
            if (chart.id === 'plataformas') {
              dadosGrafico = dadosChartPlataformas;
            } else if (chart.id === 'unidadesNegocio') {
              dadosGrafico = dadosChartUnidadesNegocio;
            } else if (chart.id === 'gerentes') {
              dadosGrafico = dadosChartGerentes;
            }

            var cores = [];
            if (dadosGrafico && dadosGrafico[tipoDados] && dadosGrafico[tipoDados].length > 0) {
              dadosGrafico[tipoDados].map((dado, index) => {
                let strBaseCor = index + dado[0].replace('PLATAFORMA', 'PLTF');
                cores.push(geraCorHexaDecimal(strBaseCor));
                return dado;
              });
            }
            var optsCharts = { ...options };
            optsCharts.colors = cores;

            var isPending = false;
            if (chart.id === 'plataformas') {
              isPending = isPendingPlataformas;
            } else if (chart.id === 'unidadesNegocio') {
              isPending = isPendingUnidadesNegocio;
            } else if (chart.id === 'gerentes') {
              isPending = isPendingGerentes;
            }
            return (
              <Grid key={chart.id} item xl={4} lg={4} md={6} sm={12} xs={12}>
                <Card className="chart-card border-radius-0">
                  <CardContent
                    className="ajuste-spin-chart"
                    sx={isPending ? { position: 'relative', height: '540px' } : { height: '540px' }}
                  >
                    {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                    <Grid container>
                      <Grid item xl={8} lg={8} md={8} sm={9} xs={12}>
                        <Typography
                          variant="subtitle1"
                          className="labelChartVopSpread texto-cor-cinza-escuro pad-5 texto-weight-600"
                        >
                          {`${chart.titulo} - ${tipoDados}`}
                        </Typography>
                      </Grid>
                      <Grid className="texto-centro" item xl={4} lg={4} md={4} sm={3} xs={12}>
                        <span
                          className={`${
                            !switchVop ? 'texto-cor-cinza-escuro' : 'texto-cor-cinza-claro'
                          } texto-weight-600`}
                        >
                          Spread
                        </span>
                        <Switch
                          checked={switchVop}
                          onChange={event => handleChangeSwitch(event, chart.id)}
                        />
                        <span
                          className={`${
                            switchVop ? 'texto-cor-cinza-escuro' : 'texto-cor-cinza-claro'
                          } texto-weight-600`}
                        >
                          VOP
                        </span>
                      </Grid>
                    </Grid>
                    {dadosGrafico && dadosGrafico[tipoDados] && dadosGrafico[tipoDados].length > 0 ? (
                      <Chart
                        className="chartVopSpread legend-position-top grayscale-20"
                        chartType="PieChart"
                        width="100%"
                        height="450px"
                        data={[[chart.titulo, 'Valor' + tipoDados], ...dadosGrafico[tipoDados]]}
                        options={optsCharts}
                        chartLanguage="pt-Br"
                      />
                    ) : (
                      <Typography
                        variant="h2"
                        className="line-hifen-gray flex-box-items-centralizados"
                        sx={{ height: '460px' }}
                      >
                        - - -
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default VOPSpread;
