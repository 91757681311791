import exportFromJSON from 'export-from-json';
import { dateFormat, moneyFullFormat, deletePropriedades } from '../../utils/format';

const renameKeys = (keysMap, obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const renamedObject = {
      [keysMap[key] || key]: obj[key]
    };

    return {
      ...acc,
      ...renamedObject
    };
  }, {});
};

const RelatorioExport = (relatorioData, exportType, operaTrustee, colunasConfig = []) => {
  const currentDate = `${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}`;
  const data = [];
  const colunasArray = [];

  if (relatorioData.length) {
    var dadosRelatorio = [...relatorioData];
    if (Array.isArray(colunasConfig) && colunasConfig.length) {
      colunasConfig.forEach(config => colunasArray.push(config.dataField));
      dadosRelatorio = dadosRelatorio.map(row => {
        var rowData = { ...row };
        Object.keys(rowData).forEach(col => {
          if (!colunasArray.includes(col)) {
            delete rowData[col];
          }
        });
        return rowData;
      });
    }

    const fileName = `relatorio-${exportType}-${currentDate}`;
    const formataDinheiro = [
      'debito',
      'saldo',
      'aVencer',
      'liquidado',
      'vencido',
      'valorFace',
      'valorTitulo',
      'valorPago',
      'valorDevido'
    ];
    const formataData = [
      'liquidacao',
      'liquidacaoCS',
      'inclusao',
      'dataAceite',
      'dataProrrogacao',
      'dataVencimento'
    ];
    const formataTexto = ['documento'];

    /**
     * _header é usado para montar um Header "bonito" na planilha. O header inicial que é montado era baseado nas keys, que seriam 0, 1, 2, 3...
     */
    const _header = Object.keys(dadosRelatorio[0]).map(key => {
      if (colunasArray.length > 0 && colunasArray.includes(key)) {
        let indexKey = colunasArray.indexOf(key);
        let headerText = key;
        if (colunasConfig[indexKey] && colunasConfig[indexKey].text) {
          headerText = colunasConfig[indexKey].text;
          if (colunasConfig[indexKey].formatacao && colunasConfig[indexKey].formatacao === 'valor')
            formataDinheiro.push(key);
        }
        return headerText.charAt(0).toUpperCase() + headerText.slice(1).toLocaleLowerCase();
      } else {
        switch (key) {
          case 'code':
            return 'Código';
          case 'nrBordero':
            return 'Nr.Borderô';
          case 'dataAceite':
            return 'Data Aceite';
          case 'valorFace':
            return 'Valor Face (R$)';
          case 'dataProrrogacao':
            return 'Data Prorrogação';
          case 'dataVencimento':
            return 'Data Vencimento';
          case 'liquidacao':
            return 'Liquidação';
          case 'inclusao':
            return 'Inclusão';
          case 'descricao':
            return 'Descrição';
          case 'operacao':
            return 'Operação';
          case 'liquidacaoCS':
            return 'Liquidação CS';
          case 'notaFiscal':
            return 'Nota Fiscal';
          case 'tipoDocumento':
            return 'Tipo Documento';
          case 'tipoOperacaoDescricao':
            return 'Tipo Operação';
          case 'valorDevido':
            return 'Valor Devido (R$)';
          case 'valorPago':
            return 'Valor Pago (R$)';
          case 'valorTitulo':
            return 'Valor Título (R$)';
          case 'aVencer':
            return 'A Vencer';
          case 'abatimento':
            return 'Abatimento (R$)';
          default:
            return key.charAt(0).toUpperCase() + key.slice(1); // aplica maiúscula apenas no primeiro caracter (css capitalize)
        }
      }
    });

    dadosRelatorio.forEach(item => {
      let _data = Object.keys(item).map(key => {
        if (item[key] === null) {
          return '';
        } else if (formataDinheiro.indexOf(key) !== -1) {
          return moneyFullFormat(item[key], true);
        } else if (formataData.indexOf(key) !== -1) {
          return dateFormat(item[key]);
        } else if (formataTexto.indexOf(key) !== -1) {
          return (item[key] = ' ' + item[key]);
        } else {
          return item[key];
        }
      });

      if (_header.indexOf('Boleto') === -1) {
        _header.push('Boleto');
      }

      _data = renameKeys(_header, _data);
      data.push(_data);
    });

    if (operaTrustee === 'N') {
      var ValorPago = 'Valor Pago (R$)';
    } else {
      ValorPago = '';
    }

    data.map(item => {
      return deletePropriedades(item, [
        'Boleto',
        'TipoFiltroSelecionado',
        'TipoOperacao',
        'Id',
        'ApelidoUsuario',
        'CodBancoRemessa',
        'UrlBaseImpressaoBoleto',
        'TipoBoleto',
        'Meta',
        'MensagemTitleBoleto',
        'FiltroEmpresa',
        '27',
        ValorPago
      ]);
    });

    exportFromJSON({ data, fileName, exportType });
  }
};

export default RelatorioExport;
