const etapasStatus = {
  INICIO: { etapa: 'CadastroEmpresa', subEtapa: '' },
  EMP: { etapa: 'CadastroEmpresa', subEtapa: '' },
  PRO: { etapa: 'PerfilCarteira', subEtapa: '' },
  DCE: { etapa: 'EnvioDocumentos', subEtapa: 'Empresa' },
  DCS: { etapa: 'EnvioDocumentos', subEtapa: 'Sociedade' },
  PFG: { etapa: 'ParecerFinalGerente', subEtapa: '' },
  ANE: { etapa: 'FinalizacaoJornada', subEtapa: '' },
  AGR: { etapa: 'GerenciarDocumentos', subEtapa: '' }
};
export default etapasStatus;
