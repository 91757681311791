/* eslint-disable import/prefer-default-export */
import API from './api';

export const validarUsuario = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/valida-usuario-onboarding`);

export const reenviaTokenConfirmacao = email =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/reenvia-token`, {
    email: email
  });

export const solicitarTokenMFA = values =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/solicitar-token-mfa`, values);

export const confirmacaoResend = values =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/confirmacao-resend`, values);

export const confirmacaoSalvar = values =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/confirmacao-salvar`, values);

export const salvarAcesso = ({ codModulo, codTela, codusuario, nomeUsuario }) =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/filas/registrar-acesso`, {
    codModulo: codModulo,
    codTela: codTela,
    codUsuario: codusuario,
    nome: nomeUsuario
  });
