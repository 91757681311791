import { Button, UncontrolledTooltip } from 'reactstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import { tiposOrigemProposta } from '../Onboarding/shared/Enums/EnumsOne7MaisCredito';
// import { useHistory } from 'react-router-dom';

export default (
  toggleBorderoVisualizaDocumento,
  toggleExluir,
  clienteAtual,
  irParaJornadaOne7MaisCredito
) => {
  // const history = useHistory();
  const formatarStatus = (cellContent, row) => {
    return <span className={'text-' + row.statusAnalise.cor}>{cellContent}</span>;
  };

  const criarBotoesBordero = row => {
    const disableBordero = row.statusProcesso === 1 || row.statusProcesso === 2;
    return (
      <>
        {row.tipoOperacao === 'GP' &&
        parseInt(row.tipoOrigemProposta) === tiposOrigemProposta.ONE7MAISCREDITO &&
        !disableBordero ? (
          <Button
            color="primary"
            outline
            type="button"
            id={`btn-editar-bordero-${row.id}`}
            className="btn btn-outline-primary rounded-circle"
            onClick={() => irParaJornadaOne7MaisCredito(row.id)}
          >
            <i className="fa fa-angle-right" />
            <UncontrolledTooltip
              target={`btn-editar-bordero-${row.id}`}
              placement="top"
              className="bold-font"
              delay={0}
            >
              Editar Bôrdero
            </UncontrolledTooltip>
          </Button>
        ) : !disableBordero ? (
          <Link
            to={`/Borderos/${row.id}`}
            id={`btn-editar-bordero-${row.id}`}
            className="btn btn-outline-primary rounded-circle"
          >
            <i className="fa fa-angle-right" />
            <UncontrolledTooltip
              target={`btn-editar-bordero-${row.id}`}
              placement="top"
              className="bold-font"
              delay={0}
            >
              Editar Bôrdero
            </UncontrolledTooltip>
          </Link>
        ) : (
          <Link
            to={`/borderos-visualizar-titulos/${row.id}`}
            id={`btn-visualizar-bordero-${row.id}`}
            className="btn btn-outline-primary rounded-circle"
          >
            <i className="fa fa-eye" />
            <UncontrolledTooltip
              target={`btn-visualizar-bordero-${row.id}`}
              placement="top"
              className="bold-font"
              delay={0}
            >
              Visualizar Bôrdero
            </UncontrolledTooltip>
          </Link>
        )}
        <Button
          color="primary"
          outline
          type="button"
          id={`btn-visualizar-documentos-${row.id}`}
          value={row.id}
          className="rounded-circle fa fa-file"
          onClick={toggleBorderoVisualizaDocumento}
        >
          <UncontrolledTooltip
            target={`btn-visualizar-documentos-${row.id}`}
            placement="top"
            delay={0}
            className="bold-font"
          >
            Visualizar Documentos
          </UncontrolledTooltip>
        </Button>
        {disableBordero ? (
          <div className="tooltip-wrapper d-inline-block" id={`btn-excluir-bordero-${row.id}`}>
            <Button
              color="primary"
              outline
              type="button"
              className="rounded-circle pointer-events-none"
              disabled
            >
              <i className="fa fa-trash-alt" />
              <UncontrolledTooltip
                delay={0}
                target={`btn-excluir-bordero-${row.id}`}
                placement="left"
                className="bold-font"
              >
                {row.statusAnalise.code === 'I' ? (
                  <>Este borderô não pode ser excluído, pois ele foi indeferido.</>
                ) : (
                  <>Este borderô já está em análise. Para editar contate a equipe One7</>
                )}
              </UncontrolledTooltip>
            </Button>
          </div>
        ) : (
          <Button
            color="primary"
            outline
            type="button"
            id={`btn-excluir-bordero-${row.id}`}
            className="rounded-circle"
            onClick={() => {
              toggleExluir(row);
            }}
          >
            <i className="fa fa-trash-alt" />
            <UncontrolledTooltip
              target={`btn-excluir-bordero-${row.id}`}
              placement="top"
              delay={0}
              className="bold-font"
            >
              Excluir Bôrdero e todos os títulos
            </UncontrolledTooltip>
          </Button>
        )}
      </>
    );
  };

  const config = [
    {
      dataField: 'id',
      text: 'CÓD. ENTRADA',
      tipoOrdenacao: 'inteiro',
      tdAlign: 'center'
    },
    {
      dataField: 'nBordero',
      text: 'Nº BORDERÔ',
      tipoOrdenacao: 'inteiro',
      tdAlign: 'center',
      formatacao: 'textoOuIfem'
    },
    {
      dataField: 'empresa',
      text: 'EMPRESA',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    },
    {
      dataField: 'descricaoTipoOperacao',
      text: 'TIPO OPERAÇÃO',
      tipoOrdenacao: 'texto',
      tdAlign: 'center',
      thWhiteSpace: 'normal'
    },
    {
      dataField: 'descricaoTipoTitulo',
      text: 'TIPO DOCUMENTO',
      tipoOrdenacao: 'texto',
      tdAlign: 'center',
      thWhiteSpace: 'normal'
    },
    {
      dataField: 'titulosQuantidade',
      text: 'QTD. TÍTULOS',
      tipoOrdenacao: 'inteiro',
      tdAlign: 'center',
      thWhiteSpace: 'normal'
    },
    {
      dataField: 'dataInclusao',
      text: 'DATA INCLUSÃO',
      tipoOrdenacao: 'data',
      formatacao: 'data',
      tdTitle: (cell, row) => row.tituloInclusao,
      tdAlign: 'center',
      thWhiteSpace: 'normal'
    },
    {
      dataField: 'titulosTotalValorBruto',
      text: 'VALOR BRUTO',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      tdAlign: 'right',
      thWhiteSpace: 'normal',
      style: { whiteSpace: 'nowrap' }
    },
    {
      dataField: 'statusAnalise.descricao',
      text: 'STATUS',
      tipoOrdenacao: 'texto',
      formatacao: formatarStatus,
      tdAlign: 'center'
    },
    {
      dataField: 'ACOES',
      text: 'AÇÕES',
      tdAlign: 'center',
      formatacao: 'button',
      evento: criarBotoesBordero,
      style: { whiteSpace: 'nowrap' },
      sort: false
    }
  ];

  if (clienteAtual && clienteAtual.id === 'todos') {
    config.unshift({
      dataField: 'nomeCliente',
      text: 'CLIENTE',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    });
  }

  return config;
};
