export default clienteAtual => {
  const config = [
    {
      dataField: 'sacado',
      text: 'SACADO',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'left' }
    },
    {
      dataField: 'aVencer',
      text: 'A VENCER',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'right' }
    },
    {
      dataField: 'vencido',
      text: 'VENCIDO',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'right' }
    },
    {
      dataField: 'liquidado',
      text: 'LIQUIDADO',
      tipoOrdenacao: 'float',
      formatacao: 'valor',
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'right' }
    }
  ];

  if (clienteAtual && clienteAtual.id === 'todos') {
    config.unshift({
      dataField: 'nomeCliente',
      text: 'CLIENTE',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    });
  }

  return config;
};
