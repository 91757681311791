/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { mascararCpfCnpj } from '../../../../shared';
import { getListaPropostasPareceres } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import ButtonMain from '../../../Onboarding/shared/ButtonMain';
import { ppcTiposEstados } from '../../../../shared/Enums';

const ModalGridDashboardPareceres = ({ modalGrid, setModalGrid }) => {
  const lstPpcTiposEstados = Object.values(ppcTiposEstados);
  var totaisPpcTiposEstados = {};
  lstPpcTiposEstados.map(tiposEstados => {
    totaisPpcTiposEstados[tiposEstados.sigla] = tiposEstados;
    return tiposEstados;
  });

  const [isPending, setIsPending] = useState(false);
  const [dadosPareceres, setDadosPareceres] = useState([]);
  const [arrayCardPareceres, setArrayCardPareceres] = useState([]);
  const [mostrarTodosPareceres, setMostrarTodosPareceres] = useState('N');
  const [etapasPpcEstados, setEtapasPpcEstados] = useState(totaisPpcTiposEstados);

  useEffect(() => {
    if (modalGrid && modalGrid.modal === 'parecer' && modalGrid.codppc) {
      setIsPending(true);
      setDadosPareceres([]);
      setArrayCardPareceres([]);
      getListaPropostasPareceres(modalGrid.codppc, mostrarTodosPareceres)
        .then(res => {
          if (res && res.data) {
            let dados = res.data;
            let arrayPareceres = [];
            var estados = { ...etapasPpcEstados };
            dados.map(d => {
              arrayPareceres[d.id] = false;
              return d;
            });
            setArrayCardPareceres(arrayPareceres);
            setDadosPareceres(res.data);
            setEtapasPpcEstados(estados);
          }
        })
        .finally(() => {
          setIsPending(false);
        });
    }

    if (!modalGrid) {
      setMostrarTodosPareceres('N');
    }
  }, [modalGrid, mostrarTodosPareceres]);

  const buttonExpandirParecer = id => {
    let newArray = [...arrayCardPareceres];
    newArray[id] = newArray[id] ? false : true;
    setArrayCardPareceres(newArray);
  };

  return (
    <Dialog
      open={modalGrid !== false}
      fullWidth={true}
      maxWidth={
        modalGrid && modalGrid.modal === 'parecer' ? 'lg' : modalGrid.modal === 'status' ? 'sm' : 'md'
      }
      keepMounted
      onClose={() => setModalGrid(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="borda-bottom-2-gray pad-topbot-10">
          {modalGrid ? modalGrid.modalTitle : ''}
          <IconButton onClick={() => setModalGrid(false)} className="float-right margin-neg-top-5">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {modalGrid && modalGrid.modal === 'parecer' ? (
          <Box className="box-parecer" sx={isPending ? { position: 'relative', height: '250px' } : {}}>
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Box className="pad-5 texto-centro sub-titulo border-radius-8 margin-top-10">
              <Typography
                variant="subtitle1"
                className="font-size-1-1-rem texto-cor-cinza-escuro font-bold"
              >
                {`${modalGrid.nomeCliente} - ${mascararCpfCnpj(modalGrid.cnpjCliente)}`}
              </Typography>
            </Box>
            <Grid className="time-line">
              {dadosPareceres &&
                dadosPareceres.map(parecer => (
                  <Grid container key={parecer.id}>
                    <Grid className="border-line-right" item xl={1} lg={1} md={1} sm={1} xs={1} />
                    <Grid
                      className="border-line-left time-card"
                      item
                      xl={11}
                      lg={11}
                      md={11}
                      sm={11}
                      xs={11}
                    >
                      <Box
                        className="timeline-badge"
                        sx={{
                          background:
                            totaisPpcTiposEstados &&
                            totaisPpcTiposEstados[parecer.SIGLA].sigla === parecer.SIGLA
                              ? totaisPpcTiposEstados[parecer.SIGLA].cor
                              : '#1976d2'
                        }}
                      >
                        <i
                          className={
                            totaisPpcTiposEstados &&
                            totaisPpcTiposEstados[parecer.SIGLA].sigla === parecer.SIGLA
                              ? totaisPpcTiposEstados[parecer.SIGLA].icon
                              : 'fa fa-plus'
                          }
                        />
                        <i className="fa fa-file-text-o" />
                      </Box>
                      <Box className="timeline-card">
                        <Box>
                          <Typography
                            variant="subtitle1"
                            className="timeline-title texto-cor-cinza-escuro font-bold"
                          >
                            <span className="timeline-inclusao">
                              {' '}
                              &nbsp;
                              {parecer.NOME ? parecer.NOME : ''}
                            </span>
                            <br />
                            <span className="timeline-inclusao"> &nbsp; </span>
                            <span className="timeline-inclusao">
                              {`Em: ${parecer.DATAINCLUSAOFORMATADA} por: ${parecer.NOMEUSUAINSERT}`}
                            </span>
                          </Typography>
                        </Box>

                        <Box
                          className={`box-pareceres ${
                            arrayCardPareceres[parecer.id] ? '' : 'limitar-tamanho-parecer'
                          }`}
                        >
                          <Box
                            dangerouslySetInnerHTML={{ __html: parecer.PARECER ? parecer.PARECER : '' }}
                          />
                        </Box>
                        <Box>
                          <Button
                            onClick={() => buttonExpandirParecer(parecer.id)}
                            className="float-right"
                            sx={{ textTransform: 'inherit' }}
                          >
                            {arrayCardPareceres[parecer.id] ? 'Ver menos' : 'Ver mais'}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ))}

              {dadosPareceres.length >= 6 && mostrarTodosPareceres === 'N' && (
                <Box className="texto-centro">
                  <ButtonMain
                    sx={{ maxWidth: '190px', marginTop: '15px' }}
                    tipoBotao="principal"
                    onClick={() => setMostrarTodosPareceres('S')}
                  >
                    Mostrar tudo
                  </ButtonMain>
                </Box>
              )}
            </Grid>
          </Box>
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalGridDashboardPareceres;
