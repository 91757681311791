import React from 'react';

import { HashRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Routes from './Routes';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH
});

const App = () => {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <Router>
      <div className="wrapper">
        <Routes />
      </div>
    </Router>
  );
};

export default App;
