import React, { memo, useEffect } from 'react';
import strip from '../../../../utils/strip';
import CustomMaskedInput from '../CustomMaskedInput/CustomMaskedInput';
import { DANFEMask } from '../../../masks/masks';

/*
        A Chave de Acesso de um CF-e é formada pelas seguintes informações: 
        Exemplos:
          35180657424244000119550010000936611122667587          
          31190708004619000186550010000815901437858550

          chave: 35180657424244000119550010000936611122667587
          nota fiscal:                    000093661

        (dígitos 1 e 2) *** Código do Estado (UF) do emitente
        (dígitos 3 até 6) *** Ano e mês da emissão da NF-e (no formato AAMM)
        (dígitos 7 até 20) *** CNPJ do emitente da NF-e (CNPJ da sua Empresa)
        (dígitos 21 até 22) *** Modelo da NF-e
        (dígitos 23 até 25) *** Série da NF-e

        (dígitos 26 até 34) *** Número da NF-e

        (dígito 35) *** Tipo de emissão *
        (dígitos 36 até 43) *** Código da NF-e – Número gerado pelo sistema
        (dígito 44) *** Dígito verificador – DV (Cálculo no módulo 11).
        */

const DANFEInput = props => {
  const { onSearch, searchHandler, ...otherProps } = props;
  const maxLength = 44;
  const numberRegex = /\D/g;
  otherProps.field.value = (otherProps.field.value || '').replace(numberRegex, '');

  useEffect(() => {
    let propriedades = props;
    const stripped = strip(propriedades.field.value);
    if (stripped.length === maxLength && onSearch) {
      const uf = stripped.substring(0, 2);
      const anoMes = stripped.substring(2, 6);
      const cnpj = stripped.substring(6, 20);
      const modeloNFE = stripped.substring(20, 22);
      const serieNFE = stripped.substring(22, 25);
      const numeroNFE = stripped.substring(25, 34);
      const tipoEmissao = stripped.substring(34, 35);
      const codigoNFE = stripped.substring(35, 43);
      const digitoVerificador = stripped.substring(43, 44);
      const danfe = {
        uf,
        anoMes,
        cnpj,
        modeloNFE,
        serieNFE,
        numeroNFE,
        tipoEmissao,
        codigoNFE,
        digitoVerificador
      };

      return onSearch(danfe, otherProps.form);
    } else if (onSearch) {
      return onSearch({}, otherProps.form);
    }
    return;
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.field.value]);

  return (
    <div className="loadingWrapper">
      <CustomMaskedInput maxLength={maxLength} {...otherProps} mask={DANFEMask} />
    </div>
  );
};

export default memo(DANFEInput);
