import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import 'moment/locale/pt-br';
import { postLiquidezCarteira } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';

const Liquidez = ({ filtroDashboard, loadingDashFim }) => {
  moment.locale('pt-br');
  const hoje = moment();
  const labelsCharts = ['Mês', 'Em dia', 'Atrasado', 'Vencido', 'Recomp.', 'PDD'];

  const [dadosChart, setDadosChart] = useState([
    [...labelsCharts],
    ['Meses anteriores', 0, 0, 0, 0, 0],
    [hoje.subtract(3, 'month').format('MMM[/]YY'), 0, 0, 0, 0, 0],
    [hoje.subtract(2, 'month').format('MMM[/]YY'), 0, 0, 0, 0, 0],
    [hoje.subtract(1, 'month').format('MMM[/]YY'), 0, 0, 0, 0, 0],
    [hoje.format('MMM[/]YY'), 0, 0, 0, 0, 0]
  ]);
  const [periodoChart, setPeriodoChart] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const options = {
    title: null,
    width: '100%',
    chartArea: { width: '80%' },
    legend: {
      position: 'bottom',
      maxLines: 3,
      alignment: 'center',
      textStyle: { fontSize: 12 }
    },
    animation: {
      duration: 1000,
      easing: 'out'
    },
    vAxis: {
      title: 'Percentual',
      viewWindowMode: 'explicit',
      viewWindow: {
        max: 100,
        min: 0
      }
    },
    pointSize: 6,
    series: {
      0: { pointShape: 'circle', color: '#79BCFB' },
      1: { pointShape: 'diamond', color: '#F97A7B' },
      2: { pointShape: 'polygon', color: '#ff6600' },
      3: { pointShape: 'square', color: '#376384' },
      4: { pointShape: 'triangle', color: '#800080' }
    }
  };

  useEffect(() => {
    buscaliquidezCarteira();
  }, [filtroDashboard]);

  const buscaliquidezCarteira = () => {
    let params = filtroDashboard ? filtroDashboard : {};
    if (params.filtrar === false) return false;

    setIsPending(true);
    postLiquidezCarteira(params)
      .then(res => {
        let arrayDados = [];
        arrayDados.push([...labelsCharts]);
        let lstDados = res && res.data && res.data.lista ? res.data.lista : [];
        let periodo =
          res && res.data && res.data.periodo && res.data.periodo.inicio
            ? {
                inicio: moment(res.data.periodo.inicio).format('MMM[/]YY'),
                final: moment(res.data.periodo.final).format('MMM[/]YY')
              }
            : false;
        lstDados.map(dados => {
          let mesAno =
            dados.dataMesAno !== 'ultimos_meses'
              ? moment(dados.dataMesAno).format('MMM[/]YY')
              : 'Meses anteriores';

          let emDia = parseFloat(dados.emDiaPercentual.toFixed(2));
          let atrasado = parseFloat(dados.atrasadoPercentual.toFixed(2));
          let vencido = parseFloat(dados.vencidoPercentual.toFixed(2));
          let recomprado = parseFloat(dados.recompradoPercentual.toFixed(2));
          let perdido = parseFloat(dados.perdidoPercentual.toFixed(2));

          arrayDados.push([mesAno, emDia, atrasado, vencido, recomprado, perdido]);
          return dados;
        });

        if (lstDados.length > 0) {
          setDadosChart(arrayDados);
        }

        setPeriodoChart(periodo);
      })
      .catch(() => {
        setDadosChart(false);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('Liquidez');
      });
  };

  return (
    <Card className="chart-card">
      {dadosChart ? (
        <CardContent className="ajuste-spin-chart" sx={isPending ? { position: 'relative' } : {}}>
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-5 word-break labelChartLiquidez"
          >
            <span className="font-size-1rem margin-right-10 texto-weight-600">
              Performance da carteira
            </span>
          </Typography>

          <Chart
            className="chartLiquidez"
            chartType="LineChart"
            width="100%"
            height="450px"
            data={dadosChart}
            options={options}
            chartLanguage="pt-Br"
          />
          {periodoChart && periodoChart.inicio && periodoChart.final && (
            <Typography variant="subtitle1" className="font-size-1-rem">
              <b>*</b>
              <i>{`Período referente a ${periodoChart.inicio} até ${periodoChart.final}`}</i>
            </Typography>
          )}
        </CardContent>
      ) : (
        <CardContent>
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default Liquidez;
