import React, { useState } from 'react';
import Produtos from './Produtos/Produtos';
import Simulacao from './Simulacao/Simulacao';

export default ({
  avancarEtapa,
  cnpjEmpresa,
  entradaOnboarding,
  codContacorrente,
  origemProposta = 'digital'
}) => {
  const [mudaTelaSolucoes, setMudaTelaSolucoes] = useState('Produtos');
  const [produtoEscolhido, setProdutoEscolhido] = useState([]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [mudaTelaSolucoes]);

  return (
    <>
      {mudaTelaSolucoes === 'Produtos' && (
        <Produtos
          avancarEtapa={avancarEtapa}
          mudaTelaSolucoes={setMudaTelaSolucoes}
          cnpjEmpresa={cnpjEmpresa}
          produtoEscolhido={produtoEscolhido}
          setProdutoEscolhido={setProdutoEscolhido}
          entradaOnboarding={entradaOnboarding}
          origemProposta={origemProposta}
        />
      )}

      {mudaTelaSolucoes === 'Simulacao' && (
        <Simulacao
          avancarEtapa={avancarEtapa}
          mudaTelaSolucoes={setMudaTelaSolucoes}
          cnpjEmpresa={cnpjEmpresa}
          produtoEscolhido={produtoEscolhido}
          setProdutoEscolhido={setProdutoEscolhido}
          codContacorrente={codContacorrente}
          origemProposta={origemProposta}
        />
      )}
    </>
  );
};
