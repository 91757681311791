import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10260_116)">
      <mask
        id="mask0_10260_116"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path d="M20 0H0V20H20V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_10260_116)">
        <path
          d="M4.99475 10.2877V14.6526"
          stroke="#1A284D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.57971 10.2877V14.6526"
          stroke="#1A284D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.22253 10.2877V14.6526"
          stroke="#1A284D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8646 10.2877V14.6526"
          stroke="#1A284D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4545 10.2877V14.6526"
          stroke="#1A284D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.10846 0.71228V3.51509C7.11315 3.85691 6.98226 4.18667 6.74441 4.43221C6.50656 4.67775 6.18113 4.81908 5.83934 4.82526H2.88354"
          stroke="#1A284D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.89373 9.20735L2.87935 16.1828C2.87511 16.6403 3.05186 17.0809 3.37108 17.4087C3.69029 17.7364 4.12611 17.9248 4.58356 17.9326H13.8681C14.324 17.9255 14.7584 17.7376 15.0757 17.4102C15.3931 17.0829 15.5674 16.6429 15.5604 16.187V2.2284C15.5641 2.00258 15.5232 1.77825 15.4402 1.56823C15.3571 1.35821 15.2335 1.16661 15.0764 1.00437C14.9193 0.842134 14.7317 0.712443 14.5245 0.622707C14.3172 0.532972 14.0943 0.48495 13.8685 0.481384H7.10988L2.89409 4.83226"
          stroke="#1A284D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.87305 17.9888C4.87901 18.9561 5.53621 19.513 6.4734 19.5137H15.758C15.9838 19.5101 16.2067 19.4621 16.4139 19.3723C16.6212 19.2826 16.8087 19.1529 16.9659 18.9907C17.123 18.8284 17.2466 18.6368 17.3297 18.4268C17.4127 18.2168 17.4536 17.9925 17.4499 17.7666V3.80805C17.4536 3.58224 17.4127 3.3579 17.3297 3.14788C17.2466 2.93786 17.123 2.74626 16.9659 2.58402C16.8087 2.42178 16.6212 2.29209 16.4139 2.20236C16.2067 2.11262 15.9838 2.0646 15.758 2.06104"
          stroke="#1A284D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_10260_116">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
