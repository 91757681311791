import * as CNPJ from '@fnando/cnpj';
import * as CPF from '@fnando/cpf';
import * as Yup from 'yup';
import moment from 'moment';
import strip from '../../../../../utils/strip';
import { unmaskValue, moneyFullFormat, dateFormat } from '../../../../../utils/format';
import validaCMC7 from '../../../../../utils/validaCMC7';
import validaCaracteresEspeciais from '../../../../../utils/validaCaracteresEspeciais';

// const TIPO_OPERACAO = {
//   NORMAL: 'NO', // NO (Normal)
//   COMISSIONARIA: 'CM', // Comissária
//   INTERCOMPANY: 'IN', // Intercompany
//   GIRO_RAPIDO: 'GR' // 'Giro rápido'
// };

export const TIPO_ENTRADA_TITULO = {
  ENTRADA_POR_XML: 1,
  ENTRADA_MANUAL: 2,
  ENTRADA_POR_XLS: 3,
  ENTRADA_POR_CNAB: 4
};

export const TIPO_TITULO = {
  DUPLICATA: 'DM', // Duplicata
  CHEQUE: 'CH', // Cheque
  DUPLICATA_SERVICO: 'DS', // Dplicata de serviço
  NOTA_PROMISSORIA: 'NP' // Nota promissória
};

export const TIPO_EMPRESA = {
  FIDC: 6,
  SECURITIZADORA: 14,
  SECURITIZADORA_JPE: 7
};

export const formSchema = (bordero, regras, clienteAtual, alteraCampos) => {
  let baseSchema;
  var requerido = !(clienteAtual && clienteAtual.clienteOperaConnect
    ? clienteAtual.clienteOperaConnect
    : false);

  var liberaCedente =
    clienteAtual && clienteAtual.clienteOperaConnect && clienteAtual.liberaXmlRedesconto
      ? clienteAtual.liberaXmlRedesconto
      : false;

  // Definições Basicas
  const camposFormSchema = {
    // sacado: Yup.object().shape({
    cpfCnpj: Yup.string()
      .nullable()
      .required('Campo Obrigatório.')
      .test({
        exclusive: true,
        message: params => {
          const strippedValue = strip(params.value);
          if (strippedValue.length === 11) {
            return 'CPF inválido.';
          } else if (strippedValue.length === 14) {
            return 'CNPJ inválido.';
          }
          return 'CPF/CNPJ inválido.';
        },
        name: 'CPF-CNPJ',
        test: value => {
          if (value) {
            return CNPJ.isValid(value) || CPF.isValid(value);
          }
          return true;
        }
      }),
    nome: Yup.string()
      .nullable()
      .required('Campo Obrigatório.')
      .test('nome', 'Campo obrigatório', value => {
        return value && value.toString().replace(/\s/g, '') !== '';
      })
      .test('nome', 'Caracteres Inválidos', value => {
        let caracteresEspeciais = [`'`, `*`, `"`];
        let validacao = validaCaracteresEspeciais(value, caracteresEspeciais);
        return validacao;
      }),
    email: Yup.string()
      .nullable()
      .test('email', 'E-mail inválido', value => {
        if (value && requerido) {
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value.toString().replace(/\s/g, '') === '';
        }
        return true;
      }),
    telefone: Yup.string().when('eCliente', {
      is: val =>
        !val &&
        bordero.tipoTitulo &&
        (bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA ||
          bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA_SERVICO),
      then: Yup.string()
        .nullable()
        .test('telefone', 'Campo obrigatório', value => {
          if (requerido) {
            return value && value.toString().replace(/\s/g, '') !== '';
          } else {
            return true;
          }
        })
        .test({
          message: 'Telefone inválido',
          test: value => {
            if (value && requerido) {
              const tel = strip(value);
              const ddd = parseInt(tel.substring(0, 2), 10);
              const telWithoutDDD = tel.substring(2, tel.length);
              if (ddd <= 10 || ddd >= 100) {
                return false;
              }
              return (
                telWithoutDDD.length === 8 ||
                (telWithoutDDD.length === 9 && [7, 8, 9].includes(parseInt(telWithoutDDD.charAt(0), 10)))
              );
            }
            return true;
          }
        })
    })
    // })
  };

  const enderecoSchema = Yup.object().when('eCliente', {
    is: val => typeof val === 'undefined' || !val,
    then: Yup.object().shape({
      bairro: Yup.string()
        .nullable()
        .test('bairro', 'Campo obrigatório', value => {
          if (alteraCampos.includes('libera_endereco') || requerido) {
            return value && value.toString().replace(/\s/g, '') !== '';
          } else {
            return true;
          }
        })
        .test('bairro', 'Caracteres Inválidos', value => {
          let caracteresEspeciais = [`'`, `*`, `"`];
          let validacao = validaCaracteresEspeciais(value, caracteresEspeciais);
          return validacao;
        }),
      cep: Yup.string()
        .nullable()
        .test('cep', 'Campo obrigatório', value => {
          if (alteraCampos.includes('libera_endereco') || requerido) {
            return value && value.toString().replace(/\s/g, '') !== '';
          } else {
            return true;
          }
        })
        .test('cep', 'CEP inválido', value => {
          if (value) {
            return /^[0-9]{8}$/.test(strip(value));
          }
          return true;
        }),
      cidade: Yup.string()
        .nullable()
        .test('cidade', 'Campo obrigatório', value => {
          if (alteraCampos.includes('libera_endereco') || requerido) {
            return value && value.toString().replace(/\s/g, '') !== '';
          } else {
            return true;
          }
        })
        .test('cidade', 'Caracteres Inválidos', value => {
          let caracteresEspeciais = [`'`, `*`, `"`];
          let validacao = validaCaracteresEspeciais(value, caracteresEspeciais);
          return validacao;
        }),
      complemento: Yup.string()
        .nullable()
        .test('complemento', 'Caracteres Inválidos', value => {
          let caracteresEspeciais = [`'`, `*`, `"`];
          let validacao = validaCaracteresEspeciais(value, caracteresEspeciais);
          return validacao;
        }),
      logradouro: Yup.string()
        .nullable()
        .test('logradouro', 'Campo obrigatório', value => {
          if (alteraCampos.includes('libera_endereco') || requerido) {
            return value && value.toString().replace(/\s/g, '') !== '';
          } else {
            return true;
          }
        })
        .test('logradouro', 'Caracteres Inválidos', value => {
          let caracteresEspeciais = [`'`, `*`, `"`];
          let validacao = validaCaracteresEspeciais(value, caracteresEspeciais);
          return validacao;
        }),
      numero: Yup.string()
        .nullable()
        .test('numero', 'Campo obrigatório', value => {
          if (alteraCampos.includes('libera_endereco') || requerido) {
            return value && value.toString().replace(/\s/g, '') !== '';
          } else {
            return true;
          }
        }),
      uf: Yup.string()
        .nullable()
        .test('uf', 'Campo obrigatório', value => {
          if (alteraCampos.includes('libera_endereco') || requerido) {
            return value && value.toString().replace(/\s/g, '') !== '';
          } else {
            return true;
          }
        })
    })
  });

  const commonFields = {
    ndoc: Yup.string()
      .nullable()
      .test('ndoc', 'Campo obrigatório', value => {
        if (requerido) {
          return value && value.toString().replace(/\s/g, '') !== '';
        } else {
          return true;
        }
      })
      .test('ndoc', 'Caracteres Inválidos', value => {
        let caracteresEspeciais = [`'`, `*`, `"`];
        let validacao = validaCaracteresEspeciais(value, caracteresEspeciais);
        return validacao;
      }),
    valor: Yup.string()
      .nullable()
      .test('valor', 'Campo obrigatório', value => {
        if (requerido) {
          return value && value.toString().replace(/\s/g, '') !== '';
        } else {
          return true;
        }
      })
      .test('valor', `Deve ser maior que ${moneyFullFormat(regras.valorMimimo)}`, value => {
        const valor = unmaskValue(value);
        if ((valor < regras.valorMimimo || valor === 0 || !valor) && requerido) {
          return false;
        }
        return true;
      })
      .test('valor', `Deve ser menor que ${moneyFullFormat(regras.valorMaximo)}`, value => {
        const valor = unmaskValue(value);
        if (valor > regras.valorMaximo && requerido) {
          return false;
        }
        return true;
      }),

    vencimento: Yup.string()
      .nullable()
      .test('vencimento', 'Campo obrigatório', value => {
        if (requerido) {
          return value && value.toString().replace(/\s/g, '') !== '';
        } else {
          return true;
        }
      })
      .test('Data', 'Data inválida', value => {
        if (value && requerido) {
          return moment(value, 'DD/MM/YYYY').isValid();
        }
        return true;
      })
      .test(
        'vencimento_entre',
        `Deve estar entre ${dateFormat(regras.dataMinima)} e ${dateFormat(regras.dataMaxima)}`,
        value => {
          const current = moment(value, 'DD/MM/YYYY');

          if (!current.isValid()) {
            return true;
          }

          const { dataMaxima, dataMinima } = regras;
          let validRange = true;
          if (dataMaxima && requerido) {
            const before = moment(dataMinima);
            const after = moment(dataMaxima);
            // REF: https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
            validRange = current.isBetween(before, after, null, '[]');
          }
          return validRange;
        }
      )
      .test('vencimento_bloqueado', 'Feriado(s) não são permitidos', value => {
        const current = moment(value, 'DD/MM/YYYY');
        let isHoliday = true;
        let isWeekend = true;

        if (!current.isValid()) {
          return true;
        }

        const { datasBloqueadas } = regras;
        // const working
        if (datasBloqueadas && requerido) {
          const currentDateISO = current.format(moment.HTML5_FMT.DATE);
          isHoliday = !datasBloqueadas.includes(currentDateISO);
        }
        return isHoliday && isWeekend;
      })
  };

  const duplicataSchema = Yup.object().shape({
    ...commonFields,
    chaveDanfe: Yup.string()
      .nullable()
      .required('Campo Obrigatório.')
      .test('chafeDanfe', 'Campo obrigatório', value => {
        return value && value.toString().replace(/\s/g, '') !== '';
      })
      .test('chafeDanfe', 'DANFE inválida. Chave deve conter 44 caracteres.', value => {
        var valueRegex = '';
        if (typeof value !== 'undefined' && value !== undefined && value !== null) {
          valueRegex = value.toString().replace(/\D/g, '');
        }
        if (isNaN(value) === true) {
          return false;
        }
        if (
          typeof valueRegex !== 'undefined' &&
          valueRegex !== undefined &&
          valueRegex !== null &&
          valueRegex !== ''
        ) {
          return value && valueRegex.length === 44;
        }
        return true;
      })
  });

  const duplicataServicoSchema = Yup.object().shape({
    ...commonFields,
    chaveDanfe: Yup.string()
      .nullable()
      .test('chafeDanfe', 'DANFE inválida. Chave deve conter 44 caracteres.', value => {
        var valueRegex = '';
        if (typeof value !== 'undefined' && value !== undefined && value !== null) {
          valueRegex = value.toString().replace(/\D/g, '');
        }
        if (isNaN(value) === true) {
          return false;
        }
        if (
          typeof valueRegex !== 'undefined' &&
          valueRegex !== undefined &&
          valueRegex !== null &&
          valueRegex !== ''
        ) {
          return value && valueRegex.length === 44;
        }
        return true;
      })
  });

  const chequeSchema = Yup.object().shape({
    ...commonFields,
    cmc7: Yup.string()
      .nullable()
      .required('Campo Obrigatório.')
      .test('CMC7', 'CMC7 inválido', value => {
        value = value.replace(/[/\D*]/g, ''); // removendo máscara
        if (value && (value.length === 30 || value.length === 34) && requerido) {
          if (validaCMC7(value)) {
            return true;
          }
        }
        return false;
      }),
    codBanco: Yup.string()
      .nullable()
      .required('Campo Obrigatório.'),
    compensacao: Yup.string()
      .nullable()
      .required('Campo Obrigatório.'),
    codAgencia: Yup.string()
      .nullable()
      .required('Campo Obrigatório.'),
    codcc: Yup.string()
      .nullable()
      .required('Campo Obrigatório.')
  });

  // Definição conforme tipoTitulo

  if (
    bordero.tipoEmpresa &&
    parseInt(bordero.tipoEmpresa.code) === TIPO_EMPRESA.FIDC &&
    bordero.tipoTitulo &&
    bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA
  ) {
    baseSchema = Yup.object().shape({
      sacado: Yup.object().shape({
        ...camposFormSchema,
        endereco: enderecoSchema
      }),
      duplicata: duplicataSchema
    });
  } else if (bordero.tipoTitulo && bordero.tipoTitulo.code === TIPO_TITULO.CHEQUE) {
    baseSchema = Yup.object().shape({
      sacado: Yup.object().shape({
        ...camposFormSchema
      }),
      cheque: chequeSchema
    });
  } else if (
    bordero.tipoTitulo &&
    (bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA_SERVICO ||
      bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA)
  ) {
    if (liberaCedente && bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA_SERVICO) {
      baseSchema = Yup.object().shape({
        sacado: Yup.object().shape({
          ...camposFormSchema,
          endereco: enderecoSchema
        }),
        cedente: Yup.object().shape({
          ...camposFormSchema,
          endereco: enderecoSchema
        }),
        duplicata: duplicataServicoSchema
      });
    } else {
      baseSchema = Yup.object().shape({
        sacado: Yup.object().shape({
          ...camposFormSchema,
          endereco: enderecoSchema
        }),
        duplicata: duplicataServicoSchema
      });
    }
  }

  return baseSchema;
};

// const formataDataDeVencimento = titulo => {
//   if (titulo.cheque) {
//     return {
//       cheque: {
//         ...titulo.cheque,
//         vencimento: moment(titulo.cheque.vencimento, 'YYYY/MM/DD').format('DD/MM/YYYY')
//       }
//     };
//   } else {
//     return {
//       duplicata: {
//         ...titulo.duplicata,
//         vencimento: moment(titulo.duplicata.vencimento, 'YYYY/MM/DD').format('DD/MM/YYYY')
//       }
//     };
//   }
// };

export const initialValues = (bordero, titulo) => {
  const common = {
    ndoc: '',
    valor: '',
    vencimento: ''
  };
  const values = {
    sacado: {
      cpfCnpj: '',
      email: '',
      endereco: {
        bairro: '',
        cep: '',
        complemento: '',
        cidade: '',
        logradouro: '',
        numero: '',
        uf: ''
      },
      nome: '',
      telefone: '',
      eCliente: false
    },
    ...(bordero &&
    bordero.liberaXmlRedesconto &&
    bordero.liberaXmlRedesconto === 'N' &&
    bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA_SERVICO
      ? {
          cedente: {
            cpfCnpj: '',
            email: '',
            endereco: {
              bairro: '',
              cep: '',
              complemento: '',
              cidade: '',
              logradouro: '',
              numero: '',
              uf: ''
            },
            nome: '',
            telefone: '',
            eCliente: false
          }
        }
      : {})
  };

  const valuesEndereco = {
    bairro: '',
    cep: '',
    complemento: '',
    cidade: '',
    logradouro: '',
    numero: '',
    uf: ''
  };

  if (
    bordero.tipoTitulo &&
    (bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA ||
      bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA_SERVICO)
  ) {
    values.duplicata = {
      ...common,
      chaveDanfe: '',
      nf: ''
    };
  } else if (bordero.tipoTitulo && bordero.tipoTitulo.code === TIPO_TITULO.CHEQUE) {
    values.cheque = {
      ...common,
      codAgencia: '',
      compensacao: '',
      codBanco: '',
      cmc7: '',
      codcc: ''
    };
  }

  return Object.keys(titulo || {}).length
    ? {
        ...titulo,
        sacado: {
          nome:
            (titulo.sacado.fisica || {}).nome ||
            (titulo.sacado.juridica || {}).razaoSocial ||
            (titulo.sacado.juridica || {}).nomeFantasia,
          email:
            (titulo.sacado.fisica || titulo.sacado.juridica).emails &&
            (titulo.sacado.fisica || titulo.sacado.juridica).emails[0].email,
          cpfCnpj: (titulo.sacado.fisica || {}).cpf || (titulo.sacado.juridica || {}).cnpj,
          endereco:
            (titulo.sacado.fisica || titulo.sacado.juridica).enderecos === ''
              ? valuesEndereco
              : (titulo.sacado.fisica || titulo.sacado.juridica).enderecos[0],
          telefone:
            ((titulo.sacado.fisica || titulo.sacado.juridica).telefones[0] &&
              (titulo.sacado.fisica || titulo.sacado.juridica).telefones[0].ddd) +
            ((titulo.sacado.fisica || titulo.sacado.juridica).telefones[0] &&
              (titulo.sacado.fisica || titulo.sacado.juridica).telefones[0].fone),
          eCliente: titulo.sacado.eCliente
        },
        ...(bordero &&
        bordero.liberaXmlRedesconto &&
        bordero.liberaXmlRedesconto === 'S' &&
        bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA_SERVICO
          ? {
              cedente: {
                nome: titulo.cedente.nome,
                email: titulo.cedente.emails && titulo.cedente.emails[0].email,
                cpfCnpj: (titulo.cedente || {}).cpfCnpj,
                endereco: titulo.cedente.enderecos === '' ? valuesEndereco : titulo.cedente.enderecos[0],
                telefone:
                  (titulo.cedente.telefones[0] && titulo.cedente.telefones[0].ddd) +
                  (titulo.cedente.telefones[0] && titulo.cedente.telefones[0].fone),
                eCliente: titulo.cedente.eCliente
              }
            }
          : {})
      }
    : values;
};
