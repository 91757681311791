import { Formik } from 'formik';
import React, { useState, useEffect, memo } from 'react';
import swal from '@sweetalert/with-react';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import FileRow from '../../FileRow/FileRow';
import InclusaoTituloForm from './InclusaoTituloForm';
import { formSchema, initialValues, TIPO_TITULO, TIPO_ENTRADA_TITULO } from './schema';
import { saveBorderoTitulo, updateBorderoTitulo, uploadFile } from '../../../../../api/borderos';
import { toPayload } from './helper';
import { formatarBorderoTitulo } from '../../../../../utils/format';
import { searchContasViaJornadaDigital } from '../../../../../api/clientSearch';
import { TAMANHO_MAXIMO_UPLOAD } from '../../../../../shared';

const ModalInclusaoTitulo = props => {
  const { modal, className = '', toggle, bordero, updateBordero, regras, clienteAtual } = props;

  const isAlteracao = modal.titulo;

  const [files, setFiles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [alteraCampos, setAlteraCampos] = useState([]);
  const [, setFileUploadProgress] = useState({});
  const [groupedTitulos, setGroupedTitulos] = useState([]);
  const [form, setForm] = useState({});
  const [activeTab, setActiveTab] = useState(
    clienteAtual && clienteAtual.clienteOperaConnect && !clienteAtual.liberaXmlRedesconto && !isAlteracao
      ? '2'
      : '1'
  );
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState();
  const [formInitialValues, setInitialValues] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  var tituloModal = isAlteracao ? 'Alterar' : 'Incluir';
  tituloModal =
    clienteAtual &&
    clienteAtual.clienteOperaConnect &&
    !clienteAtual.liberaXmlRedesconto &&
    isAlteracao &&
    !alteraCampos.length > 0
      ? 'Visualizar'
      : tituloModal;
  tituloModal = `${tituloModal} Título`;

  const [contaViaJornada, setContaViaJornada] = useState(false);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    setInitialValues(initialValues(bordero, modal.titulo));
    contaViaJornadaDigital();
  }, []);

  useEffect(() => {
    setTitles(
      groupedTitulos.flatMap(groupedTitulo => groupedTitulo.data).filter(titulos => titulos !== null)
    );
  }, [groupedTitulos]);

  const filesAcceptedHandler = acceptedFiles => {
    setIsUploading(true);

    const parsedFiles = acceptedFiles.map(acceptedFile => {
      const data = new FormData();
      data.append('file', acceptedFile);
      data.append('bordero', bordero.tipoTitulo.code);
      const uniqueId = Math.random()
        .toString(36)
        .substr(2, 9);
      return {
        id: uniqueId,
        data,
        name: acceptedFile.name,
        statusCode: 0,
        response: null
      };
    });

    const parsedTitles = parsedFiles.map(pf => ({ fileId: pf.id, data: null }));

    setFiles([...files, ...parsedFiles]);
    setGroupedTitulos([...groupedTitulos, ...parsedTitles]);

    parsedFiles.forEach(parsedFile => {
      uploadFile(
        parsedFile.data,
        progressValue => {
          parsedFile.progress = progressValue;
          parsedFile.statusCode = 0;
          setFileUploadProgress({ [parsedFile.id]: progressValue });
        },
        clienteAtual ? clienteAtual.clienteEspecial : false,
        bordero.tipoOperacao.code,
        clienteAtual ? clienteAtual.clienteOperaConnect : false,
        contaViaJornada,
        clienteAtual ? clienteAtual.liberaXmlRedesconto : false
      )
        .then(res => {
          let parsedTitle = parsedTitles.find(gt => gt.fileId === parsedFile.id);
          parsedFile.response = res.data;
          parsedFile.statusCode = res.status;
          parsedTitle.data = res.data.titulos;

          parsedTitle.data.forEach(titulo => {
            if (titulo.cheque && titulo.cheque.cmc7) {
              titulo.cheque.cmc7 = titulo.cheque.cmc7.replace(/\s/g, '');
            }
          });
        })
        .catch(err => {
          parsedFile.response = err.response;
          parsedFile.statusCode = (err.response && err.response.status) || 500;
        })
        .finally(() => {
          setGroupedTitulos([...groupedTitulos, ...parsedTitles]);
          setFiles([...files, ...parsedFiles]);
          setIsUploading(false);
        });
    });
  };

  const contaViaJornadaDigital = () => {
    return searchContasViaJornadaDigital(clienteAtual.cnpj)
      .then(resContaJornada => {
        if (
          resContaJornada &&
          resContaJornada.data &&
          resContaJornada.data.onboarding &&
          resContaJornada.data.total_operacoes &&
          resContaJornada.data.total_operacoes > 0
        ) {
          setContaViaJornada(false);
        } else {
          setContaViaJornada(true);
        }
      })
      .catch(err => {
        const {
          response: { data: { error: { message = 'Erro ao buscar os dados' } = {} } = {} } = {}
        } = err;
        setMessage(message);
      })
      .finally(() => {});
  };

  const filesRejectedHandler = file => {
    const _fileName = file[0].name;
    const _fileTypesAccepted = getFilesToAccept();
    const _extension = _fileName.split('.');
    let _errorMessage;

    if (_fileTypesAccepted.indexOf(`.${_extension[_extension.length - 1]}`) === -1) {
      if (clienteAtual && clienteAtual.clienteEspecial) {
        _errorMessage = `Para clientes que emitem o próprio boleto, o tipo de arquivo ${_extension[
          _extension.length - 1
        ].toUpperCase()} não é permitido`;
      } else if (clienteAtual && clienteAtual.clienteOperaConnect) {
        _errorMessage = `Para clientes que operam via Connect, o tipo de arquivo ${_extension[
          _extension.length - 1
        ].toUpperCase()} não é permitido`;
      } else if (bordero.tipoTitulo === 'DS') {
        _errorMessage = `Para duplicatas de serviço o tipo de arquivo ${_extension[
          _extension.length - 1
        ].toUpperCase()} não é permitido`;
      } else if (contaViaJornada) {
        _errorMessage = `ARQUIVO DO TIPO ${_extension[
          _extension.length - 1
        ].toUpperCase()} NÃO É PERMITIDO!`;
      } else {
        _errorMessage = `O tipo de arquivo ${_extension[
          _extension.length - 1
        ].toUpperCase()} não é permitido`;
      }
    } else {
      _errorMessage = 'Arquivo ultrapassa o tamanho máximo de 10MB';
    }

    swal({
      title: 'Atenção!',
      text: _errorMessage,
      icon: 'error',
      dangerMode: true
    });
  };

  const deleteFile = file => {
    const updatedFiles = files.filter(fl => fl.id !== file.id);
    const updatedGroupedTitulos = groupedTitulos.filter(gp => gp.fileId !== file.id);
    setFiles([...updatedFiles]);
    setGroupedTitulos([...updatedGroupedTitulos]);
  };

  const retryUpload = file => {
    setIsUploading(true);

    uploadFile(
      file.data,
      progressValue => {
        file.progress = progressValue;
        file.statusCode = 0;
        setFiles([...files]);
      },
      clienteAtual ? clienteAtual.clienteEspecial : false,
      bordero.tipoOperacao.code,
      clienteAtual ? clienteAtual.clienteOperaConnect : false,
      contaViaJornada,
      clienteAtual ? clienteAtual.liberaXmlRedesconto : false
    )
      .then(res => {
        file.response = res.data;
        file.statusCode = res.status;
        const parsedTitle = { fileId: file.id, data: res.data.titulos };

        parsedTitle.data.forEach(titulo => {
          if (titulo.cheque && titulo.cheque.cmc7) {
            titulo.cheque.cmc7 = titulo.cheque.cmc7.replace(/\s/g, '');
          }
        });

        setGroupedTitulos([...groupedTitulos, parsedTitle]);
      })
      .catch(err => {
        file.response = err.response;
        file.statusCode = err.response.status;
      })
      .finally(() => {
        setFiles([...files]);
        setIsUploading(false);
      });
  };

  const handleButtonIncluir = () => {
    setSubmitting(true);
    let titulosFiltrados = [];

    if (
      bordero &&
      bordero.tipoTitulo &&
      (bordero.tipoTitulo.code === 'DM' || bordero.tipoTitulo.code === 'DS')
    ) {
      titulosFiltrados = titles.filter(titulo => titulo.duplicata);
    } else if (bordero && bordero.tipoTitulo && bordero.tipoTitulo.code === 'CH') {
      titulosFiltrados = titles.filter(titulo => titulo.cheque);
    }
    if (activeTab === '2') {
      return saveBorderoTitulo(bordero.id, titulosFiltrados)
        .then(() => {
          toggle({});
          return updateBordero();
        })
        .catch(err => {
          const {
            response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
          } = err;
          setMessage(message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      form && form.submitForm();
      setSubmitting(false);
    }
  };

  const handlerButtonfechar = () => {
    // Limpa os titulos
    setTitles([]);

    // Fecha ou abre o modal
    toggle({});
  };

  const showFileRows = () => {
    if (files.length) {
      return (
        <div className="file-wrapper">
          {files.map(file => (
            <FileRow key={file.name} file={file} deleteFile={deleteFile} retryUpload={retryUpload} />
          ))}
        </div>
      );
    }
  };

  const handleFormSubmit = (values, formProps) => {
    setSubmitting(true);
    if (bordero && bordero.tipoTitulo.code === TIPO_TITULO.CHEQUE) {
      values.cheque = {
        ...values.cheque,
        codTipoEntrada: TIPO_ENTRADA_TITULO.ENTRADA_MANUAL,
        vencimento: moment(values.cheque.vencimento, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
      };
    } else {
      values.duplicata = {
        ...values.duplicata,
        codTipoEntrada: TIPO_ENTRADA_TITULO.ENTRADA_MANUAL,
        vencimento: moment(values.duplicata.vencimento, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
      };
    }

    values.tipoAcao = 'alteracao';
    const payload = toPayload(values, modal.titulo);
    // ALTERACAO DE TITULO
    if (modal.titulo && modal.titulo.id) {
      return updateBorderoTitulo(bordero.id, payload)
        .then(resultTituloAlterado => {
          toggle({});
          var newBordero = { ...bordero };
          if (resultTituloAlterado.data.titulos) {
            var titulos = resultTituloAlterado.data.titulos;
            newBordero.titulosTotalValorBruto = 0;
            titulos.map(tit => {
              var valorTitulo = tit.duplicata ? tit.duplicata.valor : tit.cheque.valor;
              newBordero.titulosTotalValorBruto += valorTitulo;
              return formatarBorderoTitulo(tit);
            });
            newBordero.titulos = titulos;
            return updateBordero(newBordero);
          } else {
            return updateBordero();
          }
        })
        .finally(() => {
          setSubmitting(false);
          form.setSubmitting(false);
        });

      // INCLUSAO DE TITULO
    } else {
      return saveBorderoTitulo(bordero.id, [payload])
        .then(() => {
          setMessage('');
          const {
            bairro = '',
            cep = '',
            complemento = '',
            cidade = '',
            logradouro = '',
            uf = '',
            numero = ''
          } = values.sacado.endereco;

          formProps.resetForm();

          form.setFieldValue('sacado.cpfCnpj', values.sacado.cpfCnpj);
          form.setFieldValue('sacado.nome', values.sacado.nome);
          form.setFieldValue('sacado.email', values.sacado.email);
          form.setFieldValue('sacado.telefone', values.sacado.telefone);
          form.setFieldValue(
            'sacado.endereco',
            {
              bairro,
              cep,
              complemento,
              cidade,
              logradouro,
              numero,
              uf
            },
            true
          );

          if (
            clienteAtual &&
            clienteAtual.clienteOperaConnect &&
            clienteAtual.liberaXmlRedesconto &&
            bordero.tipoTitulo === TIPO_TITULO.DUPLICATA_SERVICO
          ) {
            const {
              bairro = '',
              cep = '',
              complemento = '',
              cidade = '',
              logradouro = '',
              uf = '',
              numero = ''
            } = values.cedente.endereco;

            form.setFieldValue('cedente.cpfCnpj', values.cedente.cpfCnpj);
            form.setFieldValue('cedente.nome', values.cedente.nome);
            form.setFieldValue('cedente.email', values.cedente.email);
            form.setFieldValue('cedente.telefone', values.cedente.telefone);
            form.setFieldValue(
              'cedente.endereco',
              {
                bairro,
                cep,
                complemento,
                cidade,
                logradouro,
                numero,
                uf
              },
              true
            );
          }

          return updateBordero();
        })
        .catch(err => {
          const {
            response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
          } = err;
          setMessage(message);
        })
        .finally(() => {
          setSubmitting(false);
          form.setSubmitting(false);
        });
    }
  };

  const getFilesToAccept = () => {
    let generalFiles = ['.txt', '.zip', '.rar', '.rem'];

    if (clienteAtual && clienteAtual.clienteOperaConnect) {
      return generalFiles;
    } else {
      if (clienteAtual && clienteAtual.clienteEspecial) {
        if (
          bordero &&
          ((bordero.tipoTitulo && bordero.tipoTitulo.code === 'CH') ||
            (bordero.tipoOperacao &&
              (bordero.tipoOperacao.code === 'CM' || bordero.tipoOperacao.code === 'ES')))
        ) {
          return [...generalFiles, '.xml', '.xls'];
        }
        return generalFiles;
      }

      if (bordero && bordero.tipoTitulo && bordero.tipoTitulo.code === 'DS') {
        return [...generalFiles, '.xls'];
      } else if (clienteAtual && contaViaJornada) {
        generalFiles = ['.xml', '.zip', '.rar'];
        return [...generalFiles, '.xml', '.zip', '.rar'];
      }

      return [...generalFiles, '.xml', '.xls'];
    }
  };

  const resetTitulo = () => {
    if (modal.titulo) {
      setInitialValues({
        id: modal.titulo.id,
        ...initialValues(bordero)
      });
    } else {
      setInitialValues(initialValues(bordero));
    }
  };

  return (
    <Modal
      isOpen={modal.show}
      toggle={toggle}
      className={`modalInclusaoTitulo ${className}`}
      size="lg"
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>{tituloModal}</ModalHeader>
      <ModalBody>
        {!isAlteracao && (
          <Nav tabs>
            {clienteAtual &&
              (typeof clienteAtual.clienteOperaConnect === 'undefined' ||
                clienteAtual.clienteOperaConnect === false ||
                (typeof clienteAtual.liberaXmlRedesconto !== 'undefined' &&
                  clienteAtual.liberaXmlRedesconto === true)) && (
                // eslint-disable-next-line react/jsx-indent
                <NavItem>
                  <NavLink
                    className={`${activeTab === '1' ? 'active' : ''}`}
                    onClick={() => {
                      toggleTab('1');
                    }}
                  >
                    Digitar manualmente
                  </NavLink>
                </NavItem>
              )}
            <NavItem>
              <NavLink
                className={`${activeTab === '2' ? 'active' : ''}`}
                onClick={() => {
                  toggleTab('2');
                }}
              >
                Importar arquivos
              </NavLink>
            </NavItem>
          </Nav>
        )}
        <TabContent activeTab={activeTab}>
          {((clienteAtual &&
            (typeof clienteAtual.clienteOperaConnect === 'undefined' ||
              clienteAtual.clienteOperaConnect === false ||
              (typeof clienteAtual.liberaXmlRedesconto !== 'undefined' &&
                clienteAtual.liberaXmlRedesconto === true))) ||
            isAlteracao) && (
            <TabPane tabId="1">
              <>
                {message && (
                  <div className="alert alert-danger animated fadeIn" role="alert">
                    {message}
                  </div>
                )}
                <Formik
                  ref={node => setForm(node)}
                  initialValues={formInitialValues}
                  validationSchema={formSchema(bordero, regras, clienteAtual, alteraCampos)}
                  onSubmit={handleFormSubmit}
                  enableReinitialize={true}
                  onReset={resetTitulo}
                  render={renderProps => {
                    const mergedProps = {
                      ...renderProps,
                      toggleTab,
                      activeTab,
                      toggle,
                      bordero,
                      regras,
                      bloquearFormulario:
                        clienteAtual &&
                        clienteAtual.clienteOperaConnect &&
                        !clienteAtual.liberaXmlRedesconto
                          ? true
                          : false,
                      liberaPreencherCedente:
                        clienteAtual &&
                        clienteAtual.clienteOperaConnect &&
                        clienteAtual.liberaXmlRedesconto
                          ? true
                          : false
                    };
                    return (
                      <InclusaoTituloForm
                        {...mergedProps}
                        setAlteraCampos={setAlteraCampos}
                        alteraCampos={alteraCampos}
                      />
                    );
                  }}
                />
              </>
            </TabPane>
          )}
          <TabPane tabId="2">
            <Row>
              <Col md="12" lg="12">
                <Label className="custom-label mb-3">Importe seus arquivos: </Label>
              </Col>
              <Col md="12" lg="12">
                <Dropzone
                  onDropAccepted={filesAcceptedHandler}
                  onDropRejected={filesRejectedHandler}
                  noDrag={false}
                  accept={getFilesToAccept()}
                  maxSize={TAMANHO_MAXIMO_UPLOAD}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <section>
                      <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <h4>Solte o arquivo aqui...</h4>
                        ) : (
                          <h4>
                            <span>
                              <i className="fas fa-paperclip file-icon" />
                            </span>
                            <span className="link">Selecione</span>
                            <span> o arquivo ou arraste aqui</span>
                          </h4>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
                {showFileRows()}
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="12">
                {message && (
                  <div className="alert alert-danger animated fadeIn" role="alert">
                    {message}
                  </div>
                )}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="md" className="mr-auto" outline onClick={handlerButtonfechar}>
          Fechar
        </Button>
        {((clienteAtual &&
          (clienteAtual.clienteOperaConnect === false || clienteAtual.liberaXmlRedesconto === true)) ||
          isAlteracao === null ||
          alteraCampos.length > 0) &&
          !submitting && (
            <Button
              color="primary"
              size="md"
              type="button"
              disabled={isUploading}
              onClick={handleButtonIncluir}
            >
              {isAlteracao ? 'Alterar' : 'Incluir'}
            </Button>
          )}
        {submitting && (
          <Button color="primary" size="md" type="button" disabled={isUploading}>
            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
            {isAlteracao ? 'Alterando...' : 'Incluindo...'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

ModalInclusaoTitulo.defaultProps = {
  modal: { show: false },
  regras: {},
  bordero: {}
};

export default memo(ModalInclusaoTitulo);
