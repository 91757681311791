import React, { useState, useEffect } from 'react';
import moment from 'moment';
import swal from '@sweetalert/with-react';

import Abatimento from './Dados/Abatimento';
import Recompra from './Dados/Recompra';
import Prorrogacao from './Dados/Prorrogacao';
import Baixa from './Dados/Baixa';

import { postSimularInstrucao, postInstrucao, getRegraDataAgendamento } from '../../../api/instrucoes';
import { unmaskValue } from '../../../utils/format';

const DadosStep = props => {
  const {
    instrucao,
    setIsPending,
    setInstrucao,
    isConfirmation,
    arrRules,
    setArrRUles,
    history
  } = props;
  const [simulacao, setSimulacao] = useState({
    isPending: false
  });
  const [regras, setRegras] = useState();
  var hasError = false;

  useEffect(() => {
    // https://reactjs.org/docs/hooks-rules.html#explanation
    if (!isConfirmation) {
      const newInstrucao = {
        ...instrucao
      };
      delete newInstrucao.motivo;
      delete newInstrucao.motivoDescricao;
      delete newInstrucao.motivoId;

      setInstrucao(newInstrucao);
    }
    if (!isConfirmation && !regras) {
      setIsPending(true);
      getRegraDataAgendamento(instrucao.tipoInstrucao.code)
        .then(dataAgendamento => {
          const newRegras = {
            dataAgendamento: dataAgendamento.data
          };
          setRegras(newRegras);
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, []);

  const getSimulacao = values => {
    setSimulacao({
      isPending: true
    });
    const sinularValues = parseValues(values);
    return postSimularInstrucao(sinularValues)
      .then(res => {
        const { valorSimulado } = res.data;
        setSimulacao({
          isPending: false,
          valorSimulado
        });
        return res;
      })
      .catch(err => {
        hasError = true;
        const {
          response: { data: { error: { message = 'Erro inesperado ao simular.' } = {} } = {} } = {}
        } = err;
        swal({
          text: message
        });
      })
      .finally(() => {
        if (hasError) {
          return history.push('/instrucoes');
        }
        hasError = false;
        setIsPending(false);
      });
  };

  const finishStep = values => {
    return postInstrucao(values)
      .then(() => {
        return history.push('/instrucoes');
      })
      .catch(err => {
        hasError = true;
        const {
          response: {
            data: { error: { message = 'Erro inesperado ao criar instrução' } = {} } = {}
          } = {}
        } = err;
        swal({
          text: message
        });
        return history.push('/instrucoes');
      })
      .finally(() => {
        if (!hasError) {
          swal({
            text: 'Instrução criada com sucesso!.'
          });
        }
        hasError = false;
        setIsPending(false);
      });
  };

  const parseValues = value => {
    let formValues = JSON.parse(JSON.stringify(value));

    if (formValues.abatimento) {
      formValues.abatimento.forEach(element => {
        element.abatimento = unmaskValue(element.abatimento);
      });
    }
    if (formValues.prorrogacao) {
      formValues.prorrogacao.forEach(element => {
        if (moment(element.dataVencimento, 'DD/MM/YYYY'))
          element.dataVencimento = moment(element.dataVencimento, 'DD/MM/YYYY').format(
            moment.HTML5_FMT.DATE
          );
      });
    }

    formValues = {
      ...formValues,
      motivo: formValues.motivoDescricao || formValues.motivo.descricao,
      dataAgendamento: moment(formValues.dataAgendamento, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE),
      codMotivo: formValues.motivo.id
    };
    delete formValues.motivoDescricao;
    return formValues;
  };

  const newProps = {
    ...props,
    getSimulacao,
    simulacao,
    arrRules,
    setArrRUles,
    finishStep,
    regras,
    parseValues,
    setInstrucao
  };

  // * 1 - Recompra.
  // * 2 - Abatimento.
  // * 3 - Prorrogação
  // * 4 - Baixa
  if (instrucao.tipoInstrucao.code === '1') {
    return <Recompra {...newProps} />;
  } else if (instrucao.tipoInstrucao.code === '2') {
    return <Abatimento {...newProps} />;
  } else if (instrucao.tipoInstrucao.code === '3') {
    return <Prorrogacao {...newProps} />;
  } else if (instrucao.tipoInstrucao.code === '4') {
    return <Baixa {...newProps} />;
  } else {
    return <p>Tipo de Instrução não suportado</p>;
  }
};

DadosStep.defaultProps = {
  isConfirmation: false
};

export default DadosStep;
