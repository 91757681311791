import React, { useEffect, useState, memo } from 'react';
import {
  Button,
  FormGroup,
  Label,
  UncontrolledTooltip,
  CustomInput,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import swal from '@sweetalert/with-react';

import { v4 } from 'uuid';
import {
  getCondicaoOperacionalVencimentoTitulo,
  deleteBorderoTitulos,
  deleteBorderoArquivoXML
} from '../../../../api/borderos';
import Avancar from '../../../../shared/icons/Avancar';
import VoltarIcon from '../../../../shared/icons/Voltar';

import ModalInclusaoTitulo from './ModalInclusaoTitulo/ModalInclusaoTitulo';
import ModalApagarTitulo from './ModalApagarTitulo';
import SkeletonLoader from '../../../../shared/components/common/SkeletonLoader';
import expandRow from '../../common/expandRow';
import { moneyFullFormat, transformTableTitulosData, scrollToId } from '../../../../utils/format';
import ModalConfirmacaoRetorno from './ModalConfrimacaoRetorno';
import ModalImportarPDF from './ModalImportarPDF/ModalImportarPDF';
import ModalImportarCartaCorrecao from './ModalImportarCartaCorrecao/ModalImportarCartaCorrecao';
import ModalListaTitulosCorrecao from './ModalListaTitulosCorrecao/ModalListaTitulosCorrecao';
import ModalImportarContratoFisico from './ModalImportarContratoFisico/ModalImportarContratoFisico';
import ModalImportarXml from './ModalImportarXml/ModalImportarXml';
import ModalImportarDocumentoAssinado from './ModalImportarDocumentoAssinado/ModalImportarDocumentoAssinado';
import Grid from '../../../commom/Grid';
import { downloadFileToken } from '../../../../shared';
import HistoricoAcesso from '../../../commom/HistoricoAcesso';

const SelectionStep = props => {
  const {
    clienteAtual,
    previousStep,
    nextStep,
    bordero = {},
    updateStateBordero,
    isPending: isParentPending,
    itensCount,
    setItensCount,
    titulosChecados,
    setTitulosChecados,
    collapseMenuRight,
    setCollapseMenuRight
  } = props;
  const [modalInclusao, setModalInclusao] = useState({
    show: false,
    titulo: null
  });
  const [modalApagar, setModalApagar] = useState({ show: false, bordero: bordero });
  const [modalApagarRetorno, setModalApagarRetorno] = useState({ show: false });
  const [modalModalUpload, setModalUpload] = useState({ show: false });
  const [modalModalAlertas, setModalAlertas] = useState({
    show: false,
    titulo: [],
    statusError: [],
    statusNotificacao: []
  });
  const [statusErrorAlertas, setStatusErrorAlertas] = useState([]);
  const [statusNotificacaoAlertas, setStatusNotificacao] = useState([]);
  const [rowAlertas, setRowAlertas] = useState([]);

  const [modalModalCartaCorrecao, setModalCartaCorrecao] = useState({ show: false });
  const [modalModalListaTitulosCorrecao, setModalListaTitulosCorrecao] = useState({ show: false });
  const [modalContratoFisico, setModalContratoFisico] = useState({ show: false });
  const [modalDocumentoConnect, setModalDocumentoConnect] = useState({ show: false });
  const [modalXml, setModalXml] = useState({ show: false });
  const [message, setMessage] = useState();
  const [messageModal, setMessageModal] = useState();
  const [haveFile, setHaveFile] = useState(false);
  const [haveAssDoc, sethaveAssDoc] = useState(false);
  const [haveContrato, setHaveContrato] = useState(false);
  const [listaNdocAjuste, setListaNdocAjuste] = useState([]);
  const [isLocalPending, setIsLocalPending] = useState(false);
  const [regras, setRegras] = useState();
  const isDuplicataServico =
    bordero && bordero.tipoTitulo && bordero.tipoTitulo.code && bordero.tipoTitulo.code === 'DS';
  const isDuplicata =
    bordero && bordero.tipoTitulo && bordero.tipoTitulo.code && bordero.tipoTitulo.code === 'DM';
  const isContratoFisico =
    bordero &&
    bordero.contratoFisico &&
    bordero.contratoFisico.code &&
    bordero.contratoFisico.code === 'S';
  const isCheque = bordero && bordero.tipoTitulo && bordero.tipoTitulo.code === 'CH';
  const isProdutoCm_Es =
    bordero &&
    bordero.tipoOperacao &&
    (bordero.tipoOperacao.code === 'CM' || bordero.tipoOperacao.code === 'ES');

  useEffect(() => {
    setHaveFile(bordero.arquivos);
    setHaveContrato(bordero.contratos);
    sethaveAssDoc(bordero.documentosAssinados);
  }, [bordero]);

  useEffect(() => {
    updateStateBordero();
  }, []);

  useEffect(() => {
    if (
      !regras &&
      !isLocalPending &&
      bordero.tipoOperacao &&
      bordero.tipoTitulo &&
      bordero.tipoEmpresa
    ) {
      setIsLocalPending(true);
      getCondicaoOperacionalVencimentoTitulo(
        bordero.tipoOperacao.code,
        bordero.tipoTitulo.code,
        bordero.tipoEmpresa.code
      )
        .then(res => {
          setRegras(res.data);
        })
        .finally(() => {
          setIsLocalPending(false);
        });
    }
  }, [isParentPending]);

  const updateParentBordero = (data = null) => {
    setMessage(null);
    if (data && data !== null) {
      updateStateBordero(data);
    } else {
      updateStateBordero();
    }
  };

  const confirmRetornar = () => {
    setIsLocalPending(true);
    deleteBorderoTitulos(bordero.id)
      .then(() => {
        previousStep();
        toggleApagarModalRetorno({});
      })
      .catch(err => {
        const {
          response: { data: { error: { messageModal = 'Erro inesperado ao salvar' } = {} } = {} } = {}
        } = err;
        setMessageModal(messageModal);
      })
      .finally(() => {
        setIsLocalPending(false);
      });
  };

  const toggleIncluirModal = ({ titulo = null, show = !modalInclusao.show }) => {
    if (clienteAtual.clienteEspecial && !isCheque && !isProdutoCm_Es) {
      swal({
        title: 'Atenção!',
        text: 'Operação não disponível para Clientes Especiais',
        icon: 'error',
        dangerMode: true
      });
    } else {
      setModalInclusao({ show, titulo, bordero });
    }
  };

  const toggleApagarModal = ({ titulo = null, tituloLote = null, show = !modalApagar.show }) => {
    setModalApagar({ show, titulo, tituloLote, bordero });
  };

  const toggleModalUpload = ({ show = !modalModalUpload.show }) => {
    setModalUpload({ show });
  };

  const toggleModalAlertas = ({
    titulo = [],
    show = !modalModalAlertas.show,
    statusError = [],
    statusNotificacao = []
  }) => {
    setModalAlertas({ show, titulo });
    setStatusErrorAlertas(statusError);
    setStatusNotificacao(statusNotificacao);
    setRowAlertas(titulo);
  };

  const toggleModalCartaCorrecao = ({ show = !modalModalCartaCorrecao.show, titulo = null }) => {
    setModalCartaCorrecao({ show, titulo });
  };

  const toggleModalContratoFisico = ({ show = !modalContratoFisico.show }) => {
    setModalContratoFisico({ show });
  };

  const toggleModalDocumentoConnect = ({ show = !modalContratoFisico.show }) => {
    setModalDocumentoConnect({ show });
  };

  const toggleModalXml = ({ show = !modalXml.show }) => {
    setModalXml({ show });
  };

  const toggleModalListaTitulosCorrecao = ({ show = !modalModalListaTitulosCorrecao.show }) => {
    setModalListaTitulosCorrecao({ show });
  };

  const deleteXmlFile = async (file, idFile) => {
    await swal({
      title: 'Atenção!',
      text: 'Deseja apagar o XML atrelado ao Título?',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar']
    }).then(confirm => {
      if (confirm) {
        deleteBorderoArquivoXML(file, idFile)
          .then(() => {
            updateStateBordero();
            swal({
              title: 'Exclusão',
              text: 'Arquivo XML apagado com sucesso!',
              icon: 'success'
            });
          })
          .catch(() => {
            swal({
              title: 'Erro',
              text: 'Erro ao apagar o Arquivo XML!',
              icon: 'error'
            });
          });
      }
    });
  };

  const toggleApagarModalRetorno = () => {
    if ((bordero.titulos || []).length !== 0) {
      setModalApagarRetorno({ show: !modalApagarRetorno.show });
    } else {
      previousStep();
    }
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setTitulosChecados([...titulosChecados, row.id]);
    } else {
      setTitulosChecados(titulosChecados.filter(x => x !== row.id));
    }
    // Toda vez que um checkbox é selecionado a variavel 'titulosChecados' vem a anterior, por isso +1 ou -1
    var qtdTitulosChecados = isSelect ? titulosChecados.length + 1 : titulosChecados.length - 1;
    setItensCount(qtdTitulosChecados);
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      setTitulosChecados(ids);
      setItensCount(ids.length);
    } else {
      setTitulosChecados([]);
      setItensCount(0);
    }
  };

  const handleOnCheckChange = event => {
    event.preventDefault();
    return false;
  };

  const handleOnCheckChangeCustomInput = () => {
    return true;
  };

  const handlerSelectAction = event => {
    const { value } = event.target;
    event.preventDefault();
    if (value === 'excluir_selecionados') {
      toggleApagarModal({ titulo: null, tituloLote: { cod_titulo_in: titulosChecados }, show: true });
    }
  };

  const handleNext = async () => {
    let isValid = true;
    setMessage();

    if (isValid && (bordero.titulos || []).length === 0) {
      isValid = false;
      setMessage('É obrigatório inserir pelo menos um título');
    }

    if (
      (isDuplicata || isDuplicataServico) &&
      isValid &&
      clienteAtual.clienteOperaConnect &&
      !haveAssDoc
    ) {
      isValid = false;
      const errorMessage =
        'Você deve incluir pelo menos um documento de assinatura, antes de prosseguir.';
      await swal({
        title: 'Atenção!',
        text: errorMessage,
        icon: 'error',
        dangerMode: true
      });
      setMessage(
        <>
          Favor incluir o(s) documento(s) de assinatura, através do botão
          <button
            type="button"
            id="btn-upload-example"
            color={haveContrato ? 'primary' : 'danger'}
            className="btn btn-outline-danger"
            style={{ pointerEvents: 'none', margin: '0 5px' }}
          >
            <i className="fa-solid fa-file-pdf" />
            Duplic. Assinadas
          </button>
        </>
      );
    }

    const hasStatusError =
      bordero.titulos &&
      bordero.titulos.find(titulo => {
        return (
          titulo.status &&
          titulo.status.find(status => {
            return status.tipo === 'trava' ? titulo : 0;
          })
        );
      });

    let tipoAlerta = '';
    const titulosInvalidos = {};
    bordero.titulos &&
      bordero.titulos.forEach(function(titulo) {
        titulo.status &&
          titulo.status.forEach(function(status) {
            if (status.subTipo === 'ndocForaDoPadrao') {
              titulosInvalidos[titulo.duplicata.id] = {
                ndoc: titulo.duplicata.ndoc,
                razaoSocial: titulo.sacado.juridica.razaoSocial,
                nf: titulo.duplicata.nf,
                vencimento: titulo.duplicata.vencimento,
                valor: titulo.duplicata.valor
              };
            } else if (
              status.subTipo === 'divergenciaSacado' ||
              status.subTipo === 'sacadoEmProdutoEscrowSimples'
            ) {
              tipoAlerta = status.subTipo;
            }
          });
      });

    if (isValid && Object.keys(titulosInvalidos).length > 0) {
      if (clienteAtual.clienteEspecial === true || bordero.tipoOperacao.code === 'SC') {
        isValid = false;
        toggleModalListaTitulosCorrecao({ show: true });
        setListaNdocAjuste(titulosInvalidos);
      } else {
        let errorMessage = `O número de documento está fora do padrão, caso queira prosseguir, este(s) título(s) será(ão) incluído(s) em uma operação Securitizadora.   
        De acordo com o padrão FIDC o número de documento deve ser:
        Ex: 12345-001 ("NF"-"Número da parcela") `;

        await swal({
          title: 'Atenção!',
          text: errorMessage,
          icon: 'warning',
          buttons: ['Prosseguir', 'Ajustar']
        }).then(cancel => {
          if (cancel) {
            toggleModalListaTitulosCorrecao({ show: true });
            setListaNdocAjuste(titulosInvalidos);
            isValid = false;
          } else {
            isValid = true;
          }
        });
      }
    }

    if (isValid && bordero.tipoOperacao.code === 'ES' && tipoAlerta === 'divergenciaSacado') {
      let errorMessage = `Há sacados nessa operação que não fazem parte deste produto, deseja prosseguir mesmo assim?`;
      await swal({
        title: 'Atenção!',
        text: errorMessage,
        icon: 'warning',
        buttons: ['Cancelar', 'Prosseguir']
      }).then(cancel => {
        if (!cancel) {
          isValid = false;
        } else {
          isValid = true;
        }
      });
    }

    if (isValid && bordero.tipoOperacao.code === 'CM' && tipoAlerta === 'sacadoEmProdutoEscrowSimples') {
      let errorMessage = `Há sacado(s) vinculado(s) ao produto Escrow Simples. Deseja continuar mesmo assim?`;
      await swal({
        title: 'Atenção!',
        text: errorMessage,
        icon: 'warning',
        buttons: ['Cancelar', 'Prosseguir']
      }).then(cancel => {
        if (!cancel) {
          isValid = false;
        } else {
          isValid = true;
        }
      });
    }

    if (isValid && hasStatusError) {
      isValid = false;
      const errorMessage =
        'É obrigatório ter todos título válidos, favor verificar os alertas vermelhos';
      setMessage(errorMessage);
      swal({
        title: 'Atenção!',
        text: errorMessage,
        icon: 'error',
        dangerMode: true
      }).then(buttonPress => {
        if (buttonPress) {
          let btnError = hasStatusError.duplicata
            ? hasStatusError.duplicata.id
            : hasStatusError.cheque.id;
          scrollToId('btn-danger-' + btnError);
        }
      });
    }

    const hasStatusNotificacao =
      bordero.titulos &&
      bordero.titulos.find(titulo => {
        return (
          titulo.status &&
          titulo.status.find(status => {
            return status.tipo === 'notificacao' ? titulo : 0;
          })
        );
      });

    const hasStatusNotificacaoCep =
      bordero.titulos &&
      bordero.titulos.find(titulo => {
        return (
          titulo.status &&
          titulo.status.find(status => {
            return status.tipoCep === 'S' ? titulo : 0;
          })
        );
      });

    if (isValid && hasStatusNotificacao) {
      let errorMessage = '';
      if (isValid && hasStatusNotificacaoCep) {
        errorMessage = 'Deseja continuar mesmo com notificações nos títulos. Existem CEPs inválidos!';
      } else {
        errorMessage = 'Deseja continuar mesmo com notificações nos títulos.';
      }
      await swal({
        title: 'Atenção!',
        text: errorMessage,
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar']
      }).then(cancel => {
        if (!cancel) {
          isValid = false;
          let btnNotif = hasStatusNotificacao.duplicata
            ? hasStatusNotificacao.duplicata.id
            : hasStatusNotificacao.cheque.id;
          scrollToId('btn-danger-' + btnNotif);
        } else {
          isValid = true;
        }
      });
    }

    if (isValid && isDuplicataServico && !haveFile) {
      isValid = false;
      const errorMessage =
        'Você deve incluir pelo menos um arquivo de duplicatas de serviço, ou o XML das notas, antes de prosseguir.';
      await swal({
        title: 'Atenção!',
        text: errorMessage,
        icon: 'error',
        dangerMode: true
      });
      setMessage(
        <>
          Favor incluir os documentos das duplicatas de serviço, ou os XML das notas através do botão
          <button
            type="button"
            id="btn-upload-example"
            className="btn btn-outline-danger"
            style={{ pointerEvents: 'none', margin: '0 5px' }}
          >
            <i className="fa fa-upload" />
            Inclusão de Arquivos
          </button>
        </>
      );
    }

    if (isValid && isContratoFisico && !haveContrato) {
      isValid = false;
      const errorMessage = 'Você deve incluir pelo menos um contrato físico antes de prosseguir.';
      await swal({
        title: 'Atenção!',
        text: errorMessage,
        icon: 'error',
        dangerMode: true
      });
      setMessage(
        <>
          Favor incluir os contratos físicos através do botão
          <button
            type="button"
            id="btn-upload-example"
            className="btn btn-outline-danger"
            style={{ pointerEvents: 'none', margin: '0 5px' }}
          >
            <i className="fa fa-upload" />
            Inclusão de Contrato
          </button>
        </>
      );
    }

    if (isValid) {
      nextStep();
    }
  };

  const handleDownloadFile = urlArquivo => {
    if (!urlArquivo) {
      setMessage('Falha para obter arquivo, informações ausentes.');
    }

    let ret = downloadFileToken(urlArquivo);
    if (ret !== true) {
      setMessage(ret);
    }
  };

  const criarButtonAcoes = row => {
    const statusError = row.status
      ? row.status.filter(status => {
          return ['trava', 'erro'].includes(status.tipo);
        })
      : null;

    const statusNotificacao = row.status
      ? row.status.filter(status => {
          return status.tipo === 'notificacao';
        })
      : null;

    const showNotification =
      (statusError && statusError.length > 0) || (statusNotificacao && statusNotificacao.length > 0);
    return (
      <>
        <Button
          color="primary"
          outline
          type="button"
          id={`btn-${row.id}`}
          className={`rounded-circle mr-1 ${
            clienteAtual.clienteEspecial && !isCheque && !isProdutoCm_Es ? 'disabled' : ''
          }`}
          title="Editar"
          onClick={() => {
            if (clienteAtual.clienteEspecial && !isCheque && !isProdutoCm_Es) {
              swal({
                title: 'Atenção!',
                text: 'Operação não disponível para Clientes Especiais',
                icon: 'error',
                dangerMode: true
              });
            } else {
              toggleIncluirModal({ titulo: row.originalData, show: true });
            }
          }}
        >
          <i className="fa fa-pen" />
        </Button>
        <Button
          color="primary"
          outline
          type="button"
          id={`btn-excluir-${row.id}`}
          className="rounded-circle mr-1"
          onClick={() => toggleApagarModal({ titulo: row.originalData, show: true })}
          title="Excluir"
        >
          <i className="fa fa-trash-alt" />
        </Button>
        {row.originalData.duplicata && row.originalData.duplicata.nomeXml !== null && (
          <UncontrolledDropdown
            tag="button"
            outline
            type="button"
            className="no-pad no-border no-outline background-transparent"
          >
            <DropdownToggle
              tag="button"
              outline
              type="button"
              id={`btn-danger-${row.id}`}
              className="rounded-circle mr-1 btn btn-outline-primary"
              title="XML do título, clique para ver mais detalhes"
            >
              <i className="fa-file-code" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                tag="a"
                className="btn-minimal text-align-center no-outline"
                target="blank"
                onClick={() => handleDownloadFile(row.originalData.duplicata.nomeXml)}
              >
                BAIXAR XML
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                className="btn-minimal text-align-center no-outline"
                onClick={() => {
                  deleteXmlFile(bordero.id, row.originalData.duplicata.codArquivoXml);
                }}
              >
                EXCLUIR XML
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {row.originalData.totalCartaCorrecao && row.originalData.totalCartaCorrecao > 0 && (
          <Button
            color="primary"
            outline
            type="button"
            id={`btn-excluir-${row.id}`}
            className="rounded-circle mr-1"
            onClick={() => {
              toggleModalCartaCorrecao({ show: true, titulo: row.originalData });
            }}
            title={`Ver ${row.originalData.totalCartaCorrecao > 1 ? 'Cartas' : 'Carta'} de correção`}
          >
            <i className="fa fa-file-alt" />
          </Button>
        )}
        {showNotification && (
          <>
            <Button
              color={statusError.length ? 'danger' : 'warning'}
              outline
              type="button"
              id={`btn-danger-${row.id}`}
              className="rounded-circle mr-1"
              title="Existem alerta(s) nesse título, clique para ver mais detalhes"
              onClick={() => {
                toggleModalAlertas({
                  titulo: row,
                  show: true,
                  statusError,
                  statusNotificacao
                });
              }}
            >
              <i className="fa fa-exclamation" />
            </Button>
          </>
        )}
      </>
    );
  };

  const criarCheckBox = ({ mode, checked, disabled, rowIndex }) => (
    <CustomInput
      type={mode}
      checked={checked}
      disabled={disabled}
      onChange={handleOnCheckChangeCustomInput}
      id={'chk' + rowIndex}
      className="label-pointer-none"
    />
  );

  const criarCheckBoxAll = ({ mode, checked }) => (
    <CustomInput
      type={mode}
      checked={checked}
      onChange={handleOnCheckChange}
      id="chkAll"
      className="label-pointer-none"
    />
  );

  return (
    <>
      <div className="selection-step">
        <div className="row row-content">
          <div
            className={
              (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
              ' col-12 content-body col-selection-step'
            }
          >
            <div className="body">
              <div className="align-items-center grid-buttons-selecao">
                <h1 className="title">Títulos para antecipação</h1>
                {(!isParentPending || isLocalPending) && (
                  <>
                    <Button
                      color="primary"
                      outline
                      className={`incluir-titulos ${
                        clienteAtual.clienteEspecial && !isCheque && !isProdutoCm_Es ? 'disabled' : ''
                      }`}
                      onClick={toggleIncluirModal}
                    >
                      Incluir Títulos
                    </Button>

                    {bordero &&
                      bordero.titulos &&
                      bordero.titulos.length !== 0 &&
                      bordero.statusImportXmlTit &&
                      (bordero.statusImportXmlTit === 'OK' ||
                        bordero.statusImportXmlTit === 'NAO-OK') && (
                        <>
                          <Button
                            color={
                              bordero.statusImportXmlTit && bordero.statusImportXmlTit === 'OK'
                                ? 'primary'
                                : isContratoFisico || bordero.tipoEmpresa.code !== '6'
                                ? 'warning'
                                : 'danger'
                            }
                            outline
                            type="button"
                            id="btn-upload-xml"
                            onClick={() => toggleModalXml(true)}
                            className="btn-upload-xml"
                          >
                            <i className="fa fa-upload" />
                            Atrelar XML
                          </Button>
                          <UncontrolledTooltip
                            target="btn-upload-xml"
                            placement="top"
                            className="bold-font"
                          >
                            Subir arquivos XML obrigatorios dos titulos incluidos
                          </UncontrolledTooltip>
                        </>
                      )}

                    {isDuplicataServico && (
                      <>
                        <Button
                          color={haveFile ? 'primary' : 'danger'}
                          outline
                          className="margin-right-10"
                          type="button"
                          id="btn-upload"
                          onClick={() => toggleModalUpload(true)}
                        >
                          <i className="fa fa-upload" />
                          Inclusão de Arquivos
                        </Button>
                        <UncontrolledTooltip target="btn-upload" placement="top" className="bold-font">
                          Suba aqui os documento(s) de duplicata de serviço, ou o XML da nota
                        </UncontrolledTooltip>
                      </>
                    )}
                    {(isDuplicata || isDuplicataServico) && clienteAtual.clienteOperaConnect && (
                      <>
                        <Button
                          color={haveAssDoc ? 'primary' : 'danger'}
                          outline
                          className="margin-right-10"
                          type="button"
                          id="btn-upload-doc-ass"
                          onClick={() => toggleModalDocumentoConnect(true)}
                        >
                          <i className="fa-solid fa-file-pdf" />
                          Duplic. Assinadas
                        </Button>
                        <UncontrolledTooltip
                          target="btn-upload-doc-ass"
                          placement="top"
                          className="bold-font"
                        >
                          Subir Documentos Assinados
                        </UncontrolledTooltip>
                      </>
                    )}
                    {isContratoFisico && (
                      <>
                        <Button
                          color={haveContrato ? 'primary' : 'danger'}
                          outline
                          className="margin-right-10"
                          type="button"
                          id="btn-upload"
                          onClick={() => toggleModalContratoFisico(true)}
                        >
                          <i className="fa fa-upload" />
                          Inclusão de Contrato
                        </Button>
                        <UncontrolledTooltip target="btn-upload" placement="top" className="bold-font">
                          Subir contrato físico
                        </UncontrolledTooltip>
                      </>
                    )}
                  </>
                )}
              </div>

              <SkeletonLoader
                isPending={isParentPending || isLocalPending}
                width="60%"
                height="30px"
                count={8}
              >
                <>
                  {message && (
                    <div className="alert alert-danger animated fadeIn" role="alert">
                      {message}
                      <i
                        role="button"
                        className="fa fa-times float-right cursor-pointer"
                        onClick={() => setMessage(null)}
                        onKeyPress={() => setMessage(null)}
                        tabIndex={0}
                      />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-12 col-absolute">
                      <div className="col-md-12 d-flex flex-row-reverse bd-highlight">
                        <div
                          className={`form-inline selecao-itens animated ${
                            itensCount ? 'fadeIn' : 'fadeOut'
                          }`}
                        >
                          <span className="selecao-itens-numero m-2 half-rounded-circle">
                            {itensCount}
                          </span>
                          Ítens selecionados
                          <select
                            className="form-control ml-2"
                            disabled={itensCount === 0}
                            onChange={handlerSelectAction}
                            value="nenhuma"
                          >
                            <option value="nenhuma">Ação</option>
                            <option value="excluir_selecionados">Excluir Selecionados</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'grid-scrollable-content ' + (message ? 'haveMessage' : '')}>
                    <Grid
                      keyField="id"
                      data={transformTableTitulosData(bordero.titulos) || []}
                      expandRow={expandRow}
                      tableSearch={true}
                      classes="table-layout-auto"
                      selectRow={{
                        mode: 'checkbox',
                        // clickToSelect: true,
                        classes: 'trChecked', //classe da TR quando checkbox checado
                        selected: titulosChecados,
                        onSelect: handleOnSelect,
                        onSelectAll: handleOnSelectAll,
                        selectionRenderer: criarCheckBox,
                        selectionHeaderRenderer: criarCheckBoxAll
                      }}
                      configuracaoColunas={[
                        { dataField: 'sacado.nome', text: 'SACADO' },
                        {
                          dataField: 'tipoOperacao.ndoc',
                          text: 'Nº DO DOC.',
                          tipoOrdenacao: 'texto'
                        },
                        {
                          dataField: 'tipoOperacao.vencimento',
                          text: 'VENCIMENTO',
                          formatacao: 'dataOuIfem',
                          tipoOrdenacao: 'data'
                        },
                        {
                          dataField: 'tipoOperacao.valor',
                          text: 'VALOR BRUTO',
                          formatacao: 'valor',
                          tipoOrdenacao: 'float'
                        },
                        {
                          dataField: 'btnAcoes',
                          text: '',
                          formatacao: 'button',
                          evento: criarButtonAcoes,
                          sort: false
                        }
                      ]}
                    />
                    {(bordero.titulos || []).length === 0 && (
                      <div className="empty-table-message">
                        {/* <h4 className="title">Hmm…</h4> <p>Parece que você não tem histórico de dados suficientes.</p> */}
                      </div>
                    )}
                  </div>
                </>
              </SkeletonLoader>
            </div>
            <div className="footer d-flex justify-content-between">
              <Button
                className="mr-auto btn-back"
                size="lg"
                outline
                color="primary"
                type="button"
                disabled={isParentPending || isLocalPending}
                onClick={toggleApagarModalRetorno}
              >
                <i className="svg-icon">
                  <VoltarIcon />
                </i>
                Voltar
              </Button>

              <Button
                size="lg"
                className="ml-3"
                color="primary"
                type="button"
                outline={true}
                disabled={isParentPending || isLocalPending}
                onClick={handleNext}
              >
                <i className="svg-icon">
                  <Avancar />
                </i>
                Avançar
              </Button>
            </div>
          </div>
          {!collapseMenuRight && (
            <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
              <div className="fixed-side">
                {bordero && bordero.id && (
                  <>
                    <div
                      role="button"
                      className="header cursor-pointer"
                      onClick={() => setCollapseMenuRight(true)}
                      onKeyPress={() => setCollapseMenuRight(true)}
                      tabIndex={0}
                    >
                      <h4 className="title">
                        Detalhes Borderô
                        <i className="fa fa-angle-right float-right" />
                      </h4>
                    </div>
                    <div className="info infoBordero">
                      <FormGroup>
                        <Label>Cliente:</Label>
                        <p className="form-control-static">{clienteAtual.razaoSocial}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Conta Corrente:</Label>
                        <p className="form-control-static">{bordero.contaCorrente.descricao}</p>
                      </FormGroup>
                      {bordero.contaEscrow && bordero.contaEscrow.descricao ? (
                        <FormGroup>
                          <Label>Conta para cobrança Escrow:</Label>
                          <p className="form-control-static">{bordero.contaEscrow.descricao}</p>
                        </FormGroup>
                      ) : (
                        ''
                      )}
                      <FormGroup>
                        <Label>Tipo de Empresa:</Label>
                        <p className="form-control-static">{bordero.tipoEmpresa.descricao}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Tipo de Operação:</Label>
                        <p className="form-control-static">{bordero.tipoOperacao.descricao}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Tipo de Documento:</Label>
                        <p className="form-control-static text-capitalize">
                          {bordero.tipoTitulo.descricao}
                          {isContratoFisico ? ' (Com Contrato Físico)' : ''}
                        </p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Quantidade de Títulos:</Label>
                        <p className="form-control-static">{bordero.titulosQuantidade}</p>
                      </FormGroup>
                      <FormGroup>
                        <SkeletonLoader
                          isPending={isParentPending || isLocalPending}
                          width="300px"
                          height="40px"
                        >
                          <Label>Total bruto:</Label>
                          <p className="form-control-static total-price">
                            {moneyFullFormat(bordero.titulosTotalValorBruto)}
                          </p>
                        </SkeletonLoader>
                      </FormGroup>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <Modal isOpen={modalModalAlertas.show} toggle={toggleModalAlertas} centered size="sm">
          <ModalHeader toggle={toggleModalAlertas}>Alerta!</ModalHeader>
          <ModalBody className="text-align-center">
            <div>
              {statusErrorAlertas && statusErrorAlertas.length > 0 ? (
                <p>
                  Esse título possui iconsistências que
                  <strong className="text-danger"> impedem </strong>
                  você de prosseguir até que elas sejam corrigidas.
                </p>
              ) : (
                <p>
                  As inconsistências listadas abaixo
                  <strong className="text-warning"> não impedem </strong>
                  você de prosseguir com a finalização do seu borderô.
                </p>
              )}
              <ul className="list-group list-group-flush max-height">
                {statusErrorAlertas.map(status => {
                  return (
                    <div key={v4()} className="alert alert-danger" role="alert">
                      {status.mensagem}
                      {status.botao && status.botao === 'carta_correcao' ? (
                        <button
                          type="button"
                          onClick={() => {
                            toggleModalCartaCorrecao({ show: true, titulo: rowAlertas.originalData });
                            toggleModalAlertas({});
                          }}
                          title="Importar Carta de Correção"
                          className="btn btn-primary btn-xs"
                          style={{ marginLeft: '15px' }}
                        >
                          <i className="fa fa-upload" />
                          <span>Importar</span>
                        </button>
                      ) : (
                        ''
                      )}
                      {status.botao && status.botao === 'arquivo_xml' ? (
                        <button
                          type="button"
                          onClick={() => {
                            toggleModalAlertas({});
                            toggleModalXml({ show: true });
                          }}
                          title="Abrir importação de arquivo XML"
                          className="btn btn-danger btn-xs"
                          style={{ marginLeft: '15px' }}
                        >
                          <i className="fa fa-upload" />
                          <span>Atrelar XML</span>
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}

                {statusNotificacaoAlertas.map(status => {
                  return (
                    <div key={v4()} className="alert alert-warning" role="alert">
                      {status.mensagem}
                      {status.botao && status.botao === 'carta_correcao' ? (
                        <button
                          type="button"
                          onClick={() => {
                            toggleModalCartaCorrecao({ show: true, titulo: rowAlertas.originalData });
                            toggleModalAlertas({});
                          }}
                          title="Importar Carta de Correção"
                          className="btn btn-primary btn-xs"
                          style={{ marginLeft: '15px' }}
                        >
                          <i className="fa fa-upload" />
                          <span>Importar</span>
                        </button>
                      ) : (
                        ''
                      )}
                      {status.botao && status.botao === 'arquivo_xml' ? (
                        <button
                          type="button"
                          onClick={() => {
                            toggleModalAlertas({});
                            toggleModalXml({ show: true });
                          }}
                          title="Abrir importação de arquivo XML"
                          className="btn btn-warning btn-xs"
                          style={{ marginLeft: '15px' }}
                        >
                          <i className="fa fa-upload" />
                          <span>Importar XML</span>
                        </button>
                      ) : (
                        ''
                      )}
                      {status.botao && status.botao === 'alterar_titulo' ? (
                        <button
                          type="button"
                          onClick={() => {
                            toggleModalAlertas({});
                            toggleIncluirModal({ show: true, titulo: rowAlertas.originalData });
                          }}
                          title="Abrir Alteração de Título"
                          className="btn btn-warning btn-xs"
                          style={{ marginLeft: '15px' }}
                        >
                          <i className="fa fa-pencil" />
                          <span>Ajustar</span>
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}
              </ul>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" size="md" onClick={toggleModalAlertas}>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        {modalInclusao &&
          !(clienteAtual.clienteEspecial && !isCheque && !isProdutoCm_Es) &&
          modalInclusao.show && (
            <ModalInclusaoTitulo
              modal={modalInclusao}
              toggle={toggleIncluirModal}
              bordero={bordero}
              regras={regras}
              updateBordero={updateParentBordero}
              clienteAtual={clienteAtual}
            />
          )}

        {modalApagar && modalApagar.show && (
          <ModalApagarTitulo
            modal={modalApagar}
            toggle={toggleApagarModal}
            updateParentBordero={updateParentBordero}
          />
        )}

        {modalApagarRetorno && modalApagarRetorno.show && (
          <ModalConfirmacaoRetorno
            modal={modalApagarRetorno}
            toggle={toggleApagarModalRetorno}
            message={messageModal}
            confirm={confirmRetornar}
          />
        )}

        {modalModalUpload && modalModalUpload.show && (
          <ModalImportarPDF
            bordero={bordero}
            updateBordero={updateStateBordero}
            modal={modalModalUpload}
            toggle={toggleModalUpload}
            updateFileStatus={setHaveFile}
          />
        )}

        {modalModalCartaCorrecao && modalModalCartaCorrecao.show && (
          <ModalImportarCartaCorrecao
            entrada={bordero}
            updateBordero={updateStateBordero}
            modal={modalModalCartaCorrecao}
            toggle={toggleModalCartaCorrecao}
            updateFileStatus={setHaveFile}
          />
        )}

        {modalModalListaTitulosCorrecao && modalModalListaTitulosCorrecao.show && (
          <ModalListaTitulosCorrecao
            modal={modalModalListaTitulosCorrecao}
            listaNdocAjuste={listaNdocAjuste}
            setListaNdocAjuste={setListaNdocAjuste}
            toggle={toggleModalListaTitulosCorrecao}
            bordero={bordero}
            updateBordero={updateParentBordero}
          />
        )}

        {modalContratoFisico && modalContratoFisico.show && (
          <ModalImportarContratoFisico
            bordero={bordero}
            updateBordero={updateStateBordero}
            modal={modalContratoFisico}
            toggle={setModalContratoFisico}
            updateFileStatus={setHaveContrato}
          />
        )}

        {modalDocumentoConnect && modalDocumentoConnect.show && (
          <ModalImportarDocumentoAssinado
            bordero={bordero}
            updateBordero={updateStateBordero}
            modal={modalDocumentoConnect}
            toggle={setModalDocumentoConnect}
            updateFileStatus={sethaveAssDoc}
          />
        )}

        {modalXml && modalXml.show && (
          <ModalImportarXml
            bordero={bordero}
            updateBordero={updateStateBordero}
            modal={modalXml}
            toggle={setModalXml}
          />
        )}
      </div>
      <HistoricoAcesso codTela={1194} />
    </>
  );
};

export default memo(SelectionStep);
