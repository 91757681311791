import React, { useState } from 'react';
import { FastField, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Label, Card, CardBody, Container } from 'reactstrap';
import * as CNPJ from '@fnando/cnpj';
import * as CPF from '@fnando/cpf';
import ReCAPTCHA from 'react-google-recaptcha';
import strip from '../../utils/strip';
import { getVisaoSacadoTitulos } from '../../api/relatorios';
import one7Logo from '../../assets/img/one7-logo.png';
import SkeletonLoader from '../../shared/components/common/SkeletonLoader';
import { CPFInput, If } from '../../shared';
import Grid from '../commom/Grid';
import titulosGrid from './SegundaViaGrid';
import { moneyFullFormat, transformTableTitulosData } from '../../utils/format';

export default () => {
  const [isPending, setPending] = useState(false);
  const [message, setMessage] = useState(false);
  const [relatorios, setRelatorios] = useState({});
  const [itensCount, setItensCount] = useState(0);
  const [isBtnFiltrarDisabled, setBtnFiltrarDisabled] = useState(true);
  const [checkedAll, setCheckedAll] = useState({ checked: false, disabled: false });
  const recaptchaRef = React.createRef();

  const handleSubmit = (values, actions) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    setPending(true);
    setRelatorios({});
    setMessage();
    values = {
      ...values,
      recaptchaValue: recaptchaValue,
      cpfCnpjCedente: strip(values.cpfCnpjCedente),
      cnpj: strip(values.cnpj)
    };

    return getRelatoriosHandler(values).finally(() => {
      actions.setSubmitting(false);
      setBtnFiltrarDisabled(true);
    });
  };

  const getRelatoriosHandler = values => {
    return getVisaoSacadoTitulos(values)
      .then(res => {
        let { data } = res;
        let valorTotal =
          data.length && data.map(item => item.valorDevido).reduce((acc, curr) => acc + curr);
        let valorDevido =
          data.length && data.map(item => item.valorTitulo).reduce((acc, curr) => acc + curr);

        data.forEach(item => {
          let disabled = true;
          if (item.boleto) {
            disabled = false;
          }

          item.meta = {
            visible: true,
            disabled: disabled,
            checked: false
          };
        });

        let newRelatorios = {
          data,
          valorTotal,
          valorDevido
        };
        setRelatorios(newRelatorios);

        setCheckedAll({ checked: false, disabled: false });

        if (data.length === 0) {
          setMessage('Nenhum resultado foi encontrado com estes filtros');
        }
      })
      .catch(() => {
        setMessage('Por favor, preencha o captcha e tente novamente.');
      })
      .finally(() => {
        setPending(false);
        setItensCount(0);
      });
  };

  const handleCheck = event => {
    const { checked, value } = event.target;
    const newRelatorios = [...relatorios.data];
    let intensCount = 0;
    let marcaCheckAll = newRelatorios.length > 0 ? true : false;
    let desmarcaCheckAll = false;

    newRelatorios.forEach(item => {
      if (parseInt(item.id) === parseInt(value)) {
        item.meta.checked = checked;
      }

      if (item.meta.checked) {
        intensCount++;
      } else if (item.boleto) {
        desmarcaCheckAll = true;
        marcaCheckAll = false;
      }
    });

    const valorTotal =
      newRelatorios.length &&
      newRelatorios.map(item => item.valorDevido).reduce((acc, curr) => acc + curr);
    const valorDevido =
      newRelatorios.length &&
      newRelatorios.map(item => item.valorTitulo).reduce((acc, curr) => acc + curr);

    newRelatorios.data = newRelatorios;
    newRelatorios.valorTotal = valorTotal;
    newRelatorios.valorDevido = valorDevido;

    setItensCount(intensCount);
    setRelatorios(newRelatorios);

    if (marcaCheckAll === true) {
      setCheckedAll({ checked: true, disabled: false });
    } else if (desmarcaCheckAll === true) {
      setCheckedAll({ checked: false, disabled: false });
    }
  };

  const handleCheckAll = event => {
    let { checked } = event.target;
    let newRelatorios = [...relatorios.data];

    let intensCount = 0;
    newRelatorios.forEach(item => {
      if (item.boleto) {
        item.meta.checked = checked;
      }
      if (item.meta.checked) {
        intensCount++;
      }
    });

    let valorTotal =
      newRelatorios.length &&
      newRelatorios.map(item => item.valorDevido).reduce((acc, curr) => acc + curr);
    let valorDevido =
      newRelatorios.length &&
      newRelatorios.map(item => item.valorTitulo).reduce((acc, curr) => acc + curr);

    newRelatorios.data = newRelatorios;
    newRelatorios.valorTotal = valorTotal;
    newRelatorios.valorDevido = valorDevido;

    setItensCount(intensCount);
    setRelatorios(newRelatorios);
    setCheckedAll({ checked: checked, disabled: false });
  };

  const handlerSelectAction = event => {
    const { value } = event.target;
    event.preventDefault();

    const newRelatorios = [...relatorios.data];
    let url = '';

    let boletos = [];
    newRelatorios.forEach(item => {
      url = item.urlBaseImpressaoBoleto;
      if (item.meta.checked && item.boleto.emLote != null && item.boleto.emLote.length > 0) {
        boletos.push(item.boleto.emLote);
      }
    });

    if (boletos && boletos.length > 0) {
      url += '/iSeven/boleto_lote.php?tipoLayout=' + value;
      var winBoleto = window.open('', `Boleto `, 'resizable,scrollbars,status');
      var boletosHtml = `<form id="form" method="post" action="${url}">`;
      boletos.forEach(token => {
        boletosHtml += `<input type="hidden" name="token[]" value="${token}" />`;
      });
      boletosHtml += '</form>';
      boletosHtml += "<script>document.getElementById('form').submit();</script>";
      winBoleto.document.write(boletosHtml);
    } else {
      alert('Sem boletos para geraçao');
    }
  };

  const verificacaoReCaptcha = value => {
    if (value) {
      setBtnFiltrarDisabled(false);
    } else {
      setBtnFiltrarDisabled(true);
    }
  };

  const mensagemButton = () => {
    return isBtnFiltrarDisabled ? 'Preencha o captcha para pesquisar' : '';
  };

  const CPFInputWrapper = props => <CPFInput {...props} />;

  const verificaMobille = () => {
    let mobille = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? 'SITE'
      : 'ACESSAR SITE';
    return mobille;
  };

  return (
    <Container className="authentication d-flex justify-content-center heightAuto">
      <Card className="segundaVia animated fadeIn">
        <CardBody>
          <div className="row alignItensCenter">
            <div className="col-lg-5 marginLeft-15">
              <img width={140} src={one7Logo} alt="one7 logo" />
            </div>
            <div className="col-lg-5">
              <div className="font30"> 2ª Via Boleto</div>
            </div>
            <div className="col-lg-2">
              <a
                className="visaoSacadoBtn btnVsCuston"
                disabled={isPending}
                href="https://one7.com.vc"
                target="_blank"
                rel="noopener noreferrer"
              >
                {verificaMobille()}
              </a>
            </div>
          </div>
          <hr />
          <Formik
            initialValues={{
              cpfCnpjCedente: '',
              cnpj: ''
            }}
            validationSchema={Yup.object().shape({
              cpfCnpjCedente: Yup.string()
                .nullable()
                .required('Campo Obrigatório.')
                .test({
                  exclusive: true,
                  message: params => {
                    const strippedValue = strip(params.value);
                    if (strippedValue.length === 11) {
                      return 'CPF inválido.';
                    } else if (strippedValue.length === 14) {
                      return 'CNPJ inválido.';
                    }
                    return 'CPF/CNPJ inválido.';
                  },
                  name: 'CPF-CNPJ',
                  test: value => {
                    if (value) {
                      return CNPJ.isValid(value) || CPF.isValid(value);
                    }
                    return true;
                  }
                }),
              cnpj: Yup.string()
                .nullable()
                .required('Campo Obrigatório.')
                .test({
                  exclusive: true,
                  message: params => {
                    const strippedValue = strip(params.value);
                    if (strippedValue.length === 11) {
                      return 'CPF inválido.';
                    } else if (strippedValue.length === 14) {
                      return 'CNPJ inválido.';
                    }
                    return 'CPF/CNPJ inválido.';
                  },
                  name: 'CPF-CNPJ',
                  test: value => {
                    if (value) {
                      return CNPJ.isValid(value) || CPF.isValid(value);
                    }
                    return true;
                  }
                }),
              reCaptcha: Yup.string().test({
                exclusive: true,
                message: () => {
                  return 'Prencha o Captcha.';
                },
                name: 'reCaptcha',
                test: () => {
                  let recaptchaValue = recaptchaRef.current.getValue();
                  if (recaptchaValue) {
                    return true;
                  }
                  return false;
                }
              })
            })}
            onSubmit={handleSubmit}
            render={({ isSubmitting }) => {
              return (
                <div className="row">
                  <Form className="col-lg-3">
                    <FastField
                      type="text"
                      label="CEDENTE"
                      labelClassName="required-label"
                      name="cpfCnpjCedente"
                      id="cpfCnpjCedente"
                      disabled={isSubmitting}
                      autoComplete="nope"
                      component={CPFInputWrapper}
                      placeholder="INFORME UM CNPJ"
                    />

                    <FastField
                      type="text"
                      label="SACADO"
                      labelClassName="required-label"
                      name="cnpj"
                      id="cnpj"
                      disabled={isSubmitting}
                      autoComplete="nope"
                      component={CPFInputWrapper}
                      placeholder="INFORME UM CPF OU CNPJ"
                    />

                    <ReCAPTCHA
                      name="reCaptcha"
                      ref={recaptchaRef}
                      sitekey="6LegwOMUAAAAAGRAeW77vEWbLEWKX6-YcJovqkj0" //<- CHAVE PUBLICA DO RECAPTCHA
                      onChange={verificacaoReCaptcha}
                      className="responsividadeCaptcha"
                    />

                    {message && message !== 'Nenhum resultado foi encontrado com estes filtros' && (
                      <div className="alert alert-danger animated fadeIn" role="alert">
                        {message}
                      </div>
                    )}

                    <div className="button-group">
                      <Button
                        type="submit"
                        color="primary"
                        className="ml-auto"
                        title={mensagemButton()}
                        disabled={isPending || isSubmitting || isBtnFiltrarDisabled}
                      >
                        <i className="fa fa-search" />
                        Pesquisar
                      </Button>
                    </div>
                  </Form>

                  <SkeletonLoader
                    isPending={isPending}
                    width="100%"
                    height="40px"
                    count={8}
                    widthRandomness={0}
                  >
                    <If test={Object.keys(relatorios).length > 0}>
                      <div className="col-md-12 d-flex flex-row-reverse bd-highlight marginBotton10">
                        <div
                          className={`form-inline selecao-itens animated ${
                            itensCount ? 'fadeIn' : 'fadeOut esconder'
                          }`}
                        >
                          <span className="selecao-itens-numero m-2 half-rounded-circle">
                            {itensCount}
                          </span>
                          {itensCount > 1 ? 'Boletos em Lote' : 'Boleto em Lote'}
                          <select
                            className="form-control ml-2"
                            disabled={itensCount === 0}
                            onChange={handlerSelectAction}
                            value="nenhuma"
                          >
                            <option value="nenhuma">Imprimir</option>
                            <option value="original">Original</option>
                            <option value="atualizado">Atualizado</option>
                          </select>
                        </div>
                      </div>

                      <Grid
                        keyField="id"
                        tableSearch={true}
                        data={transformTableTitulosData(relatorios.data)}
                        configuracaoColunas={titulosGrid(handleCheck, checkedAll, handleCheckAll)}
                        mensagem={message}
                        classes="table-layout-fixed"
                        classeIdentificadora="visaoSacadoTitulos"
                      />

                      {relatorios.data && relatorios.data.length > 0 && (
                        <div className="col-lg-12" style={{ marginTop: 5 }}>
                          <div className="col-md-12 ml-auto mt-12 text-right">
                            <Label className="col-md-12 col-lg-6 col-xl-3 align-items-center">
                              Quantidade
                              <span className="badge badge-visaoSacado">{relatorios.data.length}</span>
                            </Label>
                            <Label className="col-md-12 col-lg-6 col-xl-3 align-items-center">
                              Valor Total
                              <span className="badge badge-visaoSacado">
                                {moneyFullFormat(relatorios && relatorios.valorDevido)}
                              </span>
                            </Label>
                          </div>
                        </div>
                      )}
                    </If>
                  </SkeletonLoader>
                </div>
              );
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
