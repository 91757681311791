import React from 'react';
import MaskedInput, { conformToMask } from 'react-text-mask';
import ReactstrapFormik from '../ReactstrapFormik/ReactstrapFormik';

export default props => {
  // Retira as propriedades do formulario formik e as customs
  const { labelClassName, label, field, returnConformed, form, mask, children, ...restProps } = props;

  if (returnConformed) {
    field.value = conformToMask(field.value || '', mask, {
      guide: false,
      keepCharPositions: true
    }).conformedValue;
  }

  return (
    <MaskedInput
      mask={mask}
      guide={false}
      {...restProps}
      keepCharPositions={true}
      render={(ref, maskedInputProps) => {
        const { defaultValue, ...restMaskedInputProps } = maskedInputProps;
        const inputProps = {
          labelClassName,
          label,
          field,
          form,
          ...restMaskedInputProps
        };
        return <ReactstrapFormik {...inputProps} innerRef={ref} />;
      }}
    />
  );
};
