import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik, FastField, Field } from 'formik';
import moment from 'moment';
import { Button, Card, CardBody, Col, Label } from 'reactstrap';
import * as Yup from 'yup';

import SkeletonLoader from '../../shared/components/common/SkeletonLoader';
import Main from '../Main/Main';
import { DateTimePicker, Select, Radio, getClienteAtual } from '../../shared';
import { getRetornos, getRetornoArquivo, getTiposEmpresas } from '../../api/borderos';
import { dateFormat } from '../../utils/format';
import HistoricoAcesso from '../commom/HistoricoAcesso';

const today = dateFormat(moment());
const lastWeek = dateFormat(moment().subtract(6, 'days'));

const initialValues = {
  empresa: '',
  data: {
    inicio: lastWeek,
    fim: today
  },
  filtro: 'A'
};

const filtros = [
  {
    label: 'Ambos',
    value: 'A'
  },
  {
    label: 'Rejeitados',
    value: 'S'
  },
  {
    label: 'Não rejeitados',
    value: 'N'
  }
];

const Retorno = props => {
  const [retornos, setRetornos] = useState([]);
  const [tipoEmpresas, setTipoEmpresas] = useState([]);
  const [message, setMessage] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [empresa, setEmpresa] = useState('');
  const [filtro, setFiltro] = useState('A');
  // Douglas - 24/01/20 - Ao carregar a pagina, caso o tamanho da janela seja menor ou igual a 600px ja carrega o menu collapsado
  const [collapseMenuRight, setCollapseMenuRight] = useState(window.innerWidth <= 600 ? true : false);
  const clienteAtual = getClienteAtual();
  const hist = useHistory();

  useEffect(() => {
    if (clienteAtual && clienteAtual.id === 'todos') {
      hist.push('/home');
    }
  }, [clienteAtual]);

  useEffect(() => {
    setIsPending(true);
    getRetornos(initialValues)
      .then(res => {
        setRetornos(res.data);
      })
      .catch(() => {
        setRetornos([]);
      })
      .finally(() => {
        setIsPending(false);
      });
    getTiposEmpresas().then(res => {
      setTipoEmpresas(
        res.data.map(empresa => {
          return {
            value: empresa.code,
            label: empresa.descricao
          };
        })
      );
    });
  }, []);

  const handleSubmit = (values, setSubmitting) => {
    setIsPending(true);
    setEmpresa(values.empresa);
    setFiltro(values.filtro);
    getRetornos(values)
      .then(res => {
        setMessage('');
        setRetornos(res.data);
      })
      .catch(() => {
        setRetornos([]);
        setMessage('Nenhum retorno foi encontrado com estes filtros');
      })
      .finally(() => {
        setIsPending(false);
        setSubmitting(false);
      });
  };

  const retornosList = () => {
    if (retornos.length) {
      return retornos.map((retorno, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Col md="2" key={`key-${index}`}>
          <button
            className="btn btn-retorno mt-2 d-flex align-items-center justify-content-center"
            rel="noopener noreferrer"
            type="button"
            onClick={() => getRetornoArquivo(empresa, retorno, filtro)}
          >
            {dateFormat(retorno.data)}
          </button>
        </Col>
      ));
    }
  };

  return (
    <>
      <Main title="Retorno" className={'retorno ' + (collapseMenuRight ? 'collapsed' : '')} {...props}>
        <Card>
          <CardBody>
            <div className="row">
              <div
                className={
                  (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                  ' col-12 content-body '
                }
              >
                <div className="body">
                  <h1 className="title" style={{ width: '100%' }}>
                    Arquivo de Retorno
                    {collapseMenuRight && (
                      <button
                        type="button"
                        onClick={() => setCollapseMenuRight(false)}
                        title="Filtros"
                        className="btn btn-outline-secondary btn-show-menu-right float-right"
                      >
                        <i className="fa fa-angle-left" />
                        <span>Filtros</span>
                      </button>
                    )}
                  </h1>
                  <p className="subTitle">Filtre ao lado para visualizar os arquivos.</p>
                  <SkeletonLoader
                    isPending={isPending}
                    width="100%"
                    height="40px"
                    count={8}
                    widthRandomness={0}
                  >
                    <div className="content-retorno">
                      <div className="row">{retornosList()}</div>
                      {message && (
                        <div className="alert alert-danger animated fadeIn" role="alert">
                          {message}
                        </div>
                      )}
                    </div>
                  </SkeletonLoader>
                </div>
              </div>
              {!collapseMenuRight && (
                <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                      data: Yup.object().shape({
                        inicio: Yup.string()
                          .nullable()
                          .required('Campo obrigatório.'),
                        fim: Yup.string()
                          .nullable()
                          .required('Campo obrigatório.')
                      })
                    })}
                    onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                    render={({ values, isSubmitting }) => {
                      return (
                        <Form>
                          <div className="fixed-side">
                            <div
                              className="header cursor-pointer"
                              onClick={() => setCollapseMenuRight(true)}
                              onKeyPress={() => setCollapseMenuRight(true)}
                              role="button"
                              tabIndex={0}
                            >
                              <h4 className="title">
                                Filtros
                                <i className="fa fa-angle-right float-right" />
                              </h4>
                            </div>
                            <div className="body">
                              <div className="row">
                                <div className="col-md-12">
                                  <Field
                                    label="Empresa"
                                    id="select-empresa"
                                    name="empresa"
                                    options={tipoEmpresas}
                                    disabled={isSubmitting}
                                    component={Select}
                                    placeholder=""
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <Label>Período</Label>
                                </div>
                                <div className="col-md-6">
                                  <FastField
                                    name="data.inicio"
                                    id="begin"
                                    disabled={isSubmitting}
                                    component={DateTimePicker}
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <FastField
                                    name="data.fim"
                                    id="end"
                                    disabled={isSubmitting}
                                    component={DateTimePicker}
                                    autoComplete="off"
                                  />
                                </div>
                              </div>

                              <Label>Exibir</Label>
                              {filtros.map(filtro => (
                                <React.Fragment key={filtro.value}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Field
                                        name="filtro"
                                        checked={values.filtro === filtro.value}
                                        label={filtro.label}
                                        id={'radio-filtro-' + filtro.value.toLowerCase()}
                                        disabled={isSubmitting}
                                        value={filtro.value}
                                        component={Radio}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                            <div className="bottom">
                              <Button color="primary" type="submit" block disabled={isSubmitting}>
                                Filtrar
                              </Button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Main>
      <HistoricoAcesso codTela={1181} />
    </>
  );
};

export default Retorno;
