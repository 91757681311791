import React from 'react';
import { Button } from 'reactstrap';

const StepWizardNav = props => {
  const { currentStep, steps, goToStep } = props;
  return (
    <div className="step-wizard-nav">
      <div className="row nav">
        {steps.map(step => {
          const isCompleted = currentStep > step.number;
          const isDisabled = currentStep <= step.number;
          const isActive = currentStep === step.number;

          return (
            <div
              key={step.number}
              className={`col step text-center ${isActive ? 'active' : ''} ${
                isCompleted ? 'completed' : ''
              }`}
            >
              <Button
                type="button"
                className="dot"
                disabled={isDisabled}
                onClick={() => {
                  if (goToStep) {
                    goToStep(step.number);
                  }
                }}
              >
                <span className="inner-dot" />
              </Button>
              <p className="step-name">{step.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
StepWizardNav.defaultProps = {
  currentStep: 1,
  steps: [
    {
      number: 1,
      title: 'Upload'
    },
    {
      number: 2,
      title: 'Seleção'
    },
    {
      number: 3,
      title: 'Finalização'
    }
  ]
};
export default StepWizardNav;
