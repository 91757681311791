import React, { useState } from 'react';
import CustomMaskedInput from '../CustomMaskedInput/CustomMaskedInput';
import { CEPMask } from '../../../masks/masks';
import consultaCEP from '../../../../api/consultaCep';
import strip from '../../../../utils/strip';

const CEPInput = props => {
  const { onSearch, searchHandler, ...otherProps } = props;

  const [loading, setLoading] = useState(false);

  const handleKeyChange = e => {
    const stripped = strip(otherProps.field.value);

    if (
      (e.keyCode >= 48 && e.keyCode <= 57) || // only numbers
      (e.keyCode >= 96 && e.keyCode <= 105) || // only numbers (keypad)
      ((e.ctrlKey || e.metaKey) && e.keyCode === 86) // ctrl+v || cmd+v
    ) {
      if (
        onSearch &&
        ((otherProps.form.values.sacado && !otherProps.form.values.sacado.eCliente) ||
          (otherProps.form.values.cedente && !otherProps.form.values.cedente.eCliente))
      ) {
        if (stripped.length === 8) {
          setLoading(true);

          searchHandler(stripped)
            .then(res => {
              onSearch(res, otherProps.form, otherProps.field);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
  };

  return (
    <div className="loadingWrapper">
      <CustomMaskedInput
        onKeyUp={handleKeyChange}
        returnConformed={true}
        {...otherProps}
        mask={CEPMask}
      />
      {loading && (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          <span className="sr-only">Carregando...</span>
        </>
      )}
    </div>
  );
};

CEPInput.defaultProps = {
  searchHandler: consultaCEP
};

export default CEPInput;
