import PpcTiposEstadosColors from './PpcTiposEstadosColors';
import PpcTiposEstadosIcons from './PpcTiposEstadosIcons';

export default {
  RES: {
    sigla: 'RES',
    nome: 'RESERVADA',
    cor: PpcTiposEstadosColors.WARNING,
    icon: PpcTiposEstadosIcons.RES
  },
  REN: {
    sigla: 'REN',
    nome: 'RENOVAÇÃO',
    cor: PpcTiposEstadosColors.WARNING,
    icon: PpcTiposEstadosIcons.REN
  },
  INA: {
    sigla: 'INA',
    nome: 'INADEQUADA',
    cor: PpcTiposEstadosColors.DEFAULT,
    icon: PpcTiposEstadosIcons.INA
  },
  PRO: {
    sigla: 'PRO',
    nome: 'PROPONDO',
    cor: PpcTiposEstadosColors.INFO,
    icon: PpcTiposEstadosIcons.ESTADOSPROPONDO
  },
  REJ: {
    sigla: 'REJ',
    nome: 'REJEITADA',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.REJ
  },
  EXP: {
    sigla: 'EXP',
    nome: 'EXPIRADA',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.EXP
  },
  CAN: {
    sigla: 'CAN',
    nome: 'CANCELADA',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.CAN
  },
  ANA: {
    sigla: 'ANA',
    nome: 'ANALISANDO',
    cor: PpcTiposEstadosColors.PRIMARY,
    icon: PpcTiposEstadosIcons.ANA
  },
  PDG: {
    sigla: 'PDG',
    nome: 'PROPONDO - PLATAFORMA DEVOLVEU AO GERENTE',
    cor: PpcTiposEstadosColors.WARNING,
    icon: PpcTiposEstadosIcons.PDG
  },
  DPI: {
    sigla: 'DPI',
    nome: 'DEVOLUÇÃO GERENTE PERFORMAR',
    cor: PpcTiposEstadosColors.WARNING,
    icon: PpcTiposEstadosIcons.DPI
  },
  CDP: {
    sigla: 'CDP',
    nome: 'ANALISANDO - CREDITO DEVOLVEU AO PLATAFORMA',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.CDP
  },
  CRE: {
    sigla: 'CRE',
    nome: 'CREDITO ENTRADA',
    cor: PpcTiposEstadosColors.WARNING,
    icon: PpcTiposEstadosIcons.CRE
  },
  CAU: {
    sigla: 'CAU',
    nome: 'AUDITORIA',
    cor: PpcTiposEstadosColors.INFO,
    icon: PpcTiposEstadosIcons.CAU
  },
  CPI: {
    sigla: 'CPI',
    nome: 'PERFORMAR INFORMAÇÃO',
    cor: PpcTiposEstadosColors.PRIMARY,
    icon: PpcTiposEstadosIcons.CPI
  },
  CAF: {
    sigla: 'CAF',
    nome: 'ANALISE FINANCEIRA',
    cor: PpcTiposEstadosColors.SUCCESS,
    icon: PpcTiposEstadosIcons.CAF
  },
  FIM: {
    sigla: 'FIM',
    nome: 'FINALIZADA',
    cor: PpcTiposEstadosColors.SUCCESS,
    icon: PpcTiposEstadosIcons.FIM
  },
  CDG: {
    sigla: 'CDG',
    nome: 'PROPONDO - CREDITO DEVOLVEU AO GERENTE',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.CDG
  },
  CPD: {
    sigla: 'CPD',
    nome: 'PROPONDO - PLATAFORMA DEVOLVEU AO GERENTE VINDO DO CREDITO',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.CPD
  },
  CAP: {
    sigla: 'CAP',
    nome: 'ANALISE DE PRODUTO',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.CAP
  },
  FCR: {
    sigla: 'FCR',
    nome: 'FINALIZADO CREDITO',
    cor: PpcTiposEstadosColors.SUCCESS,
    icon: PpcTiposEstadosIcons.FCR
  },
  COM: {
    sigla: 'COM',
    nome: 'COMITE',
    cor: PpcTiposEstadosColors.GRAY999,
    icon: PpcTiposEstadosIcons.COM
  },
  REP: {
    sigla: 'REP',
    nome: 'REPESCAGEM',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.REP
  },
  ABO: {
    sigla: 'ABO',
    nome: 'ANALISANDO - BACKOFFICE',
    cor: PpcTiposEstadosColors.WARNING,
    icon: PpcTiposEstadosIcons.ABO
  },
  BDG: {
    sigla: 'BDG',
    nome: 'PROPONDO - BACKOFFICE DEVOLVEU AO GERENTE',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.BDG
  },
  CDB: {
    sigla: 'CDB',
    nome: 'ANALISANDO - CREDITO DEVOLVEU AO BACKOFFICE',
    cor: PpcTiposEstadosColors.DANGER,
    icon: PpcTiposEstadosIcons.CDB
  },
  CBG: {
    sigla: 'CBG',
    nome: 'PROPONDO - BACKOFFICE DEVOLVEU AO GERENTE VINDO DO CREDITO',
    cor: PpcTiposEstadosColors.WARNING,
    icon: PpcTiposEstadosIcons.DANGER
  },
  DCE: {
    sigla: 'DCE',
    nome: 'AGUARDANDO CADASTRO DE DOCUMENTOS DA EMPRESA',
    cor: PpcTiposEstadosColors.INFO,
    icon: PpcTiposEstadosIcons.ESTADOSPROPONDO
  },
  DCS: {
    sigla: 'DCS',
    nome: 'AGUARDANDO DOCUMENTOS SOCIOS',
    cor: PpcTiposEstadosColors.INFO,
    icon: PpcTiposEstadosIcons.ESTADOSPROPONDO
  },
  PFG: {
    sigla: 'PFG',
    nome: 'PARECER FINAL GERENTE',
    cor: PpcTiposEstadosColors.INFO,
    icon: PpcTiposEstadosIcons.ESTADOSPROPONDO
  },
  AGR: {
    sigla: 'AGR',
    nome: 'REVISÃO DE DOCUMENTOS DA EMPRESA',
    cor: PpcTiposEstadosColors.WARNING,
    icon: PpcTiposEstadosIcons.AGR
  },
  CHP: {
    sigla: 'CHP',
    nome: 'CHECAGEM PROPOSTA',
    cor: PpcTiposEstadosColors.INFO,
    icon: PpcTiposEstadosIcons.ESTADOSPROPONDO
  },
  ANG: {
    sigla: 'ANG',
    nome: 'ANALISE DE NEGÓCIO',
    cor: PpcTiposEstadosColors.INFO,
    icon: PpcTiposEstadosIcons.ESTADOSPROPONDO
  },
  CLI: {
    sigla: 'CLI',
    nome: 'PENDENCIA CLIENTE',
    cor: PpcTiposEstadosColors.DEFAULT,
    icon: PpcTiposEstadosIcons.CLI
  }
};
