import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { Field } from 'formik';
import CheckBoxMain from '../../shared/InputMain/CheckBoxMain';
import InputMain from '../../shared/InputMain/InputMain';

export default ({
  dadosFormaRecPrazo,
  values,
  setFieldValue,
  campoValidacao,
  setCampoValidacao,
  isDisabled
}) => {
  return (
    <Box>
      <Box className="pad-bot-10">
        <Typography
          variant="h5"
          className={
            'texto-cor-principal texto-negrito ' +
            (campoValidacao === 'formaRecPrazo' ? 'texto-danger-strong' : '')
          }
        >
          Formas de pagamento
        </Typography>
        <Typography
          variant="subtitle1"
          className={
            'texto-cor-cinza-escuro pad-topbot-10 ' +
            (campoValidacao === 'formaRecPrazo' ? 'texto-danger-strong' : '')
          }
        >
          Selecione as formas de pagamento aceitas pela empresa e preencha as informações correspondentes
          a cada uma
        </Typography>
      </Box>
      {dadosFormaRecPrazo.map(formaPrazo => (
        <Box key={`boxFormaRecPrazo_${formaPrazo.siglaTipo}`} className="pad-top-20">
          <Field
            type="checkbox"
            name={`chkbx_formaRecPrazo[${formaPrazo.siglaTipo}]`}
            value={formaPrazo.codeTipo}
            checked={
              values.chkbx_formaRecPrazo && values.chkbx_formaRecPrazo[formaPrazo.siglaTipo]
                ? true
                : false
            }
            component={CheckBoxMain}
            disabled={isDisabled}
            corCheckBox="checkbox-cor-complementar"
            controlLabelCheckbox={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <span className="font-size-1-rem texto-cor-cinza-escuro camel-case font-bold">
                {formaPrazo.nomeTipo.toLowerCase()}
              </span>
            }
            onChangeChkbx={() => {
              if (campoValidacao.substring(0, 13) === 'formaRecPrazo') setCampoValidacao('');
            }}
          />
          <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`formaRecPrazo[${formaPrazo.siglaTipo}][percentual]`}
                titulo="Percentual (%)"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName={
                  'input-disabled-cinza ' +
                  (campoValidacao === `formaRecPrazo_${formaPrazo.siglaTipo}_percentual`
                    ? 'input-error'
                    : '')
                }
                disabled={
                  isDisabled ||
                  !(values.chkbx_formaRecPrazo && values.chkbx_formaRecPrazo[formaPrazo.siglaTipo])
                }
                tipoMascara="number-percentual"
                setFieldValue={setFieldValue}
                onChangeInput={() => {
                  if (campoValidacao === `formaRecPrazo_${formaPrazo.siglaTipo}_percentual`)
                    setCampoValidacao('');
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`formaRecPrazo[${formaPrazo.siglaTipo}][ticketMedio]`}
                titulo="Ticket médio (R$)"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName={
                  'input-disabled-cinza ' +
                  (campoValidacao === `formaRecPrazo_${formaPrazo.siglaTipo}_ticketMedio`
                    ? 'input-error'
                    : '')
                }
                disabled={
                  isDisabled ||
                  !(values.chkbx_formaRecPrazo && values.chkbx_formaRecPrazo[formaPrazo.siglaTipo])
                }
                tipoMascara="number-money"
                onChangeInput={() => {
                  if (campoValidacao === `formaRecPrazo_${formaPrazo.siglaTipo}_ticketMedio`)
                    setCampoValidacao('');
                }}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`formaRecPrazo[${formaPrazo.siglaTipo}][prazoMedio]`}
                titulo="Prazo médio (dias)"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName={
                  'input-disabled-cinza ' +
                  (campoValidacao === `formaRecPrazo_${formaPrazo.siglaTipo}_prazoMedio`
                    ? 'input-error'
                    : '')
                }
                disabled={
                  isDisabled ||
                  !(values.chkbx_formaRecPrazo && values.chkbx_formaRecPrazo[formaPrazo.siglaTipo])
                }
                tipoMascara="number-inteiro"
                onChangeInput={() => {
                  if (campoValidacao === `formaRecPrazo_${formaPrazo.siglaTipo}_prazoMedio`)
                    setCampoValidacao('');
                }}
                inputProps={{ maxLength: 5 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="pad-top-20">
            <Grid item xl={12} lg={12} md={12} xs={12} className="pad-top-0">
              <Field
                type="text"
                name={`formaRecPrazo[${formaPrazo.siglaTipo}][observacao]`}
                titulo="Observação"
                size="small"
                component={InputMain}
                autoComplete="off"
                inputClassName="input-disabled-cinza"
                disabled={
                  isDisabled ||
                  !(values.chkbx_formaRecPrazo && values.chkbx_formaRecPrazo[formaPrazo.siglaTipo])
                }
                multiline
                rows={3}
                inputProps={{ maxLength: 250 }}
                placeholder="Escreva informações que considera importante sobre essa forma de pagamento"
              />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};
