import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from '@mui/material';
import LoaderMain from '../../Onboarding/shared/LoaderMain';
import ButtonMain from '../../Onboarding/shared/ButtonMain';
import Detalhes from '../Detalhes';

export default ({
  dadosDocumentosEmpresa,
  detalhesDocumentos,
  setDetalhesDocumentos,
  iconeStatus,
  listaTiposDocumentos,
  clienteSelecionado = false,
  cnpjEmpresa = false,
  isPending,
  carregarAbaEmpresa,
  bloquearTipoDocDuplicado = true,
  arquivosAceitos = ['pdf']
}) => {
  const [listaDocs, setListaDocs] = useState([]);

  useEffect(() => {
    if (Object.keys(dadosDocumentosEmpresa).length > 0) {
      setListaDocs(dadosDocumentosEmpresa.documentos);
    }
  }, [detalhesDocumentos, dadosDocumentosEmpresa]);

  const verDocumentos = () => {
    setDetalhesDocumentos(true);
  };

  const fecharDocumentos = () => {
    setDetalhesDocumentos(false);
  };

  return (
    <Box sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      {detalhesDocumentos === false ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead className="tbl-head-doc-clientes">
              <TableRow>
                <TableCell align="left" className="texto-semi-negrito">
                  EMPRESA
                </TableCell>
                <TableCell align="left" className="texto-semi-negrito">
                  TIPO DE EMPRESA
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '170px' }} className="texto-semi-negrito">
                  STATUS
                </TableCell>
                <TableCell align="left" sx={{ width: '270px' }} />
              </TableRow>
            </TableHead>
            <TableBody className="tab-body-doc-clientes">
              {dadosDocumentosEmpresa && dadosDocumentosEmpresa.dados ? (
                <TableRow>
                  <TableCell align="left">{dadosDocumentosEmpresa.dados.nome}</TableCell>
                  <TableCell align="left">{dadosDocumentosEmpresa.dados.tipo_empresa}</TableCell>
                  <TableCell align="left">
                    {iconeStatus[dadosDocumentosEmpresa.dados.status_documentos]}
                    {dadosDocumentosEmpresa.dados.status_documentos}
                  </TableCell>
                  <TableCell align="left">
                    <ButtonMain
                      tipoBotao="principal"
                      onClick={() => verDocumentos()}
                      sx={{ m: '10px 0' }}
                    >
                      VER DOCUMENTOS
                      {dadosDocumentosEmpresa.dados.status_documentos === 'REPROVADO' && (
                        <div className="icone-alert-right-top" />
                      )}
                    </ButtonMain>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    Nenhum Registro Encontrado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Detalhes
          tipo="empresa"
          clienteSelecionado={clienteSelecionado}
          cnpjEmpresa={cnpjEmpresa}
          listaDocumentos={listaDocs}
          setListaDocumentos={setListaDocs}
          fecharDocumentos={fecharDocumentos}
          dados={dadosDocumentosEmpresa.dados}
          listaTiposDocumentos={listaTiposDocumentos}
          carregarAba={carregarAbaEmpresa}
          bloquearTipoDocDuplicado={bloquearTipoDocDuplicado}
          arquivosAceitos={arquivosAceitos}
        />
      )}
    </Box>
  );
};
