import React from 'react';
import swal from '@sweetalert/with-react';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  CustomInput
} from 'reactstrap';

export default (handleCheck, checkedAll, handleCheckAll, operaTrustee, clienteAtual) => {
  const criarCheckBoxBoletos = row => {
    let disabled = true;
    if (row.boleto) {
      disabled = false;
    }

    return (
      <FormGroup>
        <CustomInput
          type="checkbox"
          checked={row.meta.checked}
          disabled={disabled}
          onChange={event => handleCheck(event)}
          id={`check-${row.id}`}
          value={row.id}
        />
      </FormGroup>
    );
  };

  const criarButtonBoletos = row => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          color="primary"
          id={`btnDrop-${row.id}`}
          outline
          className={typeof row.boleto === 'undefined' ? 'btn-outline-danger' : ''}
          title="Clique para visualizar o status do título"
          onClick={e => {
            e.preventDefault();
            if (typeof row.boleto === 'undefined') {
              swal({
                title: 'Atenção!',
                text: row.mensagemTitleBoleto,
                content: <>{row.mensagemTitleBoleto}</>,
                icon: 'warning'
              });
            }
          }}
        >
          {row.boleto ? (
            <>
              BOLETO
              <i className="svg-icon right-side far fa-chevron-down" />
            </>
          ) : (
            'NÃO HÁ BOLETO'
          )}
        </DropdownToggle>
        {(row.boleto && (
          <>
            <DropdownMenu>
              <DropdownItem
                className="custom-link"
                onClick={e => {
                  e.preventDefault();
                  window.open(row.boleto.original, `Boleto `, 'resizable,scrollbars,status');
                }}
              >
                ORIGINAL
              </DropdownItem>
              {row.boleto && row.boleto.atualizado && (
                <>
                  <DropdownItem divider />
                  <DropdownItem
                    className="custom-link"
                    onClick={e => {
                      e.preventDefault();
                      window.open(row.boleto.atualizado, `Boleto `, 'resizable,scrollbars,status');
                    }}
                  >
                    ATUALIZADO
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </>
        )) || <></>}
      </UncontrolledDropdown>
    );
  };

  const checkBoxAll = (checkedAll, handleCheckAll) => {
    return (
      <FormGroup>
        <CustomInput
          type="checkbox"
          checked={checkedAll.checked}
          disabled={checkedAll.disabled}
          onChange={event => handleCheckAll(event)}
          id="chkAllTitulos"
          value="all"
        />
      </FormGroup>
    );
  };

  const headerCheckBoxAll = () => {
    return checkBoxAll(checkedAll, handleCheckAll);
  };

  const ordenarBoleto = (a, b, order, dataField, rowA, rowB) => {
    a = typeof rowA.boleto !== 'undefined' ? 1 : 0;
    b = typeof rowB.boleto !== 'undefined' ? 1 : 0;
    var ordenacao = order === 'asc' ? a - b : b - a;
    return ordenacao;
  };

  if (operaTrustee === 'N') {
    var classeSacado = 'tamanhoCorreto';
  } else {
    classeSacado = '';
  }

  const config = [
    //
    {
      dataField: 'meta.checked',
      text: '',
      headerFormatter: headerCheckBoxAll,
      formatacao: 'checkbox',
      evento: criarCheckBoxBoletos,
      headerStyle: { width: '50px', textAlign: 'center' },
      sort: false
    },
    { dataField: 'fundo', text: 'FUNDO', formatacao: 'texto' },
    { dataField: 'notaFiscal', text: 'NOTA FISCAL', tipoOrdenacao: 'inteiro' },
    { dataField: 'documento', text: 'DOCUMENTO', tipoOrdenacao: 'texto', tdAlign: 'center' },
    { dataField: 'status', text: 'STATUS', formatacao: 'texto' },
    { dataField: 'tipoDocumento', text: 'TIPO', formatacao: 'texto', width: '80px', tdAlign: 'center' },
    { dataField: 'alinea', text: 'ALÍNEA', formatacao: 'texto', tdAlign: 'center' },
    {
      dataField: 'tto',
      text: 'TTO',
      formatacao: 'texto',
      width: '80px',
      tdAlign: 'center'
    },
    {
      dataField: 'dataVencimento',
      text: 'VENCIMENTO',
      tipoOrdenacao: 'data',
      formatacao: 'data'
    },
    {
      dataField: 'dataProrrogacao',
      text: 'VENC. PROR.',
      tipoOrdenacao: 'data',
      formatacao: 'data'
    },
    { dataField: 'atraso', text: 'ATRASO', tdAlign: 'center', tipoOrdenacao: 'inteiro' },
    {
      dataField: 'originalData.sacado',
      text: 'SACADO',
      formatacao: 'texto',
      headerStyle: { width: '290px', textAlign: 'center' },
      headerClasses: classeSacado
    },
    {
      dataField: 'valorTitulo',
      text: 'VALOR',
      formatacao: 'valor',
      tipoOrdenacao: 'float',
      width: '200px'
    },
    { dataField: 'valorDevido', text: 'VALOR DEV.', formatacao: 'valor', tipoOrdenacao: 'float' },
    { dataField: 'abatimento', text: 'ABATIMENTO', formatacao: 'valor', tipoOrdenacao: 'float' },
    {
      dataField: 'liquidacao',
      text: 'LIQUIDAÇÃO',
      tipoOrdenacao: 'data',
      formatacao: 'dataOuIfem'
    },
    {
      dataField: 'liquidacaoCS',
      text: 'LIQ. CS',
      tipoOrdenacao: 'data',
      formatacao: 'dataOuIfem'
    },
    {
      dataField: 'valorPago',
      text: 'VL. PAGO',
      tipoOrdenacao: 'float',
      formatacao: 'valorOuIfem'
    },
    {
      dataField: 'BTN',
      text: 'BOLETO',
      formatacao: 'button',
      evento: criarButtonBoletos,
      tipoOrdenacao: ordenarBoleto
    }
  ];

  if (operaTrustee === 'N') {
    config.splice(16, 2); //Remove as colnas 17 e 18 se o cliente não opera Trustee
  }

  if (clienteAtual && clienteAtual.id === 'todos') {
    config.splice(1, 0, {
      dataField: 'nomeCliente',
      text: 'CLIENTE',
      tipoOrdenacao: 'texto',
      tdAlign: 'left'
    });
  }

  return config;
};
