// REF: https://blog.rocketseat.com.br/reactjs-autenticacao/
// TODO: REFAZER com OAuth, simplificado pra não bloquear a história de troca de usuario
import API from './api';
import { getClientesOnboarding } from './clientes';
import { reenviaTokenConfirmacao, salvarAcesso } from './usuario';
import {
  setClientes,
  setClienteAtual,
  setToken,
  setTokenRefresh,
  setDadosUsuario,
  setEmailValidaToken,
  verificaClienteOnboarding,
  getDadosUsuario,
  setEscopoComponentes,
  setTelaDefault
} from '../shared/services/User';
import { MODULOS, MODULOS_TAGS } from '../components/Onboarding/shared/Enums/EnumsOnboarding';
import { getLogin, getEscopo } from './autenticacao';

const MSG_LOGIN_ERROR = 'Usuário e/ou senha inválido';

const errorTimeout = 3000;

export const signIn = ({ login, password, setEscopoAcesso }) => {
  sessionStorage.clear();
  return new Promise((resolve, reject) => {
    // Forçando Login pra qualquer usuario
    if (!login || !password) {
      return setTimeout(function() {
        return reject(MSG_LOGIN_ERROR);
      }, errorTimeout);
    }

    const form = new URLSearchParams();
    form.append('username', login);
    form.append('password', password);
    form.append('client_id', process.env.REACT_APP_CLIENT_ID);
    form.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
    form.append('grant_type', process.env.REACT_APP_GRANT_TYPE);

    //VISAO CLIENTE ATUAL
    // const auth = {
    //   login,
    //   senha: password
    // };

    return getLogin(form)
      .then(resAuth => {
        const { access_token, refresh_token } = resAuth.data;

        setToken(access_token);
        setTokenRefresh(refresh_token);

        return getEscopo({
          modulos: [
            MODULOS_TAGS.PLATAFORMACLIENTE,
            MODULOS_TAGS.PLATAFORMA_CRM,
            MODULOS_TAGS.PLATAFORMA_FORMALIZACAO,
            MODULOS_TAGS.PLATAFORMA_RELACIONAMENTOS
          ]
        })
          .then(resEscopo => {
            // VALIDA SE O RESULTADO DA BUSCA PELOS ESCOPOS É VAZIO
            if (!(resEscopo.data && Object.keys(resEscopo.data).length > 1)) {
              return reject('Acesso não encontrado, valide o seu cadastro de usuário junto a ONE7');
            }

            // VERIFICA SE EXISTE ESCOPO COMPLEMENTAR, CASO POSSUA, FORMATA E SETA O MESMO
            var escoposModulos = {};
            Object.keys(resEscopo.data).map(modulo => {
              if (modulo !== 'telaDefault') {
                escoposModulos[modulo] = formatarEscopo({ ...resEscopo.data[modulo] });
              }
              return modulo;
            });
            setEscopoAcesso(escoposModulos);
            setEscopoComponentes(escoposModulos);

            // CASO EXISTA UMA TELA DEFAULT, SETA A MESMA
            if (resEscopo.data.telaDefault) setTelaDefault(resEscopo.data.telaDefault);

            return getClientesOnboarding()
              .then(resClientes => {
                if (
                  resClientes.data === false ||
                  resClientes.data === null ||
                  (typeof resClientes.data.usuario_nao_encontrado !== 'undefined' &&
                    resClientes.data.usuario_nao_encontrado)
                ) {
                  return reject('Usuário não cadastrado, validar o cadastro de usuário junto a ONE7');
                }

                const dados = resClientes.data;
                const usuario = dados.usuario;
                usuario.login = login;
                setDadosUsuario(usuario);

                salvarAcesso({
                  codModulo: MODULOS.PLATAFORMACLIENTE,
                  codTela: null,
                  codusuario: getDadosUsuario().codusuario,
                  nomeUsuario: getDadosUsuario().nome
                });

                if (
                  typeof dados.entrada_cnpj_onboarding !== 'undefined' &&
                  dados.entrada_cnpj_onboarding
                ) {
                  return resolve(dados);
                } else if (
                  typeof dados.usuario_validado !== 'undefined' &&
                  dados.usuario_validado === false
                ) {
                  return resolve(dados);
                }

                const clientes = dados.lista_clientes;

                if (clientes && clientes.length > 0) {
                  setClientes(clientes);

                  if (clientes.length > 1) {
                    return resolve({ grupo_empresarial: true });
                  } else {
                    var clienteUnico = clientes[0];
                    setClienteAtual(clienteUnico);
                    var verifCli = verificaClienteOnboarding(clienteUnico);
                    return resolve(verifCli);
                  }
                } else if (usuario.nivel !== 7) {
                  return resolve({ grupo_empresarial: true });
                } else {
                  return resolve({ entrada_cnpj_onboarding: true });
                }
              })
              .catch(err => {
                // eslint-disable-next-line no-console
                console.error(err);
                throw err;
              });
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error(err);
            throw err;
          });
      })
      .catch(function(error) {
        let errorMessage = null;

        if (error.response) {
          errorMessage = error.response.data.error;
        }

        if (error.response && error.response.status === 401) {
          setEmailValidaToken(login);
          return reenviaTokenConfirmacao(login)
            .then(() => {
              return resolve({ usuario_validado: false });
            })
            .catch(err2 => {
              let errorMessage2 = null;
              if (err2.response && err2.response.data && err2.response.data.error) {
                errorMessage2 = err2.response.data.error;
              } else if (err2.response && err2.response.data && err2.response.data.errors) {
                errorMessage2 = 'Erro ao reenviar token: ';
                errorMessage2 += Object.values(err2.response.data.errors)[0][0];
                errorMessage2 += ' Favor contatar o nosso suporte e informar sobre este erro.';
              }
              sessionStorage.clear();
              return reject(errorMessage2 || MSG_LOGIN_ERROR);
            });
        }
        sessionStorage.clear();
        return reject(errorMessage || MSG_LOGIN_ERROR);
      });
  });
};

export const signOut = () => {
  return new Promise(resolve => {
    // return API.post('/auth/logout').finally(() => {
    sessionStorage.clear();
    return resolve(true);
    // });
  });
};

export const refreshToken = () => API.get('/auth/refresh');

export const resetPassword = (params, token) => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/nova-senha/${token}`, params);
};

export const recoveryPassword = forgotPassword => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/recuperar-senha`,
    forgotPassword
  );
};

const formatarEscopo = resEscopo => {
  var lstEscopo = resEscopo.perfil ? resEscopo.perfil : [];
  lstEscopo = lstEscopo.concat(resEscopo.complementos ? resEscopo.complementos : []);
  var escopo = {};
  lstEscopo.map(itemEscopo => {
    if (itemEscopo.tipo_chave && itemEscopo.chave) {
      var tipoChave = itemEscopo.tipo_chave.toLowerCase();
      var chave = itemEscopo.chave.toLowerCase();
      if (escopo[tipoChave] === undefined) {
        escopo[tipoChave] = {};
      }
      escopo[tipoChave][chave] = itemEscopo;
    }
    return itemEscopo;
  });
  return escopo;
};
