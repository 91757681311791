import moment from 'moment';
import { saveAs } from 'file-saver';
import API from './api';
import { maskCMC7 } from '../utils/format';
import removeNullKeys from '../utils/remove-null-keys';

const getBorderosPendente = (values = {}) => {
  if (Object.keys(values).length > 0) {
    values = {
      ...values,
      bordero: values.bordero ? values.bordero.replace(/\s/gm, '') : null,
      documento: values.documento ? values.documento.replace(/\s/gm, '') : null,
      data_inicio: values.data_inicio
        ? moment(values.data_inicio, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null,
      data_final: values.data_final
        ? moment(values.data_final, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null
    };
  }
  removeNullKeys(values);
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/pendente`, {
    params: { ...values }
  });
};

const deleteBorderoByCodeIN = codBorderoIN =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/deletein/`, codBorderoIN);

const deleteBorderoByCode = codBordero =>
  API.delete(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${codBordero}`);

const getBorderoByCode = codBordero =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${codBordero}`);

const saveBordero = bordero =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos`, bordero);

const updateBordero = bordero =>
  API.put(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${bordero.id}`, bordero);

const saveOrUpdateBordero = bordero => {
  if (bordero.id) {
    return updateBordero(bordero);
  }
  return saveBordero(bordero);
};

const finalizarBordero = bordero =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${bordero.id}/finalizar`);

const saveBorderoTitulo = (idBordero, titulo) =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/titulos`, titulo);

const getBorderoTitulo = idBordero =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/titulos`);

const getCartasCorrecao = (idBordero, idTitulo) =>
  API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/carta-correcao/${idTitulo}`
  );

const getBorderoDocumentoNotaFiscalServico = codBordero =>
  API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${codBordero}/documentonotafiscalservico`
  );

const getBorderoDocumentoImpotacaoGeracaoTitulo = codBordero =>
  API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${codBordero}/documentoimpotacaogeracaotitulo`
  );

const getBorderoDocumentoContratoFisico = codBordero =>
  API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${codBordero}/documentocontratofisico`
  );

const updateBorderoTitulo = (idBordero, titulo) =>
  API.put(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/titulos/${titulo.id}`,
    titulo
  );

const deleteBorderoTitulo = (idBordero, titulo) =>
  API.delete(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/titulos/${titulo.id}`
  );

const deleteTituloBorderoByCodeIN = (idBordero, tituloIN) =>
  API.post(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/titulos/deletein/${idBordero}`,
    tituloIN
  );

const uploadFile = (
  file,
  cb,
  clienteEspecial,
  tipoOperacao,
  clienteOperaConnect,
  contaViaJornada,
  liberaXmlRedesconto
) => {
  file.set('clienteEspecial', clienteEspecial);
  file.set('tipoOperacao', tipoOperacao);
  file.set('clienteOperaConnect', clienteOperaConnect);
  file.set('contaViaJornada', contaViaJornada);
  file.set('liberaXmlRedesconto', liberaXmlRedesconto);

  return API.post(
    `${process.env.REACT_APP_API_UPLOAD_BASE_URL}${process.env.REACT_APP_API_BASE_PATH}/upload/bordero-titulos`,
    file,
    {
      onUploadProgress: function(progressEvent) {
        cb(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
      }
    }
  );
};

const uploadNFeDuplicata = (file, cb) =>
  API.post(
    `${process.env.REACT_APP_API_UPLOAD_BASE_URL}${process.env.REACT_APP_API_BASE_PATH}/upload/titulo`,
    file,
    {
      onUploadProgress: function(progressEvent) {
        cb(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
      }
    }
  );

const uploadDocumentoAssinado = (file, cb) =>
  API.post(
    `${process.env.REACT_APP_API_UPLOAD_BASE_URL}${process.env.REACT_APP_API_BASE_PATH}/upload/documentos-assinados`,
    file,
    {
      onUploadProgress: function(progressEvent) {
        cb(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
      }
    }
  );

const uploadContratoFisico = (file, cb) =>
  API.post(
    `${process.env.REACT_APP_API_UPLOAD_BASE_URL}${process.env.REACT_APP_API_BASE_PATH}/upload/titulo`,
    file,
    {
      onUploadProgress: function(progressEvent) {
        cb(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
      }
    }
  );

const uploadArquivoXML = (file, cb) =>
  API.post(
    `${process.env.REACT_APP_API_UPLOAD_BASE_URL}${process.env.REACT_APP_API_BASE_PATH}/upload/xml`,
    file,
    {
      onUploadProgress: function(progressEvent) {
        cb(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
      }
    }
  );

const saveBorderoFilesURL = (idBordero, arquivos) => {
  return API.post(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/arquivo`,
    arquivos
  );
};

const saveSignedDocumentURL = (idBordero, arquivos) => {
  return API.post(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/documento`,
    arquivos
  );
};

const saveCartaCorrecaoFilesURL = (codEntrada, codTittulo, arquivos) => {
  return API.post(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${codEntrada}/carta-correcao`,
    {
      CODTITULO: codTittulo,
      ARQUIVOS: arquivos
    }
  );
};

const saveBorderoContratosURL = (idBordero, arquivos) => {
  return API.post(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/contrato`,
    arquivos
  );
};

const saveBorderoArquivoXML = (idBordero, arquivos) => {
  return API.post(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/arquivo-xml`,
    arquivos
  );
};

const deleteBorderoFile = (idBordero, idFile) => {
  API.delete(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/arquivo/${idFile}`
  );
};

const deleteDocumentoAssinado = (idBordero, idFile) => {
  API.delete(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/documento/${idFile}`
  );
};

const deleteCartaCorrecaoFile = (idBordero, idFile) => {
  API.delete(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/carta-correcao/${idFile}`
  );
};

const deleteBorderoArquivoXML = (idBordero, idFile) => {
  return API.delete(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/arquivo-xml/${idFile}`
  );
};

const deleteBorderoContrato = (idBordero, idFile) => {
  API.delete(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/contrato/${idFile}`
  );
};

const getTiposOperacoes = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/tipos-operacoes`);

const getTiposTitulos = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/tipos-titulos`);

const getRetornos = ({ empresa, data, filtro }) => {
  const dataInicial = moment(data.inicio, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE);
  const dataFinal = moment(data.fim, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE);

  const queryParams = {
    data_inicio: dataInicial ? dataInicial.replace(/\s/gm, '') : null,
    data_fim: dataFinal ? dataFinal.replace(/\s/gm, '') : null,
    rejeitados: filtro ? filtro : null,
    cod_empresa: empresa ? empresa : null
  };

  removeNullKeys(queryParams);

  return API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/clientes/retornos-arquivos`,
    {
      params: { ...queryParams }
    }
  );
};

const getRetornoArquivo = async (empresa, dados, filtro) => {
  const queryParams = {
    data: dados.data ? dados.data : '',
    ocorrencia_connect: dados.ocorrencia_connect ? dados.ocorrencia_connect : false,
    rejeitados: filtro ? filtro : null,
    cod_empresa: empresa ? empresa : null
  };

  removeNullKeys(queryParams);

  const response = await API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/clientes/retorno-arquivo`,
    {
      params: { ...queryParams }
    }
  );
  const blob = new Blob([response.data], { type: `application/ret` });
  saveAs(blob, `RETORNO_BORDERO_${queryParams.data.split('-').join('')}.RET`);
};

const getTiposEmpresas = () => {
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/tipos-empresas`);
};

const getTiposEmpresasRelatorio = () => {
  return API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/tipos-empresas/Relatorio`
  );
};

const getCMC7 = cmc7 => {
  const maskedCmc7 = maskCMC7(cmc7);
  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/regras/consulta-cmc`, {
    params: {
      cmc7: maskedCmc7
    }
  });
};

const getCondicaoOperacionalVencimentoTitulo = (tipoOperacao, tipoTitulo, tipoEmpresa) => {
  const params = {
    tipo_operacao: tipoOperacao,
    tipo_empresa: tipoEmpresa,
    tipo_titulo: tipoTitulo
  };

  return API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/regras/condicao-operacional-vencimento-titulo`,
    { params }
  );
};

const deleteBorderoTitulos = idBordero => {
  return API.delete(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/titulos`);
};

const updateNdocTitulo = (idBordero, titulos) =>
  API.put(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/borderos/${idBordero}/titulos/ndoc`,
    titulos
  );

export {
  getBorderosPendente,
  deleteBorderoByCodeIN,
  getBorderoDocumentoNotaFiscalServico,
  getBorderoDocumentoImpotacaoGeracaoTitulo,
  getBorderoDocumentoContratoFisico,
  getBorderoByCode,
  deleteBorderoByCode,
  saveBordero,
  updateBordero,
  saveOrUpdateBordero,
  saveBorderoTitulo,
  updateBorderoTitulo,
  deleteBorderoTitulo,
  deleteTituloBorderoByCodeIN,
  getBorderoTitulo,
  getCartasCorrecao,
  uploadFile,
  uploadNFeDuplicata,
  uploadDocumentoAssinado,
  uploadContratoFisico,
  uploadArquivoXML,
  saveBorderoFilesURL,
  saveSignedDocumentURL,
  saveCartaCorrecaoFilesURL,
  saveBorderoContratosURL,
  saveBorderoArquivoXML,
  deleteBorderoFile,
  deleteDocumentoAssinado,
  deleteBorderoArquivoXML,
  deleteCartaCorrecaoFile,
  deleteBorderoContrato,
  finalizarBordero,
  getTiposOperacoes,
  getTiposTitulos,
  getRetornos,
  getRetornoArquivo,
  getTiposEmpresas,
  getTiposEmpresasRelatorio,
  getCMC7,
  getCondicaoOperacionalVencimentoTitulo,
  deleteBorderoTitulos,
  updateNdocTitulo
};
