export default (value, caracteresEspeciais) => {
  let result = true;
  if (value !== '' && value !== null && typeof value !== 'undefined') {
    caracteresEspeciais.forEach(function(item) {
      if (value.indexOf(item) >= 0) {
        result = false;
      }
    });
  }
  return result;
};
