import React from 'react';
import { Button } from '@mui/material';

const ButtonMain = ({
  children,
  tipoBotao = '',
  elevacao = false,
  className,
  classesAdicional = '',
  widthClass = 'w-100',
  ...restProps
}) => {
  var corBotao = '',
    corTextoBotao = '',
    varianteBotao = 'text',
    desabilitarElevacao = elevacao;

  if (tipoBotao === 'principal') {
    corBotao += 'fundo-cor-complementar fundo-grayscale-hover';
    corTextoBotao += 'texto-cor-principal';
    varianteBotao = 'contained';
    desabilitarElevacao = true;
  } else if (tipoBotao === 'principal-texto-branco') {
    corBotao += 'fundo-cor-complementar fundo-grayscale-hover';
    corTextoBotao += 'texto-cor-branca';
    varianteBotao = 'contained';
    desabilitarElevacao = true;
  } else if (tipoBotao === 'azul-escuro') {
    corBotao += 'fundo-cor-principal';
    corTextoBotao += 'texto-cor-branca';
    varianteBotao = 'contained';
    desabilitarElevacao = true;
  } else if (tipoBotao === 'transparente') {
    corBotao += 'fundo-transparente';
    corTextoBotao += 'texto-cor-principal';
  } else if (tipoBotao === 'transparente-com-bordas') {
    corBotao += '';
    corTextoBotao += 'texto-cor-principal borda-1';
  } else if (tipoBotao === 'transparente-com-bordas-complementar') {
    corBotao += '';
    corTextoBotao += 'texto-cor-complementar borda-1';
  } else if (tipoBotao === 'transparente-com-bordas-principal') {
    corBotao += '';
    corTextoBotao += 'texto-cor-principal borda-cor-principal borda-1';
  }

  return (
    <Button
      {...restProps}
      variant={restProps.varianteBotao ? restProps.varianteBotao : varianteBotao}
      disableElevation={desabilitarElevacao}
      className={`${
        className ? className : ''
      } ${corBotao} ${corTextoBotao} ${classesAdicional} ${widthClass} botao-${tipoBotao}
      borda-outline-none texto-semi-negrito font-size-16 text-transform-none btn-new`}
    >
      {children}
    </Button>
  );
};
export default ButtonMain;
