export default {
  iprospect: {
    url: process.env.REACT_APP_ISEVEN_BASE_URL
      ? process.env.REACT_APP_ISEVEN_BASE_URL + '/apps/ePC/view/prePropostaResumo.php'
      : '',
    tipo: 'post'
  },
  icred: {
    url: process.env.REACT_APP_ISEVEN_BASE_URL
      ? process.env.REACT_APP_ISEVEN_BASE_URL + '/apps/eCredito/view/creditoDadosBasicos.php'
      : '',
    tipo: 'post'
  },
  icomite: {
    url: process.env.REACT_APP_MODULUS_BASE_URL
      ? process.env.REACT_APP_MODULUS_BASE_URL + '/comite/mesa-comite/propostas-para-analise'
      : '',
    tipo: 'get'
  },
  condicoes_operacionais: {
    url: process.env.REACT_APP_MODULUS_BASE_URL
      ? process.env.REACT_APP_MODULUS_BASE_URL + '/condicoes-operacionais/cadastros/pareceres'
      : '',
    tipo: 'get'
  }
};
