import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import InputMain from '../../../../shared/InputMain/InputMain';
import ButtonMain from '../../../../shared/ButtonMain';
import ToggleButtonMain from '../../../../shared/ToggleButtonMain';
import validaCaracteresEspeciais from '../../../../../../utils/validaCaracteresEspeciais';
import strip from '../../../../../../utils/strip';
import DocumentoUpload from '../../../../shared/DocumentoUpload';
import LoaderMain from '../../../../shared/LoaderMain';
import AlertErroMain from '../../../../shared/AlertErroMain';

import {
  estadosCivil,
  regimesBens,
  tiposDocumentosPessoaFisica
} from '../../../../shared/Enums/EnumsOnboarding';

import {
  postAlterarDadosSocios,
  postConfirmarDocumentos,
  getDocumentosRequeridos
} from '../../../../../../api/onboarding';

export default ({
  cpfSocioAberto,
  setCpfSocioAberto,
  listaSocios,
  setListaSocios,
  isPendingCadastro,
  setPending,
  setliberarBotaoContinuar,
  cnpjEmpresa,
  codEntrada = null,
  origemProposta
}) => {
  const [dadosFormulario, setDadosFormulario] = useState({});
  const [indexSocio, setIndexSocio] = useState(false);
  const [estadoCivil, setEstadoCivil] = useState('');
  const [regimeBens, setRegimeBens] = useState('CPC');
  const [pessoaPoliticaExposta, setPessoaPoliticaExposta] = useState('N');
  const [mostraErro, setMostraErro] = useState(false);
  const [cnpjConjugeSocio, setCnpjConjugeSocio] = useState(false);
  const [tiposArquivosAceitos, setTiposArquivosAceitos] = useState(['pdf']);
  const [nomeBloqueado, setNomeBloqueado] = useState(true);
  const [documentosSocio, setDocumentosSocio] = useState([]);
  const [documentosSocioReferencia, setDocumentosSocioReferencia] = useState({
    RG: [],
    CNH: [],
    RG_CPF: [],
    COMUM: []
  });
  const [isPendingUploadSocio, setPendingUploadSocio] = useState(false);
  const [isPendingDocumentosSocio, setPendingDocumentosSocio] = useState(false);
  const [tipoDocumentoSocio, setTipoDocumentoSocio] = useState('CNH');

  const [documentosConjuge, setDocumentosConjuge] = useState([]);
  const [documentosConjugeReferencia, setDocumentosConjugeReferencia] = useState({
    RG: [],
    CNH: [],
    RG_CPF: [],
    COMUM: []
  });
  const [isPendingUploadConjuge, setPendingUploadConjuge] = useState(false);
  const [isPendingDocumentosConjuge, setPendingDocumentosConjuge] = useState(false);
  const [tipoDocumentoConjuge, setTipoDocumentoConjuge] = useState(false);

  useEffect(() => {
    let btnFinalizar = true;
    listaSocios.map((socio, index) => {
      if (cpfSocioAberto === socio.cpf_cnpj) {
        setIndexSocio(index);
        if (socio.cpf_conjuge !== '') {
          setCnpjConjugeSocio(strip(socio.cpf_conjuge));
        } else {
          setCnpjConjugeSocio(false);
        }
      }
      if (cpfSocioAberto && socio.documentos_enviados !== 'S') {
        if (btnFinalizar && origemProposta === 'one7_mais_credito' && socio.CPFCNPJ.length > 11) {
          btnFinalizar = true;
        } else {
          btnFinalizar = false;
        }
      }
      return socio;
    });

    if (btnFinalizar) {
      setliberarBotaoContinuar(true);
    }

    var tiposArqAceitos = ['pdf'];
    if (origemProposta === 'connect' || origemProposta === 'indicantes') {
      tiposArqAceitos = ['pdf', 'imagens', 'word', 'powerPoint', 'excel'];
    } else if (origemProposta === 'one7_mais_credito') {
      tiposArqAceitos = ['pdf', 'png', 'jpg'];
    }
    setTiposArquivosAceitos(tiposArqAceitos);
  }, [listaSocios]);

  useEffect(() => {
    if (typeof listaSocios[indexSocio] === 'undefined') return false;
    controlarDadosFomulario(listaSocios[indexSocio]);
    setPendingDocumentosSocio(true);

    getDocumentosRequeridos('DSO', origemProposta)
      .then(resp => {
        let dados = resp.data;
        let documentosSociosTemp = processarDocumentos(dados);

        setDocumentosSocioReferencia({ ...documentosSociosTemp });
        setDocumentosSocio([...documentosSociosTemp.CNH, ...documentosSociosTemp.COMUM]);
      })
      .finally(() => {
        setPendingDocumentosSocio(false);
      });
  }, [indexSocio]);

  useEffect(() => {
    if (estadoCivil === 'CSD') {
      setTipoDocumentoConjuge('CNH');

      setPendingDocumentosConjuge(true);

      getDocumentosRequeridos('CJS', origemProposta)
        .then(resp => {
          let dados = resp.data;
          let documentosConjugeTemp = processarDocumentos(dados);

          setDocumentosConjugeReferencia({ ...documentosConjugeTemp });
          setDocumentosConjuge([...documentosConjugeTemp.CNH, ...documentosConjugeTemp.COMUM]);
        })
        .finally(() => {
          setPendingDocumentosConjuge(false);
        });
    }
  }, [estadoCivil]);

  const processarDocumentos = dados => {
    let dadosTemp = [...dados['grupos'][0]['Identification']];
    let documentosTemp = { RG: [], CNH: [], RG_CPF: [], COMUM: [] };

    dadosTemp.map(doc => {
      if (doc.nome_doc === 'RG') {
        documentosTemp.RG.push(doc);
        documentosTemp.RG_CPF.push(doc);
      } else if (doc.nome_doc === 'CNH') {
        documentosTemp.CNH.push(doc);
      } else if (doc.nome_doc === 'CPF') {
        documentosTemp.RG_CPF.push(doc);
      } else {
        documentosTemp.COMUM.push(doc);
      }
      return doc;
    });
    return documentosTemp;
  };

  const controlarDadosFomulario = dados => {
    setDadosFormulario(dados);
    setEstadoCivil(dados && dados.estado_civil ? dados.estado_civil : 'SLT');
    setRegimeBens(dados && dados.regime_bens ? dados.regime_bens : 'CPC');
    setPessoaPoliticaExposta(
      dados && dados.pessoa_politicamente_exposta ? dados.pessoa_politicamente_exposta : 'N'
    );
  };

  const confirmarDocumentosSocio = (values, form) => {
    if (estadoCivil === 'CSD' && dadosFormulario.cpf_conjuge) {
      var documentosConjugeTemp = [...documentosConjuge];
      var bloquearSubmitConj = false;
      documentosConjugeTemp = documentosConjugeTemp.map(conj => {
        if ((conj.hash_arquivo === undefined || conj.hash_arquivo === '') && conj.obrigacao_doc) {
          bloquearSubmitConj = true;
          if (conj.nome_arquivo === undefined || conj.nome_arquivo === '') {
            conj.status = 'reprovado';
            conj.titulo_status = 'Pendente';
            conj.subtitulo_status = 'Favor importar um arquivo para este documento';
          }
        }
        return conj;
      });

      if (bloquearSubmitConj) {
        setDocumentosConjuge(documentosConjugeTemp);
        return false;
      }
    }

    var documentosSocioTemp = [...documentosSocio];
    var bloquearSubmit = false;
    documentosSocioTemp = documentosSocioTemp.map(doc => {
      if ((doc.hash_arquivo === undefined || doc.hash_arquivo === '') && doc.obrigacao_doc) {
        bloquearSubmit = true;
        if (doc.nome_arquivo === undefined || doc.nome_arquivo === '') {
          doc.status = 'reprovado';
          doc.titulo_status = 'Pendente';
          doc.subtitulo_status = 'Favor importar um arquivo para este documento';
        }
      }
      return doc;
    });

    if (bloquearSubmit) {
      setDocumentosSocio(documentosSocioTemp);
      return false;
    }

    if (origemProposta === 'one7_mais_credito') {
      setPendingDocumentosSocio(true);
      setPendingDocumentosConjuge(true);
    } else {
      setPending(true);
    }

    let paramsSocio = formatarPayloadDocumentos(
      documentosSocio,
      dadosFormulario.cpf_cnpj,
      null,
      'DSO',
      dadosFormulario.tipo,
      dadosFormulario.SOCIO_RESPONSAVEL
    );

    if (estadoCivil === 'CSD') {
      let paramsConjuge = formatarPayloadDocumentos(
        documentosConjuge,
        dadosFormulario.cpf_cnpj,
        dadosFormulario.cpf_conjuge,
        'CJS',
        dadosFormulario.tipo,
        dadosFormulario.SOCIO_RESPONSAVEL
      );
      if (typeof paramsConjuge.documentos !== 'undefined' && paramsConjuge.documentos.length > 0) {
        postConfirmarDocumentos(paramsConjuge)
          .then(res => {
            if (res.data && res.data.dados && res.data.dados.processado) {
              enviaPostDocumentosSocio(paramsSocio, values, form);
            } else {
              setMostraErro(true);
              setPending(false);
              setPendingDocumentosSocio(false);
              setPendingDocumentosConjuge(false);
              form.setSubmitting(false);
            }
          })
          .catch(err => {
            setMostraErro(err.response);
            setPending(false);
            setPendingDocumentosSocio(false);
            setPendingDocumentosConjuge(false);
            form.setSubmitting(false);
          });
      } else {
        enviaPostDocumentosSocio(paramsSocio, values, form);
      }
    } else {
      enviaPostDocumentosSocio(paramsSocio, values, form);
    }
  };

  const enviaPostDocumentosSocio = (params, values, form) => {
    if (typeof params.documentos !== 'undefined' && params.documentos.length > 0) {
      postConfirmarDocumentos(params)
        .then(res => {
          if (res.data && res.data.dados && res.data.dados.processado) {
            enviaPostAlterarSocio(values, form);
          } else {
            setMostraErro(true);
            setPending(false);
            setPendingDocumentosSocio(false);
            setPendingDocumentosConjuge(false);
            form.setSubmitting(false);
          }
        })
        .catch(err => {
          setMostraErro(err.response);
          setPending(false);
          setPendingDocumentosSocio(false);
          setPendingDocumentosConjuge(false);
          form.setSubmitting(false);
        });
    } else {
      enviaPostAlterarSocio(values, form, false);
    }
  };

  const enviaPostAlterarSocio = (values, form, docEnviado = true) => {
    values = formatarPayLoad(values);
    postAlterarDadosSocios(values)
      .then(() => {
        let tmpListaSocios = listaSocios !== undefined ? [...listaSocios] : [];
        tmpListaSocios[indexSocio] = values;
        if (docEnviado) tmpListaSocios[indexSocio].documentos_enviados = 'S';
        setListaSocios(tmpListaSocios);
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        form.setSubmitting(false);
        setPending(false);
        setPendingDocumentosSocio(false);
        setPendingDocumentosConjuge(false);
        botaoVoltar();
      });
  };

  const formatarPayLoad = dadosForm => {
    dadosForm.cpf_cnpj = strip(dadosForm.cpf_cnpj);
    dadosForm.celular = strip(dadosForm.celular);
    dadosForm.estado_civil = estadoCivil;
    dadosForm.pessoa_politicamente_exposta = pessoaPoliticaExposta;
    if (estadoCivil === 'CSD') {
      dadosForm.cpf_conjuge = strip(dadosForm.cpf_conjuge);
      dadosForm.regime_bens = regimeBens;
    } else {
      dadosForm.regime_bens = '';
      dadosForm.cpf_conjuge = '';
      dadosForm.nome_conjuge = '';
    }
    return dadosForm;
  };

  const formatarPayloadDocumentos = (
    dadosDocumentos,
    cpnj_socio,
    cnpj_conjuge,
    tipoEmpresa,
    tipoSocio,
    socioResponsavel = false
  ) => {
    let tempDocs = [];
    dadosDocumentos.map(doc => {
      if (
        typeof doc.hash_arquivo !== 'undefined' &&
        doc.hash_arquivo !== undefined &&
        doc.hash_arquivo !== null
      ) {
        let tempDoc = {
          cpf_socio: cpnj_socio,
          cpf_conjugue: cnpj_conjuge,
          hash: doc.hash_arquivo,
          cod_documento: doc.id,
          tipo: tipoSocio,
          socio_responsavel: socioResponsavel
        };
        tempDocs.push(tempDoc);
      }
      return doc;
    });

    let params = {
      cnpj: cnpjEmpresa,
      tipo_empresa: tipoEmpresa,
      cod_entrada: codEntrada,
      origem_proposta: origemProposta,
      cod_proposta_comercial: null,
      valida_documento: true,
      envia_email: false,
      documentos: tempDocs
    };

    return params;
  };

  const handleFormSubmit = (values, form) => {
    let resultDoc = confirmarDocumentosSocio(values, form);
    if (resultDoc === false || tipoDocumentoSocio === false) {
      setPending(false);
      setPendingDocumentosSocio(false);
      setPendingDocumentosConjuge(false);
      form.setSubmitting(false);
      return false;
    }
  };

  const botaoVoltar = () => {
    setCpfSocioAberto(false);
  };

  const alterarTipoDocumentoSocio = novoTipo => {
    if (novoTipo !== tipoDocumentoSocio) {
      setTipoDocumentoSocio(novoTipo);
      if (documentosSocioReferencia[novoTipo].length > 0) {
        let docTipo = [...documentosSocioReferencia[novoTipo]];
        let docComun = [...documentosSocioReferencia.COMUM];
        let documentosSociosTemp = docTipo.concat(docComun);
        setDocumentosSocio(documentosSociosTemp);
      }
    }
  };

  const alterarTipoDocumentoConjuge = novoTipo => {
    if (novoTipo !== tipoDocumentoConjuge) {
      setTipoDocumentoConjuge(novoTipo);
      if (documentosConjugeReferencia[novoTipo].length > 0) {
        let docTipo = [...documentosConjugeReferencia[novoTipo]];
        let docComun = [...documentosConjugeReferencia.COMUM];
        let documentosConjugesTemp = docTipo.concat(docComun);
        setDocumentosConjuge(documentosConjugesTemp);
      }
    }
  };

  const onChangeCpfConjuge = event => {
    let cnpjConj = strip(event.target.value);
    setCnpjConjugeSocio(cnpjConj === '' ? false : cnpjConj);
  };

  return (
    <Formik
      initialValues={dadosFormulario}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        nome: Yup.string()
          .nullable()
          .required('Favor informar o nome')
          .test('nome', 'Nome precisa ter 2 palavras ou mais', value => {
            if (value && value.length > 0) {
              value = value.trim();
              let result = value.includes(' ');
              if (!result) {
                setNomeBloqueado(result);
              }
              return result;
            }
          })
          .test('nome', 'Nome com caracteres Inválidos', value => {
            let caracteresEspeciais = [`'`, `*`, `"`];
            let result = validaCaracteresEspeciais(value, caracteresEspeciais);
            if (!result) {
              setNomeBloqueado(result);
            }
            return result;
          }),
        cpf_cnpj: Yup.string()
          .nullable()
          .required('Campo obrigatório.')
          .test({
            exclusive: true,
            message: 'CPF inválido.',
            name: 'cpf_cnpj',
            test: () => {
              return true;
            }
          }),
        data_nascimento: Yup.string()
          .nullable()
          .required('Favor informar a data de nascimento')
          .test({
            message: () => {
              return 'Data inválida';
            },
            name: 'data_nascimento',
            test: data => {
              if (data !== undefined && data !== '' && data.length >= 10) {
                data = data.substr(0, 10);
                let dia = data.split('/')[0];
                let mes = data.split('/')[1];
                let ano = data.split('/')[2];
                let dataAtual = new Date(ano, mes - 1, dia);
                if (
                  dataAtual.getMonth() + 1 !== parseInt(mes) ||
                  dataAtual.getDate() !== parseInt(dia) ||
                  dataAtual.getFullYear() !== parseInt(ano) ||
                  dataAtual > new Date()
                ) {
                  return false;
                }
                if (dataAtual.getFullYear() < 1900) {
                  return false;
                }
                return true;
              }
              return false;
            }
          }),
        email: Yup.string()
          .nullable()
          .required('Favor informar o e-mail do sócio'),
        celular: Yup.string()
          .nullable()
          .required('Favor informar o telefone do sócio')
          .test({
            message: 'Celular inválido',
            test: () => {
              return true;
            }
          })
      })}
      onSubmit={handleFormSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form>
          <ConnectedFocusError />
          <Field
            type="text"
            name="nome"
            titulo="Nome completo"
            id="nome"
            size="small"
            uppercase={true}
            component={InputMain}
            autoComplete="off"
            disabled={isPendingCadastro || isSubmitting || nomeBloqueado}
          />

          <Field
            type="text"
            name="cpf_cnpj"
            titulo="CPF/CNPJ"
            id="cpf_cnpj"
            size="small"
            uppercase={true}
            component={InputMain}
            autoComplete="off"
            disabled={isPendingCadastro || isSubmitting || dadosFormulario.cpf_cnpj !== ''}
            tipoMascara="CPFCNPJMask"
          />

          <Field
            type="text"
            name="data_nascimento"
            titulo="Data de nascimento"
            id="data_nascimento"
            size="small"
            inputProps={{ maxLength: 10 }}
            component={InputMain}
            autoComplete="off"
            disabled={isPendingCadastro || isSubmitting || dadosFormulario.data_nascimento !== ''}
            tipoMascara="dateMask"
          />

          <Field
            type="text"
            name="celular"
            titulo="Celular"
            id="celular"
            size="small"
            uppercase={true}
            component={InputMain}
            autoComplete="off"
            disabled={isPendingCadastro || isSubmitting || dadosFormulario.celular !== ''}
            tipoMascara="phoneMask"
          />

          <Field
            type="email"
            name="email"
            titulo="E-mail"
            id="email"
            size="small"
            uppercase={false}
            component={InputMain}
            autoComplete="off"
            disabled={isPendingCadastro || isSubmitting || dadosFormulario.email !== ''}
          />

          <ToggleButtonMain
            titulo="Estado cívil do sócio"
            tipo="input"
            nomeInput="estado_civil"
            funcaoHandleChange={setEstadoCivil}
            atributoHandle={estadoCivil}
            atributosBotao={{
              disabled: true
            }}
            botoes={Object.values(estadosCivil)}
          />

          {estadoCivil === 'CSD' && (
            <>
              <ToggleButtonMain
                titulo="Regime de Bens"
                tipo="input"
                nomeInput="regime_bens"
                funcaoHandleChange={setRegimeBens}
                atributoHandle={regimeBens}
                atributosBotao={{
                  disabled: isPendingCadastro || isSubmitting || dadosFormulario.regime_bens !== ''
                }}
                botoes={Object.values(regimesBens)}
              />

              <Field
                type="text"
                name="nome_conjuge"
                titulo="Nome completo do cônjuge"
                id="nome_conjuge"
                size="small"
                uppercase={true}
                component={InputMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting || dadosFormulario.nome_conjuge !== ''}
              />

              <Field
                type="text"
                name="cpf_conjuge"
                titulo="CPF do cônjuge"
                id="cpf_conjuge"
                size="small"
                onChangeInput={onChangeCpfConjuge}
                uppercase={true}
                component={InputMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting || dadosFormulario.cpf_conjuge !== ''}
                tipoMascara="CPFMask"
              />
            </>
          )}

          <ToggleButtonMain
            titulo="É uma pessoa politicamente exposta (PEP)?"
            nomeInput="input"
            nome="pessoa_politicamente_exposta"
            funcaoHandleChange={setPessoaPoliticaExposta}
            atributosBotao={{
              disabled:
                isPendingCadastro ||
                isSubmitting ||
                (dadosFormulario.pessoa_politicamente_exposta !== undefined &&
                  dadosFormulario.pessoa_politicamente_exposta !== '' &&
                  dadosFormulario.pessoa_politicamente_exposta !== null)
            }}
            atributoHandle={pessoaPoliticaExposta}
            botoes={[
              { valor: 'S', label: 'Sim', col: 3, sm: 4, xs: 6 },
              { valor: 'N', label: 'Não', col: 3, sm: 4, xs: 6 }
            ]}
          />

          <Box className="mt-2">
            <ToggleButtonMain
              titulo="Tipo de documento - Sócio"
              tipo="input"
              nomeInput="tipo_documento_socio"
              funcaoHandleChange={alterarTipoDocumentoSocio}
              atributoHandle={tipoDocumentoSocio}
              atributosBotao={{
                disabled: isPendingUploadSocio || isPendingDocumentosSocio
              }}
              botoes={Object.values(tiposDocumentosPessoaFisica)}
            />
          </Box>

          <Box
            sx={
              isPendingDocumentosSocio
                ? { position: 'relative', p: '25px 0 0 0', minHeight: '150px' }
                : { p: '25px 0 0 0' }
            }
          >
            {isPendingDocumentosSocio && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            {documentosSocio.length !== 0 &&
              documentosSocio.map((doc, index) => {
                var bordaBottom =
                  index + 1 === documentosSocio.length ? 'borda-transparente' : 'borda-cor-cinza-claro';
                return (
                  <DocumentoUpload
                    key={doc.id}
                    id={doc.id}
                    boxClassesAdicionais={bordaBottom}
                    isPending={isPendingUploadSocio || isSubmitting}
                    setPending={setPendingUploadSocio}
                    listaDocs={documentosSocio}
                    setListaDocs={setDocumentosSocio}
                    arquivosAceitos={tiposArquivosAceitos}
                    tamanhoMaximoMegaAceito={30}
                    cnpjEmpresa={dadosFormulario.cpf_cnpj}
                  />
                );
              })}
          </Box>

          {estadoCivil === 'CSD' && dadosFormulario.cpf_conjuge && (
            // SOMENTE IRA DEIXAR ELE UPAR OS DOCUMENTOS DO CONJUGE CASO JA EXISTA CADASTRO DO CONJUGE
            <>
              <Box className="mt-2">
                <ToggleButtonMain
                  titulo="Tipo de documento - Cônjuge"
                  tipo="input"
                  nomeInput="tipo_documento_conjuge"
                  funcaoHandleChange={alterarTipoDocumentoConjuge}
                  atributoHandle={tipoDocumentoConjuge}
                  atributosBotao={{
                    disabled: isPendingUploadConjuge || isPendingDocumentosConjuge
                  }}
                  botoes={Object.values(tiposDocumentosPessoaFisica)}
                />
              </Box>

              <Box
                sx={
                  isPendingDocumentosConjuge
                    ? { position: 'relative', p: '25px 0 0 0', minHeight: '150px' }
                    : { p: '25px 0 0 0' }
                }
              >
                {isPendingDocumentosConjuge && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                {documentosConjuge.length !== 0 &&
                  documentosConjuge.map((doc, index) => {
                    var bordaBottom =
                      index + 1 === documentosConjuge.length
                        ? 'borda-transparente'
                        : 'borda-cor-cinza-claro';
                    return (
                      <DocumentoUpload
                        key={doc.id}
                        id={doc.id}
                        adicional="conj"
                        boxClassesAdicionais={bordaBottom}
                        isPending={isPendingUploadConjuge || isSubmitting}
                        setPending={setPendingUploadConjuge}
                        listaDocs={documentosConjuge}
                        setListaDocs={setDocumentosConjuge}
                        arquivosAceitos={tiposArquivosAceitos}
                        tamanhoMaximoMegaAceito={30}
                        cnpjEmpresa={cnpjConjugeSocio}
                        bloquearUpload={cnpjConjugeSocio === false ? true : false}
                      />
                    );
                  })}
              </Box>
            </>
          )}

          <Grid container className="mb-4">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}>
              <ButtonMain
                tipoBotao="transparente"
                disabled={
                  isPendingCadastro || isSubmitting || isPendingDocumentosSocio || isPendingUploadSocio
                }
                onClick={() => {
                  botaoVoltar();
                }}
              >
                Voltar
              </ButtonMain>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
              <ButtonMain
                type="submit"
                tipoBotao="principal"
                disabled={
                  isPendingCadastro ||
                  isSubmitting ||
                  isPendingDocumentosSocio ||
                  isPendingUploadSocio ||
                  !tipoDocumentoSocio
                }
              >
                Continuar
              </ButtonMain>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
