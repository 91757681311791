import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { memo } from 'react';

const ModalConfirmacaoRetorno = ({ modal, toggle, message, confirm }) => {
  return (
    <Modal isOpen={modal.show} toggle={toggle} centered size="sm" className="delete-modal">
      <ModalHeader toggle={toggle}>Retornar operação</ModalHeader>
      <ModalBody>
        {message && (
          <div className="alert alert-danger animated fadeIn" role="alert">
            {message}
          </div>
        )}
        Caso você retorne esta operação, os títulos adicionados serão removidos, Deseja retornar a
        operação?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="md" className="mr-auto" outline onClick={toggle}>
          Não, prefiro continuar
        </Button>
        <Button color="primary" size="md" onClick={confirm}>
          Retornar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalConfirmacaoRetorno.defaultProps = {
  modal: { show: false }
};

export default memo(ModalConfirmacaoRetorno);
