import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  TextField
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { KeyboardArrowDown } from '@mui/icons-material';
import InputMain from '../../shared/InputMain/InputMain';
import ButtonMain from '../../shared/ButtonMain';
import {
  getListaBancos,
  getDadosBancarios,
  postDadosBancarios,
  postDeletarDadosBancarios,
  postConfirmarDadosBancarios
} from '../../../../api/onboarding';
import { setTelas } from '../../../../shared';
import AlertErroMain from '../../shared/AlertErroMain';
import LoaderMain from '../../shared/LoaderMain';

export default ({ cnpjEmpresa, avancarEtapa, isPending, setPending }) => {
  const [alterandoDadosBancarios, setAlterandoDadosBancarios] = useState(false);
  const [modalDeletarDadosBancarios, setModalDeletarDadosBancarios] = useState(false);
  const [dadosBancarios, setDadosBancarios] = useState({});
  const [dadosFormulario, setDadosFormulario] = useState({});
  const [listaBancos, setListaBancos] = useState([]);
  const [newLstBancos, setNewLstBancos] = useState([]);
  const [valueCodBanco, setValueCodBanco] = useState('');
  const [mostraErro, setMostraErro] = useState(false);
  const [isPendingBanco, setIsPendingBanco] = useState(false);

  useEffect(() => {
    setIsPendingBanco(true);

    getListaBancos()
      .then(resBanco => {
        if (resBanco.data) {
          let lstBancosTemp = [...resBanco.data];
          let lstBancos = {};
          lstBancosTemp.map(banco => {
            lstBancos[banco.CODBANCO] = banco;
            return banco;
          });
          setListaBancos(lstBancos);

          let newLstBc = Object.values(lstBancos).map(item => {
            return { label: `${item.CODBANCO} - ${item.NOME}`, value: item.CODBANCO };
          });

          setNewLstBancos(newLstBc);

          getDadosBancarios(cnpjEmpresa)
            .then(resultContaCorrente => {
              if (
                resultContaCorrente.data &&
                resultContaCorrente.data.dados &&
                resultContaCorrente.data.dados.conta_corrente
              ) {
                setDadosBancarios(resultContaCorrente.data.dados.conta_corrente);
              }
            })
            .catch(err => {
              setMostraErro(err.response);
            })
            .finally(() => {
              setIsPendingBanco(false);
            });
        }
      })
      .catch(err => {
        setMostraErro(err.response);
        setIsPendingBanco(false);
      });
  }, []);

  const handleFormSubmit = (values, form) => {
    if (!valueCodBanco || valueCodBanco === '') {
      form.setSubmitting(false);
      return;
    }

    values.codBanco = valueCodBanco;
    values.cnpj = cnpjEmpresa;
    values.alteracao = alterandoDadosBancarios;
    values.agencia_digito = values && values.agencia_digito ? values.agencia_digito : '';

    setIsPendingBanco(true);
    postDadosBancarios(values)
      .then(() => {
        setDadosBancarios(values);
        setDadosFormulario({});
        setAlterandoDadosBancarios(false);
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setIsPendingBanco(false);
        form.setSubmitting(false);
      });
  };

  const confirmarDadosBancarios = values => {
    values.cnpj = cnpjEmpresa;
    setPending(true);
    postConfirmarDadosBancarios(values)
      .then(() => {
        setTelas(false, true);
        avancarEtapa();
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const deletarDadosBancarios = () => {
    setModalDeletarDadosBancarios(false);
    setPending(true);
    postDeletarDadosBancarios(cnpjEmpresa)
      .then(() => {
        setDadosBancarios({});
        setDadosFormulario({});
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const abrirAlterarDadosBancarios = () => {
    setDadosFormulario(dadosBancarios);
    setValueCodBanco(dadosBancarios && dadosBancarios.codBanco ? dadosBancarios.codBanco : '');
    setAlterandoDadosBancarios(true);
  };

  const handleOnChangeBanco = (ev, newValue) => {
    setValueCodBanco(newValue && newValue.value ? newValue.value : '');
  };

  return (
    <Grid container>
      <Grid item xl={2} lg={2} md={2} sm={1} />
      <Grid item xl={8} lg={8} md={8} sm={10} xs={12}>
        <Box sx={{ p: '0 0 30px 0' }}>
          <Typography variant="h5" className="texto-cor-principal texto-negrito texto-centro">
            Dados bancários
          </Typography>
          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
            Preencha seus dados bancários para as operações de crédito
          </Typography>
        </Box>
        <Box>
          {alterandoDadosBancarios || Object.keys(dadosBancarios).length === 0 ? (
            <Formik
              initialValues={dadosFormulario}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                codBancoNovo: Yup.string()
                  .nullable()
                  .test('codBancoNovo', 'Escolha o banco', () => {
                    if (valueCodBanco === '') {
                      setValueCodBanco(false);
                      return false;
                    }
                    return true;
                  }),
                agencia: Yup.string()
                  .nullable()
                  .required('Favor informar a agência'),
                agencia_digito: Yup.string().nullable(),
                conta_corrente: Yup.string()
                  .nullable()
                  .required('Favor informar a conta corrente'),
                conta_corrente_digito: Yup.string()
                  .nullable()
                  .required('Favor informar o digito da conta corrente')
              })}
              onSubmit={handleFormSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              render={({ isSubmitting }) => {
                return (
                  <Box
                    sx={isPendingBanco ? { position: 'relative', p: '25px 0 0 0' } : { p: '25px 0 0 0' }}
                  >
                    {isPendingBanco && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

                    <Form>
                      <ConnectedFocusError />

                      <Typography className="texto-cor-cinza-escuro label-padding">
                        Busque por instituição
                      </Typography>

                      <Autocomplete
                        disablePortal
                        id="codBancoNovo"
                        name="codBancoNovo"
                        onChange={handleOnChangeBanco}
                        className={`combobox_principal borda-autocomplete ${
                          valueCodBanco === false ? 'borda-erro-autocomplete' : ''
                        }`}
                        popupIcon={
                          <KeyboardArrowDown className="texto-cor-complementar w-1-5em font-size-2rem" />
                        }
                        options={newLstBancos || []}
                        value={newLstBancos.find(option => option.value === valueCodBanco)}
                        autoHighlight
                        renderInput={params => <TextField {...params} label="" />}
                        noOptionsText="Nenhuma resultado encontrado"
                      />

                      {valueCodBanco === false && (
                        <Typography className="texto-danger label-padding">
                          Favor escolher um banco
                        </Typography>
                      )}

                      <Grid container spacing={2}>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={9}>
                          <Field
                            type="text"
                            name="agencia"
                            titulo="Agência"
                            id="agencia"
                            size="small"
                            uppercase={true}
                            component={InputMain}
                            autoComplete="off"
                            disabled={isPending || isSubmitting}
                          />
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={3}>
                          <Field
                            type="text"
                            name="agencia_digito"
                            titulo="Dígito"
                            id="agencia_digito"
                            size="small"
                            uppercase={true}
                            component={InputMain}
                            autoComplete="off"
                            disabled={isPending || isSubmitting}
                          />
                        </Grid>
                        <Grid item xl={5} lg={5} md={5} sm={5} xs={9}>
                          <Field
                            type="text"
                            name="conta_corrente"
                            titulo="Conta Corrente"
                            id="conta_corrente"
                            size="small"
                            uppercase={true}
                            component={InputMain}
                            autoComplete="off"
                            disabled={isPending || isSubmitting}
                          />
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={3}>
                          <Field
                            type="text"
                            name="conta_corrente_digito"
                            titulo="Digito"
                            id="conta_corrente_digito"
                            size="small"
                            uppercase={true}
                            component={InputMain}
                            autoComplete="off"
                            disabled={isPending || isSubmitting}
                          />
                        </Grid>
                      </Grid>

                      <Box
                        className="borda-1 borda-cor-suporte border-radius-8 mt-3"
                        sx={{ p: '0px 15px' }}
                      >
                        <Typography
                          variant="subtitle1"
                          className="texto-cor-cinza-escuro texto-negrito mt-2"
                        >
                          Você pode adicionar outras contas depois
                        </Typography>
                        <Typography variant="subtitle1" className="texto-cor-cinza-escuro mb-2">
                          Na área de operações é possível incluir outras contas e escolher onde você
                          prefere receber o crédito
                        </Typography>
                      </Box>

                      <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

                      <Grid container spacing={3} className="pad-top-40">
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                        >
                          {alterandoDadosBancarios && (
                            <ButtonMain
                              tipoBotao="transparente"
                              disabled={isPending || isSubmitting}
                              onClick={() => {
                                setAlterandoDadosBancarios(false);
                              }}
                            >
                              Voltar
                            </ButtonMain>
                          )}
                        </Grid>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                        >
                          <ButtonMain
                            type="submit"
                            tipoBotao="principal"
                            disabled={isPending || isSubmitting}
                          >
                            Continuar
                          </ButtonMain>
                        </Grid>
                      </Grid>
                    </Form>
                  </Box>
                );
              }}
            />
          ) : (
            <>
              <Box className="borda-1 borda-cor-complementar pad-topbot-20 pad-right-left-15 border-radius-8">
                <Grid container spacing={1}>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={8}>
                    <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                      <b>Instituição: </b>
                      {`${listaBancos[dadosBancarios.codBanco].CODBANCO} - ${
                        listaBancos[dadosBancarios.codBanco].NOME
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                    <ButtonMain
                      tipoBotao="transparente"
                      disabled={isPending}
                      classesAdicional="min-width-none"
                      onClick={() => {
                        abrirAlterarDadosBancarios();
                      }}
                    >
                      <CreateOutlinedIcon />
                    </ButtonMain>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                    <ButtonMain
                      tipoBotao="transparente"
                      disabled={isPending}
                      classesAdicional="min-width-none"
                      onClick={() => {
                        setModalDeletarDadosBancarios(true);
                      }}
                    >
                      <DeleteForeverOutlinedIcon />
                    </ButtonMain>
                  </Grid>
                </Grid>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Agência: </b>
                  {dadosBancarios.agencia}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Agência Dígito: </b>
                  {dadosBancarios.agencia_digito}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Conta Corrente: </b>
                  {dadosBancarios.conta_corrente}
                </Typography>
                <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-5">
                  <b>Digito: </b>
                  {dadosBancarios.conta_corrente_digito}
                </Typography>
              </Box>

              <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

              <Grid container spacing={3} className="pad-top-40">
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                />
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                  <ButtonMain
                    type="button"
                    tipoBotao="principal"
                    disabled={
                      isPending ||
                      dadosBancarios === undefined ||
                      Object.keys(dadosBancarios).length === 0
                    }
                    onClick={() => confirmarDadosBancarios(dadosBancarios)}
                  >
                    Confirmar
                  </ButtonMain>
                </Grid>
              </Grid>
            </>
          )}

          <Dialog
            open={modalDeletarDadosBancarios}
            keepMounted
            onClose={() => setModalDeletarDadosBancarios(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
              Excluir dados do imóvel
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ao confirmar esta ação todos as informações dos dados bancários serão excluidos. Você
                quer mesmo continuar?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xl={6} lg={6} md={4} sm={2} />
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  <ButtonMain
                    tipoBotao="transparente"
                    onClick={() => setModalDeletarDadosBancarios(false)}
                  >
                    Cancelar
                  </ButtonMain>
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  <ButtonMain tipoBotao="azul-escuro" onClick={deletarDadosBancarios} autoFocus>
                    Confirmar
                  </ButtonMain>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
    </Grid>
  );
};
