import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CheckCircle, Schedule, Cancel } from '@mui/icons-material';
import Empresa from '../../../Documentos/Empresa/Empresa';
import Sociedade from '../../../Documentos/Sociedade/Sociedade';
import {
  avancarEstadoPropostaContrato,
  getDocumentosEnviadosCliente,
  getListaTiposDocumentos
} from '../../../../api/onboarding';
import ButtonMain from '../../shared/ButtonMain';
import AlertErroMain from '../../shared/AlertErroMain';

export default ({ avancarEtapa, isPending, setPending, cnpjEmpresa, origemProposta }) => {
  const [mudaAbaDocumentos, setMudaAbaDocumentos] = useState('Empresa');
  const [isPendingEmpresa, setPendingEmpresa] = useState(false);
  const [dadosDocumentosEmpresa, setDadosDocumentosEmpresa] = useState({});
  const [alertaEmpresa, setAlertaEmpresa] = useState(false);
  const [isPendingSociedade, setPendingSociedade] = useState(false);
  const [dadosDocumentosSociedade, setDadosDocumentosSociedade] = useState({});
  const [alertaSociedade, setAlertaSociedade] = useState(false);
  const [detalhesDocumentos, setDetalhesDocumentos] = useState(false);
  const [listaTiposDocumentos, setListaTiposDocumentos] = useState([]);
  const [mostraErro, setMostraErro] = useState(false);
  const [tiposArquivosAceitos, setTiposArquivosAceitos] = useState(['pdf']);

  const iconeStatus = {
    APROVADO: <CheckCircle className="font-size-14 texto-success" sx={{ mr: '10px' }} />,
    REPROVADO: <Cancel className="font-size-14 texto-danger" sx={{ mr: '10px' }} />,
    ANALISE: <Schedule className="font-size-14 texto-warning" sx={{ mr: '10px' }} />
  };

  const mudaAba = value => {
    setMudaAbaDocumentos(value);
  };

  useEffect(() => {
    carregarTodasAbas();
    // BUSCA OS TIPOS
    getListaTiposDocumentos()
      .then(retornoTipos => {
        let ret = retornoTipos.data;
        if (ret.dados && ret.dados.length > 0) {
          setListaTiposDocumentos(ret.dados);
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      });
  }, []);

  const carregarTodasAbas = () => {
    carregarAbaEmpresa();
    carregarAbaSociedade();
  };

  const carregarAbaEmpresa = () => {
    setPendingEmpresa(true);
    var params = {
      cnpj: cnpjEmpresa,
      pesquisado_por: 'EMPRESA',
      origem_proposta: origemProposta,
      tipo_status_documento: 'aceitacao'
    };
    getDocumentosEnviadosCliente(params)
      .then(retorno => {
        let dados = retorno.data;
        if (dados.processado && dados.detalhes) {
          setDadosDocumentosEmpresa(dados.detalhes.empresa);
        } else {
          setDadosDocumentosEmpresa({});
        }
      })
      .finally(() => {
        setPendingEmpresa(false);
      })
      .catch(err => {
        setMostraErro(err.response);
      });
  };

  const carregarAbaSociedade = () => {
    setPendingSociedade(true);
    var params = {
      cnpj: cnpjEmpresa,
      pesquisado_por: 'SOCIOS',
      origem_proposta: origemProposta,
      tipo_status_documento: 'aceitacao'
    };
    getDocumentosEnviadosCliente(params)
      .then(retorno => {
        let dados = retorno.data;
        if (dados.processado && dados.detalhes) {
          setDadosDocumentosSociedade(dados.detalhes.socios);
        } else {
          setDadosDocumentosSociedade({});
        }
      })
      .finally(() => {
        setPendingSociedade(false);
      })
      .catch(err => {
        setMostraErro(err.response);
      });
  };

  const finalizarGerenciarDocumentos = () => {
    let params = {
      cnpj_empresa: cnpjEmpresa,
      origem_proposta: origemProposta,
      estado_atual: 'gerenciar_documentos'
    };
    avancarEstadoPropostaContrato(params)
      .then(() => {
        avancarEtapa();
      })
      .catch(() => {
        setMostraErro(true);
      })
      .finally(() => {
        setPending(false);
      });
  };

  useEffect(() => {
    if (dadosDocumentosEmpresa.dados && dadosDocumentosEmpresa.dados.status_documentos === 'REPROVADO') {
      setAlertaEmpresa(true);
    } else {
      setAlertaEmpresa(false);
    }
    setAlertaSociedade(false);
    if (Object.keys(dadosDocumentosSociedade).length > 0) {
      Object.keys(dadosDocumentosSociedade).map(chaveSocio => {
        if (dadosDocumentosSociedade[chaveSocio].dados.status_documentos === 'REPROVADO') {
          setAlertaSociedade(true);
        }
        return chaveSocio;
      });
    }
  }, [dadosDocumentosEmpresa, dadosDocumentosSociedade]);

  useEffect(() => {
    var tiposArqAceitos = ['pdf'];
    if (origemProposta === 'connect' || origemProposta === 'indicantes') {
      tiposArqAceitos = ['pdf', 'imagens', 'word', 'powerPoint', 'excel'];
    } else if (origemProposta === 'one7_mais_credito') {
      tiposArqAceitos = ['pdf', 'png', 'jpg'];
    }
    setTiposArquivosAceitos(tiposArqAceitos);
  }, []);

  return (
    <Box>
      {detalhesDocumentos === false && (
        <Box>
          <Typography variant="h5" className="mt-2 texto-cor-principal texto-negrito texto-centro">
            Documentações
          </Typography>
          <Grid container>
            <Grid item xl={1} lg={1} md={1} sm={1} />
            <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
              <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
                <span>
                  Mantenha a documentação da empresa e dos sócios atualizada para garantir uma melhor
                  análise de crédito
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ m: '60px 15px 0 15px' }}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ButtonMain
                  tipoBotao={
                    mudaAbaDocumentos === 'Empresa' ? 'azul-escuro' : 'transparente-com-bordas-principal'
                  }
                  onClick={() => mudaAba('Empresa')}
                >
                  Documentos da empresa
                  {alertaEmpresa && <div className="icone-alert-right-top" />}
                </ButtonMain>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ButtonMain
                  tipoBotao={
                    mudaAbaDocumentos === 'Sociedade'
                      ? 'azul-escuro'
                      : 'transparente-com-bordas-principal'
                  }
                  onClick={() => mudaAba('Sociedade')}
                >
                  Documentos da sociedade
                  {alertaSociedade && <div className="icone-alert-right-top" />}
                </ButtonMain>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      <Box sx={{ p: '40px 15px' }}>
        {mudaAbaDocumentos === 'Empresa' && (
          <Empresa
            dadosDocumentosEmpresa={dadosDocumentosEmpresa}
            setDadosDocumentosEmpresa={setDadosDocumentosEmpresa}
            detalhesDocumentos={detalhesDocumentos}
            setDetalhesDocumentos={setDetalhesDocumentos}
            iconeStatus={iconeStatus}
            listaTiposDocumentos={listaTiposDocumentos}
            cnpjEmpresa={cnpjEmpresa}
            isPending={isPendingEmpresa || isPending}
            carregarAbaEmpresa={carregarAbaEmpresa}
            bloquearTipoDocDuplicado={false}
            arquivosAceitos={tiposArquivosAceitos}
          />
        )}
        {mudaAbaDocumentos === 'Sociedade' && (
          <Sociedade
            dadosDocumentosSociedade={dadosDocumentosSociedade}
            setDadosDocumentosSociedade={setDadosDocumentosSociedade}
            detalhesDocumentos={detalhesDocumentos}
            setDetalhesDocumentos={setDetalhesDocumentos}
            iconeStatus={iconeStatus}
            listaTiposDocumentos={listaTiposDocumentos}
            cnpjEmpresa={cnpjEmpresa}
            isPending={isPendingSociedade || isPending}
            carregarAbaSociedade={carregarAbaSociedade}
            bloquearTipoDocDuplicado={false}
            arquivosAceitos={tiposArquivosAceitos}
          />
        )}
      </Box>
      <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

      {detalhesDocumentos === false && (
        <Grid container spacing={3} className="pad-topbot-50">
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }} />
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12} order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
            <ButtonMain
              type="button"
              tipoBotao="principal"
              disabled={
                isPendingEmpresa || isPendingSociedade || isPending || alertaEmpresa || alertaSociedade
              }
              onClick={() => finalizarGerenciarDocumentos()}
            >
              Finalizar
            </ButtonMain>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
