import React, { useState, useEffect } from 'react';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
// import consultaCEP from '../../../../../api/consultaCep';
import * as Yup from 'yup';
import AlertErroMain from '../../../shared/AlertErroMain';
import InputMain from '../../../shared/InputMain/InputMain';
import ButtonMain from '../../../shared/ButtonMain';
import strip from '../../../../../utils/strip';
import { postCadastroDadosEndereco, getDadosCEP } from '../../../../../api/onboarding';

export default ({
  mudarAba,
  todosDadosCadastrais,
  setTodosDadosCadastrais,
  isPendingCadastro,
  setPendingCadastro
}) => {
  const [dadosOnboarding, setDadosOnboarding] = useState(false);
  const [erroCEPNaoEncontrado, setErroCEPNaoEncontrado] = useState(false);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [cidadeBloqueada, setCidadeBloqueada] = useState(true);
  const [estadoBloqueado, setEstadoBloqueado] = useState(true);
  const [mostraErro, setMostraErro] = useState(false);

  useEffect(() => {
    if (todosDadosCadastrais.cnpj === undefined) return false;
    if (dadosOnboarding) return false;

    var dadosOnboardingTemp = { cnpj: todosDadosCadastrais.cnpj, ...todosDadosCadastrais.DadosEndereco };

    setDadosOnboarding(dadosOnboardingTemp);
  }, [todosDadosCadastrais]);

  const buscarCEP = valuesForm => {
    var cep = valuesForm.cep ? strip(valuesForm.cep).substr(0, 8) : '';
    if (cep === '') return false;
    setErroCEPNaoEncontrado(false);
    setPendingCadastro(true);
    getDadosCEP(cep)
      .then(res => {
        if (res.data && res.data.dados && res.data.dados.cidade) {
          var dadosCEP = {
            logradouro: res.data.dados.endereco,
            bairro: res.data.dados.bairro,
            uf: res.data.dados.estado,
            cidade: res.data.dados.cidade
          };

          if (res.data.dados.cidade && res.data.dados.cidade === '') {
            setCidadeBloqueada(false);
          } else {
            setCidadeBloqueada(true);
          }

          if (res.data.dados.estado && res.data.dados.estado === '') {
            setEstadoBloqueado(false);
          } else {
            setEstadoBloqueado(true);
          }

          delete valuesForm.logradouro;
          delete valuesForm.bairro;
          delete valuesForm.uf;
          delete valuesForm.cidade;
          var dados = { ...dadosCEP, ...valuesForm };
          setDadosOnboarding(dados);
        } else {
          if (
            res.data &&
            res.data.dados &&
            res.data.dados.original &&
            res.data.dados.original.substr(0, 24) === 'FALHA AO CONSULTAR O CEP'
          ) {
            setMostraErro({
              title: 'Atenção',
              msg: 'Consulta de CEP indisponível',
              tipoAlerta: 'warning'
            });
            setCidadeBloqueada(false);
            setEstadoBloqueado(false);
          } else {
            setErroCEPNaoEncontrado(true);
            limparCampos(valuesForm);
          }
        }
      })
      .catch(err => {
        setMostraErro(err.response);
        setCidadeBloqueada(false);
        setEstadoBloqueado(false);
      })
      .finally(() => {
        setPendingCadastro(false);
      });
  };

  const handleFormSubmit = (values, form) => {
    var dadosForm = { ...values };
    dadosForm.cnpj = dadosOnboarding.cnpj;
    dadosForm.cidade = dadosOnboarding.cidade ? dadosOnboarding.cidade : values.cidade;
    dadosForm.uf = dadosOnboarding.uf ? dadosOnboarding.uf : values.uf;
    dadosForm.cep = dadosOnboarding.cep
      ? strip(dadosOnboarding.cep).substr(0, 8)
      : strip(values.cep).substr(0, 8);
    dadosForm.numero = String(values.numero ? values.numero : '');
    dadosForm.complemento = values.complemento ? values.complemento : '';
    setPendingCadastro(true);
    postCadastroDadosEndereco(dadosForm)
      .then(() => {
        var dadosCad = { ...todosDadosCadastrais };
        dadosCad.DadosEndereco = dadosForm;
        setTodosDadosCadastrais(dadosCad);

        setPendingCadastro(false);
        form.setSubmitting(false);

        mudarAba('DadosSocios');
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPendingCadastro(false);
        form.setSubmitting(false);
      });
  };

  const limparCampos = valuesForm => {
    var dadosClear = {
      logradouro: '',
      bairro: '',
      uf: '',
      cidade: '',
      numero: '',
      complemento: ''
    };

    var dados = { ...valuesForm, ...dadosClear };
    setDadosOnboarding(dados);
  };

  return (
    <Formik
      initialValues={dadosOnboarding}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        cep: Yup.string()
          .nullable()
          .required('Favor informar o CEP'),
        logradouro: Yup.string()
          .nullable()
          .required('Favor informar a rua'),
        numero: Yup.string()
          .nullable()
          .required('Favor informar o número'),
        complemento: Yup.string().nullable(),
        bairro: Yup.string()
          .nullable()
          .required('Favor informar o bairro'),
        cidade: Yup.string()
          .nullable()
          .required('Favor buscar outro CEP para preencher a cidade'),
        uf: Yup.string()
          .nullable()
          .required('Favor buscar outro CEP para preencher o estado')
      })}
      onSubmit={handleFormSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      render={({ values, isSubmitting }) => {
        return (
          <Form>
            <ConnectedFocusError />
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                <Field
                  type="text"
                  name="cep"
                  titulo="CEP"
                  id="cep"
                  size="small"
                  uppercase={true}
                  component={InputMain}
                  erro={erroCEPNaoEncontrado ? 'CEP não encontrado' : ''}
                  autoComplete="off"
                  disabled={isPendingCadastro || isSubmitting}
                  tipoMascara="CEPMask"
                />

                {erroCEPNaoEncontrado && (
                  <a
                    className="mt-2 font-size-1-rem"
                    disabled={isPendingCadastro || isSubmitting}
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Não sei o CEP
                  </a>
                )}
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <ButtonMain
                  tipoBotao="azul-escuro"
                  disabled={isPendingCadastro || isSubmitting}
                  className="margin-top-label"
                  onClick={() => buscarCEP(values)}
                >
                  {onlySmallScreen ? 'Buscar' : 'Buscar CEP'}
                </ButtonMain>
              </Grid>
            </Grid>
            <Field
              type="text"
              name="logradouro"
              titulo="Endereço"
              id="logradouro"
              size="small"
              uppercase={true}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
            />
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <Field
                  type="number"
                  name="numero"
                  titulo="Número"
                  id="numero"
                  size="small"
                  uppercase={true}
                  component={InputMain}
                  autoComplete="off"
                  disabled={isPendingCadastro || isSubmitting}
                />
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                <Field
                  type="text"
                  name="complemento"
                  titulo="Complemento"
                  id="complemento"
                  size="small"
                  uppercase={true}
                  component={InputMain}
                  autoComplete="off"
                  disabled={isPendingCadastro || isSubmitting}
                />
              </Grid>
            </Grid>
            <Field
              type="text"
              name="bairro"
              titulo="Bairro"
              id="bairro"
              size="small"
              uppercase={true}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
            />
            <Field
              type="text"
              name="cidade"
              titulo="Cidade"
              id="cidade"
              size="small"
              uppercase={true}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
              inputProps={{
                readOnly: cidadeBloqueada
              }}
              inputClassName={estadoBloqueado ? 'fundo-cor-cinza-claro' : ''}
            />
            <Field
              type="text"
              name="uf"
              titulo="UF"
              id="uf"
              size="small"
              uppercase={true}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
              inputProps={{
                readOnly: estadoBloqueado,
                maxLength: 2
              }}
              inputClassName={estadoBloqueado ? 'fundo-cor-cinza-claro' : ''}
            />

            <AlertErroMain
              exibeErro={mostraErro}
              escondeErro={setMostraErro}
              titleErro={mostraErro && mostraErro.title ? mostraErro.title : ''}
              msgErro={mostraErro && mostraErro.msg ? mostraErro.msg : ''}
              tipoAlerta={mostraErro && mostraErro.tipoAlerta ? mostraErro.tipoAlerta : 'error'}
            />

            <Grid container spacing={3} className="pad-topbot-50">
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
              >
                <ButtonMain
                  tipoBotao="transparente"
                  disabled={isPendingCadastro || isSubmitting}
                  onClick={() => {
                    mudarAba('DadosEmpresa');
                  }}
                >
                  Voltar
                </ButtonMain>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
              >
                <ButtonMain
                  type="submit"
                  tipoBotao="principal"
                  disabled={isPendingCadastro || isSubmitting}
                >
                  Continuar
                </ButtonMain>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    />
  );
};
