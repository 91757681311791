import { FastField, Form, Field } from 'formik';
import React, { memo, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
  CEPInput,
  CPFInput,
  DANFEInput,
  CMC7Input,
  CurrencyInput,
  DateTimePicker,
  Input,
  PhoneInput,
  Select
} from '../../../../../shared';
import NumberInput from '../../../../../shared/components/inputs/NumberInput/NumberInput';
import StatesToSelectOptions from './helper';
import { TIPO_TITULO } from './schema';

const onCEPSearch = (data, form, field) => {
  if (!form) {
    return;
  }
  if (data) {
    const { cep, logradouro, bairro, uf, localidade } = data;
    const cidade = localidade;
    const selectedFormName = field.name;
    const selectedFormNameValue = selectedFormName.split('.')[0];

    form.setFieldValue(
      selectedFormNameValue + '.endereco',
      {
        cep,
        bairro,
        cidade,
        logradouro,
        uf
      },
      true
    );

    if (document.getElementById('addressNumber')) {
      document.getElementById('addressNumber').focus();
    }
  }
};

const onClientSearch = (data, form, field) => {
  if (field && field.name) {
    const selectedFormName = field.name;
    const selectedFormNameValue = selectedFormName.split('.')[0];

    if (form && data && data.length) {
      const { enderecos, nome, nomeFantasia, razaoSocial, telefones, email } =
        data[0].fisica || data[0].juridica;

      const {
        bairro = '',
        cep = '',
        complemento = '',
        cidade = '',
        logradouro = '',
        uf = '',
        numero = ''
      } = enderecos[0];

      // Com o formik é possível setar o valor dos campos de uma só vez
      // Mas seto um por um devido ao auto complete do cpf, que pode causar rerender

      form.setFieldValue(selectedFormNameValue + '.nome', nome || razaoSocial || nomeFantasia);
      form.setFieldValue(selectedFormNameValue + '.telefone', `${telefones[0].ddd}${telefones[0].fone}`);
      form.setFieldValue(selectedFormNameValue + '.email', email);
      form.setFieldValue(selectedFormNameValue + '.eCliente', data[0].eCliente);
      form.setFieldValue(
        selectedFormNameValue + '.endereco',
        {
          bairro,
          cep,
          complemento,
          cidade,
          logradouro,
          numero,
          uf
        },
        true
      );
    } else if (form) {
      const { bairro, cep, complemento, cidade, logradouro, uf, numero } = '';

      form.setFieldValue(selectedFormNameValue + '.nome', '');
      form.setFieldValue(selectedFormNameValue + '.telefone', '');
      form.setFieldValue(selectedFormNameValue + '.email', '');
      form.setFieldValue(selectedFormNameValue + '.eCliente', false);
      form.setFieldValue(
        selectedFormNameValue + '.endereco',
        {
          bairro,
          cep,
          complemento,
          cidade,
          logradouro,
          numero,
          uf
        },
        true
      );
    }
  }
};

const onDanfeChange = (data, form) => {
  if (data && form && form.dirty) {
    const { numeroNFE = '' } = data;
    form.setFieldValue('duplicata.nf', numeroNFE);
  }
};

const onCMC7Change = (data, form) => {
  if (data && form) {
    const {
      banco = '',
      compensacao = '',
      agencia = '',
      contaCorrente = '',
      numeroDocumento = '',
      documento = '',
      nome = '',
      email = ''
    } = data;

    form.setFieldValue('cheque.codBanco', banco);
    form.setFieldValue('cheque.codAgencia', agencia);
    form.setFieldValue('cheque.codcc', contaCorrente);
    form.setFieldValue('cheque.compensacao', compensacao);
    form.setFieldValue('cheque.ndoc', numeroDocumento);

    if (documento) {
      form.setFieldValue('sacado.cpfCnpj', documento);
      form.setFieldValue('sacado.nome', nome);
      form.setFieldValue('sacado.email', email);
      form.setFieldValue('sacado.eCliente', data.eCliente);

      const eventDocumento = new KeyboardEvent('keyup', {
        bubbles: true,
        cancelable: true,
        keyCode: 104
      });

      document.getElementById('cpf/cnpj').dispatchEvent(eventDocumento);
    }
  }
};

const CPFInputWrapper = props => <CPFInput {...props} onSearch={onClientSearch} />;
const CEPInputWrapper = props => <CEPInput {...props} onSearch={onCEPSearch} />;
const CMC7InputWrapper = props => <CMC7Input {...props} onSearch={onCMC7Change} />;
const DANFEInputWrapper = props => <DANFEInput {...props} onSearch={onDanfeChange} />;

const InclusaoTituloForm = ({
  bordero,
  isSubmitting,
  regras,
  setAlteraCampos,
  alteraCampos,
  values,
  bloquearFormulario,
  liberaPreencherCedente
}) => {
  const states = StatesToSelectOptions();

  useEffect(function InclusaoTituloForm() {
    if (values && values.status) {
      values.status.forEach(valor => {
        if (valor && valor.campo) {
          if (!alteraCampos.includes(valor.campo)) {
            setAlteraCampos([...alteraCampos, valor.campo]);
          }
        }
      });
    }
  });

  const isDuplicata =
    bordero &&
    bordero.tipoTitulo &&
    (bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA ||
      bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA_SERVICO);

  const isCheque = bordero && bordero.tipoTitulo && bordero.tipoTitulo.code === TIPO_TITULO.CHEQUE;
  return (
    <Form>
      <Row>
        <Col md="12">
          <div className="d-flex align-items-center">
            <h1 className="title">Dados do Sacado:</h1>
            {bloquearFormulario === false && (
              <Button type="reset" className="clear-fields btn btn-link" disabled={isSubmitting}>
                Limpar Campos
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="12">
          <FastField
            type="text"
            label="CPF/CNPJ"
            labelClassName="required-label"
            name="sacado.cpfCnpj"
            id="cpf/cnpj"
            disabled={isSubmitting || bloquearFormulario}
            autoComplete="nope"
            component={CPFInputWrapper}
          />
          <FastField type="hidden" name="sacado.eCliente" id="eCliente" component={Input} />
        </Col>
        <Col lg="6" md="12">
          <Field
            type="text"
            label="Nome"
            labelClassName="required-label"
            name="sacado.nome"
            id="name"
            disabled={
              isSubmitting || (values && values.sacado && values.sacado.eCliente) || bloquearFormulario
            }
            autoComplete="nope"
            component={Input}
          />
        </Col>
        <Col lg="3" md="12">
          <Field
            type="tel"
            label="Telefone"
            labelClassName={isDuplicata ? 'required-label' : ''}
            name="sacado.telefone"
            id="phone"
            disabled={
              isSubmitting || (values && values.sacado && values.sacado.eCliente) || bloquearFormulario
            }
            autoComplete="nope"
            component={PhoneInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Field
            type="text"
            label="Email"
            labelClassName=""
            name="sacado.email"
            id="email"
            disabled={
              isSubmitting || (values && values.sacado && values.sacado.eCliente) || bloquearFormulario
            }
            autoComplete="nope"
            component={Input}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="6">
          <Field
            type="text"
            label="CEP"
            labelClassName={isDuplicata ? 'required-label' : ''}
            name="sacado.endereco.cep"
            id="cep"
            disabled={
              (isSubmitting ||
                (values && values.sacado && values.sacado.eCliente) ||
                bloquearFormulario) &&
              !alteraCampos.includes('libera_endereco')
            }
            autoComplete="nope"
            component={CEPInputWrapper}
          />
        </Col>
        <Col lg="2" md="6">
          <Field
            label="Estado"
            labelClassName={isDuplicata ? 'required-label' : ''}
            name="sacado.endereco.uf"
            id="state"
            disabled={
              (isSubmitting ||
                (values && values.sacado && values.sacado.eCliente) ||
                bloquearFormulario) &&
              !alteraCampos.includes('libera_endereco')
            }
            options={states}
            component={Select}
            autoComplete="nope"
            placeholder=""
          />
        </Col>
        <Col lg="4" md="12">
          <Field
            type="text"
            label="Cidade"
            labelClassName={isDuplicata ? 'required-label' : ''}
            name="sacado.endereco.cidade"
            id="city"
            disabled={
              (isSubmitting ||
                (values && values.sacado && values.sacado.eCliente) ||
                bloquearFormulario) &&
              !alteraCampos.includes('libera_endereco')
            }
            autoComplete="nope"
            component={Input}
          />
        </Col>
        <Col lg="3" md="12">
          <Field
            type="text"
            label="Bairro"
            labelClassName={isDuplicata ? 'required-label' : ''}
            name="sacado.endereco.bairro"
            id="neighborhood"
            disabled={
              (isSubmitting ||
                (values && values.sacado && values.sacado.eCliente) ||
                bloquearFormulario) &&
              !alteraCampos.includes('libera_endereco')
            }
            autoComplete="nope"
            component={Input}
          />
        </Col>
        <Col lg="5" md="12">
          <Field
            type="text"
            label="Logradouro"
            labelClassName={isDuplicata ? 'required-label' : ''}
            name="sacado.endereco.logradouro"
            id="street"
            disabled={
              (isSubmitting ||
                (values && values.sacado && values.sacado.eCliente) ||
                bloquearFormulario) &&
              !alteraCampos.includes('libera_endereco')
            }
            autoComplete="nope"
            component={Input}
          />
        </Col>
        <Col lg="3" md="6">
          <Field
            type="text"
            label="Número"
            labelClassName={isDuplicata ? 'required-label' : ''}
            name="sacado.endereco.numero"
            id="addressNumber"
            disabled={
              (isSubmitting ||
                (values && values.sacado && values.sacado.eCliente) ||
                bloquearFormulario) &&
              !alteraCampos.includes('libera_endereco')
            }
            autoComplete="nope"
            component={Input}
          />
        </Col>
        <Col lg="4" md="6">
          <Field
            type="text"
            label="Complemento"
            name="sacado.endereco.complemento"
            id="complement"
            disabled={
              (isSubmitting ||
                (values && values.sacado && values.sacado.eCliente) ||
                bloquearFormulario) &&
              !alteraCampos.includes('libera_endereco')
            }
            autoComplete="nope"
            component={Input}
          />
        </Col>
      </Row>

      {liberaPreencherCedente &&
        bordero &&
        bordero.tipoTitulo &&
        bordero.tipoTitulo.code &&
        bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA_SERVICO && (
          <>
            <Row className="mt-4">
              <Col md="12">
                <div className="d-flex align-items-center">
                  <h1 className="title">Dados do Cedente:</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="12">
                <FastField
                  type="text"
                  label="CPF/CNPJ"
                  labelClassName="required-label"
                  name="cedente.cpfCnpj"
                  id="cpf/cnpj"
                  disabled={isSubmitting || bloquearFormulario}
                  autoComplete="nope"
                  component={CPFInputWrapper}
                />
                <FastField type="hidden" name="cedente.eCliente" id="eCliente" component={Input} />
              </Col>
              <Col lg="6" md="12">
                <Field
                  type="text"
                  label="Nome"
                  labelClassName="required-label"
                  name="cedente.nome"
                  id="name"
                  disabled={
                    isSubmitting ||
                    (values && values.cedente && values.cedente.eCliente) ||
                    bloquearFormulario
                  }
                  autoComplete="nope"
                  component={Input}
                />
              </Col>
              <Col lg="3" md="12">
                <Field
                  type="tel"
                  label="Telefone"
                  name="cedente.telefone"
                  id="phone"
                  disabled={
                    isSubmitting ||
                    (values && values.cedente && values.cedente.eCliente) ||
                    bloquearFormulario
                  }
                  autoComplete="nope"
                  component={PhoneInput}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  type="text"
                  label="Email"
                  labelClassName=""
                  name="cedente.email"
                  id="email"
                  disabled={
                    isSubmitting ||
                    (values && values.cedente && values.cedente.eCliente) ||
                    bloquearFormulario
                  }
                  autoComplete="nope"
                  component={Input}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="6">
                <Field
                  type="text"
                  label="CEP"
                  name="cedente.endereco.cep"
                  id="cep"
                  disabled={
                    (isSubmitting ||
                      (values && values.cedente && values.cedente.eCliente) ||
                      bloquearFormulario) &&
                    !alteraCampos.includes('libera_endereco')
                  }
                  autoComplete="nope"
                  component={CEPInputWrapper}
                />
              </Col>
              <Col lg="2" md="6">
                <Field
                  label="Estado"
                  name="cedente.endereco.uf"
                  id="state"
                  disabled={
                    (isSubmitting ||
                      (values && values.cedente && values.cedente.eCliente) ||
                      bloquearFormulario) &&
                    !alteraCampos.includes('libera_endereco')
                  }
                  options={states}
                  component={Select}
                  autoComplete="nope"
                  placeholder=""
                />
              </Col>
              <Col lg="4" md="12">
                <Field
                  type="text"
                  label="Cidade"
                  name="cedente.endereco.cidade"
                  id="city"
                  disabled={
                    (isSubmitting ||
                      (values && values.cedente && values.cedente.eCliente) ||
                      bloquearFormulario) &&
                    !alteraCampos.includes('libera_endereco')
                  }
                  autoComplete="nope"
                  component={Input}
                />
              </Col>
              <Col lg="3" md="12">
                <Field
                  type="text"
                  label="Bairro"
                  name="cedente.endereco.bairro"
                  id="neighborhood"
                  disabled={
                    (isSubmitting ||
                      (values && values.cedente && values.cedente.eCliente) ||
                      bloquearFormulario) &&
                    !alteraCampos.includes('libera_endereco')
                  }
                  autoComplete="nope"
                  component={Input}
                />
              </Col>
              <Col lg="5" md="12">
                <Field
                  type="text"
                  label="Logradouro"
                  name="cedente.endereco.logradouro"
                  id="street"
                  disabled={
                    (isSubmitting ||
                      (values && values.cedente && values.cedente.eCliente) ||
                      bloquearFormulario) &&
                    !alteraCampos.includes('libera_endereco')
                  }
                  autoComplete="nope"
                  component={Input}
                />
              </Col>
              <Col lg="3" md="6">
                <Field
                  type="text"
                  label="Número"
                  name="cedente.endereco.numero"
                  id="addressNumber"
                  disabled={
                    (isSubmitting ||
                      (values && values.cedente && values.cedente.eCliente) ||
                      bloquearFormulario) &&
                    !alteraCampos.includes('libera_endereco')
                  }
                  autoComplete="nope"
                  component={Input}
                />
              </Col>
              <Col lg="4" md="6">
                <Field
                  type="text"
                  label="Complemento"
                  name="cedente.endereco.complemento"
                  id="complement"
                  disabled={
                    (isSubmitting ||
                      (values && values.cedente && values.cedente.eCliente) ||
                      bloquearFormulario) &&
                    !alteraCampos.includes('libera_endereco')
                  }
                  autoComplete="nope"
                  component={Input}
                />
              </Col>
            </Row>
          </>
        )}

      <Row>
        <Col md="12">
          <h4 className="title mb-1 mt-4">Dados do Título:</h4>
        </Col>
      </Row>
      <Row>
        {isDuplicata && (
          <>
            {(!liberaPreencherCedente ||
              (liberaPreencherCedente &&
                bordero &&
                bordero.tipoTitulo &&
                bordero.tipoTitulo.code &&
                bordero.tipoTitulo.code !== TIPO_TITULO.DUPLICATA_SERVICO)) && (
              // eslint-disable-next-line react/jsx-indent
              <Col lg="8" md="12">
                <Field
                  type="text"
                  label="Chave DANFE"
                  labelClassName={
                    bordero.tipoTitulo.code === TIPO_TITULO.DUPLICATA &&
                    parseInt(bordero.tipoEmpresa.code) === 6
                      ? 'required-label'
                      : ''
                  }
                  name="duplicata.chaveDanfe"
                  id="danfeKey"
                  disabled={
                    (isSubmitting || bloquearFormulario) && !alteraCampos.includes('libera_danfe')
                  }
                  autoComplete="nope"
                  component={DANFEInputWrapper}
                />
              </Col>
            )}
            <Col lg="4" md="12">
              <Field
                type="text"
                label="Nº da NF"
                name="duplicata.nf"
                id="nfNumber"
                disabled={isSubmitting || bloquearFormulario}
                autoComplete="nope"
                component={NumberInput}
              />
            </Col>
          </>
        )}
        {isCheque && (
          <>
            <Col lg="5" md="12">
              <Field
                type="text"
                label="CMC7"
                labelClassName="required-label"
                name="cheque.cmc7"
                id="cmc7"
                disabled={isSubmitting || bloquearFormulario}
                autoComplete="nope"
                component={CMC7InputWrapper}
              />
            </Col>
            <Col lg="2" md="12">
              <Field
                type="text"
                label="Banco"
                name="cheque.codBanco"
                id="codBanco"
                disabled={
                  isSubmitting ||
                  (values && values.cheque && values.cheque.cmc7 && values.cheque.codBanco) ||
                  bloquearFormulario
                }
                autoComplete="nope"
                component={Input}
              />
            </Col>
            <Col lg="2" md="12">
              <Field
                type="text"
                label="Agência"
                name="cheque.codAgencia"
                id="codAgencia"
                disabled={
                  isSubmitting ||
                  (values && values.cheque && values.cheque.cmc7 && values.cheque.codAgencia) ||
                  bloquearFormulario
                }
                autoComplete="nope"
                component={Input}
              />
            </Col>
            <Col lg="3" md="12">
              <Field
                type="text"
                label="Conta Corrente"
                name="cheque.codcc"
                id="codcc"
                disabled={
                  isSubmitting ||
                  (values && values.cheque && values.cheque.cmc7 && values.cheque.codcc) ||
                  bloquearFormulario
                }
                autoComplete="nope"
                component={Input}
              />
            </Col>
            <Col lg="4" md="12">
              <Field
                type="text"
                label="Compensação"
                name="cheque.compensacao"
                id="compensacao"
                disabled={
                  isSubmitting ||
                  (values && values.cheque && values.cheque.cmc7 && values.cheque.compensacao) ||
                  bloquearFormulario
                }
                autoComplete="nope"
                component={Input}
              />
            </Col>
          </>
        )}
        <Col lg="4" md="12">
          <Field
            type="text"
            label="Nº do Documento"
            labelClassName="required-label"
            name={`${isCheque ? 'cheque' : 'duplicata'}.ndoc`}
            id="documentNumber"
            disabled={
              isSubmitting ||
              bloquearFormulario ||
              (isCheque && values && values.cheque && values.cheque.cmc7 && values.cheque.ndoc) ||
              values.codTipoEntrada === '1' ||
              (bordero &&
                bordero.tipoTitulo &&
                bordero.tipoTitulo.code &&
                bordero.tipoTitulo.code === 'DM' &&
                bordero.tipoEmpresa &&
                bordero.tipoEmpresa.code &&
                bordero.tipoEmpresa.code === '6' &&
                values &&
                values.duplicata &&
                values.duplicata.ndocXml &&
                values.duplicata.ndocXml === 'S')
            }
            helper={
              bordero &&
              bordero.tipoTitulo &&
              bordero.tipoTitulo.code &&
              bordero.tipoTitulo.code === 'DM' &&
              bordero.tipoEmpresa &&
              bordero.tipoEmpresa.code &&
              bordero.tipoEmpresa.code === '6' &&
              values &&
              (values.duplicata === undefined ||
                values.duplicata.ndocXml === undefined ||
                values.duplicata.ndocXml === 'N')
                ? 'Favor fornecer no padrão NF-Parcela'
                : ''
            }
            autoComplete="nope"
            component={Input}
          />
        </Col>
        <Col lg="4" md="12">
          <FastField
            label="Vencimento"
            labelClassName="required-label"
            name={`${isCheque ? 'cheque' : 'duplicata'}.vencimento`}
            id="dueDate"
            disabled={isSubmitting || bloquearFormulario}
            rules={regras}
            component={DateTimePicker || bloquearFormulario}
            autoComplete="nope"
          />
        </Col>
        <Col lg="4" md="12">
          <FastField
            label="Valor Bruto"
            labelClassName="required-label"
            name={`${isCheque ? 'cheque' : 'duplicata'}.valor`}
            id="grossValue"
            disabled={isSubmitting || bloquearFormulario}
            component={CurrencyInput}
            autoComplete="nope"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default memo(InclusaoTituloForm);
