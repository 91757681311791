import React from 'react';
import { Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SelectMainDefault = ({ className = '', children = false, opcoes = [], ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(open ? false : true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  return (
    <Select
      {...props}
      open={open}
      onClick={handleClickOpen}
      onClose={handleClose}
      className={`${className ? className : ''} combobox-principal w-100`}
      IconComponent={props => {
        let classes = props.className;
        if (classes.indexOf('MuiSelect-iconOpen') !== -1) {
          return (
            <KeyboardArrowUpIcon
              className="texto-cor-complementar w-1-5em font-size-2rem cursor-pointer"
              onClick={() => handleClickOpen()}
            />
          );
        } else {
          return (
            <KeyboardArrowDownIcon
              className="texto-cor-complementar w-1-5em font-size-2rem cursor-pointer"
              onClick={() => handleClickOpen()}
            />
          );
        }
      }}
    >
      {opcoes &&
        Object.values(opcoes).length !== 0 &&
        Object.values(opcoes).map((opt, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={`key_${index}`} value={opt.valor}>
              {opt.label}
            </MenuItem>
          );
        })}
      {children && <>{children}</>}
    </Select>
  );
};
export default SelectMainDefault;
