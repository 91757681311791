export default {
  ADMINISTRADOR: 0,
  DIRETOR: 1,
  GERENTE: 2,
  SUPERVISOR: 3,
  FUNCIONARIO: 4,
  GERENTE_NEGOCIOS: 5,
  GERENTE_PLATAFORMA: 6,
  CLIENTE: 7,
  CONTADOR: 8,
  ADVOGADO_EXTERNO: 9,
  BACKOFFICE: 10,
  REPRESENTANTE_UNID_NEGOC: 11
};
