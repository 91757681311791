import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Grid, CardMedia, Typography } from '@mui/material';
import { Undo, Add } from '@mui/icons-material';
import DocumentoUpload from '../Onboarding/shared/DocumentoUpload';
import ButtonMain from '../Onboarding/shared/ButtonMain';
import AlertErroMain from '../Onboarding/shared/AlertErroMain';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import { postEnviarDocumento, postExcluirDocumento } from '../../api/onboarding';
import { getDocumentosReprovadosCliente } from '../../api/clientSearch';
import { setClienteAtual } from '../../shared/services/User';

export default function Detalhes({
  tipo,
  clienteSelecionado = false,
  cnpjEmpresa = false,
  listaDocumentos,
  setListaDocumentos,
  dados,
  fecharDocumentos,
  listaTiposDocumentos,
  carregarAba,
  bloquearTipoDocDuplicado = true,
  arquivosAceitos = ['pdf']
}) {
  const [isPendingIndexArq, setPendingIndexArq] = useState(false);
  const [isPendingDocumento, setPendingDocumento] = useState(false);
  const [newDocOpen, setNewDocOpen] = useState(false);
  const [listaTiposDocInseridos, setListaTiposDocInseridos] = useState([]);
  const [mostraErro, setMostraErro] = useState(false);
  const [cnpjCliente, setCnpjCliente] = useState(false);
  const [arquivoBoxSelected, setArquivoBoxSelected] = useState();

  useEffect(() => {
    setCnpjCliente(
      clienteSelecionado && clienteSelecionado.cnpj ? clienteSelecionado.cnpj : cnpjEmpresa
    );
  }, [clienteSelecionado, cnpjEmpresa]);

  const clickDocumento = (indexArquivo, arquivo) => {
    let arqSelected = { ...arquivo };
    if (arqSelected.novo_doc_lista === undefined || arqSelected.novo_doc_lista.length === 0) {
      arqSelected.indexArquivo = indexArquivo;
      setArquivoBoxSelected(arqSelected);
    }
  };

  const novoDocumento = () => {
    if (newDocOpen === true || listaDocumentos === undefined) return false;
    let listaDocsTemp = [...listaDocumentos];
    let lstTiposDoc = listaTiposDocumentos.filter(tipoDoc =>
      bloquearTipoDocDuplicado
        ? listaTiposDocInseridos.includes(tipoDoc.CODPPCDOCUMENTO) === false
        : true
    );
    listaDocsTemp.unshift({ id: 'new_doc', novo_doc_lista: lstTiposDoc });
    setListaDocumentos(listaDocsTemp);
  };

  const deletarArquivo = (hashArquivo, indexArquivo) => {
    let payload = {
      cnpj: cnpjCliente,
      codigo_arquivo: listaDocumentos[indexArquivo].id
    };
    setPendingIndexArq(indexArquivo);
    postExcluirDocumento(payload)
      .then(retorno => {
        if (retorno.data && retorno.data.dados && retorno.data.dados.processado) {
          setListaDocumentos([]);
          carregarAba();
          recalcularQtdDocsReprovados();
        }
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPendingIndexArq(false);
      });
  };

  const recalcularQtdDocsReprovados = () => {
    if (clienteSelecionado && clienteSelecionado.cnpj) {
      getDocumentosReprovadosCliente(clienteSelecionado.cnpj).then(retorno => {
        let dados = retorno.data;
        if (typeof dados.qtd_documento_reprovado !== 'undefined') {
          setClienteAtual({
            ...clienteSelecionado,
            qtd_documento_reprovado: dados.qtd_documento_reprovado
          });
        }
      });
    }
  };

  const salvarNovoDocumento = (indexArquivo, tipoNovoDoc) => {
    if (tipoNovoDoc && tipoNovoDoc.CODPPCDOCUMENTO && listaDocumentos[indexArquivo].hash_arquivo) {
      let payload = {
        cnpj: cnpjCliente,
        documento: {
          cpf_socio: null,
          cpf_conjugue: null,
          hash: listaDocumentos[indexArquivo].hash_arquivo,
          cod_documento: tipoNovoDoc.CODPPCDOCUMENTO,
          tipo: null
        }
      };
      if (tipo === 'sociedade') {
        payload.documento.cpf_socio = dados.cpfcnpj;
        payload.documento.tipo = dados.tipo.toUpperCase();
        let tiposConjuge = ['socio_conjuge', 'responsavel_solidario_conjuge', 'vinculo_conjuge'];
        if (tiposConjuge.includes(dados.tipo) && dados.conjuge_de && dados.conjuge_de.cpfcnpj) {
          payload.documento.cpf_conjugue = dados.cpfcnpj;
          payload.documento.cpf_socio = dados.conjuge_de.cpfcnpj;
        }
      }
      // ###### ====== FUTURO DESENVOLVIMENTO DE UPLOAD DE DOCUMENTOS DE GARANTIA ====== ######
      // else if (tipo === 'garantia') {
      //   payload.entrada_onboarding = false;
      //   payload.documento.documento_id = tipoNovoDoc.CODPPCDOCUMENTO;
      //   payload.garantias = [
      //     {
      //       garantia_id: dados.garantia_id,
      //       documentos: [{ ...payload.documento }]
      //     }
      //   ];
      //   delete payload.documento;
      // }
      setPendingIndexArq(indexArquivo);
      postEnviarDocumento(payload)
        .then(retorno => {
          if (retorno && retorno.data && retorno.data.dados && retorno.data.dados.processado) {
            setListaDocumentos([]);
            carregarAba();
          }
          if (retorno && retorno.data && retorno.data.erro) {
            setMostraErro(retorno.data.erro);
          }
        })
        .catch(err => {
          setMostraErro(err.response);
        })
        .finally(() => {
          setPendingIndexArq(false);
        });
    }
  };

  useEffect(() => {
    let newDoc = false;
    let lstTiposDocInsTemp = [...listaTiposDocInseridos];
    listaDocumentos.map(doc => {
      if (doc.id === 'new_doc') newDoc = true;
      if (doc.status !== 'reprovado' && doc.id !== 'new_doc') lstTiposDocInsTemp.push(doc.cod_documento);
      return doc;
    });
    setNewDocOpen(newDoc);
    setListaTiposDocInseridos(lstTiposDocInsTemp);
    setArquivoBoxSelected(listaDocumentos[0] ? listaDocumentos[0] : {});
  }, [listaDocumentos]);

  useEffect(() => {
    window.scrollTo(0, 75);
  }, []);

  return (
    <Grid container>
      <Grid
        item
        xl={9}
        lg={9}
        md={9}
        sm={10}
        xs={12}
        sx={{ pr: '30px', minHeight: '70vh' }}
        className="borda-cor-cinza-claro-2 borda-bottom-1"
      >
        <Grid container className="grid-documentos-cliente">
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ pl: '85px' }}
            className="texto-esquerda texto-negrito"
          >
            DOCUMENTO
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className="texto-esquerda texto-negrito">
            ARQUIVO
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={12} xs={12} />
        </Grid>
        {listaDocumentos.length > 0 && cnpjCliente ? (
          <>
            {listaDocumentos.map((doc, index) => {
              var classesAdicionais =
                index + 1 === listaDocumentos.length ? 'borda-transparente' : 'borda-cor-cinza-claro';
              classesAdicionais +=
                typeof arquivoBoxSelected.indexArquivo !== 'undefined' &&
                arquivoBoxSelected.indexArquivo === index &&
                arquivoBoxSelected.id !== 'new_doc'
                  ? ' box-selected fundo-cor-cinza-claro'
                  : '';
              return (
                <Box key={doc.id} sx={isPendingIndexArq === index ? { position: 'relative' } : {}}>
                  {isPendingIndexArq === index && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                  <DocumentoUpload
                    key={doc.id}
                    id={doc.id}
                    boxClassesAdicionais={classesAdicionais}
                    isPending={isPendingDocumento}
                    setPending={setPendingDocumento}
                    listaDocs={listaDocumentos}
                    setListaDocs={setListaDocumentos}
                    arquivosAceitos={arquivosAceitos}
                    tamanhoMaximoMegaAceito={30}
                    boxUploadAction={clickDocumento}
                    tipoComponente="basico"
                    cnpjEmpresa={cnpjCliente}
                    funcaoSalvarNovoDocumento={salvarNovoDocumento}
                    funcaoDeletar={doc.id !== 'new_doc' ? deletarArquivo : ''}
                    permitirDeletar={doc.status === 'reprovado' || doc.id === 'new_doc'}
                  />
                </Box>
              );
            })}
          </>
        ) : (
          <Box className="borda-cor-cinza-claro flex-box-items-centralizados w-100 pad-topbot-20">
            Nenhum Documento incluído
          </Box>
        )}
      </Grid>
      <Grid item xl={3} lg={3} md={3} sm={2} xs={12}>
        <Card
          className="fundo-cor-cinza borda-cor-cinza-claro-2 borda-left-1 borda-bottom-1 border-radius-0 box-shadow-none"
          sx={{ mr: '-30px', mt: '-56px', minHeight: 'calc(100% + 56px)' }}
        >
          <CardContent>
            <Box
              sx={{ p: '40px 0 15px 0', mb: '25px' }}
              className="borda-bottom-1 borda-cor-cinza-claro-2"
            >
              <Typography variant="h6" className="texto-cor-cinza-escuro texto-weight-600">
                Detalhes da Documentação
              </Typography>
            </Box>

            <CardMedia>
              <Box>
                <h2 className="texto-cor-cinza-escuro">Documento</h2>
              </Box>
              <Box>
                <p className="texto-cinza-escuro">
                  {arquivoBoxSelected && arquivoBoxSelected.nome_doc
                    ? arquivoBoxSelected.nome_doc
                    : 'Nenhum Documento Selecionado'}
                </p>
              </Box>
              {dados && dados.nome && (
                <>
                  <Box>
                    <h2 className="texto-cor-cinza-escuro">Nome</h2>
                  </Box>
                  <Box>
                    <p className="texto-cinza-escuro">{dados.nome}</p>
                  </Box>
                </>
              )}
              {dados && dados.tipoSociedadeLabel && (
                <>
                  <Box>
                    <h2 className="texto-cor-cinza-escuro">Tipo Sociedade</h2>
                  </Box>
                  <Box>
                    <p className="texto-cinza-escuro">{dados.tipoSociedadeLabel}</p>
                  </Box>
                </>
              )}
              <Box>
                <h2 className="texto-cor-cinza-escuro">Status</h2>
              </Box>
              <Box>
                <p className="texto-cinza-escuro">
                  {arquivoBoxSelected && arquivoBoxSelected.titulo_status
                    ? arquivoBoxSelected.titulo_status
                    : 'Nenhum Documento Selecionado'}
                </p>
              </Box>
              <Box>
                <h2 className="texto-cor-cinza-escuro">Observação</h2>
              </Box>
              <Box>
                <p className="texto-cinza-escuro">
                  {arquivoBoxSelected &&
                  arquivoBoxSelected.status &&
                  arquivoBoxSelected.status === 'analise' ? (
                    <>
                      Estamos analisando o documento enviado.
                      <br />
                      <br />
                      Em caso de dúvidas fale com a gente via chat ou ligue para (15) 3042-0017
                    </>
                  ) : (
                    <>
                      {arquivoBoxSelected &&
                      arquivoBoxSelected.pareceres &&
                      arquivoBoxSelected.pareceres.length > 0 ? (
                        <>
                          {arquivoBoxSelected.pareceres.map((item, index) => {
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <span key={`parecer${index}`}>
                                {item.retorno_parecer}
                                <br />
                                <br />
                              </span>
                            );
                          })}
                        </>
                      ) : (
                        '---'
                      )}
                    </>
                  )}
                </p>
              </Box>
            </CardMedia>
          </CardContent>
        </Card>
      </Grid>
      {mostraErro !== false && (
        <Grid item lg={12}>
          <AlertErroMain autoFocus exibeErro={mostraErro} escondeErro={setMostraErro} />
        </Grid>
      )}
      <Grid container item lg={12}>
        <Grid item xl={3} lg={3} md={3} sm={4} xs={5}>
          <ButtonMain
            tipoBotao="transparente-com-bordas-complementar"
            className="margin-top-label"
            disabled={isPendingDocumento}
            onClick={() => fecharDocumentos()}
          >
            <Undo />
            Voltar
          </ButtonMain>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={4} xs={2} />
        <Grid item xl={3} lg={3} md={3} sm={4} xs={5}>
          <ButtonMain
            tipoBotao="principal-texto-branco"
            className="margin-top-label"
            disabled={
              isPendingDocumento || (listaTiposDocumentos && listaTiposDocumentos.length > 0)
                ? false
                : true
            }
            onClick={() => novoDocumento()}
          >
            <Add />
            Novo Documento
          </ButtonMain>
        </Grid>
      </Grid>
    </Grid>
  );
}
