import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { ArrowBack, Visibility, CheckCircle } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Typography
} from '@mui/material';

import * as CPF from '@fnando/cpf';
import strip from '../../utils/strip';
import MainOnlyHeader from '../Main/MainOnlyHeader';
import ButtonMain from '../Onboarding/shared/ButtonMain';
import InputMain from '../Onboarding/shared/InputMain/InputMain';
import { validaForcaSenha } from '../Onboarding/shared/Utils';
import { resetPassword } from '../../api/authentication';
import EyeClose from '../../assets/fonts/eye-close.svg';
import AlertErroMain from '../Onboarding/shared/AlertErroMain';

const ResetPassword = props => {
  const history = useHistory();
  const { match: { params: { token } = {} } = {} } = props;

  const [isPending, setIsPending] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [message, setMessage] = useState('');
  const [valueCPF, setValueCPF] = useState('');
  const [mostraErro, setMostraErro] = useState(false);

  const resetPasswordHandler = (values, form) => {
    if (senhaIguais) {
      form.setSubmitting(false);
      return false;
    }

    if (progress < 50) {
      setMessage('Força de senha muito fraca');
      form.setSubmitting(false);
      return false;
    }

    setIsPending(true);
    const entity = {
      documento: strip(values.documento),
      senha: values.password,
      senha_confirmacao: values.password_confirmacao
    };

    resetPassword(entity, token)
      .then(() => {
        setMessage('');
        setPasswordReset(true);
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setIsPending(false);
        form.setSubmitting(false);
      });
  };

  const limpaMsgErro = () => {
    setMessage(false);
  };

  const [valuesPassword, setValuesPassword] = React.useState({
    password: '',
    passwordConfirmation: '',
    showPassword: false,
    showPasswordConfirmation: false
  });

  const [progress, setProgress] = React.useState(0);
  const [senhaIguais, setSenhaIguais] = React.useState(false);
  const [textoForcaSenha, setTextoForcaSenha] = React.useState({
    msgForcaSenha: '',
    corForcaSenha: '',
    corProgress: ''
  });

  const handleChangePassword = prop => event => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
    limpaMsgErro();
    if (prop === 'password') {
      verificaValidaSenha(event.target.value);
      validaSenhasIguais(valuesPassword.passwordConfirmation, event.target.value);
    } else {
      validaSenhasIguais(event.target.value, valuesPassword.password);
    }
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword
    });
  };

  const handleClickShowPasswordConfirmation = () => {
    setValuesPassword({
      ...valuesPassword,
      showPasswordConfirmation: !valuesPassword.showPasswordConfirmation
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirmation = event => {
    event.preventDefault();
  };

  const verificaValidaSenha = password => {
    let forcaSenha = validaForcaSenha(password);
    let textoSenha = '';
    let corSenha = '';
    let corBarra = '';

    if (forcaSenha === 100) {
      textoSenha = 'Forte';
      corSenha = 'texto-success texto-semi-bold';
      corBarra = 'success';
    } else if (forcaSenha === 50) {
      textoSenha = 'Mediana';
      corBarra = 'warning';
      corSenha = 'texto-warning texto-semi-bold';
    } else if (forcaSenha > 0 && forcaSenha < 50) {
      textoSenha = 'Fraca';
      corBarra = 'error';
      corSenha = 'texto-danger texto-semi-bold';
    }

    if (validaSenhaDemaisCampos(password, valueCPF) === false) {
      textoSenha = 'Fraca';
      corBarra = 'error';
      corSenha = 'texto-danger texto-semi-bold';
      forcaSenha = 5;
    }

    setTextoForcaSenha({
      msgForcaSenha: textoSenha,
      corForcaSenha: corSenha,
      corProgress: corBarra
    });

    setProgress(() => {
      return forcaSenha;
    });
  };

  const validaSenhaDemaisCampos = (senha, cpf) => {
    if (senha === '') return false;
    if (strip(senha) === strip(cpf) || senha === cpf || senha === strip(cpf)) {
      return false;
    }
    return true;
  };

  const handleOnChangeCPF = event => {
    setValueCPF(event.target.value);
  };

  const validaSenhasIguais = (passwor_confimacao, password) => {
    let condicao = passwor_confimacao !== '' && passwor_confimacao !== password ? true : false;

    setSenhaIguais(() => {
      return condicao;
    });
  };

  const acessaLogin = () => {
    history.push('/authentication/login');
  };

  return (
    <MainOnlyHeader>
      <Container fixed>
        <Grid container>
          <Grid item xl={2} lg={2} md={2} sm={1} xs={12} />
          <Grid item xl={8} lg={8} md={8} sm={10} xs={12}>
            <Card variant="outlined" className="box-shadow-natural-5 border-radius-8">
              <CardContent>
                <Grid container>
                  <Grid item xl={2} lg={3} md={4} sm={4} xs={4}>
                    <Link
                      to="/authentication/login"
                      className="texto-cor-cinza-escuro height-fit-content"
                    >
                      <ArrowBack />
                      &nbsp; Voltar
                    </Link>
                  </Grid>
                  <Grid item xl={10} lg={9} md={8} sm={8} xs={8} />
                  <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
                  <Grid item xl={8} lg={8} md={8} sm={10} xs={10} sx={{ p: '50px 0 50px 0' }}>
                    {passwordReset === false ? (
                      <>
                        <Typography
                          variant="h5"
                          className="texto-cor-principal texto-negrito texto-centro"
                          sx={{ margin: '0 0 20px 0' }}
                        >
                          Cadastro de senha
                        </Typography>
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="subtitle1"
                              className="texto-cor-cinza-escuro pad-10 texto-centro label-senha-reset-password"
                            >
                              A senha precisa ter pelo menos 8 caracteres, sendo 1 letra maiúscula, 1
                              letra minúscula e 1 número. Não pode ser igual ao CPF, E-Mail, Telefone ou
                              Data de Nascimento
                            </Typography>
                          </Grid>
                        </Grid>
                        <Formik
                          initialValues={{
                            password: '',
                            password_confirmacao: ''
                          }}
                          validateOnChange={false}
                          validateOnBlur={false}
                          validationSchema={Yup.object().shape({
                            documento: Yup.string()
                              .required('Informe o CPF')
                              .nullable()
                              .test({
                                exclusive: true,
                                message: params => {
                                  const strippedValue = strip(params.value);
                                  if (strippedValue.length === 11 && CPF.isValid(strippedValue)) {
                                    return 'CPF válido';
                                  }
                                  return 'CPF inválido';
                                },
                                name: 'documento',
                                test: value => CPF.isValid(value)
                              }),
                            password: Yup.string()
                              .required('Informe a senha')
                              .nullable()
                              .test({
                                exclusive: true,
                                message: 'Senha muito fraca',
                                name: 'outlined-adornment-password',
                                test: () => {
                                  let forcaSenha = validaForcaSenha(valuesPassword.password);
                                  if (forcaSenha >= 50) {
                                    return true;
                                  }
                                  return false;
                                }
                              })
                              .test({
                                exclusive: true,
                                message: 'Senha não pode ser igual ao CPF',
                                name: 'outlined-adornment-password',
                                test: () => {
                                  return validaSenhaDemaisCampos(valuesPassword.password, valueCPF);
                                }
                              }),
                            password_confirmacao: Yup.string()
                              .required('Informe a senha de confirmação')
                              .nullable()
                              .test({
                                exclusive: true,
                                message: 'As senhas precisam ser iguais',
                                name: 'outlined-adornment-password',
                                test: () => {
                                  if (!senhaIguais) {
                                    return true;
                                  }
                                  return true;
                                }
                              })
                          })}
                          onSubmit={resetPasswordHandler}
                          render={({ isSubmitting }) => {
                            return (
                              <>
                                <Form>
                                  <Grid container>
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={12} />
                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                                      <Field
                                        type="text"
                                        name="documento"
                                        titulo="CPF"
                                        id="documento"
                                        size="small"
                                        className="w-100"
                                        placeholder="000.000.000-00"
                                        uppercase={true}
                                        component={InputMain}
                                        onChangeInput={handleOnChangeCPF}
                                        autoComplete="off"
                                        tipoMascara="CPFMask"
                                        disabled={isPending || isSubmitting}
                                      />

                                      <Field
                                        name="password"
                                        id="password"
                                        titulo="Senha"
                                        component={InputMain}
                                        tipoComponente="outlineInput"
                                        type={valuesPassword.showPassword ? 'text' : 'password'}
                                        value={valuesPassword.password}
                                        onChangeInput={handleChangePassword('password')}
                                        size="small"
                                        autoComplete="new-password"
                                        className="w-100"
                                        endAdornment={
                                          // eslint-disable-next-line react/jsx-wrap-multilines
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {valuesPassword.showPassword ? (
                                                <Visibility />
                                              ) : (
                                                <img src={EyeClose} alt="icone olho fechado" />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />

                                      <Typography
                                        variant="subtitle1"
                                        className={textoForcaSenha.corForcaSenha}
                                      >
                                        {textoForcaSenha.msgForcaSenha
                                          ? textoForcaSenha.msgForcaSenha
                                          : ''}
                                      </Typography>

                                      {textoForcaSenha.msgForcaSenha &&
                                        textoForcaSenha.msgForcaSenha !== '' && (
                                          <Box>
                                            <LinearProgress
                                              variant="determinate"
                                              value={progress}
                                              color={textoForcaSenha.corProgress}
                                            />
                                          </Box>
                                        )}

                                      <Field
                                        name="password_confirmacao"
                                        id="password_confirmacao"
                                        titulo="Confirmar senha"
                                        component={InputMain}
                                        tipoComponente="outlineInput"
                                        type={
                                          valuesPassword.showPasswordConfirmation ? 'text' : 'password'
                                        }
                                        value={valuesPassword.passwordConfirmation}
                                        onChangeInput={handleChangePassword('passwordConfirmation')}
                                        size="small"
                                        error={senhaIguais}
                                        autoComplete="new-password"
                                        inputClassName="w-100"
                                        endAdornment={
                                          // eslint-disable-next-line react/jsx-wrap-multilines
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={handleClickShowPasswordConfirmation}
                                              onMouseDown={handleMouseDownPasswordConfirmation}
                                              edge="end"
                                            >
                                              {valuesPassword.showPasswordConfirmation ? (
                                                <Visibility />
                                              ) : (
                                                <img src={EyeClose} alt="icone olho fechado" />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                      />

                                      <Typography
                                        variant="subtitle1"
                                        className="texto-danger texto-semi-bold"
                                      >
                                        {senhaIguais ? 'As senhas devem ser iguais' : ''}
                                      </Typography>

                                      {message && (
                                        <Typography
                                          variant="subtitle1"
                                          className="mt-2 texto-danger pad-10 texto-centro"
                                        >
                                          <b>{message}</b>
                                        </Typography>
                                      )}

                                      <AlertErroMain
                                        exibeErro={mostraErro}
                                        escondeErro={setMostraErro}
                                        msgErro={typeof mostraErro === 'string' ? mostraErro : ''}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={2} className="mt-3">
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={12} />
                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                                      <ButtonMain
                                        type="submit"
                                        tipoBotao="principal"
                                        disabled={isPending || isSubmitting || senhaIguais}
                                      >
                                        Cadastrar nova senha
                                      </ButtonMain>
                                    </Grid>
                                  </Grid>
                                </Form>
                              </>
                            );
                          }}
                        />
                      </>
                    ) : (
                      <Grid container className="pad-topbot-20">
                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} />
                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                          <div className="texto-centro">
                            <CheckCircle className="font-size-45 texto-info" sx={{ color: '#54A900' }} />
                          </div>

                          <Typography
                            variant="h5"
                            className="mt-3 texto-cor-principal texto-negrito texto-centro"
                          >
                            Senha alterada
                          </Typography>

                          <Typography
                            variant="subtitle1"
                            className="texto-cor-cinza-escuro pad-10 texto-centro"
                          >
                            Utilize o e-mail e a nova senha cadastrada para acessar sua conta.
                          </Typography>

                          <Grid container className="mt-4">
                            <Grid item xl={3} lg={3} md={3} sm={3} xs={12} />
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                              <center>
                                <ButtonMain tipoBotao="principal" onClick={acessaLogin}>
                                  Acessar
                                </ButtonMain>
                              </center>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MainOnlyHeader>
  );
};

export default ResetPassword;
