import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import Empresa from './Empresa/Empresa';
import Sociedade from './Sociedade/Sociedade';

export default ({
  avancarEtapa,
  isPending,
  setPending,
  cnpjEmpresa,
  tipoAbaAtiva,
  codEntrada = null,
  origemProposta = 'digital'
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [tipoAbaAtiva]);

  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container>
      <Grid item xl={2} lg={2} md={1} sm={1} />
      <Grid item xl={8} lg={8} md={10} sm={10} xs={12}>
        <Typography variant="h5" className="mt-2 texto-cor-principal texto-negrito texto-centro">
          Envio de documentos
        </Typography>

        <Grid container>
          <Grid item xl={1} lg={1} md={1} sm={1} />
          <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
            <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
              <span>Precisamos que você nos envie alguns documentos </span>
              {tipoAbaAtiva === 'Sociedade'
                ? 'dos(as) sócios(as) e/ou responsável solidário(a)'
                : ' da empresa'}
              <span>
                . O arquivo deve ser no formato
                {origemProposta === 'one7_mais_credito' ? ' PDF, JPG ou PNG com ' : ' PDF '}
                até 30MB
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Grid container className="mt-5">
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <div className={tipoAbaAtiva === 'Empresa' ? 'abaDocumentosAtiva' : 'abaDocumentosNaoAtiva'}>
              {onlySmallScreen ? 'Empresa' : 'Documentos da empresa'}
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <div
              className={tipoAbaAtiva === 'Sociedade' ? 'abaDocumentosAtiva' : 'abaDocumentosNaoAtiva'}
            >
              {onlySmallScreen ? 'Sociedade' : 'Documentos da sociedade'}
            </div>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {tipoAbaAtiva === 'Empresa' ? (
              <Empresa
                avancarEtapa={avancarEtapa}
                isPending={isPending}
                setPending={setPending}
                cnpjEmpresa={cnpjEmpresa}
                codEntrada={codEntrada}
                origemProposta={origemProposta}
              />
            ) : (
              <Sociedade
                avancarEtapa={avancarEtapa}
                isPending={isPending}
                setPending={setPending}
                cnpjEmpresa={cnpjEmpresa}
                codEntrada={codEntrada}
                origemProposta={origemProposta}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
