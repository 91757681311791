import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

export default ({ title }) => (
  <Breadcrumb>
    <BreadcrumbItem>
      <Link to="/">Home</Link>
    </BreadcrumbItem>
    <BreadcrumbItem active={true}>{title}</BreadcrumbItem>
  </Breadcrumb>
);
