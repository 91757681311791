import React, { useState, useEffect } from 'react';
import { Smartphone, MailOutline, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Typography, Grid, Snackbar, Alert } from '@mui/material';
import {
  getClienteAtual,
  setClienteAtual,
  getClientes,
  setClientes,
  mascararTelefone
} from '../../../shared/services/User';
import LoaderMain from '../../Onboarding/shared/LoaderMain';
import { getClienteBrokerDados, getPropostaBrokerDados } from '../../../api/clientes';

const BrokerCliente = ({ dadosBrokerValidado, dadosPlataformaValidada, buscarDadosCliente = true }) => {
  const [menuGerente, setMenuGerente] = useState(false);
  const [dadosBroker, setDadosBroker] = useState(false);
  const [dadosPlataforma, setDadosPlataforma] = useState(false);
  const [isPending, setPending] = useState(false);
  const [msgAlerta, setMsgAlerta] = useState(false);
  var dadosCliente = getClienteAtual();
  var lstClientes = getClientes();

  useEffect(() => {
    if (!buscarDadosCliente) return false;
    if (!menuGerente) return false;
    if (dadosCliente && !dadosCliente.dadosBrokerCliente) {
      buscarDadosBroker();
    } else {
      setDadosBroker(dadosCliente.dadosBrokerCliente);
    }
  }, [menuGerente]);

  useEffect(() => {
    if (dadosBroker) return false;
    if (dadosBrokerValidado && dadosBrokerValidado.NOMEBROKER) {
      setDadosBroker(dadosBrokerValidado);
      setMenuGerente(true);
    }
  }, [dadosBrokerValidado]);

  useEffect(() => {
    if (dadosPlataforma) return false;
    if (dadosPlataformaValidada && dadosPlataformaValidada.nomePlataforma) {
      setDadosPlataforma({
        nomePlataforma: dadosPlataformaValidada.nomePlataforma,
        iniciais_plataforma: dadosPlataformaValidada.iniciais_plataforma,
        nomeUnidadeNegocio: dadosPlataformaValidada.nomeUnidadeNegocio
          ? dadosPlataformaValidada.nomeUnidadeNegocio
          : false,
        tipoOnboarding: dadosPlataformaValidada.tipoOnboarding
      });
      setMenuGerente(false);
    }
  }, [dadosPlataformaValidada]);

  const buscarDadosBroker = () => {
    if (dadosCliente.codCliente || dadosCliente.codPPC) {
      setPending(true);
      const getBrokerDados = dadosCliente.codCliente ? getClienteBrokerDados : getPropostaBrokerDados;
      getBrokerDados(dadosCliente.codCliente ? dadosCliente.codCliente : dadosCliente.codPPC)
        .then(retorno => {
          if (retorno.status === 200 && retorno && retorno.data && retorno.data.NOMEBROKER) {
            dadosCliente.dadosBrokerCliente = retorno.data;
            lstClientes = lstClientes.map(cli => {
              if (dadosCliente.codCliente) {
                if (cli.codCliente === dadosCliente.codCliente) cli.dadosBrokerCliente = retorno.data;
              } else if (dadosCliente.codPPC) {
                if (cli.codPPC === dadosCliente.codPPC) cli.dadosBrokerCliente = retorno.data;
              }
              return cli;
            });
            setDadosBroker(retorno.data);
            setClienteAtual(dadosCliente);
            setClientes(lstClientes);
          } else {
            setMsgAlerta('Nenhum gerente encontrado para este cliente!');
            setMenuGerente(false);
          }
        })
        .catch(() => {
          setMenuGerente(false);
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  return (
    <>
      {((dadosCliente &&
        ((dadosCliente.codCliente && dadosCliente.codCliente !== 'todos') || dadosCliente.codPPC)) ||
        buscarDadosCliente === false) && (
        <div
          className={`box-flutuante-gerente-usuario box-shadow-escura-1 
          fundo-cor-cinza-claro borda-cor-cinza-claro-2 borda-2 ${
            menuGerente ? 'box-open' : 'box-closed'
          }`}
          role="button"
          tabIndex="0"
          onBlur={e => {
            if (!e.currentTarget.contains(e.relatedTarget)) setMenuGerente(false);
          }}
        >
          <Box
            className="titulo-box-gerente-usuario cursor-pointer"
            onClick={() => setMenuGerente(!menuGerente)}
          >
            {dadosPlataforma ? 'MENU PLATAF.' : 'MENU GERENTE'}
            {menuGerente ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </Box>
          <Box className="pad-20 box-gerente-usuario">
            {isPending && (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <LoaderMain tipoLoader="apenas_loader" />
              </Box>
            )}
            <Grid container sx={isPending ? { display: 'none' } : {}}>
              <Grid item xl={9} lg={9} md={9} sm={8} xs={8}>
                <Typography
                  variant="h6"
                  className="texto-cor-principal texto-negrito texto-nowrap-overflow-dotted"
                >
                  {dadosPlataforma ? dadosPlataforma.nomePlataforma : dadosBroker.NOMEBROKER}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="texto-cor-principal texto-nowrap-overflow-dotted"
                >
                  {dadosPlataforma ? 'Plataforma' : 'Gerente de Negócios'}
                </Typography>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={4} className="flex-box-items-centralizados">
                <Box className="box-circulo-60 fundo-cor-branca texto-semi-negrito texto-cor-complementar texto-nowrap-overflow-dotted">
                  {dadosPlataforma
                    ? dadosPlataforma.iniciais_plataforma
                    : dadosBroker.INICIAIS_NOMEBROKER}
                </Box>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="border-gradient-principal borda-bottom-2 pad-5 margin-bottom-10"
              />
              {(dadosBroker.TELEFONE || dadosBroker.EMAIL) && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-bot-5">
                  <Typography variant="subtitle1" className="texto-cor-principal texto-negrito">
                    Contato
                  </Typography>
                </Grid>
              )}
              {dadosBroker.TELEFONE && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className="texto-cor-principal pad-bot-5">
                    <Smartphone className="texto-cor-complementar font-size-1-6rem margin-right-5" />
                    {mascararTelefone(dadosBroker.TELEFONE)}
                  </Typography>
                </Grid>
              )}
              {dadosBroker.EMAIL && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="subtitle1" className="texto-cor-principal">
                    <MailOutline className="texto-cor-complementar font-size-1-6rem margin-right-5" />
                    {dadosBroker.EMAIL}
                  </Typography>
                </Grid>
              )}
              {dadosPlataforma.nomeUnidadeNegocio && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-bot-5">
                  <Typography
                    variant="h6"
                    className="texto-cor-principal texto-negrito texto-nowrap-overflow-dotted"
                  >
                    {dadosPlataforma.nomeUnidadeNegocio}
                  </Typography>
                  <Typography variant="subtitle1" className="texto-cor-principal">
                    Unidade Negócio
                  </Typography>
                </Grid>
              )}
              {dadosPlataforma.tipoOnboarding && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pad-bot-5">
                  <Typography variant="subtitle1" className="texto-cor-principal text-uppercase">
                    {dadosPlataforma.tipoOnboarding}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </div>
      )}
      <Snackbar
        open={msgAlerta ? true : false}
        autoHideDuration={3000}
        onClose={reason => {
          if (reason === 'clickaway') return false;
          setMsgAlerta(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={reason => {
            if (reason === 'clickaway') return false;
            setMsgAlerta(false);
          }}
          severity="error"
          sx={{ width: '100%' }}
        >
          {msgAlerta}
        </Alert>
      </Snackbar>
    </>
  );
};
export default BrokerCliente;
