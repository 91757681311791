import { useEffect } from 'react';
import { salvarAcesso } from '../../api/usuario';
import { getDadosUsuario } from '../../shared';
import { MODULOS } from '../Onboarding/shared/Enums/EnumsOnboarding';

function HistoricoAcesso({ codTela }) {
  const dadosUsuarios = getDadosUsuario();
  useEffect(() => {
    salvarAcesso({
      codModulo: MODULOS && MODULOS.PLATAFORMACLIENTE ? MODULOS.PLATAFORMACLIENTE : 0,
      codTela: codTela ? codTela : 0,
      codusuario: dadosUsuarios && dadosUsuarios.codusuario ? dadosUsuarios.codusuario : 0,
      nomeUsuario: dadosUsuarios && dadosUsuarios.nome ? dadosUsuarios.nome : ''
    });
  }, [codTela]);
  return null;
}

export default HistoricoAcesso;
