import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Box,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid
} from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom';
import Main from '../Main/Main';
import Solucoes from '../Onboarding/Jornada/Solucoes';
import Garantia from '../Onboarding/Jornada/Garantia/Documentos';
import { getClienteAtual } from '../../shared';
import EnvioDocumentos from '../Onboarding/Jornada/EnvioDocumentos';
import { getEstadoOne7MaisCredito } from '../../api/one7MaisCredito';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import ProdutoNaoDisponivel from './ProdutoNaoDisponivel';
import ButtonMain from '../Onboarding/shared/ButtonMain';
import { deleteBorderoByCode } from '../../api/borderos';

export default function NovaEntrada(props) {
  const history = useHistory();
  const [avancarEtapa, setAvancarEtapa] = useState(false);
  const [cnpjEmpresa, setCnpjEmpresa] = useState(false);
  const [isPending, setPending] = useState(true);
  const [codContacorrente, setCodContacorrente] = useState(false);
  const [codEntrada, setCodEntrada] = useState(null);
  const [tipoAbaAtivaDocumentos, setTipoAbaAtivaDocumentos] = useState('');
  const [msgNaoDisponivel, setMsgNaoDisponivel] = useState('');
  const [novaEntrada, setNovaEntrada] = useState(false);
  const [modalContinuarDeletar, setModalContinuarDeletar] = useState(false);
  const [continuarSigla, setContinuarSigla] = useState(false);

  useEffect(() => {
    let dados = props;
    if (dados.location && dados.location.state && dados.location.state.codEntrada) {
      setCodEntrada(dados.location.state.codEntrada);
    }
    if (dados.location && dados.location.state && dados.location.state.novaEntrada) {
      setNovaEntrada(dados.location.state.novaEntrada);
    }
    if (dados.location && dados.location.state && dados.location.state.codContacorrente) {
      setCodContacorrente(dados.location.state.codContacorrente);
    } else {
      history.push('/borderos');
    }

    let objCliente = getClienteAtual();
    if (objCliente && objCliente.cnpj) {
      setCnpjEmpresa(objCliente.cnpj);
    }
  }, []);

  useEffect(() => {
    buscaEstadoOne7MaisCredito();
  }, [cnpjEmpresa]);

  const buscaEstadoOne7MaisCredito = () => {
    if (!cnpjEmpresa) return;

    setPending(true);
    getEstadoOne7MaisCredito(cnpjEmpresa, codEntrada)
      .then(retorno => {
        if (Object.keys(retorno.data).length > 0) {
          if (retorno.data.BLOQUEADO === 'S') {
            setAvancarEtapa('ProdutoNaoDisponivel');
            setMsgNaoDisponivel('BLOQUEADO');
          } else if (retorno.data.PROPOSTAVENCIDA) {
            setAvancarEtapa('ProdutoNaoDisponivel');
            setMsgNaoDisponivel('VENCIDO');
          } else if (
            retorno.data.STATUSANALISE &&
            retorno.data.STATUSANALISE !== null &&
            retorno.data.STATUSANALISE !== 'I'
          ) {
            setAvancarEtapa('ProdutoNaoDisponivel');
            setMsgNaoDisponivel('ANALISE');
          } else if (novaEntrada && retorno.data.CODENTRADA && retorno.data.SIGLA !== 'APV') {
            setNovaEntrada(false);
            setCodEntrada(retorno.data.CODENTRADA);
            setContinuarSigla(retorno.data.SIGLA);

            if (retorno.data.STATUSANALISE === 'I') {
              setAvancarEtapa('Solucoes');
              setCodEntrada(null);
            } else {
              setModalContinuarDeletar(true);
            }
          } else {
            setNovaEntrada(false);
            realizavaTratativaEstadoJornada(retorno.data.SIGLA);
            if (retorno.data.CODENTRADA && retorno.data.SIGLA !== 'APV') {
              setCodEntrada(retorno.data.CODENTRADA);
            }
          }
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const realizavaTratativaEstadoJornada = estado => {
    if (estado === 'APV') {
      setAvancarEtapa('Solucoes');
      setCodEntrada(null);
    } else if (estado === 'ACG') {
      setAvancarEtapa('Garantia');
    } else if (estado === 'ACD') {
      setAvancarEtapa('EnvioDocumentos');
      setTipoAbaAtivaDocumentos('Empresa');
    } else if (estado === 'DCS') {
      setAvancarEtapa('EnvioDocumentos');
      setTipoAbaAtivaDocumentos('Sociedade');
    } else {
      setAvancarEtapa('FinalizarJornadaOne7MaisCredito');
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setModalContinuarDeletar(false);
  };

  const confirmarNovaEntrada = () => {
    // DELETA A ENTRADA E SEUS VINCULOS
    setModalContinuarDeletar(false);
    setPending(true);
    deleteBorderoByCode(codEntrada)
      .then(() => {
        setAvancarEtapa('Solucoes');
        setCodEntrada(null);
      })
      .catch(() => {
        history.push('/borderos');
      })
      .finally(() => {
        setPending(false);
      });
  };

  const continuarEntrada = () => {
    setModalContinuarDeletar(false);
    setPending(true);
    setTimeout(() => {
      setPending(false);
      realizavaTratativaEstadoJornada(continuarSigla);
    }, 500);
  };

  return (
    <Main {...props}>
      <Card
        sx={
          !avancarEtapa
            ? {
                minHeight: '750px'
              }
            : {}
        }
      >
        <CardContent sx={{ margin: '25px 20px 25px 20px' }}>
          <Box
            sx={
              isPending
                ? {
                    position: 'relative'
                  }
                : {}
            }
          >
            {isPending ? (
              <LoaderMain />
            ) : (
              <>
                {avancarEtapa === 'Solucoes' && (
                  <Solucoes
                    avancarEtapa={buscaEstadoOne7MaisCredito}
                    isPending={isPending}
                    setPending={setPending}
                    cnpjEmpresa={cnpjEmpresa}
                    entradaOnboarding={false}
                    codContacorrente={codContacorrente}
                    origemProposta="one7_mais_credito"
                  />
                )}

                {avancarEtapa === 'Garantia' && (
                  <Garantia
                    avancarEtapa={buscaEstadoOne7MaisCredito}
                    isPending={isPending}
                    setPending={setPending}
                    cnpjEmpresa={cnpjEmpresa}
                    codEntrada={codEntrada}
                    origemProposta="one7_mais_credito"
                  />
                )}

                {avancarEtapa === 'EnvioDocumentos' && (
                  <EnvioDocumentos
                    avancarEtapa={buscaEstadoOne7MaisCredito}
                    isPending={isPending}
                    setPending={setPending}
                    cnpjEmpresa={cnpjEmpresa}
                    tipoAbaAtiva={tipoAbaAtivaDocumentos}
                    codEntrada={codEntrada}
                    origemProposta="one7_mais_credito"
                  />
                )}

                {avancarEtapa === 'ProdutoNaoDisponivel' && (
                  <ProdutoNaoDisponivel msgNaoDisponivel={msgNaoDisponivel} />
                )}

                {avancarEtapa === 'FinalizarJornadaOne7MaisCredito' && (
                  <Redirect
                    to={{
                      pathname: '/borderos'
                    }}
                  />
                )}

                <Dialog
                  open={modalContinuarDeletar !== false}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
                    Atenção
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Indentificamos uma entrada de giro parcelado em andamento. Você pode continuar ou
                      iniciar uma nova entrada.
                      <br />
                      <br />
                      <b>Importante: </b>
                      <br />
                      Ao iniciar uma nova entrada, a anterior será excluída.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Grid container className="texto-centro mb-2">
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <ButtonMain
                          tipoBotao="azul-escuro"
                          sx={{ p: '6px 65px', width: '90% !important' }}
                          onClick={() => continuarEntrada()}
                        >
                          Continuar
                        </ButtonMain>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <ButtonMain
                          tipoBotao="principal"
                          sx={{ p: '6px 65px', width: '90% !important' }}
                          onClick={() => {
                            confirmarNovaEntrada();
                          }}
                        >
                          Nova entrada
                        </ButtonMain>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Main>
  );
}
