const stepDefinition = [
  {
    number: 1,
    title: 'Definição'
  },
  {
    number: 2,
    title: 'Seleção'
  },
  {
    number: 3,
    title: 'Dados'
  },
  {
    number: 4,
    title: 'Confirmação'
  }
];

export default stepDefinition;
