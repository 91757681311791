import API from './api';

const getContasCorrentes = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/clientes/contas-correntes`);

const postContasCorrentes = contaCorrente =>
  API.post(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/clientes/contas-correntes`,
    contaCorrente
  );

const getBancos = () => API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/bancos`);

const getAgencias = codigoBanco => {
  return API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/agencias?cod_banco=${codigoBanco}`
  );
};

export { getContasCorrentes, postContasCorrentes, getBancos, getAgencias };
