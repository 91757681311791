import React from 'react';
import { Alert, AlertTitle, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AlertErroMain = ({
  exibeErro = false,
  escondeErro,
  titleErro = '',
  msgErro = '',
  tipoAlerta = 'error',
  classes = ''
}) => {
  const [lstMsgErro, setLstMsgErro] = React.useState([]);

  React.useEffect(() => {
    let arrayErro = [];
    if (exibeErro && exibeErro.data && exibeErro && exibeErro.data.errors) {
      let erros = Object.values(exibeErro && exibeErro.data.errors);
      erros.map(ers => {
        ers.map(er => {
          arrayErro.push(er);
          return er;
        });
        return ers;
      });
      setLstMsgErro(arrayErro ? arrayErro : []);
    } else if (typeof exibeErro === 'string') {
      setLstMsgErro([exibeErro]);
    }
  }, [exibeErro]);

  return (
    <Collapse in={exibeErro !== false}>
      <Alert
        severity={tipoAlerta}
        action={
          exibeErro && (
            <IconButton
              size="small"
              onClick={() => {
                escondeErro(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          )
        }
        sx={{ marginTop: '20px' }}
        className={classes}
      >
        <AlertTitle>
          <b>
            {titleErro
              ? titleErro
              : lstMsgErro.length > 0
              ? 'Dados informados inválidos'
              : 'Ocorreu um erro'}
          </b>
        </AlertTitle>

        {msgErro ? (
          msgErro
        ) : lstMsgErro.length > 0 ? (
          <>
            {lstMsgErro.map(er => (
              <span key={`erro_${er}`}>
                <>{er}</>
                <br />
              </span>
            ))}
          </>
        ) : (
          <span>
            Estamos trabalhando para corrigir o problema no nosso sistema.
            <br />
            Tente novamente mais tarde ou
            <a
              href="mailto:suporte@one7.com.vc"
              className="link-email-suporte"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp;fale com a gente&nbsp;
            </a>
            para obter ajuda.
          </span>
        )}
      </Alert>
    </Collapse>
  );
};

export default AlertErroMain;
