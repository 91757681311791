import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import MainOnlyHeader from '../Main/MainOnlyHeader';

import { signOut } from '../../api/authentication';

export default () => {
  const history = useHistory();
  useEffect(() => {
    signOut().then(() => history.push('/authentication/login'));
  });

  return (
    <MainOnlyHeader>
      <LoaderMain />
    </MainOnlyHeader>
  );
};
