const etapasStatus = {
  INICIO: { etapa: 'CadastroEmpresa', subEtapa: '' },
  ANE: { etapa: 'FinalizacaoCadastroEmpresa', subEtapa: 'validacao' },
  ASM: { etapa: 'FinalizacaoCadastroEmpresa', subEtapa: 'solucoes' },
  OPR: { etapa: 'FinalizacaoCadastroEmpresa', subEtapa: 'reprovado' },
  CAN: { etapa: 'FinalizacaoCadastroEmpresa', subEtapa: 'reprovado' },
  SOL: { etapa: 'Solucoes', subEtapa: '' },
  ACG: { etapa: 'Garantia', subEtapa: '' },
  DCG: { etapa: 'Garantia', subEtapa: '' },
  DCE: { etapa: 'EnvioDocumentos', subEtapa: 'Empresa' },
  DCS: { etapa: 'EnvioDocumentos', subEtapa: 'Sociedade' },
  CCC: { etapa: 'DadosBancarios', subEtapa: '' }
};
export default etapasStatus;
