/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { mascararCpfCnpj } from '../../../shared';
import {
  getListarCheckpoints,
  getListarCheckpointsNegocios,
  getListarCheckpointsPropostas,
  postSalvarCheckpointsNegocios,
  postSalvarCheckpointsPropostas
} from '../../../api/dashboard';
import LoaderMain from '../../Onboarding/shared/LoaderMain';
import { formatarDataCreatedAtNow } from '../../Onboarding/shared/Utils';

const ModalGridDashboardCheckpoints = ({
  modalGridCheckpoints,
  setModalGridCheckpoints,
  lstPropostasEsteira,
  setLstPropostasEsteira,
  lstPropostasEsteiraCompleta,
  setLstPropostasEsteiraCompleta,
  listaClientes,
  setListaClientes
}) => {
  const [isPending, setIsPending] = useState(false);
  const [dadosCheckpoints, setDadosCheckpoints] = useState([]);
  const [valueCheckpoint, setValueCheckpoint] = useState('');
  const [inputObservacoesCheckpoints, setInputObservacoesCheckpoints] = useState('');
  const [defaultObservacoesCheckpoints, setDefaultObservacoesCheckpoints] = useState('');
  const [mensagemErro, setMensagemErro] = useState();
  const [mensagemSucesso, setMensagemSucesso] = useState();
  const [isChecked, setIsChecked] = useState(valueCheckpoint);
  const [valueRadio, setValueRadio] = useState(false);
  const [error, setError] = useState(false);
  const [mostraMensagemErroSelecaoRadio, setMostraMensagemErroSelecaoRadio] = useState(false);
  const [restauraBotao, setRestauraBotao] = useState(false);

  const defaultInputLength = 200;

  useEffect(() => {
    if (modalGridCheckpoints && modalGridCheckpoints.cnpjCliente) {
      setIsPending(true);
      setIsChecked(valueCheckpoint);
      setDadosCheckpoints([]);
      getListarCheckpoints()
        .then(res => {
          if (res && res.data) {
            let dados = res.data;
            setDadosCheckpoints(dados);
          }
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, [modalGridCheckpoints]);

  useEffect(() => {
    if (modalGridCheckpoints && modalGridCheckpoints.cnpjCliente) {
      setIsPending(true);
      setDadosCheckpoints([]);
      if (modalGridCheckpoints.ckeckpointsClientes) {
        getListarCheckpointsNegocios(modalGridCheckpoints.cnpjCliente)
          .then(res => {
            if (res && res.data) {
              let dados = res.data;
              setValueCheckpoint(dados.tag);
              if (dados && dados.observacao !== undefined && dados.observacao !== null) {
                setDefaultObservacoesCheckpoints(dados.observacao);
              }
              setIsChecked(dados.tag);
            }
          })
          .finally(() => {
            setIsPending(false);
          });
      } else {
        getListarCheckpointsPropostas(modalGridCheckpoints.cnpjCliente)
          .then(res => {
            if (res && res.data) {
              let dados = res.data;

              setValueCheckpoint(dados.tag);

              if (dados && dados.observacao !== undefined && dados.observacao !== null) {
                setDefaultObservacoesCheckpoints(dados.observacao);
              }
              setIsChecked(dados.tag);
            }
          })
          .finally(() => {
            setIsPending(false);
          });
      }
    }
  }, [modalGridCheckpoints]);

  const obterDescricaoCheckpointPorSuaTag = isChecked => {
    let descricao = '';

    dadosCheckpoints &&
      dadosCheckpoints.map(checkpoint =>
        checkpoint.tag === isChecked ? (descricao = checkpoint.descricao) : ''
      );

    return descricao;
  };

  const salvarCheckpointsNegocios = (form, params, checkpointObservacoes, descricaoCheckpoint) => {
    postSalvarCheckpointsNegocios(params)
      .then(res => {
        if (res && res.data) {
          let dados = res.data;

          setValueCheckpoint(dados.tag);
          setInputObservacoesCheckpoints(checkpointObservacoes);

          let tempLstClientes = [...listaClientes].map(prop => {
            if (prop.cnpjCliente === modalGridCheckpoints.cnpjCliente) {
              prop.checkpoint_tag = dados.tag;
              prop.checkpoint_descricao = descricaoCheckpoint;
              prop.checkpoint_observacao = checkpointObservacoes;
              prop.data_insercao = formatarDataCreatedAtNow();
              prop.nome_usuario_insercao = dados.nome_usuario;
            }
            return prop;
          });

          setListaClientes(tempLstClientes);
          if (res.status === 200) {
            setMensagemSucesso(dados.mensagem);
            setTimeout(() => {
              setModalGridCheckpoints(false);
            }, 2000);
          } else {
            setModalGridCheckpoints(false);
          }
        }
      })
      .catch(err => {
        const {
          response: {
            data: { error: { mensagemErro = 'Erro ao salvar as informações' } = {} } = {}
          } = {}
        } = err;
        setMensagemErro(mensagemErro);
      })
      .finally(() => {
        form.resetForm();
        form.setSubmitting(false);
      });
  };

  const salvarCheckpointsPropostas = (form, params, checkpointObservacoes, descricaoCheckpoint) => {
    postSalvarCheckpointsPropostas(params)
      .then(res => {
        if (res && res.data) {
          let dados = res.data;

          setValueCheckpoint(dados.tag);
          setInputObservacoesCheckpoints(checkpointObservacoes);
          //setDefaultObservacoesCheckpoints(checkpointObservacoes);

          let tempLstPropostasEsteira = [...lstPropostasEsteira].map(prop => {
            if (prop.cnpjCliente === modalGridCheckpoints.cnpjCliente) {
              prop.checkpoint_tag = dados.tag;
              prop.checkpoint_descricao = descricaoCheckpoint;
              prop.checkpoint_observacao = checkpointObservacoes;
              prop.data_insercao = formatarDataCreatedAtNow();
              prop.nome_usuario_insercao = dados.nome_usuario;
            }
            return prop;
          });

          setLstPropostasEsteira(tempLstPropostasEsteira);

          let tempLstPropostasEsteiraCompleta = [...lstPropostasEsteiraCompleta].map(prop => {
            if (prop.cnpjCliente === modalGridCheckpoints.cnpjCliente) {
              prop.checkpoint_tag = dados.tag;
              prop.checkpoint_descricao = descricaoCheckpoint;
              prop.checkpoint_observacao = checkpointObservacoes;
              prop.data_insercao = formatarDataCreatedAtNow();
              prop.nome_usuario_insercao = dados.nome_usuario;
            }
            return prop;
          });

          setLstPropostasEsteiraCompleta(tempLstPropostasEsteiraCompleta);

          if (res.status === 200) {
            setMensagemSucesso(dados.mensagem);
            setTimeout(() => {
              setModalGridCheckpoints(false);
            }, 2000);
          } else {
            setModalGridCheckpoints(false);
          }
        }
      })
      .catch(err => {
        const {
          response: {
            data: { error: { mensagemErro = 'Erro ao salvar as informações' } = {} } = {}
          } = {}
        } = err;
        setMensagemErro(mensagemErro);
      })
      .finally(() => {
        form.resetForm();
        form.setSubmitting(false);
      });
  };

  const cadastrarObservacoesCheckpoint = (values, form) => {
    if (modalGridCheckpoints && modalGridCheckpoints.cnpjCliente) {
      const checkpointObservacoes = inputObservacoesCheckpoints
        ? inputObservacoesCheckpoints
        : defaultObservacoesCheckpoints;

      let descricaoCheckpoint = obterDescricaoCheckpointPorSuaTag(isChecked);

      var origem =
        modalGridCheckpoints.ckeckpointsClientes !== true
          ? 'Checkpoint Propostas'
          : 'Checkpoint Negócios';
      let params = {
        cnpj: modalGridCheckpoints.cnpjCliente,
        tag: isChecked,
        observacao: checkpointObservacoes,
        origem: origem
      };

      setIsPending(true);
      setDadosCheckpoints([]);
      if (modalGridCheckpoints.ckeckpointsClientes) {
        salvarCheckpointsNegocios(form, params, checkpointObservacoes, descricaoCheckpoint);
      } else {
        salvarCheckpointsPropostas(form, params, checkpointObservacoes, descricaoCheckpoint);
      }
    }
  };

  const handleChangeTypingObservacaoCheckpoint = event => {
    setInputObservacoesCheckpoints(event.target.value);
    setDefaultObservacoesCheckpoints(event.target.value);
  };

  const handleChange = event => {
    setIsChecked(event.target.value);
    if (event.target.value !== '' || event.target.value !== undefined) {
      setRestauraBotao(true);
      setValueRadio(true);
    }
  };

  return (
    <Dialog
      open={modalGridCheckpoints !== false}
      fullWidth={false}
      maxWidth="sm"
      keepMounted
      onClose={() => setModalGridCheckpoints(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="items-centralizados-esquerda borda-bottom-2-gray pad-topbot-10">
          {modalGridCheckpoints ? modalGridCheckpoints.modalTitle : ''}
          <IconButton
            onClick={() => setModalGridCheckpoints(false)}
            className="float-right margin-neg-top-5"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {modalGridCheckpoints ? (
          <Box
            className="box-checkpoint"
            sx={isPending ? { position: 'relative', height: '250px' } : {}}
          >
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Box className="pad-5 texto-centro sub-titulo border-radius-8 margin-top-10">
              <Typography
                variant="subtitle2"
                className="font-size-1-1-rem texto-cor-cinza-escuro font-bold"
              >
                {modalGridCheckpoints.nomeCliente}
              </Typography>
              <Typography
                variant="subtitle2"
                className="font-size-1-1-rem texto-cor-cinza-escuro font-bold"
              >
                {mascararCpfCnpj(modalGridCheckpoints.cnpjCliente)}
              </Typography>
            </Box>
            <Grid
              className="margin-top-25 borda-bottom-2-main"
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="margin-top-30">
              <>
                {mensagemErro && (
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">{mensagemErro}</Alert>
                  </Stack>
                )}
              </>
              <Formik
                initialValues={{
                  observacoesCheckpoints: inputObservacoesCheckpoints
                }}
                onSubmit={(values, form) => {
                  {
                    error
                      ? setMostraMensagemErroSelecaoRadio(true)
                      : cadastrarObservacoesCheckpoint(values, form);
                  }
                }}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ padding: '2px' }}>
                        <>
                          <Grid container>
                            <FormControl className="radio-group-tipo-checkpoint">
                              <RadioGroup
                                className="radio-list"
                                component={RadioGroup}
                                name="dadosCheckpoints"
                                disabled={isSubmitting}
                                value={isChecked}
                              >
                                {dadosCheckpoints &&
                                  dadosCheckpoints.map(checkpoint => (
                                    <FormControlLabel
                                      className="margin-bottom: 0.15rem;"
                                      key={checkpoint.id}
                                      value={checkpoint.tag}
                                      onChange={handleChange}
                                      control={<Radio className="margin-bottom: 0.15rem;" />}
                                      label={checkpoint.descricao}
                                    />
                                  ))}
                              </RadioGroup>
                              {isChecked === '' || isChecked === undefined || !isChecked
                                ? setError(true)
                                : setError(false)}
                            </FormControl>
                          </Grid>
                        </>
                        <Grid container>
                          <TextField
                            defaultValue={defaultObservacoesCheckpoints}
                            onChange={handleChangeTypingObservacaoCheckpoint}
                            type="text"
                            className="fixed-input-checkpoint"
                            placeholder="Adicione informações sobre o checkpoint"
                            fullWidth
                            name="observacoesCheckpoints"
                            id="observacoes_checkpoints"
                            autoComplete="off"
                            disabled={isSubmitting && mostraMensagemErroSelecaoRadio && !restauraBotao}
                            multiline
                            rows={2}
                            inputProps={{ maxLength: 200 }}
                          />
                        </Grid>
                        <Box className="pad-5 texto-centro margin-top-10">
                          <Typography variant="h7" className="font-size-12 texto-cor-cinza-2 font-bold">
                            {(defaultObservacoesCheckpoints &&
                              defaultObservacoesCheckpoints.length &&
                              defaultObservacoesCheckpoints !== undefined &&
                              defaultObservacoesCheckpoints !== null &&
                              defaultObservacoesCheckpoints.length > 0 &&
                              defaultObservacoesCheckpoints.length >= defaultInputLength) ||
                            (inputObservacoesCheckpoints &&
                              inputObservacoesCheckpoints.length &&
                              inputObservacoesCheckpoints !== undefined &&
                              inputObservacoesCheckpoints !== null &&
                              inputObservacoesCheckpoints.length > 0 &&
                              inputObservacoesCheckpoints.length >= defaultInputLength)
                              ? `Os ${defaultInputLength} caracteres foram alcançados!`
                              : `Faltam ${
                                  defaultObservacoesCheckpoints
                                    ? defaultInputLength - defaultObservacoesCheckpoints.length
                                    : defaultInputLength - inputObservacoesCheckpoints.length
                                } caracteres para o limite de inserção das observações!`}
                          </Typography>
                        </Box>
                      </Grid>
                      <>
                        {mensagemSucesso && (
                          <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="success">{mensagemSucesso}</Alert>
                          </Stack>
                        )}
                      </>
                      <>
                        {error && mostraMensagemErroSelecaoRadio && (
                          <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="error">Por favor, selecione uma opção de checkpoint.</Alert>
                          </Stack>
                        )}
                      </>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Button
                          className="btn-main pad-5 margin-top-10"
                          disabled={isSubmitting && !valueRadio}
                          onClick={submitForm}
                        >
                          Salvar Observação
                        </Button>
                      </Grid>

                      {isSubmitting && <LinearProgress />}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Box>
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalGridDashboardCheckpoints;
