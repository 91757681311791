export const mimeTypesArquivo = {
  pdf: ['application/pdf'],
  imagens: ['image/png', 'image/jpeg', 'image/bmp'],
  png: ['image/png'],
  jpg: ['image/jpeg'],
  word: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  powerPoint: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ],
  excel: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  compactados: ['application/zip', 'application/x-rar-compressed'],
  rar: ['application/x-rar-compressed'],
  zip: ['application/zip']
};

export const extensoesArquivo = {
  pdf: ['.pdf'],
  imagens: ['.png', '.jpeg', '.jpg', '.bmp'],
  png: ['.png'],
  jpg: ['.jpg'],
  word: ['.doc', '.docx'],
  excel: ['.xls', '.xlsx'],
  powerPoint: ['.ppt', '.pptx'],
  compactados: ['.zip', '.rar'],
  rar: ['.rar'],
  zip: ['.zip']
};
