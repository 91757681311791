import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Link } from 'react-router-dom';
import { postUltimosBorderos } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';

const UltimosBorderos = ({ filtroDashboard, loadingDashFim }) => {
  moment.locale('pt-br');
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [dadosChart, setDadosChart] = useState(false);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    let params = filtroDashboard ? filtroDashboard : {};
    if (params.filtrar === false) return false;

    setIsPending(true);
    setDadosChart([]);
    postUltimosBorderos(params)
      .then(res => {
        let lstDados = res && res.data ? res.data : [];
        setDadosChart(lstDados);
      })
      .catch(() => {
        setDadosChart(false);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('UltimosBorderos');
      });
  }, [filtroDashboard]);

  return (
    <Card className="chart-card card-ultimos-borderos">
      {dadosChart ? (
        <CardContent className="ajuste-spin-chart" sx={isPending ? { position: 'relative' } : {}}>
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-5 word-break labelChartLiquidez"
          >
            <span className="font-size-1rem margin-right-10 texto-weight-600">
              Últimos borderôs adicionados
            </span>
            <Link
              className="linkRelatorios float-right texto-info font-size-875"
              to={{
                pathname: '/relatorios/borderos',
                state: {
                  fromDashboard: true
                }
              }}
            >
              Mostrar todos
            </Link>
          </Typography>

          <CardContent className={onlySmallScreen ? 'ajuste-responsivo-card-content' : 'ajuste-tamanho'}>
            <Box className={onlySmallScreen ? 'ajuste-responsivo-box' : ''}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid className="sem-quebra-linha" container>
                    <Grid item lg={1} md={1} sm={1} xs={1} />
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                      Status
                    </Grid>
                    <Grid className="texto-centro" item lg={2} md={2} sm={2} xs={2}>
                      Nº do borderô
                    </Grid>
                    <Grid className="texto-centro" item lg={3} md={3} sm={3} xs={3}>
                      Valor bruto
                    </Grid>
                    <Grid className="texto-centro" item lg={2} md={2} sm={2} xs={2}>
                      <Tooltip arrow placement="top" title="Data do aceite da operação">
                        <span>Data operação</span>
                      </Tooltip>
                    </Grid>
                    <Grid className="texto-centro" item lg={2} md={2} sm={2} xs={2}>
                      <Tooltip arrow placement="top" title="Quantidade de títulos">
                        <span>Títulos</span>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  {dadosChart && dadosChart.length > 0 ? (
                    dadosChart.map(dados => {
                      return (
                        <Grid
                          key={dados.numeroBordero}
                          container
                          className="texto-centro row-ultimos-borderos"
                        >
                          <Grid
                            className={`row-borderos borda-row-ultimos-borderos cor-borda-${dados.cor}`}
                            item
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                          />
                          <Grid className="row-borderos texto-esquerda" item lg={2} md={2} sm={2} xs={2}>
                            {dados.descricaoStatus}
                          </Grid>
                          <Grid className="row-borderos" item lg={2} md={2} sm={2} xs={2}>
                            {dados.numeroBordero}
                          </Grid>
                          <Grid className="row-borderos" item lg={3} md={3} sm={3} xs={3}>
                            {dados.valorBruto.toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </Grid>
                          <Grid className="row-borderos" item lg={2} md={2} sm={2} xs={2}>
                            {dados.dataAceite}
                          </Grid>
                          <Grid className="row-borderos" item lg={2} md={2} sm={2} xs={2}>
                            {dados.quantidadeTitulos}
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : (
                    <CardContent>
                      <center className="mt-3">
                        <h1>Não há borderôs para serem mostrados!</h1>
                      </center>
                    </CardContent>
                  )}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </CardContent>
      ) : (
        <CardContent>
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default UltimosBorderos;
