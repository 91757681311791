import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { FastField, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

import VoltarIcon from '../../../shared/icons/Voltar';
import Avancar from '../../../shared/icons/Avancar';

import { Radio, SkeletonLoader, If } from '../../../shared';
import { getClienteAtual } from '../../../shared/services/User';
import HistoricoAcesso from '../../commom/HistoricoAcesso';
import TiposInstrucaoEnum from '../../../shared/Enums/TiposInstrucaoEnum';

const DefinicaoStep = props => {
  const {
    nextStep,
    isPending,
    setIsPending,
    instrucao,
    setInstrucao,
    tiposInstrucao,
    exibirAlerta,
    setExibirAlerta
  } = props;
  const [initialValues, setInitialValues] = useState(null);
  const [clienteAtual, setClienteAtual] = useState();
  const [proximaPagina, setProximaPagina] = useState(false);

  useEffect(() => {
    if (!initialValues) {
      let queryStrTipoInstrucao = buscaTipoInstrucaoQueryString();

      if (queryStrTipoInstrucao && Object.keys(instrucao).length === 0) {
        let tiposInstrucoesDefault = TiposInstrucaoEnum;
        tiposInstrucoesDefault.forEach(tipoInstrucao => {
          if (tipoInstrucao.code === queryStrTipoInstrucao) {
            setInitialValues({ tipoInstrucao: tipoInstrucao });
            setInstrucao({ tipoInstrucao: tipoInstrucao });
            setProximaPagina(true);
          }
        });
      } else {
        setInitialValues({
          tipoInstrucao: instrucao.tipoInstrucao || {}
        });
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (proximaPagina) {
      nextStep();
    }
  }, [proximaPagina]);

  const buscaTipoInstrucaoQueryString = () => {
    const url = new URL(window.location.href);
    const hash = url.hash;
    const queryParams = new URLSearchParams(hash.split('?')[1]);
    return queryParams.get('tipoInstrucao');
  };

  useEffect(() => {
    if (!clienteAtual) {
      const newCliente = getClienteAtual();
      setClienteAtual(newCliente);
    }
  }, []);

  useEffect(() => {
    if (initialValues && tiposInstrucao) {
      setIsPending(false);
    }
  }, [initialValues, setIsPending, tiposInstrucao]);

  const handleFormSubmit = values => {
    setInstrucao(values);
    nextStep();
  };

  if (!exibirAlerta && setExibirAlerta) {
    setExibirAlerta(true);
  }

  return (
    <>
      <If test={initialValues}>
        <Formik
          initialValues={initialValues}
          validate={values => {
            let errors = {};
            if (!values.tipoInstrucao.code) {
              errors.tipoInstrucao = 'Escolha um tipo de instrução';
            }
            return errors;
          }}
          onSubmit={handleFormSubmit}
          render={({ values, setFieldValue, isSubmitting }) => {
            return (
              <Form className={isPending || isSubmitting ? 'is-submitting' : ''}>
                <div className="body">
                  <h1 className="title">Defina sua Instrução</h1>
                  <SkeletonLoader
                    isPending={isPending}
                    width="50%"
                    height="30px"
                    count={5}
                    widthRandomness={1}
                  >
                    <div className="row">
                      <div className="col">
                        <Label className="custom-label">Cliente:</Label>
                        <h4 className="company-name">
                          {clienteAtual && clienteAtual.razaoSocial ? clienteAtual.razaoSocial : ''}
                        </h4>
                        <FormGroup tag="fieldset">
                          <legend>Tipo de Instrução:</legend>
                          {tiposInstrucao &&
                            tiposInstrucao.map(tipoInstrucao => {
                              return (
                                <FastField
                                  key={tipoInstrucao.code}
                                  name="tipoInstrucao"
                                  checked={values.tipoInstrucao.code === tipoInstrucao.code}
                                  label={`${tipoInstrucao.code} - ${tipoInstrucao.descricao}`}
                                  id={tipoInstrucao.code}
                                  value={tipoInstrucao.code}
                                  disabled={isPending || isSubmitting}
                                  autoComplete="off"
                                  onChange={() => {
                                    setFieldValue('tipoInstrucao', tipoInstrucao);
                                  }}
                                  component={Radio}
                                />
                              );
                            })}
                        </FormGroup>
                      </div>
                    </div>
                  </SkeletonLoader>
                </div>
                <div className="footer d-flex justify-content-between">
                  <Link
                    to="/instrucoes"
                    className="btn btn-lg btn-outline-primary mr-auto btn-back"
                    disabled={isSubmitting}
                  >
                    <i className="svg-icon">
                      <VoltarIcon />
                    </i>
                    Voltar
                  </Link>

                  <Button size="lg" color="primary" type="submit" disabled={isPending || isSubmitting}>
                    <i className="svg-icon">
                      <Avancar />
                    </i>
                    Avançar
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </If>
      <HistoricoAcesso codTela={1198} />
    </>
  );
};

export default DefinicaoStep;
