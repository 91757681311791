export default {
  TODAS: { etapa: 'TODAS', titulo: 'Todas' },
  RESERVADO: { etapa: 'RESERVADO', titulo: 'Reservado' },
  REJEITADO: { etapa: 'REJEITADO', titulo: 'Rejeitado' },
  PROPONDO: { etapa: 'PROPONDO', titulo: 'Comercial' },
  CANCELADO: { etapa: 'CANCELADO', titulo: 'Cancelado' },
  CHECAGEM: { etapa: 'CHECAGEM', titulo: 'Checking' },
  CLIENTE: { etapa: 'CLIENTE', titulo: 'Pend.Cliente' },
  ANALISE_NEGOCIO: { etapa: 'ANALISE_NEGOCIO', titulo: 'Negócios' },
  BACK_OFFICE: { etapa: 'BACK_OFFICE', titulo: 'BackOffice' },
  GERENTE_PLATAFORMA: { etapa: 'GERENTE_PLATAFORMA', titulo: 'Ger.Plataf.' },
  CREDITO: { etapa: 'CREDITO', titulo: 'Crédito' },
  COMITE: { etapa: 'COMITE', titulo: 'Comitê' },
  FORMALIZACAO: { etapa: 'FORMALIZACAO', titulo: 'Formalização' },
  CONCLUIDO: { etapa: 'CONCLUIDO', titulo: 'Concluído' },
  EXPIRADO: { etapa: 'EXPIRADO', titulo: 'Expirado' }
};
