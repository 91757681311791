import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.03 39.28">
    <path
      d="M42,27.09H2.22c-3,0-3-4.9,0-4.9H42l-12.52-13C27.26,7,30.57,3.52,32.83,5.69L49.35,22.92a2.61,2.61,0,0,1,0,3.45L32.83,43.59c-2.26,2.18-5.57-1.27-3.31-3.44Z"
      transform="translate(0.03 -5)"
    />
  </svg>
);
