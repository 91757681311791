import React from 'react';
import { Button } from 'reactstrap';
import swal from '@sweetalert/with-react';
import { v4 } from 'uuid';

import { dateFormat, moneyFullFormat } from '../../../../utils/format';

const columns = () => {
  const ret = [
    {
      dataField: 'sacado.nome',
      text: 'SACADO',
      headerAlign: 'center'
    },
    {
      dataField: 'tipoOperacao.ndoc',
      text: 'Nº DO DOC.',
      headerAlign: 'center',
      formatter: (cellContent, row) => {
        return <div className="text-center">{row.tipoOperacao.ndoc}</div>;
      }
    },
    {
      dataField: 'tipoOperacao.vencimento',
      text: 'VENCIMENTO',
      headerAlign: 'center',
      searchable: false,
      formatter: (cellContent, row) => {
        return <div className="text-center">{dateFormat(row.tipoOperacao.vencimento)}</div>;
      }
    },
    {
      dataField: 'tipoOperacao.valor',
      text: 'VALOR BRUTO',
      headerAlign: 'center',
      searchable: false,
      formatter: (cellContent, row) => {
        return <div className="text-right">{moneyFullFormat(row.tipoOperacao.valor)}</div>;
      }
    }
  ];

  ret.push({
    dataField: ' ',
    text: 'INCONSISTÊNCIAS',
    searchable: false,
    clickToSelect: false,
    headerAlign: 'center',
    formatter: (cellContent, row) => {
      const statusError =
        row.status &&
        row.status.filter(status => {
          return ['trava', 'erro'].includes(status.tipo);
        });

      const statusNotificacao =
        row.status &&
        row.status.filter(status => {
          return status.tipo === 'notificacao';
        });

      const showNotification =
        (statusError && statusError.length > 0) || (statusNotificacao && statusNotificacao.length > 0);

      return showNotification ? (
        <div className="text-center">
          <Button
            color={statusError && statusError.length > 0 ? 'danger' : 'warning'}
            outline
            type="button"
            id={`btn-danger-${row.id}`}
            className="rounded-circle mr-1"
            title="Existem alerta(s) nesse título, clique para ver mais detalhes"
            onClick={e => {
              e.preventDefault();
              swal(
                <div>
                  {statusError && statusError.length > 0 ? (
                    <p>
                      Esse título possui iconsistências que
                      <strong className="text-danger"> impedem </strong>
                      você de prosseguir até que elas sejam corrigidas.
                    </p>
                  ) : (
                    <p>
                      As inconsistências listadas abaixo
                      <strong className="text-warning"> não impedem </strong>
                      você de prosseguir com a finalização da sua instrução.
                    </p>
                  )}
                  <ul className="list-group list-group-flush max-height">
                    {statusError.map(status => {
                      return (
                        <div key={v4()} className="alert alert-danger" role="alert">
                          {status.mensagem}
                        </div>
                      );
                    })}
                    {statusNotificacao.map(status => {
                      return (
                        <div key={v4()} className="alert alert-warning" role="alert">
                          {status.mensagem}
                        </div>
                      );
                    })}
                  </ul>
                </div>,
                {
                  title: 'Erro(s)'
                }
              );
            }}
          >
            <i className="fa fa-exclamation" />
          </Button>
        </div>
      ) : null;
    }
  });

  return ret;
};

export default columns;
