export default value => {
  value = value.replace(/</g, '');
  value = value.replace(/>/g, '');
  value = value.replace('Ç', '');

  var lc_Tam, lc_NumCalc, lc_DigCalc1, lc_DigCalc2, lc_DigCalc3, lc_Cmc7Calc;

  lc_Tam = value.length;
  if (lc_Tam < 30) {
    return false;
  }

  lc_NumCalc = value.substring(8, 18); //, 9, 10)
  lc_DigCalc1 = CalcDig_Mod10(lc_NumCalc);
  lc_NumCalc = '000' + value.substring(0, 7); //, 1, 7)
  lc_DigCalc2 = CalcDig_Mod10(lc_NumCalc);
  lc_NumCalc = value.substring(19, 29); //, 20, 10)
  lc_DigCalc3 = CalcDig_Mod10(lc_NumCalc);
  lc_Cmc7Calc =
    value.substring(0, 7) +
    lc_DigCalc1 +
    value.substring(8, 18) +
    lc_DigCalc2 +
    value.substring(19, 29) +
    lc_DigCalc3;
  if (value !== lc_Cmc7Calc) {
    return false;
  }
  return true;
};

const CalcDig_Mod10 = value => {
  var lc_Tam, lc_Soma, lc_Resto, lc_Cont, lc_Pos, lc_Aux, CalcDig_Mod10;

  lc_Tam = value.length;
  lc_Soma = 0;
  lc_Pos = true;

  for (lc_Cont = 0; lc_Cont < lc_Tam; lc_Cont++) {
    lc_Aux = value.substring(lc_Cont, lc_Cont + 1);
    if (lc_Pos === true) {
      lc_Pos = false;
      lc_Soma = Number(lc_Soma) + Number(lc_Aux);
    } else {
      lc_Pos = true;
      if (lc_Aux > 4) {
        lc_Soma = lc_Soma + lc_Aux * 2 - 9;
      } else {
        lc_Soma = lc_Soma + lc_Aux * 2;
      }
    }
  }
  lc_Resto = lc_Soma % 10;
  if (lc_Resto === 0) {
    CalcDig_Mod10 = 0;
  } else {
    CalcDig_Mod10 = 10 - lc_Resto;
  }
  return CalcDig_Mod10;
};
