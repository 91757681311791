import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label
} from 'reactstrap';
import { Form, Formik, FastField, Field } from 'formik';
import moment from 'moment';

import { Link, useHistory } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import * as Yup from 'yup';
import {
  deleteBorderoByCode,
  getBorderosPendente,
  getTiposEmpresas,
  getTiposOperacoes,
  getBorderoDocumentoNotaFiscalServico,
  getBorderoDocumentoImpotacaoGeracaoTitulo,
  getBorderoDocumentoContratoFisico
} from '../../api/borderos';
import {
  getClienteAtual,
  getCodPlataformaEscolhida,
  setCodPlataformaEscolhida,
  verificaMostraFiltroPlataforma
} from '../../shared/services/User';
import ModalBorderoVisualizaDocumento from './Wizard/SelectionStep/ModalBorderoVisualizaDocumento';
import {
  DateTimePicker,
  Input,
  Select,
  getCodGerenteEscolhido,
  getCodUnidadeEscolhido,
  listaFiltradaGerentesPlataforma,
  listaFiltradaUnidadeNegocioPlataforma,
  listarPlataformaEListarGerentes,
  normalizaListaUnidadesNegociosPlataforma,
  normalizaListaGerentesPlataforma,
  setCodGerenteEscolhido,
  setCodUnidadeEscolhido,
  setCodVerticalEscolhida
} from '../../shared';
import SkeletonLoader from '../../shared/components/common/SkeletonLoader';
import Grid from '../commom/Grid';
import borderosGrid from './borderosGrid';
import Main from '../Main/Main';
import HistoricoAcesso from '../commom/HistoricoAcesso';
import Wootric from '../Dashboard/Wootric/Wootric';

const Borderos = props => {
  const history = useHistory();
  const [form, setForm] = useState({});
  const [modal, setModal] = useState({ show: false, bordero: null });
  const [isPending, setPending] = useState(true);
  const [tipoEmpresas, setTipoEmpresas] = useState([]);
  const [clienteAtual, setClienteAtual] = useState();
  const [tipoOperacoes, setTipoOperacoes] = useState([]);
  const [borderos, setBorderos] = useState([]);
  const [message, setMessage] = useState();
  const [modalBorderoVisualizaDocumento, setModalBorderoVisualizaDocumento] = useState({ show: false });
  // Douglas - 24/01/20 - Ao carregar a pagina, caso o tamanho da janela seja menor ou igual a 600px ja carrega o menu collapsado
  const [collapseMenuRight, setCollapseMenuRight] = useState(window.innerWidth <= 600 ? true : false);
  const filtroInicial = {
    data_inicio: moment()
      .subtract(14, 'days')
      .format('DD/MM/YYYY'),
    data_final: moment().format('DD/MM/YYYY')
  };
  const [vertical, setVertical] = useState(0);
  const [lstVerticais, setLstVerticais] = useState([]);
  const [plataforma, setPlataforma] = useState(0);
  const [lstPlataformas, setLstPlataformas] = useState([]);
  const [lstPlataformasFiltradas, setLstPlataformasFiltradas] = useState([]);
  const mostrarFiltroPlataforma = verificaMostraFiltroPlataforma();
  const [lstFiltradaGerentePlataforma, setLstFiltradaGerentePlataforma] = useState([]);
  const [gerente, setGerente] = useState(null);
  const [lstFiltradaUnidadeNegocio, setLstFiltradaUnidadeNegocio] = useState([]);
  const [unidadeNegocio, setUnidadeNegocio] = useState(null);

  const getBorderos = (filtro = {}, actions = null) => {
    setPending(true);
    getBorderosPendente(filtro)
      .then(res => {
        setPending(false);
        setBorderos(res.data);
      })
      .catch(() => {
        setMessage('Houve um erro na busca pelos borderos. Por favor, tente novamente mais tarde.');
      })
      .finally(() => {
        setPending(false);
        if (actions !== null) {
          actions.setSubmitting(false);
          setCollapseMenuRight(true);
        }
      });
  };

  useEffect(() => {
    const clienteSelecionado = getClienteAtual();
    if (!clienteAtual) {
      setClienteAtual(clienteSelecionado);
    }

    setPending(true);
    getTiposEmpresas().then(res => {
      setTipoEmpresas(
        res.data.map(empresa => {
          return {
            value: empresa.code,
            label: empresa.descricao
          };
        })
      );
    });

    getTiposOperacoes().then(res => {
      setTipoOperacoes(
        res.data.map(operacao => {
          return {
            value: operacao.code,
            label: operacao.descricao
          };
        })
      );
    });

    if (!mostrarFiltroPlataforma) {
      getBorderos(filtroInicial);
    }

    buscaPlataformas();
  }, []);

  const buscaPlataformas = () => {
    if (!mostrarFiltroPlataforma) {
      return;
    }
    setPending(true);
    listarPlataformaEListarGerentes({
      setLstPlataformas: setLstPlataformas,
      setPlataforma: setPlataforma,
      setLstPlataformasFiltradas: setLstPlataformasFiltradas,
      setListaFiltradaGerentes: setLstFiltradaGerentePlataforma,
      setGerenteEscolhido: setGerente,
      setListaFiltradaUnidadeNegocio: setLstFiltradaUnidadeNegocio,
      setUnidadeNegocioEscolhido: setUnidadeNegocio,
      funcaoSucesso: funcaoSucessoPlataforma,
      setPending: setPending,
      setLstVerticais: setLstVerticais,
      setVertical: setVertical
    });
  };

  const funcaoSucessoPlataforma = (vertical, plat, ger, unid) => {
    let filtros = filtroInicial;
    filtros.cod_vertical = vertical;
    filtros.cod_plataforma = plat;
    filtros.cod_gerente = ger;
    filtros.cod_unidade_negocio = unid;
    getBorderos(filtros);
  };

  const deleteBordero = () => {
    if (modal && modal.bordero) {
      const { id } = modal.bordero;
      setPending(true);
      toggle(null);
      deleteBorderoByCode(id)
        .then(res => {
          if (typeof res.data.success !== 'undefined') {
            swal({
              title: res.data.success.message,
              icon: 'success'
            });
          }
          getBorderos();
        })
        .catch(err => {
          const {
            response: {
              data: { error: { message = 'Erro inesperado ao excluir bordero' } = {} } = {}
            } = {}
          } = err;
          swal({
            title: 'Erro!',
            text: message,
            icon: 'error',
            dangerMode: true
          });
          getBorderos();
        });
    }
  };

  const toggle = bordero => {
    const newModal = {
      bordero,
      show: !modal.show
    };
    setModal(newModal);
  };

  const toggleBorderoVisualizaDocumento = event => {
    let idBordero = -1;
    let exibirTela = !modalBorderoVisualizaDocumento.show;

    if (exibirTela === true) {
      idBordero = event.target.value;
    }

    if (idBordero === -1) {
      setModalBorderoVisualizaDocumento({
        show: exibirTela,
        idBordero: idBordero
      });
      return false;
    }

    setPending(true);
    getBorderoDocumentoNotaFiscalServico(idBordero)
      .then(resNF => {
        getBorderoDocumentoImpotacaoGeracaoTitulo(idBordero)
          .then(resIMP => {
            getBorderoDocumentoContratoFisico(idBordero)
              .finally(() => setPending(false))
              .then(resCF => {
                setModalBorderoVisualizaDocumento({
                  show: exibirTela,
                  idBordero: idBordero,
                  notasServico: resNF.data,
                  impotacaoGeracaoTitulo: resIMP.data,
                  contratosFisico: resCF.data
                });
              });
          })
          .catch(() => setPending(false));
      })
      .catch(() => setPending(false));
  };

  const irParaJornadaOne7MaisCredito = value => {
    return history.push({
      pathname: '/nova-entrada',
      state: { codContacorrente: true, codEntrada: value, novaEntrada: false }
    });
  };

  useEffect(() => {
    if (form && form.setFieldValue && vertical && lstPlataformas.length > 0) {
      form.setFieldValue('cod_vertical', vertical);

      let plataformas = [...lstPlataformas].filter(
        plataf => parseInt(plataf.codVertical) === parseInt(vertical)
      );
      setLstPlataformasFiltradas(plataformas);

      let platafEscolhida =
        plataformas && plataformas[0] && plataformas[0].value ? plataformas[0].value : null;
      if (
        getCodPlataformaEscolhida() &&
        plataformas &&
        plataformas.filter(e => e.value === getCodPlataformaEscolhida()[0]).length > 0
      ) {
        platafEscolhida = getCodPlataformaEscolhida()[0];
      }
      setPlataforma(platafEscolhida);
    }
  }, [vertical]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_plataforma', Array.isArray(plataforma) ? plataforma[0] : plataforma);

      let lstFiltradaUnidadeNegocioPlataforma = listaFiltradaUnidadeNegocioPlataforma(
        Array.isArray(plataforma) ? plataforma : [plataforma]
      );
      let unidadeNegocioEscolhido = null;
      if (lstFiltradaUnidadeNegocioPlataforma.length > 0) {
        lstFiltradaUnidadeNegocioPlataforma = normalizaListaUnidadesNegociosPlataforma(
          lstFiltradaUnidadeNegocioPlataforma
        );
        setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
        unidadeNegocioEscolhido =
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma[0] &&
          lstFiltradaUnidadeNegocioPlataforma[0].value
            ? lstFiltradaUnidadeNegocioPlataforma[0].value
            : null;

        if (
          getCodUnidadeEscolhido() &&
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma.filter(e => e.value === getCodUnidadeEscolhido()).length >
            0
        ) {
          unidadeNegocioEscolhido = getCodUnidadeEscolhido();
        }
      } else {
        setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
      }
      setUnidadeNegocio(unidadeNegocioEscolhido);
    }
  }, [plataforma]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_unidade_negocio', unidadeNegocio);
      let lstFiltradaGerentes = listaFiltradaGerentesPlataforma(plataforma, unidadeNegocio);
      lstFiltradaGerentes = normalizaListaGerentesPlataforma(lstFiltradaGerentes);
      setLstFiltradaGerentePlataforma(lstFiltradaGerentes);

      let gerenteEscolhido =
        lstFiltradaGerentes && lstFiltradaGerentes[0] && lstFiltradaGerentes[0].value
          ? lstFiltradaGerentes[0].value
          : null;

      if (
        getCodGerenteEscolhido() &&
        lstFiltradaGerentes &&
        lstFiltradaGerentes.filter(e => e.value === getCodGerenteEscolhido()).length > 0
      ) {
        gerenteEscolhido = getCodGerenteEscolhido();
      }
      setGerente(gerenteEscolhido);
    }
  }, [unidadeNegocio, plataforma]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_gerente', gerente);
    }
  }, [gerente]);

  const handleSubmitFiltro = (filtro = {}, actions = null) => {
    setCodVerticalEscolhida(vertical);
    setCodPlataformaEscolhida(plataforma);
    setCodUnidadeEscolhido(unidadeNegocio);
    setCodGerenteEscolhido(gerente);
    getBorderos(filtro, actions);
  };

  return (
    <Main title="Borderôs" className={'borderos ' + (collapseMenuRight ? 'collapsed' : '')} {...props}>
      <Formik
        ref={node => setForm(node)}
        initialValues={filtroInicial}
        validationSchema={Yup.object().shape({
          data_inicio: Yup.string()
            .nullable()
            .required('Período obrigatório.'),
          data_final: Yup.string()
            .nullable()
            .required('Período obrigatório.')
        })}
        onSubmit={handleSubmitFiltro}
        render={({ isSubmitting }) => {
          return (
            <Card>
              <CardBody>
                <div className="row row-content">
                  <div
                    id="content-bordero"
                    className={
                      (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                      ' col-12 content-body content-bordero'
                    }
                  >
                    <div className="body">
                      <CardHeader className="d-flex align-items-center">
                        <CardTitle className="mb-0">Borderôs</CardTitle>
                        {!isPending && (
                          <>
                            {clienteAtual && clienteAtual.id !== 'todos' && (
                              <Link to="/Borderos/new" className="btn btn-primary ml-auto">
                                <i className="fa fa-plus" />
                                <span className="d-none d-lg-inline-block">Incluir Novo Borderô</span>
                              </Link>
                            )}
                            {collapseMenuRight && (
                              <button
                                type="button"
                                onClick={() => setCollapseMenuRight(false)}
                                title="Filtros"
                                className="btn btn-outline-secondary btn-show-menu-right"
                                style={{ marginLeft: '30px' }}
                              >
                                <i className="fa fa-angle-left" />
                                <span>Filtros</span>
                              </button>
                            )}
                          </>
                        )}
                      </CardHeader>
                      <CardBody>
                        <SkeletonLoader
                          isPending={isPending}
                          width="98%"
                          height="40px"
                          count={8}
                          widthRandomness={0}
                        >
                          {message && (
                            <div className="alert alert-danger animated fadeIn" role="alert">
                              {message}
                              <i
                                role="button"
                                className="fa fa-times float-right cursor-pointer"
                                onClick={() => setMessage(null)}
                                onKeyPress={() => setMessage(null)}
                                tabIndex={0}
                              />
                            </div>
                          )}
                          <Grid
                            keyField="id"
                            data={borderos || []}
                            classes="table-layout-auto"
                            configuracaoColunas={borderosGrid(
                              toggleBorderoVisualizaDocumento,
                              toggle,
                              clienteAtual,
                              irParaJornadaOne7MaisCredito
                            )}
                            classeIdentificadora="listagem-bordero"
                          />
                        </SkeletonLoader>
                      </CardBody>
                    </div>
                  </div>
                  {!collapseMenuRight && (
                    <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                      <Form>
                        <div className="fixed-side">
                          <div
                            className="header cursor-pointer"
                            onClick={() => setCollapseMenuRight(true)}
                            onKeyPress={() => setCollapseMenuRight(true)}
                            role="button"
                            tabIndex={0}
                          >
                            <h4 className="title">
                              Filtros
                              <i className="fa fa-angle-right float-right" />
                            </h4>
                          </div>
                          <div className="body">
                            <SkeletonLoader
                              isPending={isPending}
                              width="98%"
                              height="20px"
                              count={8}
                              widthRandomness={0}
                            >
                              <div className="container p-0">
                                <FormGroup>
                                  <Label>Cliente:</Label>
                                  <p className="form-control-static">
                                    {clienteAtual &&
                                      (clienteAtual.razaoSocial || clienteAtual.nomeFantasia)}
                                  </p>
                                </FormGroup>

                                {mostrarFiltroPlataforma && lstVerticais && (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Field
                                        label="Vertical"
                                        id="cod_vertical"
                                        name="cod_vertical"
                                        className="select-plataforma-relatorios"
                                        options={lstVerticais}
                                        onChange={e => {
                                          setVertical(e.value);
                                        }}
                                        disabled={isSubmitting || lstVerticais.length === 1}
                                        component={Select}
                                        autoComplete="off"
                                        isClearable={false}
                                        placeholder="-- Selecione"
                                      />
                                    </div>
                                  </div>
                                )}

                                {mostrarFiltroPlataforma && lstPlataformasFiltradas && (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Field
                                        label="Plataforma"
                                        id="cod_plataforma"
                                        name="cod_plataforma"
                                        className="select-plataforma-relatorios"
                                        options={lstPlataformasFiltradas}
                                        onChange={e => {
                                          setPlataforma(e.value);
                                        }}
                                        disabled={isSubmitting || lstPlataformasFiltradas.length === 1}
                                        component={Select}
                                        autoComplete="off"
                                        isClearable={false}
                                        placeholder="-- Selecione"
                                      />
                                    </div>
                                  </div>
                                )}

                                {mostrarFiltroPlataforma &&
                                  lstPlataformasFiltradas &&
                                  lstFiltradaUnidadeNegocio &&
                                  unidadeNegocio && (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Field
                                          label="Unidade de Negócio"
                                          id="cod_unidade_negocio"
                                          name="cod_unidade_negocio"
                                          className="select-plataforma-relatorios"
                                          options={lstFiltradaUnidadeNegocio}
                                          onChange={e => {
                                            setUnidadeNegocio(e.value);
                                          }}
                                          disabled={
                                            isSubmitting || lstFiltradaUnidadeNegocio.length === 1
                                          }
                                          component={Select}
                                          autoComplete="off"
                                          isClearable={false}
                                          placeholder="-- Selecione"
                                        />
                                      </div>
                                    </div>
                                  )}

                                {mostrarFiltroPlataforma &&
                                  lstPlataformasFiltradas &&
                                  lstFiltradaGerentePlataforma &&
                                  gerente && (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Field
                                          label="Gerente"
                                          id="cod_gerente"
                                          name="cod_gerente"
                                          className="select-plataforma-relatorios"
                                          options={lstFiltradaGerentePlataforma}
                                          onChange={e => {
                                            setGerente(e.value);
                                          }}
                                          disabled={
                                            isSubmitting || lstFiltradaGerentePlataforma.length === 1
                                          }
                                          component={Select}
                                          autoComplete="off"
                                          isClearable={false}
                                          placeholder="-- Selecione"
                                        />
                                      </div>
                                    </div>
                                  )}

                                <div className="row">
                                  <div className="col-md-12">
                                    <FastField
                                      label="Tipo de Documento"
                                      id="documentType"
                                      name="tipo_documento"
                                      options={[
                                        { value: 'CH', label: 'Cheque' },
                                        { value: 'DM', label: 'Duplicata' },
                                        { value: 'DS', label: 'Duplicata Serviço' }
                                      ]}
                                      disabled={isSubmitting}
                                      component={Select}
                                      autoComplete="off"
                                      placeholder="-- Selecione"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Label>Período Inclusão</Label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-xl-6 col-lg-12 col-md-6 col-12 col-data-inicio">
                                    <FastField
                                      name="data_inicio"
                                      id="start"
                                      disableWeekend={false}
                                      disabled={isSubmitting}
                                      component={DateTimePicker}
                                      autoComplete="off"
                                    />
                                  </div>
                                  <div className="col-xl-6 col-lg-12 col-md-6 col-12 col-data-final">
                                    <FastField
                                      name="data_final"
                                      id="end"
                                      disableWeekend={false}
                                      disabled={isSubmitting}
                                      component={DateTimePicker}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Tipo de Empresa"
                                      id="companyType"
                                      name="tipo_empresa"
                                      options={tipoEmpresas}
                                      component={Select}
                                      disabled={isSubmitting}
                                      placeholder="-- Selecione"
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Tipo de Operação"
                                      id="operationType"
                                      name="tipo_operacao"
                                      options={tipoOperacoes}
                                      component={Select}
                                      disabled={isSubmitting}
                                      placeholder="-- Selecione"
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <FastField
                                      type="text"
                                      helper="(Entrada1, Entrada2, …)"
                                      label="Cód. Entrada"
                                      name="entrada"
                                      id="entradas"
                                      disabled={isSubmitting}
                                      component={Input}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <FastField
                                      type="text"
                                      helper="(Borderô1, Borderô2, …)"
                                      label="Borderôs"
                                      name="bordero"
                                      id="borderos"
                                      disabled={isSubmitting}
                                      component={Input}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <FastField
                                      type="text"
                                      helper="(Documento1, Documento2, …)"
                                      label="Documentos"
                                      name="documento"
                                      id="Documentos"
                                      disabled={isSubmitting}
                                      component={Input}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            </SkeletonLoader>
                          </div>
                          <div className="bottom">
                            <Button color="primary" type="submit" block disabled={isPending}>
                              Filtrar
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          );
        }}
      />

      <Modal isOpen={modal.show} toggle={() => toggle(null)} centered size="sm" className="delete-modal">
        <ModalHeader toggle={() => toggle(null)}>Excluir Borderô</ModalHeader>
        <ModalBody>Você tem certeza que deseja excluir este borderô?</ModalBody>
        <ModalFooter>
          <Button color="primary" size="md" className="mr-auto" outline onClick={() => toggle(null)}>
            Cancelar
          </Button>
          <Button color="primary" size="md" onClick={deleteBordero}>
            Excluir
          </Button>
        </ModalFooter>
      </Modal>

      {modalBorderoVisualizaDocumento && modalBorderoVisualizaDocumento.show && (
        <ModalBorderoVisualizaDocumento
          modal={modalBorderoVisualizaDocumento}
          toggle={toggleBorderoVisualizaDocumento}
          message="borderoVisualizarDocumento"
          setMessage={setMessage}
        />
      )}
      <HistoricoAcesso codTela={1174} />
      <Wootric />
    </Main>
  );
};

export default Borderos;
