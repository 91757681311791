import { Grid, Typography } from '@mui/material';
import React from 'react';
import { exibeValorLocalizado, mascararCpfCnpj } from '../../../../shared';

const definicaoDeColunas = modalOperVop => {
  let config = [
    {
      field: 'nomeCliente',
      headerName: 'Cliente',
      flex: 1,
      minWidth: 200,
      headerClassName: 'data-grid-header-centralizado-esquerda font-bold-header',
      cellClassName: 'data-grid-light-gray',
      renderCell: params => {
        return (
          <Grid container>
            <Grid item xs={12} className="texto-left" title={params.value}>
              <Typography className="texto-nowrap-overflow-dotted">{params.value}</Typography>
            </Grid>
            {params.row.cnpjCliente && (
              <Grid item xs={12} title={mascararCpfCnpj(params.row.cnpjCliente)}>
                <Typography
                  variant="subtitle1"
                  className="texto-nowrap-overflow-dotted font-size-0-8rem texto-cinza-medio"
                >
                  {mascararCpfCnpj(params.row.cnpjCliente)}
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      }
    },
    {
      field: 'tipoOper',
      headerName: 'Tipo Operação',
      flex: 1,
      minWidth: 180,
      maxWidth: 200,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray camel-case'
    }
  ];

  if (modalOperVop.statusAnalise === 'I') {
    config.push({
      field: 'motivoIndeferimento',
      headerName: 'Motivo Indeferimento',
      flex: 1,
      minWidth: 205,
      maxWidth: 215,
      headerClassName: 'data-grid-header-centralizado font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-gray camel-case'
    });
  }

  config.push({
    field: 'valorFace',
    headerName: 'Valor de Face',
    flex: 1,
    minWidth: 180,
    maxWidth: 200,
    headerClassName:
      'data-grid-header-centralizado-direita font-bold-header data-grid-header-flow-reverse',
    cellClassName: 'items-centralizados-direita data-grid-light-gray',
    renderCell: params => (
      <>{params.value ? `R$ ${exibeValorLocalizado(parseFloat(params.value), 2, 2)}` : '---'}</>
    ),
    sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2)
  });

  return config;
};

export default definicaoDeColunas;
