import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState, useEffect, memo } from 'react';
import { FastField, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { getCondicaoOperacionalVencimentoTitulo } from '../../../../../api/borderos';
import { CurrencyInput, DateTimePicker, SkeletonLoader } from '../../../../../shared';
import { unmaskValue, moneyFullFormat, dateFormat } from '../../../../../utils/format';

const initialValues = {
  valorParaRecebimento: '',
  dataParaPagamento: ''
};

const validationSchema = regras => {
  return Yup.object().shape({
    valorParaRecebimento: Yup.string()
      .nullable()
      .required('Informe o valor para recebimento.')
      .test('valor', `Deve ser maior que ${moneyFullFormat(regras.valorMimimo)}`, value => {
        const valor = unmaskValue(value);
        if (valor < regras.valorMimimo) {
          return false;
        }
        return true;
      }),
    /**Comentado para não verificar valor maximo do GR - Alteração feita em 31/10/2019 - André*/
    /*.test('valor', `Deve ser menor que ${moneyFullFormat(regras.valorMaximo)}`, value => {
        const valor = unmaskValue(value);
        if (valor > regras.valorMaximo) {
          return false;
        }
        return true;
      }),*/
    dataParaPagamento: Yup.string()
      .nullable()
      .required('Informe a data para pagamento.')
      .test('Data', 'Data inválida', value => {
        if (value) {
          return moment(value, 'DD/MM/YYYY').isValid();
        }
        return true;
      })
      .test(
        'vencimento_entre',
        `Deve estar entre ${dateFormat(regras.dataMinima)} e ${dateFormat(regras.dataMaxima)}`,
        value => {
          const current = moment(value, 'DD/MM/YYYY');

          if (!current.isValid()) {
            return true;
          }

          const { dataMaxima, dataMinima } = regras;
          let validRange = true;
          if (dataMaxima) {
            const before = moment(dataMinima);
            const after = moment(dataMaxima);
            // REF: https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
            validRange = current.isBetween(before, after, null, '[]');
          }
          return validRange;
        }
      )
      .test('vencimento_bloqueado', 'Feriado(s) não são permitidos', value => {
        const current = moment(value, 'DD/MM/YYYY');

        if (!current.isValid()) {
          return true;
        }

        const { datasBloqueadas } = regras;
        // const workingDays = [1, 2, 3, 4, 5];
        let isHoliday = true;
        let isWeekend = true;
        // isWeekend = workingDays.includes(current.day());

        if (datasBloqueadas) {
          const currentDateISO = current.format(moment.HTML5_FMT.DATE);
          isHoliday = !datasBloqueadas.includes(currentDateISO);
        }
        return isHoliday && isWeekend;
      })
  });
};

const ModalGiroRapido = props => {
  const { modal, toggle, saveBordero, bordero } = props;

  const [message, setMessage] = useState();
  const [isLocalPending, setIsLocalPending] = useState(false);
  const [regras, setRegras] = useState({});

  useEffect(() => {
    if (
      !regras.dataMinima &&
      !isLocalPending &&
      bordero &&
      bordero.tipoOperacao &&
      bordero.tipoTitulo &&
      bordero.tipoEmpresa
    ) {
      setIsLocalPending(true);
      getCondicaoOperacionalVencimentoTitulo(
        bordero.tipoOperacao.code,
        bordero.tipoTitulo.code,
        bordero.tipoEmpresa.code
      )
        .then(res => {
          setRegras(res.data);
        })
        .finally(() => {
          setIsLocalPending(false);
        });
    }
  }, []);

  const handleFormSubmit = (values, form) => {
    // TODO: Validar api pra aceitar nao receber esse parametro no caso de GIRO-RAPIDO
    // const tipoTitulo = {
    //   code: '00',
    //   descricao: 'NENHUM'
    // };

    const giroRapido = {
      valorParaRecebimento: unmaskValue(values.valorParaRecebimento),
      dataParaPagamento: moment(values.dataParaPagamento, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
    };

    const newBordero = {
      // tipoTitulo,
      giroRapido
    };

    saveBordero(newBordero, form)
      .then(() => {
        toggle();
      })
      .catch(err => {
        const {
          response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
        } = err;
        setMessage(message);
        form.setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className="modalGiroRapido" size="md" backdrop="static">
      <Formik
        validationSchema={validationSchema(regras)}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        render={({ isSubmitting }) => {
          return (
            <Form>
              <ModalHeader toggle={toggle}>Giro Rápido</ModalHeader>
              <ModalBody>
                {message && (
                  <div className="alert alert-danger animated fadeIn" role="alert">
                    {message}
                  </div>
                )}
                <SkeletonLoader isPending={isLocalPending} width="60%" height="30px" count={4}>
                  <FastField
                    type="text"
                    label="Valor para recebimento"
                    labelClassName="required-label"
                    name="valorParaRecebimento"
                    id="valorParaRecebimento"
                    disabled={isSubmitting || isLocalPending}
                    autoComplete="off"
                    component={CurrencyInput}
                  />
                  <FastField
                    label="Data para pagamento"
                    name="dataParaPagamento"
                    disabled={isSubmitting || isLocalPending}
                    rules={regras}
                    autoComplete="off"
                    component={DateTimePicker}
                  />
                </SkeletonLoader>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  size="md"
                  className="mr-auto"
                  outline
                  disabled={isSubmitting || isLocalPending}
                  onClick={toggle}
                >
                  Fechar
                </Button>
                <Button
                  color="primary"
                  size="md"
                  type="submit"
                  disabled={isSubmitting || isLocalPending}
                >
                  Confirmar e Finalizar
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default memo(ModalGiroRapido);
