import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import ButtonMain from '../../../shared/ButtonMain';
import { getListaSocios, avancarEstadoPropostaContrato } from '../../../../../api/onboarding';

import FormularioSocio from './FormularioSocio/FormularioSocio';
import LoaderMain from '../../../shared/LoaderMain';
import AlertErroMain from '../../../shared/AlertErroMain';
import { finalizaPropostaOne7MaisCredito } from '../../../../../api/one7MaisCredito';
import FormularioJuridico from './FormularioJuridico/FormularioJuridico';

export default ({
  avancarEtapa,
  isPending,
  setPending,
  cnpjEmpresa,
  codEntrada = null,
  origemProposta = 'digital'
}) => {
  const [liberarBotaoContinuar, setliberarBotaoContinuar] = useState(false);
  const [cpfSocioAberto, setCpfSocioAberto] = useState(false);
  const [isPendingSocios, setPendingSocios] = useState(false);
  const [listaSocios, setListaSocios] = useState([]);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mostraErro, setMostraErro] = useState(false);

  useEffect(() => {
    setPendingSocios(true);
    getListaSocios(cnpjEmpresa, origemProposta, codEntrada)
      .then(resp => {
        let dadosListaSocios = resp.data;
        let listaSociosTemp = [];
        let liberarBtnFinalizar = true;

        dadosListaSocios.map(soc => {
          if (soc.DOCSENVIADOS <= 0 && origemProposta !== 'connect' && origemProposta !== 'indicantes') {
            if (
              liberarBtnFinalizar &&
              origemProposta === 'one7_mais_credito' &&
              soc.CPFCNPJ.length > 11
            ) {
              liberarBtnFinalizar = true;
            } else {
              liberarBtnFinalizar = false;
            }
          }
          soc.documentos_enviados = soc.DOCSENVIADOS > 0 ? 'S' : 'N';
          soc.cpf_cnpj = soc.CPFCNPJ;
          soc.cpf_conjuge = soc.CPFCNPJ_CONJUGE;
          soc.data_nascimento = soc.DATANASCIMENTO;
          soc.data_nascimento =
            soc.DATANASCIMENTO !== ''
              ? moment(soc.DATANASCIMENTO, moment.HTML5_FMT.DATE).format('DD/MM/YYYY')
              : '';
          soc.email = soc.EMAIL ? soc.EMAIL : '';
          soc.estado_civil = soc.ESTADOCIVIL;
          soc.nome = soc.NOME;
          soc.nome_conjuge = soc.NOME_CONJUGE;
          soc.pessoa_politicamente_exposta = soc.POLITICAMENTEEXPOSTA;
          soc.regime_bens = soc.REGIMECASAMENTO;
          soc.celular = soc.TELEFONE ? soc.TELEFONE : '';
          soc.tipo = soc.TIPO;

          if (listaSociosTemp[soc.CPFCNPJ] === undefined) {
            if (soc.TIPO === 'SOCIO') {
              soc.socio = 'S';
            } else if (soc.TIPO === 'RESPONSAVEL_SOLIDARIO') {
              soc.responsavel = 'S';
            } else if (soc.TIPO === 'VINCULO') {
              soc.vinculo = 'S';
            }
            listaSociosTemp[soc.CPFCNPJ] = soc;
          } else {
            if (soc.TIPO === 'SOCIO') {
              listaSociosTemp[soc.CPFCNPJ].socio = 'S';
            } else if (soc.TIPO === 'RESPONSAVEL_SOLIDARIO') {
              listaSociosTemp[soc.CPFCNPJ].responsavel = 'S';
            } else if (soc.TIPO === 'VINCULO') {
              listaSociosTemp[soc.CPFCNPJ].vinculo = 'S';
            }
          }
          return soc;
        });

        setliberarBotaoContinuar(liberarBtnFinalizar);

        setListaSocios(Object.values(listaSociosTemp));
      })
      .finally(() => {
        setPendingSocios(false);
      });
  }, []);

  const continuarTela = () => {
    if (origemProposta === 'one7_mais_credito') {
      setPendingSocios(true);
      let params = {
        cnpj_empresa: cnpjEmpresa,
        cod_entrada: codEntrada
      };
      finalizaPropostaOne7MaisCredito(params)
        .then(() => {
          avancarEtapa();
        })
        .catch(err => {
          setMostraErro(err.response);
        })
        .finally(() => {
          setPendingSocios(false);
        });
    } else {
      setPending(true);
      let params = {
        cnpj_empresa: cnpjEmpresa,
        origem_proposta: origemProposta,
        estado_atual: 'documentos_socios'
      };
      avancarEstadoPropostaContrato(params)
        .then(() => {
          avancarEtapa();
        })
        .catch(err => {
          setMostraErro(err.response);
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {cpfSocioAberto === false ? (
          <Box
            className="mt-4 box-documentos-sociedade"
            sx={isPendingSocios ? { position: 'relative', p: '25px 0 0 0', minHeight: '150px' } : {}}
          >
            {isPendingSocios && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}

            {listaSocios.length > 0 ? (
              listaSocios.map(socio => (
                <Box key={socio.cpf_cnpj} className="mt-4 margin-bottom-15">
                  <Grid container className="items-centro-verticalmente">
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Grid container sx={{ p: '0 0 0 10px' }}>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className="texto-centro">
                          {(socio.socio || socio.responsavel || socio.vinculo) && (
                            <Tooltip
                              arrow
                              title={
                                socio.SOCIO_RESPONSAVEL
                                  ? 'Sócio e Responsável solidário'
                                  : socio.socio === 'S'
                                  ? 'Sócio'
                                  : socio.responsavel === 'S'
                                  ? 'Responsável solidário'
                                  : socio.vinculo === 'S'
                                  ? 'Vínculo'
                                  : ''
                              }
                            >
                              <InfoOutlinedIcon sx={{ margin: '5px 0 0 5px' }} />
                            </Tooltip>
                          )}
                        </Grid>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                          <Typography variant="subtitle1" className="pad-0 mt-1 w-100">
                            <Tooltip
                              arrow
                              placement={onlySmallScreen ? 'bottom' : 'right'}
                              title={
                                socio.documentos_enviados === 'S'
                                  ? 'Documentos já enviados, não é possível alterar.'
                                  : 'Clique aqui para enviar.'
                              }
                            >
                              <span>
                                <button
                                  type="button"
                                  className={`
                                  ${
                                    onlySmallScreen
                                      ? 'link-button-sociedade ajuste-font margin-left-7 ajust-width'
                                      : 'link-button-sociedade'
                                  }
                                  ${socio.documentos_enviados === 'S' ? '' : ' font-bold '}
                                `}
                                  disabled={socio.documentos_enviados === 'S'}
                                  onClick={() => setCpfSocioAberto(socio.cpf_cnpj)}
                                >
                                  <div className="nome-sociedade">{socio.nome}</div>
                                </button>
                              </span>
                            </Tooltip>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Grid container sx={{ p: '0 0 0 10px' }}>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className={onlySmallScreen ? 'mt-1 texto-esquerda' : 'mt-1 texto-centro'}
                        >
                          {socio.documentos_enviados === 'S' ? (
                            <div className="situacao-documento-socio-aprovado">
                              <CheckCircleIcon className="texto-success icone-situacao-socio" />
                              <Typography
                                variant="subtitle1"
                                sx={{ marginTop: '2px' }}
                                className={
                                  onlySmallScreen
                                    ? 'pad-0 font-size-875 texto-cor-principal'
                                    : 'pad-0 texto-cor-principal'
                                }
                              >
                                Documentação enviada
                              </Typography>
                            </div>
                          ) : socio.cpf_cnpj.length > 11 ||
                            origemProposta === 'connect' ||
                            origemProposta === 'indicantes' ? (
                            // eslint-disable-next-line react/jsx-indent
                            <div className="situacao-documento-pj-pendente">
                              <ErrorIcon className="texto-info icone-situacao-socio " />
                              <Typography
                                variant="subtitle1"
                                sx={{ marginTop: '2px' }}
                                className={
                                  onlySmallScreen
                                    ? 'pad-0 font-size-875 texto-cor-principal'
                                    : 'pad-0 texto-cor-principal'
                                }
                              >
                                Documentação pendente
                              </Typography>
                            </div>
                          ) : (
                            <div className="situacao-documento-socio-pendente">
                              <ErrorIcon className="texto-warning icone-situacao-socio " />
                              <Typography
                                variant="subtitle1"
                                sx={{ marginTop: '2px' }}
                                className={
                                  onlySmallScreen
                                    ? 'pad-0 font-size-875 texto-cor-principal'
                                    : 'pad-0 texto-cor-principal'
                                }
                              >
                                Documentação pendente
                              </Typography>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ))
            ) : (
              <Box className="mt-4 margin-bottom-15">
                <Box className="items-centro-verticalmente" sx={{ p: '0 0 0 10px' }}>
                  <Typography variant="subtitle1" className="pad-0 mt-1">
                    Nenhum Sócio Cadastrado
                  </Typography>
                </Box>
              </Box>
            )}

            <Grid container className="label-icones-sociedade">
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <ErrorIcon className="texto-info icone-situacao-socio" />
                Envio não obrigatório
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <ErrorIcon className="texto-warning icone-situacao-socio" />
                Envio obrigatório
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <CheckCircleIcon className="texto-success icone-situacao-socio" />
                Envio realizado
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            {origemProposta === 'one7_mais_credito' && cpfSocioAberto.length > 11 ? (
              <FormularioJuridico
                cpfSocioAberto={cpfSocioAberto}
                setCpfSocioAberto={setCpfSocioAberto}
                listaSocios={listaSocios}
                setListaSocios={setListaSocios}
                isPending={isPending}
                setPending={setPending}
                setliberarBotaoContinuar={setliberarBotaoContinuar}
                cnpjEmpresa={cnpjEmpresa}
                codEntrada={codEntrada}
                origemProposta={origemProposta}
              />
            ) : (
              <FormularioSocio
                cpfSocioAberto={cpfSocioAberto}
                setCpfSocioAberto={setCpfSocioAberto}
                listaSocios={listaSocios}
                setListaSocios={setListaSocios}
                isPending={isPending}
                setPending={setPending}
                setliberarBotaoContinuar={setliberarBotaoContinuar}
                cnpjEmpresa={cnpjEmpresa}
                codEntrada={codEntrada}
                origemProposta={origemProposta}
              />
            )}
          </>
        )}

        {liberarBotaoContinuar && cpfSocioAberto === false && (
          <>
            <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

            <Grid container className="mt-3" spacing={3}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
              />
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
              >
                <center>
                  <ButtonMain
                    tipoBotao="principal"
                    disabled={isPendingSocios || isPending}
                    onClick={() => continuarTela()}
                  >
                    {origemProposta === 'one7_mais_credito' ? 'Finalizar' : 'Continuar'}
                  </ButtonMain>
                </center>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
