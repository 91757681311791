import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { Form, Formik, FastField } from 'formik';
import * as Yup from 'yup';

import VoltarIcon from '../../../../shared/icons/Voltar';

import Avancar from '../../../../shared/icons/Avancar';

import { SkeletonLoader, Input } from '../../../../shared';
import { moneyFullFormat, dateFormat } from '../../../../utils/format';

const validationSchema = Yup.object().shape({
  motivo: Yup.string()
    .nullable()
    .required('Campo obrigatório')
    .test('motivo', 'Campo obrigatório', value => {
      return value && value.toString().replace(/\s/g, '') !== '';
    })
});

const Baixa = props => {
  const {
    nextStep,
    previousStep,
    isPending,
    setIsPending,
    instrucao,
    setInstrucao,
    finishStep,
    isConfirmation,
    collapseMenuRight,
    setCollapseMenuRight
  } = props;

  const [itensCount, setItensCount] = useState(0);
  const [message, setMessage] = useState();

  useEffect(() => {
    if (!itensCount) {
      setIsPending(false);
      setItensCount(instrucao.baixa.length);

      const valorTotal =
        instrucao.baixa &&
        instrucao.baixa.length &&
        instrucao.baixa
          .map(item => {
            if (item.titulo.duplicata && item.titulo.duplicata.valor) {
              return item.titulo.duplicata.valor;
            } else if (item.titulo.cheque && item.titulo.cheque.valor) {
              return item.titulo.cheque.valor;
            }
            return 0;
          })
          .reduce((acc, curr) => acc + curr);

      const newInstrucao = {
        ...instrucao,
        valor: valorTotal
      };

      setInstrucao(newInstrucao);
    }
  }, [instrucao.baixa.length]);

  const initialValues = {
    ...instrucao,
    motivo: instrucao.motivo || ''
  };

  const handleFormSubmit = ({ motivo }) => {
    const newInstrucao = {
      ...instrucao,
      motivo
    };
    setInstrucao(newInstrucao);
    nextStep();
  };

  const handleConfirmationFormSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    finishStep(values)
      .catch(err => {
        const {
          response: { data: { error: { message = 'Erro inesperado ao salvar' } = {} } = {} } = {}
        } = err;
        setMessage(message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleValidate = () => {
    setInterval(() => {
      var errors = document.getElementsByClassName('is-invalid');
      if (errors && errors[0] && errors[0].focus) {
        setMessage('Existem campos com erros. Verifique-os para continuar.');
      } else {
        setMessage('');
      }
    }, 500);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      validate={handleValidate}
      render={({ values, isSubmitting, setSubmitting }) => {
        return (
          <Form className={isPending || isSubmitting ? 'is-submitting' : ''}>
            <div className="row">
              <div
                className={
                  (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                  ' col-12 content-body'
                }
              >
                <div className="body">
                  <h1 className="title">
                    {isConfirmation && 'Confirmação dos '}
                    Dados de Baixa
                  </h1>
                  {message && (
                    <div className="alert alert-danger animated fadeIn" role="alert">
                      {message}
                    </div>
                  )}
                  <SkeletonLoader
                    isPending={isPending}
                    width="100%"
                    height="40px"
                    count={8}
                    widthRandomness={0}
                  >
                    <div className="content-instrucoes--dados">
                      <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-8 col-10">
                          {isConfirmation ? (
                            <div className="form-group">
                              <Label>Motivo</Label>
                              <div className="form-control-static">{instrucao.motivo}</div>
                            </div>
                          ) : (
                            <FastField
                              type="text"
                              name="motivo"
                              label="Motivo"
                              labelClassName="required-label "
                              value={values.motivo}
                              component={Input}
                              disabled={isPending || isSubmitting}
                              autoComplete="off"
                            />
                          )}
                        </div>
                      </div>
                      {isConfirmation && (
                        <table className="table table-hover table-recompra-titulos">
                          <thead>
                            <tr>
                              <th>SACADO</th>
                              <th>Nº DO DOC</th>
                              <th>VENCIMENTO</th>
                              <th>VALOR BRUTO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values &&
                              values.baixa.map((baixa, index) => {
                                const nome =
                                  (baixa.titulo.sacado.fisica || {}).nome ||
                                  (baixa.titulo.sacado.juridica || {}).razaoSocial ||
                                  (baixa.titulo.sacado.juridica || {}).nomeFantasia;
                                const vencimento = (baixa.titulo.cheque || baixa.titulo.duplicata)
                                  .vencimento;
                                const valor = (baixa.titulo.cheque || baixa.titulo.duplicata).valor;
                                const ndoc = (baixa.titulo.cheque || baixa.titulo.duplicata).ndoc || '';
                                return (
                                  <tr
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${baixa.titulo.id}_${index}`}
                                    className={`animated ${
                                      baixa.titulo.meta.disabled ? ' disabled' : ' enabled'
                                    } ${baixa.titulo.meta.visible ? ' fadeIn' : '  d-none'}`}
                                  >
                                    <td>{nome}</td>
                                    <th scope="row">{ndoc}</th>
                                    <td>{dateFormat(vencimento)}</td>
                                    <td>{moneyFullFormat(valor)}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </SkeletonLoader>
                </div>
                <div className="footer d-flex justify-content-between">
                  <Button
                    size="lg"
                    outline={true}
                    color="primary"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      previousStep();
                    }}
                  >
                    <i className="svg-icon">
                      <VoltarIcon />
                    </i>
                    Voltar
                  </Button>

                  {isConfirmation ? (
                    <Button
                      size="lg"
                      color="primary"
                      type="button"
                      disabled={isPending || isSubmitting}
                      onClick={() => {
                        handleConfirmationFormSubmit(values, setSubmitting);
                      }}
                    >
                      <i className="svg-icon">
                        <Avancar />
                      </i>
                      Finalizar
                    </Button>
                  ) : (
                    <Button size="lg" color="primary" type="submit" disabled={isPending || isSubmitting}>
                      <i className="svg-icon">
                        <Avancar />
                      </i>
                      Avançar
                    </Button>
                  )}
                </div>
              </div>
              {!collapseMenuRight && (
                <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                  <div className="fixed-side">
                    <div
                      role="button"
                      className="header cursor-pointer"
                      onClick={() => setCollapseMenuRight(true)}
                      onKeyPress={() => setCollapseMenuRight(true)}
                      tabIndex={0}
                    >
                      <h4 className="title">
                        Sua Instrução
                        <i className="fa fa-angle-right float-right" />
                      </h4>
                    </div>
                    <div className="info infoInstrucoes">
                      <FormGroup>
                        <Label>Tipo de Instrução:</Label>
                        <p className="form-control-static">{instrucao.tipoInstrucao.descricao}</p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Quantidade de Títulos Selecionados:</Label>
                        <p className="form-control-static">{itensCount}</p>
                      </FormGroup>
                      {instrucao.valor > 0 && (
                        <>
                          <Label>Custo:</Label>
                          <p className="form-control-static total-price">
                            {moneyFullFormat(instrucao.valor)}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default Baixa;
