import API from './api';

export const postCriarPpcInativa = dados => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/criar-ppc-inativa/`,
    dados
  );
};

export const getDadosCadastroEmpresa = cnpjEmpresa => {
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/empresas/${cnpjEmpresa}`);
};

export const getDadosCadastroEndereco = cnpjEmpresa => {
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/enderecos/${cnpjEmpresa}`);
};

export const getDadosCadastroSocio = cnpjEmpresa => {
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/socios/${cnpjEmpresa}`);
};

export const postCadastroDadosEmpresa = async dadosForm => {
  const response = await API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/empresas/${dadosForm.cnpj}`,
    dadosForm
  );

  return response;
};

export const postReservarProposta = dados => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/reservar-proposta`,
    dados
  );
};

export const postCadastroDadosEndereco = dadosForm => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/enderecos/${dadosForm.cnpj}`,
    dadosForm
  );
};

export const postCadastroDadosSocios = dadosForm => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/socios/${dadosForm.cnpj}`, dadosForm);
};

export const deleteDadosCadastroSocio = (cnpjEmpresa, cpfSocio) => {
  return API.delete(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/socios/${cnpjEmpresa}/socio/${cpfSocio}`
  );
};

export const getDadosCEP = cep => {
  return API.post(`${process.env.REACT_APP_API_AUTOMACAO_BASE_URL}/cep/consultarCep/${cep}`);
};

export const postSalvarCadastroEmpresa = todosDadosCadastrais => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/filas/empresa-cadastro`,
    todosDadosCadastrais
  );
};

export const postSalvarCadastroEmpresaConnect = todosDadosCadastrais => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/iniciar-proposta`,
    todosDadosCadastrais
  );
};

export const postCadastroLogin = dados => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios`, dados);
};

export const validarTokenBroker = dados => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/valida-token-broker`, dados);
};

export const validarTokenPlataforma = parametros => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/valida-token-plataforma`,
    parametros
  );
};

export const validarTokenUsuario = parametros => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/valida-token-usuario`,
    parametros
  );
};

export const postVerificaTokenCadastro = dados => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/valida-token`, dados);
};

export const postReenviaTokenCadastro = dados => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/reenvia-token`, dados);
};

export const getDadosProdutos = cnpjEmpresa => {
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/comercial/produtos/${cnpjEmpresa}`
  );
};

export const getDadosGarantiaMinima = params => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/oferta/garantia-minima`,
    params
  );
};

export const enviaAceiteProposta = params => {
  return API.post(`${process.env.REACT_APP_API_OPERACAO_BASE_URL}/aceite-propostas`, params);
};

export const mudaStatusContrato = params => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/alterar-contrato-estado`,
    params
  );
};

export const avancarEstadoPropostaContrato = params => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/avancar-estado-proposta-contrato`,
    params
  );
};

export const getDadosSimulacaoResumo = params => {
  // return API.post(`${process.env.REACT_APP_API_BANK_SERVICES_BASE_URL}/divida/simular-qitech`, params);
  return API.post(`${process.env.REACT_APP_API_BANK_SERVICES_BASE_URL}/divida/simulador-hbi`, params);
};

export const getDocumentosGarantia = cnpjEmpresa => {
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/garantias/documentos-requeridos/${cnpjEmpresa}`
  );
};

export const uploadDocumentoPadrao = (dataFile, cnpjEmpresa, progressFunction) => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/documentos/upload/${cnpjEmpresa}`,
    dataFile,
    {
      onUploadProgress: function(progressEvent) {
        progressFunction(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
      }
    }
  );
};

export const deletarDocumentoPadrao = hashArquivo => {
  return API.delete(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/documentos/upload/${hashArquivo}`);
};

export const postConfirmarDadosGarantia = (cnpjEmpresa, tipoPessoa, dadosGarantia) => {
  var rotaTipoPessoa = tipoPessoa === 'PF' ? 'fisica' : 'juridica';
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/garantias/pessoa-${rotaTipoPessoa}/${cnpjEmpresa}`,
    dadosGarantia
  );
};

export const postCadastrarGarantia = dadosGarantia => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/garantias/cadastrar-garantias`,
    dadosGarantia
  );
};

export const postConfirmarDocumentosGarantia = dadosDocumentosGarantia => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/documentos/identifica-garantia`,
    dadosDocumentosGarantia
  );
};

export const getListaTiposEmpresa = () => {
  return API.get(`${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/tipo-empresas`);
};

export const getDocumentosRequeridos = (tipoDocumento, tipoOrigemProp = 'digital') => {
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/documentos/documento-requerido/${tipoOrigemProp}/${tipoDocumento}`
  );
};

export const postConfirmarDocumentos = params => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/documentos/identificar-documento`,
    params
  );
};

export const postEnviarDocumento = params => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/documentos/enviar-documento`,
    params
  );
};

export const postExcluirDocumento = params => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/documentos/excluir-documento`,
    params
  );
};

export const postAlterarDadosSocios = dadosForm => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/pessoas/alterar`, dadosForm);
};

export const getListaSocios = (cnpjEmpresa, origemProposta, codEntrada) => {
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/comercial/socios/${cnpjEmpresa}`,
    {
      params: { origem_proposta: origemProposta, cod_entrada: codEntrada }
    }
  );
};

export const getDocumentosEnviadosCliente = params => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/documentos/status-documentos`, params);
};

export const getListaTiposDocumentos = tipo => {
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/documentos/tipos-documentos`,
    {
      params: { cliente_arquivos_tipos: tipo }
    }
  );
};

export const getListaBancos = () => {
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/bancos`);
};

export const getDadosBancarios = cnpjEmpresa => {
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/contas-correntes/${cnpjEmpresa}`);
};

export const postDadosBancarios = values => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/contas-correntes/${values.cnpj}`,
    values
  );
};

export const postDeletarDadosBancarios = cnpjEmpresa => {
  return API.delete(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/contas-correntes/${cnpjEmpresa}`);
};

export const postConfirmarDadosBancarios = values => {
  return API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/bancos/conta`, values);
};

export const postDadosEmpresaReceitaFederal = values => {
  return API.post(`${process.env.REACT_APP_API_AUTOMACAO_BASE_URL}/receita/consultarEmpresa`, values);
};

export const postValidaEntradaJornadaOnboarding = values => {
  return API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/clientes/valida-entrada-jornada-onboarding`,
    values
  );
};

export const getCodigoProposta = cnpjEmpresa => {
  return API.get(`${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/${cnpjEmpresa}`);
};

export const getEnvioProposta = cnpjEmpresa => {
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/proposta-enviada/${cnpjEmpresa}`);
};

export const postInsereParecerFinal = values => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/comercial/cadastrar-parecer-final`,
    values
  );
};

export const postCadastrarPerfilCarteira = values => {
  return API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/comercial/cadastrar-perfil-carteira`,
    values
  );
};

export const getDadosPerfilCarteira = cnpjEmpresa => {
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/comercial/perfil-carteira/${cnpjEmpresa}`
  );
};

export const getListaBrokersEntradaProposta = () => {
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/comercial/brokers-entrada/listar`
  );
};
