import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import ButtonMain from '../../../shared/ButtonMain';
import { getDadosProdutos, mudaStatusContrato } from '../../../../../api/onboarding';
import LoaderMain from '../../../shared/LoaderMain';

export default ({
  avancarEtapa,
  mudaTelaSolucoes,
  cnpjEmpresa,
  produtoEscolhido,
  setProdutoEscolhido,
  entradaOnboarding,
  origemProposta = 'digital'
}) => {
  const [listaProdutos, setListaProdutos] = useState([]);
  const [controleMobile, setcontroleMobile] = useState('');
  const [isPending, setPending] = useState(false);
  const [semProdutos, setSemProdutos] = useState(false);
  const [codPPC, setCodPPC] = useState(false);

  useEffect(() => {
    setPending(true);
    let dadosTemp = [];
    getDadosProdutos(cnpjEmpresa)
      .then(dadosResp => {
        let dados = dadosResp.data;
        if (dados && dados.produto.length > 0 && dados.produto !== undefined) {
          setCodPPC(dados.cod_ppc);

          dados.produto.map(prod => {
            prod.finalizar = true;
            if (prod.sigla === 'GP') {
              prod.garantias.map(gar => {
                gar.finalizar = false;
                dadosTemp.push(gar);
                return gar;
              });
            } else {
              // Demais produtos somente pela entrada onBoarding
              if (entradaOnboarding) {
                dadosTemp.push(prod);
              }
            }
            return prod;
          });

          setListaProdutos(dadosTemp);
          setcontroleMobile('controle-mobile');
        } else {
          setcontroleMobile('');
          setListaProdutos([]);
        }
      })
      .catch(() => {
        setSemProdutos(true);
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  const escolherProduto = dadosProduto => {
    setProdutoEscolhido(dadosProduto);
    setTimeout(function() {
      if (dadosProduto.finalizar && dadosProduto.garantias && dadosProduto.garantias.length === 0) {
        let params = {
          cod_proposta: codPPC,
          estado_ppc: 'ACD'
        };
        mudaStatusContrato(params)
          .then(() => {
            avancarEtapa();
          })
          .finally(() => {
            setPending(false);
          });
      } else {
        mudaTelaSolucoes('Simulacao');
      }
    }, 1000);
  };

  return (
    <Grid container>
      <Grid item xl={2} lg={2} md={2} sm={1} xs={1} />
      <Grid item xl={8} lg={8} md={8} sm={10} xs={10}>
        <Typography variant="h5" className="mt-3 texto-cor-principal texto-negrito texto-centro">
          Escolha a melhor solução para a sua empresa
        </Typography>

        <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
          Disponibilizamos a opção de crédito recomendada para o seu negócio
        </Typography>
      </Grid>

      <div className={controleMobile + ' mt-5 card-produtos-pai'}>
        <Box
          sx={
            isPending
              ? { position: 'relative', p: '25px 0 0 0', minHeight: '100px', marginLeft: '-45px' }
              : {}
          }
        >
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        </Box>

        {listaProdutos.map(dados => {
          return (
            <Card
              key={dados.id}
              id={'cardProduto' + dados.id}
              className={`card-produto-solucoes ${
                produtoEscolhido.id === dados.id ? 'produtoSelecionado' : ''
              }`}
            >
              <Grid container>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Typography
                    variant="subtitle1"
                    className="mt-3 texto-cor-complementar texto-semi-negrito texto-centro nomeProduto"
                  >
                    {dados.nome}
                  </Typography>

                  <div className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro font-size-075">
                    Valor pré-aprovado*
                  </div>

                  <Typography variant="subtitle1" className="texto-negrito texto-centro">
                    <span className="texto-cor-principal margin-neg-left-2">R$ </span>
                    <span className="texto-cor-principal margin-neg-left-2">
                      {parseFloat(dados.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                    </span>
                    {dados.taxa && dados.taxa !== null && dados.taxa !== undefined && dados.taxa > 0 && (
                      <>
                        <span className="texto-cor-principal font-size-075 texto-weight-600">
                          &nbsp;/&nbsp;
                        </span>
                        <span className="texto-cor-principal font-size-075 texto-weight-600">
                          {parseFloat(dados.taxa).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                        </span>
                        <span className="texto-cor-principal font-size-075 texto-weight-600">
                          %&nbsp;mês
                        </span>
                      </>
                    )}
                  </Typography>

                  <Grid container className="mt-2">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ minHeight: '345px' }}>
                      <div className="min-height-100">
                        <span className="font-size-875 texto-cinza-medio">{dados.descricao[0]}</span>
                      </div>

                      {dados.descricao.map(
                        (msg, idx) =>
                          idx !== 0 && (
                            <Grid key={idx++} container className="mt-2 font-size-875 texto-cinza-medio">
                              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                <div className="mt-2">
                                  <span>•</span>
                                </div>
                              </Grid>
                              <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                <div className="mt-2">
                                  <span>{msg}</span>
                                </div>
                              </Grid>
                              <Grid item xl={1} lg={1} md={1} sm={1} xs={1} />
                            </Grid>
                          )
                      )}
                    </Grid>
                    <div className="mt-3">
                      <span className="font-size-875 texto-cinza-medio">
                        * Não é garantia definitiva de aprovação. Sujeito a tarifas
                      </span>
                    </div>
                    <ButtonMain
                      classesAdicional="mt-3 margin-bottom-15"
                      tipoBotao="transparente-com-bordas"
                      onClick={() => {
                        escolherProduto(dados);
                      }}
                      sx={{ p: '5px', fontSize: '14px!important' }}
                    >
                      Selecionar este produto
                    </ButtonMain>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          );
        })}

        {semProdutos && (
          <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
            Não foram encotrados produtos disponíveis
          </Typography>
        )}
      </div>

      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
            <span>Precisa de ajuda? </span>
            {origemProposta === 'one7_mais_credito' ? (
              <span>Contate seu gerente ou nosso suporte.</span>
            ) : (
              <span>
                Mande um e-mail para
                <a href="mailto:comercial@one7.com.vc"> comercial@one7.com.vc </a>
              </span>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
