import React, { useState, useEffect } from 'react';
import { Grid, Box, Tabs, Tab, Typography, useTheme, useMediaQuery } from '@mui/material';

// import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import DadosEmpresa from './DadosEmpresa/DadosEmpresa';
import DadosEndereco from './DadosEndereco/DadosEndereco';
import DadosSocios from './DadosSocios/DadosSocios';
import { faturamentosAnual } from '../../shared/Enums/EnumsOnboarding';
import {
  getDadosCadastroEmpresa,
  getDadosCadastroEndereco,
  getDadosCadastroSocio,
  postSalvarCadastroEmpresa,
  postSalvarCadastroEmpresaConnect
} from '../../../../api/onboarding';
import LoaderMain from '../../shared/LoaderMain';
import { getToken, getDadosUsuario } from '../../../../shared/services/User';
import AlertErroMain from '../../shared/AlertErroMain';

export default ({ avancarEtapa, cnpjEmpresa, origemProposta, isPending, setPending }) => {
  const [mostraErro, setMostraErro] = useState(false);
  const [isPendingCadastro, setPendingCadastro] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabDesc, setActiveTabDesc] = useState('DadosEmpresa');
  const [msgFinalizando, setMsgFinalizando] = useState(true);
  const [fingerPrint, setFingerPrint] = useState('');
  const [todosDadosCadastrais, setTodosDadosCadastrais] = useState({
    DadosEmpresa: {},
    DadosEndereco: {},
    DadosSocios: []
  });

  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // const [ipLocate, setIpLocate] = useState();

  // const getIpLocate = async () => {
  //   const geolocate = await axios.get('https://geolocation-db.com/json/', { timeout: 30000 });
  //   setIpLocate(geolocate);
  // };

  const getFingerPrint = async () => {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const resp = await fp.get();
    setFingerPrint(resp);
  };

  const mudarAba = tabDesc => {
    var tabsNumbers = {
      DadosEmpresa: 0,
      DadosEndereco: 1,
      DadosSocios: 2
    };
    if (typeof tabsNumbers[tabDesc] === 'undefined') return true;
    setActiveTab(tabsNumbers[tabDesc]);
    setActiveTabDesc(tabDesc);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // getIpLocate();
  }, [activeTab]);

  useEffect(() => {
    if (cnpjEmpresa === undefined || cnpjEmpresa === '' || cnpjEmpresa === null) return false;
    var dadosCadastrais = {
      cnpj: cnpjEmpresa,
      DadosEmpresa: {},
      DadosEndereco: {},
      DadosSocios: []
    };

    setPending(true);
    setPendingCadastro(true);
    getDadosCadastroEmpresa(cnpjEmpresa)
      .then(resEmp => {
        if (resEmp.data && resEmp.data.empresa) {
          dadosCadastrais.DadosEmpresa = resEmp.data.empresa;
          getDadosCadastroEndereco(cnpjEmpresa)
            .then(resEnd => {
              if (resEnd.data && resEnd.data.endereco) {
                dadosCadastrais.DadosEndereco = resEnd.data.endereco;
                getDadosCadastroSocio(cnpjEmpresa)
                  .then(resSocio => {
                    if (resSocio.data && resSocio.data.length > 0) {
                      dadosCadastrais.DadosSocios = resSocio.data.map(socio => {
                        return socio.socio;
                      });
                    }
                    setTodosDadosCadastrais(dadosCadastrais);
                    setPending(false);
                    setPendingCadastro(false);
                  })
                  .catch(err => {
                    setPending(false);
                    setPendingCadastro(false);
                    setMostraErro(err.response);
                  });
              } else {
                setTodosDadosCadastrais(dadosCadastrais);
                setPending(false);
                setPendingCadastro(false);
              }
            })
            .catch(err => {
              setPending(false);
              setPendingCadastro(false);
              setMostraErro(err.response);
            });
        } else {
          setTodosDadosCadastrais(dadosCadastrais);
          setPending(false);
          setPendingCadastro(false);
        }
      })
      .catch(err => {
        setPending(false);
        setPendingCadastro(false);
        setMostraErro(err.response);
      });
    getFingerPrint();
  }, []);

  const buscarDadosUsuario = () => {
    var dadosUser = getDadosUsuario();
    var token = getToken();

    var finger = '';
    if (fingerPrint.visitorId) {
      finger = {
        id: fingerPrint.visitorId,
        ...fingerPrint.components
      };
      finger = JSON.stringify(finger);
    }

    return {
      cpf: dadosUser.cpfcnpj ? dadosUser.cpfcnpj : '',
      // ip: ipLocate && ipLocate.data && ipLocate.data.IPv4 ? ipLocate.data.IPv4 : '',
      fingerprint: finger,
      session_id: 'MD5 => ' + token,
      token: token
    };
  };

  const formatarPayLoadTodosDados = todosDados => {
    var dadosUsuario = buscarDadosUsuario();
    var dados = {
      cnpj: todosDados.cnpj,
      ...todosDados.DadosEmpresa,
      endereco: { ...todosDados.DadosEndereco },
      socios: [...todosDados.DadosSocios],
      usuario: { ...dadosUsuario },
      proposta: { canal_captacao: 'DIG' }
    };
    if (origemProposta === 'connect' || origemProposta === 'indicantes') {
      var obsFaturamento = 'Indefinido';
      Object.values(faturamentosAnual).map(fat => {
        if (dados.faturamento_anual >= fat.valor_minimo && dados.faturamento_anual <= fat.valor_maximo) {
          obsFaturamento = fat.label;
        }
        return fat;
      });
      dados.faturamento_anual_observacao = obsFaturamento;
    } else {
      dados.faturamento_anual_observacao = faturamentosAnual[dados.faturamento_anual]
        ? faturamentosAnual[dados.faturamento_anual].label
        : 'Indefinido';
      dados.faturamento_anual = faturamentosAnual[dados.faturamento_anual]
        ? faturamentosAnual[dados.faturamento_anual].valor_maximo
        : 0;
    }
    dados.endereco.tipo = 'C';
    dados.socios = dados.socios.map(socio => {
      // if (socio.cpf_conjuge !== undefined && socio.nome_conjuge !== undefined) {
      //   socio.conjuge = {
      //     cpf: socio.cpf_conjuge,
      //     nome: socio.nome_conjuge
      //   };
      // }
      socio.pep_tipo_id = 1;
      return socio;
    });
    return dados;
  };

  const finalizarCadastro = () => {
    var dados = formatarPayLoadTodosDados({ ...todosDadosCadastrais });
    setPending(true);
    if (origemProposta === 'connect' || origemProposta === 'indicantes') {
      postSalvarCadastroEmpresaConnect(dados)
        .then(() => {
          // SE A REQUISICAO FOR OK JA APRESENTA A TELA DE ENVIADO PARA ANALISE
          avancarEtapa();
        })
        .catch(err => {
          setPending(false);
          setMostraErro(err.response);
        })
        .finally(() => {
          setPending(false);
        });
    } else {
      postSalvarCadastroEmpresa(dados)
        .then(resp => {
          // SE A REQUISICAO FOR OK JA APRESENTA A TELA DE ENVIADO PARA ANALISE
          avancarEtapa(resp && resp.status === 202 ? 'ANE' : '');
        })
        .catch(err => {
          setPending(false);
          setMostraErro(err.response);
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  return (
    <Grid container>
      <Grid item xl={1} lg={1} md={1} sm={1} />
      <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
        <Box sx={{ p: '0 0 30px 0' }}>
          <Typography variant="h5" className="texto-cor-principal texto-negrito texto-centro">
            {activeTabDesc === 'DadosEmpresa' && 'Avançamos mais uma etapa...'}
            {activeTabDesc === 'DadosEndereco' && 'Qual o endereço da sua empresa?'}
            {activeTabDesc === 'DadosSocios' &&
              !msgFinalizando &&
              'Precisamos conhecer seus(uas) sócios(as)...'}
            {activeTabDesc === 'DadosSocios' && msgFinalizando && 'Estamos quase finalizando...'}
          </Typography>
          <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
            {activeTabDesc === 'DadosEmpresa' &&
              'Confirme os dados e complete os campos abaixo para seguirmos com a sua solicitação.'}
            {activeTabDesc === 'DadosEndereco' &&
              'Informe aqui o endereço comercial do CNPJ cadastrado.'}
            {activeTabDesc === 'DadosSocios' &&
              !msgFinalizando &&
              'Preencha as informações solicitadas abaixo. Em caso de sócios Pessoa Jurídica, informe o(a) administrador(a) da empresa.'}
            {activeTabDesc === 'DadosSocios' &&
              msgFinalizando &&
              'Revise as informações fornecidas para seguir com sua solicitação'}
          </Typography>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={parseInt(activeTab)} centered variant="fullWidth">
            <Tab
              label={onlySmallScreen ? 'Dados' : 'Dados da empresa'}
              item={0}
              className={`tab-principal cursor-default ${onlySmallScreen ? 'responsivo' : ''}`}
            />
            <Tab
              label={onlySmallScreen ? 'Endereço' : 'Endereço da empresa'}
              item={1}
              className={`tab-principal cursor-default ${onlySmallScreen ? 'responsivo' : ''}`}
            />
            <Tab
              label={onlySmallScreen ? 'Sociedade' : 'Dados da sociedade'}
              item={2}
              className={`tab-principal cursor-default ${onlySmallScreen ? 'responsivo' : ''}`}
            />
          </Tabs>
        </Box>

        <Box sx={isPendingCadastro ? { position: 'relative', p: '25px 0 0 0' } : { p: '25px 0 0 0' }}>
          {isPendingCadastro && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          {activeTabDesc === 'DadosEmpresa' && !isPending && (
            <DadosEmpresa
              mudarAba={mudarAba}
              todosDadosCadastrais={todosDadosCadastrais}
              setTodosDadosCadastrais={setTodosDadosCadastrais}
              index={0}
              isPendingCadastro={isPendingCadastro}
              setPendingCadastro={setPendingCadastro}
              origemProposta={origemProposta}
            />
          )}
          {activeTabDesc === 'DadosEndereco' && !isPending && (
            <DadosEndereco
              mudarAba={mudarAba}
              todosDadosCadastrais={todosDadosCadastrais}
              setTodosDadosCadastrais={setTodosDadosCadastrais}
              index={1}
              isPendingCadastro={isPendingCadastro}
              setPendingCadastro={setPendingCadastro}
              origemProposta={origemProposta}
            />
          )}
          {activeTabDesc === 'DadosSocios' && !isPending && (
            <DadosSocios
              mudarAba={mudarAba}
              todosDadosCadastrais={todosDadosCadastrais}
              setTodosDadosCadastrais={setTodosDadosCadastrais}
              index={2}
              isPendingCadastro={isPendingCadastro}
              setPendingCadastro={setPendingCadastro}
              finalizarCadastro={finalizarCadastro}
              setMsgFinalizando={setMsgFinalizando}
              origemProposta={origemProposta}
            />
          )}
        </Box>

        <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />
      </Grid>
    </Grid>
  );
};
